import { useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Group, Space, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { financeRoutes } from 'constants/routes';

import { roundTwoDecimal } from 'util/numUtils';
import { financialQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { createPaymentV2 } from 'store/pricing/requests';
import CenteredLoader from 'common/CenteredLoader';

import SamplingPlanGrid from './Components/BillingUser/SamplingPlanGrid';
import SummaryTable from './Components/SummaryTable';
import { useBillingParams, useBillingTranslations } from './hooks';
import PaymentModal from './PaymentModal';
import { useIndividualSummaryFtQuery, useTransactionsByUserQuery } from './queries';

const BillingUserContainer = () => {
  const translations = useBillingTranslations();
  const navigate = useNavigate();
  const [createSalesOrderOpened, { open: openCreateSalesOrder, close: closeCreateSalesOrder }] =
    useDisclosure(false);
  const { approvalStatus, billingUserId } = useBillingParams();
  const queryClient = useQueryClient();
  const { data: summaryFts, isPending: isPendingSummary } = useIndividualSummaryFtQuery(
    approvalStatus || '',
    billingUserId,
  );
  const { data: transactionGroups, isPending: isPendingTransactions } = useTransactionsByUserQuery(
    approvalStatus || '',
    billingUserId,
  );
  const [selectedPlanIds, setSelectedPlanIds] = useState<number[]>([]);
  const [selectedAdjustmentFtIds, setSelectedAdjustmentFtIds] = useState<number[]>([]);
  const [isPaymentOnly, toggleIsPaymentOnly] = useState(false);

  const createPaymentMutation = useMutation({
    mutationFn: (isPayOnly: boolean) =>
      createPaymentV2({
        billing_user_id: billingUserId,
        sampling_plan_ids: selectedPlanIds,
        adjustment_ft_ids: selectedAdjustmentFtIds,
        is_payment_only: isPayOnly,
      }),
    onSuccess: () => {
      showToast(translations.salesOrderCreatedMsg, 'success');
      closeCreateSalesOrder();
      if (approvalStatus) {
        queryClient.invalidateQueries({
          queryKey: financialQueryKeys.summaryTransactions(approvalStatus),
        });
        navigate(financeRoutes.routes.all(approvalStatus));
      }
    },
  });

  useEffect(() => {
    if (transactionGroups?.sampling_plans) {
      setSelectedPlanIds(transactionGroups.sampling_plans.map((plan) => plan.sampling_plan_id));
    }
    if (transactionGroups?.adjustments) {
      setSelectedAdjustmentFtIds(transactionGroups.adjustments.map((adj) => adj.id));
    }
  }, [transactionGroups]);

  const getSalesOrderAmount = (): number => {
    const planTotal =
      transactionGroups?.sampling_plans.reduce(
        (acc, plan) =>
          selectedPlanIds.includes(plan.sampling_plan_id) ? acc + plan.total_price : acc,
        0,
      ) || 0;
    const adjustmentTotal =
      transactionGroups?.adjustments?.reduce(
        (acc, adj) => (selectedAdjustmentFtIds.includes(adj.id) ? acc + adj.amount : acc),
        0,
      ) || 0;
    return roundTwoDecimal(planTotal + adjustmentTotal) || 0;
  };

  const openPaymentsModal = (isPayOnly: boolean) => {
    toggleIsPaymentOnly(isPayOnly);
    openCreateSalesOrder();
  };

  if (isPendingSummary || isPendingTransactions) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Stack pos="sticky" top={0} bg="var(--mantine-color-body)" style={{ zIndex: 3 }} pt="xl">
        <PaymentModal
          opened={createSalesOrderOpened}
          onSave={createPaymentMutation.mutate}
          onClose={closeCreateSalesOrder}
          salesOrderAmount={getSalesOrderAmount()}
          isLoading={createPaymentMutation.isPending}
          isPaymentOnly={isPaymentOnly}
        />
        <Stack gap={0} justify="flex-start" align="flex-start">
          <Button
            variant="transparent"
            c="blue.3"
            leftSection={<FiArrowLeft />}
            component={Link}
            to={financeRoutes.routes.all(approvalStatus as string)}
          >
            <Text>{translations.openTransactions}</Text>
          </Button>
          <Group justify="space-between" w="100%">
            <Title order={2}>
              {summaryFts?.agency_name || `${summaryFts?.first_name} ${summaryFts?.last_name}`}
            </Title>
            <Group gap="xs">
              <Button tt="capitalize" variant="outline" onClick={() => openPaymentsModal(true)}>
                {translations.createPaymentOnly}
              </Button>
              <Button tt="capitalize" onClick={() => openPaymentsModal(false)}>
                {translations.createSalesOrders}
              </Button>
            </Group>
          </Group>
        </Stack>
        <Divider size="sm" />
        <Space h="lg" />
        <Stack gap="lg">
          <Text size="lg" c="green">
            {translations.summary}
          </Text>
          <Box bg="grey.6">
            <Group justify="space-between">
              <Group p="xs">
                <Text fw="bold">{translations.payor}</Text>
                <Text>
                  {summaryFts?.agency_name || `${summaryFts?.first_name} ${summaryFts?.last_name}`}
                </Text>
              </Group>
              <Group p="xs">
                <Text fw="bold">{translations.netValue}: </Text>
                <Text tt="capitalize">${transactionGroups?.balance}</Text>
              </Group>
            </Group>
          </Box>
          <Group justify="space-between">
            <Text size="lg" c="green">
              {translations.completedOrders} - {translations.notInvoiced}
            </Text>
            <Text size="lg" c="green" tt="capitalize">
              {translations.totalOrders}: $
              {roundTwoDecimal(transactionGroups?.sampling_plans_total)}
            </Text>
          </Group>
          <SummaryTable
            summaryData={summaryFts ? [summaryFts] : []}
            approvalStatus={approvalStatus}
          />
          {transactionGroups && (
            <SamplingPlanGrid
              transactionGroups={transactionGroups.sampling_plans}
              selectedPlanIds={selectedPlanIds}
              setSelectedPlanIds={setSelectedPlanIds}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default BillingUserContainer;
