import { LegendWrap } from 'apps/Results/common/MapLegend/LegendWrap';
import { MapLegendItem } from 'apps/Results/common/types';

import { PROJECT_MAP_COLORS } from 'constants/projects';

import { useProjectsTranslations } from './hooks';

type Props = {
  /** e.g. gate marker. These will be prepended before the common items (e.g. Fields symbols). */
  additionalItems?: MapLegendItem[];
};

const ProjectMapLegend = ({ additionalItems = [] }: Props) => {
  const translations = useProjectsTranslations();

  return (
    <LegendWrap
      title={translations.legend}
      maxWidth={150}
      rootBoxProps={{
        style: {
          height: 'auto',
          top: 'unset',
          bottom: 'var(--map-nav-ctrls-gutter)',
        },
      }}
      items={[
        ...additionalItems,
        { label: translations.selectedField, outlineColor: PROJECT_MAP_COLORS.selectedField },
        { label: translations.otherFields, outlineColor: PROJECT_MAP_COLORS.otherFields },
      ]}
    />
  );
};

export default ProjectMapLegend;
