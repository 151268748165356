import { Select } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useAllSkusQuery, useFieldQuery, usePriceEstimateQuery } from 'apps/ZoneAnalysisV3/queries';

import { LIGHT_GREY_BG } from 'constants/colors';
import { CUSTOM_SKU_ID, CUSTOM_SKU_NAME, PRIMARY_PACKAGES_SKU_IDS } from 'constants/pricing';

import { getSkuById, getSubProductsByPrimarySku } from 'util/pricing';
import { getDefaultMapFormInfo } from 'util/samplePlanV3';
import { OrderForm, type SkuType } from 'store/pricing/types';

import AnalysisProductSelectItem from './AnalysisProductSelectItem';

type Props = {
  label: string;
  onChange: (value: string) => void;
};

const FORM_KEY: keyof OrderForm = 'primaryProductId';

const getProductOptions = (skus: SkuType[]) => {
  return PRIMARY_PACKAGES_SKU_IDS.map((skuId) => {
    const sku = getSkuById(skus, skuId);
    const isCustom = skuId === CUSTOM_SKU_ID;
    return {
      value: skuId.toString(),
      label: sku?.name || (isCustom ? CUSTOM_SKU_NAME : 'Name unavailable'),
    };
  });
};

const AnalysisProductSelect = ({ label }: Props) => {
  const allSkusQuery = useAllSkusQuery();
  const form = useOrderFormContext();
  const { data: fieldFeatureCollection } = useFieldQuery();
  const inputProps = form.getInputProps(FORM_KEY);
  const { targetBioDensity, targetScanDensity } = usePriceEstimateQuery();

  const handleChange = (primaryProductIdString: string | null) => {
    const numericSkuId = primaryProductIdString ? parseInt(primaryProductIdString, 10) : null;
    const sku = numericSkuId ? getSkuById(allSkusQuery.data || [], numericSkuId) : null;
    const newMapsConfig = getDefaultMapFormInfo(
      fieldFeatureCollection,
      targetBioDensity,
      targetScanDensity,
    );
    if (numericSkuId === CUSTOM_SKU_ID) {
      form.setValues({ ...newMapsConfig, primaryProductId: CUSTOM_SKU_ID }); // inputProps.onChange not working here 🤷
    } else if (sku) {
      const subProducts = getSubProductsByPrimarySku(sku);
      inputProps.onChange(numericSkuId);

      form.setValues({
        ...newMapsConfig,
        isProScan: sku.is_pro,
        isTillRx: sku.is_till_rx,
        ...subProducts,
      });
    }
  };

  if (allSkusQuery.isPending) {
    return <Select label={label} />;
  }

  return (
    <Select
      key={form.key(FORM_KEY)}
      allowDeselect={false}
      data={getProductOptions(allSkusQuery.data || [])}
      label={label}
      w={300}
      withScrollArea={false}
      comboboxProps={{
        position: 'bottom-start',
        shadow: 'lg',
        transitionProps: { transition: 'fade-down', duration: 200 },
      }}
      styles={{
        option: {
          borderBottom: `1px solid ${LIGHT_GREY_BG}`,
        },
      }}
      renderOption={(item) => (
        <AnalysisProductSelectItem
          itemProps={item}
          selectedSku={getSkuById(allSkusQuery.data || [], Number(item.option.value))}
        />
      )}
      {...inputProps}
      value={String(form.getValues()[FORM_KEY])}
      onChange={handleChange}
    />
  );
};

export default AnalysisProductSelect;
