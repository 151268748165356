import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, FileButton, Group, Text } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Marker } from 'mapbox-gl';

import { ReactComponent as DefaultMapboxMarker } from 'images/icons/mapMarkers/defaultMapboxMarker.svg';

import { FIELD_BOUNDARY, MAP_GUTTERS } from 'constants/mapbox';
import { projectsRoutes } from 'constants/routes';

import { usePageTitle } from 'util/hooks/usePageTitle';
import useReactMapbox from 'util/hooks/useReactMapbox';
import { projectsQueryKeys } from 'util/queryKeys';
import { carbonProjectQueryOptions, fieldQueryOptions } from 'util/queryOptions';
import showToast from 'actions/toastActions';
import { FieldPropertiesType } from 'store/fields/types';
import { createFieldGate, createFieldGatesViaFile, getFieldGates } from 'store/projects/requests';
import { FieldGateProperties } from 'store/projects/types';
import { Header } from 'common';
import FullHeightLayoutFooter from 'common/FullHeightLayoutFooter';
import FullHeightLayoutWrap from 'common/FullHeightLayoutWrap';
import Popup from 'common/Maps/Popup';
import { MapboxClickEvent, PopupState } from 'common/Maps/types';

import BasicPopupContent from '../BasicPopupContent';
import {
  useFieldHoverHandlers,
  useProjectFieldsLayer,
  useProjectRouteParams,
  useProjectsTranslations,
} from '../hooks';
import ProjectMapLegend from '../ProjectMapLegend';

import DeleteGateModal from './DeleteGateModal';
import GatePopupContent from './GatePopupContent';

type MapInteractionMode = 'popup' | 'create';

const FieldGatesContainer = () => {
  const translations = useProjectsTranslations();
  const [idOfGateToDelete, setIdOfGateToDelete] = useState<number>();
  const [interactionMode, setInteractionMode] = useState<MapInteractionMode>('popup');
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const { fieldId, projectId } = useProjectRouteParams();
  const queryClient = useQueryClient();
  const [gateMarkers, setGateMarkers] = useState<Marker[]>([]);
  const fieldQuery = useQuery(fieldQueryOptions(fieldId));
  const fileUploadResetRef = useRef<() => void>(null);
  const [gatesFile, setGatesFile] = useState<File | null>(null);
  const projectQuery = useQuery(carbonProjectQueryOptions(projectId));
  const { setFieldMapCursorListeners, removeFieldMapCursorListeners } = useFieldHoverHandlers();

  const closePopup = () => setPopupInfo(null);

  const gatesQuery = useQuery({
    queryKey: projectsQueryKeys.fieldGates(fieldId),
    queryFn: () => getFieldGates(fieldId),
  });

  const createGateMutation = useMutation({
    mutationFn: (coordinates: [number, number]) =>
      createFieldGate({
        type: 'Feature',
        properties: {
          field_id: fieldId,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      }),
    onSettled: () => {
      fileUploadResetRef.current?.();
    },
    onSuccess: () => {
      showToast(translations.gateCreatedMsg, 'success');

      queryClient.invalidateQueries({
        queryKey: projectsQueryKeys.fieldGates(fieldId),
      });
    },
  });

  const createGatesViaFileMutation = useMutation({
    mutationFn: (file: File) => createFieldGatesViaFile(fieldId, file),
    onSuccess: () => {
      showToast(translations.gateCreatedMsg, 'success');

      queryClient.invalidateQueries({
        queryKey: projectsQueryKeys.fieldGates(fieldId),
      });
    },
  });

  const handleCreateGateMapClick = (evt: MapboxClickEvent) => {
    createGateMutation.mutate([evt.lngLat.lng, evt.lngLat.lat]);
  };

  const { mapContainerRef, mapRef, mapHasLoaded } = useReactMapbox({
    onLoad: setFieldMapCursorListeners,
  });

  const handleFieldClick = ({ target: map, lngLat, point }: MapboxClickEvent) => {
    const clickedFeature = map.queryRenderedFeatures(point, {
      layers: [FIELD_BOUNDARY],
    })[0];

    if (!clickedFeature) return;

    const properties = clickedFeature.properties as FieldPropertiesType;

    setPopupInfo({
      ...lngLat,
      content: (
        <BasicPopupContent
          titleText={properties.name}
          buttonText={translations.manageGates}
          routeTo={projectsRoutes.routes.fieldGates(projectId, properties.id)}
          onClick={closePopup}
        />
      ),
    });
  };

  const handleMarkerClick = (
    evt: MouseEvent,
    properties: FieldGateProperties,
    [lng, lat]: [number, number],
    id: number,
  ) => {
    evt.stopPropagation();
    evt.preventDefault();

    setPopupInfo({
      lng,
      lat,
      content: (
        <GatePopupContent onDeleteClick={() => setIdOfGateToDelete(id)} properties={properties} />
      ),
    });
  };

  const handleNonMarkerMapClick = useCallback(
    (evt: MapboxClickEvent) => {
      if (interactionMode === 'create') {
        handleCreateGateMapClick(evt);
      } else {
        handleFieldClick(evt);
      }
    },
    [interactionMode],
  );

  useProjectFieldsLayer({
    mapHasLoaded: mapHasLoaded && !projectQuery.isPending,
    mapRef,
    fieldIds: projectQuery.data?.fields.map((field) => field.id) || [],
    currentFieldId: fieldId,
  });

  const pageTitle = fieldQuery.data
    ? `${translations.gates} - ${fieldQuery.data?.features[0].properties.name}`
    : translations.gates;

  usePageTitle(pageTitle);

  // Load gate points
  useEffect(() => {
    const map = mapRef.current;

    if (!map || !mapHasLoaded || !gatesQuery.data) return;

    const markers = gatesQuery.data.map(({ geometry, properties, id }) => {
      const marker = new Marker();
      const lngLat = geometry.coordinates as [number, number];
      const element = marker.getElement();

      element.style.cursor = 'pointer';

      element.addEventListener('click', (evt) =>
        handleMarkerClick(evt, properties, lngLat, id as number),
      );

      marker.setLngLat(lngLat).addTo(map);

      return marker;
    });

    // No other way to remove individual markers
    gateMarkers.forEach((marker) => marker.remove());

    setGateMarkers(markers);
  }, [mapHasLoaded, gatesQuery.data]);

  // Remove and re-add all click listeners
  useEffect(() => {
    const map = mapRef.current;

    if (!map || !mapHasLoaded) return;

    map.on('click', handleNonMarkerMapClick);

    return () => {
      map.off('click', handleNonMarkerMapClick);
    };
  }, [mapRef, mapHasLoaded, handleNonMarkerMapClick]);

  const clearFileInput = () => {
    setGatesFile(null);
    fileUploadResetRef.current?.();
  };

  const handleCreateCancelBtnClick = () => {
    const map = mapRef.current;

    if (!map) return;

    const isPopupMode = interactionMode === 'popup';
    const canvas = map.getCanvas();

    if (isPopupMode) {
      removeFieldMapCursorListeners(map);
      canvas.style.cursor = 'crosshair';
    } else {
      canvas.style.cursor = '';
      setFieldMapCursorListeners(map);
    }

    setInteractionMode(isPopupMode ? 'create' : 'popup');
  };

  return (
    <FullHeightLayoutWrap>
      <Header
        title={pageTitle}
        breadcrumbsItems={[
          { title: translations.allProjects, to: projectsRoutes.routes.base },
          { title: projectQuery.data?.name || '', to: projectsRoutes.routes.project(projectId) },
          { title: translations.gates },
        ]}
      >
        <Group>
          <FileButton
            resetRef={fileUploadResetRef}
            accept=".zip,application/zip,.json,application/json,.geojson,application/geo+json"
            onChange={(file) => {
              setGatesFile(file);
              file && createGatesViaFileMutation.mutate(file);
            }}
          >
            {(props) => (
              <Button
                {...props}
                disabled={!!gatesFile}
                loading={createGatesViaFileMutation.isPending}
              >
                {translations.uploadFile}
              </Button>
            )}
          </FileButton>
          <Button disabled={!gatesFile} variant="outline" onClick={clearFileInput}>
            {translations.reset}
          </Button>
          <Text c="dimmed" size="sm">
            {translations.uploadFileInstrs}
          </Text>
        </Group>
      </Header>
      <Group w="100%" align="start" flex={1} gap="xl" style={{ overflow: 'hidden' }} pos="relative">
        <Button
          left={MAP_GUTTERS.default}
          top={MAP_GUTTERS.default}
          style={{ zIndex: 1 }}
          onClick={handleCreateCancelBtnClick}
        >
          {interactionMode === 'popup' ? translations.createNewGate : translations.done}
        </Button>
        <DeleteGateModal
          fieldId={fieldId}
          gateId={idOfGateToDelete}
          onClose={() => {
            setIdOfGateToDelete(undefined);
            closePopup();
          }}
        />
        <ProjectMapLegend
          additionalItems={[
            {
              label: translations.gates,
              icon: (
                <Group justify="center" align="center" h={24}>
                  <DefaultMapboxMarker />
                </Group>
              ),
            },
          ]}
        />
        <div ref={mapContainerRef} style={{ height: '100%', width: '100%', position: 'absolute' }}>
          {popupInfo && mapRef.current && (
            <Popup {...popupInfo} map={mapRef.current} anchor="bottom" onClose={closePopup}>
              {popupInfo.content}
            </Popup>
          )}
        </div>
      </Group>
      <FullHeightLayoutFooter />
    </FullHeightLayoutWrap>
  );
};

export default FieldGatesContainer;
