import React from 'react';
import classNames from 'classnames';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { ApprovedPaymentsType } from 'store/payments/types';
import { Table, TableCol, TableRow } from 'common';

import ApprovedRow from './ApprovedRow';

import styles from './PaymentTables.module.css';

type ApprovedTablePropsType = {
  rowClick?: (param?: any) => void;
  payments: ApprovedPaymentsType[];
};

const ApprovedTable = ({ rowClick, payments }: ApprovedTablePropsType) => {
  const language = useBroswerLanguage();
  return (
    <Table>
      <TableRow header className={classNames(styles.TableHeader, styles.RowPadding)}>
        <TableCol>ID</TableCol>
        <TableCol>{getString('payorPayee', language)}</TableCol>
        <TableCol>{getString('type', language)}</TableCol>
        <TableCol>{getString('amount', language)}</TableCol>
        <TableCol>{getString('updated', language)}</TableCol>
        <TableCol>{getString('status', language)}</TableCol>
      </TableRow>
      {payments.map((payment) => (
        <ApprovedRow
          key={payment.id}
          rowClick={rowClick}
          paymentUuid={payment.payment_uuid}
          paymentId={payment.id}
          billingName={payment.billing_party_name}
          type={payment.payment_type}
          amount={payment.amount}
          updated={payment.settled_at ? payment.settled_at : payment.created_at}
          status={payment.status}
        />
      ))}
    </Table>
  );
};

export default ApprovedTable;
