import React from 'react';
import { Box, Stack, Text } from '@mantine/core';
import classNames from 'classnames';

import { ENGLISH, PORTUGUESE } from 'constants/languages';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { riskRangeToString } from 'util/results';
import { getString } from 'strings/translation';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';

import AnalyticImages from '../../../common/AnalyticImages';

import styles from './AnalyticDetails.module.css';

type AnalyticDetailsPropsType = {
  planAnalytic: SingleAnalyticType;
  activeAnalytic: AnalyticType;
};

const AnalyticDetails = ({ planAnalytic, activeAnalytic }: AnalyticDetailsPropsType) => {
  const language = useBroswerLanguage();
  const { potential_yield_impact_notes, environment_and_weather_notes } = activeAnalytic;

  return (
    <Stack>
      {language === ENGLISH &&
        potential_yield_impact_notes &&
        potential_yield_impact_notes.slice(0, 2) !== '--' && (
          <>
            <Text fw={700}>{getString('yieldImpact', language)}</Text>
            <Text>{potential_yield_impact_notes}</Text>
          </>
        )}
      {planAnalytic.risk_summary && (
        <Box>
          <Box className={styles.RiskLevelRow}>
            <Text fw={700} className={styles.RiskLevel}>
              {getString('rating', language)}
            </Text>
            <Text fw={700}>{getString('level', language)}</Text>
          </Box>
          <Box className={classNames(styles.RiskLevelRow, styles.LowRisk)}>
            <Text className={styles.RiskLevel}>{getString('lowRisk', language)}</Text>
            <Text>{riskRangeToString(planAnalytic.risk_summary.risk_ranges.low, 2)}</Text>
          </Box>
          {planAnalytic.risk_summary.moderate && (
            <Box className={classNames(styles.RiskLevelRow, styles.ModerateRisk)}>
              <Text className={styles.RiskLevel}>{getString('moderateRisk', language)}</Text>
              <Text>{riskRangeToString(planAnalytic.risk_summary.risk_ranges.moderate, 2)}</Text>
            </Box>
          )}
          {planAnalytic.risk_summary.high && (
            <Box className={classNames(styles.RiskLevelRow, styles.HighRisk)}>
              <Text className={styles.RiskLevel}>{getString('highRisk', language)}</Text>
              <Text>{riskRangeToString(planAnalytic.risk_summary.risk_ranges.high, 2)}</Text>
            </Box>
          )}
        </Box>
      )}
      {language === ENGLISH && environment_and_weather_notes && (
        <>
          <Text fw={700}>{getString('effectOfEnvironment', language)}</Text>
          <Text>{environment_and_weather_notes}</Text>
        </>
      )}
      {language === PORTUGUESE && potential_yield_impact_notes && (
        <Box dangerouslySetInnerHTML={{ __html: potential_yield_impact_notes }} />
      )}
      <Box className={styles.HideForPrint}>
        <AnalyticImages images={activeAnalytic?.images} />
      </Box>
    </Stack>
  );
};

export default AnalyticDetails;
