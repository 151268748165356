import { AppThunk } from 'store';

import {
  receiveRecommendationSets,
  receiveSingleRecommendationSet,
  recommendationSetRequestError,
  requestRecommendationSets,
} from './actions';
import {
  requestAllRecommendationSets,
  requestCreateRecommendationSet,
  requestGetRecommendationSet,
  requestUpdateRecommendationSet,
} from './requests';
import { RecommendationSetType } from './types';

export const getAllRecommendationSets = (): AppThunk => async (dispatch) => {
  dispatch(requestRecommendationSets());
  try {
    const response = await requestAllRecommendationSets();
    return dispatch(receiveRecommendationSets(response));
  } catch (e) {
    return dispatch(recommendationSetRequestError);
  }
};

export const getRecommendationSet =
  (set_id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendationSets());
    try {
      const response = await requestGetRecommendationSet(set_id);
      return dispatch(receiveSingleRecommendationSet(response));
    } catch (e) {
      return dispatch(recommendationSetRequestError);
    }
  };

export const createRecommendationSet =
  (body: Partial<RecommendationSetType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendationSets());
    try {
      const response = await requestCreateRecommendationSet(body);
      return dispatch(receiveSingleRecommendationSet(response));
    } catch (e) {
      return dispatch(recommendationSetRequestError);
    }
  };

export const editRecommendationSet =
  (set_id: number, body: Partial<RecommendationSetType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendationSets());
    try {
      const response = await requestUpdateRecommendationSet(body, set_id);
      return dispatch(receiveSingleRecommendationSet(response));
    } catch (e) {
      return dispatch(recommendationSetRequestError);
    }
  };
