import { Flex } from '@mantine/core';

import SamplingZonesForm, { SamplingZonesFormPropsType } from './SamplingZonesForm';

type Props = SamplingZonesFormPropsType & {
  map: React.ReactNode;
};

const SamplingZonesStep = (props: Props) => {
  const { map } = props;

  return (
    <Flex h="100%" w="100%" gap="xl">
      <SamplingZonesForm {...props} />
      {map}
    </Flex>
  );
};

export default SamplingZonesStep;
