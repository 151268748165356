import React from 'react';
import classNames from 'classnames';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { OpenTransactionsType } from 'store/payments/types';
import { Table, TableCol, TableRow } from 'common';

import OpenRow from './OpenRow';

import styles from './PaymentTables.module.css';

type OpenTablePropsType = {
  rowClick?: (param?: any) => void;
  openTransactions: OpenTransactionsType[];
  showRole?: boolean;
  isSampler: boolean;
};

const OpenTable = ({ rowClick, openTransactions, showRole, isSampler }: OpenTablePropsType) => {
  const language = useBroswerLanguage();

  return (
    <Table>
      <TableRow header className={classNames(styles.TableHeader, styles.RowPadding)}>
        <TableCol>{getString('user', language)}</TableCol>
        {showRole && <TableCol>{getString('role', language)}</TableCol>}
        <TableCol>{getString('earliestSample', language)}</TableCol>
        <TableCol>{getString('purchases', language)}</TableCol>
        <TableCol>{getString('earnings', language)}</TableCol>
        <TableCol>{getString('other', language)}</TableCol>
        <TableCol>{getString('balance', language)}</TableCol>
      </TableRow>
      {openTransactions.map((user) => (
        <OpenRow
          key={user.id}
          rowClick={rowClick}
          userId={user.id}
          agencyPrimary={user.agency_primary}
          billingName={user.billing_party_name}
          role={showRole ? user.roles[0]?.name : null}
          billedAmount={user.open_transactions.summary.collections}
          commissions={user.open_transactions.summary.commissions}
          other={user.open_transactions.summary.adjustments}
          balance={user.open_transactions.summary.balance}
          sampling={user.open_transactions.summary.sampling}
          isSampler={isSampler}
          earliest_sampled_date={user.open_transactions.summary.earliest_sampled_date}
        />
      ))}
    </Table>
  );
};

export default OpenTable;
