import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, SegmentedControl } from '@mantine/core';
import turfBbox from '@turf/bbox';
import center from '@turf/center';
import mapboxgl from 'mapbox-gl';

import { RISK_AREA_TYPES } from 'constants/fieldRisks';
import { TILLAGE_LAYER_TEST_RESULTS } from 'constants/proMaps';
import { mapDisplayTypes } from 'constants/results';

import { getHighestRiskFromOrder } from 'util/chartUtils';
import { useSetCompactionFillColor } from 'util/hooks/compactionMap';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getLegendMarks, getLegendTitle, getMapLegendColorStops } from 'util/mapImageryColors';
import {
  addCompactionSourcesAndLayers,
  getProLayerHighLow,
  setTillageHoverHandlers,
} from 'util/proMaps';
import { addFieldAndGetZoom, getAnalytic } from 'util/results';
import { getString } from 'strings/translation';
import { AnalyticType } from 'store/analytics/types';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import useMapboxGl from 'common/MapHooks';
import Popup from 'common/Maps/Popup';
import { PopupState, ViewPortProps } from 'common/Maps/types';

import { LegendWrap } from '../common/MapLegend/LegendWrap';
import { TillageLayerId, TillRxMapCtrls } from '../FieldSummary/Compaction/TillRxMapCtrls';

import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './Map.module.css';

interface MapProps {
  activeAnalytic: AnalyticType;
  field: FieldType;
  samplingPlan: SamplingPlanType;
}

type MapDisplayType = keyof typeof mapDisplayTypes;

const CompactionMap = ({ activeAnalytic, field, samplingPlan }: MapProps) => {
  const language = useBroswerLanguage();
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [mapDisplayType, setMapDisplayType] = useState<MapDisplayType>('pro');
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const [inferenceLayer, setInferenceLayer] = useState<EOInferenceLayerType | null | undefined>();
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const planAnalytic = getAnalytic(samplingPlan, activeAnalytic.category, activeAnalytic.id);
  const riskLevelOrder =
    planAnalytic?.risk_summary?.risk_level_order || RISK_AREA_TYPES.LOW_MODERATE_HIGH;
  const highestRiskOrder = getHighestRiskFromOrder(riskLevelOrder);

  const initialViewport = {
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  };

  const [viewport, setViewport] = useState<ViewPortProps>({
    ...initialViewport,
    width: 0,
    height: 0,
  });

  const [activeTillageLayerId, setActiveTillageLayerId] = useState<TillageLayerId>(
    TILLAGE_LAYER_TEST_RESULTS,
  );

  const proHighLow = getProLayerHighLow(
    mapDisplayType === 'highContrast',
    activeAnalytic.id,
    undefined,
    activeTillageLayerId,
    undefined,
    inferenceLayer,
  );

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, true, null, null);

  // Set map loaded state
  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef]);

  // Set initial map view based on the field boundary
  useEffect(() => {
    if (mapHasLoaded && mapRef.current) {
      const zoom = addFieldAndGetZoom(mapRef.current, field);

      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  // Set up map sources and layers, and hover/click handlers
  useEffect(() => {
    const map = mapRef.current;

    if (!mapHasLoaded || !map) {
      return;
    }

    addCompactionSourcesAndLayers(map, language, setPopupInfo, proHighLow);
    setTillageHoverHandlers(map);
  }, [mapRef, language, mapHasLoaded, proHighLow]);

  useSetCompactionFillColor(activeTillageLayerId, mapHasLoaded, mapRef.current, proHighLow);

  const recenterMap = () => {
    setViewport(initialViewport);

    mapRef.current?.fitBounds(turfBbox(field) as mapboxgl.LngLatBoundsLike, {
      padding: {
        top: 45,
        bottom: 45,
        left: 30,
        right: 30,
      },
    });
  };

  const changeMapDisplayType = (element: string) => {
    setMapDisplayType(element as MapDisplayType);
    setPopupInfo(null);
  };

  const handleActiveTillageLayerChange = (layerId: TillageLayerId) => {
    setActiveTillageLayerId(layerId);
    setPopupInfo(null);
  };

  const legendMarks = proHighLow ? getLegendMarks(proHighLow.high, proHighLow.low) : [];

  const legendTitle = getLegendTitle(
    activeAnalytic.id,
    activeTillageLayerId === 'testResults',
    activeTillageLayerId === 'rx',
    planAnalytic?.unit,
    inferenceLayer,
  );

  return (
    <Box className={styles.Wrapper} display="flex" style={{ flexDirection: 'column' }}>
      <Box ref={mapContainerRef} className={styles.MapWrapper}>
        <LegendWrap
          showPlus
          marks={legendMarks}
          title={legendTitle}
          colorStops={getMapLegendColorStops(
            getHighestRiskFromOrder(highestRiskOrder) || '',
            activeAnalytic.id,
          )}
        />
        {popupInfo && mapRef.current && (
          <Popup
            {...popupInfo}
            map={mapRef.current}
            anchor="bottom"
            onClose={() => setPopupInfo(null)}
          >
            {popupInfo.content}
          </Popup>
        )}
        <Button variant="white" className={styles.Recenter} onClick={recenterMap}>
          {getString('recenter', language)}
        </Button>
      </Box>
      <Box px="sm" py="md">
        <TillRxMapCtrls
          inlineRxResultsSlider
          activeTillageLayerId={activeTillageLayerId}
          field={field.features[0]}
          mapHasLoaded={mapHasLoaded}
          mapRef={mapRef}
          onActiveLayerChange={handleActiveTillageLayerChange}
          onSliderChange={() => setPopupInfo(null)}
          samplingPlanId={samplingPlan.id}
          setInferenceLayer={(layer) => setInferenceLayer(layer)}
          rootFlexProps={{
            direction: 'row',
            align: 'center',
            mb: 0,
          }}
          mapDisplayTypeToggle={
            activeTillageLayerId === TILLAGE_LAYER_TEST_RESULTS ? (
              <SegmentedControl
                value={mapDisplayType}
                onChange={changeMapDisplayType}
                data={[
                  {
                    label: getString('proResults', language),
                    value: mapDisplayTypes.pro,
                  },
                  {
                    label: getString('highContrast', language),
                    value: mapDisplayTypes.highContrast,
                  },
                ]}
              />
            ) : null
          }
        />
      </Box>
    </Box>
  );
};

export default CompactionMap;
