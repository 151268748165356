import { Select } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { ZONE_TYPES } from 'constants/mapbox';

import { getZoneCreationOptions } from 'util/samplePlan';
import { getString } from 'strings/translation';

type Props = {
  language: string;
  onChange: (zoneType: string) => void;
  isProOrTillRx: boolean;
};

const ZoneTypeSelect = ({ language, isProOrTillRx, onChange }: Props) => {
  const form = useOrderFormContext();
  const formValues = form.getValues();

  const planFromSelectedSkus = (() => {
    if (!isProOrTillRx && formValues.completedSamples.length) {
      return formValues.completedPlan;
    }
    if (
      isProOrTillRx &&
      formValues.completedSamples.every((s) => s.properties.zone_type === ZONE_TYPES.POLYGON)
    ) {
      return formValues.completedPlan;
    }
    return null;
  })();

  const options = getZoneCreationOptions(language, planFromSelectedSkus, isProOrTillRx);

  return (
    <Select
      key={form.key('creationOption')}
      label={getString('type', language)}
      data={options}
      w="11rem"
      {...form.getInputProps('creationOption')}
      onChange={(zoneType) => {
        if (zoneType) {
          onChange(zoneType);
          form.getInputProps('creationOption').onChange(zoneType);
        }
      }}
      value={formValues.creationOption}
    />
  );
};

export default ZoneTypeSelect;
