import React, { ReactNode } from 'react';
import { Box } from '@mantine/core';

import { NavBar, PrintHeaderFooter } from 'common';

import styles from './NavBarLayout.module.css';

interface Props {
  children: ReactNode;
}

const FullHeightNavBarLayout = ({ children }: Props) => {
  return (
    <PrintHeaderFooter className={styles.FullHeightBody}>
      <NavBar mode="core" />
      <Box
        display="flex"
        flex={1}
        mx="auto"
        w="95%"
        style={{
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </PrintHeaderFooter>
  );
};

export default FullHeightNavBarLayout;
