import { useState } from 'react';
import { FaExclamationCircle, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Alert, Button, Space, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';

import { UI_COLORS } from 'constants/colors';
import { projectsRoutes } from 'constants/routes';

import { formatCalendarMonthDayYear } from 'util/date';
import { usePageTitle } from 'util/hooks/usePageTitle';
import { projectsQueryKeys } from 'util/queryKeys';
import { carbonProjectQueryOptions } from 'util/queryOptions';
import showToast from 'actions/toastActions';
import {
  getProjectRunningSamplingPlanCount,
  getSamplingPlansByProjectId,
} from 'store/projects/requests';
import { CarbonProjectFieldRowData } from 'store/projects/types';
import { Header } from 'common';
import CenteredLoader from 'common/CenteredLoader';
import FullHeightLayoutFooter from 'common/FullHeightLayoutFooter';
import FullHeightLayoutWrap from 'common/FullHeightLayoutWrap';

import ManagePlanFeaturesMenu from './SamplePlans/ManagePlanFeaturesMenu';
import CarbonProjectHeader from './CarbonProjectHeader';
import { useEditProjectMutation, useProjectRouteParams, useProjectsTranslations } from './hooks';
import ProjectFieldsTable from './ProjectFieldsTable';

const ProjectContainer = () => {
  const { projectId } = useProjectRouteParams();
  const projectQuery = useQuery(carbonProjectQueryOptions(projectId));
  const translations = useProjectsTranslations();
  const editProjectMutation = useEditProjectMutation();

  const [selectedFields, setSelectedFields] = useState<CarbonProjectFieldRowData[]>([]);
  const [removedFieldIds, setRemovedFieldIds] = useState<number[]>([]);
  const [fieldRemovalIsActive, setFieldRemovalIsActive] = useState(false);

  const samplingPlansQuery = useQuery({
    queryKey: projectsQueryKeys.projectSamplingPlans(projectId),
    queryFn: () => getSamplingPlansByProjectId(projectId),
    enabled: !!projectQuery.data,
    select: (data) => {
      const firstSamplingPlan = data[0];

      // Just return the first sampling plan for now, flattened into a fields list
      const fields = firstSamplingPlan?.carbon_collections.flatMap((c) =>
        c.collection_fields.map(
          ({ field, created_at, updated_at }): CarbonProjectFieldRowData => ({
            ...field,
            created_at,
            updated_at,
            samplingPlanId: data[0].id,
          }),
        ),
      );

      return { fields, firstSamplingPlan };
    },
  });

  const { firstSamplingPlan } = samplingPlansQuery.data || {};
  const fields = samplingPlansQuery.data?.fields || projectQuery.data?.fields || [];
  const intactFields = fields.filter((field) => !removedFieldIds.includes(field.id));
  const isPending = projectQuery.isPending || samplingPlansQuery.isPending;

  usePageTitle(projectQuery.data?.name);

  const samplingPlanCountQuery = useQuery({
    queryKey: projectsQueryKeys.projectSamplingPlanCount(projectId),
    queryFn: () => getProjectRunningSamplingPlanCount(projectId),
    select: (data) => data.running_jobs,
  });

  const inProgressPlanCount = samplingPlanCountQuery.data;

  const fieldsListIsEditable =
    !samplingPlanCountQuery.isPending && !inProgressPlanCount && !firstSamplingPlan;

  const uniqueOperationNames = Array.from(
    new Set(intactFields.map((field) => field.operation_name)),
  );

  const showSuccessToastWithUndo = (onClick?: VoidFunction) => {
    const toastId = 'remove-edit-fields';

    showToast(translations.clickToUndo, 'success', 5000, {
      id: toastId,
      title: translations.projectUpdatedMsg,
      style: { cursor: 'pointer' },
      position: 'bottom-center',
      withCloseButton: false, // unsure how to prevent click from bubbling up to the parent
      onClick: () => {
        !!onClick && onClick();
        notifications.hide(toastId);
        setRemovedFieldIds([]);
      },
    });
  };

  const showBasicSuccessToast = () => showToast(translations.projectUpdatedMsg, 'success');

  const handleRemoveFieldsClick = () => {
    setRemovedFieldIds((prev) => {
      editProjectMutation.mutate(
        {
          projectId,
          fieldIds: fields
            .filter(
              (field) => !selectedFields.some((selectedField) => selectedField.id === field.id),
            )
            .map((field) => field.id),
        },
        {
          onSuccess: (_response, { fieldIds: fieldIdsInPayload }, { previousFieldIds }) => {
            if (fieldIdsInPayload) {
              setRemovedFieldIds([]);

              showSuccessToastWithUndo(() =>
                editProjectMutation.mutate(
                  { projectId, fieldIds: previousFieldIds },
                  { onSuccess: showBasicSuccessToast }, // don't show undo toast again
                ),
              );
            } else {
              showBasicSuccessToast();
            }
          },
        },
      );

      return [...prev, ...selectedFields.map(({ id }) => id)];
    });

    setSelectedFields([]);
    setFieldRemovalIsActive(false);
  };

  const getFieldRemovalButtons = () => {
    if (!fieldsListIsEditable) {
      return null;
    }

    if (fieldRemovalIsActive) {
      return (
        <>
          <Button variant="subtle" onClick={() => setFieldRemovalIsActive(false)}>
            {translations.cancel}
          </Button>
          <Button
            color={UI_COLORS.error}
            onClick={handleRemoveFieldsClick}
            disabled={!selectedFields.length}
            leftSection={<FaTrash />}
          >
            {translations.removeSelectedFields}
          </Button>
        </>
      );
    }

    return (
      <Button
        color={UI_COLORS.error}
        onClick={() => setFieldRemovalIsActive(true)}
        variant="outline"
        leftSection={<FaTrash />}
      >
        {translations.removeFieldsFromProject}
      </Button>
    );
  };

  return (
    <FullHeightLayoutWrap>
      <CarbonProjectHeader
        fieldsListIsEditable={fieldsListIsEditable}
        projectFields={fields}
        projectName={projectQuery.data?.name}
        projectId={projectId}
        operationNames={uniqueOperationNames}
      >
        {getFieldRemovalButtons()}
        {!inProgressPlanCount && !firstSamplingPlan && (
          <Button
            component={Link}
            to={projectsRoutes.routes.createSamplePlanForProject(projectId)}
            loading={samplingPlanCountQuery.isPending}
          >
            {translations.orderSamplingPlan}
          </Button>
        )}
        {!!inProgressPlanCount && <Text size="sm">{translations.samplingPlanInProgress}</Text>}
      </CarbonProjectHeader>
      {!!firstSamplingPlan?.errors && (
        <>
          <Alert
            variant="light"
            color={UI_COLORS.error}
            title={translations.samplingPlanErrors}
            icon={<FaExclamationCircle />}
          >
            {firstSamplingPlan.errors}
          </Alert>
          <Space h="sm" />
        </>
      )}
      {!!firstSamplingPlan && (
        <Header
          title={
            <>
              <Text size="xl">
                {translations.samplingPlan} {firstSamplingPlan.id}
              </Text>
              <Text size="sm" c="dimmed">
                {translations.created} {formatCalendarMonthDayYear(firstSamplingPlan.created_at)}
              </Text>
            </>
          }
        >
          <ManagePlanFeaturesMenu
            carbonSamplingPlanId={firstSamplingPlan.id}
            projectId={projectId}
          />
        </Header>
      )}
      <Stack w="100%" style={{ overflowY: 'auto' }}>
        {isPending && <CenteredLoader />}
        {!isPending && (
          <ProjectFieldsTable
            fields={intactFields}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            enableSelection={fieldRemovalIsActive}
            projectId={projectId}
          />
        )}
      </Stack>
      <FullHeightLayoutFooter />
    </FullHeightLayoutWrap>
  );
};

export default ProjectContainer;
