import { ImWarning } from 'react-icons/im';
import { Alert, Button, Group, Modal, Stack, Text, Title } from '@mantine/core';

import { UI_COLORS } from 'constants/colors';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import { getString } from 'strings/translation';

type Props = {
  language: string;
  isLoading: boolean;
  opened: boolean;
  coverageIsLow: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
};

const AssignCollectionsModal = ({
  language,
  isLoading,
  opened,
  onClose,
  onSubmit,
  coverageIsLow,
}: Props) => {
  return (
    <Modal opened={opened} onClose={onClose} centered withCloseButton={false} size="lg">
      <Stack px="xl" py="lg" ta="center">
        <Title
          ta="center"
          fw={FONT_WEIGHT_BOLD}
        >{`${getString('confirmFieldAssignment', language)}?`}</Title>
        {coverageIsLow && (
          <Alert
            variant="light"
            color={UI_COLORS.warning}
            title={<Text fw="bold">{getString('lowScanCoverageWarning', language)}</Text>}
            icon={<ImWarning />}
          >
            <Text size="sm" ta="left">
              {getString('lowScanCoverageWarningMsg', language)}
            </Text>
          </Alert>
        )}
        <Text mt="sm" mb="md">
          {getString('confirmCollectionMsg', language)}
        </Text>
        <Group justify="center">
          <Button size="md" onClick={onClose} variant="outline">
            {getString('cancel', language)}
          </Button>
          <Button
            size="md"
            loading={isLoading}
            onClick={onSubmit}
            color={coverageIsLow ? UI_COLORS.error : UI_COLORS.darkBlue}
          >
            {getString(coverageIsLow ? 'approveAnyway' : 'confirm', language)}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default AssignCollectionsModal;
