import { Feature } from '@turf/helpers';

import {
  ANALYSIS_590,
  COMMISSION_PERFORMANCE_PANEL,
  COMMISSION_PRESSURE_PANEL,
  COMPLETE_BIO,
  DECISION_DASHBOARD,
  DISCOUNT_PANELS,
  DISCOUNT_PRESSURE_PANEL,
  DISCOUNT_SAMPLING_FEE,
  legacyProducts,
  NITRATE_PANEL,
  NONE,
  NUTRIENT_PANEL,
  NUTRIENT_PRO,
  PATTERN_360,
  PATTERN_360_PRO,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
  PRESSURE_PANEL_ONLY,
  PRO_MAP_GENERATION,
  PRO_SENSOR_SCAN,
  REFUND_NITRATE_PANEL,
  REFUND_NUTRIENT_PANEL,
  REFUND_PERFORMANCE_PANEL,
  REFUND_PRESSURE_PANEL,
  REFUND_SAMPLING_FEE,
  ROOTWORM_PRESSURE_PANEL,
  SAMPLING_FEE_KEY,
  SCN_PRESSURE_PANEL,
  SPRING,
  SUMMER,
  TILL_MAPPER,
  TILLAGE_MAP_GENERATION,
  TILLAGE_MEASUREMENT,
  timingSeasons,
} from 'constants/products';
import { SPLIT_DENSITY, ZONE_BY_ZONE } from 'constants/samplePlanning';

import { getRequiredAnalysisForProduct, isAnalysisComplete } from 'util/analysis';
import { getString } from 'strings/translation';
import { SamplingPlanType } from 'store/fields/types';
import { OrderForm } from 'store/pricing/types';
import { PriceSummaryType, SamplePlanType } from 'store/samplePlans/types';
import { SampleType } from 'store/samples/types';

export const isProductComplete = (
  samplingPlanOrSample: SamplingPlanType | SampleType,
  product: string,
) => {
  const analysis = getRequiredAnalysisForProduct(product);
  return !!isAnalysisComplete(samplingPlanOrSample, analysis);
};

export const getTimingSeason = (timing: string) => {
  const season = timing.split('-')[0];
  return timingSeasons.includes(season) ? season : null;
};

export const readyToSampleDefaultState = (timing: string) => {
  return getTimingSeason(timing) === SPRING;
};

export const getDefaultTimingOptionIndex = (currentTiming?: string) =>
  currentTiming && getTimingSeason(currentTiming) === SUMMER ? 1 : 0;

export const hasProducts = (
  samplingPlanOrSample: SamplePlanType | SampleType,
  ...products: string[]
) => Boolean(samplingPlanOrSample.products.filter((product) => products.includes(product)).length);

export const hasLegacyProducts = (samplingPlanOrSample: SamplePlanType | SampleType) =>
  hasProducts(samplingPlanOrSample, ...legacyProducts);

export const isProductNotAnalyzed = (sample: SampleType, product: string) => {
  switch (product) {
    case PERFORMANCE_PANEL:
      return sample.performance_panel_not_analyzed;
    case PRESSURE_PANEL:
      return sample.pressure_panel_not_analyzed;
    case NUTRIENT_PANEL:
      return sample.nutrient_panel_not_analyzed;
    case NITRATE_PANEL:
      return sample.nitrate_panel_not_analyzed;
    default:
      return false;
  }
};

// sampling groups for split density
// -- used in plan submission
export const getSampleGroupings = (
  features: Feature[],
  products: string[],
  isSplitDensityNitrate: boolean,
) => {
  const splitGroupDict = features.reduce(
    (dict, feat) => {
      const group = feat.properties?.sample_group;
      if (group && feat.properties?.sample_uuid) {
        if (!dict[group]) {
          dict[group] = [feat.properties.sample_uuid];
        } else {
          dict[group].push(feat.properties.sample_uuid);
        }
      }
      return dict;
    },
    {} as { [name: string]: string[] },
  );

  const groupingsList = Object.keys(splitGroupDict).map((group) => splitGroupDict[group]);

  return {
    products: isSplitDensityNitrate ? [...products, NITRATE_PANEL] : products,
    groupings: groupingsList,
  };
};

export const getSamplePlanProductsBio = (language: string, isRestricted: boolean) => [
  {
    displayName: getString('pressure_panel_only', language),
    label: getString('pressure_panel_only', language),
    value: PRESSURE_PANEL_ONLY,
    panels: [PRESSURE_PANEL],
    msg: isRestricted
      ? getString('pressure_panel_description_rkn', language)
      : getString('pressure_panel_description', language),
  },
  {
    displayName: getString('complete_bio', language),
    label: getString('complete_bio', language),
    value: COMPLETE_BIO,
    panels: [PRESSURE_PANEL, PERFORMANCE_PANEL],
    msg: getString('completeBioMsg', language),
  },
  {
    displayName: getString('pattern_360', language),
    label: getString('pattern_360', language),
    value: PATTERN_360,
    panels: [PRESSURE_PANEL, PERFORMANCE_PANEL],
    msg: getString('pattern360Msg', language),
  },
  {
    displayName: getString('none', language),
    label: getString('none', language),
    value: NONE,
    panels: [],
    msg: '',
  },
];

export const getSelectedBioProduct = (
  planState: OrderForm,
  language: string,
  isRestricted: boolean,
) => {
  const { products } = planState;
  const possibleProductsBio = getSamplePlanProductsBio(language, isRestricted);
  const hasPressure = products.includes(PRESSURE_PANEL);
  const hasPerformance = products.includes(PERFORMANCE_PANEL);
  const hasNutrient = products.includes(NUTRIENT_PANEL);

  if ([hasPressure, hasPerformance, hasNutrient].every(Boolean)) {
    return possibleProductsBio.find((product) => product.value === PATTERN_360);
  }
  if ([hasPressure, hasPerformance].every(Boolean)) {
    return possibleProductsBio.find((product) => product.value === COMPLETE_BIO);
  }
  if (hasPressure) {
    return possibleProductsBio.find((product) => product.value === PRESSURE_PANEL_ONLY);
  }
  return possibleProductsBio.find((product) => product.value === NONE);
};

export const isSelectSplitDensityPossible = (
  isProOrTillRx: boolean,
  products: OrderForm['products'],
  zones: OrderForm['zones'],
  isRestricted: boolean,
) => {
  const hasPressureOrPerformance =
    products.includes(PRESSURE_PANEL) || products.includes(PERFORMANCE_PANEL);

  return (
    !isRestricted &&
    !isProOrTillRx &&
    zones &&
    products.includes(NUTRIENT_PANEL) &&
    hasPressureOrPerformance
  );
};

export const getSelectedBioProductFromAnalysis = (
  analysis: OrderForm,
  language: string,
  isRestricted: boolean,
) => {
  const { products } = analysis;
  const hasPressure = products.includes(PRESSURE_PANEL);
  const hasPerformance = products.includes(PERFORMANCE_PANEL);
  const hasNutrient = products.includes(NUTRIENT_PANEL);
  const possibleProductsBio = getSamplePlanProductsBio(language, isRestricted);

  if ([hasPressure, hasPerformance, hasNutrient].every(Boolean)) {
    return possibleProductsBio.find((product) => product.value === PATTERN_360);
  }
  if ([hasPressure, hasPerformance].every(Boolean)) {
    return possibleProductsBio.find((product) => product.value === COMPLETE_BIO);
  }
  if (hasPressure) {
    return possibleProductsBio.find((product) => product.value === PRESSURE_PANEL_ONLY);
  }
  return possibleProductsBio.find((product) => product.value === NONE);
};

export const getSamplePlanProductsNutrient = (language: string, isRestricted: boolean) => [
  {
    displayName: getString('nutrient_panel', language),
    label: getString('nutrient_panel', language),
    value: NUTRIENT_PANEL,
    panels: [NUTRIENT_PANEL],
    msg: isRestricted
      ? getString('restricted_panel_description', language)
      : getString('nutrient_panel_description', language),
  },
  {
    displayName: getString('none', language),
    label: getString('none', language),
    value: 'pressure_nutrient',
    panels: [],
    msg: '',
  },
];

export const getSelectedNutrientProduct = (
  planState: OrderForm,
  language: string,
  isRestricted: boolean,
) => {
  const { products } = planState;
  const possibleProductsNutrient = getSamplePlanProductsNutrient(language, isRestricted);
  const hasNutrient = products.includes(NUTRIENT_PANEL);
  if (hasNutrient) {
    return possibleProductsNutrient.find((product) => product.value === NUTRIENT_PANEL);
  }
  return possibleProductsNutrient[possibleProductsNutrient.length - 1];
};

export const getNutrientPanels = (
  planState: OrderForm,
  product: string | null,
  selectedNutrientProduct,
) => {
  const hasNitrate = planState.products.includes(NITRATE_PANEL);
  const nitratePanels = hasNitrate ? [NITRATE_PANEL] : [];
  // for 360, automatically set nutrient to be true
  if (product === PATTERN_360) {
    // setNutrientProduct()
    return [NUTRIENT_PANEL].concat(nitratePanels);
  }
  // for complete bio and pressure only, automatically set nutrient to false
  if (product === COMPLETE_BIO || product === PRESSURE_PANEL_ONLY) {
    return nitratePanels;
  }
  // otherwise, return existing nutrient option
  return (selectedNutrientProduct?.panels || []).concat(nitratePanels);
};

export const isPattern360 = (products: string[]) => {
  return (
    products.includes(PRESSURE_PANEL) &&
    products.includes(PERFORMANCE_PANEL) &&
    products.includes(NUTRIENT_PANEL)
  );
};

export const isCompleteBio = (products: string[]) => {
  return (
    products.includes(PRESSURE_PANEL) &&
    products.includes(PERFORMANCE_PANEL) &&
    products.length === 2
  );
};

const hasNitratePanel = (products: string[]) => {
  return products.includes(NITRATE_PANEL);
};

export const isNutrientPanel = (products: string[]) => {
  return (
    products?.includes(NUTRIENT_PANEL) &&
    (products?.length === 1 || (hasNitratePanel(products) && products?.length === 2))
  );
};

export const getProductList = (
  products: string[],
  is_pro: boolean,
  is_till_rx: boolean,
  is_590?: boolean,
  primary_sku_name?: string | null,
) => {
  if (primary_sku_name) {
    return [primary_sku_name];
  }
  if (isPattern360(products)) {
    let productsToDisplay = [PATTERN_360];
    if (is_pro) {
      productsToDisplay = [PATTERN_360_PRO];
    }
    if (is_till_rx) {
      productsToDisplay.push(TILL_MAPPER);
    }
    if (is_590) {
      productsToDisplay.push(ANALYSIS_590);
    }
    return hasNitratePanel(products) ? [...productsToDisplay, NITRATE_PANEL] : productsToDisplay;
  }
  if (isCompleteBio(products)) {
    return is_till_rx ? [COMPLETE_BIO, TILL_MAPPER] : [COMPLETE_BIO];
  }
  if (products.includes(DECISION_DASHBOARD)) {
    return [ROOTWORM_PRESSURE_PANEL, SCN_PRESSURE_PANEL, DECISION_DASHBOARD];
  }
  const prodList: string[] = [];
  if (products.includes(PRESSURE_PANEL)) {
    prodList.push(PRESSURE_PANEL);
  }
  if (products.includes(PERFORMANCE_PANEL)) {
    prodList.push(PERFORMANCE_PANEL);
  }
  if (products.includes(NUTRIENT_PANEL)) {
    prodList.push(is_pro ? NUTRIENT_PRO : NUTRIENT_PANEL);
  }
  if (products.includes(NITRATE_PANEL)) {
    prodList.push(NITRATE_PANEL);
  }
  if (is_till_rx) {
    prodList.push(TILL_MAPPER);
  }
  if (is_590) {
    prodList.push(ANALYSIS_590);
  }
  return prodList;
};

export const getProductComboName = (
  productNames: string[],
  language: string,
  isRestricted: boolean,
) => {
  const bioProducts = getSamplePlanProductsBio(language, isRestricted);
  const nutrientProducts = getSamplePlanProductsNutrient(language, isRestricted);

  const hasPressure = productNames.includes(PRESSURE_PANEL);
  const hasPerformance = productNames.includes(PERFORMANCE_PANEL);
  const hasNutrient = productNames.includes(NUTRIENT_PANEL);
  if ([hasPressure, hasPerformance, hasNutrient].every(Boolean)) {
    return bioProducts[2];
  }
  if ([hasPressure, hasPerformance].every(Boolean)) {
    return bioProducts[1];
  }
  if (hasPressure) {
    return bioProducts[0];
  }
  return nutrientProducts[0];
};

const getNonCombinedPanels = (priceSummary: PriceSummaryType, language: string) => {
  return Object.keys(priceSummary)
    .filter((panel) => ![NUTRIENT_PANEL, PRESSURE_PANEL, PERFORMANCE_PANEL].includes(panel))
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: {
          ...priceSummary[cur],
          name: getString(cur, language),
        },
      }),
      {} as { [key: string]: PriceSummaryType[string] },
    );
};

export const getPriceSummary = (
  priceSummary: PriceSummaryType,
  language: string,
  analysisMode: string,
  isProScan?: boolean,
) => {
  const pressureSummary = priceSummary[PRESSURE_PANEL];
  const performanceSummary = priceSummary[PERFORMANCE_PANEL];
  const nutrientSummary = priceSummary[NUTRIENT_PANEL];

  const nonCombinedPanels = getNonCombinedPanels(priceSummary, language);

  if (analysisMode === SPLIT_DENSITY || isProScan) {
    // complete bio, both split panels are present
    if (
      [pressureSummary?.num_zones, performanceSummary?.num_zones, nutrientSummary?.num_zones].every(
        Boolean,
      )
    ) {
      return {
        [COMPLETE_BIO]: {
          name: getString(COMPLETE_BIO, language),
          num_zones: pressureSummary.num_zones,
          total: pressureSummary.total + performanceSummary.total,
          commission: pressureSummary.commission + performanceSummary.commission,
          collection: pressureSummary.collection + performanceSummary.collection,
        },
        [NUTRIENT_PANEL]: {
          name: getString(NUTRIENT_PANEL, language),
          num_zones: nutrientSummary.num_zones,
          total: nutrientSummary.total,
          commission: nutrientSummary.commission,
          collection: nutrientSummary.collection,
        },
        ...nonCombinedPanels,
      };
    }
    // just pressure
    if (pressureSummary?.num_zones) {
      return {
        [PRESSURE_PANEL]: {
          name: getString(PRESSURE_PANEL, language),
          num_zones: pressureSummary.num_zones,
          total: pressureSummary.total,
          commission: pressureSummary.commission,
          collection: pressureSummary.collection,
        },
        [NUTRIENT_PANEL]: {
          name: getString(NUTRIENT_PANEL, language),
          num_zones: nutrientSummary?.num_zones,
          total: nutrientSummary?.total,
          commission: nutrientSummary?.commission,
          collection: nutrientSummary?.collection,
        },
        ...nonCombinedPanels,
      };
    }
    if (nutrientSummary?.num_zones) {
      return {
        [NUTRIENT_PANEL]: {
          name: getString(NUTRIENT_PANEL, language),
          num_zones: nutrientSummary.num_zones,
          total: nutrientSummary.total,
          commission: nutrientSummary.commission,
          collection: nutrientSummary.collection,
        },
        ...nonCombinedPanels,
      };
    }
    return nonCombinedPanels;
  }
  if (analysisMode === ZONE_BY_ZONE) {
    // pattern 360, all panels present
    if (
      [pressureSummary?.num_zones, performanceSummary?.num_zones, nutrientSummary?.num_zones].every(
        Boolean,
      )
    ) {
      return {
        [PATTERN_360]: {
          name: getString(PATTERN_360, language),
          num_zones: pressureSummary.num_zones,
          total: pressureSummary.total + performanceSummary.total + nutrientSummary.total,
          commission:
            pressureSummary.commission + performanceSummary.commission + nutrientSummary.commission,
          collection:
            pressureSummary.collection + performanceSummary.collection + nutrientSummary.collection,
        },
        ...nonCombinedPanels,
      };
    }
    // complete bio, both bio panels present
    if ([pressureSummary?.num_zones, performanceSummary?.num_zones].every(Boolean)) {
      return {
        [COMPLETE_BIO]: {
          name: getString(COMPLETE_BIO, language),
          num_zones: pressureSummary.num_zones,
          total: pressureSummary.total + performanceSummary.total,
          commission: pressureSummary.commission + performanceSummary.commission,
          collection: pressureSummary.collection + performanceSummary.collection,
        },
        ...nonCombinedPanels,
      };
    }
    // just pressure or just nutrient, add panels to noncombined
    if (pressureSummary?.num_zones || nutrientSummary?.num_zones) {
      return [PRESSURE_PANEL, NUTRIENT_PANEL].reduce((acc, cur) => {
        const panel = priceSummary[cur];
        if (panel) {
          return {
            ...acc,
            [cur]: {
              ...panel,
              name: getString(cur, language),
            },
          };
        }
        return acc;
      }, nonCombinedPanels);
    }
    if (nutrientSummary?.num_zones) {
      return {
        [NUTRIENT_PANEL]: {
          name: getString(NUTRIENT_PANEL, language),
          num_zones: nutrientSummary.num_zones,
          total: nutrientSummary.total,
          commission: nutrientSummary.commission,
          collection: nutrientSummary.collection,
        },
        ...nonCombinedPanels,
      };
    }
    return nonCombinedPanels;
  }

  return nonCombinedPanels;
};

// define sort order to order summary as follows
// 1. Biology product, i.e. 360, complete bio, pp
// 2. Nutrient panel, if not covered by above
// 3. Nitrate panel, if ordered
// 4. Sampling
// 5. Discounts, panels first then sampling
const priceSummarySortOrder = {
  [PATTERN_360]: 1,
  [COMPLETE_BIO]: 1,
  [PRESSURE_PANEL]: 1,
  [NUTRIENT_PANEL]: 2,
  [NITRATE_PANEL]: 3,
  [SAMPLING_FEE_KEY]: 4,
  [DISCOUNT_PRESSURE_PANEL]: 5,
  [DISCOUNT_PANELS]: 5,
  [DISCOUNT_SAMPLING_FEE]: 6,
  [COMMISSION_PRESSURE_PANEL]: 7,
  [COMMISSION_PERFORMANCE_PANEL]: 8,
  [REFUND_PRESSURE_PANEL]: 9,
  [REFUND_PERFORMANCE_PANEL]: 10,
  [REFUND_NUTRIENT_PANEL]: 11,
  [REFUND_NITRATE_PANEL]: 12,
  [REFUND_SAMPLING_FEE]: 13,
  [PRO_SENSOR_SCAN]: 14,
  [PRO_MAP_GENERATION]: 15,
  [TILLAGE_MEASUREMENT]: 16,
  [TILLAGE_MAP_GENERATION]: 17,
};

export const sortPriceSummaryKeys = (priceSummary: PriceSummaryType) => {
  const productKeys = Object.keys(priceSummary);
  return productKeys.sort((prod1, prod2) => {
    // default to infinity to push noncovered keys last and satisfy TS
    const prod1Order = priceSummarySortOrder[prod1] || Infinity;
    const prod2Order = priceSummarySortOrder[prod2] || Infinity;

    if (prod1Order < prod2Order) {
      return -1;
    }
    return prod2Order < prod1Order ? 1 : 0;
  });
};
