import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Loader, Modal, Stack, Text } from '@mantine/core';

import { CREATED, PARTIALLY_SAMPLED } from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { getFieldGeometry } from 'store/fields/thunks';
import { putSamplePlan } from 'store/samplePlans/requests';
import { SamplePlanType } from 'store/samplePlans/types';

type MarkReadyPropsType = {
  samplePlanId: number;
  samplePlans?: SamplePlanType[];
  fieldId: number;
  onClose: () => void;
  opened: boolean;
};

const MarkReady = ({ samplePlans, fieldId, samplePlanId, onClose, opened }: MarkReadyPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [isLoading, toggleIsLoading] = useState(false);
  const planIds = samplePlans
    ? samplePlans
        .filter((plan) => [PARTIALLY_SAMPLED, CREATED].includes(plan.sampling_status))
        .map((plan) => plan.id)
    : [samplePlanId];

  const submit = useCallback(async () => {
    const body = {
      ready_to_sample: true,
    };
    try {
      toggleIsLoading(true);
      await Promise.all(planIds.map((id) => putSamplePlan(body, id)));
      showToast(getString('markedReadySuccessMsg', language));
    } catch (error) {
      showToast(getString('markedReadyFailureMsg', language), 'error');
    } finally {
      dispatch(getFieldGeometry(fieldId));
      toggleIsLoading(false);
    }
    onClose();
  }, [dispatch, samplePlanId, fieldId, onClose]);

  return (
    <Modal onClose={onClose} opened={opened} title={getString('markReady', language)} centered>
      <Stack>
        <Text>{getString('markReadyMsg', language)}</Text>
        <Group justify="flex-end">
          {isLoading && <Loader size="sm" />}
          <Button
            onClick={submit}
            data-test-id="confirmation-mark-ready-button"
            disabled={isLoading}
          >
            {getString('markReady', language)}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default MarkReady;
