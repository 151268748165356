import React, { Children, ReactNode, useState } from 'react';
import { AccordionChevron, ActionIcon, Collapse, Table, TableTrProps } from '@mantine/core';

import styles from './CollapsibleRow.module.css';

type CollapsibleRowPropsType = TableTrProps & {
  collapseComponent: ReactNode;
  children?: ReactNode;
  clickableRow?: boolean;
  disabled?: boolean;
};

export const CollapsibleRow = ({
  collapseComponent,
  children,
  clickableRow = true,
  disabled,
  ...props
}: CollapsibleRowPropsType) => {
  const [showDetails, toggleShowDetails] = useState(false);

  return (
    <>
      <Table.Tr
        {...props}
        pos="relative"
        className={clickableRow ? styles.Clickable : undefined}
        onClick={() => clickableRow && toggleShowDetails(!showDetails)}
      >
        {children}
        <Table.Td maw="3rem" miw="3rem" w="3rem" hidden={disabled}>
          <ActionIcon
            p="0"
            m="0"
            variant="subtle"
            onClick={() => toggleShowDetails(!showDetails)}
            disabled={disabled}
          >
            <AccordionChevron
              style={{
                transition: 'transform 0.3s ease',
                transform: `rotate(${showDetails ? 180 : 0}deg)`,
              }}
            />
          </ActionIcon>
        </Table.Td>
      </Table.Tr>
      <Table.Tr p="0" m="0" style={!showDetails ? { borderBottom: 0 } : undefined}>
        <Table.Td p="0" m="0" colSpan={Children.count(children) + 1}>
          <Collapse in={showDetails} bg="gray">
            {collapseComponent}
          </Collapse>
        </Table.Td>
      </Table.Tr>
    </>
  );
};
