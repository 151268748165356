import React from 'react';
import { useDispatch } from 'react-redux';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestPostUploadIngestFile } from 'store/plates/requests';
import { getPlate } from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { FileUploadButton } from 'common';

type ResultsUploadPropsType = {
  plate: PlateType;
};

export const ResultsUpload = ({ plate }: ResultsUploadPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const uploadDocument = async (file: File) => {
    try {
      const response = await requestPostUploadIngestFile(plate.barcode, file);
      if (response.msg) {
        showToast(response.msg, 'error');
      } else {
        showToast(getString('uploadSuccessMsg', language));
      }
      dispatch(getPlate(plate.barcode));
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  return (
    <FileUploadButton
      buttonLabel={getString('uploadResults', language)}
      onUpload={uploadDocument}
      accept=".csv"
    />
  );
};
