import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CloseButton, Combobox, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';

import useBroswerLanguage from 'util/hooks/useLanguage';
import sortByName from 'util/sortByName';
import { capitalizeEveryWord } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { Agency } from 'store/agencies/types';
import { requestUserAgencySearch } from 'store/user/requests';
import { User, UserAgencySelectorType } from 'store/user/types';

type OperationSearchBarPropsType = {
  selected: { id: string; displayName: string } | null;
  onSelect: (val: UserAgencySelectorType | null) => void;
  onDeselect: () => void;
  placeholder?: string;
  className?: string;
  toggleIsSearching: (arg0: boolean) => void;
  isAgencyOnly?: boolean;
  hasCreateNewAgency?: boolean;
  maxHeight?: number;
  ariaLabel?: string;
};

export const UserAgencySearch = ({
  selected,
  onSelect,
  onDeselect,
  placeholder,
  className,
  toggleIsSearching,
  isAgencyOnly,
  hasCreateNewAgency,
  maxHeight,
  ariaLabel,
}: OperationSearchBarPropsType) => {
  const language = useBroswerLanguage();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [localSelected, setLocalSelected] = useState<string>('');
  const [loaded, setLoaded] = useState(false);

  const createAgencyOption = {
    id: String(0),
    displayName: getString('createNewAgency', language),
  } as UserAgencySelectorType;

  const [selectorOptions, setSelectorOptions] = useState<UserAgencySelectorType[]>(
    hasCreateNewAgency ? [createAgencyOption] : [],
  );

  const debounceFetchUserAndAgencies = useDebouncedCallback(
    async (name: string) => {
      if (name) {
        toggleIsSearching(true);
        const usersAndAgencies = await requestUserAgencySearch(name);

        const users = usersAndAgencies.users.map((user: User) => {
          const role = user.roles[0];
          const roleName = capitalizeEveryWord(role.name.replace('_', ' '));

          return {
            user_id: user.id,
            agency_id: null,
            agency_primary_id: null,
            displayName: `${roleName}: ${user.first_name} ${user.last_name}`,
            id: user.id,
          };
        });

        const agencies = usersAndAgencies.agencies.map((agency: Agency) => ({
          user_id: null,
          agency_id: agency.id,
          agency_primary_id: agency.primary_user.id,
          displayName: `${getString(agency.hierarchy, language)}: ${agency.name}`,
          id: `agency-${agency.id}`,
        }));
        // if hasCreateNewAgency, assuming is agency only search
        const agencyUserList = isAgencyOnly
          ? [createAgencyOption, ...sortByName(agencies)]
          : sortByName([...users, ...agencies]);
        if (isAgencyOnly && !hasCreateNewAgency) {
          agencyUserList.shift();
        }
        setSelectorOptions(agencyUserList);
        toggleIsSearching(false);
      }
    },
    500,
    { trailing: true },
  );

  useEffect(() => {
    debounceFetchUserAndAgencies(localSelected);
  }, [debounceFetchUserAndAgencies, localSelected]);

  useEffect(() => {
    if (!localSelected && selectorOptions.length && !loaded) {
      setLoaded(true);
      if (selected) {
        setLocalSelected(selected?.displayName);
      }
    }
  }, [selectorOptions, selected, loaded]);

  useEffect(() => {
    if (selected && !localSelected) {
      setLocalSelected(selected.displayName);
    }
  }, [selected, localSelected]);

  const handleUserAgencySelection = (str: string) => {
    const userAgency = selectorOptions.find((o) => o.displayName === str);
    if (userAgency) {
      onSelect(userAgency);
      setLocalSelected(userAgency?.displayName);
    }
  };

  const handleTextChange = (event: any) => {
    setLocalSelected(event.currentTarget.value);
    debounceFetchUserAndAgencies(event.currentTarget.value);
    combobox.resetSelectedOption();
    combobox.openDropdown();
  };

  const clearSelected = () => {
    setLocalSelected('');
    onSelect(null);
    combobox.closeDropdown();
    onDeselect && onDeselect();
  };

  const options = (selectorOptions || []).map((item) => (
    <Combobox.Option value={item.displayName} key={item.id}>
      {item.displayName}
    </Combobox.Option>
  ));

  const RightSelect = () => {
    if (selected) {
      return (
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={clearSelected}
        />
      );
    }
    return null;
  };

  return (
    <Combobox onOptionSubmit={handleUserAgencySelection} withinPortal={false} store={combobox}>
      <Combobox.Target>
        <TextInput
          className={className}
          placeholder={placeholder}
          value={localSelected}
          leftSection={<FiSearch />}
          onChange={handleTextChange}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          rightSection={<RightSelect />}
          aria-label={ariaLabel}
        />
      </Combobox.Target>

      <Combobox.Dropdown
        mah={maxHeight || 300}
        style={{ overflowY: 'auto' }}
        hidden={!selectorOptions.length}
        onClick={() => combobox.closeDropdown()}
      >
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
