import { MantineSpacing, StyleProp, Text } from '@mantine/core';

type Props = {
  text: string;
  mb?: StyleProp<MantineSpacing>;
};

const FormHeading = ({ text, mb = 'md' }: Props) => {
  return (
    <Text component="h2" size="xl" fw="bold" mb={mb}>
      {text}
    </Text>
  );
};

export default FormHeading;
