import { renderToStaticMarkup } from 'react-dom/server';

import { PH_COLORS, RISK_FILL_COLORS } from 'constants/colors';
import { HIGH_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { MapMarkers } from 'constants/mapbox';
import {
  COMPACTION_PSI_HIGH,
  COMPACTION_PSI_LOW,
  RX_INCH_HIGH,
  RX_INCH_LOW,
  TILL_RX_PROPERTIES_KEY,
  TILL_TEST_RESULTS_PROPERTIES_KEY,
} from 'constants/proMaps';
import { COMPACTION_ID } from 'constants/results';

import { getString } from 'strings/translation';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { SamplePlanTrackingType } from 'store/samplePlans/types';

import { roundToNPlaces } from './numUtils';
import { getIsPh } from './results';
import { hasNoProducts } from './samplePlanTracking';

export const isFullyAssigned = (samplePlan: SamplePlanTrackingType) => {
  const isProOrTillRx = samplePlan.is_pro || samplePlan.is_till_rx;
  if (samplePlan.is_till_rx && hasNoProducts(samplePlan)) {
    return true;
  }
  if (isProOrTillRx && samplePlan.assigned_to_id && samplePlan.assigned_to_scan_id) {
    return true;
  }
  return !isProOrTillRx && samplePlan.assigned_to_id;
};

export const getMapPinColor = (samplePlan: SamplePlanTrackingType) => {
  if (samplePlan.alerts.length && samplePlan.ready_to_sample) {
    return MapMarkers.DARK_RED_MARKER.name;
  }
  if (samplePlan.alerts.length) {
    return MapMarkers.LIGHT_RED_MARKER.name;
  }
  if (isFullyAssigned(samplePlan)) {
    return MapMarkers.DARK_BLUE_MARKER.name;
  }
  if (samplePlan.ready_to_sample) {
    return MapMarkers.DARK_YELLOW_MARKER.name;
  }
  return MapMarkers.LIGHT_YELLOW_MARKER.name;
};

export const getPlanTrackingLegendIcons = (language: string) => [
  {
    name: getString('alertsAndReady', language),
    icon: MapMarkers.DARK_RED_MARKER.image,
  },
  {
    name: getString('alertsAndNotReady', language),
    icon: MapMarkers.LIGHT_RED_MARKER.image,
  },
  {
    name: getString('fullyAssigned', language),
    icon: MapMarkers.DARK_BLUE_MARKER.image,
  },
  {
    name: getString('ready', language),
    icon: MapMarkers.DARK_YELLOW_MARKER.image,
  },
  {
    name: getString('default', language),
    icon: MapMarkers.LIGHT_YELLOW_MARKER.image,
  },
];

export const getMapPinColorRiskLevels = (riskLevel: string) => {
  if (riskLevel === HIGH_RISK) {
    return MapMarkers.HIGH_RISK_MARKER.name;
  }
  if (riskLevel === MODERATE_RISK) {
    return MapMarkers.MODERATE_RISK_MARKER.name;
  }
  return MapMarkers.LOW_RISK_MARKER.name;
};

export const getMapLegendColorStops = (highestRiskOrder: string, analyticId: number) => {
  if (getIsPh(analyticId)) {
    return [PH_COLORS.low, PH_COLORS.mid, PH_COLORS.high];
  }

  if (highestRiskOrder === RISK_FILL_COLORS.LOW_RISK || analyticId === COMPACTION_ID) {
    return [RISK_FILL_COLORS.LOW_RISK, RISK_FILL_COLORS.MODERATE_RISK, RISK_FILL_COLORS.HIGH_RISK];
  }

  return [RISK_FILL_COLORS.HIGH_RISK, RISK_FILL_COLORS.MODERATE_RISK, RISK_FILL_COLORS.LOW_RISK];
};

export const getCompactionTestResultsFillColor = (
  {
    high,
    low,
  }: {
    high: number;
    low: number;
  } = {
    high: COMPACTION_PSI_HIGH,
    low: COMPACTION_PSI_LOW,
  },
): mapboxgl.Expression => {
  const mid = (high - low) / 2 + low;

  return [
    'interpolate',
    ['linear'],
    ['get', TILL_TEST_RESULTS_PROPERTIES_KEY],
    low,
    RISK_FILL_COLORS.LOW_RISK,
    mid,
    RISK_FILL_COLORS.MODERATE_RISK,
    high,
    RISK_FILL_COLORS.HIGH_RISK,
  ];
};

export const getCompactionRxFillColor = (): mapboxgl.Expression => [
  'interpolate',
  ['linear'],
  ['get', TILL_RX_PROPERTIES_KEY],
  RX_INCH_LOW,
  RISK_FILL_COLORS.LOW_RISK,
  RX_INCH_HIGH / 2,
  RISK_FILL_COLORS.MODERATE_RISK,
  RX_INCH_HIGH,
  RISK_FILL_COLORS.HIGH_RISK,
];

export const getLegendTitle = (
  analyticId: number,
  isCompactionTestResults: boolean,
  isRx: boolean,
  planAnalyticUnit = '',
  inferenceLayer?: EOInferenceLayerType | null,
) => {
  if (getIsPh(analyticId)) {
    return 'pH';
  }

  if (isRx) {
    return 'in';
  }

  const isCompaction = analyticId === COMPACTION_ID;

  if (isCompaction && inferenceLayer && isCompactionTestResults) {
    return inferenceLayer.layer_param?.replace(/_/g, ' ') || '';
  }

  return planAnalyticUnit;
};

/**
 * Get a reversed legend-friendly array of numbers based on the high and low values. The function
 * will keep retrying itself until a unique set of numbers is generated.
 *
 * @param high Highest value
 * @param low Lowest value
 * @param numberOfDecimals How many decimals to round to. Default: `0`
 * @param numberOfMarks How many marks to generate. Default: `7`
 * @returns Reversed array of numbers based on the high and low values
 */
export const getLegendMarks = (
  high: number,
  low: number,
  numberOfDecimals = 0,
  numberOfMarks = 7,
): number[] => {
  const step = (high - low) / (numberOfMarks - 1);

  const marks = Array.from({ length: numberOfMarks }, (_, i) => {
    const value = low + i * step;

    return roundToNPlaces(numberOfDecimals)(value) || value;
  });

  const numberOfUniqueMarks = new Set(marks).size;
  const numbersAreNotUnique = numberOfUniqueMarks < numberOfMarks;

  if (numbersAreNotUnique) {
    // Keep increasing the number of decimals until we have unique numbers
    return getLegendMarks(high, low, numberOfDecimals + 1);
  }

  marks.reverse();

  return marks;
};

// CRED: https://github.com/mapbox/mapbox-gl-js/issues/5529#issuecomment-503799896
const svgPathToImage = (path: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image(36, 36);

    image.addEventListener('load', () => resolve(image));
    image.src = path;
  });
};

/**
 * Convert a React SVG element to an inline image, e.g. for use in Mapbox.
 * @param icon SVG element
 * @returns Promise<HTMLImageElement>
 */
export const symbolAsInlineImage = (icon: React.ReactElement): Promise<HTMLImageElement> => {
  const svg = renderToStaticMarkup(icon);
  const encoded = window.btoa(svg);

  return svgPathToImage(`data:image/svg+xml;charset=utf-8;base64,${encoded}`);
};
