import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Modal, Stack, Text, Title } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { CatalogType } from 'store/catalogs/types';
import { refreshFields } from 'store/fields/thunks';
import { updateOperation } from 'store/operation/thunks';

type OperationCatalogModalPropsType = {
  catalog: CatalogType;
  onClose: () => void;
  opened: boolean;
  operationId: number;
};

export const OperationCatalogModal = ({
  catalog,
  onClose,
  opened,
  operationId,
}: OperationCatalogModalPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      await dispatch(updateOperation({ id: operationId, catalog_id: catalog.id }));
      dispatch(refreshFields());
    } catch (e) {
      showToast(getString('serverErrorMsg', language), 'error');
    }
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} centered size="xl">
      <Stack align="center" gap="lg" pb="md">
        <Title order={2} fw="bold">
          {`${getString('catalog', language)}: ${catalog.name}`}
        </Title>
        <Text>{getString('overrideInputsMsg', language)}</Text>
        <Group pt="lg">
          <Button onClick={onClose} color="red.8">
            {getString('cancel', language)}
          </Button>
          <Button onClick={handleSubmit}>{getString('save', language)}</Button>
        </Group>
      </Stack>
    </Modal>
  );
};
