import { useState } from 'react';
import { Modal, Space, Stack, Text } from '@mantine/core';
import OrderSummary from 'apps/ZoneAnalysisV3/Components/PlanSteps/OrderSummary/OrderSummary';
import SamplingExecution from 'apps/ZoneAnalysisV3/Components/PlanSteps/OrderSummary/SamplingExecution';

import { ANALYSIS, FIELD_WORK, LIST_PRICE } from 'constants/pricing';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { OrderSummaryMode, PriceSummary, PriceType, TypeOfSku } from 'store/pricing/types';

type Props = {
  field: FieldType;
  confirmationValues: SamplingPlanType;
  setConfirmationValues: (values: null) => void;
};

const ConfirmationModalV2 = ({ field, confirmationValues, setConfirmationValues }: Props) => {
  const language = useBroswerLanguage();

  const [mode, setMode] = useState<OrderSummaryMode>(LIST_PRICE);
  const isListPrice = mode === LIST_PRICE;
  const { acreage } = field.features[0].properties;

  const estimates = confirmationValues.sampling_plan_skus?.reduce(
    (total: { estimatedListPrice: number; estimatedPrice: number }, planSku) => {
      const { summary_json, is_not_billed } = planSku;
      const planSkuListPrice = summary_json.total_list_price || 0;
      const planSkuPrice = is_not_billed ? 0 : summary_json.total_price || 0;
      total.estimatedListPrice += planSkuListPrice;
      total.estimatedPrice += planSkuPrice;
      return total;
    },
    {
      estimatedListPrice: 0,
      estimatedPrice: 0,
    },
  );

  const displayedProducts = confirmationValues.sampling_plan_skus?.reduce(
    (acc: { analysis: PriceSummary[]; fieldWork: PriceSummary[] }, product) => {
      if (!product.is_not_billed || isListPrice) {
        const key = product.sku.type === ANALYSIS ? ANALYSIS : FIELD_WORK;
        acc[key].push({
          sku_id: product.sku.id,
          acres: product.summary_json.acres,
          density: product.summary_json.density,
          list_price: product.summary_json.list_price,
          price: product.summary_json.price,
          total_list_price: product.summary_json.total_list_price,
          total_price: product.summary_json.total_price,
          is_not_billed: product.is_not_billed,
          sku_type: product.sku.type as TypeOfSku,
          sku_name: product.sku.name,
          price_type: product.summary_json.price_type as PriceType,
          zones: product.summary_json.zone_count,
          is_custom_analysis: false,
        });
      }
      return acc;
    },
    { analysis: [], fieldWork: [] },
  );

  return (
    <Modal
      opened={!!confirmationValues}
      onClose={() => setConfirmationValues(null)}
      size="50rem"
      centered
    >
      <Stack align="center">
        <Text fz="h2" fw="bold" component="h2" tt="capitalize">
          {getString('reviewOrder', language)}
        </Text>
        {!!estimates && !!displayedProducts && (
          <OrderSummary
            estimatedListPrice={estimates.estimatedListPrice}
            estimatedListPricePerAcre={estimates.estimatedListPrice / acreage}
            estimatedPrice={estimates.estimatedPrice}
            estimatedPricePerAcre={estimates.estimatedPrice / acreage}
            displayedProducts={displayedProducts}
            mode={mode}
            setMode={setMode}
          />
        )}
        <Space />
        <SamplingExecution
          sampleTiming={confirmationValues.sample_timing || null}
          sampledById={confirmationValues.assigned_to_id}
          scannedById={confirmationValues.assigned_to_scan_id}
          assigned_user_name={confirmationValues.assigned_user_name}
          assigned_scan_user_name={confirmationValues.assigned_scan_user_name}
          isViewOnly
        />
      </Stack>
    </Modal>
  );
};

export default ConfirmationModalV2;
