import { AppThunk } from 'store';

import { receiveTimings, requestTimings, timingsRequestError } from './actions';
import { requestGetTimings } from './requests';

export const getSamplingTimings = (): AppThunk => async (dispatch) => {
  dispatch(requestTimings());
  try {
    const response = await requestGetTimings();
    return dispatch(receiveTimings(response));
  } catch (e) {
    return dispatch(timingsRequestError());
  }
};
