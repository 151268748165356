import { Flex, Select, Stack, Switch, Text, TextInput } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useAllAgenciesQuery } from 'apps/ZoneAnalysisV3/queries';

import { agenciesAllowedForSamplePlan } from 'util/agency';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { OperationType } from 'store/operation/types';
import { OrderForm } from 'store/pricing/types';
import { AdminAccess, SuperAdminAccess } from 'common';

type Props = {
  operation: OperationType;
};

const agencyFormKey: keyof OrderForm = 'agencyId';

const SamplePlanDetailsSection = ({ operation }: Props) => {
  const language = useBroswerLanguage();
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const { data: operationAgencies } = useAllAgenciesQuery();

  const agencyOptions = agenciesAllowedForSamplePlan(
    operation,
    operationAgencies?.agencies || [],
  ).map((agency) => ({
    id: agency.id,
    label: agency.name,
    value: String(agency.id),
  }));

  return (
    <Stack maw={400} flex={1} mb="lg">
      <Text fz="h4" fw="bold" component="h2">
        {getString('samplePlanDetails', language)}
      </Text>
      <Flex
        columnGap="xl"
        rowGap="md"
        display="grid"
        align="center"
        style={{
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Text>{getString('name', language)}:</Text>
        <TextInput
          key={form.key('samplePlanName')}
          aria-label={getString('name', language)}
          {...form.getInputProps('samplePlanName')}
        />
        <AdminAccess>
          <Text>RND:</Text>
          <Switch
            key={form.key('rnd')}
            size="md"
            aria-label="RND"
            {...form.getInputProps('rnd', {
              type: 'checkbox',
            })}
          />
        </AdminAccess>
        <SuperAdminAccess>
          <Text>Do Not Bill:</Text>
          <Switch
            key={form.key('isNotBilled')}
            size="md"
            {...form.getInputProps('isNotBilled', {
              type: 'checkbox',
            })}
          />
        </SuperAdminAccess>
        {agencyOptions.length > 0 && (
          <>
            <Text>{getString('agency', language)}:</Text>
            <Select
              key={form.key(agencyFormKey)}
              {...form.getInputProps(agencyFormKey)}
              value={String(formValues[agencyFormKey])}
              aria-label={getString('agency', language)}
              data={agencyOptions}
              w="14rem"
            />
          </>
        )}
      </Flex>
    </Stack>
  );
};

export default SamplePlanDetailsSection;
