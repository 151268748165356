import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import { DEFAULT_MC_DENSITY_STR, METER_CORE, REGEN_STANDARD } from 'constants/projects';
import { projectsRoutes } from 'constants/routes';

import { usePageTitle } from 'util/hooks/usePageTitle';
import { projectsQueryKeys } from 'util/queryKeys';
import { carbonProjectQueryOptions } from 'util/queryOptions';
import { getDefaultSamplingPlanConfig } from 'store/projects/requests';
import { SamplePlanForm } from 'store/projects/types';
import { Header } from 'common';
import FullHeightLayoutFooter from 'common/FullHeightLayoutFooter';
import FullHeightLayoutWrap from 'common/FullHeightLayoutWrap';

import { useProjectRouteParams, useProjectsTranslations } from '../hooks';

import LabsSection from './FormInputs/LabsSection';
import MeterCoreDetailsSection from './FormInputs/MeterCoreDetailsSection';
import RegistrySection from './FormInputs/RegistrySection';
import SoilTextureSection from './FormInputs/SoilTextureSection';
import StratificationSection from './FormInputs/StratificationSection';
import CarbonPlanOrderConfModal from './CarbonPlanOrderConfModal';
import { SamplePlanFormProvider, useSamplePlanForm } from './samplePlanFormContext';

const CreateSamplePlanContainer = () => {
  const translations = useProjectsTranslations();
  const { projectId } = useProjectRouteParams();
  const pageTitle = translations.createSamplingPlan;
  const { data: project } = useQuery(carbonProjectQueryOptions(projectId));
  const [opened, { open, close }] = useDisclosure(false);

  const { data: defaultSamplingPlanConfig } = useQuery({
    queryKey: projectsQueryKeys.defaultSamplingPlanConfig,
    queryFn: getDefaultSamplingPlanConfig,
  });

  usePageTitle(pageTitle);

  const form = useSamplePlanForm({
    mode: 'uncontrolled',
    initialValues: {
      registry: 'Regen Standard',
      defaultLab: 'EarthOptics Memphis',
      hasMeterCoreDetails: true,
      density: DEFAULT_MC_DENSITY_STR,
      isSplitCore: false,
      splits: [],
      analytics: [],
      lab: 'EarthOptics Memphis',
      stratify: true,
      minMeterCoreSamplesPerStratum: 0,
      hasTexture: false,
      textureDensity: null,
    },
  });

  useEffect(() => {
    if (!defaultSamplingPlanConfig) {
      return;
    }

    const defaultConfig = defaultSamplingPlanConfig[REGEN_STANDARD];
    const deafultMeasurementInfo = defaultConfig.measurement_type_info[METER_CORE];
    const isCarProtocol = form.getValues().registry === 'CAR';

    const newValues: Partial<SamplePlanForm> = {
      minMeterCoreSamplesPerStratum: defaultConfig.min_samples_per_strata,
      lab: deafultMeasurementInfo.lab_name,
      analytics: deafultMeasurementInfo.test_names,
      textureDensity: isCarProtocol ? '3 per stratum' : null,
    };

    form.setValues(newValues);
    form.resetDirty();
  }, [defaultSamplingPlanConfig]);

  return (
    <SamplePlanFormProvider form={form}>
      <FullHeightLayoutWrap>
        <Header
          title={pageTitle}
          breadcrumbsItems={[
            {
              title: translations.allProjects,
              to: projectsRoutes.routes.base,
            },
            {
              title: `${translations.project}${project?.name ? `: ${project.name}` : ''}`,
              to: projectsRoutes.routes.project(projectId),
            },
            {
              title: translations.createSamplingPlan,
            },
          ]}
        />
        <Stack
          pb="xl"
          px="xl"
          flex={1}
          h="100%"
          style={{ overflowY: 'auto' }}
          gap="xl"
          maw={700}
          mx="auto"
        >
          <RegistrySection defaultConfig={defaultSamplingPlanConfig} />
          <LabsSection defaultConfig={defaultSamplingPlanConfig} />
          <MeterCoreDetailsSection defaultConfig={defaultSamplingPlanConfig} />
          <SoilTextureSection defaultConfig={defaultSamplingPlanConfig} />
          <StratificationSection />
        </Stack>
        <FullHeightLayoutFooter>
          <div />
          <Group>
            <Button variant="outline" component={Link} to={projectsRoutes.routes.base}>
              {translations.cancel}
            </Button>
            <Button onClick={open}>{translations.generateSamplingPlan}</Button>
          </Group>
        </FullHeightLayoutFooter>
      </FullHeightLayoutWrap>
      <CarbonPlanOrderConfModal
        projectId={projectId}
        onClose={close}
        opened={opened}
        form={form}
        fieldIds={project?.fields.map((field) => field.id) || []}
      />
    </SamplePlanFormProvider>
  );
};

export default CreateSamplePlanContainer;
