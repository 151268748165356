import { useQuery } from '@tanstack/react-query';
import center from '@turf/center';

import { projectsRoutes } from 'constants/routes';

import { usePageTitle } from 'util/hooks/usePageTitle';
import { projectsQueryKeys } from 'util/queryKeys';
import { carbonProjectQueryOptions } from 'util/queryOptions';
import { getCarbonSamples } from 'store/projects/requests';
import { Header } from 'common';
import CenteredLoader from 'common/CenteredLoader';
import ErrorAlert from 'common/ErrorAlert';
import FullHeightLayoutFooter from 'common/FullHeightLayoutFooter';
import FullHeightLayoutWrap from 'common/FullHeightLayoutWrap';

import { useProjectRouteParams, useProjectsTranslations } from '../hooks';

import CarbonSamplingPlanMap from './CarbonSamplingPlanMap';

const SamplePlanContainer = () => {
  const { samplingPlanId, fieldId, projectId } = useProjectRouteParams();
  const translations = useProjectsTranslations();
  const pageTitle = `${translations.samplingPlan} ${samplingPlanId}`;
  const projectQuery = useQuery(carbonProjectQueryOptions(projectId));

  const samplesQuery = useQuery({
    queryKey: projectsQueryKeys.samples(samplingPlanId),
    queryFn: () => getCarbonSamples(samplingPlanId),
    select: (data) => {
      return data
        .filter((feature) => feature.properties.field_id === fieldId)
        .map((feature) => center(feature, { properties: feature.properties }));
    },
  });

  usePageTitle(pageTitle);

  return (
    <FullHeightLayoutWrap>
      <Header
        title={pageTitle}
        breadcrumbsItems={[
          {
            title: translations.allProjects,
            to: projectsRoutes.routes.base,
          },
          { title: projectQuery.data?.name || '', to: projectsRoutes.routes.project(projectId) },
          { title: `${translations.samplingPlan} ${samplingPlanId}` },
        ]}
      />
      {(samplesQuery.isPending || projectQuery.isPending) && <CenteredLoader />}
      {samplesQuery.error && <ErrorAlert content={samplesQuery.error.message} />}
      {projectQuery.error && <ErrorAlert content={projectQuery.error.message} />}
      {samplesQuery.data && projectQuery.data && (
        <CarbonSamplingPlanMap
          sampleCentroidsFeatures={samplesQuery.data}
          projectFieldIds={projectQuery.data.fields.map((field) => field.id)}
          projectQueryIsLoading={projectQuery.isPending}
        />
      )}
      <FullHeightLayoutFooter />
    </FullHeightLayoutWrap>
  );
};

export default SamplePlanContainer;
