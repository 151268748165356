import React, { useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiSearch, FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { Box, Button, Center, Group, Input, Paper, Popover, Stack, Text } from '@mantine/core';

import { CORN } from 'constants/variables';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { receiveAnalyticSortOrder } from 'store/analytics/actions';
import { putAnalyticCrop } from 'store/analytics/requests';
import { AnalyticSortOrderType, AnalyticType } from 'store/analytics/types';
import { Typeahead } from 'common/Components/Mantine/Typeahead';

interface Props {
  cropType: string;
  analyticCategory: string;
  analyticSortOrder: AnalyticSortOrderType;
  tempCropAnalyticsOrder: AnalyticSortOrderType;
  allAnalytics: AnalyticType[];
  setCropAnalyticsOrder: (order: AnalyticSortOrderType | null) => void;
}

const AnalyticOrderRow = ({
  cropType,
  analyticCategory,
  analyticSortOrder,
  tempCropAnalyticsOrder,
  allAnalytics,
  setCropAnalyticsOrder,
}: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const [analyticText, setAnalyticText] = useState<string>('');

  const updateAnalyticOrder = async (analyticIds: number[], crop: string, category: string) => {
    try {
      const analyticsWithOrder = analyticIds.reduce(
        (acc, analyticId, index) => ({ ...acc, [analyticId]: index + 1 }),
        {},
      );
      const newSortedAnalytics: AnalyticSortOrderType = await putAnalyticCrop(
        analyticsWithOrder,
        crop,
        category,
      );
      dispatch(receiveAnalyticSortOrder(newSortedAnalytics));
      setCropAnalyticsOrder(newSortedAnalytics);
      showToast(getString('analyticOrderUpdated', language));
    } catch (err) {
      showToast('Error updating analytic order. Refresh and try again.', 'error');
    }
  };

  const addToAnalyticOrder = (category: string, crop: string, analyticId: number) => {
    if (analyticId) {
      setAnalyticText('');
      setCropAnalyticsOrder({
        ...tempCropAnalyticsOrder,
        [category]: {
          ...(tempCropAnalyticsOrder || {})[category],
          [crop]: [...(tempCropAnalyticsOrder || {})[category][crop], analyticId],
        },
      });
    }
  };

  const moveAnalytic = (category: string, crop: string, analyticId: number, moveLeft: boolean) => {
    if (tempCropAnalyticsOrder) {
      const currentOrder = tempCropAnalyticsOrder[category][crop];
      const currentIndex = currentOrder.indexOf(analyticId);
      const newIndex = moveLeft ? currentIndex - 1 : currentIndex + 1;

      const newOrder = [...currentOrder];
      newOrder.splice(currentIndex, 1);
      newOrder.splice(newIndex, 0, analyticId);

      setCropAnalyticsOrder({
        ...tempCropAnalyticsOrder,
        [category]: {
          ...(tempCropAnalyticsOrder || {})[category],
          [crop]: newOrder,
        },
      });
    }
  };

  const removeAnalytic = (category: string, crop: string, analyticId: number) => {
    if (tempCropAnalyticsOrder) {
      const newOrder = tempCropAnalyticsOrder[category][crop].filter((id) => id !== analyticId);
      setCropAnalyticsOrder({
        ...tempCropAnalyticsOrder,
        [category]: {
          ...(tempCropAnalyticsOrder || {})[category],
          [crop]: newOrder,
        },
      });
    }
  };

  const copyFromCorn = (category: string, crop: string) => {
    if (tempCropAnalyticsOrder) {
      setCropAnalyticsOrder({
        ...tempCropAnalyticsOrder,
        [category]: {
          ...(tempCropAnalyticsOrder || {})[category],
          [crop]: tempCropAnalyticsOrder[category][CORN],
        },
      });
    }
  };

  const displayedAnalytics = allAnalytics
    .filter(
      (analytic) =>
        Boolean(analytic?.untranslated_name) &&
        analytic.show_in_ui &&
        !tempCropAnalyticsOrder[analyticCategory][cropType].includes(analytic.id),
    )
    .map((analytic) => ({
      id: analytic.id,
      label: analytic.untranslated_name || '',
      value: analytic.id,
    }));

  const isFirstItem = (list: number[], id: number) => list.indexOf(id) === 0;
  const isLastItem = (list: number[], id: number) => list.indexOf(id) === list.length - 1;
  return (
    <Stack key={cropType} gap="sm" pl="xl">
      <Text>{getString(cropType, language)}</Text>
      <Group align="flex-end">
        {tempCropAnalyticsOrder[analyticCategory][cropType].map((analyticId) => (
          <Stack key={analyticId}>
            <Group justify="space-between" pl="sm" pr="sm">
              <Box>
                {!isFirstItem(tempCropAnalyticsOrder[analyticCategory][cropType], analyticId) && (
                  <FiArrowLeft
                    onClick={() => moveAnalytic(analyticCategory, cropType, analyticId, true)}
                  />
                )}
              </Box>
              <Box>
                {!isLastItem(tempCropAnalyticsOrder[analyticCategory][cropType], analyticId) && (
                  <FiArrowRight
                    onClick={() => moveAnalytic(analyticCategory, cropType, analyticId, false)}
                  />
                )}
              </Box>
            </Group>
            <Paper shadow="xs" withBorder>
              <Center h="3rem" w="7rem" p="xs" ta="center">
                <Text size="xs">
                  {
                    allAnalytics.find((analytic) => analytic.id === Number(analyticId))
                      ?.untranslated_name
                  }
                </Text>
                <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <Box w="1rem" h="1rem">
                      <FiX color="darkRed" />
                    </Box>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Stack>
                      <Text size="sm">{getString('removeAnalyticFromView', language)}</Text>
                      <Button
                        onClick={() => removeAnalytic(analyticCategory, cropType, analyticId)}
                        color="darkRed"
                      >
                        {getString('remove', language)}
                      </Button>
                    </Stack>
                  </Popover.Dropdown>
                </Popover>
              </Center>
            </Paper>
          </Stack>
        ))}
        <Group align="flex-end">
          <Input.Wrapper label={getString('addAnalytic', language)}>
            <Typeahead
              onTextChange={(val) => setAnalyticText(val)}
              onSelect={(id) => addToAnalyticOrder(analyticCategory, cropType, id)}
              value={analyticText}
              onDeselect={() => setAnalyticText('')}
              data={displayedAnalytics}
              placeholder={'Search Analytics'}
              leftSection={<FiSearch />}
              w="15rem"
            />
          </Input.Wrapper>
          {cropType !== CORN && !tempCropAnalyticsOrder[analyticCategory][cropType].length && (
            <Button variant="outline" onClick={() => copyFromCorn(analyticCategory, cropType)}>
              {getString('copyFromCorn', language)}
            </Button>
          )}
          {analyticSortOrder[analyticCategory][cropType] !==
            tempCropAnalyticsOrder[analyticCategory][cropType] && (
            <Button
              onClick={() =>
                updateAnalyticOrder(
                  tempCropAnalyticsOrder[analyticCategory][cropType],
                  cropType,
                  analyticCategory,
                )
              }
            >
              {getString('save', language)}
            </Button>
          )}
        </Group>
      </Group>
    </Stack>
  );
};

export default AnalyticOrderRow;
