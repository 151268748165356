import {
  BATCH_REQUEST_ERROR,
  BatchActionsType,
  BatchType,
  BatchUploadedFileType,
  RECEIVE_BATCH,
  RECEIVE_BATCH_FILES,
  RECEIVE_BATCHES,
  REQUEST_BATCHES,
} from './types';

export const requestBatches = (): BatchActionsType => ({
  type: REQUEST_BATCHES,
});

export const receiveBatches = (payload: {
  page: number;
  total: number;
  per_page: number;
  items: BatchType[];
  fetchRequestNumber: number;
}): BatchActionsType => {
  return {
    type: RECEIVE_BATCHES,
    payload,
  };
};

export const receiveBatch = (batch: BatchType): BatchActionsType => {
  return {
    type: RECEIVE_BATCH,
    payload: batch,
  };
};

export const receiveBatchFiles = (id: number, files: BatchUploadedFileType[]): BatchActionsType => {
  return {
    type: RECEIVE_BATCH_FILES,
    payload: { id, files },
  };
};

export const batchRequestError = (payload: { message: string }): BatchActionsType => ({
  type: BATCH_REQUEST_ERROR,
  payload,
});
