import { getProLayerPopupContent } from 'apps/Results/common/MapPopup';
import mapboxgl, { Expression } from 'mapbox-gl';

import { PH_COLORS, RISK_FILL_COLORS } from 'constants/colors';
import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { FIELD_OUTLINE } from 'constants/mapbox';
import {
  COMPACTION_PSI_HIGH,
  COMPACTION_PSI_LOW,
  DCP_POINTS_LAYER_ID,
  PRO_MAP_THRESHOLDS,
  RX_INCH_HIGH,
  RX_INCH_LOW,
  TILL_RX_PROPERTIES_KEY,
  TILL_TEST_RESULTS_PROPERTIES_KEY,
  tillagePolygonLayerIds,
} from 'constants/proMaps';
import { allPhIds, COMPACTION, COMPACTION_ID } from 'constants/results';

import { getString } from 'strings/translation';
import {
  AnalyticType,
  AnalyticWithChartKeys,
  CompactionSearchKey,
  DataRangeType,
  SingleAnalyticType,
} from 'store/analytics/types';
import { EOInferenceLayerType, EOTargetMeasurement } from 'store/eoCollections/types';
import { FieldPropertiesType, SamplingPlanType } from 'store/fields/types';

import { addSourceAndLayers } from './mapbox';
import { getCompactionRxFillColor, getCompactionTestResultsFillColor } from './mapImageryColors';
import { getIsPh } from './results';

type TillageLayerId = (typeof tillagePolygonLayerIds)[keyof typeof tillagePolygonLayerIds];

const emptyGeojsonSource: mapboxgl.GeoJSONSourceRaw = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
};

/**
 * Unlike other layers, the `target_measurements` DCP layers do not have a `layer_param`, but rather
 * a dynamic key based on depth, e.g. `depth_6`. This function finds the key based on an assumption
 * about its naming pattern.
 *
 * @param properties GeoJSON properties object
 * @returns properties key that will contain the actual psi value
 */
const getDcpLayerParam = (properties: GeoJSON.GeoJsonProperties): string | undefined => {
  if (properties) {
    return Object.keys(properties).find((key) => key.startsWith('depth_'));
  }
};

export const addCompactionSourcesAndLayers = (
  map: mapboxgl.Map,
  language: string,
  setPopupInfo: React.Dispatch<
    React.SetStateAction<{
      content: React.ReactNode;
      lat: number;
      lng: number;
    } | null>
  >,
  compactionTestResultsHighLow?: { high: number; low: number },
): void => {
  const layersConfig: Omit<Parameters<typeof addSourceAndLayers>[0], 'map'>[] = [
    {
      sourceId: tillagePolygonLayerIds.testResults,
      sourceOptions: emptyGeojsonSource,
      layers: [
        {
          id: tillagePolygonLayerIds.testResults,
          beforeId: FIELD_OUTLINE,
          type: 'fill',
          paint: {
            'fill-color': getCompactionTestResultsFillColor(compactionTestResultsHighLow),
            'fill-opacity': 1,
          },
          onClick: (evt) => {
            setPopupInfo({
              ...evt.lngLat,
              content: getProLayerPopupContent(
                map,
                evt,
                TILL_TEST_RESULTS_PROPERTIES_KEY,
                tillagePolygonLayerIds.testResults,
                getString('compaction', language),
                'psi',
              ),
            });
          },
        },
      ],
    },
    {
      sourceId: tillagePolygonLayerIds.rx,
      sourceOptions: emptyGeojsonSource,
      layers: [
        {
          id: tillagePolygonLayerIds.rx,
          beforeId: FIELD_OUTLINE,
          type: 'fill',
          paint: {
            'fill-color': getCompactionRxFillColor(),
            'fill-opacity': 1,
          },
          layout: {
            visibility: 'none',
          },
          onClick: (evt) => {
            setPopupInfo({
              ...evt.lngLat,
              content: getProLayerPopupContent(
                map,
                evt,
                TILL_RX_PROPERTIES_KEY,
                tillagePolygonLayerIds.rx,
                getString('depth', language),
                'in',
              ),
            });
          },
        },
      ],
    },
    {
      sourceId: DCP_POINTS_LAYER_ID,
      sourceOptions: emptyGeojsonSource,
      layers: [
        {
          id: DCP_POINTS_LAYER_ID,
          beforeId: FIELD_OUTLINE,
          type: 'circle',
          paint: {
            'circle-stroke-width': 2,
            'circle-stroke-color': 'hsla(0, 0%, 100%, 0.85)',
            'circle-color': 'hsl(0, 0%, 30%)',
          },
          layout: {
            visibility: 'none',
          },
          onClick: (evt) => {
            const firstFeature = evt.features?.[0];

            if (!firstFeature) {
              return;
            }

            const layerParam = getDcpLayerParam(firstFeature.properties ?? {});

            if (layerParam) {
              setPopupInfo({
                ...evt.lngLat,
                content: getProLayerPopupContent(
                  map,
                  evt,
                  layerParam,
                  DCP_POINTS_LAYER_ID,
                  getString('dcpReading', language),
                  'psi',
                ),
              });
            }
          },
        },
      ],
    },
  ];

  layersConfig.forEach((options) => {
    addSourceAndLayers({ map, ...options });
  });
};

export const convertCompactionRiskToScore = (risk: string) => {
  switch (risk) {
    case HIGH_RISK:
      return 3;
    case MODERATE_RISK:
      return 2;
    default:
      return 1;
  }
};

export const adjustAnalyticsForCompaction = (
  analytics: AnalyticType[],
  language: string,
): AnalyticWithChartKeys[] => {
  const compactionMetadataPairs = [
    [
      getString('percentCompacted', language),
      'percent_compaction_above_300_psi',
      getString('percentCompactedTooltip', language),
    ],
    ['PSI: 0-3"', 'average_compaction_0_3', ''],
    ['PSI: 4-6"', 'average_compaction_4_6', ''],
    ['PSI: 7-9"', 'average_compaction_7_9', ''],
    ['PSI: 10-12"', 'average_compaction_10_12', ''],
    ['PSI: 13-18"', 'average_compaction_13_18', ''],
  ];

  const baseAnalytic = {
    ...analytics[0],
    searchKey: 'base' as CompactionSearchKey,
    tooltipName: getString('averageCompactionTooltip', language),
  };

  const compactionMetaData = compactionMetadataPairs.map((pair, index) => ({
    ...baseAnalytic,
    name: pair[0],
    id: baseAnalytic.id + index + 1,
    searchKey: pair[1] as CompactionSearchKey,
    tooltipName: pair[2],
  }));

  return [compactionMetaData[0], baseAnalytic].concat(compactionMetaData.slice(1));
};

const PERCENT_RISK_DATA_SUMMARY = {
  low: [0, 25],
  moderate: [25, 75],
  high: [75, 100],
};

const getPercentCompationRisk = (compaction: number) => {
  if (compaction >= PERCENT_RISK_DATA_SUMMARY.high[0]) {
    return HIGH_RISK;
  }
  if (compaction >= PERCENT_RISK_DATA_SUMMARY.moderate[0]) {
    return MODERATE_RISK;
  }
  return LOW_RISK;
};

export const getAnalyticValueCompaction = (
  plan: SamplingPlanType | undefined | null,
  analytic: AnalyticWithChartKeys,
): SingleAnalyticType | null => {
  const compactionValue = plan?.analytics[COMPACTION]?.[COMPACTION_ID];
  if (!compactionValue || !analytic.searchKey) {
    return null;
  }

  if (analytic.searchKey === 'base') {
    return {
      ...compactionValue,
      quantity: Math.round(compactionValue.quantity),
    };
  }

  if (analytic.searchKey === 'percent_compaction_above_300_psi') {
    const quantity = Math.round(compactionValue.percent_compaction_above_300_psi || 0);
    return {
      ...compactionValue,
      quantity,
      risk_level: getPercentCompationRisk(quantity),
      data_summary: PERCENT_RISK_DATA_SUMMARY,
      unit: '%',
    };
  }

  const stratificationValue = compactionValue.compaction_stratification?.[analytic.searchKey];

  return {
    ...compactionValue,
    quantity: Math.round(stratificationValue?.quantity || 0),
    risk_level: (stratificationValue?.risk_level || LOW_RISK) as SingleAnalyticType['risk_level'],
  };
};

export const getCompactionMeta = (
  language: string,
  planAnalytic: SingleAnalyticType,
  fieldProperties: FieldPropertiesType,
) => {
  const {
    risk_level,
    quantity,
    percent_compaction_above_300_psi: percentCompacted = 0,
    min_depth_300_psi,
    max_depth_300_psi,
  } = planAnalytic;

  const { acreage, acreage_unit } = fieldProperties;
  const compactedAcres = Math.round((percentCompacted / 100) * acreage);

  return {
    score: {
      label: getString('score', language),
      value: convertCompactionRiskToScore(risk_level),
    },
    average: {
      label: getString('average', language),
      value: `${Math.round(quantity)} ${planAnalytic.unit}`,
    },
    compaction: {
      label: getString('compaction', language),
      value: `${compactedAcres} ${acreage_unit} (${Math.round(percentCompacted)}%)`,
    },
    minDepth: {
      label: getString('minDepth', language),
      value: (typeof min_depth_300_psi === 'number' && `${min_depth_300_psi}"`) || 'N/A',
    },
    maxDepth: {
      label: getString('maxDepth', language),
      value: (typeof max_depth_300_psi === 'number' && `${max_depth_300_psi}"`) || 'N/A',
    },
  };
};

export const setTillageHoverHandlers = (map: mapboxgl.Map): void => {
  const tillageLayerIds = [tillagePolygonLayerIds.testResults, tillagePolygonLayerIds.rx];

  tillageLayerIds.forEach((id) => {
    map.on('mouseenter', id, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', id, () => {
      map.getCanvas().style.cursor = '';
    });
  });
};

export const setNonTillageProHoverHandlers = (map: mapboxgl.Map, layerId: string): void => {
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
  });
};

export const getNonTillageProMapPaintFill = (
  analytic: AnalyticType,
  data_summary: DataRangeType,
  proLayer: EOInferenceLayerType,
  proHighLow?: { high: number; low: number },
): Expression => {
  const isPh = getIsPh(analytic.id);

  const low = proHighLow?.low ?? data_summary.low[0];
  const high = proHighLow?.high ?? data_summary.high[1];
  const moderate = proHighLow
    ? (low + high) / 2
    : (data_summary.moderate[0] + data_summary.moderate[1]) / 2;

  return [
    'interpolate',
    ['linear'],
    ['get', proLayer.layer_param],
    low,
    isPh ? PH_COLORS.low : RISK_FILL_COLORS.HIGH_RISK,
    moderate,
    isPh ? PH_COLORS.mid : RISK_FILL_COLORS.MODERATE_RISK,
    high,
    isPh ? PH_COLORS.high : RISK_FILL_COLORS.LOW_RISK,
  ];
};

/**
 * Get the compaction 'test results' EO inference polygon layer at the supplied depth, if present.
 *
 * @param depth layer depth integer, in inches
 * @param layers array of EO inference layers
 *
 * @returns corresponding layer at the supplied depth, if available
 */
export const getCompactionResultsLayerByDepth = (
  depth: number,
  layers: EOInferenceLayerType[],
): EOInferenceLayerType | undefined => {
  return layers.find(({ layer_name }) => layer_name === `depth_${depth}_polys`);
};

/**
 * Get the compaction 'test results' EO inference points layer at the supplied depth, if present.
 *
 * @param depth layer depth integer, in inches
 * @param layers array of EO inference point layers, aka "target measurements"
 *
 * @returns corresponding DCP layer at the supplied depth, if available
 */
export const getTestResultsDcpLayerByDepth = (
  depth: number,
  layers: EOTargetMeasurement[],
): EOTargetMeasurement | undefined => {
  return layers.find(({ layer_name }) => layer_name === `depth_${depth}_points`);
};

/**
 * Get the compaction Rx EO inference polygon layer at the supplied depth and psi.
 *
 * @param depth layer depth integer, in inches
 * @param psi layer psi integer
 * @param layers array of EO inference layers
 *
 * @returns corresponding layer at the supplied depth and psi, if available
 */
export const getRxLayerByDepthAndPsi = (
  depth: number,
  psi: number,
  layers: EOInferenceLayerType[],
): EOInferenceLayerType | undefined => {
  const layerNameToCheck = `rx_${psi}_max_${depth}in_polys`;
  return layers.find(({ layer_name }) => layer_name === layerNameToCheck);
};

/**
 * Get the compaction 'rx' EO inference points layer at the supplied depth and psi.
 *
 * @param depth layer depth integer, in inches
 * @param psi layer psi integer
 * @param layers array of EO inference point layers, aka "target measurements"
 *
 * @returns corresponding DCP layer at the supplied depth and psi
 */
export const getRxDcpLayerByDepthAndPsi = (
  depth: number,
  psi: number,
  layers: EOTargetMeasurement[],
): EOTargetMeasurement | undefined => {
  return layers.find(({ layer_name }) => layer_name === `rx_${psi}_max_${depth}in_points`);
};

/**
 * Determine if a compaction 'test results' or Rx layer exists in the supplied layers.
 * @param layers array of EO inference layers
 *
 * @returns true if a compaction 'test results' or Rx layer exists in the inference layers
 */
export const getTillageLayerExists = (layers: EOInferenceLayerType[]): boolean => {
  const rxPrefix = 'rx_';
  const depthPrefix = 'depth_';
  const suffix = '_polys';

  return layers.some(({ layer_name }) => {
    return (
      (layer_name.startsWith(rxPrefix) || layer_name.startsWith(depthPrefix)) &&
      layer_name.endsWith(suffix)
    );
  });
};

// TODO: when we are ready with new colors, remove this in favor of the array-based ranges
const getAnalyticHighLow = (
  analyticId: number,
  dataSummary?: null | DataRangeType,
  highLow?: { high: number; low: number },
):
  | undefined
  | {
      high: number;
      low: number;
    } => {
  if (allPhIds.includes(analyticId) && PRO_MAP_THRESHOLDS[analyticId]) {
    return PRO_MAP_THRESHOLDS[analyticId];
  }

  const low = highLow?.low ?? dataSummary?.low[0];
  const high = highLow?.high ?? dataSummary?.high[1];

  if (typeof low !== 'number' || typeof high !== 'number') {
    return undefined;
  }

  return {
    high: Math.round(high),
    low: Math.round(low),
  };
};

const getProLayerHasMinMax = (proLayer?: null | EOInferenceLayerType): boolean => {
  return !!proLayer && !(proLayer.min_value === null || proLayer.max_value === null);
};

export const getProLayerHighLow = (
  isHighContrast: boolean,
  activeAnalyticId: number,
  sampleRange?: {
    highest: number;
    lowest: number;
  },
  activeTillageLayerId?: TillageLayerId,
  dataSummary?: SingleAnalyticType['data_summary'],
  proLayer?: null | EOInferenceLayerType,
): ReturnType<typeof getAnalyticHighLow> => {
  const hasProMinMax = proLayer && getProLayerHasMinMax(proLayer);
  const isCompaction = activeAnalyticId === COMPACTION_ID;

  if (!proLayer) {
    return undefined;
  }

  // Compaction "Rx" values are hardcoded
  if (isCompaction && activeTillageLayerId === 'rx') {
    return {
      high: RX_INCH_HIGH,
      low: RX_INCH_LOW,
    };
  }

  // Compaction "Test Results" values are hardcoded if we are not in high contrast mode
  if (isCompaction && !isHighContrast && activeTillageLayerId === 'testResults') {
    return {
      high: COMPACTION_PSI_HIGH,
      low: COMPACTION_PSI_LOW,
    };
  }

  // For high contrast ("relative risk") maps, use min/max from the inference layer if available
  if (isHighContrast) {
    if (hasProMinMax) {
      return {
        high: proLayer.max_value as number,
        low: proLayer.min_value as number,
      };
    }

    if (sampleRange) {
      return {
        high: sampleRange.highest,
        low: sampleRange.lowest,
      };
    }
  }

  // Custom overrides for specific analytics
  if (PRO_MAP_THRESHOLDS[activeAnalyticId]) {
    return PRO_MAP_THRESHOLDS[activeAnalyticId];
  }

  // Otherwise, fall back to the agronomic range from the analytic's `data_summary` or custom
  // overrides in the case of pH
  if (dataSummary) {
    return getAnalyticHighLow(activeAnalyticId, dataSummary);
  }
};
