import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, SegmentedControl, Stack } from '@mantine/core';
import DeliveryCompleteModal from 'apps/Orders/FieldList/FullProductReport/DeliveryCompleteModal';
import CreateProjectForm from 'apps/Projects/CreateProjectForm';

import { SEARCHED_USER_OR_AGENCY } from 'constants/auth';
import { CROP_PROTECTION } from 'constants/results';
import { projectsRoutes } from 'constants/routes';
import { DEMO_OPERATION_ID } from 'constants/variables';

import useBrowserLanguage from 'util/hooks/useLanguage';
import { getLocalStorage, removeLocalStorageItem, setLocalStorage } from 'util/localStorage';
import { isProduction } from 'util/request';
import { getAccountSelectionOptions } from 'util/results';
import { getAcreageUnitFromLang } from 'util/units';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { getOperations, getOperationsTotalStats, setActiveOperation } from 'store/operation/thunks';
import { OperationType } from 'store/operation/types';
import { userIsAdmin, userIsAgencyAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { UserAgencySelectorType } from 'store/user/types';
import {
  AdminAgentAccess,
  AgencyAdminAccess,
  Header,
  Pagination,
  Spinner,
  UserAgencySearch,
} from 'common';
import FullHeightLayoutFooter from 'common/FullHeightLayoutFooter';
import FullHeightLayoutWrap from 'common/FullHeightLayoutWrap';

import NoOperationsBlurb from './NoOperationsBlurb';
import OperationsTable from './OperationsTable';

import styles from './Container.module.css';

const MultipleOperationsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearching, toggleIsSearching] = useState(false);
  const [searchParams] = useSearchParams();
  const [userOrAgencySelected, setUserOrAgencySelected] = useState<UserAgencySelectorType | null>(
    null,
  );
  const [hasSetSearch, toggleHasSetSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const language = useBrowserLanguage();
  const accountSelection = getAccountSelectionOptions(language);
  const [showDeliveryModal, toggleDeliveryModal] = useState<OperationType | null>(null);
  const [selectedOperationIds, setSelectedOperationIds] = useState<number[]>([]);

  const [enableTableCheckboxes, setEnableTableCheckboxes] = useState(
    searchParams.get(projectsRoutes.queryParams.project) !== null,
  );

  const {
    activeOperationId,
    hasFetched,
    isFetching,
    operations,
    totalOperationPages,
    operationTotals,
    isAdmin,
    isAgencyAdmin,
  } = useSelector((state: RootState) => ({
    activeOperationId: state.operations.activeOperationId,
    hasFetched: state.operations.hasFetched,
    isFetching: state.operations.isFetching,
    operations: state.operations.summary.items,
    totalOperationPages: state.operations.summary.total,
    operationTotals: state.operations.summary.totalStats,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
    isAgencyAdmin: userIsAgencyAdmin(state),
  }));

  const showAllOperations = useMemo(() => {
    return location.pathname.includes('/all') && (isAdmin || isAgencyAdmin);
  }, [location, isAdmin, isAgencyAdmin]);

  const changeOperation: any = useCallback(
    (id: number) => dispatch(setActiveOperation(id)),
    [dispatch],
  );

  useEffect(() => {
    if (!hasSetSearch) {
      const savedSearch: UserAgencySelectorType | null = JSON.parse(
        getLocalStorage(SEARCHED_USER_OR_AGENCY) || '{}',
      );
      if (savedSearch?.user_id || savedSearch?.agency_id) {
        setUserOrAgencySelected(savedSearch);
      }
      toggleHasSetSearch(true);
    }
  }, [hasSetSearch]);

  useEffect(() => {
    if (hasSetSearch) {
      const search_users = userOrAgencySelected?.user_id ? [userOrAgencySelected?.user_id] : [];
      const search_agency =
        userOrAgencySelected?.agency_id && userOrAgencySelected?.agency_primary_id
          ? {
              agency_id: userOrAgencySelected.agency_id,
              agency_primary_id: userOrAgencySelected.agency_primary_id,
            }
          : null;
      dispatch(
        getOperations(
          getAcreageUnitFromLang(language),
          showAllOperations,
          page,
          '',
          search_users,
          search_agency,
          sortAttribute,
          sortOrder === 'desc',
        ),
      );
      dispatch(
        getOperationsTotalStats(
          getAcreageUnitFromLang(language),
          showAllOperations,
          '',
          search_users,
          search_agency,
        ),
      );
    }
  }, [page, showAllOperations, userOrAgencySelected, sortAttribute, sortOrder, hasSetSearch]);

  useEffect(() => {
    if (!activeOperationId && operations.length) {
      changeOperation(operations[0].id);
    }
  }, [activeOperationId, changeOperation, hasFetched, operations, dispatch]);

  const manageOrders = (id: number) => {
    changeOperation(id).then(() => navigate(`/orders/${id}`));
  };

  const viewResults = (id: number) => {
    changeOperation(id).then(() => navigate(`/results/operation/${id}/${CROP_PROTECTION}`));
  };

  const editOperation = (id: number) => {
    changeOperation(id).then(() => navigate(`/operations/${id}/edit`));
  };

  const goToCreateAccount = () => {
    navigate(`/operations/register`);
  };

  const handleUserSelection = (selcted: UserAgencySelectorType | null) => {
    if (!selcted) {
      removeFilter();
    } else {
      const { id, displayName, user_id, agency_id, agency_primary_id } = selcted;
      setPage(1);
      setUserOrAgencySelected({
        id,
        user_id,
        displayName,
        agency_id,
        agency_primary_id,
      });
      setLocalStorage(SEARCHED_USER_OR_AGENCY, JSON.stringify(selcted));
    }
  };

  const removeFilter = () => {
    setUserOrAgencySelected(null);
    removeLocalStorageItem(SEARCHED_USER_OR_AGENCY);
  };

  const handleMarkAsDelivered = () => {
    toggleHasSetSearch(false);
    toggleDeliveryModal(null);
    showToast(getString('planMarkedDeliveredSuccess', language));
  };

  return (
    <FullHeightLayoutWrap>
      <Header
        title={
          isAdmin || isAgencyAdmin ? (
            <SegmentedControl
              color="blue"
              value={accountSelection.find((url) => url.value === location.pathname)?.value}
              onChange={(value) => navigate(value)}
              data={accountSelection}
            />
          ) : (
            getString('myAccounts', language)
          )
        }
      >
        <AgencyAdminAccess>
          {(isFetching || isSearching) && <Spinner className={styles.MiniSpinner} />}
          <UserAgencySearch
            placeholder={getString('searchByUserAgency', language)}
            onSelect={handleUserSelection}
            onDeselect={removeFilter}
            className={styles.SearchBar}
            toggleIsSearching={toggleIsSearching}
            selected={userOrAgencySelected}
          />
        </AgencyAdminAccess>
        {/* TODO: rm flag when ready */}
        {!isProduction && (
          <Button
            onClick={() => setEnableTableCheckboxes((enableCheckboxes) => !enableCheckboxes)}
            tt="capitalize"
          >
            {getString(enableTableCheckboxes ? 'cancel' : 'projectBuilder', language)}
          </Button>
        )}
        <AdminAgentAccess>
          <Button variant="outline" onClick={goToCreateAccount}>
            {getString('createAccount', language)}
          </Button>
          <Button variant="outline" onClick={() => manageOrders(DEMO_OPERATION_ID)}>
            {getString('demoAccount', language)}
          </Button>
        </AdminAgentAccess>
      </Header>
      <Stack w="100%" style={{ overflowY: 'auto' }}>
        {showDeliveryModal && (
          <AdminAgentAccess>
            <DeliveryCompleteModal
              operation={showDeliveryModal}
              hideModal={() => toggleDeliveryModal(null)}
              onSuccess={() => handleMarkAsDelivered()}
              open={!!showDeliveryModal}
            />
          </AdminAgentAccess>
        )}
        <OperationsTable
          enableRowCheckboxes={enableTableCheckboxes}
          manageOrders={manageOrders}
          viewResults={viewResults}
          editOperation={editOperation}
          operations={operations}
          operationTotals={operationTotals}
          sortAttribute={sortAttribute}
          sortOrder={sortOrder}
          setSortAttribute={setSortAttribute}
          setSortOrder={setSortOrder}
          toggleDeliveryModal={toggleDeliveryModal}
          selectedOperationIds={selectedOperationIds}
          setSelectedOperationIds={(rows) => setSelectedOperationIds(rows)}
        />
        {!operations.length ? <NoOperationsBlurb /> : null}
      </Stack>
      <FullHeightLayoutFooter>
        <div />
        <Pagination page={page} setPage={setPage} totalPages={totalOperationPages} />
        {(enableTableCheckboxes && <CreateProjectForm operationIds={selectedOperationIds} />) || (
          <div />
        )}
      </FullHeightLayoutFooter>
    </FullHeightLayoutWrap>
  );
};

export default MultipleOperationsContainer;
