import React from 'react';
import { Group, Stack, Text } from '@mantine/core';

import PatternLogo from 'images/earthoptics-logo.svg';

import { COMPANY_SITE_HOSTNAME, WEB_APP_HOSTNAME } from 'constants/urls';

import { formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BillingAddress } from 'store/user/types';

import styles from './FiveNinety.module.css';

export type Props = {
  operationName: string;
  operationId: number;
  fieldName: string;
  samplingPlanId: number;
  currentUserFullName: string;
  receivedDateStr: string;
  completedDateStr: string;
  billingAddress?: BillingAddress;
  agencyName?: string;
};

export const FiveNinetyMetadata = ({
  operationName,
  operationId,
  fieldName,
  samplingPlanId,
  currentUserFullName,
  billingAddress,
  receivedDateStr,
  completedDateStr,
  agencyName = '',
}: Props) => {
  const { street, city, state, zip } = billingAddress || {};
  const language = useBroswerLanguage();

  const Metadata = (
    <div className={styles.MetadataSection}>
      <b>{getString('account', language)}:</b>
      <div>
        {operationName} - {operationId}
      </div>
      <b>{getString('field', language)}:</b>
      {fieldName}
      <b>{getString('report', language)}:</b>
      {samplingPlanId}
      <b>{getString('preparedBy', language)}:</b>
      <div>
        {currentUserFullName}
        {!!billingAddress && (
          <>
            <div>{agencyName}</div>
            <div>{`${street}, ${city}, ${state} ${zip}`}</div>
          </>
        )}
      </div>
    </div>
  );

  const Dates = (
    <div className={styles.MetadataSection}>
      <b>{getString('received', language)}:</b>{' '}
      {receivedDateStr ? formatDateMonthDayYear(receivedDateStr, language) : 'N/A'}
      <b>{getString('completed', language)}:</b>
      {completedDateStr ? formatDateMonthDayYear(completedDateStr, language) : 'N/A'}
      <b>{getString('todaysDate', language)}:</b>
      {formatDateMonthDayYear(new Date().toISOString(), language)}
    </div>
  );

  return (
    <Group justify="space-between" mb="lg" align="flex-start">
      {Metadata}
      <Stack align="center" gap="xs">
        <img
          alt="EarthOptics logo"
          style={{ maxWidth: 200 }}
          data-test-id="pattern-logo"
          src={PatternLogo}
        />
        <Text fz="sm" ta="center" component="div">
          <address style={{ display: 'inline', fontStyle: 'normal' }}>
            1250 45th St, Emeryville, CA, 94608
          </address>{' '}
          - (703) 261-9436
          <div>
            {COMPANY_SITE_HOSTNAME} / {WEB_APP_HOSTNAME}
          </div>
        </Text>
      </Stack>
      {Dates}
    </Group>
  );
};
