import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Loader, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { editSampleValues } from 'store/labSamples/thunks';
import { LabSampleType } from 'store/labSamples/types';
import { SuperAdminAccess } from 'common/RoleBasedAccess';

import { ApplyToSamplingPlan } from '../../common/ApplyToSamplingPlan';

type Props = {
  sample: LabSampleType;
};

export const Priority = ({ sample }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [priority, setPriority] = useState(
    sample.rnd_meta?.priority ? sample.rnd_meta?.priority : false,
  );

  const applyPriorityToSample = async (priorityToApply) => {
    const propertiesRndUpdateData: Partial<LabSampleType> = {
      rnd_meta: {
        ...sample.rnd_meta,
        priority: priorityToApply,
      },
    };
    await dispatch(editSampleValues(sample.id, sample.barcode, propertiesRndUpdateData));
  };

  const updateSamplePriority = async (newPriority: boolean) => {
    if (sample.barcode) {
      try {
        setIsSubmitting(true);
        setPriority(newPriority);
        await applyPriorityToSample(newPriority);
      } catch (e) {
        showToast(e.message, 'error');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const priorityDisplay = () => {
    const style = priority ? { color: 'red' } : {};

    const priorityText = priority ? getString('yes', language) : getString('no', language);

    return <Text style={style}>{priorityText}</Text>;
  };

  const removePriority = async () => {
    updateSamplePriority(false);
    setIsSubmitting(false);
  };

  const addPriority = async () => {
    updateSamplePriority(true);
    setIsSubmitting(false);
  };

  const removePriorityButton = (
    <Group gap="xs" pos="relative">
      <Button variant="outline" onClick={removePriority} disabled={!priority || isSubmitting}>
        {getString(isSubmitting ? 'submitting' : 'removePriority', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const addPriorityButton = (
    <Group gap="xs" pos="relative">
      <Button variant="outline" onClick={addPriority} disabled={priority || isSubmitting}>
        {getString(isSubmitting ? 'submitting' : 'addPriority', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const confirmationMessage = `${getString('applyPriorityToSamplingPlanConfirmation', language)}: ${priority}`;

  return (
    <Group>
      <Text fw="700">
        {`${getString('priority', language)}: `} {priorityDisplay()}{' '}
      </Text>
      <SuperAdminAccess>
        <Group gap="sm" w="13.5rem" wrap="nowrap">
          {addPriorityButton}
          {removePriorityButton}
          <ApplyToSamplingPlan
            sample={sample}
            samplesInSamplingPlanRequestBody={{ priority }}
            confirmationMessage={confirmationMessage}
          />
        </Group>
      </SuperAdminAccess>
    </Group>
  );
};
