import {
  LAB_SAMPLES_REQUEST_ERROR,
  LabSamplesActionsType,
  LabSampleType,
  RECEIVE_FIELD_LAB_SAMPLES,
  RECEIVE_LAB_SAMPLES,
  REQUEST_LAB_SAMPLES,
} from './types';

export const requestLabSamples = (): LabSamplesActionsType => ({
  type: REQUEST_LAB_SAMPLES,
});

export const receiveLabSamples = (payload: LabSampleType[]): LabSamplesActionsType => ({
  type: RECEIVE_LAB_SAMPLES,
  payload,
});

export const receiveFieldLabSamples = (payload: {
  id: number;
  samples: LabSampleType[];
}): LabSamplesActionsType => ({
  type: RECEIVE_FIELD_LAB_SAMPLES,
  payload,
});

export const labSampleRequestError = (payload: { message: string }): LabSamplesActionsType => ({
  type: LAB_SAMPLES_REQUEST_ERROR,
  payload,
});
