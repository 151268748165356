import React from 'react';
import { useNavigate } from 'react-router-dom';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestDeleteLabShipment } from 'store/labShipments/requests';
import { LabShipment } from 'store/labShipments/types';
import { Button, TooltipWrapper } from 'common';

import styles from './ShipmentActions.module.css';

type ShipmentActionsModalProps = {
  shipment: LabShipment;
  reloadPage: () => {};
};

export const ShipmentActionsModal = ({ shipment, reloadPage }: ShipmentActionsModalProps) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  const handleDeleteShipment = async () => {
    try {
      await requestDeleteLabShipment(shipment.group_id);
      reloadPage();
    } catch (e) {
      showToast(e.message);
    }
  };

  const cancelActions = (
    <div className={styles.TooltipContent}>
      <Button light square onClick={() => navigate(`/lab/shipping/details/${shipment.group_id}`)}>
        {getString('edit', language)}
      </Button>
      <Button light square onClick={handleDeleteShipment}>
        {getString('delete', language)}
      </Button>
    </div>
  );

  return (
    <>
      <TooltipWrapper right content={cancelActions}>
        <Button dataTestId="actions">{getString('actions', language)}</Button>
      </TooltipWrapper>
    </>
  );
};
