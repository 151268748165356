import React, { useEffect, useState } from 'react';
import {
  Button,
  Center,
  Checkbox,
  ComboboxItem,
  Flex,
  Modal,
  Paper,
  Select,
  Space,
  Text,
} from '@mantine/core';

import {
  CSV_PRO_FORMAT,
  PRO_EXPORT_ACRE_DENSITY_OPTIONS,
  SHAPEFILE_PRO_FORMAT,
  TOAST_TWELVE,
} from 'constants/results';

import { formatCalendarMonthDayYear } from 'util/date';
import { getString } from 'strings/translation';
import showToast, { ToastType } from 'actions/toastActions';
import { FieldType } from 'store/fields/types';
import { startGenerateResultsByFormat } from 'store/operation/requests';
import { SamplePlanType } from 'store/samplePlans/types';

interface ProExportModalProps {
  closeModal: VoidFunction;
  completePlans: SamplePlanType[];
  language: string;
  modalType: typeof SHAPEFILE_PRO_FORMAT | typeof CSV_PRO_FORMAT;
  operationId: number;
  operationFields: FieldType[];
  selectedYear: number;
}

const ProExportModal = ({
  closeModal,
  completePlans,
  language,
  modalType,
  operationId,
  operationFields,
  selectedYear,
}: ProExportModalProps) => {
  const [selectedFieldIds, setSelectedFieldIds] = useState<number[]>([]);
  const [completeFields, setCompleteFields] = useState<FieldType[]>([]);

  const [density, setDensity] = useState<ComboboxItem | null>(null);

  useEffect(() => {
    const planFieldIds = completePlans.map((plan) => plan.field_id);
    const fields = operationFields.filter((field) =>
      planFieldIds.includes(field.features[0].properties.id),
    );
    const fieldIds = fields.map((field) => field.features[0].properties.id);

    setCompleteFields(fields);
    setSelectedFieldIds(fieldIds);
  }, [completePlans, operationFields]);

  const allSelected = selectedFieldIds.length === completeFields.length;

  const showMessage = (message: string, type?: ToastType, timeout = 5000) =>
    showToast(message, type, timeout);

  const handleCheckAll = () => {
    const fieldIds = allSelected ? [] : completeFields.map((f) => f.features[0].properties.id);
    setSelectedFieldIds(fieldIds);
  };

  const handleCheckOneField = (fieldId: number) => {
    const currentlyChecked = selectedFieldIds.includes(fieldId);

    if (currentlyChecked) {
      setSelectedFieldIds(selectedFieldIds.filter((id) => id !== fieldId));
    } else {
      setSelectedFieldIds(selectedFieldIds.concat(fieldId));
    }
  };

  const handleSubmit = async () => {
    if (density) {
      try {
        // pro map exports are much faster than puppeteer, so always use shorter time estimate
        showMessage(getString('csvDownloadMsg', language), 'success', TOAST_TWELVE);
        await startGenerateResultsByFormat(operationId, selectedYear, modalType, {
          field_ids: selectedFieldIds,
          density: density.value,
        });
        closeModal();
      } catch (error) {
        showMessage(getString('csvGenerateErrorMsg', language), 'error');
      }
    }
  };

  const renderField = (field: FieldType) => {
    const { farm_name, id, name } = field.features[0].properties;
    const plan = completePlans.find((p) => p.field_id === id);

    const farmField = [farm_name, name].filter(Boolean).join(': ');

    return (
      <Flex align="center" key={id}>
        <Checkbox
          checked={selectedFieldIds.includes(id)}
          onChange={() => handleCheckOneField(id)}
          w="10%"
        />
        <Text w="42.5%">{farmField}</Text>
        <Text ta="right" w="42.5%">
          {plan ? formatCalendarMonthDayYear(plan.created_at) : ''}
        </Text>
      </Flex>
    );
  };

  return (
    <Modal
      onClose={closeModal}
      opened={Boolean(modalType)}
      centered
      size="lg"
      title={getString(
        modalType === SHAPEFILE_PRO_FORMAT ? 'exportProShapefile' : 'exportProCsv',
        language,
      )}
    >
      <Paper p="lg">
        <Paper p="sm" withBorder>
          <Flex justify="space-between" ml="10%" w="85%">
            <Text fw={700}>{getString('farmField', language)}</Text>
            <Text fw={700}>{getString('sampleDate', language)}</Text>
          </Flex>
          <Space h="lg" />
          <Flex align="center">
            <Checkbox checked={allSelected} onChange={handleCheckAll} w="10%" />
            <Text color="darkRed" fw={600}>
              {getString('selectAll', language)}
            </Text>
          </Flex>
          {completeFields.map(renderField)}
        </Paper>
        <Space h="lg" />
        <Flex align="center" justify="space-between">
          <Text>{getString('selectZoneDensity', language)}</Text>
          <Select
            data={PRO_EXPORT_ACRE_DENSITY_OPTIONS}
            onChange={(_value, option) => setDensity(option)}
            placeholder={getString('choose', language)}
            value={density ? density.value : null}
          />
        </Flex>
        <Space h="lg" />
        <Center>
          <Button onClick={handleSubmit} disabled={!density || !selectedFieldIds.length}>
            {getString(
              modalType === SHAPEFILE_PRO_FORMAT ? 'exportShapefile' : 'exportCsv',
              language,
            )}
          </Button>
        </Center>
      </Paper>
    </Modal>
  );
};

export default ProExportModal;
