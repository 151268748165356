import React from 'react';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { Banner } from 'common';

type PriorityPropsType = {
  sample: LabSampleType;
};

export const Priority = ({ sample }: PriorityPropsType) => {
  const language = useBroswerLanguage();
  return (
    <>
      {sample.rnd_meta?.priority && (
        <Banner headingText={getString('prioritySampleHeader', language)} />
      )}
    </>
  );
};
