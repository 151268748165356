import React from 'react';
import { Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleQueryKeys } from 'util/queryKeys';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { LabSampleType } from 'store/labSamples/types';
import { updateSamplesInSamplingPlan } from 'store/samples/requests';
import { UpdateSamplesInSamplingPlanRequestBody } from 'store/samples/types';

import { ConfirmActionModal } from './ConfirmActionModal';

type Props = {
  sample: LabSampleType;
  samplesInSamplingPlanRequestBody: Partial<UpdateSamplesInSamplingPlanRequestBody>;
  confirmationMessage: string;
};

type SamplesInSamplingPlanMutationData = {
  samplingPlanId: number;
  samplesInSamplingPlanRequestBody: Partial<UpdateSamplesInSamplingPlanRequestBody>;
};

export const ApplyToSamplingPlan = ({
  sample,
  samplesInSamplingPlanRequestBody,
  confirmationMessage,
}: Props) => {
  const language = useBroswerLanguage();
  const rqc = useQueryClient();

  const [opened, { open, close }] = useDisclosure(false);

  const samplesInSamplingPlanMutation = useMutation<
    LabSampleType,
    Error,
    SamplesInSamplingPlanMutationData
  >({
    mutationFn: (samplesInSamplingPlanMutationData) =>
      updateSamplesInSamplingPlan(
        samplesInSamplingPlanMutationData.samplingPlanId,
        samplesInSamplingPlanMutationData.samplesInSamplingPlanRequestBody,
      ),

    onError: (err) => showToast(err.message, 'error'),
    onSuccess: () => rqc.invalidateQueries({ queryKey: sampleQueryKeys.sample(sample.barcode) }),
  });

  const applyToSamplingPlanWithConfirmation = async () => {
    try {
      open();
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  const applyToSamplingPlan = async (samplingPlanIdToUpdate) => {
    samplesInSamplingPlanMutation.mutate({
      samplingPlanId: samplingPlanIdToUpdate,
      samplesInSamplingPlanRequestBody: samplesInSamplingPlanRequestBody,
    });
  };

  const applyToSamplingPlanButton = (
    <Group gap="xs" pos="relative">
      <Button
        variant="outline"
        onClick={applyToSamplingPlanWithConfirmation}
        loading={samplesInSamplingPlanMutation.isPending}
      >
        {getString(
          samplesInSamplingPlanMutation.isPending ? 'submitting' : 'applyToSamplingPlan',
          language,
        )}
      </Button>
    </Group>
  );

  return (
    <>
      {applyToSamplingPlanButton}
      <ConfirmActionModal
        opened={opened}
        changeFunction={() => applyToSamplingPlan(sample.sampling_plan_id)}
        modalTitle={getString('applyToSamplingPlan', language)}
        modalBody={confirmationMessage}
        onClose={() => close()}
      />
    </>
  );
};
