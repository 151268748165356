import React from 'react';

import { PlateType } from 'store/plates/types';

import { EditNotes } from '../../Sections/Inputs/EditNotes';
import { MarkArrived } from '../../Sections/Inputs/MarkArrived';
import { PlateDocumentsContainer } from '../../Sections/PlateDocuments/Container';
import { PlateWells } from '../../Sections/PlateWells/PlateWells';
import { ChildrenPlates } from '../../Sections/RelatedPlates/ChildrenPlates';
import WellTable from '../../Wells/WellTable';

import styles from '../Container.module.css';

type HomogenizationLayoutPropsType = {
  plate: PlateType;
};

export const HomogenizationLayout = ({ plate }: HomogenizationLayoutPropsType) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <div className={styles.Column}>
          <PlateWells plate={plate} />
        </div>
        <div className={styles.Column}>
          <ChildrenPlates plate={plate} />
          <EditNotes plate={plate} />
          <MarkArrived plate={plate} />
          <PlateDocumentsContainer plate={plate} />
        </div>
      </div>
      <WellTable plate={plate} />
    </div>
  );
};
