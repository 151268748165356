import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';

import { roundTwoDecimal } from 'util/numUtils';
import { currencyFormatFromLanguage } from 'util/units';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { receiveFieldGeometry } from 'store/fields/actions';
import { FieldType } from 'store/fields/types';
import { putAddTillageRx } from 'store/samplePlans/requests';

type Props = {
  acres: number;
  language: string;
  open: boolean;
  onClose: VoidFunction;
  samplePlanId: number;
  baseTillageCost: number;
  fieldId: number;
};

const translate = (language: string) => {
  return {
    acre: getString('acre', language),
    acres: getString('acres', language),
    confirmOrder: getString('confirmOrder', language),
    cost: getString('cost', language),
    success: getString('tillMapperActivated', language),
    title: getString('tillMapperAvailableForField', language),
    total: getString('total', language),
  };
};

export const ActivateTillMapperModal = ({
  language,
  acres,
  open,
  onClose,
  samplePlanId,
  baseTillageCost,
  fieldId,
}: Props) => {
  const text = translate(language);
  const roundedAcres = roundTwoDecimal(acres) as number;
  const roundedTotalCost = roundTwoDecimal(roundedAcres * baseTillageCost);
  const formattedTotalCost = currencyFormatFromLanguage(roundedTotalCost as number, language);
  const formattedBaseCost = currencyFormatFromLanguage(baseTillageCost, language);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);

    try {
      const updatedField: FieldType = await putAddTillageRx(samplePlanId);

      dispatch(receiveFieldGeometry(fieldId, updatedField));
      showToast(text.success, 'success');
    } catch (error) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={open}
      size="lg"
      padding="lg"
      styles={{ title: { marginLeft: 'auto' } }}
      title={
        <Text ta="center" fw={700} fz="h2" ml="auto" component="div">
          {text.title}
        </Text>
      }
    >
      <Stack gap="xl" mb="xs">
        <Group wrap="nowrap" justify="center" align="center">
          <div>
            <b>{text.acres}:</b> {roundedAcres}
          </div>
          <div>
            <b>
              {text.cost} / {text.acre}:
            </b>{' '}
            {formattedBaseCost}
          </div>
          <div>
            <b>{text.total}:</b> {formattedTotalCost}
          </div>
        </Group>
        <div style={{ margin: '0 auto' }}>
          <Button size="lg" onClick={handleClick} loading={isLoading}>
            {text.confirmOrder}
          </Button>
        </div>
      </Stack>
    </Modal>
  );
};
