import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Button, Grid, Group, Modal, Stack, Text } from '@mantine/core';

import { PLAN_SUMMARY_SAMPLING_KEYS, SPLIT_DENSITY, ZONE_BY_ZONE } from 'constants/samplePlanning';

import { formatToNumericDate } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPriceSummary, sortPriceSummaryKeys } from 'util/product';
import { getAnalysisOverviewFromSummary, getPriceDisplay } from 'util/samplePlan';
import { getString } from 'strings/translation';
import { FieldType, SamplingPlanType } from 'store/fields/types';

import AnalysisRow from './AnalysisRow';
import PlanTotal from './PlanTotal';

import styles from './Confirmation.module.css';

type ConfirmationProps = {
  field: FieldType;
  confirmationValues: SamplingPlanType;
  setConfirmationValues: (values: null) => void;
};

const Confirmation = ({ field, confirmationValues, setConfirmationValues }: ConfirmationProps) => {
  const language = useBroswerLanguage();
  const { name, acreage, acreage_unit } = field.features[0].properties;
  const [showAnalysis, toggleShowAnalysis] = useState(false);
  const [showSampling, toggleShowSampling] = useState(false);

  const isProOrTillRx = confirmationValues.is_pro || confirmationValues.is_till_rx;
  const isSampleable = !!confirmationValues.products.length;

  const newPriceSummary = getPriceSummary(
    confirmationValues.price_summary,
    language,
    confirmationValues.is_split_density ? SPLIT_DENSITY : ZONE_BY_ZONE,
    confirmationValues?.is_pro,
  );

  const getOverviewRow = (
    summary: { total: number; num_zones: number },
    chevronBool: boolean,
    isAnalysis: boolean = false,
  ) => {
    const updateBoolType = isAnalysis ? toggleShowAnalysis : toggleShowSampling;

    if (!summary.total) {
      return;
    }
    return (
      <Grid columns={36}>
        <Grid.Col span={1} />
        <Grid.Col span={9}>
          <Button
            variant="transparent"
            rightSection={chevronBool ? <FiChevronDown /> : <FiChevronUp />}
            onClick={() => updateBoolType(!chevronBool)}
          >
            <Text fw={500} fs="italic">
              {getString(isAnalysis ? 'analysis' : 'sampling', language)}
            </Text>
          </Button>
        </Grid.Col>
        <Grid.Col span={2} />
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {summary.num_zones}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {getPriceDisplay('', summary.total / summary.num_zones)}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {getPriceDisplay('', summary.total)}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {getPriceDisplay('', summary.total / acreage)}
          </Text>
        </Grid.Col>
      </Grid>
    );
  };

  const lastUpdated = confirmationValues?.updated_at || confirmationValues?.created_at;

  const sortedPriceSummaryKeys = sortPriceSummaryKeys(newPriceSummary);

  const priceSummarySplit = sortedPriceSummaryKeys.reduce(
    (acc, key) =>
      PLAN_SUMMARY_SAMPLING_KEYS.includes(key)
        ? { ...acc, sampling: { ...acc.sampling, [key]: newPriceSummary[key] } }
        : { ...acc, analysis: { ...acc.analysis, [key]: newPriceSummary[key] } },
    { sampling: {}, analysis: {} },
  );

  const analysisOverview = getAnalysisOverviewFromSummary(priceSummarySplit.analysis);
  const samplingOverview = getAnalysisOverviewFromSummary(priceSummarySplit.sampling);

  return (
    <Modal
      opened={!!confirmationValues}
      withCloseButton
      onClose={() => setConfirmationValues(null)}
      size="50rem"
      centered
    >
      <Stack className={styles.Dialog} gap="xs">
        <Stack align="center" gap="xs">
          <Text size="xl" fw={700}>
            {`${getString('samplePlanDetails', language)}: ${name}`}
          </Text>
          <Text>
            {getString('lastUpdated', language)}: {lastUpdated && formatToNumericDate(lastUpdated)}
          </Text>
          <Text>
            {getString('acres', language)}: {acreage.toFixed(2)}
          </Text>
        </Stack>
        <Text size="lg" fw={600}>
          {getString('analysisPackage', language)}
        </Text>
        <Stack data-test-id="package-info" className={styles.PackageInfo} gap="0.1rem">
          <>
            <Grid columns={36}>
              <Grid.Col span={12}>
                <Text fw={700}>{getString('listPrice', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>{getString('zones', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>$ / {getString('zone', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>{getString('total', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>
                  {getString('per', language)} {acreage_unit}
                </Text>
              </Grid.Col>
            </Grid>
            {getOverviewRow(analysisOverview, showAnalysis, true)}
            {showAnalysis &&
              Object.keys(priceSummarySplit.analysis).map((product) => (
                <AnalysisRow
                  key={product}
                  product={product}
                  acreage={acreage}
                  priceSummary={newPriceSummary}
                />
              ))}
            {getOverviewRow(samplingOverview, showSampling, false)}
            {showSampling &&
              Object.keys(priceSummarySplit.sampling).map((product) => (
                <AnalysisRow
                  key={product}
                  product={product}
                  acreage={acreage}
                  priceSummary={newPriceSummary}
                />
              ))}
            <PlanTotal priceSummary={newPriceSummary} acreage={acreage} />
          </>
        </Stack>
        <Text size="lg" fw={600}>
          {getString('sampleExecution', language)}
        </Text>
        <Stack align="center" gap="xs">
          {isSampleable && (
            <Group className={styles.Width90} justify="space-between">
              <Text>{getString('toBeSampledBy', language)}</Text>
              <Text fw={700}>{confirmationValues.assigned_user_name}</Text>
            </Group>
          )}
          {isProOrTillRx && (
            <Group className={styles.Width90} justify="space-between">
              <Text>{getString('toBeScannedBy', language)}</Text>
              <Text fw={700}>{confirmationValues.assigned_scan_user_name}</Text>
            </Group>
          )}
          <Group className={styles.Width90} justify="space-between">
            <Text>{getString('samplingTiming', language)}</Text>
            <Text fw={700}>{confirmationValues.sample_timing || ''}</Text>
          </Group>
        </Stack>
        <Text size="sm" fs="italic">
          *{getString('summaryOfSamplePlanMsg', language)}
        </Text>
      </Stack>
    </Modal>
  );
};

export default Confirmation;
