import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { OrdersRouteParams, RootState } from 'store/index';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { userIsSampler } from 'store/user/selectors';
import { Spinner } from 'common';

import FullProductReportContainer from './FullProductReport/Container';

const FieldListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operationId } = useParams<OrdersRouteParams>();
  const numericId = Number(operationId);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(numericId)),
    [dispatch, numericId],
  );

  const { operation, isSampler } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericId],
    isSampler: userIsSampler(state),
  }));

  const setOperation = useCallback(
    () => dispatch(setActiveOperation(numericId)),
    [numericId, dispatch],
  );

  useEffect(() => {
    if (isSampler) {
      navigate('/payments/open');
    }
  }, [navigate, isSampler]);

  useEffect(() => {
    setOperation();
  }, [setOperation]);

  useEffect(() => {
    if (!operation) {
      fetchOperation();
    }
  }, [fetchOperation, operation]);

  if (!operation) {
    return <Spinner fill />;
  }

  return <FullProductReportContainer />;
};

export default FieldListContainer;
