import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, SelectProps } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getIndexSetsNames } from 'store/indexSets/thunks';
import { PlateType } from 'store/plates/types';

type IndexSetSelectorPropsType = SelectProps & {
  plate: Partial<PlateType>;
  quadrant?: number;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const IndexSetSelector = ({
  plate,
  quadrant = 1,
  onValueChange,
  ...props
}: IndexSetSelectorPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { indexSets, hasFetchedNames } = useSelector((state: RootState) => ({
    indexSets: state.indexSets.names,
    hasFetchedNames: state.indexSets.hasFetchedNames,
  }));

  useEffect(() => {
    if (!indexSets?.length && !hasFetchedNames) {
      dispatch(getIndexSetsNames());
    }
  }, [dispatch, indexSets, hasFetchedNames]);

  const quadrantKey = `quadrant${quadrant}_index_set_name` as keyof PlateType;
  const plateIndexSet = plate[quadrantKey] as string;

  const indexSetOptions = indexSets.map((name) => ({
    id: name,
    label: name,
    value: name,
  }));

  const handleOnChange = (value: string | null) => {
    if (value) {
      onValueChange(quadrantKey, value);
    }
  };

  useEffect(() => {
    if (plateIndexSet === undefined && indexSetOptions.length) {
      handleOnChange(indexSetOptions[0].value);
    }
  }, [plateIndexSet, indexSets]);

  const labelString = `${getString(
    'quadrant',
    language,
  )} ${quadrant} ${getString('indexSet', language)}`;
  return (
    <Select
      onChange={handleOnChange}
      data={indexSetOptions}
      value={plateIndexSet}
      label={labelString}
      {...props}
    />
  );
};
