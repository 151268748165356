import React from 'react';
import { Box } from '@mantine/core';

import { MapboxSample } from 'store/fields/types';

import { FiveNinetyMetadata, type Props as MetadataProps } from './FiveNinetyMetadata';
import { LabSampleTable } from './LabSampleTable';

import styles from './FiveNinety.module.css';

type Props = {
  samples?: MapboxSample[];
  showMaps?: boolean; // TODO: rm after ticket done
  metadataProps: MetadataProps;
};

export const FiveNinetyContent = ({ samples, metadataProps }: Props) => {
  return (
    <Box mb="xl" className={styles.Content} id="test">
      <div>
        <FiveNinetyMetadata {...metadataProps} />
        <LabSampleTable samples={samples} />
      </div>
    </Box>
  );
};
