import React, { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestGetAllPlates } from 'store/plates/requests';
import { PlateType } from 'store/plates/types';

import { Typeahead, TypeaheadPropsType } from './Components/Mantine/Typeahead';

type PlateSearchBarPropsType = Omit<TypeaheadPropsType, 'onSelect' | 'data'> & {
  onSelect: (barcode: string) => void;
  onDeselect: () => void;
  autofocus?: boolean;
  plateTypes?: string[];
  all?: boolean;
  labIdMode?: boolean;
  batchError?: boolean;
  forceSelect?: boolean;
};

export const PlateSearchBar = ({
  onSelect,
  onDeselect,
  autofocus,
  plateTypes,
  all,
  labIdMode,
  batchError,
  forceSelect,
  ...props
}: PlateSearchBarPropsType) => {
  const language = useBroswerLanguage();
  const [filterBarcode, setFilterBarcode] = useState<string>();
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [plates, setPlates] = useState<PlateType[]>([]);
  const [error, setError] = useState<string>();

  const debounceFetchPlates = useDebouncedCallback(
    async (barcode: string) => {
      try {
        if (barcode) {
          setIsFetching(true);
          const response = await requestGetAllPlates({
            page: 1,
            barcode: labIdMode ? undefined : barcode,
            labId: labIdMode ? [barcode] : undefined,
            plateType: plateTypes,
            sortBy: 'barcode',
            order: 'asc',
            all,
          });
          if (response.plates) {
            setPlates(response.plates);
          }
        } else {
          setPlates([]);
        }
      } catch (e) {
        showToast(e.message);
      }
      setIsFetching(false);
      setTriggerSearch(false);
    },
    300,
    { trailing: true },
  );

  useEffect(() => {
    if (triggerSearch) {
      debounceFetchPlates(filterBarcode || '');
    }
  }, [debounceFetchPlates, filterBarcode]);

  const plateOptions = useMemo(() => {
    return plates.map((p) => {
      const plateId = labIdMode ? String(p.homogenization_plate_id) : p.barcode;
      return {
        id: p.id,
        label: plateId,
        value: p,
      };
    });
  }, [plates]);

  const handleSelect = (value: PlateType) => {
    if (batchError) {
      setError(value.batch_id ? `* ${getString('plateHasBatchErrorMsg', language)}` : undefined);
    }
    const plateId = labIdMode ? String(value.homogenization_plate_id) : value.barcode;
    setFilterBarcode(plateId);
    onSelect(plateId);
  };

  const handleDeselect = () => {
    setFilterBarcode('');
    setError('');
    onDeselect();
  };

  const handleTextChange = (text: string) => {
    setTriggerSearch(true);
    setFilterBarcode(text);
    if (!forceSelect) {
      onSelect(text);
    }
  };

  return (
    <Typeahead
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onTextChange={handleTextChange}
      data={plateOptions}
      value={filterBarcode}
      autofocus={autofocus}
      waitForKeyPress
      isLoading={isFetching}
      error={error}
      {...props}
    />
  );
};
