import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import classNames from 'classnames';

import { CROP_PROTECTION } from 'constants/results';
import { CREATED, READY, UNASSIGNED } from 'constants/samplePlanning';

import { getLatestCropPlan } from 'util/cropPlans';
import { hasGeom } from 'util/field';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { SAMPLE_STATUSES, SamplePlanType } from 'store/samplePlans/types';
import { UserRole } from 'store/user/types';
import { AdminAccess, OperationManagerAccess, TableCol } from 'common';

import Actions from '../common/Actions';
import FieldsTableRowCommon from '../common/FieldRow';
import AssignSampler from '../OrderButtons/AssignSampler';
import MarkReady from '../OrderButtons/MarkReady';

import Confirmation from './Confirmation/Confirmation';
import ConfirmationModalV2 from './ConfirmationModalV2';
import TableRowSamplingPlans from './TableRowSamplingPlans';

import styles from './TableRow.module.css';

type FieldsTableRowPropsType = {
  field: FieldType;
  hasFetched: Boolean;
  samplePlans: SamplePlanType[];
  getFieldsWithUpdates: () => void;
};

const FieldsTableRow = ({
  field,
  hasFetched,
  samplePlans,
  getFieldsWithUpdates,
}: FieldsTableRowPropsType) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const fieldProps = field.features[0].properties;
  const { operation } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[fieldProps.operation_id],
  }));
  const [confirmationValues, setConfirmationValues] = useState<SamplePlanType | null>(null);
  const [showPastSamplingPlans, toggleShowPastSamplingPlans] = useState(false);
  const [showMarkReady, setShowMarkReady] = useState(false);
  const [showAssignSampler, setShowAssignSampler] = useState(false);
  const [lastSamplingPlan, ...pastSamplingPlans] = samplePlans;
  const cropPlan = getLatestCropPlan(field);

  const getSamplePlanActions = () => {
    if (!lastSamplingPlan) {
      return (
        <OperationManagerAccess>
          <Actions
            operationId={fieldProps.operation_id}
            fieldId={fieldProps.id}
            samplingPlan={lastSamplingPlan}
            hasGeom={hasGeom(field)}
          />
        </OperationManagerAccess>
      );
    }

    return (
      <>
        {lastSamplingPlan.sampling_status === CREATED && !lastSamplingPlan.ready_to_sample && (
          <Button
            data-test-id="mark-ready-button"
            className={styles.PrimaryAction}
            onClick={() => setShowMarkReady(true)}
          >
            {getString('markReady', language)}
          </Button>
        )}
        <AdminAccess>
          {[CREATED, UNASSIGNED, READY].includes(lastSamplingPlan.sampling_status) && (
            <Button
              data-test-id="assign-sampler"
              className={styles.PrimaryAction}
              onClick={() => setShowAssignSampler(true)}
            >
              {`${
                lastSamplingPlan.assigned_user_role === UserRole.Sampler
                  ? getString('reassign', language)
                  : getString('assign', language)
              } Sampler`}
            </Button>
          )}
          {lastSamplingPlan.sampling_status === SAMPLE_STATUSES.COMPLETED && (
            <Button
              className={styles.PrimaryAction}
              onClick={() =>
                navigate(
                  `/results/field/${fieldProps.operation_id}/${lastSamplingPlan.field_id}/${lastSamplingPlan.id}/${CROP_PROTECTION}?crop=${cropPlan?.crop}`,
                )
              }
            >
              {getString('viewResults', language)}
            </Button>
          )}
        </AdminAccess>
        <OperationManagerAccess>
          <Actions
            operationId={fieldProps.operation_id}
            fieldId={fieldProps.id}
            samplingPlan={lastSamplingPlan}
            hasGeom={hasGeom(field)}
            pastSamplingPlans={pastSamplingPlans}
            toggleShowPastSamplingPlans={() => toggleShowPastSamplingPlans(!showPastSamplingPlans)}
            setConfirmationValues={setConfirmationValues}
          />
        </OperationManagerAccess>
      </>
    );
  };

  const getConfirmationModal = () => {
    if (!confirmationValues || fieldProps.id !== confirmationValues?.field_id) {
      return null;
    }
    if (confirmationValues.sampling_plan_skus?.length) {
      return (
        <ConfirmationModalV2
          field={field}
          confirmationValues={confirmationValues}
          setConfirmationValues={setConfirmationValues}
        />
      );
    }
    return (
      <Confirmation
        field={field}
        confirmationValues={confirmationValues}
        setConfirmationValues={setConfirmationValues}
      />
    );
  };

  return (
    <div className={styles.FieldRowWrapper} id={`field-row-${fieldProps.id}`}>
      {!!confirmationValues && getConfirmationModal()}
      <FieldsTableRowCommon
        className={styles.Row}
        field={field}
        samplePlan={lastSamplingPlan}
        hasFetched={hasFetched}
        showFieldThumbnail
      >
        <TableCol className={classNames(styles.TableCol, styles.TooltipCol, styles.HideForPrint)}>
          {getSamplePlanActions()}
        </TableCol>
      </FieldsTableRowCommon>
      {showPastSamplingPlans && (
        <TableRowSamplingPlans samplingPlans={pastSamplingPlans} field={field} />
      )}
      {showMarkReady && (
        <MarkReady
          samplePlans={samplePlans}
          samplePlanId={lastSamplingPlan.id}
          fieldId={fieldProps.id}
          onClose={() => setShowMarkReady(false)}
          opened={showMarkReady}
        />
      )}
      {showAssignSampler && (
        <AssignSampler
          samplePlan={lastSamplingPlan}
          fieldId={fieldProps.id}
          onClose={() => setShowAssignSampler(false)}
          getFieldsWithUpdates={getFieldsWithUpdates}
          billing_user_id={operation?.billing_user_id}
          opened={showAssignSampler}
          operationId={fieldProps.operation_id}
        />
      )}
    </div>
  );
};

export default FieldsTableRow;
