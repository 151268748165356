import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { CROP_PROTECTION } from 'constants/results';
import { CORN } from 'constants/variables';

import { getLatestCropPlan } from 'util/cropPlans';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getLastSamplingPlan } from 'util/samplePlan';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import { Button, EditIcon, FieldThumbnail } from 'common';

import styles from './Field.module.css';

type FieldPropsType = {
  field: FieldType;
  router: string;
};

const Field = ({ field, router }: FieldPropsType) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const [showEditButton, toggleShowEditButton] = useState(false);
  const fieldProps = field.features[0].properties;
  const lastSamplingPlan = getLastSamplingPlan(fieldProps);
  const cropPlan = getLatestCropPlan(field);

  return (
    <div className={styles.FieldCell}>
      <div
        className={styles.FieldThumbnail}
        onMouseEnter={() => toggleShowEditButton(true)}
        onMouseLeave={() => toggleShowEditButton(false)}
      >
        {field.type && <FieldThumbnail features={field} />}
        {showEditButton && (
          <Button
            circle
            className={classNames(styles.EditBoundariesIcon)}
            title={getString('editFieldBoundary', language)}
            onClick={() => {
              navigate(`/field-boundary/${fieldProps.operation_id}/${fieldProps.id}`);
            }}
          >
            <EditIcon />
          </Button>
        )}
      </div>
      <div className={styles.FieldName}>
        <Link
          to={`/${router}/field/${fieldProps.operation_id}/${
            fieldProps.id
          }/${lastSamplingPlan?.id || 0}/${CROP_PROTECTION}?crop=${cropPlan?.crop || CORN}`}
        >
          {fieldProps?.farm_name ? `${fieldProps?.farm_name}: ` : ' '}
          {fieldProps.name}
        </Link>
      </div>
    </div>
  );
};

export default Field;
