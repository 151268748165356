import { AppThunk } from 'store';

import {
  batchRequestError,
  receiveBatch,
  receiveBatches,
  receiveBatchFiles,
  requestBatches,
} from './actions';
import {
  requestBatchSampleSheet,
  requestCreateBatchShotgun,
  requestGetBatch,
  requestGetBatches,
  requestGetBatchUploadedFile,
  requestMarkBatchSent,
  requestPostBatchFileUpload,
  requestPutBatchUploadedFile,
  requestUpdateBatch,
} from './requests';

export const getBatches =
  (page: number, type?: string[], status?: string[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(requestBatches());
    try {
      const { fetchRequestNumber } = getState().batches;
      const results = await requestGetBatches({ page, type, status });
      dispatch(receiveBatches({ ...results, fetchRequestNumber }));
    } catch (e) {
      dispatch(batchRequestError({ message: `Error Fetching Batches: ${e.message}` }));
    }
  };

export const getBatch =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestBatches());
    try {
      const batch = await requestGetBatch(id);
      dispatch(receiveBatch(batch));
    } catch (e) {
      dispatch(batchRequestError({ message: `Error Fetching Batch: ${e.message}` }));
    }
  };

export const addNewBatch =
  (attrs: {
    plates_barcodes: Array<string>;
    type: string;
    barcode_lanes?: { [lane: number]: string[] };
  }): AppThunk =>
  async (dispatch) => {
    dispatch(requestBatches());
    try {
      const batch = await requestCreateBatchShotgun(attrs);
      const action = receiveBatch(batch);
      dispatch(action);
      return action;
    } catch (e) {
      const action = batchRequestError({
        message: `Error Creating Batch: ${e.message}`,
      });
      dispatch(action);
      return action;
    }
  };

export const updateBatch =
  (id: number, newData: any): AppThunk =>
  async (dispatch) => {
    dispatch(requestBatches());
    try {
      const batch = await requestUpdateBatch(id, newData);
      dispatch(receiveBatch(batch));
    } catch (e) {
      dispatch(batchRequestError({ message: `Error Updating Batch: ${e.message}` }));
    }
  };

export const shipBatch =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestBatches());
    try {
      const batch = await requestMarkBatchSent(id);
      dispatch(receiveBatch(batch));
    } catch (e) {
      dispatch(batchRequestError({ message: e.message }));
    }
  };

export const downloadFullSampleSheet =
  (batchId: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestBatchSampleSheet(batchId);
      if (!response.sample_sheet_url) {
        throw Error(`Sample Sheet URL is not present`);
      }
      return response;
    } catch (e) {
      dispatch(batchRequestError({ message: e.message }));
    }
  };

export const downloadMiSeqSampleSheet =
  (batchId: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestBatchSampleSheet(batchId);
      if (!response.miseq_sample_sheet_url) {
        throw Error(`Sample Sheet URL is not present`);
      }
      return response;
    } catch (e) {
      dispatch(batchRequestError({ message: e.message }));
    }
  };

export const getBatchFiles =
  (batchId: number): AppThunk =>
  async (dispatch) => {
    try {
      const files = await requestGetBatchUploadedFile(batchId);
      dispatch(receiveBatchFiles(batchId, files.batch_uploaded_files));
    } catch (e) {
      await dispatch(batchRequestError({ message: e.message }));
      throw e;
    }
  };

export const uploadBatchFile =
  (batchId: number, file: File): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestPostBatchFileUpload(batchId, file.name);

      await fetch(response.upload_url, {
        method: 'PUT',
        body: file,
      });

      await requestPutBatchUploadedFile(response.id, { uploaded: true });
      dispatch(getBatchFiles(batchId));
    } catch (e) {
      await dispatch(batchRequestError({ message: e.message }));
      throw e;
    }
  };
