import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Center, Loader, Stack, Text } from '@mantine/core';
import { useDebouncedCallback, useScrollIntoView } from '@mantine/hooks';

import { CROP_PROTECTION, NUTRIENTS } from 'constants/results';
import { allCrops, CORN, MAX_FIELDS_FOR_SAMPLE_LOAD } from 'constants/variables';

import { getFieldsWithPlansForAnalyticCatV2 } from 'util/chartUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import useOperationSamples from 'util/hooks/useOperationSamples';
import { getFieldAnalyticsByCrop } from 'util/overviewResultsDisplay';
import {
  analyticPreferenceList,
  getFieldHasSamplingPlanWithExternalLab,
  getSubCategoryAnalyticsV2,
} from 'util/results';
import { getLastSamplingPlan, isPlanCompleted } from 'util/samplePlan';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';

import RiskLegend from '../../common/RiskLegendV2';

import CropFieldRows from './CropFieldRows';

import styles from './Container.module.css';

type ContainerPropsType = {
  operation: OperationType;
  samplingPlans: SamplingPlanType[];
  analysis: string | undefined;
  completeOnly: boolean;
  cropYear: number | null;
  isPrinting: boolean;
};

const AnalysisContainer = ({
  operation,
  samplingPlans,
  analysis,
  completeOnly,
  cropYear,
  isPrinting,
}: ContainerPropsType) => {
  const cropRefs = allCrops.reduce(
    // Ability to scroll into view
    (acc, crop) => ({ ...acc, [crop]: useScrollIntoView<HTMLDivElement>({ offset: 150 }) }),
    {},
  );
  const language = useBroswerLanguage();
  const [highlighted, setHighlighted] = useState(CORN);
  const [isLoading, toggleIsLoading] = useState<boolean>(true);
  const [isLoadingWithoutShownFields, toggleIsLoadingWithoutShownFields] = useState<boolean>(true);
  const operationFieldIds = operation.fields.map((field) => field.id);

  const { analytics, analyticSortOrder } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    analyticSortOrder: state.analytics.analyticSortOrder,
  }));

  const { allFieldGeometries, isFetchingList, willFetchList, hasFailedList } =
    useOperationFieldGeometries(operation, true, false, cropYear);

  operation.fields?.length < MAX_FIELDS_FOR_SAMPLE_LOAD && useOperationSamples(operation, cropYear);

  const fieldGeometryList = operationFieldIds
    .filter((id) => allFieldGeometries[id])
    .map((id) => allFieldGeometries[id]);
  const fieldsInCropYear = cropYear
    ? operation.fields.filter((field) => field.crop_years.includes(cropYear))
    : [];

  const { subCategoryAnalytics, analyticsPerTab } = getSubCategoryAnalyticsV2(
    analyticPreferenceList(operation, analytics),
    fieldGeometryList,
    analyticSortOrder?.[analysis || CROP_PROTECTION] || {},
  );

  const filteredFields = getFieldsWithPlansForAnalyticCatV2(
    samplingPlans,
    fieldGeometryList,
    analyticsPerTab.map((analytic) => analytic.id),
  ).filter((field) => {
    if (completeOnly) {
      const plan = getLastSamplingPlan(field.features[0].properties);
      return plan && isPlanCompleted(plan);
    }
    return true;
  });

  const handleScroll = useDebouncedCallback(() => {
    allCrops.forEach((crop) => {
      const cropIcon = document.getElementById(`crop-icon-${crop}`);
      if (cropIcon) {
        const { top } = cropIcon.getBoundingClientRect();
        if (top < window.innerHeight / 2) {
          setHighlighted(crop);
        }
      }
    });
  }, 250);

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('scroll', () => handleScroll(), { passive: true });
    }
    return window.removeEventListener('scroll', () => handleScroll());
  }, [isLoading, handleScroll]);

  useEffect(() => {
    // Reset loading state when crop year changes
    toggleIsLoading(true);
    toggleIsLoadingWithoutShownFields(true);
  }, [cropYear]);

  useEffect(() => {
    if (isLoading) {
      if (!operation.crop_years.length || filteredFields.length) {
        toggleIsLoadingWithoutShownFields(false);
      }
      if (
        fieldsInCropYear.every((field) =>
          fieldGeometryList.find((f) => f.features[0].properties.id === field.id),
        )
      ) {
        setTimeout(() => toggleIsLoading(false), 300);
      }
    }
  }, [filteredFields, isLoading, fieldsInCropYear, operation.crop_years, cropYear]);

  const fieldsByCrop = getFieldAnalyticsByCrop(
    filteredFields,
    subCategoryAnalytics,
    operation.fields,
  );

  const hasLoadedFields = !!Object.values(fieldsByCrop).flat().length;

  if (!operation.crop_years.length || (!hasLoadedFields && !isLoading)) {
    return (
      <Center maw="100%" h={200} id="no-results-operation">
        <Text size="lg">{getString('noResultsExistForOperation', language)}</Text>
      </Center>
    );
  }
  if (isLoadingWithoutShownFields && isLoading) {
    return (
      <Center maw="100%" h={200}>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack className={styles.StackWrapper}>
      {hasLoadedFields && (
        <RiskLegend
          clickCropSideBar={(crop: string) => cropRefs[crop]?.scrollIntoView()}
          crops={Object.keys(fieldsByCrop).filter((crop) => fieldsByCrop[crop].length)}
          highlighted={highlighted}
          showExternalLabItem={
            analysis === NUTRIENTS &&
            filteredFields.some((field) => getFieldHasSamplingPlanWithExternalLab(field))
          }
        />
      )}
      {Object.keys(subCategoryAnalytics).map((key, idx) => (
        <CropFieldRows
          key={idx}
          cropType={key}
          subCategoryAnalyticsFiltered={subCategoryAnalytics}
          filteredFields={fieldsByCrop[key]}
          samplingPlans={samplingPlans}
          isFetchingList={isFetchingList}
          willFetchList={willFetchList}
          hasFailedList={hasFailedList}
          cropRef={cropRefs[key]}
          isLoading={isLoading}
          isPrinting={isPrinting}
          analysis={analysis}
        />
      ))}
    </Stack>
  );
};

export default AnalysisContainer;
