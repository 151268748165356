import { OrderFormProvider, useOrderForm } from 'apps/ZoneAnalysisV3/orderFormContext';

import { is590DensityValid } from 'util/samplePlan';
import { getDefaultAnalysisV3 } from 'util/samplePlanV3';
import { FieldType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import { TimingsResponse } from 'store/samplingTimings/types';
import { User } from 'store/user/types';

import ZoneAnalysisV3Header from '../ZoneAnalysisV3Header';

import OrderFormStepper from './OrderFormStepper';

type Props = {
  fieldFeatureCollection?: FieldType;
  operation?: OperationType;
  operationUsers?: User[];
  timingsConfig?: TimingsResponse;
};

const SampleOrderSteps = ({
  fieldFeatureCollection,
  operation,
  operationUsers,
  timingsConfig,
}: Props) => {
  const field = fieldFeatureCollection?.features[0];

  const form = useOrderForm({
    mode: 'uncontrolled',
    initialValues: getDefaultAnalysisV3(
      operation,
      operationUsers,
      timingsConfig,
      fieldFeatureCollection,
    ),
    transformValues: (values) => {
      const { isProScan, isTillRx, nutrient, truBio, truTill, scanPoints, zones, is590Analysis } =
        values;
      const isProOrTillRx = isProScan || isTillRx;
      const hasBioProducts = truBio || (!isProOrTillRx && nutrient);
      return {
        isProOrTillRx,
        hasBioProducts,
        isSampleOnly: hasBioProducts && !isProOrTillRx,
        isSampleAndScan: hasBioProducts && isProOrTillRx,
        isScanPointsOnly: !hasBioProducts && isProOrTillRx,
        canSplitDensity: hasBioProducts && nutrient && !isProOrTillRx,
        hasProducts: nutrient || truBio || truTill,
        scanPointsCount: scanPoints?.features.length || 0,
        zonesCount: zones?.features.length || 0,
        is590DensityValid: is590DensityValid(
          is590Analysis,
          zones,
          isProScan,
          fieldFeatureCollection,
        ),
      };
    },
  });

  return (
    <OrderFormProvider form={form}>
      {!!field && (
        <ZoneAnalysisV3Header name={field.properties.name} acreage={field.properties.acreage} />
      )}
      <OrderFormStepper />
    </OrderFormProvider>
  );
};

export default SampleOrderSteps;
