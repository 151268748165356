import { AppThunk } from 'store';

import { naptControlRequestError, receiveNaptControls, requestNaptControls } from './actions';
import { requestGetNaptControls } from './requests';

export const getAllNaptControls = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestNaptControls());
    const controls = await requestGetNaptControls();
    dispatch(receiveNaptControls(controls));
  } catch (e) {
    dispatch(naptControlRequestError({ message: e.message }));
  }
};
