import React, { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { ComboboxItem, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { COMBINED_ANALYTICS_TO_HIDE, IMAGERY, SOIL_TEST, YIELD } from 'constants/prescription';
import { BIOFERTILITY, PATHOGENS, SOIL_ATTRIBUTES } from 'constants/results';

import { isNumber } from 'util/numUtils';
import { convertNumericCustomZonesToString, getDefaultRxCustomZones } from 'util/prescription';
import { getAnalyticFromPlan } from 'util/results';
import { sortByValue } from 'util/sortByName';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FieldType } from 'store/fields/types';
import { SampleFeatureType } from 'store/samples/types';

import { PrescriptionStateType } from './Container';

interface LayerSelectorProps {
  field: FieldType;
  language: string;
  proLayer?: EOInferenceLayerType | null;
  rxForm: UseFormReturnType<PrescriptionStateType>;
  samples?: SampleFeatureType[];
}

type LayerGroupType = {
  group: string;
  items: ComboboxItem[];
};

const LayerSelector = ({ field, language, proLayer, rxForm, samples = [] }: LayerSelectorProps) => {
  const { crop, customMapLayer, samplePlan } = rxForm.getValues();

  const { analytics } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
  }));

  const { composite_imagery_layers, harvest_data_files, sampling_plans } =
    field.features[0].properties;

  const analyticLookup = Object.fromEntries(analytics.map((cur) => [cur.id, cur]));

  const selectorOptions = useMemo(() => {
    const selectedPlan = sampling_plans.find((plan) => plan.id === Number(samplePlan));

    const mapLayers: LayerGroupType = {
      group: getString('maps', language),
      items: [
        harvest_data_files.length && { label: getString('yield', language), value: YIELD },
        composite_imagery_layers.length && {
          label: getString('imagery', language),
          value: IMAGERY,
        },
      ].filter(Boolean) as ComboboxItem[],
    };

    const analyticSections = [
      [SOIL_ATTRIBUTES, getString('nutrients', language)],
      [PATHOGENS, getString('crop-protection', language)],
      [BIOFERTILITY, getString('bioactive', language)],
    ];

    const analyticGroups = analyticSections.map((section) => {
      const [category, group] = section;
      const groupAnalytics = selectedPlan?.analytics?.[category] || {};
      return {
        group,
        items: sortByValue(
          Object.keys(groupAnalytics)
            .map((analyticId) => {
              const numericId = Number(analyticId);
              if (COMBINED_ANALYTICS_TO_HIDE.includes(numericId)) {
                return null;
              }
              const analytic = analyticLookup[numericId];
              if (!analytic?.show_in_ui) {
                return null;
              }
              const planAnalytic = analytic ? getAnalyticFromPlan(selectedPlan, analytic) : null;
              return Object.keys(planAnalytic || {}).length
                ? {
                    label: analytic.name,
                    value: analyticId,
                  }
                : null;
            })
            .filter((item) => item !== null) as ComboboxItem[],
          'label',
        ),
      };
    });
    return [mapLayers].concat(analyticGroups).filter((group) => group.items.length);
  }, [analytics, samplePlan]);

  const handleLayerChange = (val: string) => {
    rxForm.setFieldValue('customMapLayer', val);
    if (isNumber(val)) {
      const analytic = analyticLookup[Number(val)];
      if (analytic) {
        rxForm.setValues({
          analytic: analytic,
          customZones: convertNumericCustomZonesToString(
            getDefaultRxCustomZones(samples, SOIL_TEST, analytic, proLayer),
          ),
          harvestLayer: null,
          imageryLayer: null,
        });
      }
    } else if (val === YIELD) {
      const sortedLayers = harvest_data_files.sort((a, b) =>
        a.start_time < b.start_time ? 1 : -1,
      );
      const newHarvestLayer = sortedLayers[0];
      if (newHarvestLayer) {
        rxForm.setValues({
          analytic: null,
          crop: newHarvestLayer.crop || crop,
          customZones: convertNumericCustomZonesToString(
            getDefaultRxCustomZones([], YIELD, null, newHarvestLayer),
          ),
          harvestLayer: newHarvestLayer.processed_geojson_uri,
          imageryLayer: null,
        });
      }
      rxForm.setFieldValue('harvestLayer', harvest_data_files[0]?.processed_geojson_uri || null);
    } else if (val === IMAGERY) {
      const sortedLayers = composite_imagery_layers.sort((a, b) => (a.year < b.year ? 1 : -1));
      const newImageryLayer = sortedLayers[0];
      if (newImageryLayer) {
        rxForm.setFieldValue('imageryLayer', newImageryLayer.geojson_uri);
        rxForm.setValues({
          analytic: null,
          customZones: convertNumericCustomZonesToString(
            getDefaultRxCustomZones([], IMAGERY, null, newImageryLayer),
          ),
          harvestLayer: null,
          imageryLayer: newImageryLayer.geojson_uri,
        });
      }
    }
  };

  return (
    <>
      <Text>{getString('rxBaseLayer', language)}</Text>
      <Select
        data={selectorOptions}
        leftSection={<FiSearch />}
        onChange={(val) => val && handleLayerChange(val)}
        searchable
        value={customMapLayer}
      />
    </>
  );
};

export default LayerSelector;
