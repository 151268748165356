import { ResultPreferencesType } from 'store/operation/types';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { User } from 'store/user/types';

export const RECEIVE_ALL_AGENCIES = 'RECEIVE_ALL_AGENCIES';
export const RECEIVE_MY_AGENCIES = 'RECEIVE_MY_AGENCIES';
export const RECEIVE_SINGLE_AGENCY = 'RECEIVE_SINGLE_AGENCY';
export const REQUEST_AGENCIES = 'REQUEST_AGENCIES';
export const SET_ACTIVE_AGENCY = 'SET_ACTIVE_AGENCY';
export const USER_AGENCY_ID = 'USER_AGENCY_ID';
export const RECEIVE_AGENCY_ASSOCIATED_USERS = 'RECEIVE_AGENCY_ASSOCIATED_USERS';
export const AGENCIES_REQUEST_ERROR = 'AGENCIES_REQUEST_ERROR';
export const REQUEST_AGENCY_USERS = 'REQUEST_AGENCY_USERS';
export const REQUEST_MY_AGENCIES = 'REQUEST_MY_AGENCIES';
export const REQUEST_AGENCY_CONTRACTS = 'REQUEST_AGENCY_CONTRACTS';
export const RECEIVE_AGENCY_CONTRACTS = 'RECEIVE_AGENCY_CONTRACTS';
export const RECEIVE_AGENCY_RESULT_PREFERENCES = 'RECEIVE_AGENCY_RESULT_PREFERENCES';
export const RECEIVE_DEFAULT_PRICES = 'RECEIVE_DEFAULT_PRICES';
export const RECEIVE_MIN_MAX_PRICES = 'RECEIVE_MIN_MAX_PRICES';
export const RECEIVE_TILLAGE_MSRP = 'RECEIVE_TILLAGE_MSRP';
export const RECEIVE_AGENCY_CONTRACT_METRICS = 'RECEIVE_AGENCY_CONTRACT_METRICS';
export const RECEIVE_AGENCY_CONTRACT_TIERS = 'RECEIVE_AGENCY_CONTRACT_TIERS';

export interface DefaultContractPriceType {
  [tier: string]: {
    [density: number]: {
      [product: string]: number;
    };
  };
}

export interface ContractMinMaxType {
  [product: string]: {
    [type: string]: number[];
  };
}

export type ManageAgencyRouteParams = {
  agencyId: string;
};

export interface Agency {
  self_scanning?: boolean;
  id: number;
  name: string;
  primary_user: User;
  users: User[];
  allUsers?: User[];
  hierarchy: string;
  pricing_contracts?: ContractType[];
  parent_pricing_contracts?: ContractType[];
  metricsByContractId?: { [key: number]: ContractSamplingMetricsType };
  self_sampling?: boolean;
  allow_multiple_plans?: boolean;
  agency_logo_url?: string;
  child_agencies?: Agency[];
  editable_agencies?: number[];
  parent_agency?: Agency;
  parent_agency_id?: number | null;
  possible_recommendation_sets?: RecommendationSetType[];
  recommendation_set_id?: number;
  result_preferences?: ResultPreferencesType;
}

export type AgencyStateType = {
  currentAgency: Agency | null;
  allAgencies: Agency[];
  myAgencies: Agency[];
  allUsers: User[];
  byId: {
    [id: number]: Agency;
  };
  contractTiers: {
    [id: number]: DefaultContractPriceType;
  };
  default_prices: DefaultContractPriceType;
  min_max_prices: ContractMinMaxType;
  tillage_map_msrp: number;
  hasFailed: boolean;
  hasFetched: boolean;
  hasFetchedMyAgencies: boolean;
  isFetching: boolean;
  isFetchingMyAgencies: boolean;
  isFetchingContracts: boolean;
  isFetchingUsers: boolean;
  hasFailedList: number[];
  hasFetchedList: number[];
  isFetchingList: number[];
};

export interface ContractType {
  id: number;
  agency_id: number;
  created_by_id: number;
  committed_samples: number;
  contract_type: string;
  price_buckets: {
    [density: number]: ContractPriceBucketType;
  };
  notes: string;
  signed_at: string;
  expired_at: string;
  updated_at: string;
  created_at: string;
  is_current?: boolean;
  // Form attributes
  parent_contract_id?: number | null;
  is_not_editable?: boolean;
}

export type ContractResponseType = Omit<ContractType, 'price_buckets'> & {
  price_buckets: ContractPriceBucketType[];
};

export type ContractSubmitType = Omit<ContractType, 'signed_at' | 'expired_at'> & {
  signed_at: Date;
  expired_at: Date;
};

export type ContractSamplingMetricsType = {
  agency_id: number;
  planned_samples: number;
  taken_samples: number;
  completed_samples: number;
  planned: { [key: string]: number };
  sampled: { [key: string]: number };
  completed: { [key: string]: number };
};

export type ContractPriceBucketType = {
  id?: number;
  density?: number;
  pressure_panel_msrp: number;
  pressure_panel_agency: number;
  performance_panel_agency: number;
  performance_panel_msrp: number;
  nutrient_panel_agency: number;
  nutrient_panel_msrp: number;
  nitrate_panel_agency: number;
  nitrate_panel_msrp: number;
  performance_panel_list: number;
  pressure_panel_list: number;
  nutrient_panel_list: number;
  nitrate_panel_list: number;
  // Hybrid properties from backend
  complete_bio_msrp: number;
  pattern_360_msrp: number;
  complete_bio_agency: number;
  pattern_360_agency: number;
  complete_bio_list: number;
  pattern_360_list: number;
};

interface RequestAgenciesAction {
  type: typeof REQUEST_AGENCIES;
  payload: {
    id?: number;
  };
}

interface RequestMyAgenciesAction {
  type: typeof REQUEST_MY_AGENCIES;
  payload: {
    id?: number;
  };
}

interface RequestAgencyUsersAction {
  type: typeof REQUEST_AGENCY_USERS;
}

interface RecieveAllAgenciesAction {
  type: typeof RECEIVE_ALL_AGENCIES;
  payload: {
    agencies: Agency[];
  };
}

interface RecieveMyAgenciesAction {
  type: typeof RECEIVE_MY_AGENCIES;
  payload: {
    agencies: Agency[];
  };
}

interface RecieveSingleAgencyAction {
  type: typeof RECEIVE_SINGLE_AGENCY;
  payload: Agency;
}

interface AgenciesRequestError {
  type: typeof AGENCIES_REQUEST_ERROR;
  payload: {
    id?: number;
  };
}

interface RecieveAgencyUsersAction {
  type: typeof RECEIVE_AGENCY_ASSOCIATED_USERS;
  payload: {
    id: number;
    users: User[];
  };
}

type SetActiveAgencyActionType = {
  type: typeof SET_ACTIVE_AGENCY;
  payload: number;
};

interface RequestAgencyContractsAction {
  type: typeof REQUEST_AGENCY_CONTRACTS;
}

interface RecieveAllAgencyContractsAction {
  type: typeof RECEIVE_AGENCY_CONTRACTS;
  payload: {
    agencyId: number;
    contracts: {
      agency: ContractResponseType[];
      parent_agency: ContractResponseType[];
    };
  };
}

interface ReceiveDefaultPricesAction {
  type: typeof RECEIVE_DEFAULT_PRICES;
  payload: DefaultContractPriceType;
}

interface ReceiveMinMaxPricesAction {
  type: typeof RECEIVE_MIN_MAX_PRICES;
  payload: ContractMinMaxType;
}

interface ReceiveTillageMsrpAction {
  type: typeof RECEIVE_TILLAGE_MSRP;
  payload: number;
}

interface ReceiveAgencyResultPreferencesActionType {
  type: typeof RECEIVE_AGENCY_RESULT_PREFERENCES;
  payload: {
    preferences: ResultPreferencesType;
    id: number;
  };
}

interface ReceiveAgencyContractMetricsActionType {
  type: typeof RECEIVE_AGENCY_CONTRACT_METRICS;
  payload: {
    agencyId: number;
    contractId: number;
    metrics: ContractSamplingMetricsType;
  };
}

interface ReceiveContractTiersActionType {
  type: typeof RECEIVE_AGENCY_CONTRACT_TIERS;
  payload: {
    agencyId: number;
    contractTiers: DefaultContractPriceType;
  };
}

export type AgencyActionType =
  | RequestAgenciesAction
  | RequestMyAgenciesAction
  | RecieveAllAgenciesAction
  | RecieveMyAgenciesAction
  | RecieveSingleAgencyAction
  | RecieveAgencyUsersAction
  | AgenciesRequestError
  | SetActiveAgencyActionType
  | RequestAgencyUsersAction
  | RequestAgencyContractsAction
  | RecieveAllAgencyContractsAction
  | ReceiveDefaultPricesAction
  | ReceiveMinMaxPricesAction
  | ReceiveTillageMsrpAction
  | ReceiveAgencyResultPreferencesActionType
  | ReceiveAgencyContractMetricsActionType
  | ReceiveContractTiersActionType;
