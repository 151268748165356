import React from 'react';
import { Stack } from '@mantine/core';

import { PlateType } from 'store/plates/types';

import { QuadrantInput } from '../FormInputs/Quadrant/QuadrantInput';

type QPCR96FormPropsType = {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const QPCR96Form = ({ plate, onValueChange }: QPCR96FormPropsType) => (
  <Stack>
    <QuadrantInput plate={plate} onValueChange={onValueChange} target quadrant={1} />
  </Stack>
);
