import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';

import {
  ContractPricingGetResponse,
  ContractPricingPayload,
  DefaultPricingResponse,
  GeneratePriceEstimatePayload,
  IncompleteSalesOrderType,
  PaymentPayloadType,
  PriceDetailType,
  PriceEstimateResponse,
  SkuType,
} from './types';

export const getAllSkus = async (): Promise<SkuType[]> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/skus/`));

export const createSku = async (sku: Partial<SkuType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/skus/`, { body: sku }));

export const updateSku = async (sku: Partial<SkuType>) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/skus/${sku.id}`, { body: sku }));

export const deleteSku = async (skuId: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/skus/${skuId}`));

export const getDefaultPricing = async (): Promise<DefaultPricingResponse> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/default_pricing/`));

export const createDefaultPrice = async (defaultPricing: Partial<PriceDetailType>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/default_pricing/`, { body: defaultPricing }),
  );

export const updateDefaultPrice = async (defaultPricing: Partial<PriceDetailType>) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/default_pricing/${defaultPricing.id}`, {
      body: defaultPricing,
    }),
  );

export const deleteDefaultPrice = async (defaultPricingId: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/default_pricing/${defaultPricingId}`));

export const getContractsByAgency = async (agencyId = ''): Promise<ContractPricingGetResponse> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/pricing_contracts_v2/${agencyId}`));

export const createContractPricing = async (body: ContractPricingPayload) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/pricing_contract_v2/`, { body }));

export const updateContractPricing = async (
  pricingContractId: number,
  body: ContractPricingPayload,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/pricing_contract_v2/${pricingContractId}`, { body }),
  );

export const generatePriceEstimate = async (
  payload: GeneratePriceEstimatePayload,
): Promise<PriceEstimateResponse> =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/generate_price_estimate/`, { body: payload }),
  );

export const createPaymentV2 = async ({
  billing_user_id,
  sampling_plan_ids,
  adjustment_ft_ids,
  is_payment_only,
}: PaymentPayloadType) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/payment_v2/${billing_user_id}`, {
      body: { sampling_plan_ids, adjustment_ft_ids, is_payment_only },
    }),
  );

export const getIncompleteSalesOrders = async (): Promise<IncompleteSalesOrderType[]> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/incomplete_sales_orders/`));

export const retryIncompleteSalesOrder = async () =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/incomplete_sales_orders/`));
