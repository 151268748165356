import React, { FunctionComponent, ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';

import { ExpandIcon } from './Icon';

import styles from './Accordion.module.css';

type AccordionProps = {
  className?: string;
  toggleOpen?: () => void;
  open?: boolean;
  title: string;
  placeholder?: string;
  showPlaceholderAlways?: boolean;
  borderless?: boolean;
  dataTestId?: string;
  children?: ReactNode;
};

export const Accordion: FunctionComponent<AccordionProps> = ({
  children,
  className,
  toggleOpen,
  open,
  dataTestId,
  title,
  placeholder,
  showPlaceholderAlways,
  borderless,
}) => {
  const [openState, toggleOpenState] = useState(open || false);

  const handleOpen = useMemo(
    () => toggleOpen || (() => toggleOpenState(!openState)),
    [openState, toggleOpenState, toggleOpen],
  );

  const getOpened = useMemo(() => (open !== undefined ? open : openState), [open, openState]);

  return (
    <div
      className={classNames(styles.Accordion, { [styles.BottomBorder]: !borderless }, className)}
    >
      <div className={styles.AccordionHeader} onClick={handleOpen}>
        <span className={styles.AccordionTitle}>{title}</span>
        <span className={styles.AccordionButton} data-test-id={dataTestId}>
          {getOpened ? (
            <ExpandIcon className={classNames(styles.DropDownIcon, styles.Flipped)} />
          ) : (
            <ExpandIcon className={styles.DropDownIcon} />
          )}
        </span>
      </div>
      {placeholder && (
        <div
          className={classNames(styles.AccordionPlaceholder, {
            [styles.Open]: showPlaceholderAlways || !getOpened,
          })}
        >
          <div className={styles.PlaceholderInner}>
            <div className={styles.PlaceholderContent}>{placeholder}</div>
          </div>
        </div>
      )}
      <div className={getOpened ? styles.Open : ''}>
        <div className={styles.BodyInner}>
          <div className={styles.BodyContent}>{children}</div>
        </div>
      </div>
    </div>
  );
};
