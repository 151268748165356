import React from 'react';
import { FiCheck, FiList, FiMap, FiXCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import {
  ActionIcon,
  Button,
  Group,
  MultiSelect,
  SegmentedControl,
  Select,
  Space,
  Switch,
  Text,
} from '@mantine/core';

import { ALL_STATUSES } from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  countryTabs,
  getAssignmentOptions,
  getPlanCompletenessOptions,
  getProSelectionOptions,
  getReadinessOptions,
  getSamplePlanStatuses,
} from 'util/samplePlan';
import { getString, useTranslation } from 'strings/translation';
import { RootState } from 'store';
import { OperationAgencyListType } from 'store/samplePlans/types';
import { UserSelectorType } from 'store/user/types';
import { OperationAgencySearchBar, UserSearch } from 'common';

import styles from './TrackingFilters.module.css';

type TrackingFiltersPropsType = {
  operationOrAgency: OperationAgencyListType | undefined;
  showAllAccounts: boolean;
  setShowAllAccounts: (val: boolean) => void;
  samplerUser: UserSelectorType | undefined;
  setSamplerUser: (val: UserSelectorType | undefined) => void;
  setOperationOrAgency: (val: OperationAgencyListType | undefined) => void;
  setAlerts: (val: string[]) => void;
  setAssignment: (val: string) => void;
  setReadiness: (val: string) => void;
  setStatuses: (str: string[]) => void;
  statuses: string[];
  assignment: string;
  readiness: string;
  alerts: string[];
  setSelectedCountry: (str: string) => void;
  selectedCountry: string;
  setIsDisplayMap: (val: (old) => boolean) => void;
  isDisplayMap: boolean;
  samplingPlanLength: number;
  setProSelection: (val: string) => void;
  proSelection: string;
  isSamplerOnly: boolean;
  setIsSamplerOnly: (val: boolean) => void;
  setCompleteness: (val: string[]) => void;
  completeness: string[];
};

const TrackingFilters = ({
  operationOrAgency,
  showAllAccounts,
  setShowAllAccounts,
  setAlerts,
  setAssignment,
  setReadiness,
  setStatuses,
  statuses,
  assignment,
  readiness,
  alerts,
  setOperationOrAgency,
  setSamplerUser,
  samplerUser,
  setSelectedCountry,
  selectedCountry,
  setIsDisplayMap,
  isDisplayMap,
  samplingPlanLength,
  setProSelection,
  proSelection,
  isSamplerOnly,
  setIsSamplerOnly,
  setCompleteness,
  completeness,
}: TrackingFiltersPropsType) => {
  const alertNames = useSelector((state: RootState) => state.user.alertNames);
  const language = useBroswerLanguage();
  const countryTabsList = countryTabs(language);
  const samplePlanStatuses = getSamplePlanStatuses(language);
  const assignmentOptions = getAssignmentOptions(language);
  const readinessOptions = getReadinessOptions(language);
  const proSelectionOptions = getProSelectionOptions(language);
  const completenessOptions = getPlanCompletenessOptions(language);

  return (
    <div className={styles.FiltersWrapper}>
      <div>
        <Group justify="space-between">
          <Group>
            <OperationAgencySearchBar
              onSelect={setOperationOrAgency}
              className={styles.SearchInput}
              placeholder={useTranslation('searchOperationOrAgency')}
              onDeselect={() => setOperationOrAgency(undefined)}
              showAgency
              operationOrAgency={operationOrAgency}
            />
            {`--- ${getString('or', language)} ---`}
            <Button
              onClick={() => setShowAllAccounts(!showAllAccounts)}
              variant={showAllAccounts ? 'outline' : undefined}
              rightSection={showAllAccounts ? <FiCheck /> : <FiXCircle />}
              className={styles.ShowAllButton}
            >
              {getString('myAccounts', language)}
            </Button>
          </Group>
          <Group justify="center">
            <Text>
              <b>{getString('showing', language)}:</b> {samplingPlanLength}
            </Text>
            <SegmentedControl
              value={selectedCountry}
              onChange={setSelectedCountry}
              data={countryTabsList}
            />
            <ActionIcon variant="filled" size="lg" onClick={() => setIsDisplayMap((old) => !old)}>
              {isDisplayMap ? <FiList /> : <FiMap />}
            </ActionIcon>
          </Group>
        </Group>
        <Space h="md" />
        <Group>
          <MultiSelect
            w="20rem"
            placeholder={statuses.length ? undefined : getString(ALL_STATUSES, language)}
            data={samplePlanStatuses}
            value={statuses}
            onChange={(val) => val && setStatuses(val)}
            hidePickedOptions
          />
          <Select
            data={assignmentOptions}
            onChange={(val) => val && setAssignment(val)}
            value={assignment}
          />
          <Select
            value={readiness}
            onChange={(val) => val && setReadiness(val)}
            data={readinessOptions}
          />
          <Select
            value={proSelection}
            onChange={(val) => val && setProSelection(val)}
            data={proSelectionOptions}
          />
          <MultiSelect
            w="20rem"
            placeholder={alerts.length ? undefined : getString('alerts', language)}
            data={alertNames}
            value={alerts}
            onChange={(val) => val && setAlerts(val)}
            hidePickedOptions
          />
          <MultiSelect
            w="20rem"
            placeholder={alerts.length ? undefined : getString('completeness', language)}
            data={completenessOptions}
            value={completeness}
            onChange={(val) => val && setCompleteness(val)}
            hidePickedOptions
          />
          <UserSearch
            placeholder={getString('searchSamplerScanner', language)}
            onSelect={setSamplerUser}
            onDeselect={() => setSamplerUser(undefined)}
            className={styles.SearchBar}
            userSelected={samplerUser}
          />
          <Switch
            label={getString('samplerOnly', language)}
            checked={isSamplerOnly}
            onChange={() => setIsSamplerOnly(!isSamplerOnly)}
          />
        </Group>
      </div>
    </div>
  );
};

export default TrackingFilters;
