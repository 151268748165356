import { Group, Paper, Space, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { projectsRoutes } from 'constants/routes';

import { usePageTitle } from 'util/hooks/usePageTitle';
import { carbonProjectQueryOptions, carbonSamplingPlanQueryOptions } from 'util/queryOptions';
import { capitalize } from 'util/stringUtils';
import { Header } from 'common';
import FullHeightLayoutWrap from 'common/FullHeightLayoutWrap';

import { useProjectRouteParams, useProjectsTranslations } from '../hooks';

import CollectionFieldsMap from './CollectionFieldsMap';
import CreateCollectionForm from './CreateCollectionForm';
import ExistingCollectionsTable from './ExistingCollectionsTable';

const CarbonCollectionsContainer = () => {
  const translations = useProjectsTranslations();
  const { samplingPlanId, projectId } = useProjectRouteParams();
  const { data: samplingPlan } = useQuery(carbonSamplingPlanQueryOptions(samplingPlanId));
  const projectQuery = useQuery(carbonProjectQueryOptions(projectId));
  const collectionsCapitalized = capitalize(translations.collections);

  const pageTitle = projectQuery.data
    ? `${collectionsCapitalized} - ${projectQuery.data.name}`
    : collectionsCapitalized;

  usePageTitle(pageTitle);

  return (
    <FullHeightLayoutWrap>
      <Header
        title={pageTitle}
        breadcrumbsItems={[
          { title: translations.allProjects, to: projectsRoutes.routes.base },
          { title: projectQuery.data?.name || '', to: projectsRoutes.routes.project(projectId) },
          { title: capitalize(translations.collections) },
        ]}
      />
      <Group flex={1} style={{ overflow: 'hidden' }} gap="md" mb="xl">
        <Paper withBorder p="lg" shadow="sm" h="100%" maw={600} style={{ overflow: 'auto' }}>
          <div>
            <Text size="lg" fw="bold" mb="sm">
              {translations.createCollection}
            </Text>
            <CreateCollectionForm />
          </div>
          <Space h="xl" />
          <Text size="lg" fw="bold" mb="lg">
            {translations.existingCollections}
          </Text>
          <ExistingCollectionsTable carbonCollections={samplingPlan?.carbon_collections} />
        </Paper>
        <CollectionFieldsMap />
      </Group>
    </FullHeightLayoutWrap>
  );
};

export default CarbonCollectionsContainer;
