import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { GeoJSONSource } from 'mapbox-gl';

import { tillagePolygonLayerIds } from 'constants/proMaps';

import { clipFeaturesToBoundary } from 'util/geospatial';
import { getCompactionRxFillColor, getCompactionTestResultsFillColor } from 'util/mapImageryColors';
import { resultsQueryKeys } from 'util/queryKeys';
import { handleJsonResponse } from 'util/request';
import showToast from 'actions/toastActions';
import { EOTargetMeasurement } from 'store/eoCollections/types';
import { getSamplePlanDcpLayers, getSamplePlanTillageLayers } from 'store/samplePlans/requests';

type MapRef = React.MutableRefObject<mapboxgl.Map | null>;

/**
 * Get available inference layers for a sampling plan.
 *
 * @param samplingPlanId sampling plan id
 * @returns `useQuery` instance of available inference layers
 */
export const useAvailableInferenceLayers = (samplingPlanId?: number) =>
  useQuery({
    enabled: typeof samplingPlanId === 'number',
    queryKey: resultsQueryKeys.samplePlanTillageLayers(samplingPlanId),
    queryFn: () => getSamplePlanTillageLayers(samplingPlanId as number),
  });

/**
 * Get available "target measurement" layers, e.g. DCP points, for a sampling plan.
 *
 * @param samplingPlanId sampling plan id
 * @returns array of available target measurement layers
 */
export const useAvailableTargetMeasurementLayers = (samplingPlanId?: number) => {
  const [layers, setLayers] = useState<EOTargetMeasurement[]>();

  useEffect(() => {
    if (typeof samplingPlanId !== 'number') {
      return;
    }

    const fetchData = async () => {
      const res = await getSamplePlanDcpLayers(samplingPlanId);

      setLayers(res.target_measurements);
    };

    try {
      fetchData();
    } catch (err) {
      showToast(err.message, 'error');
    }
  }, [samplingPlanId]);

  return layers;
};

export const useSetActiveLayerSourceData = (
  mapRef: MapRef,
  mapHasLoaded: boolean,
  activeLayerId: keyof typeof tillagePolygonLayerIds,
  fieldBoundary: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>,
  testResultsGeojsonUri?: string,
  rxGeojsonUri?: string,
): void => {
  const uri =
    activeLayerId === tillagePolygonLayerIds.testResults ? testResultsGeojsonUri : rxGeojsonUri;

  useEffect(() => {
    if (!mapRef.current || !mapHasLoaded || !uri) {
      return;
    }

    const source = mapRef.current.getSource(tillagePolygonLayerIds[activeLayerId]) as
      | GeoJSONSource
      | undefined;

    const fetchAndClipInferenceLayer = async (src: GeoJSONSource) => {
      const response = await handleJsonResponse(await fetch(uri));
      const clipped = clipFeaturesToBoundary(response.features, fieldBoundary);

      src.setData(clipped);
    };

    if (source) {
      try {
        fetchAndClipInferenceLayer(source);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Unable to trim geojson fs');
        // eslint-disable-next-line
        console.error(error);
      }
    } else {
      mapRef.current.addSource(tillagePolygonLayerIds[activeLayerId], {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      const newSource = mapRef.current.getSource(tillagePolygonLayerIds[activeLayerId]) as
        | GeoJSONSource
        | undefined;

      newSource && fetchAndClipInferenceLayer(newSource);
    }
  }, [uri, mapRef, mapHasLoaded, activeLayerId]);
};

/**
 * Set the `fill-color` paint property of the active layer based on the high and low values.
 *
 * @param activeLayerId layer id of currently active layer
 * @param mapHasLoaded map is loaded
 * @param map mapbox map instance
 * @param highLow object containing high and low values
 */
export const useSetCompactionFillColor = (
  activeLayerId: keyof typeof tillagePolygonLayerIds,
  mapHasLoaded: boolean,
  map: mapboxgl.Map | null,
  highLow?: {
    high: number;
    low: number;
  },
) => {
  useEffect(() => {
    if (!mapHasLoaded || !map?.getLayer(activeLayerId)) {
      return;
    }

    if (activeLayerId === tillagePolygonLayerIds.rx) {
      map.setPaintProperty(activeLayerId, 'fill-color', getCompactionRxFillColor());
    } else if (activeLayerId === tillagePolygonLayerIds.testResults) {
      map.setPaintProperty(activeLayerId, 'fill-color', getCompactionTestResultsFillColor(highLow));
    }
  }, [activeLayerId, mapHasLoaded, map, highLow]);
};

export const useToggleActivePolyLayerVisibility = (
  mapRef: MapRef,
  mapHasLoaded: boolean,
  activeLayerId: keyof typeof tillagePolygonLayerIds,
): void => {
  useEffect(() => {
    if (!mapRef.current || !mapHasLoaded) {
      return;
    }

    const layer = mapRef.current.getLayer(activeLayerId);

    if (!layer) {
      return;
    }

    const inactiveLayerId =
      activeLayerId === tillagePolygonLayerIds.testResults
        ? tillagePolygonLayerIds.rx
        : tillagePolygonLayerIds.testResults;

    mapRef.current.setLayoutProperty(activeLayerId, 'visibility', 'visible');
    mapRef.current.setLayoutProperty(inactiveLayerId, 'visibility', 'none');
  }, [activeLayerId, mapRef.current, mapHasLoaded]);
};
