import { Checkbox, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleTimingsQueryOptions } from 'util/queryOptions';
import { getSampleTimingDisplayName } from 'util/samplePlanV3';
import { getString } from 'strings/translation';

const ReadyToSampleCheckbox = () => {
  const language = useBroswerLanguage();
  const form = useOrderFormContext();
  const { sampleTiming } = form.getValues();
  const { data: sampleTimings } = useQuery(sampleTimingsQueryOptions);

  const sampleTimingDisplayName = getSampleTimingDisplayName(
    sampleTimings?.sampling_timings || [],
    sampleTiming,
  );

  return (
    <Checkbox
      {...form.getInputProps('readyToSample', { type: 'checkbox' })}
      description={`${getString('markedReadyAssignedMsg', language)} ${sampleTimingDisplayName}`}
      label={
        <Text size="sm">
          <b>{getString('readyToSample', language)}</b>
        </Text>
      }
    />
  );
};

export default ReadyToSampleCheckbox;
