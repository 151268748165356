import {
  RECEIVE_RECOMMENDATION_SETS,
  RECEIVE_SINGLE_RECOMMENDATION_SET,
  RECOMMENDATION_SET_REQUEST_ERROR,
  RecommendationSetActionsType,
  RecommendationSetType,
  REQUEST_RECOMMENDATION_SETS,
} from './types';

export type RecommendationSetStateType = {
  byId: {
    [id: number]: RecommendationSetType;
  };
  allSets: RecommendationSetType[];
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const initialState: RecommendationSetStateType = {
  byId: {},
  allSets: [],
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const RecommendationSetReducer = (
  state = initialState,
  action: RecommendationSetActionsType,
): RecommendationSetStateType => {
  switch (action.type) {
    case RECEIVE_RECOMMENDATION_SETS:
      return {
        ...state,
        allSets: action.payload.recommendation_sets,
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_SINGLE_RECOMMENDATION_SET:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.recommendation_set.id]: action.payload.recommendation_set,
        },
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_RECOMMENDATION_SETS:
      return {
        ...state,
        isFetching: true,
      };
    case RECOMMENDATION_SET_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
