import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from '@mantine/core';

import { NOT_APPLICABLE } from 'constants/defaultValues';
import { ENGLISH, LANGUAGE_OPTIONS } from 'constants/languages';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { isAgencyRole, userIsAgencyAdmin, userIsAgent } from 'util/userRoles';
import { getString } from 'strings/translation';
import { RoleType } from 'store/roles/types';
import { submitUserDetails } from 'store/user/thunks';
import { User } from 'store/user/types';
import { TableCol, TableRow } from 'common';

import { AgencyReassignmentDialog } from './AgencyReassignment';

import styles from './UsersTable.module.css';

type UserRowPropsType = {
  roles: (RoleType & { label: string; value: string })[];
  user: User;
  setToastMessage: Function;
  isSuperAdmin: boolean;
  navigateToUser: Function;
  reloadUsers: Function;
};

const UserRow = ({
  roles,
  user,
  setToastMessage,
  isSuperAdmin,
  navigateToUser,
  reloadUsers,
}: UserRowPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [showDialog, setShowDialog] = useState(false);

  const [activeRole, setActiveRole] = useState<string | undefined>(
    roles.find((role) => user.roles[0] && role.id === user.roles[0].id)?.value,
  );
  const [activeLocale, setActiveLocale] = useState<string>(
    LANGUAGE_OPTIONS.find((locale) => user.user_locale === locale.value)?.value || ENGLISH,
  );

  const isAgencyUser = userIsAgent(user.roles) || userIsAgencyAdmin(user.roles);

  const onChangeRole = async (val: string | null) => {
    const newRole = roles.find((r) => r.value === val);
    if (newRole) {
      if (isAgencyRole(newRole)) {
        setShowDialog(true);
      } else {
        const requestBody = { email: user.email, role_id: newRole.id };
        setActiveRole(newRole.value);
        dispatch(submitUserDetails(requestBody, user.id, language, false, 'userRoleUpdated'));
        reloadUsers();
      }
    }
  };

  const onChangeLocale = async (locale: string | null) => {
    if (locale) {
      const requestBody = { email: user.email, user_locale: locale };
      setActiveLocale(locale);
      dispatch(submitUserDetails(requestBody, user.id, language, false, 'userLocaleUpdated'));
    }
  };

  const onAgencySubmit = (role: RoleType) => {
    setActiveRole(roles.find((r) => r.id === role.id)?.value);
    reloadUsers();
    setToastMessage(getString('userRoleUpdated', language));
  };

  const getAgencyName = () => {
    if (user.agencies[0] && isAgencyUser) {
      return user.agencies[0]?.name;
    }
    return NOT_APPLICABLE;
  };

  return (
    <>
      {showDialog && (
        <AgencyReassignmentDialog
          user={user}
          onClose={() => setShowDialog(false)}
          onSubmit={onAgencySubmit}
        />
      )}
      <TableRow className={styles.RowHover}>
        <TableCol className={styles.Clickable}>
          <span onClick={() => navigateToUser(user.id)}>{user.first_name}</span>
        </TableCol>
        <TableCol className={styles.Clickable}>{user.last_name}</TableCol>
        <TableCol>{user.email}</TableCol>
        <TableCol>{getAgencyName()}</TableCol>
        <TableCol>{user.agency_primary ? getString('yes', language) : ''}</TableCol>
        <TableCol shrink className={styles.ShrinkedColumn}>
          <Select
            value={activeRole}
            onChange={onChangeRole}
            data={roles}
            disabled={!isSuperAdmin || user.agency_primary}
          />
        </TableCol>
        <TableCol shrink className={styles.ShrinkedColumn}>
          <Select
            value={activeLocale}
            onChange={onChangeLocale}
            data={LANGUAGE_OPTIONS}
            disabled={!isSuperAdmin}
          />
        </TableCol>
      </TableRow>
    </>
  );
};

export default UserRow;
