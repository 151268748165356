import { useEffect } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@patternag/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';

import MAPBOX_CONSTANTS, { DRAW_STYLING } from 'constants/mapbox';

import { coordinatesGeocoder } from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

mapboxgl.accessToken = MAPBOX_CONSTANTS.token;

const useMapboxGl = (
  mapContainerRef,
  mapRef,
  drawRef,
  viewport,
  setViewport,
  setMode,
  showNavigationControls,
  showGeocoderSearch,
  mapStyle,
) => {
  const language = useBroswerLanguage();

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current || '',
      style: mapStyle || MAPBOX_CONSTANTS.styleWithLabels,
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    const map = mapRef.current;
    if (map) {
      map.doubleClickZoom.disable();
      if (showNavigationControls) {
        map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
      }
      if (drawRef) {
        const { modes } = MapboxDraw;
        drawRef.current = new MapboxDraw({
          userProperties: true,
          modes,
          displayControlsDefault: false,
          styles: DRAW_STYLING,
        });
        map.addControl(drawRef.current);
        map.on('draw.modechange', (e) => {
          setMode(e.mode);
        });
      }

      map.on('zoomend', () => {
        const zoom = map.getZoom();
        const mapCenter = map.getCenter();
        setViewport({
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
          zoom,
        });
      });
      map.on('moveend', () => {
        const zoom = map.getZoom();
        const mapCenter = map.getCenter();
        setViewport({
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
          zoom,
        });
      });
      showGeocoderSearch &&
        map.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl,
            placeholder: getString('mapSearchPlaceholder', language),
            reverseGeocode: true,
            localGeocoder: coordinatesGeocoder,
          }),
        );
    }
    return () => map?.remove();
  }, []);
};

export default useMapboxGl;
