import {
  FIELD_EVENT_REQUEST_ERROR,
  FieldEventActionType,
  FieldEventType,
  RECEIVE_FIELD_EVENT,
  RECEIVE_FIELD_EVENTS,
  REQUEST_FIELD_EVENTS,
} from './types';

export type FieldEventsStateType = {
  events: { [id: number]: FieldEventType[] };
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const initialState: FieldEventsStateType = {
  events: {},
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

const handleUpdateEvent = (
  events: { [id: number]: FieldEventType[] },
  payload: { event: FieldEventType; oldFieldId: number },
) => {
  const { event: newEvent, oldFieldId } = payload;

  const oldFieldEvents = events[oldFieldId];
  const filteredOldEvents = oldFieldEvents?.filter((event) => event.id !== newEvent.id);

  const existingEvents = events[newEvent.field_id] || [];
  const filteredEvents = existingEvents.filter((event) => event.id !== newEvent.id);
  return {
    ...events,
    [newEvent.field_id]: filteredEvents.concat(newEvent),
    [oldFieldId]: filteredOldEvents,
  };
};

export const FieldEventsReducer = (
  state = initialState,
  action: FieldEventActionType,
): FieldEventsStateType => {
  switch (action.type) {
    case REQUEST_FIELD_EVENTS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
      };
    case RECEIVE_FIELD_EVENT:
      return {
        ...state,
        events: handleUpdateEvent(state.events, action.payload),
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_FIELD_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.id]: action.payload.events,
        },
        hasFetched: true,
        isFetching: false,
      };
    case FIELD_EVENT_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
