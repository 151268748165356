import { Checkbox, Grid, TextInput } from '@mantine/core';
import { useProjectsTranslations } from 'apps/Projects/hooks';
import { useSamplePlanFormContext } from 'apps/Projects/SamplePlans/samplePlanFormContext';

import { DefaultSamplingPlanConfigResponse, SamplePlanForm } from 'store/projects/types';
import InputAndLabelGridRow from 'common/Components/InputAndLabelGridRow';

import FormHeading from './FormHeading';

type FormKey = keyof SamplePlanForm;

const TEXTURE_FORM_KEY: FormKey = 'hasTexture';
const TEXTURE_DENSITY_FORM_KEY: FormKey = 'textureDensity';

type Props = {
  defaultConfig: DefaultSamplingPlanConfigResponse | undefined;
};

/** Aka 'Additional sample details' section */
const SoilTextureSection = ({ defaultConfig }: Props) => {
  const form = useSamplePlanFormContext();
  const translations = useProjectsTranslations();
  const { registry } = form.getValues();
  const registryConfig = defaultConfig?.[registry];
  const soilTextureInfo = registryConfig?.measurement_type_info['Soil Texture'];

  if (!soilTextureInfo) {
    return null;
  }

  return (
    <div>
      <FormHeading text={translations.texturePhSampleDetails} />
      <Grid align="center">
        <InputAndLabelGridRow
          elemId="texture"
          disabled
          label={translations.textureAndPh}
          input={
            <Checkbox
              key={form.key(TEXTURE_FORM_KEY)}
              id="texture"
              disabled
              {...form.getInputProps(TEXTURE_FORM_KEY, { type: 'checkbox' })}
            />
          }
        />
        <InputAndLabelGridRow
          elemId="texture-density"
          disabled
          label={translations.density}
          input={
            <TextInput
              id="texture-density"
              disabled
              {...form.getInputProps(TEXTURE_DENSITY_FORM_KEY)}
            />
          }
        />
        <InputAndLabelGridRow
          elemId="texture-lab"
          disabled
          label={translations.lab}
          input={<TextInput id="texture-lab" disabled value={soilTextureInfo.lab_name} />}
        />
      </Grid>
    </div>
  );
};

export default SoilTextureSection;
