import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { PATTERN_RECOMMENDATION_SET } from 'constants/results';
import { DEMO_OPERATION_ID } from 'constants/variables';

import useOperationRecommendationSet from 'util/hooks/useOperationRecommendationSet';
import { getResultRecommendation } from 'util/results';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import {
  addRecommendationToSamplePlan,
  editPlanRecommendation,
  getRecommmendations,
  getSamplePlanRecommendations,
  hidePastRecommendation,
} from 'store/recommendations/thunks';
import { RecommendationType } from 'store/recommendations/types';
import { getRecommendationSet } from 'store/recommendationSets/thunks';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { Button, ClickOutsideWrapper, Textarea } from 'common';

import styles from './Recommendations.module.css';

type RecommendationsPropsType = {
  className?: string;
  analyticId: number;
  riskLevel: RecommendationType['risk_level'] | null;
  samplingPlan: SamplingPlanType;
  recommendation: RecommendationType;
  operation: OperationType;
  onClose: () => void;
};

const Recommendations = ({
  className,
  analyticId,
  riskLevel,
  samplingPlan,
  recommendation,
  operation,
  onClose,
}: RecommendationsPropsType) => {
  const dispatch = useDispatch();
  const [showTextarea, toggleShowTextarea] = useState(false);
  const [recommendationText, setRecommendationText] = useState(recommendation.recommendation);

  const defaultRecSet = useOperationRecommendationSet(operation);

  const { userRecommendations, patternRecSet, isAdmin } = useSelector((state: RootState) => ({
    userRecommendations: state.recommendations.recommendations,
    patternRecSet: state.recommendationSets.byId[PATTERN_RECOMMENDATION_SET],
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  useEffect(() => {
    dispatch(getRecommmendations());
    dispatch(getRecommendationSet(PATTERN_RECOMMENDATION_SET));
  }, []);

  const userRecForAnalytic = getResultRecommendation(
    userRecommendations,
    analyticId,
    riskLevel,
    true,
  ) as RecommendationType[];
  const defaultRecommendation = getResultRecommendation(
    defaultRecSet?.recommendations,
    analyticId,
    riskLevel,
  ) as RecommendationType;
  const patternRecommendation = getResultRecommendation(
    patternRecSet?.recommendations,
    analyticId,
    riskLevel,
  ) as RecommendationType;

  const editRecommendation = async () => {
    try {
      if (riskLevel) {
        const body = {
          sampling_plan_id: samplingPlan.id,
          risk_level: riskLevel,
          analytic_id: analyticId,
          recommendation: recommendationText,
          default: false,
        };
        await dispatch(editPlanRecommendation(recommendation.id, body));
        dispatch(getSamplePlanRecommendations(samplingPlan.id));
        onClose();
      }
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const linkPastRecommendation = async (recommendationId: number) => {
    dispatch(addRecommendationToSamplePlan(recommendationId, samplingPlan.id));
    onClose();
  };

  const hideRecommendation = async (recommendationId: number) => {
    await dispatch(hidePastRecommendation(recommendationId));
    dispatch(getRecommmendations());
  };

  if (operation.id === DEMO_OPERATION_ID && !isAdmin) {
    return null;
  }

  return (
    <ClickOutsideWrapper className={classNames(styles.Wrapper, className)} onClickOutside={onClose}>
      <div className={styles.CurrentRec}>
        <div className={styles.RecommendationRow}>
          {showTextarea || recommendationText === '' ? (
            <Textarea
              className={styles.TextInput}
              onChange={(e) => setRecommendationText(e.target.value)}
              placeholder="Enter recommendation for this analytic here..."
              value={recommendationText}
            />
          ) : (
            <span onClick={() => toggleShowTextarea(true)}>{recommendationText}</span>
          )}
        </div>
        <div className={styles.CurrButtonsRow}>
          <Button onClick={editRecommendation}>Save</Button>
        </div>
      </div>
      <div className={styles.PastRecs}>
        {userRecForAnalytic?.map((rec) => (
          <div key={rec.id} className={styles.PastRecRow}>
            <div className={styles.RecommendationRow}>{rec.recommendation}</div>
            <div className={styles.PastButtonsRow}>
              <Button onClick={() => linkPastRecommendation(rec.id)}>Use this text</Button>
              <span className={styles.RemoveButton} onClick={() => hideRecommendation(rec.id)}>
                Remove
              </span>
            </div>
          </div>
        ))}
        {defaultRecommendation && (
          <div className={styles.PastRecRow}>
            <div className={styles.RecommendationRow}>
              <b>Account Default: </b>
              {defaultRecommendation.recommendation}
            </div>
            <div className={styles.PastButtonsRow}>
              <Button onClick={() => linkPastRecommendation(defaultRecommendation.id)}>
                Use this text
              </Button>
            </div>
          </div>
        )}
        {patternRecommendation && (
          <div className={styles.PastRecRow}>
            <div className={styles.RecommendationRow}>
              <b>EarthOptics: </b>
              {patternRecommendation.recommendation}
            </div>
            <div className={styles.PastButtonsRow}>
              <Button onClick={() => linkPastRecommendation(patternRecommendation.id)}>
                Use this text
              </Button>
            </div>
          </div>
        )}
      </div>
    </ClickOutsideWrapper>
  );
};

export default Recommendations;
