import React from 'react';

import { PlateType } from 'store/plates/types';

import { EditNotes } from '../../Sections/Inputs/EditNotes';
import { QPCRCycles } from '../../Sections/Inputs/QPCRCycles';
import { PlateDocumentsContainer } from '../../Sections/PlateDocuments/Container';
import { PlateQuadrants } from '../../Sections/PlateWells/PlateQuadrants';
import { AmpliconPlates } from '../../Sections/RelatedPlates/AmpliconPlates';

import styles from '../Container.module.css';

type AmpliconLayoutPropsType = {
  plate: PlateType;
};

export const AmpliconLayout = ({ plate }: AmpliconLayoutPropsType) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <div className={styles.Column}>
          <AmpliconPlates plate={plate} />
          <QPCRCycles plate={plate} />
        </div>
        <div className={styles.Column}>
          <EditNotes plate={plate} />
          <PlateDocumentsContainer plate={plate} />
        </div>
      </div>
      <PlateQuadrants plate={plate} />
    </div>
  );
};
