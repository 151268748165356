import React from 'react';
import classNames from 'classnames';

import { Checkbox } from 'common';

import styles from './LabeledCheckbox.module.css';

type LabeledCheckboxPropsType = {
  className?: string;
  checked: boolean;
  onChange: () => void;
  right?: boolean;
  children?: React.ReactNode;
};

export const LabeledCheckbox = ({
  className,
  checked,
  onChange,
  right,
  children,
}: LabeledCheckboxPropsType) => (
  <div className={classNames(styles.CheckboxWrapper, className, { [styles.Right]: right })}>
    <span className={styles.Label}>{children}</span>
    <Checkbox checked={checked} onChange={onChange} />
  </div>
);
