import React from 'react';
import { PlatesList } from 'apps/Lab/Plates/Details/Sections/RelatedPlates/PlateList/PlateList';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BatchType } from 'store/batches/types';

import { EditBatch } from './EditBatch/EditBatch';

type SourcePlatesPropsType = {
  batch: BatchType;
};

export const SourcePlates = ({ batch }: SourcePlatesPropsType) => {
  const language = useBroswerLanguage();
  return (
    <PlatesList
      batch={batch}
      plates={batch.plates}
      title={getString('sourcePlates', language)}
      batchOrder
    >
      <EditBatch batch={batch} />
    </PlatesList>
  );
};
