import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionIcon, Divider, Group, Image, Select, Stack, Text, Title } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { CROP_PROTECTION, PATTERN_RECOMMENDATION_SET } from 'constants/results';
import { CORN } from 'constants/variables';

import {
  getAvailableCrops,
  getHeatMapForAnalytic,
  getHeatMapForOperation,
  getRiskLevelOptions,
} from 'util/heatMap';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAnalyticsPerTypeCrop } from 'util/results';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Agency } from 'store/agencies/types';
import { HeatMapAnalyticType } from 'store/heatMap/types';
import { getRecommendationSet } from 'store/recommendationSets/thunks';
import { MultiSelect } from 'common/Components/Mantine/MultiSelect';

import { AnalyticsRow } from './Common/AnalyticsRow';
import { SummaryBar } from './Common/SummaryBar';
import { HeatList } from './List/HeatList';
import HeatMap from './Map/HeatMap';

type HeatMapFilterPropsType = {
  heatMap: HeatMapAnalyticType[];
  agency?: Agency;
  operationId?: number | null;
};

const HeatMapFilter = ({ heatMap, agency, operationId }: HeatMapFilterPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [mapView, setMapView] = useState(true);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const availableCrops = getAvailableCrops(language);
  const upcomingCrops = getAvailableCrops(language, true);

  const recSetId = agency?.recommendation_set_id || PATTERN_RECOMMENDATION_SET;
  const { recSet, analytics, analyticSortOrder } = useSelector((state: RootState) => ({
    recSet: state.recommendationSets.byId[recSetId],
    analyticSortOrder: state.analytics.analyticSortOrder,
    analytics: state.analytics.analytics,
  }));

  useEffect(() => {
    dispatch(getRecommendationSet(recSetId));
  }, [recSetId]);

  const [crop, setCrop] = useState(CORN);
  const [upcomingCrop, setUpcomingCrop] = useState<string | null>(null);

  const categoryOptions = Object.keys(analyticSortOrder).map((cat) => ({
    label: getString(cat, language),
    value: cat,
  }));
  const [category, setCategory] = useState(CROP_PROTECTION);

  const initialAnalytics = getAnalyticsPerTypeCrop(analyticSortOrder, analytics, category, crop);

  const [activeAnalytic, setActiveAnalytic] = useState(initialAnalytics[0]);

  const riskLevelOptions = getRiskLevelOptions(language, [HIGH_RISK, MODERATE_RISK, LOW_RISK]);
  const [riskLevels, setRiskLevels] = useState<string[]>([]);

  const upcomingCropHeatMap = upcomingCrop
    ? heatMap.filter((hm) => hm.upcoming_crop === upcomingCrop)
    : heatMap;
  const operationHeatMap = operationId
    ? getHeatMapForOperation(upcomingCropHeatMap, operationId)
    : upcomingCropHeatMap;
  const activeAnalyticHeatMap = activeAnalytic
    ? getHeatMapForAnalytic(operationHeatMap, activeAnalytic.id)
    : [];
  const activeHeatMapWithRiskFilter = activeAnalytic
    ? getHeatMapForAnalytic(operationHeatMap, activeAnalytic.id, ...riskLevels)
    : [];

  const categoryAnalytics = initialAnalytics.filter(
    (a) => getHeatMapForAnalytic(operationHeatMap, a.id).length,
  );

  const firstCategoryAnalytic = categoryAnalytics[0];

  useEffect(() => {
    // If crop, category, or analytics list change, reset the active analytic
    if (firstCategoryAnalytic) {
      setActiveAnalytic(firstCategoryAnalytic);
    }
  }, [firstCategoryAnalytic]);

  const generateCropButtons = (
    selectedCrop: string | null,
    setSelectedCrop: (value: string | null) => void,
    cropList: {
      label: string;
      value: string | null;
      icon: string | null;
    }[],
    buttonLabel: string,
  ) => (
    <>
      <Divider
        color="blue"
        variant="dashed"
        orientation="vertical"
        style={{ alignSelf: 'flex-end' }}
        h="2.2rem"
      />
      <Stack align="center" gap="0.5rem">
        <Text size="sm">{getString(buttonLabel, language)}</Text>
        <Group gap="md">
          {cropList.map(({ label, value: c, icon: iconSrcBase64 }) => (
            <ActionIcon
              key={c}
              variant="outline"
              onClick={() => setSelectedCrop(c)}
              size="lg"
              style={
                c === selectedCrop
                  ? {
                      outline: '2px solid var(--mantine-color-orange-3)',
                      borderColor: 'var(--mantine-color-orange-3)',
                    }
                  : {}
              }
            >
              {iconSrcBase64 ? (
                <Image h={26} src={iconSrcBase64} />
              ) : (
                <Text fw="bold">{label}</Text>
              )}
            </ActionIcon>
          ))}
        </Group>
      </Stack>
    </>
  );

  return (
    <Stack>
      <Stack>
        <Group gap="lg" align="flex-end">
          <Group gap="0.5rem">
            <Text>{`${getString('show', language)}:`}</Text>
            <Select
              onChange={(value: string | null) => value && setCategory(value)}
              data={categoryOptions}
              value={category}
              w="14rem"
            />
            <Text>+</Text>
            <MultiSelect
              data={riskLevelOptions}
              value={riskLevels}
              onChange={setRiskLevels}
              all={getString('allRiskLevels', language)}
              clearable
            />
          </Group>
          {generateCropButtons(
            crop,
            setCrop as (value: string | null) => void,
            availableCrops,
            'displayedAnalytics',
          )}
          {generateCropButtons(upcomingCrop, setUpcomingCrop, upcomingCrops, 'upcomingCrop')}
        </Group>
        <Divider size="sm" />
      </Stack>
      <Stack>
        <Title order={4}>{getString('percentOfFieldsAtModerateOrHighRisk', language)}</Title>
        {activeAnalytic && (
          <AnalyticsRow
            activeAnalytic={activeAnalytic}
            setActiveAnalytic={setActiveAnalytic}
            analytics={categoryAnalytics}
            heatMap={operationHeatMap}
          />
        )}
        <SummaryBar
          analyticId={activeAnalytic?.id}
          heatMap={activeAnalyticHeatMap}
          setMapView={setMapView}
          mapView={mapView}
        />
        <Divider size="sm" />
      </Stack>
      {activeAnalytic &&
        (mapView ? (
          <HeatMap
            mapContainerRef={mapContainerRef}
            mapRef={mapRef}
            heatMap={activeHeatMapWithRiskFilter}
            analytic={activeAnalytic}
          />
        ) : (
          <HeatList
            heatMap={activeHeatMapWithRiskFilter}
            analytic={activeAnalytic}
            recSet={recSet}
          />
        ))}
    </Stack>
  );
};

export default HeatMapFilter;
