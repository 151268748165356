import { User } from 'store/user/types';

import { Agency } from '../agencies/types';

import {
  ConnectedAccountType,
  OPERATION_REQUEST_ERROR,
  OperationActionsType,
  OperationTotalStats,
  OperationType,
  RECEIVE_CONNECTED_ACCOUNTS,
  RECEIVE_OPERATION_USERS,
  RECEIVE_OPERATIONS,
  RECEIVE_RESULT_PREFERENCES,
  RECEIVE_RESULTS_LINK,
  RECEIVE_SINGLE_FIELD,
  RECEIVE_SINGLE_OPERATION,
  RECEIVE_TOTAL_STATS,
  REQUEST_CONNECTED_ACCOUNTS,
  REQUEST_OPERATIONS,
  REQUEST_RESULTS_LINK,
  ResultPreferencesType,
  ResultsLinksType,
  SET_ACTIVE_OPERATION,
} from './types';

export const requestOperations = {
  type: REQUEST_OPERATIONS,
};

export const operationRequestError = (): OperationActionsType => ({
  type: OPERATION_REQUEST_ERROR,
});

export const requestResultsLink = {
  type: REQUEST_RESULTS_LINK,
};

export const receiveResultsLink = (links: ResultsLinksType, id: number): OperationActionsType => {
  return {
    type: RECEIVE_RESULTS_LINK,
    payload: { links, id },
  };
};

export const receiveSingleField = (field: any): OperationActionsType => {
  return {
    type: RECEIVE_SINGLE_FIELD,
    payload: field,
  };
};

export const receiveSingleOperation = (operation: OperationType): OperationActionsType => {
  return {
    type: RECEIVE_SINGLE_OPERATION,
    payload: operation,
  };
};

export const receiveOperations = (
  response: {
    page: number;
    total: number;
    per_page: number;
    operations: OperationType[];
    agencies: Agency[];
  },
  acreageUnit: string,
): OperationActionsType => {
  return {
    type: RECEIVE_OPERATIONS,
    payload: {
      items: response.operations,
      agencies: response.agencies,
      page: response.page,
      total: response.total,
      per_page: response.per_page,
    },
    acreageUnit,
  };
};

export const receiveOperationsTotalStats = (
  payload: OperationTotalStats,
  acreageUnit: string,
): {
  payload: OperationTotalStats;
  acreageUnit: string;
  type: string;
} => {
  return {
    type: RECEIVE_TOTAL_STATS,
    payload,
    acreageUnit,
  };
};

export const setActiveOperation = (id: number): OperationActionsType => {
  return {
    type: SET_ACTIVE_OPERATION,
    payload: id,
  };
};

export const requestConnectedAccounts = {
  type: REQUEST_CONNECTED_ACCOUNTS,
};

export const receiveConnectedAccounts = (
  connectedAccounts: ConnectedAccountType,
  id: number,
): OperationActionsType => {
  return {
    type: RECEIVE_CONNECTED_ACCOUNTS,
    payload: { id, connectedAccounts },
  };
};

export const receiveResultPreferences = (
  preferences: ResultPreferencesType,
  id: number,
): OperationActionsType => {
  return {
    type: RECEIVE_RESULT_PREFERENCES,
    payload: { id, preferences },
  };
};

export const receiveOperationUsers = (operationUsers: User[], id: number) => {
  return {
    type: RECEIVE_OPERATION_USERS,
    payload: { id, operationUsers },
  };
};
