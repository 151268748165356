import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Group, SimpleGrid, Space, Text } from '@mantine/core';

import { jobStatuses } from 'constants/prescription';

import { getFieldAcresDisplay } from 'util/field';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  getAppliedInputStrings,
  getJobStatus,
  getPrescriptionSummaryValues,
} from 'util/prescription';
import { capitalize } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { PrescriptionType } from 'store/prescriptions/types';

import styles from './Prescription.module.css';

interface PrescriptionSummaryProps {
  field: FieldType;
  prescription: PrescriptionType;
  cancelPrescription: (script: PrescriptionType) => void;
  checked?: boolean;
  onChecked?: () => void;
}

const PrescriptionSummary = ({
  field,
  prescription,
  cancelPrescription,
  checked,
  onChecked,
}: PrescriptionSummaryProps) => {
  const language = useBroswerLanguage();
  const { id, operation_id } = field.features[0].properties;
  const { inputs, exportedScript } = useSelector((state: RootState) => ({
    inputs: state.inputs.inputs,
    exportedScript: state.prescriptionZones.scriptExports[id]?.[prescription.id],
  }));
  const navigate = useNavigate();

  const navigateToPrescription = useCallback(
    () => navigate(`/results/rx/${operation_id}/${id}/${prescription.id}`),
    [navigate, prescription, id, operation_id],
  );

  const {
    expectedYield,
    fieldCost,
    fieldRate,
    formula,
    minimumRate,
    maximumRate,
    totalApplied,
    range,
  } = getPrescriptionSummaryValues(prescription, inputs, field, language);

  const getExportedScripts = () => {
    const fieldView = exportedScript?.field_view;
    const johnDeere = exportedScript?.john_deere;
    const cnhi = exportedScript?.cnhi;

    return (
      <SimpleGrid cols={3}>
        {fieldView && (
          <Text size="sm">
            <b>
              {getString('field_view', language)} {getString('export', language)}:
            </b>{' '}
            <span className={fieldView?.complete === jobStatuses.FAILED ? styles.Red : undefined}>
              {getJobStatus(fieldView?.complete, language)}
            </span>
          </Text>
        )}
        {johnDeere && (
          <Text size="sm">
            <b>
              {getString('john_deere', language)} {getString('export', language)}:
            </b>{' '}
            <span className={johnDeere?.complete === jobStatuses.FAILED ? styles.Red : undefined}>
              {getJobStatus(johnDeere?.complete, language)}
            </span>
          </Text>
        )}
        {cnhi && (
          <Text size="sm">
            <b>
              {getString('cnhi', language)} {getString('export', language)}:
            </b>{' '}
            <span className={cnhi?.complete === jobStatuses.FAILED ? styles.Red : undefined}>
              {getJobStatus(cnhi?.complete, language)}
            </span>
          </Text>
        )}
      </SimpleGrid>
    );
  };

  const appliedInputStrings = getAppliedInputStrings(prescription, language);

  const formulaDisplay = (
    <Text size="sm">
      <b>{getString('formula', language)}:</b> {formula}
    </Text>
  );
  const cropDisplay = (
    <Text size="sm">
      <b>
        {getString(
          prescription.machine_data_id || prescription.composite_imagery_id
            ? 'previousCrop'
            : 'plannedCrop',
          language,
        )}
        :
      </b>{' '}
      {capitalize(prescription.crop)}
    </Text>
  );

  return (
    <div data-test-id="rx-row" className={styles.PrescriptionRowItem}>
      <div className={styles.PrescriptionSummaryRow}>
        <Group>
          <Button
            variant="outline"
            data-test-id="view-details"
            onClick={navigateToPrescription}
            disabled={checked !== undefined}
          >
            {getString('viewDetails', language)}
          </Button>
          {checked !== undefined && onChecked && (
            <Checkbox checked={checked} onChange={onChecked} />
          )}
        </Group>
        <Button
          data-test-id="remove-rx"
          color={checked === undefined ? 'darkRed' : undefined}
          onClick={() => cancelPrescription(prescription)}
          disabled={checked !== undefined}
        >
          {getString('remove', language)}
        </Button>
      </div>
      <SimpleGrid cols={3}>
        <Text size="sm">
          <b>{prescription.name}</b>
        </Text>
      </SimpleGrid>
      <Space h="xs" />
      <SimpleGrid cols={3}>
        {appliedInputStrings && (
          <Text size="sm">
            <b>{`${getString(appliedInputStrings[0], language)}`}:</b> {appliedInputStrings[1]}
          </Text>
        )}
        <Text size="sm">
          <b>{getString('acres', language)}:</b> {getFieldAcresDisplay(field)}
        </Text>
        {prescription.seed ? formulaDisplay : cropDisplay}
      </SimpleGrid>
      <Space h="xs" />
      <SimpleGrid cols={3}>
        <Text size="sm" fw={700}>
          <b>{getString('fieldRate', language)}:</b> {fieldRate}
        </Text>
        <Text size="sm" fw={700}>
          <b>{getString('range', language)}:</b> {range}
        </Text>
        <Text size="sm">
          <b>{getString(prescription.seed ? 'targetYield' : 'totalApplied', language)}:</b>{' '}
          {prescription.seed ? expectedYield : totalApplied}
        </Text>
      </SimpleGrid>
      <Space h="xs" />
      <SimpleGrid cols={3}>
        <Text size="sm">
          <b>{getString('minimumRate', language)}:</b> {minimumRate}
        </Text>
        <Text size="sm">
          <b>{getString('maximumRate', language)}:</b> {maximumRate}
        </Text>
        <Text size="sm">
          <b>{getString('density', language)}:</b>{' '}
          {prescription.pro_density?.replace('_', ' ') || getString('soilTest', language)}
        </Text>
      </SimpleGrid>
      <Space h="xs" />
      {!prescription.seed && (
        <SimpleGrid cols={3}>
          {formulaDisplay}
          <Text size="sm">
            <b>{getString('fieldCost', language)}:</b> {fieldCost}
          </Text>
        </SimpleGrid>
      )}
      {getExportedScripts()}
    </div>
  );
};

export default PrescriptionSummary;
