import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { PAYMENT_FILTER_OPTIONS } from 'constants/auth';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { DEBOUNCE } from 'util/request';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { CloseIcon, Header, IconInput, TabbedController } from 'common';

import ApprovedList from './ApprovedList';
import OpenList from './OpenList';

import styles from './Container.module.css';

const PaymentOverviewContainer = () => {
  const { status } = useParams<{
    status: 'open' | 'approved';
  }>();
  const [tabs, setTabs] = useState<string[]>([]);
  const [filter, setFilter] = useState('');
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  const isAdmin = useSelector((state: RootState) => userIsAdmin(state) || userIsSuperAdmin(state));

  const statusOptions = [
    {
      id: 1,
      displayName: getString('open', language),
      value: 'open',
    },
    {
      id: 2,
      displayName: getString('approved', language),
      value: 'approved',
    },
  ];

  useEffect(() => {
    if (!status) {
      navigate(`/payments/${statusOptions[0].value}`);
    }
  }, [navigate, status, statusOptions]);

  const [currentApprovedPage, setCurrentApprovedPage] = useState(1);
  const [currentOpenPage, setCurrentOpenPage] = useState(1);

  const updateSearchFilter = useDebouncedCallback(
    (userName: string) => {
      setCurrentApprovedPage(1);
      setCurrentOpenPage(1);
      setFilter(userName);
    },
    DEBOUNCE,
    { trailing: true },
  );

  const setNewTabs = (role: string) => {
    setTabs(tabs.includes(role) ? [] : [role]);
  };

  return (
    <div>
      <Header title={getString('payments', language)}>
        {isAdmin && (
          <div className={styles.Tags}>
            {isAdmin &&
              PAYMENT_FILTER_OPTIONS.map((role) => (
                <div
                  onClick={() => setNewTabs(role)}
                  className={classNames(styles.Tag, tabs.includes(role) && styles.SelectedTag)}
                  key={role}
                >
                  <span>{getString(role, language)}</span>
                  <span>{tabs.includes(role) && <CloseIcon />}</span>
                </div>
              ))}
          </div>
        )}
        <TabbedController
          options={statusOptions}
          onChange={(idx: number) => {
            navigate(`/payments/${statusOptions[idx].value}`);
          }}
          activeIndex={statusOptions.findIndex((s) => s.value === status)}
        />
        <IconInput
          dataTestId="search-payments"
          className={styles.SearchBar}
          icon="SearchIcon"
          onChange={(e) => updateSearchFilter(e.target.value)}
          placeholder={getString('searchPayments', language)}
        />
      </Header>
      {status === 'open' ? (
        <OpenList
          filter={filter}
          page={currentApprovedPage}
          setPage={setCurrentApprovedPage}
          selectedRole={tabs.length ? tabs[0] : undefined}
        />
      ) : (
        <ApprovedList
          filter={filter}
          page={currentOpenPage}
          setPage={setCurrentOpenPage}
          selectedRole={tabs.length ? tabs[0] : undefined}
        />
      )}
    </div>
  );
};

export default PaymentOverviewContainer;
