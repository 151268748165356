import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';

import { is590Capable } from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPlanName, getSortedAndActivePlans } from 'util/samplePlan';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { putAdd590 } from 'store/samplePlans/requests';
import { Header, Selector, Spinner, SuperAdminAccess } from 'common';

import { FieldLabSamplesChart } from './FieldLabSamplesChart';

import styles from './Container.module.css';

type SampleStatusParams = {
  operationId: string;
  fieldId: string;
  planId: string;
};

const SampleStatusContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { operationId, fieldId, planId } = useParams<SampleStatusParams>();
  const numericOperationId = Number(operationId);
  const numericFieldId = Number(fieldId);
  const numericPlanId = Number(planId);
  const { operation, fieldGeometry, fieldHasFetched, fieldIsFetching, samples } = useSelector(
    (state: RootState) => ({
      operation: state.operations.operationsById[numericOperationId],
      fieldGeometry: state.fieldGeometry.geometries[numericFieldId],
      fieldHasFetched: [
        ...state.fieldGeometry.hasFetchedList,
        ...state.fieldGeometry.hasFailedList,
      ].includes(numericFieldId),
      fieldIsFetching: [...state.fieldGeometry.isFetchingList].includes(numericFieldId),
      samples: state.labSamples.byFieldId[numericFieldId],
    }),
  );

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericOperationId));
    }
  }, [dispatch, numericOperationId, operation]);

  useEffect(() => {
    dispatch(getFieldGeometry(numericFieldId, true));
  }, [fieldId, dispatch, numericFieldId]);

  useEffect(() => {
    if (operation) {
      dispatch(setActiveOperation(operation.id));
    }
  }, [dispatch, operation]);

  const sortedPlans = useMemo(() => {
    if (fieldGeometry?.features[0].properties) {
      return getSortedAndActivePlans(fieldGeometry.features[0].properties);
    }
    return [];
  }, [fieldGeometry]);

  const selectedPlan = useMemo(
    () => sortedPlans.find((plan) => plan.id === Number(planId)),
    [sortedPlans, planId],
  );

  const planSamples = useMemo(
    () => samples?.filter((s) => s.sampling_plan_id === selectedPlan?.id) || [],
    [samples, selectedPlan],
  );

  const samplePlanOptions = useMemo(
    () =>
      sortedPlans.map((plan, idx) => ({
        id: idx,
        displayName: getPlanName(plan),
        value: plan.id,
      })),
    [sortedPlans],
  );

  const optionIndex = samplePlanOptions.findIndex((option) => String(option.value) === planId);

  const showAnotherPlan = useCallback(
    (optionId: number) => {
      const nextPlanId = samplePlanOptions[optionId].value;
      navigate(`/view-sample-status/${operationId}/${fieldId}/${nextPlanId}`);
    },
    [navigate, samplePlanOptions, operationId, fieldId],
  );

  useEffect(() => {
    if (samplePlanOptions.length && !planId) {
      showAnotherPlan(0);
    }
  }, [samplePlanOptions, showAnotherPlan]);

  const add590ToPlan = useCallback(async () => {
    try {
      await putAdd590(numericPlanId);
      dispatch(getFieldGeometry(numericFieldId, true));
    } catch (error) {
      showToast(getString('requestFailed', language), 'error');
    }
  }, [numericFieldId]);

  if (!operation || !fieldGeometry?.features[0].properties || !selectedPlan) {
    return <Spinner fill />;
  }

  const planHas590 = planSamples.some((sample) => sample.is_590_analysis);
  const is590Eligible = is590Capable(
    fieldGeometry.features[0].properties.country_code || '',
    fieldGeometry.features[0].properties.state,
  );

  return (
    <div className={styles.Wrapper}>
      <Header title={`${operation.name} - ${fieldGeometry.features[0].properties.name}`}>
        <div>
          {is590Eligible ? (
            <SuperAdminAccess>
              <Button disabled={planHas590} mr="md" onClick={add590ToPlan} variant="outline">
                {getString(planHas590 ? 'analysis590Selected' : 'add590', language)}
              </Button>
            </SuperAdminAccess>
          ) : null}
          {samplePlanOptions.length > 0 && (
            <Selector
              activeIndex={optionIndex}
              onChange={showAnotherPlan}
              options={samplePlanOptions}
            />
          )}
        </div>
      </Header>
      <div>
        {fieldIsFetching ? (
          <Spinner />
        ) : (
          <FieldLabSamplesChart
            field={fieldGeometry}
            samplingPlan={selectedPlan}
            hasFetched={fieldHasFetched}
          />
        )}
      </div>
    </div>
  );
};

export default SampleStatusContainer;
