import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Loader, Stack, Text } from '@mantine/core';

import { formatCalendarMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { getLabSamples, markSampleArrived } from 'store/labSamples/thunks';
import { LabSampleType } from 'store/labSamples/types';

type MarkArrivedPropsType = {
  sample: LabSampleType;
  searchBarFocus: () => void;
};

export const MarkArrived = ({ sample, searchBarFocus }: MarkArrivedPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMarkArrived = async () => {
    if (sample.barcode) {
      try {
        setIsSubmitting(true);
        await dispatch(markSampleArrived(sample.sample_uuid, sample.barcode));
        await dispatch(getLabSamples(sample.field_id));
        searchBarFocus();
        setIsSubmitting(false);
      } catch (e) {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Group align="flex-start">
      <Stack gap="0" miw="5rem">
        <Text size="md" fw={700}>{`${getString('arrived', language)}: `}</Text>
        {sample.arrived_at && (
          <Text size="sm" fw={400}>
            {formatCalendarMonthDayYear(sample.arrived_at)}
          </Text>
        )}
      </Stack>
      <Group gap="xs" pos="relative">
        <Button
          variant="outline"
          onClick={() => handleMarkArrived()}
          data-test-id="mark-arrived"
          disabled={Boolean(sample.arrived_at) || isSubmitting}
        >
          {getString(isSubmitting ? 'submitting' : 'markArrived', language)}
        </Button>
        {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
      </Group>
    </Group>
  );
};
