import { Button } from '@mantine/core';

import { getString } from 'strings/translation';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styles from './FieldBoundaryEditor.module.css';

type Props = {
  disableAdd: boolean;
  disableReload: boolean;
  isLoading: boolean;
  language: string;
  onAddClick: VoidFunction;
  onReloadClick: VoidFunction;
  hasFetched: boolean;
};

const ClusButtons = ({
  disableAdd,
  disableReload,
  isLoading,
  language,
  onAddClick,
  onReloadClick,
  hasFetched,
}: Props) => {
  return (
    <div className={styles.CluButtonsContainer}>
      <Button disabled={disableAdd} onClick={onAddClick} variant="white">
        {getString('addClus', language)}
      </Button>
      <Button
        disabled={disableReload}
        className={styles.ReloadClus}
        onClick={onReloadClick}
        variant="white"
        loading={isLoading}
      >
        {getString(hasFetched ? 'reloadClus' : 'loadClus', language)}
      </Button>
    </div>
  );
};

export default ClusButtons;
