import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { projectsRoutes } from 'constants/routes';

import { usePageTitle } from 'util/hooks/usePageTitle';
import { allProjectsQueryOptions } from 'util/queryOptions';
import { Header } from 'common';
import CenteredLoader from 'common/CenteredLoader';

import { useProjectsTranslations } from './hooks';
import ProjectsTable from './ProjectsTable';

const ProjectsContainer = () => {
  const translations = useProjectsTranslations();
  const { data = [], isPending } = useQuery(allProjectsQueryOptions);

  usePageTitle(translations.projects);

  return (
    <div>
      <Header title={translations.projects}>
        <Button
          component={Link}
          to={`${projectsRoutes.routes.operationsList}?${projectsRoutes.queryParams.project}`}
        >
          {translations.createNewProject}
        </Button>
      </Header>
      {isPending ? <CenteredLoader /> : <ProjectsTable projects={data} />}
    </div>
  );
};

export default ProjectsContainer;
