import { AppThunk } from 'store';

import { jobRequestError, receiveJob, receiveJobs, requestJobs } from './actions';
import { fetchJobs, updateJob } from './requests';
import { JobType } from './types';

export const getJobs =
  (operationId: number): AppThunk =>
  (dispatch) => {
    dispatch(requestJobs);
    return fetchJobs(operationId)
      .then((response) => dispatch(receiveJobs(operationId, response)))
      .catch(() => dispatch(jobRequestError(operationId)));
  };

export default getJobs;

export const editJob =
  (id: number, job: JobType): AppThunk =>
  (dispatch) => {
    dispatch(requestJobs);
    return updateJob(id, job)
      .then((response: JobType) => dispatch(receiveJob(response.operation_id, response)))
      .catch(() => dispatch(jobRequestError(job.operation_id)));
  };
