import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { Button, Center, Space, Text } from '@mantine/core';

import useBrowserLanguage from 'util/hooks/useLanguage';
import { handleJsonResponse, requestPost, SERVICE_URL } from 'util/request';
import { cleanFileName } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { OperationType } from 'store/operation/types';

import Modal from './Modal';

import styles from './Uploader.module.css';

interface SignedUrlResponse {
  msg?: string;
  args?: { signed_url: string };
}

interface UploaderProps {
  fetchJobs: () => void;
  operation: OperationType;
}

const Uploader = ({ fetchJobs, operation }: UploaderProps) => {
  const language = useBrowserLanguage();
  const inputRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState('');
  const [modalStep, setModalStep] = useState('');
  const [percentComplete, setPercentComplete] = useState(0);
  const [request, setRequest] = useState<XMLHttpRequest>();

  const hideModal = useCallback(() => setModalStep(''), [setModalStep]);

  const uploadFile = useCallback(
    async (file: File, name: string) => {
      const response = await requestPost(`${SERVICE_URL}/operation_file_upload/${operation.id}`, {
        body: {
          object_name: name,
        },
      });
      const json = (await handleJsonResponse(response)) as SignedUrlResponse;
      if (!json.args) {
        return setModalStep('failure');
      }

      setModalStep('pending');
      const { signed_url: signedUrl } = json.args;

      const xhr = new XMLHttpRequest();
      setRequest(xhr);
      const formData = new FormData();
      formData.append(file.name, file);

      const successHandler = async () => {
        await fetchJobs();
        hideModal();
      };
      const progressHandler = (event: ProgressEvent) => {
        const percent = ((event.loaded * 100) / event.total).toFixed(1);
        setPercentComplete(Number(percent));
      };

      xhr.upload.addEventListener('progress', progressHandler);
      xhr.addEventListener('load', successHandler);
      xhr.addEventListener('error', () => setModalStep('failure'));
      xhr.addEventListener('abort', hideModal);

      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', 'application/zip');
      xhr.send(formData);
      return xhr;
    },
    [fetchJobs, hideModal, operation],
  );

  const handleFileChange = useCallback(
    (event: ChangeEvent) => {
      const input = event.target as HTMLInputElement;
      const file = input.files && input.files[0];
      if (file && file.name.split('.').length === 2) {
        const newFileName = cleanFileName(file.name);
        setFileName(newFileName);
        uploadFile(file, newFileName);
      } else {
        setModalStep('invalid');
      }
    },
    [setFileName, uploadFile],
  );

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
      inputRef.current.value = '';
    }
  };

  const cancelUpload = useCallback(() => {
    if (request) {
      request.abort();
    }
  }, [request]);

  return (
    <>
      <div className={styles.Container}>
        <Space h="md" />
        <Center ta="center">
          <Text size="xs">{getString('uploadShapefilesOfYourFields', language)}:</Text>
        </Center>
        <Button className={styles.Upload} onClick={handleClick} leftSection={<FiUploadCloud />}>
          {getString('uploadZipFile', language)}
        </Button>
        <Text size="xs">{getString('uploadZipTip', language)}</Text>
      </div>
      <input accept=".gz,.zip" hidden onChange={handleFileChange} ref={inputRef} type="file" />
      <Modal
        cancelUpload={cancelUpload}
        fileName={fileName}
        hideModal={hideModal}
        modalStep={modalStep}
        operationName={operation.name}
        percentComplete={percentComplete}
      />
    </>
  );
};

export default Uploader;
