import React, { useState } from 'react';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { dismissAlert, snoozeAlert } from 'store/samplePlans/requests';
import { AlertType } from 'store/samplePlans/types';
import { Button, ClickOutsideWrapper, Header, Input } from 'common';

import styles from './AlertsModal.module.css';

interface AlertsModalProps {
  toggleModal: ({ show, snooze }: { show: boolean; snooze: boolean }) => void;
  snooze: boolean;
  alerts: AlertType[];
  onClose: (val: boolean) => void;
}

const AlertsModal = ({ toggleModal, snooze, alerts, onClose }: AlertsModalProps) => {
  const language = useBroswerLanguage();

  const [alertIds, setAlertIds] = useState<number[]>([]);

  const addAlert = (id) => {
    setAlertIds([...alertIds, id]);
  };

  const removeAlert = (id) => {
    setAlertIds(alertIds.filter((a) => a !== id));
  };

  const updateAlert = async () => {
    try {
      await Promise.all(
        alertIds.map(async (id) => {
          if (snooze) {
            await snoozeAlert(id);
          } else {
            await dismissAlert(id);
          }
        }),
      );
    } catch (e: any) {
      // eslint-disable-next-line
      console.error(e);
      showToast(getString('updatingAlertError', language));
    }
  };

  const handleClickedConfirm = async () => {
    await updateAlert();
    onClose(true);
    toggleModal({ show: false, snooze: false });
  };

  return (
    <>
      <ClickOutsideWrapper
        className={styles.AlertsModal}
        onClickOutside={() => toggleModal({ show: false, snooze: false })}
      >
        <div>
          <div className={styles.Header}>
            <Header
              className={styles.Header}
              size="H3"
              underline="thin"
              title={
                snooze
                  ? getString('selectAlertsToSnooze', language)
                  : getString('selectAlertsToDismiss', language)
              }
            />
          </div>
          {alerts.map((alert) => {
            const checked = alertIds.includes(alert.id);
            return (
              <div
                key={alert.name}
                className={styles.AlertRow}
                onClick={checked ? () => removeAlert(alert.id) : () => addAlert(alert.id)}
              >
                <p>{getString(alert.name, language)}</p>
                <Input className={styles.AlertCheckbox} type="checkbox" checked={checked} />
              </div>
            );
          })}
          <div className={styles.AlertButtonContainer}>
            <Button
              danger
              onClick={() => toggleModal({ show: false, snooze: false })}
              primary
              className={styles.AlertButtonCancel}
            >
              {getString('cancel', language)}
            </Button>
            <Button onClick={handleClickedConfirm} primary className={styles.AlertButtonConfirm}>
              {getString('confirm', language)}
            </Button>
          </div>
        </div>
      </ClickOutsideWrapper>
      <div className={styles.Overlay} />
    </>
  );
};

export default AlertsModal;
