import { AppThunk } from 'store';

import {
  paymentsRequestError,
  receiveApprovedPayment,
  receiveApprovedPayments,
  receiveOpenTransaction,
  receiveOpenTransactions,
  requestPayments,
} from './actions';
import {
  getAllApprovedPayments,
  getAllOpenTransactions,
  getApprovedPayment,
  getOpenTransaction,
  postAdjustment,
  putPaymentAsSettled,
} from './requests';
import { AdjustmentsType } from './types';

export const getOpenTransactions =
  (page: number, search: string, selectedRole: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(requestPayments);
    try {
      const response = await getAllOpenTransactions(search, page, selectedRole);
      return dispatch(receiveOpenTransactions(response));
    } catch (e) {
      return dispatch(paymentsRequestError);
    }
  };

export const getApprovedPayments =
  (page: number, search: string, selectedRole: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(requestPayments);
    try {
      const response = await getAllApprovedPayments(search, page, selectedRole);
      return dispatch(receiveApprovedPayments(response));
    } catch (e) {
      return dispatch(paymentsRequestError);
    }
  };

export const getOpenTransactionById =
  (id: number, planIds?: number[]): AppThunk =>
  async (dispatch) => {
    dispatch(requestPayments);
    try {
      const response = await getOpenTransaction(id, planIds || []);
      return dispatch(receiveOpenTransaction(response));
    } catch (e) {
      return dispatch(paymentsRequestError);
    }
  };

export const getApprovedPaymentByUuid =
  (uuid: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestPayments);
    try {
      const response = await getApprovedPayment(uuid);
      return dispatch(receiveApprovedPayment(response));
    } catch (e) {
      return dispatch(paymentsRequestError);
    }
  };

export const createAdjustment =
  (body: AdjustmentsType, planIds: number[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestPayments);
      await postAdjustment(body);
      dispatch(getOpenTransactionById(body.user_id, planIds));
    } catch (err) {
      throw dispatch(paymentsRequestError);
    }
  };

export const markPaymentAsSettled =
  (uuid: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestPayments);
      await putPaymentAsSettled(uuid);
      dispatch(getApprovedPayments(1, '', undefined));
    } catch (err) {
      throw dispatch(paymentsRequestError);
    }
  };
