import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

export const useEditOperationTranslations = () => {
  const language = useBroswerLanguage();

  return {
    totalFarm: getString('totalFarm', language),
    totalFarmOperationMsg: getString('totalFarmOperationMsg', language),
    activateTotalFarm: getString('activateTotalFarm', language),
    fertilityRx: getString('fertilityRx', language),
    seedRx: getString('seedRx', language),
    cropPlanning: getString('cropPlanning', language),
    keyOutcomeMsg: getString('keyOutcomeMsg', language),
    highResNutrientScriptMsg: getString('highResNutrientScriptMsg', language),
    highResVariableRateScriptMsg: getString('highResVariableRateScriptMsg', language),
    customRxMsg: getString('customRxMsg', language),
    comprehensiveFieldAnalysisMsg: getString('comprehensiveFieldAnalysisMsg', language),
    highResSoilFertMsg: getString('highResSoilFertMsg', language),
    comprehensiveFieldBioMsg: getString('comprehensiveFieldBioMsg', language),
    howItWorks: getString('howItWorks', language),
    premiumFeatures: getString('premiumFeatures', language),
    activeTodayGenerateUnlimitedMsg: getString('activeTodayGenerateUnlimitedMsg', language),
    analysisOverYear: getString('analysisOverYear', language),
    executeTargetAnalysisMsg: getString('executeTargetAnalysisMsg', language),
    percentOfFarmAnalyzedPerYear: getString('percentOfFarmAnalyzedPerYear', language),
    totalAcres: getString('totalAcres', language),
    estTotalFarmPrice: getString('estTotalFarmPrice', language),
    subscriptionCoversAnalysisMsg: getString('subscriptionCoversAnalysisMsg', language),
    totalFarmActivatedMsg: getString('totalFarmActivatedMsg', language),
    subscriptionRenewal: getString('subscriptionRenewal', language),
    package: getString('package', language),
    acres: getString('acres', language),
    list: getString('list', language),
    agency: getString('agency', language),
    price: getString('price', language),
    cancel: getString('cancel', language),
    cancelTotalFarm: getString('cancelTotalFarm', language),
    cancelTotalFarmMsg: getString('cancelTotalFarmMsg', language),
    totalFarmCancelledMsg: getString('totalFarmCancelledMsg', language),
    keepTotalFarm: getString('keepTotalFarm', language),
    customRx: getString('customRx', language),
    payAsYouGo: getString('payAsYouGo', language),
    payAsYouGoMsg: getString('payAsYouGoMsg', language),
    totalFarmAcres: getString('totalFarmAcres', language),
    year: getString('year', language),
    msrp: getString('msrp', language),
    dealerSelfSample: getString('dealerSelfSample', language),
    dealerSelfService: getString('dealerSelfService', language),
    dealerFullService: getString('dealerFullService', language),
    perYear: getString('perYear', language),
    seeDealerPrice: getString('seeDealerPrice', language),
  };
};
