import React from 'react';

import { PH } from 'constants/analysis';

import { PlateType } from 'store/plates/types';

import { EditNotes } from '../../Sections/Inputs/EditNotes';
import { PlateBatches } from '../../Sections/PlateBatches/PlateBatches';
import { PlateDocumentsContainer } from '../../Sections/PlateDocuments/Container';
import { PlateWells } from '../../Sections/PlateWells/PlateWells';
import WellTable from '../../Wells/WellTable';

import styles from '../Container.module.css';

type ChemistryAnalysisLayoutPropsType = {
  plate: PlateType;
};

export const ChemistryAnalysisLayout = ({ plate }: ChemistryAnalysisLayoutPropsType) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <div className={styles.Column}>
          <PlateWells plate={plate} runSheet={plate.analysis_type === PH} />
        </div>
        <div className={styles.Column}>
          {plate.analysis_type !== PH && <PlateBatches plate={plate} />}
          <EditNotes plate={plate} />
          <PlateDocumentsContainer plate={plate} />
        </div>
      </div>
      <WellTable plate={plate} />
    </div>
  );
};
