import { TextInput, TextInputProps } from '@mantine/core';

import { PROJECT_NAME_FORM_KEY } from 'constants/projects';

import { useProjectsTranslations } from './hooks';

type Props = TextInputProps;

const ProjectNameInput = (props: Props) => {
  const translations = useProjectsTranslations();

  return (
    <TextInput
      required
      withAsterisk
      name={PROJECT_NAME_FORM_KEY}
      w={300}
      type="text"
      label={translations.projectName}
      descriptionProps={{
        style: { textTransform: 'lowercase' },
      }}
      {...props}
    />
  );
};

export default ProjectNameInput;
