import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import showToast, { ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import getSamplePrices from 'store/samplePrices/thunks';
import { SamplePrice } from 'store/samplePrices/types';
import { Button, Header } from 'common';

import SamplePricingModal from './SamplePricingModal';
import SamplePricingTable from './SamplePricingTable';

const SamplePricingDashboardContainer = () => {
  const [showPriceModal, togglePriceModal] = useState(false);
  const [userSamplePrice, setUserSamplePrice] = useState<SamplePrice | null>(null);
  const dispatch = useDispatch();

  const setToastMessage = (message: string, type?: ToastType, time: number = 3000) => {
    showToast(message, type, time);
  };

  const { isFetching, samplePrices } = useSelector((state: RootState) => ({
    isFetching: state.samplePrices.isFetching,
    samplePrices: state.samplePrices.samplePrices,
  }));

  useEffect(() => {
    dispatch(getSamplePrices());
  }, [dispatch]);

  const editUserPrice = (user_id: number) => {
    setUserSamplePrice(samplePrices[user_id]);
    togglePriceModal(true);
  };

  const hideModal = () => {
    togglePriceModal(false);
    setUserSamplePrice(null);
  };

  return (
    <div>
      <Header title="Manage Sampling Prices">
        {showPriceModal && (
          <SamplePricingModal
            hideModal={hideModal}
            onCancel={() => togglePriceModal(false)}
            setToastMessage={setToastMessage}
            userSamplePrice={userSamplePrice}
          />
        )}
        <Button disabled={isFetching} primary onClick={() => togglePriceModal(true)}>
          Create User Sample Price
        </Button>
      </Header>
      <SamplePricingTable
        userPrices={samplePrices}
        setToastMessage={setToastMessage}
        editUserPrice={editUserPrice}
      />
    </div>
  );
};

export default SamplePricingDashboardContainer;
