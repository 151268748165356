import { useEffect, useMemo, useState } from 'react';
import { FiChevronDown, FiEdit, FiSave } from 'react-icons/fi';
import {
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Popover,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FeatureCollection, Geometry } from '@turf/helpers';

import { UI_COLORS } from 'constants/colors';
import { NDVI_KEY } from 'constants/imagery';
import { YIELD_KEY } from 'constants/machineData';
import { CUSTOM_ZONE_MAX, IMAGERY, SOIL_TEST, YIELD } from 'constants/prescription';

import { deepEqual } from 'util/deepEqual';
import { isNumber } from 'util/numUtils';
import {
  convertNumericCustomZonesToString,
  convertStringCustomZonesToNumeric,
  formatSamplesSourceLayer,
  formatSourceLayerGeojson,
  getDefaultRxCustomZones,
  getEqualAreaCustomZones,
  getEqualRangeCustomZones,
  sourceLayerIsConstantValue,
  validateCustomZones,
} from 'util/prescription';
import { handleJsonResponse } from 'util/request';
import { getString } from 'strings/translation';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FieldType } from 'store/fields/types';
import { RxCustomZoneFormType, RxCustomZoneType } from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';
import { User } from 'store/user/types';

import { RxSettingsStateType } from '../SinglePrescription/Settings';

import { PrescriptionStateType } from './Container';
import CustomZoneRow from './CustomZoneRow';

type CustomZoneEditorProps = {
  currentUser: User | null;
  field: FieldType;
  fullWidth?: boolean;
  language: string;
  onSave?: VoidFunction;
  proLayer: EOInferenceLayerType | null | undefined;
  rxForm: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>;
  samples?: SampleFeatureType[];
  saveLayerZones?: boolean;
};

const CustomZoneEditor = ({
  currentUser,
  field,
  fullWidth,
  language,
  onSave,
  proLayer,
  rxForm,
  samples,
  saveLayerZones = true,
}: CustomZoneEditorProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [configMenuOpen, setConfigMenuOpen] = useState(false);
  const {
    analytic,
    customMapLayer,
    customZones,
    generateFrom,
    harvestLayer,
    imageryLayer,
    rxType,
    savedConfigName,
    shouldSaveConfig,
    sourceLayerGeojson,
  } = rxForm.getValues();

  const availableConfigurations = (currentUser?.custom_prescription_zone_configs || []).filter(
    (config) => config.category === rxType,
  );

  const updateField = <T,>(
    form: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>,
    key: keyof T,
    value: T[keyof T],
  ): void => {
    (form.setFieldValue as (key: keyof T, value: T[keyof T]) => void)(key, value);
  };

  const addRow = () => {
    const insertIndex = Math.max(customZones.length - 1, 0);
    const previous = customZones[customZones.length - 2];
    const zoneToAdd: RxCustomZoneFormType = {
      amount: '0',
      max: null,
      min: previous?.max || '0',
    };
    const previousZones = customZones.slice(0, insertIndex);
    const followingZones = customZones.slice(insertIndex);
    updateField(rxForm, 'customZones', [...previousZones, zoneToAdd, ...followingZones]);
  };

  const removeRow = (index: number) => {
    const previousElements = customZones.slice(0, index);
    const followingElements = customZones.slice(index + 1);
    updateField(rxForm, 'customZones', previousElements.concat(followingElements));
  };

  const updateZoneValue = (index: number, key: string, value: string) => {
    if (!isNumber(value)) {
      return;
    }
    const newZones = customZones.map((zone, idx) => {
      // when updating min values for a row past the first row,
      // update the previous zone's max value to new value
      if (idx === index + 1 && key === CUSTOM_ZONE_MAX) {
        return {
          ...zone,
          min: value || zone.min,
        };
      }
      if (idx !== index) {
        return zone;
      }
      return {
        ...zone,
        [key]: value,
      };
    });
    updateField(rxForm, 'customZones', newZones);
  };

  const numericCustomZones = convertStringCustomZonesToNumeric(customZones);

  useEffect(() => {
    const getSourceLayerData = async () => {
      const { composite_imagery_layers, harvest_data_files } = field.features[0].properties;

      if (generateFrom === IMAGERY || customMapLayer === IMAGERY) {
        const activeImagery = composite_imagery_layers.find(
          (layer) => layer.geojson_uri === imageryLayer,
        );
        if (activeImagery) {
          const response: FeatureCollection<Geometry, { [YIELD_KEY]: number }> =
            await handleJsonResponse(await fetch(activeImagery.geojson_uri));
          const sourceGeojson = formatSourceLayerGeojson(response, NDVI_KEY);
          return [
            getDefaultRxCustomZones([], generateFrom, null, null, sourceGeojson),
            sourceGeojson,
          ];
        }
      }
      if (generateFrom === YIELD || customMapLayer === YIELD) {
        const activeHarvest = harvest_data_files.find(
          (layer) => layer.processed_geojson_uri === harvestLayer,
        );
        if (activeHarvest) {
          const response: FeatureCollection<Geometry, { [YIELD_KEY]: number }> =
            await handleJsonResponse(await fetch(activeHarvest.processed_geojson_uri));
          const sourceGeojson = formatSourceLayerGeojson(response, YIELD_KEY);
          return [
            getDefaultRxCustomZones([], generateFrom, null, null, sourceGeojson),
            sourceGeojson,
          ];
        }
      }
      if (generateFrom === SOIL_TEST || isNumber(customMapLayer)) {
        if (proLayer?.layer_param) {
          const response: FeatureCollection<
            Geometry,
            Record<string, number>
          > = await handleJsonResponse(await fetch(proLayer.geojson_uri));
          const sourceGeojson = formatSourceLayerGeojson(response, proLayer.layer_param);
          return [
            getDefaultRxCustomZones([], generateFrom, null, null, sourceGeojson),
            sourceGeojson,
          ];
        }
        if (samples && analytic) {
          const sourceGeojson = formatSamplesSourceLayer(samples, analytic);
          return [
            getDefaultRxCustomZones([], generateFrom, null, null, sourceGeojson),
            sourceGeojson,
          ];
        }
      }
      return [null, null];
    };
    const setSourceGeojson = async () => {
      const [newCustomZones, newSourceGeojson] = await getSourceLayerData();
      if (newCustomZones && newSourceGeojson && !deepEqual(newSourceGeojson, sourceLayerGeojson)) {
        updateField(rxForm, 'sourceLayerGeojson', newSourceGeojson);
        if (saveLayerZones) {
          updateField(
            rxForm,
            'customZones',
            convertNumericCustomZonesToString(newCustomZones as RxCustomZoneType[]),
          );
        }
      }
    };
    setSourceGeojson();
  }, [analytic, customMapLayer, field, generateFrom]);

  const generateEqualRangeZones = () => {
    updateField(
      rxForm,
      'customZones',
      convertNumericCustomZonesToString(getEqualRangeCustomZones(numericCustomZones)),
    );
  };

  const generateEqualAreaZones = () => {
    updateField(
      rxForm,
      'customZones',
      convertNumericCustomZonesToString(
        getEqualAreaCustomZones(numericCustomZones, sourceLayerGeojson),
      ),
    );
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
      setIsEditing(false);
    }
  };

  const sourceIsConstantValue = useMemo(
    () => sourceLayerIsConstantValue(sourceLayerGeojson),
    [sourceLayerGeojson],
  );

  return (
    <>
      <Title order={3}>{getString('customZoneSettings', language)}</Title>
      <Stack my="md" w={fullWidth ? '100%' : '60%'}>
        <Flex align="center" gap="sm" justify="space-between">
          <Button onClick={generateEqualRangeZones} radius="xs" size="compact-sm" variant="outline">
            {getString('setEqualRanges', language)}
          </Button>
          <Button onClick={generateEqualAreaZones} radius="xs" size="compact-sm" variant="outline">
            {getString('setEqualAreaSplit', language)}
          </Button>
          <Popover
            onChange={setConfigMenuOpen}
            opened={configMenuOpen}
            position="bottom"
            shadow="md"
            withArrow
          >
            <Popover.Target>
              <Button
                disabled={!availableConfigurations.length}
                onClick={() => setConfigMenuOpen(!configMenuOpen)}
                radius="xs"
                rightSection={<FiChevronDown />}
                size="compact-sm"
                variant="outline"
              >
                {getString('savedZoneConfigs', language)}
              </Button>
            </Popover.Target>
            <Popover.Dropdown p={0}>
              {availableConfigurations.map((config) => (
                <Button
                  c={UI_COLORS.darkBlue}
                  fullWidth
                  key={config.id}
                  onClick={() => updateField(rxForm, 'customZones', config.zone_meta)}
                  radius={0}
                  variant="default"
                >
                  {config.name}
                </Button>
              ))}
            </Popover.Dropdown>
          </Popover>
        </Flex>
        {saveLayerZones && (
          <Stack>
            <Flex align="center" gap="xl" justify="space-between">
              <Text>{getString('saveZoneConfig', language)}</Text>
              <Switch
                checked={shouldSaveConfig}
                onChange={() => updateField(rxForm, 'shouldSaveConfig', !shouldSaveConfig)}
              />
            </Flex>
            <TextInput
              aria-label={getString('name', language)}
              onChange={(event) =>
                updateField(rxForm, 'savedConfigName', event.currentTarget.value)
              }
              placeholder={getString('enterNameToSave', language)}
              value={savedConfigName}
            />
          </Stack>
        )}
      </Stack>
      <Divider />
      <Center my="md">
        <Flex align="center" gap="md">
          <Text>{getString('editZoneValues', language)}</Text>
          <Button
            onClick={() => setIsEditing(!isEditing)}
            size="compact-sm"
            variant={isEditing ? 'filled' : 'outline'}
          >
            {isEditing ? <FiSave /> : <FiEdit />}
          </Button>
        </Flex>
      </Center>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w="10%">{getString('zone', language)}</Table.Th>
            <Table.Th w="25%">{getString('min', language)}</Table.Th>
            <Table.Th w="25">{getString('max', language)}</Table.Th>
            <Table.Th w="10%">{getString('percentOfField', language)}</Table.Th>
            <Table.Th w="25%">{getString('rate', language)}</Table.Th>
            <Table.Th w="5%" />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {customZones.map((zone, index) => (
            <CustomZoneRow
              field={field}
              fullList={customZones}
              key={`rx-custom-zone-${index}`}
              index={index}
              isEditing={isEditing}
              form={rxForm}
              removeRow={removeRow}
              updateZoneValue={updateZoneValue}
              zone={zone}
            />
          ))}
        </Table.Tbody>
      </Table>
      <Group justify="flex-end">
        <Button disabled={sourceIsConstantValue} onClick={addRow}>
          {getString('addZone', language)}
        </Button>
      </Group>
      {onSave && isEditing && (
        <Center>
          <Button
            disabled={
              !(
                customZones.length &&
                validateCustomZones(convertStringCustomZonesToNumeric(customZones))
              )
            }
            onClick={handleSave}
          >
            {getString('save', language)}
          </Button>
        </Center>
      )}
    </>
  );
};

export default CustomZoneEditor;
