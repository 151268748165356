import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Center, Group, SegmentedControl, Space, Stack, Text } from '@mantine/core';

import { AMMONIUM_ACETATE, BRAY, M3, OLSEN } from 'constants/analysis';

import { getAnalysisMethodOptions } from 'util/analysis';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { updateAgencyResultPreferences } from 'store/agencies/thunks';
import { updateOperationResultPreferences } from 'store/operation/thunks';
import { ResultPreferencesType } from 'store/operation/types';

type AnalysisMethodPreferencePropsType = {
  resultPreferences?: ResultPreferencesType;
  operationOrAgencyId: number;
  isOperation?: boolean;
  children?: React.ReactNode;
};

export const AnalysisMethodPreference = ({
  resultPreferences,
  operationOrAgencyId,
  isOperation,
  children,
}: AnalysisMethodPreferencePropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const [preferences, setPreferences] = useState<Partial<ResultPreferencesType>>(
    resultPreferences || {},
  );

  const pOptions = getAnalysisMethodOptions([M3, BRAY, OLSEN], language);
  const kCaMgOptions = getAnalysisMethodOptions([M3, AMMONIUM_ACETATE], language);
  // const microOptions = getAnalysisMethodOptions([M3, DTPA], language);

  const changeMethodPreference = async (value: string | boolean, ...key: string[]) => {
    try {
      const updatedP = key.reduce(
        (dict, currKey) => ({
          ...dict,
          [currKey]: value,
        }),
        preferences,
      );
      setPreferences(updatedP);
      if (isOperation) {
        await dispatch(updateOperationResultPreferences(operationOrAgencyId, updatedP));
      } else {
        await dispatch(updateAgencyResultPreferences(operationOrAgencyId, updatedP));
      }
      showToast(getString('analysisOptionsMsg', language));
    } catch (e) {
      showToast(e.message);
    }
  };

  const chemMethodTabs = (
    options: { id: number; displayName: string; label: string; value: string }[],
    ...keys: string[]
  ) => {
    const primaryKey = keys[0];
    return (
      <SegmentedControl
        value={preferences[primaryKey]}
        onChange={(value) => changeMethodPreference(value, ...keys)}
        data={options}
      />
    );
  };

  return (
    <Group>
      {children}
      <Stack>
        {chemMethodTabs(pOptions, 'p_method_shown')}
        <Center>
          <Text>{getString('phosphorus', language)}</Text>
        </Center>
      </Stack>
      <Space w="md" />
      <Stack>
        {chemMethodTabs(kCaMgOptions, 'ca_k_mg_method_shown')}
        <Center>
          <Text>K | Ca | Mg</Text>
        </Center>
      </Stack>
    </Group>
  );
};
