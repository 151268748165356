import { Fragment } from 'react';
import { Grid, Text } from '@mantine/core';
import { useProjectsTranslations } from 'apps/Projects/hooks';
import { useSamplePlanFormContext } from 'apps/Projects/SamplePlans/samplePlanFormContext';

import { DefaultSamplingPlanConfigResponse } from 'store/projects/types';

import FormHeading from './FormHeading';

type Props = {
  defaultConfig: DefaultSamplingPlanConfigResponse | undefined;
};

const LabsSection = ({ defaultConfig }: Props) => {
  const form = useSamplePlanFormContext();
  const translations = useProjectsTranslations();
  const { registry } = form.getValues();
  const registryConfig = defaultConfig?.[registry];

  const labNames = Object.values(registryConfig?.measurement_type_info || {}).reduce((acc, lab) => {
    return acc.includes(lab.lab_name) ? acc : [...acc, lab.lab_name];
  }, [] as string[]);

  return (
    <div>
      <FormHeading text={translations.labs} />
      <Grid align="center">
        {labNames.map((lab, i) => {
          const isDefault = i === 0;

          return (
            <Fragment key={lab}>
              <Grid.Col span={6}>
                <Text>{translations[isDefault ? 'defaultLab' : 'additionalLab']}:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text>{lab}</Text>
              </Grid.Col>
            </Fragment>
          );
        })}
      </Grid>
    </div>
  );
};

export default LabsSection;
