import React, { useEffect } from 'react';
import { Select, SelectProps } from '@mantine/core';

import { targetList } from 'constants/plates';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';

type TargetSelectorPropsType = SelectProps & {
  plate: Partial<PlateType>;
  quadrant?: number;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const TargetSelector = ({
  plate,
  quadrant = 1,
  onValueChange,
  ...props
}: TargetSelectorPropsType) => {
  const language = useBroswerLanguage();
  const quadrantKey = `quadrant${quadrant}_qpcr_target` as keyof PlateType;
  const plateTarget = plate[quadrantKey] as string;

  const targetOptions = targetList.map((t) => ({
    label: t,
    value: t,
  }));

  const handleOnChange = (value: string | null) => {
    if (value) {
      onValueChange(quadrantKey, value);
    }
  };

  useEffect(() => {
    if (plateTarget === undefined && targetOptions.length) {
      handleOnChange(targetOptions[0].value);
    }
  }, [plateTarget]);

  const labelString = `${getString(
    'quadrant',
    language,
  )} ${quadrant} ${getString('target', language)}`;

  return (
    <Select
      onChange={handleOnChange}
      data={targetOptions}
      value={plateTarget}
      label={labelString}
      {...props}
    />
  );
};
