import {
  JOB_REQUEST_ERROR,
  JobActionType,
  JobType,
  RECEIVE_JOB,
  RECEIVE_JOBS,
  REQUEST_JOBS,
} from './types';

export const requestJobs = {
  type: REQUEST_JOBS,
};

export const receiveJobs = (operation_id: number, jobs: JobType[]): JobActionType => ({
  type: RECEIVE_JOBS,
  payload: {
    operation_id,
    jobs,
  },
});

export const receiveJob = (operation_id: number, job: JobType): JobActionType => ({
  type: RECEIVE_JOB,
  payload: {
    operation_id,
    job,
  },
});

export const jobRequestError = (operation_id: number): JobActionType => ({
  type: JOB_REQUEST_ERROR,
  payload: { operation_id },
});
