import { RefObject } from 'react';
import { FeatureCollection, Point, Polygon, Properties } from '@turf/helpers';

import { POINT, POLYGON } from 'constants/mapbox';
import {
  COMPLETED_PLAN,
  CUSTOM_POINTS,
  GRID_POINTS,
  GRID_ZONES,
  SPLIT_DENSITY,
  SSURGO_ZONES,
  UPLOAD_ZONES,
  ZONE_BY_ZONE,
} from 'constants/samplePlanning';

import { SampleGeoJSON } from 'util/generalTypes';
import { SamplePlanType } from 'store/samplePlans/types';

export type SkuType = {
  analysis_sku_name: string | null;
  id: number;
  name: string;
  name_pt: string;
  netsuite_name: string;
  type: TypeOfSku;
  is_pro: boolean;
  is_till_rx: boolean;
  pressure_panel: boolean;
  performance_panel: boolean;
  nutrient_panel: boolean;
  nitrate_panel: boolean;
  is_590_analysis: boolean;
  sampling_zone_type: string | null;
  sampling_depth: number | null;
  analysis_sku_id: number | null;
  created_at: string;
  updated_at: string | null;
  active: boolean;
};

export type PriceType = 'per_acre' | 'per_sample';

export type ContractPricingPayload = {
  agency_id: number;
  committed_samples: number;
  expired_at: string;
  signed_at: string;
  sku_prices: ContractPricingFormSkuPrice[];
};

export type ContractPricingPostMutationVars = {
  contractId: number | null;
  payload: ContractPricingPayload;
};

export type ContractPricingType = {
  readonly id: number | null;
  readonly agency_id: number | null;
  readonly created_by_id: number | null;
  readonly active: boolean;
  readonly created_at: string;
  notes: string | null;
  committed_samples: number | null;
  expired_at: string | null;
  signed_at: string | null;
  sku_prices: {
    [key in TypeOfSku]: Array<
      ContractPricingFormSkuPrice & {
        name: string;
      }
    >;
  };
};

export type ContractPricingGetResponse = ContractPricingType[];

export type ContractPricingFormSkuPrice = {
  readonly id: number;
  readonly sku_name: string;
  readonly min_density: number | null;
  readonly max_density: number | null;
  readonly scan_min_density: number | null;
  readonly scan_max_density: number | null;
  readonly price_type: PriceType;
  price: number;
  list_price: number;
};

export type ContractPricingFormType = {
  committedSamples: number;
  expiredAt: Date;
  signedAt: Date;
  contractSkuPrices: {
    [key in TypeOfSku]: ContractPricingFormSkuPrice[];
  };
};

export type PriceDetailType = {
  id: number;
  price: number;
  sku_id: number | string;
  list_price: number;
  min_density: number | null;
  max_density: number | null;
  scan_min_density: number | null;
  scan_max_density: number | null;
  price_type: PriceType;
  notes: string;
};

export const priceDetailDensityKeys: (keyof Pick<
  PriceDetailType,
  'min_density' | 'max_density' | 'scan_min_density' | 'scan_max_density'
>)[] = ['min_density', 'max_density', 'scan_min_density', 'scan_max_density'];

export type DefaultPriceType = {
  sku: SkuType;
  prices: PriceDetailType[];
};

export type DefaultPricingResponse = Record<string, DefaultPriceType>;

export type SkuItemPayload = {
  sku_id: number;
  acres: number;
  density?: number;
  zones?: number;
  scan_points?: number;
  scan_density?: number;
};

export type GeneratePriceEstimatePayload = {
  agency_id: number | null;
  field_id: number;
  sku_prices: SkuItemPayload[];
  billing_agency_id?: number;
  assigned_to_id: number | null;
  assigned_to_scan_id: number | null;
  is_split_density: boolean;
  is_custom_analysis: boolean;
};

export type TypeOfSku =
  | 'analysis'
  | 'sampling'
  | 'scan'
  | 'adcp'
  | 'pro_map_generation'
  | 'till_rx_map_generation';

export type PriceSummary = {
  sku_id: number;
  acres: number;
  density: number;
  list_price: number;
  price_type: PriceType;
  price: number;
  sku_name: string;
  sku_type: TypeOfSku;
  total_list_price: number;
  total_price: number;
  zones: number;
  is_not_billed: boolean;
  is_custom_analysis: boolean;
};

export type PriceEstimateResponse = {
  estimated_price: number;
  estimated_price_per_acre: number;
  estimated_list_price: number;
  estimated_list_price_per_acre: number;
  price_summary: PriceSummary[];
};

export type OrderSummaryMode = 'listPrice' | 'agencyPrice';

type NonPointCreationOption =
  | typeof SSURGO_ZONES
  | typeof UPLOAD_ZONES
  | typeof GRID_ZONES
  | typeof COMPLETED_PLAN;
type ProPointCreationOption = typeof GRID_POINTS | typeof CUSTOM_POINTS | typeof COMPLETED_PLAN;
type AnalysisMode = typeof SPLIT_DENSITY | typeof ZONE_BY_ZONE;

export type CreationOption = NonPointCreationOption | ProPointCreationOption;

/** Schema of the `useForm` and its form context instance */
export type OrderForm = {
  scannerIndex: number;
  existingSamples: SampleGeoJSON[];
  completedPlan: SamplePlanType | null;
  completedSamples: SampleGeoJSON[];
  completedScanPoints: SampleGeoJSON[];
  tempPlan: Partial<SamplePlanType> | null;
  isSplitDensity: boolean;
  is590Analysis: boolean;
  isProScan: boolean;
  isTillRx: boolean;
  rnd: boolean;
  name: string;
  readyToSample: boolean;
  samplerIndex: number;
  sampleTimingIndex: number;
  products: string[];
  gridAngle: number;
  disableMapTools: boolean;
  priceSummary: SamplePlanType['price_summary'];
  zones: FeatureCollection<Polygon | Point> | null;
  previewZones: FeatureCollection<Polygon | Point> | null;
  zoneGeomType: typeof POLYGON | typeof POINT;
  zonesLocked: boolean;
  editScanPoints: boolean;
  existingPlan: SamplePlanType | null;
  splitIndex: number;
  isSplitDensityNitrate: boolean;
  notes: string | undefined;
  density: number;
  scanDensity: number;
  agencyId: number | undefined | null;
  nutrientExternalLabIdentifier: string | null;
  primaryProductId: number;
  nutrient: boolean;
  truBio: boolean;
  truNutrient: boolean;
  truTill: boolean;
  nutrientAnalysisPackageId: number | null;
  externalLabKey: string;
  /// Plan metadata/instructions \\\
  sampledById: number;
  samplePlanName: string; // "name" in the old state
  samplerInstructions: string; // "notes" in the old state
  sampleTiming: string | null;
  scannedById: number;
  /// Zone options \\\
  analysisMode: AnalysisMode;
  creationOption: CreationOption; // will be a subset for non-Pro scenarios
  proPointCreationOption: ProPointCreationOption;
  proPointsZonesFile: File | null;
  zonesFile: File | null;
  scanPoints: FeatureCollection<Point> | null;
  previewScanPoints: FeatureCollection<Polygon | Point> | null;
  disableScanMapTools: boolean;
  zonesCount: number;
  scanPointsCount: number;
  isNotBilled?: boolean;
};

export type DefaultOrderFormMapType = Pick<
  OrderForm,
  | 'zoneGeomType'
  | 'creationOption'
  | 'zones'
  | 'zonesLocked'
  | 'density'
  | 'scanDensity'
  | 'scanPoints'
  | 'previewZones'
  | 'previewScanPoints'
  | 'disableMapTools'
  | 'editScanPoints'
  | 'gridAngle'
  | 'proPointCreationOption'
  | 'proPointsZonesFile'
  | 'disableScanMapTools'
  | 'zonesFile'
  | 'proPointsZonesFile'
>;

export type OrderFormTransformedType = {
  isProOrTillRx: boolean;
  hasBioProducts: boolean;
  isSampleOnly: boolean;
  isSampleAndScan: boolean;
  isScanPointsOnly: boolean;
  canSplitDensity: boolean;
  hasProducts: boolean;
  scanPointsCount: number;
  zonesCount: number;
  is590DensityValid: boolean;
};

export type MapReferenceType = RefObject<mapboxgl.Map | null>;
export type MapDrawReferenceType = RefObject<any | null>;
export type MouseDownType = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void;
export type MouseDownReferenceType = RefObject<MouseDownType>;

export type PreviewZonesType = FeatureCollection<Polygon | Point, Properties> | null;

export type PaymentPayloadType = {
  billing_user_id: number;
  sampling_plan_ids: number[];
  adjustment_ft_ids: number[];
  is_payment_only: boolean;
};

export type IncompleteSalesOrderType = {
  id: number;
  billing_user_id: number;
  first_name: string;
  last_name: string;
  agency_name: string | null;
  field_name: string;
  operation_name: string;
  errors: string;
};
