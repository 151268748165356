import React from 'react';

import { GeometryType } from 'store/fields/types';
import { OperationFieldType } from 'store/operation/types';

import FieldInput from './FieldInput';

type FieldInputsPropsType = {
  deleteField: (field: OperationFieldType) => void;
  geometries: GeometryType;
  fields: OperationFieldType[];
  language: string;
};

const FieldInputs = ({ deleteField, fields, geometries, language }: FieldInputsPropsType) => {
  return (
    <>
      {fields.map((field) => (
        <FieldInput
          key={field.id}
          deleteField={deleteField}
          geometry={geometries[field.id]}
          field={field}
          language={language}
        />
      ))}
    </>
  );
};

export default FieldInputs;
