import { FiEdit2 } from 'react-icons/fi';
import { ActionIcon, Group, Stack, Text, Tooltip } from '@mantine/core';
import { featureCollection } from '@turf/helpers';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { UI_COLORS } from 'constants/colors';
import { ZONE_TYPES } from 'constants/mapbox';
import { BIO_DISPLAY_TEXT_SKU_IDS } from 'constants/pricing';
import { COMPLETED_PLAN, CUSTOM_POINTS, densityOptions } from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  getDensity,
  getZonesForDisplay,
  isCustomPointsOption,
  isGridsOption,
  isUploadOption,
} from 'util/samplePlan';
import { getNewAnalysisFromUpdateV3 } from 'util/samplePlanV3';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import { OrderForm, PreviewZonesType } from 'store/pricing/types';
import { ANALYSIS_TYPES } from 'store/samplePlans/types';

import GenerateZonesButton from '../ZoneInputs/GenerateZonesButton';
import LockUnlockZonesButton from '../ZoneInputs/LockUnlockZonesButton';
import ResetMapButton from '../ZoneInputs/ResetMapButton';
import UploadZoneFileButton from '../ZoneInputs/UploadZoneFileButton';
import ZoneDensitySelect from '../ZoneInputs/ZoneDensitySelect';
import ZoneTypeSelect from '../ZoneInputs/ZoneTypeSelect';

import styles from '../../Form.module.css';

export type SamplingZonesFormPropsType = {
  fieldFeatureCollection: FieldType;
  createZones: (prevZones: PreviewZonesType, setUserZones?: boolean) => void;
  resetZones: (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
    newDensity?: number,
  ) => void;
};

const SamplingZonesForm = ({
  resetZones,
  createZones,
  fieldFeatureCollection,
}: SamplingZonesFormPropsType) => {
  const language = useBroswerLanguage();

  const form = useOrderFormContext();
  const formValues = form.getValues();
  const transformValues = form.getTransformedValues();

  const isUpload = isUploadOption(formValues.creationOption);
  const isCustomPoints = isCustomPointsOption(formValues.creationOption);
  const isZonesFromCompleted = formValues.creationOption === COMPLETED_PLAN;
  const { isProOrTillRx } = form.getTransformedValues();
  const { acreage_unit, acreage } = fieldFeatureCollection.features[0]?.properties;

  const selectCreationOption = (creationOption: string) => {
    if (creationOption === COMPLETED_PLAN && formValues.completedPlan) {
      form.setValues({
        zones: getZonesForDisplay(formValues.completedSamples, formValues.completedPlan),
        previewZones: null,
        zonesLocked: true,
        disableMapTools: false,
      });
    } else {
      form.setValues({
        zonesLocked: false,
        disableMapTools: isGridsOption(creationOption),
      });
      resetZones(ANALYSIS_TYPES.CREATION_OPTION, creationOption);
    }
  };

  const selectNewDensity = (newDensity: string) => {
    form.setValues({
      zonesLocked: false,
      disableMapTools: isGridsOption(formValues.creationOption),
    });
    resetZones(ANALYSIS_TYPES.CREATION_OPTION, formValues.creationOption, Number(newDensity));
  };

  const handleZonesActionButtonClick = () => {
    if (isCustomPoints) {
      resetZones(ANALYSIS_TYPES.CREATION_OPTION, CUSTOM_POINTS);
    } else {
      formValues.zonesLocked
        ? resetZones(ANALYSIS_TYPES.CREATION_OPTION, formValues.creationOption)
        : createZones(formValues.previewZones, !isUpload);

      form.setValues({
        zonesLocked: !formValues.zonesLocked,
        disableMapTools: isGridsOption(formValues.creationOption)
          ? formValues.zonesLocked
          : formValues.disableMapTools,
      });
    }
  };

  const setupEditZones = () => {
    const newAnalysis: OrderForm = {
      ...formValues,
      zonesLocked: true,
      disableMapTools: false,
      editScanPoints: false,
      zones: featureCollection(
        formValues.zones?.features.filter(
          (feat) => feat.properties?.zone_type !== ZONE_TYPES.POINT,
        ) || [],
      ),
    };

    form.setValues(getNewAnalysisFromUpdateV3(newAnalysis, fieldFeatureCollection));
  };

  const zonesCount = formValues.zones?.features?.length || 0;
  const zonesDensity = getDensity(zonesCount, acreage) || 0;

  return (
    <Stack w={425} h="100%" style={{ overflowY: 'auto' }}>
      {BIO_DISPLAY_TEXT_SKU_IDS.includes(formValues.primaryProductId) && (
        <Text fw="bold">{getString('zoneAnalysisDefaultBioMsg', language)}</Text>
      )}
      <Group align="flex-end" gap="sm" wrap="nowrap" className={styles.HighlightedColor}>
        <ZoneTypeSelect
          language={language}
          isProOrTillRx={isProOrTillRx}
          onChange={selectCreationOption}
        />
        {!isCustomPoints && !isZonesFromCompleted && (
          <ZoneDensitySelect
            language={language}
            options={densityOptions[acreage_unit]}
            onChange={selectNewDensity}
          />
        )}
        <UploadZoneFileButton
          language={language}
          onSuccess={(zones) => form.setValues({ zones })}
        />
        <ResetMapButton language={language} onClick={handleZonesActionButtonClick} />
        <GenerateZonesButton language={language} onClick={handleZonesActionButtonClick} />
        <LockUnlockZonesButton language={language} onClick={handleZonesActionButtonClick} />
      </Group>
      <Group justify="space-between">
        <Group c={transformValues.is590DensityValid ? UI_COLORS.darkBlue : UI_COLORS.error}>
          <Text size="xs" fs="italic">
            {getString('zones', language)}: <b>{zonesCount}</b>
          </Text>
          <Text size="xs" fs="italic">
            {getString('density', language)}:{' '}
            <b>
              {acToHectares(zonesDensity, acreage_unit)} {getAcreageUnitFromLang(language)} /{' '}
              {getString('zone', language).toLowerCase()}
            </b>
          </Text>
        </Group>
        {isProOrTillRx &&
          formValues.zones?.features.some(
            (feat) => feat.properties?.zone_type === ZONE_TYPES.POINT,
          ) && (
            <Tooltip label={getString('editZones', language)} color="blue">
              <ActionIcon variant="filled" onClick={setupEditZones}>
                <FiEdit2 />
              </ActionIcon>
            </Tooltip>
          )}
      </Group>
    </Stack>
  );
};

export default SamplingZonesForm;
