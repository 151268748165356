import React from 'react';
import { Group, Stack, Text } from '@mantine/core';

type Props = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const Pitch = ({ title, description, icon }: Props) => {
  return (
    <Group gap="md">
      {icon}
      <Stack gap={0} align="flex-start">
        <Text fw="bold" size="lg">
          {title}
        </Text>
        <Text c="gray.7">{description}</Text>
      </Stack>
    </Group>
  );
};

export default Pitch;
