import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPostFile,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { ResultPreferencesType } from 'store/operation/types';

import { UserInfo } from '../user/types';

import { Agency } from './types';

export const requestAllAgencies = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/agencies/`));

export const requestMyAgenciesGet = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/my_agencies/`));

export const requestAgencyDetails = async (id: number) =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/agency/${id}`, {
      mock: 'user',
    }),
  );

export const registerUserInAgency = async (body: Partial<UserInfo>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/register_for_agency/`, {
      body,
    }),
  );

export const createAgency = async (body: Partial<Agency>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/agency/`, {
      body,
    }),
  );

export const updateAgencyDetails = async (agencyId: number, body: Partial<Agency>) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/agency/${agencyId}`, {
      body,
    }),
  );

export const requestAgenciesforOperation = async (operationId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/operation_all_agencies/${operationId}`));

export const requestAgencyUsers = async (operationId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/agency_all_users/${operationId}`));

export const requestContracts = async (agencyId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/pricing_contracts/${agencyId}`));

export const reassignUserToAgency = async (
  userId: number,
  roleName?: string,
  agencyId?: number,
  agencyName?: string,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/reassign_user_to_agency/`, {
      body: {
        user_ids: [userId],
        role_name: roleName,
        agency_id: agencyId,
        agency_name: agencyName,
      },
    }),
  );

export const postAgencyToContract = async (agencyId: number, contractId: number) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/price_contract_agency/${agencyId}/${contractId}`),
  );

export const deleteAgencyFromContract = async (agencyId: number, contractId: number) =>
  handleJsonResponse(
    await requestDelete(`${SERVICE_URL}/price_contract_agency/${agencyId}/${contractId}`),
  );

export const getAgencyContractMetrics = async (agencyId: number, contractId: number) =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/price_contract_agency/${agencyId}/${contractId}`),
  );

export const addUsersToAgency = async (agencyId: number, emails: string[]) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/agencies_users/${agencyId}`, {
      body: { users_emails: emails },
    }),
  );

export const removeUsersFromAgency = async (agencyId: number, emails: string[]) =>
  handleJsonResponse(
    await requestDelete(`${SERVICE_URL}/agencies_users/${agencyId}`, {
      body: { users_emails: emails },
    }),
  );

export const requestGetAgencyLogoSecureUrl = async (agencyId: number, fileName: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/upload_agency_logo/${agencyId}/${fileName}`));

export const requestPostAgencyLogo = async (agencyId: number, fileName: string) =>
  handleJsonResponse(
    await requestPostFile(`${SERVICE_URL}/upload_agency_logo/${agencyId}/${fileName}`),
  );

export const requestAgencyPutResultPreferences = async (
  agencyId: number,
  body: Partial<ResultPreferencesType>,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/agency_operation_preferences/${agencyId}`, {
      body,
    }),
  );

export const requestGetAgencyContractTiers = async (agencyId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/agency_contract_tiers/${agencyId}`));
