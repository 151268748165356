import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from '@mantine/core';

import { chemistryAnalysisList } from 'constants/analysis';
import { THREE_EM_DASH } from 'constants/defaultValues';
import { HOMOGENIZATION, M3_ANALYSIS, NITRATE_ANALYSIS, QPCR, SHOTGUN } from 'constants/plates';
import {
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
} from 'constants/products';

import {
  getAnalysisDisplayName,
  getFilteredReadsString,
  getRequiredAnalysisForProduct,
  whichAnalysisComplete,
} from 'util/analysis';
import { formatToNumericDate } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPlateNamePlusText, getPlatingStatusForAnalysis } from 'util/plates';
import { hasProducts, isProductComplete, isProductNotAnalyzed } from 'util/product';
import { endEllipsis } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { getLabSamples } from 'store/labSamples/thunks';
import { LabSampleType } from 'store/labSamples/types';
import { AdminAccess, Spinner } from 'common';

import FieldsTableRowCommon from '../FieldList/FullProductReport/common/FieldRow';

import { CancelSampleModal } from './CancelSampleModal';

import styles from './FieldLabSamplesChart.module.css';

type FieldLabSamplesChartPropsType = {
  field: FieldType;
  samplingPlan?: SamplingPlanType;
  hasFetched: boolean;
};

export const FieldLabSamplesChart = ({
  field,
  samplingPlan,
  hasFetched,
}: FieldLabSamplesChartPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { samples, IsFetching } = useSelector((state: RootState) => ({
    samples: state.labSamples.byFieldId[field.features[0].properties.id],
    IsFetching: state.labSamples.isFetching,
  }));

  useEffect(() => {
    if (!samples && field) {
      dispatch(getLabSamples(field.features[0].properties.id));
    }
  }, [dispatch, samples, field]);

  const planSamples = useMemo(
    () =>
      samples?.filter((s) => s.sampling_plan_id === samplingPlan?.id).sort((a, b) => a.id - b.id) ||
      [],
    [samples, samplingPlan],
  );

  const getBarcodeIdInfo = (sample: LabSampleType) => (
    <>
      <div>{`ID: ${sample.id}`}</div>
      <div>
        {'Bar: '}
        <Link className={styles.Link} to={`/lab/samples/${sample.barcode}`}>
          {sample.barcode ? endEllipsis(sample.barcode, 6) : ''}
        </Link>
      </div>
      <div>
        {'UUID: '}
        {sample.barcode ? (
          <Link
            data-test-id="link-to-sample"
            className={styles.Link}
            to={`/lab/samples/${sample.barcode}`}
          >
            {sample.sample_uuid ? endEllipsis(sample.sample_uuid, 5) : ''}
          </Link>
        ) : (
          <>{sample.sample_uuid ? endEllipsis(sample.sample_uuid, 5) : ''}</>
        )}
      </div>
    </>
  );

  const getSamplingInfo = (sample: LabSampleType) => {
    if (sample.sampled_at) {
      const formattedDate = formatToNumericDate(sample.sampled_at);
      const sampler = sample.sampled_by_name ? ` (${sample.sampled_by_name})` : '';
      return `${formattedDate}${sampler}`;
    }
    return '--';
  };

  const getDate = (sampleDate?: string | null) => {
    if (sampleDate) {
      return formatToNumericDate(sampleDate);
    }
    return '--';
  };

  const getProgressStageForProduct = (sample: LabSampleType, product: string) => {
    const analysis = getRequiredAnalysisForProduct(product);
    const plating = getPlatingStatusForAnalysis(sample, analysis, language);
    if (plating) {
      return plating;
    }
    if (product === NUTRIENT_PANEL && sample.sample_weight) {
      return getString('weighedAndGround', language);
    }
    if (sample.arrived_at) {
      return getString('arrived', language);
    }

    return getString('notStarted', language);
  };

  const partialNutrients = (sample: LabSampleType) => {
    const { complete, incomplete } = whichAnalysisComplete(sample, ...chemistryAnalysisList);
    if (complete.length) {
      const cDisplay = complete.map((a) => getAnalysisDisplayName(a, language)).join(', ');
      const iDisplay = incomplete.map((a) => getAnalysisDisplayName(a, language)).join(', ');

      const only = `${cDisplay} ${getString('only', language)}`;
      const no = incomplete.length ? `, ${getString('no', language)} ${iDisplay}` : '';

      return `${only}${no}`;
    }
    return null;
  };

  const getProductStatus = (sample: LabSampleType, product: string) => {
    // TODO: Move this logic to the backend
    if (!hasProducts(sample, product)) {
      return getString('notOrdered', language);
    }
    if (isProductNotAnalyzed(sample, product)) {
      return getString('notAnalyzed', language);
    }
    if (isProductComplete(sample, product)) {
      return (
        <>
          <div>{getString('complete', language)}</div>
          {product === PERFORMANCE_PANEL && <div>{getFilteredReadsString(sample, language)}</div>}
        </>
      );
    }
    if (product === NUTRIENT_PANEL) {
      const partial = partialNutrients(sample);
      if (partial) {
        return partial;
      }
    }

    const eta = sample.products_eta?.[product] ? ` (${sample.products_eta[product]})` : '';
    return `${getProgressStageForProduct(sample, product)}${eta}`;
  };

  const getPlateInfo = (sample: LabSampleType, plateType: string) => {
    const samplePlates = sample.plates_summary?.[plateType];
    if (samplePlates?.length) {
      return samplePlates.map((plate) => {
        const plateCreatedDate = formatToNumericDate(plate.created_at);
        const plateBarcodeStr = endEllipsis(plate.barcode, 6);

        return (
          <div key={plateBarcodeStr}>
            <span>{`${plateCreatedDate}: `}</span>
            <Link className={styles.Link} to={`/lab/plates/details/${plate.barcode}`}>
              {plateBarcodeStr}
            </Link>
            {plate.batch_id && (
              <>
                {' / '}
                <Link className={styles.Link} to={`/lab/batches/details/${plate.batch_id}`}>
                  {plate.batch_id}
                </Link>
              </>
            )}
          </div>
        );
      });
    }
    return THREE_EM_DASH;
  };

  return (
    <>
      <div>
        {!samplingPlan ? (
          <Spinner />
        ) : (
          <FieldsTableRowCommon
            field={field}
            hasFetched={hasFetched}
            samplePlan={samplingPlan}
            showFieldThumbnail
          />
        )}
      </div>
      <div>
        {IsFetching ? (
          <Spinner />
        ) : (
          <Table data-test-id="sample-status-table">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{getString('sample', language)} ID</Table.Th>
                <Table.Th>{getString('status', language)}</Table.Th>
                <Table.Th>{getString('sampled', language)}</Table.Th>
                <Table.Th>{getString('arrived', language)}</Table.Th>
                <Table.Th>{getString('nutrientPanel', language)}</Table.Th>
                <Table.Th>{getString('nitratePanel', language)}</Table.Th>
                <Table.Th>{getString('pressurePanel', language)}</Table.Th>
                <Table.Th>{getString('perfPanel', language)}</Table.Th>
                <Table.Th>
                  {`${getPlateNamePlusText(M3_ANALYSIS, 'plate', language)} / ${getString(
                    'batch',
                    language,
                  )}`}
                </Table.Th>
                <Table.Th>
                  {`${getPlateNamePlusText(NITRATE_ANALYSIS, 'plate', language)} / ${getString(
                    'batch',
                    language,
                  )}`}
                </Table.Th>
                <Table.Th>{getPlateNamePlusText(HOMOGENIZATION, 'plate', language)}</Table.Th>
                <Table.Th>{getPlateNamePlusText(QPCR, 'plate', language)}</Table.Th>
                <Table.Th>
                  {`${getPlateNamePlusText(SHOTGUN, 'plate', language)} / ${getString(
                    'batch',
                    language,
                  )}`}
                </Table.Th>
                <AdminAccess>
                  <Table.Th />
                </AdminAccess>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {planSamples.map((sample, index) => (
                <Table.Tr
                  data-test-id={`sample-row-${index}`}
                  key={sample.id}
                  className={styles.Row}
                >
                  <Table.Td data-test-id="sample-id">{getBarcodeIdInfo(sample)}</Table.Td>
                  <Table.Td>{sample.status}</Table.Td>
                  <Table.Td>{getSamplingInfo(sample)}</Table.Td>
                  <Table.Td>{getDate(sample.arrived_at || sample.received_at)}</Table.Td>
                  <Table.Td>{getProductStatus(sample, NUTRIENT_PANEL)}</Table.Td>
                  <Table.Td>{getProductStatus(sample, NITRATE_PANEL)}</Table.Td>
                  <Table.Td>{getProductStatus(sample, PRESSURE_PANEL)}</Table.Td>
                  <Table.Td>{getProductStatus(sample, PERFORMANCE_PANEL)}</Table.Td>
                  <Table.Td>{getPlateInfo(sample, M3_ANALYSIS)}</Table.Td>
                  <Table.Td>{getPlateInfo(sample, NITRATE_ANALYSIS)}</Table.Td>
                  <Table.Td>{getPlateInfo(sample, HOMOGENIZATION)}</Table.Td>
                  <Table.Td>{getPlateInfo(sample, QPCR)}</Table.Td>
                  <Table.Td>{getPlateInfo(sample, SHOTGUN)}</Table.Td>
                  <AdminAccess>
                    <Table.Td>
                      {sample.status !== 'cancelled' && <CancelSampleModal sample={sample} />}
                    </Table.Td>
                  </AdminAccess>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}
      </div>
    </>
  );
};
