import { FiRotateCcw, FiRotateCw } from 'react-icons/fi';
import { Button } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { DELETE, DRAW_MODES, MERGE, MODES, POINT, SPLIT } from 'constants/mapbox';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { DrawMode } from 'common/Maps/types';

import styles from './DrawingToolsV3.module.css';

interface DrawingToolsProps {
  drawModeSetter: (arg0: DrawMode) => void;
  mode: DrawMode;
  handleSelect: (mergeOrDelete: string, start: boolean) => void;
  drawAction: string | null;
  handleMerge?: () => void;
  handleSplit?: () => void;
  handleDelete: () => void;
  rotateGrid: (arg0: boolean) => void;
  isScanPoints?: boolean;
}

const DrawingTools = ({
  drawModeSetter,
  mode,
  handleSelect,
  drawAction,
  handleMerge,
  handleSplit,
  handleDelete,
  rotateGrid,
  isScanPoints,
}: DrawingToolsProps) => {
  const language = useBroswerLanguage();
  const isMerging = drawAction === MERGE;
  const isSplitting = drawAction === SPLIT;
  const isDeleting = drawAction === DELETE;

  const form = useOrderFormContext();
  const formValues = form.getValues();
  const previewZones = isScanPoints ? formValues.previewScanPoints : formValues.previewZones;
  const hasPreviews = !!previewZones?.features.length;
  const canViewPolygonTools = formValues.zoneGeomType !== POINT && !isScanPoints;

  return (
    <div className={styles.Controls}>
      {hasPreviews && (
        <>
          <Button variant="white" onClick={() => rotateGrid(true)} rightSection={<FiRotateCw />}>
            {getString('rotate', language)}
          </Button>
          <Button variant="white" onClick={() => rotateGrid(false)} rightSection={<FiRotateCcw />}>
            {getString('rotate', language)}
          </Button>
        </>
      )}
      {!previewZones && (
        <>
          {canViewPolygonTools && (
            <>
              <Button
                disabled={Boolean(drawAction)}
                onClick={() => drawModeSetter(MODES.DRAW_POLYGON)}
                variant={MODES.DRAW_POLYGON === mode ? 'filled' : 'white'}
              >
                {getString('drawPolygon', language)}
              </Button>
              <Button
                disabled={Boolean(drawAction)}
                onClick={() => drawModeSetter(MODES.DRAW_RECTANGLE)}
                variant={MODES.DRAW_RECTANGLE === mode ? 'filled' : 'white'}
              >
                {getString('drawRectangle', language)}
              </Button>
              {isMerging ? (
                <div>
                  <Button
                    className={styles.Merge}
                    onClick={handleMerge}
                    variant={isMerging ? 'filled' : 'white'}
                  >
                    {getString('merge', language)}
                  </Button>
                  <Button color="darkRed" onClick={() => handleSelect(MERGE, false)}>
                    {getString('cancel', language)}
                  </Button>
                </div>
              ) : (
                <Button
                  disabled={DRAW_MODES.includes(mode)}
                  onClick={() => handleSelect(MERGE, true)}
                  variant="white"
                >
                  {getString('mergeZones', language)}
                </Button>
              )}
              {isSplitting ? (
                <div>
                  <Button
                    className={styles.Merge}
                    onClick={handleSplit}
                    variant={isSplitting ? 'filled' : 'white'}
                  >
                    {getString('split', language)}
                  </Button>
                  <Button color="darkRed" onClick={() => handleSelect(SPLIT, false)}>
                    {getString('cancel', language)}
                  </Button>
                </div>
              ) : (
                <Button
                  disabled={DRAW_MODES.includes(mode)}
                  onClick={() => handleSelect(SPLIT, true)}
                  variant="white"
                >
                  {getString('splitZones', language)}
                </Button>
              )}
            </>
          )}
          {isDeleting ? (
            <div>
              <Button
                className={styles.Merge}
                onClick={handleDelete}
                variant={isDeleting ? 'filled' : 'white'}
              >
                {getString('delete', language)}
              </Button>
              <Button color="darkRed" onClick={() => handleSelect(DELETE, false)}>
                {getString('cancel', language)}
              </Button>
            </div>
          ) : (
            <Button
              disabled={DRAW_MODES.includes(mode) && mode !== MODES.DRAW_POINT}
              onClick={() => handleSelect(DELETE, true)}
              variant="white"
            >
              {`${getString('delete', language)} ${
                formValues.zoneGeomType === POINT
                  ? getString('points', language)
                  : getString('zones', language)
              }`}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
export default DrawingTools;
