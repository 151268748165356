import React from 'react';
import classNames from 'classnames';

import { productDisplayNames, SAMPLES_PAID } from 'constants/products';

import { numsToInvoiceDollars } from 'util/stringUtils';
import { SamplePlanTransactionsType } from 'store/payments/types';
import { TableCol, TableRow } from 'common';

import styles from './PaymentTables.module.css';

type ExpandedPropsType = {
  samplePlan: SamplePlanTransactionsType;
};

const ActivityDetailRowExpanded = ({ samplePlan }: ExpandedPropsType) => {
  const { price_summary } = samplePlan;
  return (
    <>
      {Object.keys(samplePlan.price_summary).map((product) => (
        <TableRow key={product} className={classNames(styles.NoBorder, styles.RowPadding)}>
          <TableCol />
          <TableCol />
          <TableCol />
          <TableCol>{productDisplayNames[product]}</TableCol>
          <TableCol>{price_summary[product].num_zones}</TableCol>
          <TableCol>
            {product !== SAMPLES_PAID &&
              numsToInvoiceDollars(price_summary[product].collection * -1)}
          </TableCol>
          <TableCol>
            {product !== SAMPLES_PAID && numsToInvoiceDollars(price_summary[product].commission)}
          </TableCol>
          <TableCol>
            {product !== SAMPLES_PAID && numsToInvoiceDollars(price_summary[product].total * -1)}
          </TableCol>
          <TableCol />
        </TableRow>
      ))}
    </>
  );
};

export default ActivityDetailRowExpanded;
