import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { OperationType } from 'store/operation/types';
import { AdminAgentAccess, Header, OperationSearchBar, YearSelect } from 'common';

import { SamplingHistoryTable } from './SamplingHistoryTable';

export const SamplingHistoryContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const { year } = useParams<{ year: string }>();
  const [operationId, setOperationId] = useState<number | null>(null);

  const numericYear = Number(year);

  useEffect(() => {
    if (!year) {
      const currentYear = new Date().getFullYear();
      navigate(`/sampling-history/${currentYear}`);
    }
  }, [year]);

  const setYear = (nYear: number) => {
    navigate(`/sampling-history/${nYear}`);
  };

  return (
    <Stack>
      <Header title={getString('samplingHistory', language)}>
        <AdminAgentAccess>
          <OperationSearchBar
            leftSection={<FiSearch />}
            placeholder={getString('enterAccountName', language)}
            onSelect={(operation: OperationType) => setOperationId(operation.id)}
            onDeselect={() => setOperationId(null)}
          />
        </AdminAgentAccess>
        <YearSelect year={numericYear} setYear={setYear} />
      </Header>
      <SamplingHistoryTable year={numericYear} operationId={operationId} />
    </Stack>
  );
};
