import React, { useEffect, useMemo, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { CloseButton, Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';

import { AGENCY } from 'constants/products';
import { OPERATION } from 'constants/propTypes/operation';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { DEBOUNCE } from 'util/request';
import { getOperationAgencyDisplayName } from 'util/stringUtils';
import { getAcreageUnitFromLang } from 'util/units';
import { RootState } from 'store';
import { getOperations } from 'store/operation/thunks';
import { OperationAgencyListType } from 'store/samplePlans/types';

type OperationSearchBarPropsType = {
  onSelect: (val: OperationAgencyListType | undefined) => void;
  placeholder?: string;
  className?: string;
  onDeselect?: () => void;
  showAgency?: boolean;
  operationOrAgency?: OperationAgencyListType;
};

export const OperationAgencySearchBar = ({
  onSelect,
  placeholder,
  className,
  onDeselect,
  showAgency,
  operationOrAgency,
}: OperationSearchBarPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [selected, setSelected] = useState<string>('');
  const [loaded, setLoaded] = useState(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { operations, agencies, isFetching } = useSelector((state: RootState) => ({
    operations: state.operations.summary.items,
    agencies: state.operations.summary.agencies,
    isFetching: state.operations.isFetching,
  }));

  const operationsAgenciesOptions = useMemo(() => {
    return [...operations, ...agencies]
      .map((op, index) => ({
        id: index,
        displayName: getOperationAgencyDisplayName(op, language),
        name: op.name,
        opAgId: op.id,
        type: 'billing_user' in op ? OPERATION : AGENCY,
      }))
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.displayName === value.displayName),
      );
  }, [operations, agencies]);

  const debounceFetchOperations = useDebouncedCallback(
    (name: string) => {
      dispatch(
        getOperations(
          getAcreageUnitFromLang(language),
          true,
          1,
          name,
          undefined,
          undefined,
          undefined,
          undefined,
          showAgency,
        ),
      );
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    if (operationOrAgency) {
      debounceFetchOperations(operationOrAgency?.name);
    }
  }, [debounceFetchOperations, operationOrAgency]);

  useEffect(() => {
    if (!selected && operationsAgenciesOptions.length && !loaded) {
      setLoaded(true);
      if (operationOrAgency) {
        setSelected(operationOrAgency?.displayName);
      }
    }
  }, [operationsAgenciesOptions, selected, loaded]);

  const handleOperationSelection = (str: string) => {
    const opOrAg = operationsAgenciesOptions.find((o) => o.displayName === str);
    if (opOrAg) {
      onSelect(opOrAg);
      setSelected(opOrAg?.displayName);
    }
  };

  const handleTextChange = (event: any) => {
    setSelected(event.currentTarget.value);
    debounceFetchOperations(event.currentTarget.value);
    combobox.resetSelectedOption();
    combobox.openDropdown();
  };

  const clearSelected = () => {
    setSelected('');
    combobox.closeDropdown();
    onDeselect && onDeselect();
  };

  const options = (operationsAgenciesOptions || []).map((item) => (
    <Combobox.Option value={item.displayName} key={item.id}>
      {item.displayName}
    </Combobox.Option>
  ));

  const RightSelect = () => {
    if (isFetching) {
      return <Loader size={20} />;
    }
    if (operationOrAgency) {
      return (
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={clearSelected}
        />
      );
    }
    return null;
  };

  return (
    <Combobox onOptionSubmit={handleOperationSelection} withinPortal={false} store={combobox}>
      <Combobox.Target>
        <TextInput
          className={className}
          placeholder={placeholder}
          value={selected}
          leftSection={<FiSearch />}
          onChange={handleTextChange}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          rightSection={<RightSelect />}
        />
      </Combobox.Target>

      <Combobox.Dropdown
        hidden={!operationsAgenciesOptions.length}
        onClick={() => combobox.closeDropdown()}
      >
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
