import { getString } from 'strings/translation';
import { AgronomicProductType } from 'store/cropPlans/types';

import { K, P, PH } from './chemistry';
import {
  BPH_SIKORA_ID,
  FOOT_60,
  FOOT_80,
  METER_10,
  NORTHERN_CORN_ROOTWORM_ID,
  PH_ID,
  PHOSPHORUS_ID,
  POTASSIUM_ID,
  SHOTGUN_NORTHERN_CORN_ROOTWORM_ID,
  SHOTGUN_WESTERN_CORN_ROOTWORM_ID,
  WESTERN_CORN_ROOTWORM_ID,
} from './results';
import { ALL, CORN, CORN_SILAGE, OATS, SORGHUM, SOYBEANS, WHEAT } from './variables';

export const jobStatuses = {
  PENDING: 0,
  FAILED: -1,
  SUCCESS: 1,
};

export const PHOSPHORUS_ISU = 'phosphorus_isu';
export const POTASSIUM_ISU = 'potassium_isu';
export const LIME_ISU_60 = 'lime_isu_60';
export const LIME_ISU_65 = 'lime_isu_65';
export const LIME_ISU_69 = 'lime_isu_69';
export const PHOSPHORUS_TRI_STATE = 'phosphorus_tri_state';
export const POTASSIUM_TRI_STATE = 'potassium_tri_state';
export const LIME_TRI_STATE_60 = 'lime_tri_state_60';
export const LIME_TRI_STATE_65 = 'lime_tri_state_65';
export const LIME_TRI_STATE_68 = 'lime_tri_state_68';
export const LIME_TRI_STATE_OH_60 = 'lime_tri_state_oh_60';
export const LIME_TRI_STATE_OH_65 = 'lime_tri_state_oh_65';
export const LIME_TRI_STATE_OH_68 = 'lime_tri_state_oh_68';
export const PHOSPHORUS_SDSU_MN_OLSEN = 'phosphorus_sdsu_mn_olsen';
export const PHOSPHORUS_SDSU_MN_BRAY = 'phosphorus_sdsu_mn_bray';
export const POTASSIUM_SDSU_MN = 'potassium_sdsu_mn';
export const LIME_SDSU = 'lime_sdsu';
export const SATURATION_BRAZIL = 'saturation_brazil';
export const PHOSPHORUS_BRAZIL = 'phosphorus_brazil';
export const POTASSIUM_BRAZIL = 'potassium_brazil';
export const REMOVAL_P_ISU = 'removal_p_isu';
export const REMOVAL_K_ISU = 'removal_k_isu';
export const REMOVAL_P_TRI_STATE = 'removal_p_tri_state';
export const REMOVAL_K_TRI_STATE = 'removal_k_tri_state';
export const REMOVAL_P_SDSU = 'removal_p_sdsu';
export const REMOVAL_K_SDSU = 'removal_k_sdsu';
export const CUSTOM_FORMULA = 'custom';

export const DEFAULT = 'default';
export const JOHN_DEERE_RX = 'john_deere_rx';
export const CNH_RX = 'cnh_rx';
export const AG_LEADER_RX = 'ag_leader_rx';
export const RAVEN_VIPERPPRO_RX = 'raven_viper_pro_rx';
export const RAVEN_VIPER4_RX = 'raven_viper_4_rx';
export const RAVEN_ENVIZIOPRO_RX = 'raven_envizio_pro_rx';

export const INPUT_10_34_0_20_PERCENT_ID = 5;

const DEFAULT_P_INPUT_ID = 1;
const DEFAULT_PH_INPUT_ID = 2;
const DEFAULT_K_INPUT_ID = 3;
export const CUSTOM_CCE_ISU = 1003;
export const CUSTOM_RNV_ENM_ISU = 1004;
export const CUSTOM_CCE_SDSU = 1005;
export const CUSTOM_RNV_SDSU = 1006;
export const CUSTOM_RNV_INDIANA = 1007;
export const CUSTOM_NV_MICHIGAN = 1008;
export const CUSTOM_ENP_OHIO = 1009;
export const CUSTOM_RNP_BRAZIL = 1010;
export const SUPER_FOSFATO_SIMPLES = 1011;

export const inputNames = {
  [CUSTOM_CCE_ISU]: 'calciumCarbonateEquivalent',
  [CUSTOM_RNV_ENM_ISU]: 'relativeNeutralization',
  [CUSTOM_CCE_SDSU]: 'calciumCarbonateEquivalent',
  [CUSTOM_RNV_SDSU]: 'relativeNeutralization',
  [CUSTOM_RNV_INDIANA]: 'relativeNeutralization',
  [CUSTOM_NV_MICHIGAN]: 'neutralization',
  [CUSTOM_ENP_OHIO]: 'neutralization',
  [CUSTOM_RNP_BRAZIL]: 'relativeNeutralization',
};

export const PREPLANT = 'preplant';

export const DEFAULT_RX_VALUES_US = {
  [PHOSPHORUS_ID]: {
    crop: 'corn',
    type: 'soil_test',
    input_id: DEFAULT_P_INPUT_ID,
    formula_name: PHOSPHORUS_ISU,
    minimum_rate: 0,
    expected_yield: 180,
  },
  [POTASSIUM_ID]: {
    crop: 'corn',
    type: 'soil_test',
    input_id: DEFAULT_K_INPUT_ID,
    formula_name: POTASSIUM_ISU,
    minimum_rate: 0,
    expected_yield: 180,
  },
  [BPH_SIKORA_ID]: {
    crop: 'corn',
    type: 'soil_test',
    input_id: DEFAULT_PH_INPUT_ID,
    formula_name: LIME_ISU_69,
    minimum_rate: 0,
    expected_yield: 180,
  },
};

export const DEFAULT_RX_VALUES_BR = {
  [PHOSPHORUS_ID]: {
    crop: 'corn',
    type: 'soil_test',
    input_id: DEFAULT_P_INPUT_ID,
    formula_name: PHOSPHORUS_BRAZIL,
    minimum_rate: 0,
    expected_yield: 180,
  },
  [POTASSIUM_ID]: {
    crop: 'corn',
    type: 'soil_test',
    input_id: DEFAULT_K_INPUT_ID,
    formula_name: POTASSIUM_BRAZIL,
    minimum_rate: 0,
    expected_yield: 180,
    maximum_rate: 50,
  },
  [BPH_SIKORA_ID]: {
    crop: 'corn',
    type: 'soil_test',
    input_id: CUSTOM_RNP_BRAZIL,
    formula_name: SATURATION_BRAZIL,
    minimum_rate: 0,
    expected_yield: 180,
  },
};

export const formulaDisplayNames = {
  [PHOSPHORUS_ISU as string]: 'isuPhosphorus',
  [LIME_ISU_60 as string]: 'isuLimeTo6',
  [LIME_ISU_65 as string]: 'isuLimeTo65',
  [LIME_ISU_69 as string]: 'isuLimeTo69',
  [POTASSIUM_ISU as string]: 'isuPotassium',
  [PHOSPHORUS_TRI_STATE as string]: 'triStatePhosphorus',
  [POTASSIUM_TRI_STATE as string]: 'triStatePotassium',
  [LIME_TRI_STATE_60 as string]: 'triStateLimeTo6',
  [LIME_TRI_STATE_65 as string]: 'triStateLimeTo65',
  [LIME_TRI_STATE_68 as string]: 'triStateLimeTo68',
  [LIME_TRI_STATE_OH_60 as string]: 'triStateOhLimeTo6',
  [LIME_TRI_STATE_OH_65 as string]: 'triStateOhLimeTo65',
  [LIME_TRI_STATE_OH_68 as string]: 'triStateOhLimeTo68',
  [PHOSPHORUS_SDSU_MN_BRAY as string]: 'sdsuPhosphorusBray',
  [PHOSPHORUS_SDSU_MN_OLSEN as string]: 'sdsuPhosphorusOlsen',
  [POTASSIUM_SDSU_MN as string]: 'sdsuPotassium',
  [LIME_SDSU as string]: 'sdsuLime',
  [SATURATION_BRAZIL as string]: 'brazilSaturationMethod',
  [PHOSPHORUS_BRAZIL as string]: 'brazilPhosphorus',
  [POTASSIUM_BRAZIL as string]: 'brazilPotassium',
  [REMOVAL_P_ISU as string]: 'removalPIsu',
  [REMOVAL_K_ISU as string]: 'removalKIsu',
  [REMOVAL_P_TRI_STATE as string]: 'removalPTriState',
  [REMOVAL_K_TRI_STATE as string]: 'removalKTriState',
  [REMOVAL_P_SDSU as string]: 'removalPSdsu',
  [REMOVAL_K_SDSU as string]: 'removalKSdsu',
  [CUSTOM_FORMULA as string]: 'custom',
};

export const PERCENT = 'percent';
export const LBS_PER_TON = 'lbs_per_ton';

export const rxUnitToSymbol = {
  [PERCENT as string]: '%',
  [LBS_PER_TON as string]: 'lbs/ton',
};

export const BRAZIL_ONLY_INPUTS = [SUPER_FOSFATO_SIMPLES];

export const AMOUNT_KEY = 'amount';
export const FILL_PERCENT_KEY = 'fillPercent';
export const PRO_PRESCRIPTIONS_NO_OUTLINE_DENSITIES = [METER_10, FOOT_60, FOOT_80];
export const RAW_VALUE_KEY = 'raw_value';

export const REMOVAL_REC_FORMULAS = [
  REMOVAL_P_ISU,
  REMOVAL_K_ISU,
  REMOVAL_P_TRI_STATE,
  REMOVAL_K_TRI_STATE,
  REMOVAL_P_SDSU,
  REMOVAL_K_SDSU,
];

export const APPLICATION_RATE_MODE = 'application_rate';
export const YIELD_ZONE_MODE = 'yield_zone';
export const YIELD_MAP_MODE = 'yield_map';
export const IMAGERY_ZONE_MODE = 'imagery_zone';
export const IMAGERY_MAP_MODE = 'imagery_map';

export const REMOVAL_RATES = {
  [REMOVAL_K_ISU]: {
    [CORN]: 0.22,
    [SOYBEANS]: 1.2,
  },
  [REMOVAL_P_ISU]: {
    [CORN]: 0.32,
    [SOYBEANS]: 0.72,
  },
  [REMOVAL_K_TRI_STATE]: {
    [CORN]: 0.2,
    [SOYBEANS]: 1.15,
  },
  [REMOVAL_P_TRI_STATE]: {
    [CORN]: 0.35,
    [SOYBEANS]: 0.8,
  },
  [REMOVAL_P_SDSU]: {
    [CORN]: 0.38,
    [SOYBEANS]: 0.84,
  },
  [REMOVAL_K_SDSU]: {
    [CORN]: 0.27,
    [SOYBEANS]: 1.3,
  },
};

export const SOIL_TEST = 'soil_test';
export const YIELD = 'yield';
export const IMAGERY = 'imagery';

export const RX_TYPE_REMOVAL = 'rx_type_removal';
export const RX_TYPE_BUILDUP = 'rx_type_buildup';
export const RX_TYPE_SEED = 'rx_type_seed';
export const RX_TYPE_CUSTOM = 'rx_type_custom';
export const RX_ZONE_TYPE_FORMULA = 'rx_zone_type_formula';
export const RX_ZONE_TYPE_CUSTOM = 'rx_zone_type_custom';

export const PRESCRIPTION_FORMULA_DETAILS = {
  [CORN]: {
    [PHOSPHORUS_ISU]: '(108.5 - 3.05 * ST) / (1 - 0.0192 * ST)',
    [POTASSIUM_ISU]: '(138.5 - 0.825 * ST) / (1 - 0.00532 * ST)',
    [LIME_ISU_60]: '(38619 - (5915 * BpH))',
    [LIME_ISU_65]: '(49886 - (7245 * BpH))',
    [LIME_ISU_69]: '(58776 - (8244 * BpH))',
    [PHOSPHORUS_TRI_STATE]: '(Y * 0.35) + ((20 - ST) * 5)',
    [POTASSIUM_TRI_STATE]: '((Y * 0.2) + 20) + ((100 - ST) * (1 + (0.05 * CEC)))',
    [LIME_TRI_STATE_60]: '(54.2 - (0.78 * (BpH * 10))) * 2000',
    [LIME_TRI_STATE_65]: '(65.5 - (0.94 * (BpH * 10))) * 2000',
    [LIME_TRI_STATE_68]: '(71.2 - (1.02 * (BpH * 10))) * 2000',
    [LIME_TRI_STATE_OH_60]: '((-4.5721 * BpH) + 31.7602) * 2000',
    [LIME_TRI_STATE_OH_65]: '((-5.6399 * BpH) + 39.1496) * 2000',
    [LIME_TRI_STATE_OH_68]: '((-6.7553 * BpH) + 46.8098) * 2000',
    [PHOSPHORUS_SDSU_MN_OLSEN]: '(0.7 - (0.044 * ST)) * Y',
    [PHOSPHORUS_SDSU_MN_BRAY]: '(0.7 - (0.044 * ST)) * Y',
    [POTASSIUM_SDSU_MN]: '(1.166 - (0.0073 * ST)) * Y',
    [LIME_SDSU]: '≤ 5.6: 3500\n5.6 - 5.8: 3000\n5.8 - 6.0: 2500\n6.0 - 6.4: 2000\n6.5+: 0',
    [PHOSPHORUS_BRAZIL]: '((TV - ST) * 2 * 2.29)',
    [POTASSIUM_BRAZIL]: '((TV - ST) * 94.231)',
    [REMOVAL_P_ISU]: 'Y * 0.32',
    [REMOVAL_K_ISU]: 'Y * 0.22',
    [REMOVAL_P_TRI_STATE]: 'Y * 0.35',
    [REMOVAL_K_TRI_STATE]: 'Y * 0.2',
    [REMOVAL_P_SDSU]: 'Y * 0.38',
    [REMOVAL_K_SDSU]: 'Y * 0.27',
  },
  [SOYBEANS]: {
    [PHOSPHORUS_ISU]: '(85.8 - 2.77 * ST) / (1 - 0.0253 * ST)',
    [POTASSIUM_ISU]: '(127.9 - 0.699 * ST) / (1 - 0.00476 * ST)',
    [LIME_ISU_60]: '(38619 - (5915 * BpH))',
    [LIME_ISU_65]: '(49886 - (7245 * BpH))',
    [LIME_ISU_69]: '(58776 - (8244 * BpH))',
    [PHOSPHORUS_TRI_STATE]: '(Y * 0.8) + ((20 - ST) * 5)',
    [POTASSIUM_TRI_STATE]: '((Y * 1.15) + 20) + ((100 - ST) * (1 + (0.05 * CEC)))',
    [LIME_TRI_STATE_60]: '(54.2 - (0.78 * (BpH * 10))) * 2000',
    [LIME_TRI_STATE_65]: '(65.5 - (0.94 * (BpH * 10))) * 2000',
    [LIME_TRI_STATE_68]: '(71.2 - (1.02 * (BpH * 10))) * 2000',
    [LIME_TRI_STATE_OH_60]: '((-4.5721 * BpH) + 31.7602) * 2000',
    [LIME_TRI_STATE_OH_65]: '((-5.6399 * BpH) + 39.1496) * 2000',
    [LIME_TRI_STATE_OH_68]: '((-6.7553 * BpH) + 46.8098) * 2000',
    [PHOSPHORUS_SDSU_MN_OLSEN]: '(1.55 - (0.14 * ST)) * Y',
    [PHOSPHORUS_SDSU_MN_BRAY]: '(1.55 - (0.14 * ST)) * Y',
    [POTASSIUM_SDSU_MN]: '(2.2 - (0.0183 * ST)) * Y',
    [LIME_SDSU]: '≤ 5.6: 3500\n5.6 - 5.8: 3000\n5.8 - 6.0: 2500\n6.0 - 6.4: 2000\n6.5+: 0',
    [PHOSPHORUS_BRAZIL]: '((TV - ST) * 2 * 2.29)',
    [POTASSIUM_BRAZIL]: '((TV - ST) * 94.231)',
    [REMOVAL_P_ISU]: 'Y * 0.72',
    [REMOVAL_K_ISU]: 'Y * 1.2',
    [REMOVAL_P_TRI_STATE]: 'Y * 0.8',
    [REMOVAL_K_TRI_STATE]: 'Y * 1.15',
    [REMOVAL_P_SDSU]: 'Y * 0.84',
    [REMOVAL_K_SDSU]: 'Y * 1.3',
  },
  [CORN_SILAGE]: {
    [REMOVAL_P_ISU]: 'Y * 0.44',
    [REMOVAL_K_ISU]: 'Y * 1.10',
  },
  [OATS]: {
    [REMOVAL_P_ISU]: 'Y * 0.29',
    [REMOVAL_K_ISU]: 'Y * 0.19',
  },
  [SORGHUM]: {
    [REMOVAL_P_ISU]: 'Y * 11',
    [REMOVAL_K_ISU]: 'Y * 33',
  },
  [WHEAT]: {
    [REMOVAL_P_ISU]: 'Y * 0.55',
    [REMOVAL_K_ISU]: 'Y * 0.27',
  },
};

export const SOIL_TEST_FORMULAS = [
  PHOSPHORUS_ISU,
  POTASSIUM_ISU,
  LIME_ISU_60,
  LIME_ISU_65,
  LIME_ISU_69,
  PHOSPHORUS_TRI_STATE,
  POTASSIUM_TRI_STATE,
  LIME_TRI_STATE_60,
  LIME_TRI_STATE_65,
  LIME_TRI_STATE_68,
  LIME_TRI_STATE_OH_60,
  LIME_TRI_STATE_OH_65,
  LIME_TRI_STATE_OH_68,
  PHOSPHORUS_SDSU_MN_OLSEN,
  PHOSPHORUS_SDSU_MN_BRAY,
  POTASSIUM_SDSU_MN,
  PHOSPHORUS_BRAZIL,
  POTASSIUM_BRAZIL,
];
export const YIELD_FORMULAS = [
  PHOSPHORUS_SDSU_MN_BRAY,
  PHOSPHORUS_SDSU_MN_OLSEN,
  POTASSIUM_SDSU_MN,
  REMOVAL_K_ISU,
  REMOVAL_P_ISU,
  REMOVAL_K_TRI_STATE,
  REMOVAL_P_TRI_STATE,
  REMOVAL_P_SDSU,
  REMOVAL_K_SDSU,
];
export const TARGET_VALUE_FORMULAS = [PHOSPHORUS_BRAZIL, POTASSIUM_BRAZIL];

export const CUSTOM_ZONE_MAX = 'max';

export const CONVENTIONAL_RX_TYPES = [RX_TYPE_BUILDUP, RX_TYPE_REMOVAL];
export const CUSTOM_RX_TYPES = [RX_TYPE_SEED, RX_TYPE_CUSTOM];

export const RX_NUTRIENT_ANALYTIC_MAP = {
  [P]: PHOSPHORUS_ID,
  [K]: POTASSIUM_ID,
  [PH]: PH_ID,
};

export const LIME_FORMULAS = [
  LIME_ISU_60,
  LIME_ISU_65,
  LIME_ISU_69,
  LIME_SDSU,
  LIME_TRI_STATE_60,
  LIME_TRI_STATE_65,
  LIME_TRI_STATE_68,
  LIME_TRI_STATE_OH_60,
  LIME_TRI_STATE_OH_65,
  LIME_TRI_STATE_OH_68,
];

export const ISU_REMOVAL_FORMULAS = [REMOVAL_P_ISU, REMOVAL_K_ISU];
export const SEED_TYPE = 'seed';
export const CUSTOM_TYPE = 'custom';
export const REMOVAL_TYPE = 'removal';

// since the rootworm analytic is combined from WCRW and NCRW, omit child analytics
export const COMBINED_ANALYTICS_TO_HIDE = [
  WESTERN_CORN_ROOTWORM_ID,
  NORTHERN_CORN_ROOTWORM_ID,
  SHOTGUN_WESTERN_CORN_ROOTWORM_ID,
  SHOTGUN_NORTHERN_CORN_ROOTWORM_ID,
];

export const DEFAULT_CORN_TARGET_YIELD = 220;
export const DEFAULT_SOYBEAN_TARGET_YIELD = 65;

export const MAX_PRESCRIPTION_NAME_LENGTH = 128;

export const CUSTOM_PRODUCT_ID = 22175;

export const getCustomAgronomicProduct = (language: string): AgronomicProductType => ({
  crop: ALL,
  id: CUSTOM_PRODUCT_ID,
  name: getString('customProduct', language),
  pattern_product_type: null,
  ratings: [],
  registrant: '',
});
