import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  DISCARDED,
  HOMOGENIZATION,
  mergeablePlateTypes,
  nextPlateTypeMap,
  PROCESSING,
  processingPlateStatuses,
  QPCR,
  STORAGE,
} from 'constants/plates';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import {
  deletePlate,
  downloadPlateDetails,
  generateBarcodeSheet,
  generateQPCRSampleSheet,
  getPlate,
  updatePlate,
} from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { Button, Container, Dialog, DropdownButton } from 'common';

import { BulkUpload } from './ActionForms/BulkUpload';
import { StorageForm } from './ActionForms/StorageForm';

import styles from './ActionsDropdown.module.css';

type ActionsDropdownPropsType = {
  plate: PlateType;
};

const ActionsDropdown = ({ plate }: ActionsDropdownPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showStorageDialog, setShowStorageDialog] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const { status, plate_type } = plate;

  const onDiscardPlate = () => {
    dispatch(deletePlate(plate.barcode));
  };
  const onOpenPlate = () => {
    dispatch(
      updatePlate(plate.barcode, {
        status: PROCESSING,
      }),
    );
  };

  const onCopyPlate = () => {
    const nextType = nextPlateTypeMap[plate.plate_type] || plate.plate_type;
    navigate(`/lab/plates/create/${nextType}`, {
      state: {
        sourcePlateBarcode: plate.barcode,
      },
    });
  };

  const onDownloadDetails = () => {
    dispatch(downloadPlateDetails(plate.barcode));
  };

  const onDownloadQPCRSampleSheet = async () => {
    if (plate.sample_sheet_url) {
      window.open(plate.sample_sheet_url);
    } else {
      const response: any = await dispatch(generateQPCRSampleSheet(plate.id));
      window.open(response.sample_sheet_url);
      dispatch(getPlate(plate.barcode));
    }
  };

  const onDownloadBarcodeFile = async () => {
    try {
      if (plate.barcode_sheet_url) {
        window.open(plate.barcode_sheet_url);
      } else {
        const response: any = await dispatch(generateBarcodeSheet(plate.id));
        window.open(response.barcode_sheet_url);
        dispatch(getPlate(plate.barcode));
      }
    } catch (e) {
      showToast(e.message);
    }
  };

  return (
    <div>
      <DropdownButton
        dataTestId="actions-dropdown"
        className={styles.Actions}
        title={getString('actions', language)}
        render={({ close }: { close: () => void }) => {
          return [
            <Button
              key="copy-plate"
              dataTestId="copy-plate"
              square
              light
              onClick={() => {
                close();
                onCopyPlate();
              }}
            >
              {getString('copyPlate', language)}
            </Button>,
            mergeablePlateTypes.includes(plate_type) && (
              <Button square light onClick={() => navigate(`/lab/plates/merge/${plate.barcode}`)}>
                {getString('mergePlates', language)}
              </Button>
            ),
            processingPlateStatuses.includes(status) && (
              <Button
                square
                light
                onClick={() => {
                  close();
                  setShowUploadDialog(true);
                }}
              >
                {getString('uploadBulkPlate', language)}
              </Button>
            ),
            status === STORAGE && (
              <Button
                square
                light
                onClick={() => {
                  close();
                  onOpenPlate();
                }}
              >
                {getString('openPlate', language)}
              </Button>
            ),
            processingPlateStatuses.includes(status) && (
              <Button
                square
                light
                onClick={() => {
                  close();
                  setShowStorageDialog(true);
                }}
              >
                {getString('storePlate', language)}
              </Button>
            ),
            plate_type === HOMOGENIZATION && (
              <Button
                square
                light
                onClick={() => {
                  onDownloadDetails();
                  close();
                }}
              >
                {getString('downloadDetails', language)}
              </Button>
            ),
            status !== DISCARDED && (
              <Button
                dataTestId="discard-plate"
                square
                light
                danger
                onClick={() => {
                  close();
                  setConfirmDiscard(true);
                }}
              >
                {getString('discardPlate', language)}
              </Button>
            ),
            plate_type === QPCR && (
              <Button
                square
                light
                onClick={() => {
                  onDownloadQPCRSampleSheet();
                  close();
                }}
              >
                {getString('dlSampleSheet', language)}
              </Button>
            ),
            plate_type === QPCR && (
              <Button
                square
                light
                onClick={() => {
                  onDownloadBarcodeFile();
                  close();
                }}
              >
                {getString('dlBarcodeFile', language)}
              </Button>
            ),
          ];
        }}
      />
      {showStorageDialog && (
        <Dialog onClose={() => setShowStorageDialog(false)}>
          <StorageForm plate={plate} onClose={() => setShowStorageDialog(false)} />
        </Dialog>
      )}
      {showUploadDialog && (
        <BulkUpload
          open={showUploadDialog}
          plate={plate}
          onClose={() => setShowUploadDialog(false)}
        />
      )}
      {confirmDiscard && (
        <Dialog onClose={() => setConfirmDiscard(false)}>
          <Container vertical>
            <div>{getString('discardPlateWarningMsg', language)}</div>
            <Container>
              <Button
                dataTestId="confirm-discard"
                danger
                onClick={() => {
                  onDiscardPlate();
                  setConfirmDiscard(false);
                }}
              >
                {getString('confirm', language)}
              </Button>
              <Button onClick={() => setConfirmDiscard(false)}>
                {getString('cancel', language)}
              </Button>
            </Container>
          </Container>
        </Dialog>
      )}
    </div>
  );
};

export default ActionsDropdown;
