import { NumberInput, Table, Text } from '@mantine/core';

import { ContractPricingFormSkuPrice, TypeOfSku } from 'store/pricing/types';

import { useContractPricingFormContext } from './contractPricingContext';

const { Td, Tr } = Table;

interface Props {
  index: number;
  skuPrice: ContractPricingFormSkuPrice;
  typeOfSku: TypeOfSku;
}

const DefaultSkuPriceRow = ({ skuPrice, index, typeOfSku }: Props) => {
  const { sku_name, min_density, max_density, scan_max_density, scan_min_density } = skuPrice;
  const form = useContractPricingFormContext();
  const priceType = form.getValues().contractSkuPrices[typeOfSku][index].price_type;
  const priceKey = `contractSkuPrices.${typeOfSku}.${index}.price`;
  const listPriceKey = `contractSkuPrices.${typeOfSku}.${index}.list_price`;

  return (
    <Tr>
      <Td>
        <Text>{sku_name || 'SKUs me, need a name'}</Text>
      </Td>
      <Td>
        <Text>{priceType}</Text>
      </Td>
      <Td>
        <NumberInput leftSection="$" key={form.key(priceKey)} {...form.getInputProps(priceKey)} />
      </Td>
      <Td>
        <NumberInput
          leftSection="$"
          key={form.key(listPriceKey)}
          {...form.getInputProps(listPriceKey)}
        />
      </Td>
      <Td>
        <Text>{min_density || '---'}</Text>
      </Td>
      <Td>
        <Text>{max_density || '---'}</Text>
      </Td>
      <Td>
        <Text>{scan_max_density || '---'}</Text>
      </Td>
      <Td>
        <Text>{scan_min_density || '---'}</Text>
      </Td>
    </Tr>
  );
};

export default DefaultSkuPriceRow;
