import { Button } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import { MODES } from 'constants/mapbox';

import { getString } from 'strings/translation';
import { DrawMode } from 'common/Maps/types';

import styles from './DrawingTools.module.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,@typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// fixes a mapbox-gl issue, will not be needed soon

type DrawingToolsPropType = {
  drawModeSetter: (
    arg0: DrawMode,
  ) => (((event: any) => void) & ((event: string) => void)) | undefined;
  handleDelete: () => void;
  mode: string;
  language: string;
};

const DrawingTools = ({ drawModeSetter, handleDelete, mode, language }: DrawingToolsPropType) => {
  return (
    <div className={styles.Controls}>
      <Button
        onClick={drawModeSetter(MODES.DRAW_POLYGON)}
        variant={mode === MODES.DRAW_POLYGON ? 'filled' : 'white'}
      >
        {getString('drawPolygon', language)}
      </Button>
      <Button
        onClick={drawModeSetter(MODES.DRAW_RECTANGLE)}
        variant={mode === MODES.DRAW_RECTANGLE ? 'filled' : 'white'}
      >
        {getString('drawRectangle', language)}
      </Button>
      <Button color="darkRed" onClick={handleDelete}>
        {getString('delete', language)}
      </Button>
    </div>
  );
};

export default DrawingTools;
