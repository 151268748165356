export const WEB_APP_HOSTNAME = 'earthoptics.app';
export const COMPANY_SITE_HOSTNAME = 'earthoptics.com';
export const COMPANY_SITE_URL = `https://${COMPANY_SITE_HOSTNAME}`;

export const DEALER_TERMS_CONDITIONS_URL = `${COMPANY_SITE_URL}/dealer-terms-conditions`;
export const EULA_URL = `${COMPANY_SITE_URL}/eula`;
export const PRIVACY_POLICY_URL = `${COMPANY_SITE_URL}/privacy-policy`;
// TODO: 👇 update when ready
// export const DEALER_PORTAL_URL = `${COMPANY_SITE_URL}/dealer-portal-24/home?pass=Dealer24`;
export const DEALER_PORTAL_URL = 'https://www.pattern.ag/dealer-portal-24/home?pass=Dealer24';

export const PRODUCTION_HOSTS = [
  WEB_APP_HOSTNAME,
  `www.${WEB_APP_HOSTNAME}`,
  `blueprint.${WEB_APP_HOSTNAME}`,
  `www.blueprint.${WEB_APP_HOSTNAME}`,
  `lavoro.${WEB_APP_HOSTNAME}`,
  `www.lavoro.${WEB_APP_HOSTNAME}`,
  `redstar.${WEB_APP_HOSTNAME}`,
  `www.redstar.${WEB_APP_HOSTNAME}`,
];

export const SUPPORT_EMAIL = `support@${COMPANY_SITE_HOSTNAME}`;

export const QR_CODE_URL = 'https://api.qrserver.com/v1';
