import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Center, Loader, Stack, Title } from '@mantine/core';
import { FieldLabSamplesChart } from 'apps/Orders/SampleStatus/FieldLabSamplesChart';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { LabSampleType } from 'store/labSamples/types';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';

type SampleFieldContainerPropsType = {
  sample: LabSampleType;
};

export const SampleFieldContainer = ({ sample }: SampleFieldContainerPropsType) => {
  const language = useBroswerLanguage();
  const { fieldGeometry, fieldHasFetched, isAdmin } = useSelector((state: RootState) => ({
    fieldGeometry: sample.field_id ? state.fieldGeometry.geometries[sample.field_id] : null,
    fieldHasFetched: [
      ...state.fieldGeometry.hasFetchedList,
      ...state.fieldGeometry.hasFailedList,
    ].includes(sample.field_id),
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  const selectedPlan = useMemo(() => {
    const samplingPlans = fieldGeometry?.features[0].properties.sampling_plans;
    if (samplingPlans && sample.sampling_plan_id) {
      return samplingPlans.find((plan) => plan.id === Number(sample.sampling_plan_id));
    }
  }, [fieldGeometry, sample]);

  if (
    !sample.field_id ||
    !sample.sampling_plan_id ||
    (fieldGeometry && !selectedPlan) ||
    !isAdmin
  ) {
    return null;
  }

  return (
    <Stack>
      <Title order={2} fw={700}>
        {getString('fieldAndSamplePlanDetails', language)}
      </Title>
      {!fieldGeometry || !selectedPlan ? (
        <Center>
          <Stack align="center">
            <Loader size={30} type="oval" />
          </Stack>
        </Center>
      ) : (
        <FieldLabSamplesChart
          field={fieldGeometry}
          hasFetched={fieldHasFetched}
          samplingPlan={selectedPlan}
        />
      )}
    </Stack>
  );
};
