import {
  handleFileAttachment,
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { UserRxCustomZoneConfig } from 'store/user/types';

import { PrescriptionType } from './types';

export const putPrescriptionZone = async (zone_id: number, body: any) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/prescription_zone/${zone_id}`, { body }));

export const getZones = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/prescription_zones/${id}`));

export const getPrescription = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/prescription/${id}`));

export const postPrescription = async (body: PrescriptionType) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/prescription/`, { body }));

export const putPrescription = async (id: number, body: Partial<PrescriptionType>) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/prescription/${id}`, { body }));

export const deletePrescription = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/prescription/${id}`));

export const downloadPrescriptionShapefile = async (id: number, exportFormat?: string) =>
  handleFileAttachment(
    await requestPost(`${SERVICE_URL}/prescription_export/${id}`, {
      body: { oem_type: exportFormat },
    }),
  );

export const postPrescriptionExternalAccount = async (prescriptionId: number, body: {}) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/prescription_export_to_external_provider/${prescriptionId}`, {
      body,
    }),
  );

export const getExportedPrescriptionsByField = async (fieldId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/field_prescription_exports/${fieldId}`));

export const bulkDeletePrescriptions = async (prescriptionIds: number[]) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/prescription_bulk_delete/`, {
      body: { prescription_ids: prescriptionIds },
    }),
  );

export const bulkExportDownloadPrescriptions = async (
  prescription_ids: number[],
  oem_type: string,
) =>
  handleFileAttachment(
    await requestPost(`${SERVICE_URL}/prescription_bulk_export/`, {
      body: { prescription_ids, oem_type },
    }),
  );

export const bulkExportPrescriptions = async (
  prescription_ids: number[],
  oem_type: string,
  external_user_uuid: string | null = null,
) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/prescription_bulk_export/`, {
      body: { prescription_ids, oem_type, external_user_uuid },
    }),
  );

export const getProPrescriptionsByField = async (fieldId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/pro_prescriptions/${fieldId}`));

export const createSavedCustomZoneConfig = async (body: Partial<UserRxCustomZoneConfig>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/rx_custom_zone_config/`, {
      body,
    }),
  );
