import { AppThunk } from 'store';

import {
  labShipmentRequestError,
  receiveLabShipment,
  receiveLabShipments,
  requestLabShipment,
} from './actions';
import {
  requestGetLabShipment,
  requestGetLabShipments,
  requestPostLabShipment,
  requestPutLabShipment,
} from './requests';

export const getLabShipments =
  (page: number = 1): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabShipment());
    try {
      const shipments = await requestGetLabShipments(page);
      dispatch(receiveLabShipments(shipments));
    } catch (e) {
      await dispatch(labShipmentRequestError({ message: e.message }));
      throw e;
    }
  };

export const getLabShipment =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabShipment());
    try {
      const shipment = await requestGetLabShipment(id);
      dispatch(receiveLabShipment(shipment));
    } catch (e) {
      await dispatch(labShipmentRequestError({ message: e.message }));
      throw e;
    }
  };

export const createLabShipment =
  (body: {
    is_regulated?: boolean;
    nutrient_sample_count?: number | null;
    plate_barcodes?: string[];
    tracking_numbers?: string[];
  }): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabShipment());
    try {
      const shipment = await requestPostLabShipment(body);
      dispatch(receiveLabShipment(shipment));
      return shipment;
    } catch (e) {
      await dispatch(labShipmentRequestError({ message: e.message }));
      throw e;
    }
  };

export const updateLabShipment =
  (
    id: string,
    body: {
      is_regulated?: boolean;
      nutrient_sample_count?: number | null;
      plate_barcodes?: string[];
      new_tracking_numbers?: string[];
    },
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabShipment());
    try {
      const shipment = await requestPutLabShipment(id, body);
      dispatch(receiveLabShipment(shipment));
      return shipment;
    } catch (e) {
      await dispatch(labShipmentRequestError({ message: e.message }));
      throw e;
    }
  };
