import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import center from '@turf/center';
import {
  Feature,
  FeatureCollection,
  featureCollection,
  Point,
  Polygon,
  Properties,
} from '@turf/helpers';
import { v4 as uuid } from 'uuid';

import { SELF_SAMPLING, SELF_SCANNING } from 'constants/agency';
import { AC } from 'constants/languages';
import { POINT, POLYGON, ZONE_TYPES } from 'constants/mapbox';
import {
  ANALYSIS,
  CUSTOM_SKU_ID,
  DUMMY_USER_ID,
  EXTERNAL_LAB_SKU_ID,
  EXTERNAL_LAB_SKU_NAME,
  NUTRIENT_ANALYSIS_PACKAGE_SKU_IDS,
  NUTRIENT_TRUNUTRIENT_MAPPING,
  STANDARD_NUTRIENT_SKU_ID,
  THREE_SIXTY_COMPLETE_SKU_ID,
} from 'constants/pricing';
import {
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
  PRODUCT_COMBINATIONS,
  productList,
} from 'constants/products';
import {
  DEFAULT_BIO_DENSITY,
  DEFAULT_SCAN_DENSITY,
  densityOptions,
  GRID_POINTS,
  GRID_ZONES,
  PT_DEFAULT_BIO_DENSITY,
  ZONE_BY_ZONE,
} from 'constants/samplePlanning';

import { FieldType, SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import {
  CreationOption,
  DefaultOrderFormMapType,
  OrderForm,
  OrderFormTransformedType,
  SkuType,
} from 'store/pricing/types';
import { postDensityGroupings } from 'store/samplePlans/requests';
import { PlanSkuType, SAMPLE_STATUSES } from 'store/samplePlans/types';
import { getActiveSamples, getSamples } from 'store/samples/requests';
import { TimingsResponse, TimingsResponseItemType } from 'store/samplingTimings/types';
import { User } from 'store/user/types';

import { defaultPlanDate } from './date';
import { SampleGeoJSON } from './generalTypes';
import { convertToPolygon, isPointZone } from './geospatial';
import {
  checkSkuHasProductFromList,
  findClosestDensity,
  getSkuById,
  getSubProductsByPrimarySku,
} from './pricing';
import { getDefaultTimingOptionIndex, isNutrientPanel } from './product';
import {
  canAddAllUuidsToAnalysis,
  getPerProduct,
  getSamplerOptions,
  getScannerOptions,
  getSplitDensityOptions,
  getZonesBasedOnAnalysis,
  getZonesForDisplay,
  getZoneType,
  isPointBased,
  prepareResetZones,
  resetPreviewGrid,
} from './samplePlan';

const getSamplerScannerIdFromPlan = (
  sampleOrScan: typeof SELF_SAMPLING | typeof SELF_SCANNING,
  operation?: OperationType,
  operationUsers?: User[],
  assignedId?: number | null,
): number => {
  if (!operationUsers?.length || !operation) {
    return DUMMY_USER_ID;
  }

  const userOptions =
    sampleOrScan === SELF_SAMPLING
      ? getSamplerOptions({ operationUsers })
      : getScannerOptions({ operationUsers });

  const assignedUser = userOptions.find((user) => user.id === assignedId);
  const { billing_agency_id, billing_user_id } = operation;

  if (assignedId || !billing_agency_id) {
    return assignedUser?.id || DUMMY_USER_ID;
  }

  const agencyUserDefault = userOptions.find(
    (user) => user.id === billing_user_id && user.agencies?.[0]?.[sampleOrScan],
  );

  return agencyUserDefault?.id || DUMMY_USER_ID;
};

export const getDefaultMapFormInfo = (
  fieldGeometry?: FieldType,
  targetBioDensity?: number,
  targetScanDensity?: number,
): DefaultOrderFormMapType => {
  const acreage_unit = fieldGeometry?.features[0].properties.acreage_unit;
  const possibleDensities = densityOptions[acreage_unit || AC].map((option) => option.label);
  const defaultBioDensity = acreage_unit === AC ? DEFAULT_BIO_DENSITY : PT_DEFAULT_BIO_DENSITY;
  const previewZones = fieldGeometry
    ? resetPreviewGrid(GRID_ZONES, targetBioDensity || defaultBioDensity, fieldGeometry)
    : null;
  const previewScanPoints = fieldGeometry
    ? resetPreviewGrid(GRID_POINTS, targetScanDensity || DEFAULT_SCAN_DENSITY, fieldGeometry)
    : null;
  return {
    zoneGeomType: POLYGON,
    creationOption: GRID_ZONES,
    zones: null,
    zonesLocked: false,
    density: targetBioDensity
      ? findClosestDensity(possibleDensities, targetBioDensity)
      : defaultBioDensity,
    scanDensity: targetScanDensity
      ? findClosestDensity(possibleDensities, targetScanDensity)
      : DEFAULT_SCAN_DENSITY,
    scanPoints: null,
    previewZones: previewZones,
    previewScanPoints: previewScanPoints,
    disableMapTools: false,
    editScanPoints: true,
    gridAngle: 0,
    proPointCreationOption: GRID_POINTS,
    proPointsZonesFile: null,
    disableScanMapTools: false,
    zonesFile: null,
  };
};

export const getDefaultAnalysisV3 = (
  operation?: OperationType,
  operationUsers?: User[],
  timingsConfig?: TimingsResponse,
  field?: FieldType,
): OrderForm => {
  const billingAgencyId = operation?.default_bill_to_agency_id || operation?.billing_agency_id;
  const defaultTimingIndex = getDefaultTimingOptionIndex(timingsConfig?.current_timing);

  return {
    agencyId: billingAgencyId,
    analysisMode: ZONE_BY_ZONE,
    existingPlan: null,
    existingSamples: [],
    completedPlan: null,
    completedSamples: [],
    completedScanPoints: [],
    is590Analysis: false,
    isProScan: true,
    isSplitDensity: false,
    isTillRx: true,
    nutrient: true,
    nutrientAnalysisPackageId: STANDARD_NUTRIENT_SKU_ID,
    externalLabKey: '',
    priceSummary: {},
    primaryProductId: THREE_SIXTY_COMPLETE_SKU_ID,
    products: productList,
    readyToSample: false,
    rnd: false,
    samplePlanName: defaultPlanDate(),
    sampledById: getSamplerScannerIdFromPlan(SELF_SAMPLING, operation, operationUsers, null),
    samplerInstructions: '',
    sampleTiming: timingsConfig?.sampling_timings[defaultTimingIndex].value || null,
    scannedById: getSamplerScannerIdFromPlan(SELF_SCANNING, operation, operationUsers, null),
    splitIndex: 0,
    tempPlan: null,
    truBio: true,
    truNutrient: true,
    truTill: true,
    zonesCount: 0,
    scanPointsCount: 0,
    scannerIndex: 0,
    name: '',
    samplerIndex: 0,
    sampleTimingIndex: 0,
    isSplitDensityNitrate: false,
    notes: '',
    nutrientExternalLabIdentifier: '',
    ...getDefaultMapFormInfo(field),
  };
};

export const getBioZonesScanPointsForDisplay = (
  polygonGeometries: SampleGeoJSON[],
  plan: SamplingPlanType,
): {
  zones: FeatureCollection<Polygon | Point, Properties> | null;
  scanPoints: FeatureCollection<Point, Properties> | null;
} => {
  const isProOrTillRx = plan.is_pro || plan.is_till_rx;

  const featuresByType = getZonesForDisplay(polygonGeometries, plan).features.reduce(
    (acc, feature) => {
      const isScanPoint = isProOrTillRx && feature.properties?.zone_type === ZONE_TYPES.POINT;
      (isScanPoint ? acc.scanPoints : acc.zones).push(feature);
      return acc;
    },
    { zones: [] as Feature[], scanPoints: [] as Feature[] },
  );
  const { zones, scanPoints } = featuresByType;
  return {
    zones: zones.length
      ? (featureCollection(zones) as FeatureCollection<Polygon | Point, Properties>)
      : null,
    scanPoints: scanPoints.length
      ? (featureCollection(scanPoints) as FeatureCollection<Point, Properties>)
      : null,
  };
};

export const getAnalysisPackageOptions = (
  hasExternalLabs: boolean,
  skus: SkuType[],
): { label: string; value: string }[] => {
  const packageIdsToUse = hasExternalLabs
    ? [...NUTRIENT_ANALYSIS_PACKAGE_SKU_IDS, EXTERNAL_LAB_SKU_ID]
    : NUTRIENT_ANALYSIS_PACKAGE_SKU_IDS;

  return packageIdsToUse.map((skuId) => {
    const sku = getSkuById(skus, skuId);
    const isExternalLab = skuId === EXTERNAL_LAB_SKU_ID;

    return {
      label: sku?.name || (isExternalLab ? EXTERNAL_LAB_SKU_NAME : 'Name unavailable'),
      value: skuId.toString(),
    };
  });
};

const getNutrientAnalysisPackageId = (analysisSkuIds: number[] | undefined, allSkus: SkuType[]) =>
  getAnalysisPackageOptions(true, allSkus)
    .map((labPackage) => Number(labPackage.value))
    .find(
      (skuId) =>
        analysisSkuIds?.includes(skuId) ||
        analysisSkuIds?.includes(NUTRIENT_TRUNUTRIENT_MAPPING[skuId]),
    ) || null;

const getProductsFromPlanSkus = (
  samplingPlanSkus: PlanSkuType[] | undefined,
  allSkus: SkuType[],
  isSplitDensity = false,
) => {
  // is_custom_analysis will be on at least one of the sku prices, if the primary SKU was 'Custom'
  const isCustomPrimary = samplingPlanSkus?.some(
    (skuPrice) => skuPrice.summary_json.is_custom_analysis,
  );
  const analysisSkuIds =
    samplingPlanSkus
      ?.filter((skuPrice) => skuPrice.sku.type === ANALYSIS)
      .map((skuPrice) => skuPrice.sku.id) || [];

  if (isSplitDensity) {
    return {
      primaryProductId: CUSTOM_SKU_ID,
      nutrientAnalysisPackageId: getNutrientAnalysisPackageId(analysisSkuIds, allSkus),
      truBio: true,
      nutrient: true,
      truNutrient: false,
      truTill: false,
    };
  }

  if (isCustomPrimary) {
    // Iterate over Plan SKUs to find the sub-products that are on the plan
    const subProductsOnPlan = allSkus?.flatMap((sku) =>
      analysisSkuIds?.includes(sku.id)
        ? Object.keys(getSubProductsByPrimarySku(sku)).filter(
            (key) => getSubProductsByPrimarySku(sku)[key],
          )
        : [],
    );

    return {
      primaryProductId: CUSTOM_SKU_ID,
      nutrientAnalysisPackageId: getNutrientAnalysisPackageId(analysisSkuIds, allSkus),
      truBio: subProductsOnPlan.includes('truBio'),
      nutrient: subProductsOnPlan.includes('nutrient'),
      truNutrient: subProductsOnPlan.includes('truNutrient'),
      truTill: subProductsOnPlan.includes('truTill'),
    };
  }

  const sku = analysisSkuIds
    ? getSkuById(allSkus, isCustomPrimary ? CUSTOM_SKU_ID : analysisSkuIds[0])
    : null;

  if (!sku) return {};

  const subProducts = getSubProductsByPrimarySku(sku);
  const labPackageSkuId = getNutrientAnalysisPackageId(analysisSkuIds, allSkus);
  return {
    primaryProductId: sku.id,
    nutrientAnalysisPackageId: labPackageSkuId || STANDARD_NUTRIENT_SKU_ID,
    ...subProducts,
  };
};

export const getSamplePolygonGeometries = (
  samples: SampleGeoJSON[],
  fieldGeometry: FieldType,
  samplingPlan: SamplingPlanType,
) =>
  samples
    .filter((sample: SampleGeoJSON) =>
      sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
    )
    .map((sample: SampleGeoJSON) => ({
      ...sample,
      geometry: sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
      properties: {
        ...sample.properties,
        products: sample.properties.products.filter((product) =>
          canAddAllUuidsToAnalysis(fieldGeometry, product),
        ),
        zone_type: getZoneType(sample, samplingPlan),
      },
    })) as SampleGeoJSON[];

const getPlanDetailsFromExistingV3 = async (
  plan: SamplingPlanType,
  sampleFeatures: SampleGeoJSON[],
  fieldGeometry: FieldType,
  formValues: OrderForm,
  allSkus: SkuType[],
): Promise<OrderForm> => {
  const {
    sampling_plan_skus,
    plan_type,
    samples,
    products,
    is_pro,
    is_till_rx,
    nutrient_external_lab_identifier,
    is_split_density,
  } = plan;
  const uncancelledSamples = sampleFeatures.filter(
    ({ properties }) => properties.status !== SAMPLE_STATUSES.CANCELLED,
  );

  const polygonGeometries = getSamplePolygonGeometries(uncancelledSamples, fieldGeometry, plan);
  formValues.existingSamples = polygonGeometries;
  formValues.zoneGeomType = isPointBased(plan) ? POINT : POLYGON;

  const pointsAndZones = getBioZonesScanPointsForDisplay(polygonGeometries, plan);
  let { zones } = pointsAndZones;
  const { scanPoints } = pointsAndZones;

  let splitDensityIdx = formValues.splitIndex;
  let { disableMapTools } = formValues;

  if (is_split_density && zones) {
    const numSplitGroups = samples.filter((sample) => sample.biological_subsample).length;
    const actualSamplesTaken = samples.filter((sample) => !sample.biological_subsample).length;
    const splitDensityOptions = getSplitDensityOptions(actualSamplesTaken);

    splitDensityIdx = splitDensityOptions.findIndex((option) => option.value === numSplitGroups);

    zones = await postDensityGroupings(splitDensityOptions[splitDensityIdx].value, zones);
    disableMapTools = true;
  }

  const updatedPlan: OrderForm = {
    ...formValues,
    analysisMode: plan_type,
    disableMapTools: disableMapTools || is_pro || is_till_rx,
    products,
    zones: zones,
    scanPoints: scanPoints,
    isProScan: is_pro,
    isTillRx: is_till_rx,
    splitIndex: splitDensityIdx || 0,
    externalLabKey: nutrient_external_lab_identifier || '',
    isSplitDensity: is_split_density,
    ...getProductsFromPlanSkus(sampling_plan_skus, allSkus, is_split_density),
  };

  return updatedPlan;
};

export const setupExistingAnalysisV3 = async (
  operation: OperationType,
  operationUsers: User[],
  fieldGeometry: FieldType,
  existingPlan: SamplingPlanType,
  timingsConfig: TimingsResponse,
  allSkus: SkuType[],
): Promise<OrderForm> => {
  const defaultAnalysis = getDefaultAnalysisV3(
    operation,
    operationUsers,
    timingsConfig,
    fieldGeometry,
  );

  const sampleResponse = await getActiveSamples(fieldGeometry.features[0].properties.id);

  const { samples } =
    sampleResponse.sampling_plans.find((sp) => sp.id === existingPlan.id) ||
    sampleResponse.sampling_plans[0];

  const scannedById = existingPlan.assigned_to_scan_id
    ? getScannerOptions(operation).find((user) => user.id === existingPlan.assigned_to_scan_id)
        ?.id || DUMMY_USER_ID
    : DUMMY_USER_ID;

  const analysisFromExisting = await getPlanDetailsFromExistingV3(
    existingPlan,
    samples.features,
    fieldGeometry,
    defaultAnalysis,
    allSkus,
  );

  const updatedPlan: OrderForm = {
    ...defaultAnalysis,
    ...getPerProduct(fieldGeometry, samples.features, existingPlan),
    ...analysisFromExisting,
    existingPlan,
    samplePlanName: existingPlan.name,
    rnd: existingPlan.rnd,
    samplerInstructions: existingPlan.notes || '',
    zonesLocked: true,
    agencyId: existingPlan.agency_id,
    sampleTiming: existingPlan.sample_timing || timingsConfig.sampling_timings[0].value,
    sampledById:
      operationUsers.find((user) => user.id === existingPlan.assigned_to_id)?.id || DUMMY_USER_ID,
    scannedById,
    readyToSample: existingPlan.ready_to_sample,
    creationOption: (existingPlan.zone_type || GRID_ZONES) as CreationOption,
    zoneGeomType: isPointBased(existingPlan) ? POINT : POLYGON,
    is590Analysis: (samples.features || []).some((sample) => sample.properties?.is_590_analysis),
    previewZones: null,
    previewScanPoints: null,
  };

  return updatedPlan;
};

export const setupCompletedAnalysisV3 = async (
  operation: OperationType,
  operationUsers: User[],
  fieldGeometry: FieldType,
  completedPlan: SamplingPlanType,
  timingsConfig: TimingsResponse,
  allSkus: SkuType[],
) => {
  let newAnalyis = getDefaultAnalysisV3(operation, operationUsers, timingsConfig, fieldGeometry);

  newAnalyis = {
    ...newAnalyis,
    creationOption: (completedPlan.zone_type || GRID_ZONES) as CreationOption,
    rnd: completedPlan.rnd,
  };
  const samples = await getSamples(fieldGeometry.features[0].properties.id);

  newAnalyis = await getPlanDetailsFromExistingV3(
    completedPlan,
    samples.features.filter(({ properties }) => properties?.sampling_plan_id === completedPlan.id),
    fieldGeometry,
    newAnalyis,
    allSkus,
  );

  const updatedPlan: OrderForm = {
    ...newAnalyis,
    existingPlan: completedPlan,
    zonesLocked: true,
    sampledById:
      getSamplerScannerIdFromPlan(
        SELF_SAMPLING,
        operation,
        operationUsers,
        completedPlan.assigned_to_id,
      ) ?? DUMMY_USER_ID,
    scannedById: getSamplerScannerIdFromPlan(
      SELF_SCANNING,
      operation,
      operationUsers,
      completedPlan.assigned_to_scan_id,
    ),
  };

  return updatedPlan;
};

export const getSampleTimingDisplayName = (
  timings: TimingsResponseItemType[],
  valueToCheck: string | null,
) => timings.find(({ value }) => value === valueToCheck)?.display_name || '';

export const getNewAnalysisFromUpdateV3 = (
  formValues: OrderForm,
  fieldGeometry: FieldType,
): OrderForm => {
  const { products, isProScan, isTillRx } = formValues;
  const isProOrTillRx = isProScan || isTillRx;

  const existingZones = formValues.existingSamples.reduce<{
    points: SampleGeoJSON[];
    polygons: SampleGeoJSON[];
  }>(
    (zonesPoints, sample) => {
      if (
        sample.properties.zone_type === ZONE_TYPES.POINT ||
        (!sample.properties.zone_type && isPointZone(sample))
      ) {
        return { ...zonesPoints, points: [...zonesPoints.points, sample] };
      }
      return { ...zonesPoints, polygons: [...zonesPoints.polygons, sample] };
    },
    { points: [], polygons: [] },
  );

  const isNutrientOnly = isNutrientPanel(products);
  const noBioZones = !products.includes(PRESSURE_PANEL) && !products.includes(PERFORMANCE_PANEL);
  const noPanels = !isNutrientOnly && noBioZones;
  const isNutrientOnlyOrTillRxNoBio = isNutrientOnly || (isTillRx && noPanels);

  if (isProOrTillRx) {
    if (isNutrientOnlyOrTillRxNoBio && existingZones.points.length) {
      return {
        ...formValues,
        creationOption: GRID_POINTS,
        zoneGeomType: POINT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // TODO
        scanPoints: featureCollection(existingZones.points),
        zonesLocked: true,
      };
    }

    if (
      !isNutrientOnlyOrTillRxNoBio &&
      existingZones.polygons.length &&
      existingZones.points.length
    ) {
      return {
        ...formValues,
        creationOption: GRID_ZONES,
        zoneGeomType: POLYGON,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // TODO
        zones: featureCollection(existingZones.polygons),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // TODO
        scanPoints: featureCollection(existingZones.points),
        zonesLocked: true,
      };
    }

    if ((!isTillRx && isNutrientOnly) || (isTillRx && noPanels)) {
      return {
        ...formValues,
        creationOption: GRID_POINTS,
        zoneGeomType: POINT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // TODO
        scanPoints: existingZones.points.length ? featureCollection(existingZones.points) : null,
        zonesLocked: true,
      };
    }

    return {
      ...formValues,
      creationOption: noBioZones ? GRID_POINTS : GRID_ZONES,
      zoneGeomType: noBioZones ? POINT : POLYGON,
      zones: null,
      zonesLocked: false,
      editScanPoints: true,
      previewZones: resetPreviewGrid(
        noBioZones ? GRID_POINTS : GRID_ZONES,
        formValues.density,
        fieldGeometry,
      ),
    };
  }

  if (formValues.existingPlan) {
    const newCreation = formValues.existingPlan.zone_type || GRID_ZONES;
    return {
      ...formValues,
      creationOption: newCreation as CreationOption,
      zones: getZonesBasedOnAnalysis(
        formValues.isProScan || formValues.isTillRx,
        formValues.existingPlan,
        formValues.existingSamples,
      ),
      zoneGeomType: newCreation.includes(ZONE_TYPES.POINT) ? POINT : POLYGON,
      previewZones: null,
      zonesLocked: true,
    };
  }

  const resetZones = prepareResetZones(
    formValues.previewZones,
    formValues.density,
    GRID_ZONES,
    fieldGeometry,
  );

  const newValues: OrderForm = {
    ...formValues,
    creationOption: GRID_ZONES,
    zones: null,
    zonesLocked: false,
    previewZones: resetZones.previewZones,
    zoneGeomType: resetZones.zoneGeomType as OrderForm['zoneGeomType'],
  };

  return newValues;
};

const getSkuBasedProductsForSamplePlanOrder = (
  formValues: OrderForm,
  transformValues: OrderFormTransformedType,
  allSkus: SkuType[],
) => {
  const { truBio, nutrient, nutrientAnalysisPackageId } = formValues;
  const { isProOrTillRx } = transformValues;
  if (isProOrTillRx) {
    return truBio ? PRODUCT_COMBINATIONS.tru_bio : [];
  }
  let newProducts: string[] = [];
  if (truBio) {
    newProducts = PRODUCT_COMBINATIONS.tru_bio;
  }
  if (nutrient) {
    newProducts.push(NUTRIENT_PANEL);
    const nutrientAnalysisSku = getSkuById(
      allSkus,
      nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID,
    );
    if (checkSkuHasProductFromList(nutrientAnalysisSku, [NITRATE_PANEL])) {
      newProducts.push(NITRATE_PANEL);
    }
  }

  return newProducts;
};

const getSkuBasedPointBasedProductsForSamplePlanOrder = (
  formValues: OrderForm,
  allSkus: SkuType[],
) => {
  const { isProScan, nutrient, nutrientAnalysisPackageId } = formValues;
  const nutrientAnalysisSku = getSkuById(
    allSkus,
    nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID,
  );
  const hasNitrate = checkSkuHasProductFromList(nutrientAnalysisSku, [NITRATE_PANEL]);
  const panelsOnPoints = hasNitrate ? [NUTRIENT_PANEL, NITRATE_PANEL] : [NUTRIENT_PANEL];
  if (isProScan) {
    return panelsOnPoints;
  }
  // TruTill will either have nutrient(and possibly nitrate) or nothing
  return nutrient ? panelsOnPoints : [];
};

export const setupSamplesToSubmitV3 = (
  formValues: OrderForm,
  transformValues: OrderFormTransformedType,
  field: FieldType,
  allSkus: SkuType[],
) => {
  const { zoneGeomType } = formValues;
  const { isProOrTillRx } = transformValues;

  const productizedGridPointFeatures = (formValues.scanPoints?.features || [])
    .filter((feature) => booleanPointInPolygon(center(feature.geometry), field.features[0]))
    .map((feature) => {
      return {
        ...feature,
        geometry: convertToPolygon(feature, POINT),
        properties: {
          ...feature.properties,
          products: getSkuBasedPointBasedProductsForSamplePlanOrder(formValues, allSkus),
          sample_uuid: uuid(),
        },
      };
    });

  const productizedZonesFeatures = (formValues.zones?.features || []).map((feature) => {
    return {
      ...feature,
      geometry: convertToPolygon(feature, isProOrTillRx ? feature.geometry.type : zoneGeomType),
      properties: {
        ...feature.properties,
        products: getSkuBasedProductsForSamplePlanOrder(formValues, transformValues, allSkus),
        sample_uuid: uuid(),
      },
    };
  });

  return [...productizedGridPointFeatures, ...productizedZonesFeatures];
};

export const getSampleGroupings = (features: Feature[], isNitrate: boolean) => {
  const splitGroupDict = features.reduce(
    (dict, feat) => {
      const group = feat.properties?.sample_group;
      if (group && feat.properties?.sample_uuid) {
        if (!dict[group]) {
          dict[group] = [feat.properties.sample_uuid];
        } else {
          dict[group].push(feat.properties.sample_uuid);
        }
      }
      return dict;
    },
    {} as { [name: string]: string[] },
  );

  const groupingsList = Object.keys(splitGroupDict).map((group) => splitGroupDict[group]);

  return {
    products: isNitrate ? PRODUCT_COMBINATIONS.all : PRODUCT_COMBINATIONS.core,
    groupings: groupingsList,
  };
};
