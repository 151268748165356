import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Center, SegmentedControl, Space, Text } from '@mantine/core';

import { ACTIVE_OPERATION_ID } from 'constants/auth';
import { JOHN_DEERE } from 'constants/connectAccounts';

import { sortByCreatedAt } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import { getUploadStatus } from 'util/jobUtils';
import { getLocalStorage } from 'util/localStorage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getEventsForField } from 'store/fieldEvents/thunks';
import { editJob, getJobs } from 'store/jobs/thunks';
import { JOB_STATUSES, JOB_TYPES, JobType } from 'store/jobs/types';
import { getConnectedAccountsInfo, getOperation, setActiveOperation } from 'store/operation/thunks';
import { Header, Spinner } from 'common';

import ConnectedAccountsContainer from '../ConnectedAccounts/Container';

import UploadContainer from './UploadContainer';

import styles from './Container.module.css';

type paramsType = {
  operationId?: string;
  accountType?: string;
};

const IngestHistoryContainer = () => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const { operationId, accountType } = useParams<paramsType>();
  const dispatch = useDispatch();
  const activeOperationId = getLocalStorage(ACTIVE_OPERATION_ID);
  const numericId = Number(operationId || activeOperationId);
  const uploadShapeFileUrl = `/operations/${numericId}/ingest_history`;
  const { pathname } = window.location;

  const { operation, jobs } = useSelector((state: RootState) => ({
    jobs: state.jobs.jobsByOperation[numericId] || [],
    operation: state.operations.operationsById[numericId],
  }));

  const VIEW_OPTIONS = [
    {
      id: 1,
      displayName: getString('connectOutsideAccounts', language),
      label: getString('connectOutsideAccounts', language),
      value: '/connected-accounts',
    },
    {
      id: 2,
      displayName: getString('uploadShapefiles', language),
      label: getString('uploadShapefiles', language),
      value: uploadShapeFileUrl,
    },
  ];

  useEffect(() => {
    dispatch(setActiveOperation(numericId));
  }, [dispatch, numericId]);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(numericId)),
    [dispatch, numericId],
  );

  useOperationFieldGeometries(operation, true, true);

  useEffect(() => {
    if (!operation || !operation.fields) {
      fetchOperation();
    }
    if (!operation?.hasOwnProperty('connectedAccounts')) {
      dispatch(getConnectedAccountsInfo(numericId));
    }
  }, [fetchOperation, operation, dispatch, numericId]);

  const [pastJobs, setPastJobs] = useState<JobType[]>([]);

  const cancelJob = useCallback(
    (jobId: number) => {
      const jobToCancel = pastJobs.find((job) => job.id === jobId);
      if (jobToCancel) {
        dispatch(
          editJob(jobId, {
            ...jobToCancel,
            status: JOB_STATUSES.CANCELLED,
          }),
        );
      }
    },
    [dispatch, pastJobs],
  );

  const fetchJobs = useCallback(() => dispatch(getJobs(numericId)), [dispatch, numericId]);

  const getFieldEvents = () => {
    operation?.fields?.forEach((field) => dispatch(getEventsForField(field.id)));
  };

  useEffect(() => {
    fetchJobs();
    getFieldEvents();
    const jobInterval = setInterval(fetchJobs, 10000);
    const fieldInterval = setInterval(() => getFieldEvents, 10000);
    return () => {
      clearInterval(jobInterval);
      clearInterval(fieldInterval);
    };
  }, [fetchJobs]);

  useEffect(() => {
    const jobsWithRunId = jobs.filter((job) => job.run_id !== null);
    if (jobsWithRunId.length) {
      const jobsByRunId = jobsWithRunId.reduce(
        (total: { [id: number]: JobType[] }, job: JobType) => {
          const { run_id } = job;
          const existingJobs = total[run_id] || [];
          return {
            ...total,
            [run_id]: existingJobs.concat(job),
          };
        },
        {},
      );
      const formattedJobs = Object.values(jobsByRunId).map((runJobs) => {
        const uploadJob = runJobs.find((job) => job.type === JOB_TYPES.UPLOADED_FILE);
        const status = getUploadStatus(runJobs);
        return {
          ...uploadJob,
          status,
        };
      }) as JobType[];
      setPastJobs(sortByCreatedAt(formattedJobs));
    }
  }, [jobs, setPastJobs]);

  if (!operation) {
    return <Spinner fill />;
  }

  return (
    <div className={styles.Container}>
      <Header title={`${operation.name} - ${getString('boundariesAndData', language)}`} />
      <Center>
        <Text size="lg">{`${getString('twoWaysToUpload', language)}:`}</Text>
      </Center>
      <Space h="md" />
      <Center>
        <SegmentedControl
          color="blue"
          value={VIEW_OPTIONS.find((s) => pathname.includes(s.value))?.value}
          onChange={(val) => val && navigate(val)}
          data={VIEW_OPTIONS}
        />
      </Center>
      {pathname === uploadShapeFileUrl ? (
        <UploadContainer
          cancelJob={cancelJob}
          operation={operation}
          pastJobs={pastJobs}
          fetchJobs={fetchJobs}
        />
      ) : (
        <ConnectedAccountsContainer
          operationId={numericId}
          operation={operation}
          connectAccount={accountType || JOHN_DEERE}
        />
      )}
    </div>
  );
};

export default IngestHistoryContainer;
