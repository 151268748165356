import React, { useState } from 'react';
import { Select } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Agency } from 'store/agencies/types';
import { requestPutOperation } from 'store/operation/requests';
import { OperationType } from 'store/operation/types';

interface CommissionPropsType {
  operation: OperationType;
  agencies: Agency[];
}
const Commission = ({ operation, agencies }: CommissionPropsType) => {
  const language = useBroswerLanguage();
  const [commissionId, setCommissionId] = useState<number | null>(operation.commissions_user_id);

  const handleSelect = async (value: string | null) => {
    const selectedId = value ? parseInt(value, 10) : null;
    if (selectedId !== operation.commissions_user_id) {
      setCommissionId(selectedId);
      requestPutOperation(operation.id, {
        commissions_user_id: selectedId,
      });
    }
  };

  const options = agencies
    .filter((agency) => agency.primary_user?.id)
    .map((agency) => ({
      label: agency.name,
      value: String(agency.primary_user.id),
    }));

  return (
    <Select
      data={options}
      onChange={handleSelect}
      value={commissionId ? String(commissionId) : null}
      placeholder={getString('none', language)}
      maw="20rem"
    />
  );
};

export default Commission;
