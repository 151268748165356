export const toCamelCase = (str: string) =>
  str.replace(/([-_][a-z])/gi, (next) => next.toUpperCase().replace('-', '').replace('_', ''));

const formatKeys = (obj: { [key: string]: any }) =>
  Object.keys(obj).reduce((result, key) => {
    const newKey = toCamelCase(key);
    return {
      ...result,
      [newKey]: obj[key],
    };
  }, {});

export default formatKeys;
