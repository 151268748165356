import React from 'react';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BatchType } from 'store/batches/types';

import { EditBatchForm } from './EditBatchForm';

type EditBatchPropsType = {
  batch: BatchType;
};

export const EditBatch = ({ batch }: EditBatchPropsType) => {
  const language = useBroswerLanguage();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} size="70%">
        <EditBatchForm batch={batch} onClose={close} />
      </Modal>
      <Button variant="outline" onClick={open}>
        {getString('editBatch', language)}
      </Button>
    </>
  );
};
