import React, { useEffect, useRef } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';

type NewBarcodeInputPropsType = TextInputProps & {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const NewBarcodeInput = ({ plate, onValueChange, ...props }: NewBarcodeInputPropsType) => {
  const language = useBroswerLanguage();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange('barcode', e.target.value.trim());
  };

  return (
    <TextInput
      onChange={handleBarcodeChange}
      ref={inputRef}
      placeholder={getString('scanNewBarcodeMsg', language)}
      value={plate.barcode || ''}
      label={`${getString('newPlateBarcode', language)} *`}
      {...props}
    />
  );
};
