import {
  RECEIVE_STATE_GEOMETRY,
  REQUEST_STATE_GEOMETRY,
  STATE_GEOMETRY_ERROR,
  StateActionsType,
  StateGeometryType,
  StateType,
} from './types';

export type StateStateType = {
  geometries: { [country: string]: StateGeometryType };
  hasFailedList: string[];
  hasFetchedList: string[];
  isFetchingList: string[];
};

export const initialState: StateStateType = {
  geometries: {},
  hasFailedList: [],
  hasFetchedList: [],
  isFetchingList: [],
};

const applyNewGeometry = (
  geometries: StateStateType['geometries'],
  { stateId, countryId, geometry }: { stateId: string; countryId: string; geometry: StateType },
) => {
  return {
    ...geometries,
    [countryId]: {
      ...(geometries[countryId] || {}),
      [stateId]: geometry,
    },
  };
};

export const stateGeometryReducer = (
  state = initialState,
  action: StateActionsType,
): StateStateType => {
  switch (action.type) {
    case RECEIVE_STATE_GEOMETRY:
      return {
        ...state,
        geometries: applyNewGeometry(state.geometries, action.payload),
        hasFetchedList: state.hasFetchedList.concat(action.payload.stateId),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.stateId),
      };
    case REQUEST_STATE_GEOMETRY:
      return {
        ...state,
        isFetchingList: state.isFetchingList.concat(action.payload.stateId),
      };
    case STATE_GEOMETRY_ERROR:
      return {
        ...state,
        hasFailedList: state.hasFailedList.concat(action.payload.stateId),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.stateId),
      };
    default:
      return state;
  }
};
