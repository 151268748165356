import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Center, Flex, Modal, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Feature, FeatureCollection, Geometry, GeometryCollection } from '@turf/helpers';

import { BR } from 'constants/countries';
import { POLYGON } from 'constants/mapbox';
import {
  BRAZIL_ONLY_INPUTS,
  CONVENTIONAL_RX_TYPES,
  CUSTOM_FORMULA,
  CUSTOM_TYPE,
  DEFAULT_RX_VALUES_BR,
  DEFAULT_RX_VALUES_US,
  IMAGERY,
  MAX_PRESCRIPTION_NAME_LENGTH,
  PREPLANT,
  RAW_VALUE_KEY,
  REMOVAL_TYPE,
  RX_TYPE_BUILDUP,
  RX_TYPE_CUSTOM,
  RX_TYPE_REMOVAL,
  RX_TYPE_SEED,
  RX_ZONE_TYPE_CUSTOM,
  RX_ZONE_TYPE_FORMULA,
  SEED_TYPE,
  SOIL_TEST,
  YIELD,
} from 'constants/prescription';
import { NUTRIENT_PANEL } from 'constants/products';
import {
  BPH_SIKORA_ID,
  DEFAULT_RX_DENSITY,
  PH_ID,
  PHOSPHORUS_ID,
  PRO_EXPORT_ACRE_DENSITY_OPTIONS,
  SOIL_ATTRIBUTES,
} from 'constants/results';

import { formatToYear, sortByCreatedAt, sortByStartTime } from 'util/date';
import { generateZonesForPoints } from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  convertStringCustomZonesToNumeric,
  deduplicateLayersByValue,
  getDefaultCropYields,
  getDefaultRxCrop,
  getFormulaOptions,
  getInputOptions,
  getNutrientSelectorOptions,
  isImageryRxEnabled,
  isMachineDataRxEnabled,
  validateCustomZones,
} from 'util/prescription';
import {
  getPlanName,
  getProNutrientMapLayer,
  hasSoilAttributes,
  isPlanCreated,
  isPointBased,
  sortPlansByDate,
} from 'util/samplePlan';
import { capitalize } from 'util/stringUtils';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { AnalyticType } from 'store/analytics/types';
import { AgronomicProductType, SeedType } from 'store/cropPlans/types';
import { getFieldGeometry } from 'store/fields/thunks';
import { FieldType } from 'store/fields/types';
import { createSavedCustomZoneConfig, postPrescription } from 'store/prescriptions/requests';
import { getProPrescriptions } from 'store/prescriptions/thunks';
import { RxCustomZoneFormType } from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';
import { getUser } from 'store/user/thunks';

import CustomZoneEditor from './CustomZoneEditor';
import ScriptCreatorMap from './Map';
import ScriptMetadata from './ScriptMetadata';

import styles from './Container.module.css';

type ScriptCreatorContainerProps = {
  analytics: AnalyticType[];
  field: FieldType;
  onClose: () => void;
  onSubmit: (id: number) => void;
  samples: SampleFeatureType[];
  opened: boolean;
};

export type RxTypeType =
  | typeof RX_TYPE_BUILDUP
  | typeof RX_TYPE_REMOVAL
  | typeof RX_TYPE_SEED
  | typeof RX_TYPE_CUSTOM;

export type PrescriptionStateType = {
  agronomicProduct: AgronomicProductType | null;
  analytic: AnalyticType | null;
  crop: string;
  customMapLayer: string | null;
  customZones: RxCustomZoneFormType[];
  formula: string;
  generateFrom: string;
  harvestLayer: string | null;
  imageryLayer: string | null;
  input: string | null;
  isSubmitting: boolean;
  maxRate: number | undefined;
  maxYield: number | undefined;
  minYield: number | undefined;
  nutrient: string;
  proDensity: string | null;
  rxType: RxTypeType;
  rxZoneType: typeof RX_ZONE_TYPE_FORMULA | typeof RX_ZONE_TYPE_CUSTOM;
  samplePlan: string;
  savedConfigName: string;
  seed: SeedType | null;
  shouldSaveConfig: boolean;
  sourceLayerGeojson: FeatureCollection<
    Geometry | GeometryCollection,
    { [RAW_VALUE_KEY]: number }
  > | null;
};

const ScriptCreatorContainer = ({
  analytics,
  field,
  onClose,
  onSubmit,
  samples,
  opened,
}: ScriptCreatorContainerProps) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { currentUser, inputs } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
    inputs: state.inputs.inputs,
  }));

  const { composite_imagery_layers, harvest_data_files, nutrient_results_available } =
    field.features[0].properties;

  const samplingPlanOptions = useMemo(() => {
    const { sampling_plans } = field.features[0].properties;
    const plansWithAttributes = sortPlansByDate(
      sampling_plans.filter((plan) => hasSoilAttributes(plan) && !isPlanCreated(plan)),
    );
    return plansWithAttributes.map((plan) => ({
      id: plan.id,
      displayName: getPlanName(plan),
      label: getPlanName(plan),
      value: String(plan.id),
      plan,
    }));
  }, [field]);

  const harvestYearOptions = useMemo(
    () =>
      deduplicateLayersByValue(
        sortByStartTime(harvest_data_files)
          .filter(isMachineDataRxEnabled)
          .map((file) => ({
            id: file.id,
            label: `${formatToYear(file.start_time)} ${capitalize(file.crop || '')}`,
            value: file.processed_geojson_uri,
          })),
      ),
    [harvest_data_files],
  );

  const imageryYearOptions = useMemo(
    () =>
      deduplicateLayersByValue(
        composite_imagery_layers
          .sort((a, b) => (a.year < b.year ? 1 : -1))
          .filter(isImageryRxEnabled)
          .map((file) => ({
            id: file.id,
            label: `${file.year} NDVI`,
            value: file.geojson_uri,
          })),
      ),
    [composite_imagery_layers],
  );

  const prescriptionForm = useForm<PrescriptionStateType>({
    mode: 'uncontrolled',
    initialValues: {
      agronomicProduct: null,
      analytic: analytics.find((a) => a.id === PHOSPHORUS_ID) || null,
      crop: '',
      customMapLayer: null,
      customZones: [],
      formula: '',
      generateFrom: SOIL_TEST,
      harvestLayer: harvestYearOptions[0]?.value || null,
      imageryLayer: imageryYearOptions[0]?.value || null,
      input: '',
      isSubmitting: false,
      maxRate: undefined,
      maxYield: undefined,
      minYield: undefined,
      nutrient: '',
      proDensity: null,
      rxType: RX_TYPE_BUILDUP,
      rxZoneType: RX_ZONE_TYPE_FORMULA,
      samplePlan: '',
      savedConfigName: '',
      seed: null,
      shouldSaveConfig: false,
      sourceLayerGeojson: null,
    },
  });

  const {
    agronomicProduct,
    analytic,
    crop,
    customMapLayer,
    customZones,
    formula,
    generateFrom,
    harvestLayer,
    imageryLayer,
    input,
    isSubmitting,
    maxRate,
    maxYield,
    minYield,
    nutrient,
    proDensity,
    rxType,
    rxZoneType,
    savedConfigName,
    shouldSaveConfig,
    samplePlan,
    seed,
  } = prescriptionForm.getValues();

  const isCustom = rxZoneType === RX_ZONE_TYPE_CUSTOM;
  const isHarvest = generateFrom === YIELD || customMapLayer === YIELD;
  const isImagery = generateFrom === IMAGERY || customMapLayer === IMAGERY;
  const isRemoval = isHarvest || isImagery;

  // input options
  const getAvailableInputs = (forceNutrient?: string) => {
    const filteredInputs =
      field.features[0].properties.country_code === BR
        ? inputs
        : inputs.filter((i) => !BRAZIL_ONLY_INPUTS.includes(i.id));
    return getInputOptions(filteredInputs, forceNutrient || nutrient, formula);
  };

  const inputOptions = getAvailableInputs().map((i) => ({
    id: i.id,
    label: i.displayName,
    value: String(i.value.id),
  }));

  // Generate from selector
  const handleGenerateFromChange = (val: string) => {
    prescriptionForm.setFieldValue('generateFrom', val);
    if (val === SOIL_TEST) {
      prescriptionForm.setValues({
        harvestLayer: null,
        imageryLayer: null,
      });
    } else if (val === YIELD) {
      prescriptionForm.setFieldValue('imageryLayer', null);
      if (!harvestLayer) {
        prescriptionForm.setFieldValue('harvestLayer', harvestYearOptions[0]?.value);
      }
    } else if (val === IMAGERY) {
      prescriptionForm.setFieldValue('harvestLayer', null);
      if (!imageryLayer) {
        const { defaultMaxYield, defaultMinYield } = getDefaultCropYields(field, crop);
        prescriptionForm.setValues({
          imageryLayer: imageryYearOptions[0]?.value,
          maxYield: defaultMaxYield,
          minYield: defaultMinYield,
        });
      }
    }
  };

  const selectedPlan = samplingPlanOptions.find((plan) => plan.id === Number(samplePlan));
  const samplingPlanSamples = (() => {
    if (selectedPlan) {
      const planSamples = samples.filter((sample) => {
        const { biological_subsample, sampling_plan_id } = sample.properties;
        return sampling_plan_id === selectedPlan.id && !biological_subsample;
      });
      if (!isPointBased(selectedPlan.plan)) {
        return planSamples;
      }
      return generateZonesForPoints(
        planSamples as Feature<GeometryCollection>[],
        field,
      ) as SampleFeatureType[];
    }
  })();

  // Analytic selector
  const nutrientSelectorOptions = getNutrientSelectorOptions(rxType, language);
  const filteredNutrientOptions =
    selectedPlan && !isRemoval
      ? nutrientSelectorOptions.filter(
          (option) => selectedPlan.plan.analytics[SOIL_ATTRIBUTES][option.id],
        )
      : nutrientSelectorOptions;
  const planNutrientOptions = filteredNutrientOptions.map((option) => ({
    id: option.id,
    label: option.displayName,
    value: option.value,
  }));
  const proLayer =
    selectedPlan && analytic ? getProNutrientMapLayer(selectedPlan.plan, analytic) : null;

  // density options
  const proDensityOptions = useMemo(() => {
    if (isRemoval) {
      if (isImagery) {
        const selectedLayer = composite_imagery_layers.find(
          (layer) => layer.geojson_uri === imageryLayer,
        );
        return PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
          selectedLayer?.pro_densities.includes(option.value),
        );
      }
      const selectedLayer = harvest_data_files.find(
        (layer) => layer.processed_geojson_uri === harvestLayer,
      );
      return PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
        selectedLayer?.pro_densities.includes(option.value),
      );
    }
    return selectedPlan?.plan.pro_densities.length
      ? PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
          selectedPlan.plan.pro_densities.includes(option.value),
        )
      : [];
  }, [
    harvest_data_files,
    harvestLayer,
    composite_imagery_layers,
    imageryLayer,
    selectedPlan,
    isRemoval,
    isImagery,
  ]);

  // formula options
  const formulaOptionsByCategory = getFormulaOptions(language, field, nutrient);
  const { NUTRIENTS: nutrientOptions, REMOVAL: removalOptions } = formulaOptionsByCategory;
  const allFormulaOptions = rxType === RX_TYPE_BUILDUP ? nutrientOptions : removalOptions;

  // Form submission
  const submit = async () => {
    prescriptionForm.setFieldValue('isSubmitting', true);
    const { id: fieldId, country_code: countryCode, crop_plans } = field.features[0].properties;
    const newestCropPlan = sortByCreatedAt(crop_plans || [])?.[0];
    // for ph prescriptions, we want to show ph on the map but use buffer ph for
    // the amount calculations
    const analyticId = analytic?.id === PH_ID ? BPH_SIKORA_ID : Number(analytic?.id);
    const density = PRO_EXPORT_ACRE_DENSITY_OPTIONS.find((option) => option.value === proDensity);

    const zones =
      proLayer || isHarvest || isImagery || !analytic
        ? undefined
        : (samplingPlanSamples || [])
            .map((sample) => ({
              ...sample,
              geometry: sample.geometry.geometries.find((geom) => geom.type === POLYGON),
              properties: {
                sample_uuid: sample.properties.sample_uuid,
                amount:
                  sample.properties.analytics[analytic?.category]?.[analyticId]?.quantity ||
                  selectedPlan?.plan.analytics[analytic?.category]?.[analyticId]?.quantity ||
                  null,
                products: sample.properties.products,
              },
            }))
            .filter(
              (sample) => sample.geometry && sample.properties.products.includes(NUTRIENT_PANEL),
            );

    const defaultValues =
      (countryCode === BR ? DEFAULT_RX_VALUES_BR[analyticId] : DEFAULT_RX_VALUES_US[analyticId]) ||
      {};

    const harvestFile = harvest_data_files.find(
      (file) => file.processed_geojson_uri === harvestLayer,
    );

    const imageryFile = composite_imagery_layers.find((file) => file.geojson_uri === imageryLayer);

    const getProDensity = () => {
      if (generateFrom === SOIL_TEST || proLayer) {
        return proLayer ? density?.value : null;
      }
      if (isHarvest || isImagery) {
        return density?.value;
      }
      return null;
    };

    const getNameSuffix = () => {
      if (seed) {
        return seed.hybrid;
      }
      if (agronomicProduct) {
        return agronomicProduct.name;
      }
      if (input) {
        const selectedInput = inputOptions.find((option) => option.id === Number(input));
        if (selectedInput) {
          return selectedInput.label;
        }
      }
      if (analytic) {
        return analytic.name;
      }
      return nutrient.toUpperCase();
    };

    const getType = () => {
      switch (rxType) {
        case RX_TYPE_REMOVAL:
          return REMOVAL_TYPE;
        case RX_TYPE_SEED:
          return SEED_TYPE;
        case RX_TYPE_CUSTOM:
          return CUSTOM_TYPE;
        default:
          return SOIL_TEST;
      }
    };

    const payload = {
      ...defaultValues,
      field_id: fieldId,
      name: `${field.features[0].properties.name} - ${getNameSuffix()}`.slice(
        0,
        MAX_PRESCRIPTION_NAME_LENGTH,
      ),
      zones,
      sampling_plan_id: selectedPlan?.id,
      crop: crop || defaultValues.crop,
      expected_yield: newestCropPlan?.target_yield_per_acre || defaultValues.expected_yield,
      maximum_rate: maxRate || defaultValues.maximum_rate,
      pro_density: getProDensity(),
      machine_data_id: isHarvest ? harvestFile?.id : null,
      composite_imagery_id: isImagery ? imageryFile?.id : null,
      formula_name: isCustom ? CUSTOM_FORMULA : formula || defaultValues.formula_name,
      input_id:
        CONVENTIONAL_RX_TYPES.includes(rxType) && input ? Number(input) : defaultValues.input_id,
      imagery_calculation_max: isImagery ? maxYield : null,
      imagery_calculation_min: isImagery ? minYield : null,
      custom_zone_meta: isCustom ? convertStringCustomZonesToNumeric(customZones) : null,
      analytic_id: analytic?.primary_analytic_id || analytic?.id || null,
      seed_id: rxType === RX_TYPE_SEED && seed ? seed.id : null,
      agronomic_product_id:
        rxType === RX_TYPE_CUSTOM && agronomicProduct ? agronomicProduct.id : null,
      type: getType(),
      timing: PREPLANT,
    };
    try {
      const response = await postPrescription(payload);
      await Promise.all([
        dispatch(getFieldGeometry(fieldId)),
        dispatch(getProPrescriptions(fieldId)),
      ]);
      if (shouldSaveConfig && savedConfigName && currentUser) {
        await createSavedCustomZoneConfig({
          name: savedConfigName,
          category: rxType,
          zone_meta: convertStringCustomZonesToNumeric(customZones),
        });
        await dispatch(getUser(currentUser.id));
      }
      showToast(getString('prescriptionCreatedMsg', language), 'success');
      onSubmit(response.id);
    } catch (error) {
      showToast(getString('prescriptionCreatedFailMsg', language), 'error');
    } finally {
      prescriptionForm.setFieldValue('isSubmitting', false);
    }
  };

  useEffect(() => {
    prescriptionForm.setFieldValue('maxRate', undefined);
  }, [nutrient]);

  useEffect(() => {
    const cropValue = crop || getDefaultRxCrop(field);
    const { defaultMaxYield, defaultMinYield } = getDefaultCropYields(field, cropValue);

    const getDefaultValues = () => {
      const hasHarvestLayers = harvest_data_files.length > 0;
      const hasImageryLayers = composite_imagery_layers.length > 0;
      const hasRemovalLayers = hasHarvestLayers || hasImageryLayers;

      const removalDefaultGenerate = hasHarvestLayers || !hasImageryLayers ? YIELD : IMAGERY;

      const defaultToRemoval = !nutrient_results_available && hasRemovalLayers;
      return {
        generateFrom: defaultToRemoval ? removalDefaultGenerate : SOIL_TEST,
        rxType: (defaultToRemoval ? RX_TYPE_REMOVAL : RX_TYPE_BUILDUP) as RxTypeType,
      };
    };

    if (!crop) {
      prescriptionForm.setValues({
        ...getDefaultValues(),
        crop: cropValue,
        maxYield: defaultMaxYield,
        minYield: defaultMinYield,
      });
    }
  }, [crop, field, harvestYearOptions, imageryYearOptions]);

  useEffect(() => {
    if (!proDensity) {
      prescriptionForm.setFieldValue(
        'proDensity',
        proDensityOptions.find((option) => option.value === DEFAULT_RX_DENSITY.value)?.value ||
          null,
      );
    }
    if (!samplePlan) {
      prescriptionForm.setFieldValue('samplePlan', samplingPlanOptions[0]?.value || '');
    }
    if (!nutrient) {
      prescriptionForm.setFieldValue('nutrient', planNutrientOptions?.[0]?.value || '');
    }
    if (!formula) {
      prescriptionForm.setFieldValue('formula', String(allFormulaOptions?.[0]?.value || ''));
    }
    if (!input && CONVENTIONAL_RX_TYPES.includes(rxType)) {
      prescriptionForm.setFieldValue('input', inputOptions[0]?.value || '');
    }
  }, [
    allFormulaOptions,
    formula,
    input,
    inputOptions,
    nutrient,
    planNutrientOptions,
    proDensity,
    proDensityOptions,
    rxType,
    samplePlan,
    samplingPlanOptions,
  ]);

  const areCustomZonesValid = (() => {
    if (rxZoneType !== RX_ZONE_TYPE_CUSTOM || customZones.length === 0) {
      return true;
    }
    return validateCustomZones(convertStringCustomZonesToNumeric(customZones));
  })();

  return (
    <Modal centered onClose={onClose} opened={opened} size="xxl">
      <Title ta="center" order={1} pb="sm">
        {getString('createRx', language)}
      </Title>
      <Flex gap="md">
        <ScriptMetadata
          allFormulaOptions={allFormulaOptions}
          analytics={analytics}
          field={field}
          getAvailableInputs={getAvailableInputs}
          harvestYearOptions={harvestYearOptions}
          imageryYearOptions={imageryYearOptions}
          inputOptions={inputOptions}
          planNutrientOptions={planNutrientOptions}
          prescriptionForm={prescriptionForm}
          proDensityOptions={proDensityOptions}
          proLayer={proLayer}
          samples={samplingPlanSamples}
          samplingPlanOptions={samplingPlanOptions}
        />
        <Box className={styles.Map}>
          <ScriptCreatorMap
            field={field}
            handleGenerateFromChange={handleGenerateFromChange}
            harvestYearOptions={harvestYearOptions}
            imageryYearOptions={imageryYearOptions}
            isRemoval={isRemoval}
            language={language}
            prescriptionForm={prescriptionForm}
            proLayer={proLayer}
            samples={samplingPlanSamples}
          />
        </Box>
      </Flex>
      {rxZoneType === RX_ZONE_TYPE_CUSTOM && (
        <CustomZoneEditor
          currentUser={currentUser}
          field={field}
          language={language}
          proLayer={proLayer}
          rxForm={prescriptionForm}
          samples={samplingPlanSamples}
        />
      )}
      <Center my="lg">
        <Button
          disabled={
            (isRemoval && !(harvestLayer || imageryLayer)) ||
            !areCustomZonesValid ||
            !(input || seed || agronomicProduct)
          }
          onClick={submit}
          loading={isSubmitting}
        >
          {getString('createAndEditRx', language)}
        </Button>
      </Center>
    </Modal>
  );
};

export default ScriptCreatorContainer;
