import {
  RECEIVE_SAMPLES,
  REQUEST_SAMPLES,
  SAMPLE_REQUEST_ERROR,
  SamplesActionType,
  SamplesResponse,
} from './types';

export const requestSamples = (fieldId: number): SamplesActionType => ({
  type: REQUEST_SAMPLES,
  payload: { id: fieldId },
});
export const receiveSamples = (payload: {
  id: number;
  samples: SamplesResponse;
}): SamplesActionType => ({
  type: RECEIVE_SAMPLES,
  payload,
});
export const sampleRequestError = (payload: {
  id: number;
  message: string;
}): SamplesActionType => ({
  type: SAMPLE_REQUEST_ERROR,
  payload,
});
