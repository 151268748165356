import { Button, Group, Stack, Table, Text } from '@mantine/core';

import { UI_COLORS } from 'constants/colors';

import { FieldGateProperties } from 'store/projects/types';

import { useProjectsTranslations } from '../hooks';

type Props = {
  properties: FieldGateProperties;
  onDeleteClick: VoidFunction;
};

const GatePopupContent = ({ properties, onDeleteClick }: Props) => {
  const translations = useProjectsTranslations();

  return (
    <Stack>
      <Table
        variant="vertical"
        layout="fixed"
        withTableBorder
        maw={300}
        data={{
          body: Object.entries(properties || {}).map(([key, value]) => [
            <Text key={key} fw="bold" w={160}>
              {key}
            </Text>,
            value,
          ]),
        }}
      />
      <Group justify="center" pos="sticky" bottom={0}>
        <Button color={UI_COLORS.error} onClick={onDeleteClick}>
          {translations.deleteGate}
        </Button>
      </Group>
    </Stack>
  );
};

export default GatePopupContent;
