import React, { useEffect, useRef } from 'react';
import { geoIdentity, geoPath } from 'd3-geo';
import { select } from 'd3-selection';

import { FieldType } from 'store/fields/types';

const DEFAULT_HEIGHT = 40;
const DEFAULT_WIDTH = 40;

type FieldThumbnailProps = {
  features: FieldType;
  height?: number;
  width?: number;
};

const FieldThumbnail = ({
  features,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}: FieldThumbnailProps) => {
  const thumbnailRef = useRef(null);

  useEffect(() => {
    if (!features) {
      return;
    }

    const projection = geoIdentity().reflectY(true).fitSize([height, width], features);
    const path = geoPath().projection(projection);

    select(thumbnailRef.current)
      .selectAll('path')
      .data(features.features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', '#E6E6E6')
      .style('stroke-width', '1')
      .style('stroke', '#999999');
  }, [features, height, width]);

  if (!features) {
    return null;
  }

  return (
    <svg height={height} width={width}>
      <g ref={thumbnailRef} />
    </svg>
  );
};

export default FieldThumbnail;
