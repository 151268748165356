import React, { Fragment } from 'react';
import { Checkbox, Group, Stack, Table, Text } from '@mantine/core';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { formatToNumericDate } from 'util/date';
import {
  getHistoryByOperation,
  getTotalHistoryAcres,
  getTotalHistorySamples,
  isFieldArrived,
} from 'util/samplingHistory';
import { SamplingDateType, SamplingHistorySummaryType } from 'store/samplingHistory/types';

type SamplingHistoryExpandedPropsType = {
  sampledDate: SamplingDateType;
};

export const SamplingHistoryExpanded = ({ sampledDate }: SamplingHistoryExpandedPropsType) => {
  const fieldsByOperation = getHistoryByOperation(sampledDate.sampled_fields);

  const getShippedDisplay = (field: SamplingHistorySummaryType) => {
    const shippingLabels = field.shipping_labels;

    if (!shippingLabels?.length) {
      return <Text inherit>{NOT_APPLICABLE}</Text>;
    }

    return (
      <Stack gap="0">
        {shippingLabels.map((label) => (
          <Text key={label.created_at} inherit>
            {formatToNumericDate(label.created_at)}
          </Text>
        ))}
      </Stack>
    );
  };

  return (
    <Table verticalSpacing="0" withRowBorders={false}>
      <Table.Tbody>
        {fieldsByOperation.map((operation) => (
          <Fragment key={operation.id}>
            <Table.Tr style={{ border: 0 }}>
              <Table.Th w="20rem" miw="11rem">
                <Text inherit>{operation.name}</Text>
              </Table.Th>
              <Table.Th w="15%" miw="5.5rem" ta="right">
                <Text inherit>{operation.fields.length}</Text>
              </Table.Th>
              <Table.Th w="10%" miw="4.5rem" ta="right">
                <Text inherit>{getTotalHistoryAcres(operation.fields)}</Text>
              </Table.Th>
              <Table.Th w="10%" miw="4.5rem" ta="right">
                <Text inherit>{getTotalHistorySamples(operation.fields)}</Text>
              </Table.Th>
              <Table.Td w="30%" colSpan={2} />
              <Table.Th w="3rem" maw="3rem" miw="3rem" />
            </Table.Tr>
            {operation.fields.map((field, findex) => (
              <Table.Tr key={`${field.field_id}_${findex}`} style={{ border: 0 }}>
                <Table.Td ta="right">
                  <Text inherit fs="italic">
                    {field.field_name}
                  </Text>
                </Table.Td>
                <Table.Td />
                <Table.Td ta="right">
                  <Text inherit>{getTotalHistoryAcres([field])}</Text>
                </Table.Td>
                <Table.Td ta="right">
                  <Text inherit>{getTotalHistorySamples([field])}</Text>
                </Table.Td>
                <Table.Td w="20%" ta="right">
                  {getShippedDisplay(field)}
                </Table.Td>
                <Table.Td w="10%" miw="4.5rem">
                  <Group justify="flex-end">
                    <Checkbox variant="outline" readOnly checked={isFieldArrived(field)} />
                  </Group>
                </Table.Td>
                <Table.Td w="3rem" maw="3rem" miw="3rem" />
              </Table.Tr>
            ))}
            <Table.Tr h="xs" style={{ border: 0 }} />
          </Fragment>
        ))}
      </Table.Tbody>
    </Table>
  );
};
