import { Fragment } from 'react';
import { Table, Text } from '@mantine/core';

import { GREY_BLUE, WHITE } from 'constants/colors';

import { TypeOfSku } from 'store/pricing/types';

import { useContractPricingFormContext } from './contractPricingContext';
import DefaultSkuPriceRow from './DefaultSkuPriceRow';

const { Td, Tr, Tbody } = Table;

const PricingTableRows = () => {
  const form = useContractPricingFormContext();

  const getDefaultRow = (headerStr: string, backgroundColor?: string, textColor?: string) => (
    <Tr
      bg={backgroundColor}
      style={{
        top: 90, // 110 works if stickyHeaderOffset is 75
        zIndex: 2,
        position: 'sticky',
      }}
    >
      <Td colSpan={9}>
        <Text size="sm" c={textColor}>
          {headerStr}
        </Text>
      </Td>
    </Tr>
  );

  return (
    <Tbody>
      {Object.entries(form.getValues().contractSkuPrices).map(([key, skuPrices]) => (
        <Fragment key={key}>
          {getDefaultRow(key.toUpperCase(), GREY_BLUE, WHITE)}
          {skuPrices.map((skuPrice, i) => (
            <DefaultSkuPriceRow
              key={skuPrice.id}
              index={i}
              skuPrice={skuPrice}
              typeOfSku={key as TypeOfSku}
            />
          ))}
        </Fragment>
      ))}
    </Tbody>
  );
};

export default PricingTableRows;
