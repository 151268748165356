import { Group, Stack, Text } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { NUTRIENT_PANEL } from 'constants/products';

import { getString } from 'strings/translation';

import SplitDensityQuantitySelect from './SplitDensityQuantitySelect';

type Props = {
  language: string;
  showCompleteBioLabel: boolean;
};

const SplitDensitySection = ({ language, showCompleteBioLabel }: Props) => {
  const form = useOrderFormContext();
  const { zones } = form.getValues();
  const totalZones = zones?.features.length || 0;

  const groupNumbers = zones?.features
    .map((feature) => feature.properties?.sample_group)
    .filter(Boolean);

  const numGroups = new Set(groupNumbers).size;

  return (
    <Stack gap="xs">
      <Text size="xl" fw={500} ta="center">
        {getString('splitDensity', language)}
      </Text>
      <Text size="xs">{getString('splitDensityDesc', language)}</Text>
      <Group justify="space-between">
        <Text>{`${getString(NUTRIENT_PANEL, language)} ${getString('zones', language)}: `}</Text>
        <Text>{totalZones}</Text>
      </Group>
      <SplitDensityQuantitySelect
        language={language}
        showCompleteBioLabel={showCompleteBioLabel}
        totalZones={totalZones}
        numGroups={numGroups}
      />
    </Stack>
  );
};

export default SplitDensitySection;
