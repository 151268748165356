import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { GCPFileUpload } from 'util/request';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestUpdateDocument, requestUploadDocument } from 'store/plates/requests';
import { getPlate } from 'store/plates/thunks';
import { PlateType, PlateUploadedFileType } from 'store/plates/types';
import { FileUploadButton, Input, Label } from 'common';

import styles from '../Container.module.css';

type DocumentUploadPropTypes = {
  plate: PlateType;
  documentType: string;
};

export const DocumentUpload = ({ plate, documentType }: DocumentUploadPropTypes) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [notes, setNotes] = useState('');

  const uploadToGCP = (uploadedFile: PlateUploadedFileType, file: File) => {
    // fetch does not support onProgress, so using XHR
    return new Promise((resolve, reject) => {
      const onCompleted = () => resolve(undefined);
      const onError = (err: any) => reject(err);

      if (!uploadedFile.upload_url) {
        return onError(new Error(getString('missingUrlMsg', language)));
      }

      const formData = new FormData();
      formData.append(file.name, file);

      const xhr = GCPFileUpload({
        uploadUrl: uploadedFile.upload_url,
        file: formData,
        onCompleted,
        onError,
        onAbort: onError,
        header: 'Content-Type',
        headerValue: 'application/zip',
      });
      return xhr;
    });
  };

  const uploadDocument = async (file: File) => {
    setIsUploading(true);
    try {
      const uploadeFile = await requestUploadDocument(
        plate.barcode,
        file.name,
        notes,
        documentType,
      );
      await uploadToGCP(uploadeFile, file);
      await requestUpdateDocument(uploadeFile.id, { uploaded: true });
      showToast(getString('uploadSuccessMsg', language));
      dispatch(getPlate(plate.barcode));
    } catch (e) {
      showToast(e.message, 'error');
    }
    setIsUploading(false);
  };

  return (
    <FileUploadButton
      buttonLabel={getString('uploadDocument', language)}
      onUpload={uploadDocument}
      accept=".csv,.zip,.xls"
    >
      <Label className={styles.NotesInput} label={getString('notes', language)}>
        <Input
          className={styles.Input}
          disabled={isUploading}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
          value={notes}
          placeholder={getString('provideNotesMsg', language)}
        />
      </Label>
    </FileUploadButton>
  );
};
