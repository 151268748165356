import React from 'react';
import { Alert, Flex, Loader, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAcreageUnitFromLang } from 'util/units';

type Props = {
  formattedPrice: { beforeDecimal: string; afterDecimal: number } | null;
  isPending: boolean;
  error: Error | null;
  followingString?: string;
};

const AnalysisProductPrice = ({ formattedPrice, isPending, error, followingString }: Props) => {
  const language = useBroswerLanguage();
  if (isPending) {
    return <Loader />;
  }

  if (!formattedPrice) {
    return null;
  }

  if (error) {
    return (
      <Alert variant="filled" color="red.8">
        {error.message}
      </Alert>
    );
  }

  return (
    <Flex align="baseline" gap={0}>
      <Text fz="3rem">${formattedPrice.beforeDecimal}</Text>
      <span>
        {formattedPrice.afterDecimal ? `.${formattedPrice.afterDecimal}` : ''} /{' '}
        {getAcreageUnitFromLang(language)}
        {followingString}
      </span>
    </Flex>
  );
};

export default AnalysisProductPrice;
