import BluePrintHeader from 'images/blueprintHeader.png';
import EarthOpticsLogo from 'images/earthoptics-logo.svg';
import LavoroHeader from 'images/lavoroHeader.png';
import LavoroSignIn from 'images/lavoroSignIn.png';
import RedstarLogo from 'images/redstarLogo.png';
import RedstarSignIn from 'images/redstarSignIn.png';

import { BLUEPRINT, EARTH_OPTICS, LAVORO, REDSTAR, whiteLabels } from 'constants/agency';
import { ACCESS_TOKEN, ACTIVE_USER_ID, CELL, EMAIL, PASSWORD, REFRESH_TOKEN } from 'constants/auth';

import { getString } from 'strings/translation';
import { getUser } from 'store/user/thunks';
import { PasswordlessUserEmailType, TokenDTO } from 'store/user/types';

import { setLocalStorage } from './localStorage';

export const saveLoginToLocalStorage = (data: TokenDTO) => {
  const { access_token: accessToken, id, refresh_token: refreshToken } = data;
  const params = new URLSearchParams(window.location.search);
  const referrer = params.get('referrer');
  setLocalStorage(ACCESS_TOKEN, accessToken);
  setLocalStorage(ACTIVE_USER_ID, id);
  setLocalStorage(REFRESH_TOKEN, refreshToken);

  window.location.href = referrer || '/';
};

export const getUserStartPage = async (activeUserId, dispatch, auth) => {
  try {
    await dispatch(getUser(activeUserId));
    auth.setLoadingAuth(false);
    auth.refresh('errorLoggedInMsg');
  } catch (err) {
    auth.logout('errorLoggedInMsg');
  }
};

export const hostNameLogoParse = (isSignIn?: boolean) => {
  const hostArr = getHostName();
  switch (hostArr) {
    case BLUEPRINT:
      return BluePrintHeader;
    case LAVORO:
      if (isSignIn) {
        return LavoroSignIn;
      }
      return LavoroHeader;
    case REDSTAR:
      if (isSignIn) {
        return RedstarSignIn;
      }
      return RedstarLogo;
    default:
      return EarthOpticsLogo;
  }
};

export const getHostName = () => {
  const hostArr = window.location.hostname.split('.');
  if (hostArr.length && whiteLabels.includes(hostArr[0])) {
    return hostArr[0];
  }
  return EARTH_OPTICS;
};

export const getLoginSelectionList = (language: string, emailCell: PasswordlessUserEmailType) =>
  [
    {
      label: `${getString('sendCodeTo', language)} (***) ***-${emailCell.cell}`,
      value: CELL,
      dataTestId: `${CELL}-radio`,
    },
    {
      label: `${getString('emailCodeTo', language)} ${emailCell.email}`,
      value: EMAIL,
      dataTestId: `${EMAIL}-radio`,
    },
    {
      label: getString('signInWithPassword', language),
      value: PASSWORD,
      dataTestId: `${PASSWORD}-radio`,
    },
  ].filter((val) => (!emailCell.cell ? val.value !== 'cell' : val));

export const getIsPatternSite = () => {
  return !whiteLabels.some((domain) => window.location.hostname.includes(domain));
};

export default saveLoginToLocalStorage;
