import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { checkRole, userIsAgencyAdmin, userIsAgent } from 'util/userRoles';
import { getString, useTranslation } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { registerUserInAgency } from 'store/agencies/requests';
import { getAllAgencies } from 'store/agencies/thunks';
import { registerUser } from 'store/user/requests';
import { Header } from 'common';

import RegistrationForm from './RegistrationForm';

import styles from './Container.module.css';

const RegistrationContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();

  const { roles } = useSelector((state: RootState) => ({
    roles: state.roles.roles,
  }));
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(getAllAgencies());
  }, [dispatch]);

  const submitUser = async (body, setSubmitting: Function) => {
    setErrorMessage('');
    try {
      if (
        checkRole(roles, body.role_id, userIsAgent) ||
        checkRole(roles, body.role_id, userIsAgencyAdmin)
      ) {
        await registerUserInAgency(body);
      } else {
        await registerUser(body);
      }
      showToast(getString('userCreatedMsg', language));
      navigate('/users');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.Wrapper}>
      <Header title={useTranslation('createUser')} />
      <RegistrationForm errorMessage={errorMessage} submit={submitUser} />
    </div>
  );
};

export default RegistrationContainer;
