import React, { useState } from 'react';
import { Accordion, Badge, Group, Stack, Text } from '@mantine/core';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import {
  DataReviewCollectionGroup,
  EOCollectionFieldType,
  GroupedCollectionsListPayload,
} from 'store/eoCollections/types';

import FieldCollections from './FieldCollections';

interface DataReviewCollectionsProps {
  collectionsGroup: DataReviewCollectionGroup;
  selectedFields: EOCollectionFieldType[];
  /** Prevent unnecessary render of collapsed content */
  shouldRenderChildren: boolean;
  toggleField: (fieldProperties: EOCollectionFieldType) => void;
  groupId: string;
  currentGetPayload: GroupedCollectionsListPayload;
}

const CollectionsGroup = ({
  collectionsGroup,
  selectedFields,
  toggleField,
  groupId,
  shouldRenderChildren,
  currentGetPayload,
}: DataReviewCollectionsProps) => {
  const language = useBroswerLanguage();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const { fields, secondaryNames, name: groupName } = collectionsGroup;

  if (!fields.length) {
    return null;
  }

  const totalAcreage = fields.reduce(
    (total, field) => total + Math.round(field.features[0].properties.acreage),
    0,
  );
  const acreageUnit = fields[0].features[0].properties.acreage_unit;
  const totalCollections = fields.reduce(
    (total, field) => total + field.features[0].properties.pending_eo_collections.length,
    0,
  );

  const uniqueSecondaryNames = secondaryNames.filter(
    (name, index, self) => self.indexOf(name) === index,
  );

  const getCollectionsText = () => {
    const justOneCollection = totalCollections === 1;

    if (currentGetPayload.status === 'pending') {
      return getString(justOneCollection ? 'collectionToReview' : 'collectionsToReview', language);
    }

    return getString(justOneCollection ? 'collection' : 'collections', language);
  };

  return (
    <Accordion.Item value={groupId}>
      <Accordion.Control>
        <Stack gap={0}>
          <Group gap="xs">
            <Text size="lg" fw={FONT_WEIGHT_BOLD}>
              {groupName}
            </Text>
            <Badge color="blue" variant="light">
              {totalCollections} {getCollectionsText()} ({`${totalAcreage} ${acreageUnit}`})
            </Badge>
          </Group>
          <Text c="dimmed" size="sm">
            {uniqueSecondaryNames.join(', ')}
          </Text>
        </Stack>
      </Accordion.Control>
      <Accordion.Panel>
        {shouldRenderChildren && (
          <Accordion
            multiple
            value={expandedIds}
            onChange={(ids) => {
              setExpandedIds(ids);
              // Ensure map is resized after accordion is expanded. 200 is the default transition
              // duration for the accordion component. Still some jank, but better than white void.
              setTimeout(() => window.dispatchEvent(new Event('resize')), 205);
            }}
          >
            {fields.map((field) => (
              <FieldCollections
                key={`field-${field.features[0].properties.id}`}
                fieldFeatureCollection={field}
                selectedFields={selectedFields}
                currentGetPayload={currentGetPayload}
                toggleField={toggleField}
                accordionPanelIsOpen={expandedIds.includes(
                  field.features[0].properties.id.toString(),
                )}
              />
            ))}
          </Accordion>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default CollectionsGroup;
