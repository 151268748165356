import { Accordion, Space, Text } from '@mantine/core';

import { UI_COLORS } from 'constants/colors';
import { ANALYSIS, FIELD_WORK, LIST_PRICE, PRICE } from 'constants/pricing';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getOrderPriceTotalsBySkuType } from 'util/pricing';
import { getString } from 'strings/translation';
import { OrderSummaryMode, PriceSummary } from 'store/pricing/types';

import OrderSummaryAccordionControl from './OrderSummaryAccordionControl';
import OrderSummaryColumnHeaders from './OrderSummaryColumnHeaders';
import OrderSummaryModeToggle from './OrderSummaryModeToggle';
import OrderSummaryTotalsFooter from './OrderSummaryTotalsFooter';
import SummaryLineItem from './SummaryLineItem';

type Props = {
  displayedProducts: { analysis: PriceSummary[]; fieldWork: PriceSummary[] };
  mode: OrderSummaryMode;
  setMode: (newMode: OrderSummaryMode) => void;
  estimatedListPrice: number;
  estimatedPrice: number;
  estimatedListPricePerAcre: number;
  estimatedPricePerAcre: number;
  isNotBilled?: boolean;
};

const OrderSummary = ({
  displayedProducts,
  mode,
  setMode,
  estimatedListPrice,
  estimatedPrice,
  estimatedListPricePerAcre,
  estimatedPricePerAcre,
  isNotBilled,
}: Props) => {
  const language = useBroswerLanguage();
  const isListPrice = mode === LIST_PRICE;

  const calculateTotalPrice = (products: PriceSummary[]) =>
    products.reduce(
      (acc, product) => {
        const key = isListPrice ? LIST_PRICE : PRICE;
        acc[key] += isListPrice ? product.total_list_price : product.total_price;
        return acc;
      },
      { price: 0, listPrice: 0 },
    );

  const analysisPrices = getOrderPriceTotalsBySkuType(displayedProducts.analysis, isListPrice);
  const fieldWorkPrices = getOrderPriceTotalsBySkuType(displayedProducts.fieldWork, isListPrice);
  const totalAnalysisPrice = calculateTotalPrice(displayedProducts.analysis);
  const totalFieldWorkPrice = calculateTotalPrice(displayedProducts.fieldWork);

  return (
    <>
      <OrderSummaryModeToggle language={language} mode={mode} onChange={setMode} />
      <Space />
      {isNotBilled && (
        <Text size="lg" fw="bold" c={UI_COLORS.error}>
          *Is not billed - Financial Transactions will not be created*
        </Text>
      )}
      <OrderSummaryColumnHeaders language={language} />
      <Accordion w="100%" multiple>
        <Accordion.Item value={ANALYSIS}>
          <OrderSummaryAccordionControl
            perZoneTotalPrice={analysisPrices.perZone}
            perAcreTotalPrice={analysisPrices.perAcre}
            totalPrice={totalAnalysisPrice[isListPrice ? LIST_PRICE : PRICE]}
            titleText={getString('analysis', language)}
            zoneCount={displayedProducts.analysis[0]?.zones}
          />
          <Accordion.Panel>
            {displayedProducts.analysis.map((product) => (
              <SummaryLineItem
                key={product.sku_id}
                productPriceSummary={product}
                isListPrice={isListPrice}
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value={FIELD_WORK}>
          <OrderSummaryAccordionControl
            perZoneTotalPrice={fieldWorkPrices.perZone}
            perAcreTotalPrice={fieldWorkPrices.perAcre}
            totalPrice={totalFieldWorkPrice[isListPrice ? LIST_PRICE : PRICE]}
            titleText={getString('fieldWork', language)}
            zoneCount={displayedProducts.fieldWork[0]?.zones}
          />
          <Accordion.Panel>
            {displayedProducts.fieldWork.map((product) => (
              <SummaryLineItem
                key={product.sku_id}
                productPriceSummary={product}
                isListPrice={isListPrice}
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <OrderSummaryTotalsFooter
        language={language}
        totalPrice={isListPrice ? estimatedListPrice : estimatedPrice}
        pricePerAcre={isListPrice ? estimatedListPricePerAcre : estimatedPricePerAcre}
      />
    </>
  );
};

export default OrderSummary;
