/**
 * @file `queryOptions` can be reused by useQuery, preFetchQuery, and invalidateQueries. This
 * approach is entirely optional and should only be implemented if you need to reuse a similar set
 * of options across multiple queries. Otherwise, it is a bit overkill if you only need to reuse a
 * query (but not prefetch, invalidate, etc.). In that case, just make a hook called that returns
 * the query instance.
 */
import { queryOptions } from '@tanstack/react-query';

import { getGroupedCollections } from 'store/eoCollections/requests';
import fetchFieldGeometry from 'store/fields/requests';
import { requestSingleOperation } from 'store/operation/requests';
import { getAllSkus, getContractsByAgency, getDefaultPricing } from 'store/pricing/requests';
import { getAllProjects, getProjectById, getSamplingPlanById } from 'store/projects/requests';
import { ExternalNutrientLabsResponse } from 'store/samplePlans/types';
import { requestGetTimings } from 'store/samplingTimings/requests';

import {
  dataReviewQueryKeys,
  DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS,
  fieldsQueryKeys,
  operationsQueryKeys,
  pricingQueryKeys,
  projectsQueryKeys,
  samplingPlansQueryKeys,
} from './queryKeys';
import { handleJsonResponse, requestGet, SERVICE_URL } from './request';

export const collectionGroupQueryOptions = (filters = DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS) => {
  return queryOptions({
    queryKey: dataReviewQueryKeys.filteredGroupedCollections(filters),
    queryFn: () => getGroupedCollections(filters),
  });
};

export const fieldQueryOptions = (fieldId: number) => {
  return queryOptions({
    queryKey: fieldsQueryKeys.field(fieldId),
    queryFn: () => fetchFieldGeometry(fieldId),
  });
};

export const operationQueryOptions = (operationId: number) => {
  return queryOptions({
    queryKey: operationsQueryKeys.operation(operationId),
    queryFn: () => requestSingleOperation(operationId),
  });
};

export const defaultPricingQueryOptions = queryOptions({
  queryKey: pricingQueryKeys.defaultPricing,
  queryFn: getDefaultPricing,
});

export const contractsPricingQueryOptions = (agencyId = '') => {
  return queryOptions({
    queryKey: pricingQueryKeys.contractsByAgency(agencyId),
    queryFn: () => getContractsByAgency(agencyId),
  });
};

export const externalLabsQueryOptions = queryOptions({
  queryKey: pricingQueryKeys.externalLabs,
  queryFn: async (): Promise<ExternalNutrientLabsResponse> =>
    handleJsonResponse(await requestGet(`${SERVICE_URL}/external_nutrient_labs`)),
});

export const sampleTimingsQueryOptions = queryOptions({
  queryKey: samplingPlansQueryKeys.sampleTimings,
  queryFn: requestGetTimings,
});

export const allSkusQueryOptions = queryOptions({
  queryKey: pricingQueryKeys.allSkus,
  queryFn: getAllSkus,
});

export const allProjectsQueryOptions = queryOptions({
  queryKey: projectsQueryKeys.listProjects,
  queryFn: getAllProjects,
});

export const carbonSamplingPlanQueryOptions = (samplingPlanId: number) => {
  return queryOptions({
    retry: 1,
    queryKey: projectsQueryKeys.samplingPlan(samplingPlanId),
    queryFn: () => getSamplingPlanById(samplingPlanId),
  });
};

export const carbonProjectQueryOptions = (projectId: number) => {
  return queryOptions({
    queryKey: projectsQueryKeys.editProject(projectId),
    queryFn: () => getProjectById(projectId),
  });
};
