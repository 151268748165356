import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { putOperation } from 'store/operation/requests';
import { OperationType } from 'store/operation/types';
import { getAllRecommendationSets } from 'store/recommendationSets/thunks';

interface DefaultSetProps {
  operation: OperationType;
}
const DefaultSet = ({ operation }: DefaultSetProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [recSetId, setRecSetId] = useState(operation.recommendation_set_id);

  const { defaultSets } = useSelector((state: RootState) => ({
    defaultSets: state.recommendationSets.allSets,
  }));

  const fetchRecommendationSets = useCallback(() => {
    dispatch(getAllRecommendationSets());
  }, [dispatch]);

  useEffect(() => {
    fetchRecommendationSets();
  }, [fetchRecommendationSets]);

  const handleSelect = (value: string | null) => {
    const newRecId = value ? parseInt(value, 10) : null;
    setRecSetId(newRecId);
    putOperation({
      id: operation.id,
      recommendation_set_id: newRecId,
    });
  };

  const options = defaultSets.map((set) => ({
    label: set.name,
    value: String(set.id),
  }));

  return (
    <Select
      data={options}
      onChange={handleSelect}
      value={recSetId ? String(recSetId) : null}
      placeholder={getString('none', language)}
      maw="20rem"
    />
  );
};

export default DefaultSet;
