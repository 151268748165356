import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, SelectProps } from '@mantine/core';

import { getbatchAnalysisTypeOptions } from 'util/batches';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BatchFormType } from 'store/batches/types';

type BatchTypeSelectorPropsType = SelectProps & {
  batchType?: string;
  onValueChange: (
    attributeKey: keyof BatchFormType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
  showLabel?: boolean;
};

export const BatchTypeSelector = ({
  batchType,
  onValueChange,
  showLabel,
  ...props
}: BatchTypeSelectorPropsType) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const batchTypeOptions = getbatchAnalysisTypeOptions(language);

  useEffect(() => {
    if (batchType) {
      onValueChange('type', batchType);
    }
  }, [batchType]);

  const handleOnChange = (value: string | null) => {
    if (value) {
      navigate(`/lab/batches/create/${value}`);
    }
  };

  return (
    <Select
      onChange={handleOnChange}
      data={batchTypeOptions}
      value={batchType}
      label={showLabel && getString('plateType', language)}
      {...props}
    />
  );
};
