import React from 'react';
import { useSelector } from 'react-redux';
import { Center, Space, Stack, Text, Title } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';

import SampleSearchBar from '../common/SampleSearchBar';

import styles from './Container.module.css';

const SamplesOverviewContainer = () => {
  const language = useBroswerLanguage();

  const { isFetching, errorMessage } = useSelector((state: RootState) => ({
    isFetching: state.labSamples.isFetching,
    errorMessage: state.labSamples.errorMessage,
  }));

  return (
    <Center>
      <Stack align="center" gap="lg">
        <Space h="lg" />
        <Title order={2}>{`${getString('searchSampleMsg', language)}:`}</Title>
        <SampleSearchBar className={styles.Input} autofocus />
        {isFetching && <Text>{`${getString('searching', language)}...`}</Text>}
        {errorMessage && <Text c="red.8">{errorMessage}</Text>}
      </Stack>
    </Center>
  );
};

export default SamplesOverviewContainer;
