import { getString } from 'strings/translation';
import {
  DataReviewGroupedCollections,
  DataReviewGroupViewType,
  type DataReviewSort,
  type DataReviewStatus,
  EOCollectionFieldFeatCollection,
  GroupedCollectionsListPayload,
  GroupedCollectionsResponse,
} from 'store/eoCollections/types';

export const getDataReviewStatusOptions = (
  language: string,
): { label: string; value: DataReviewStatus }[] => [
  { label: getString('unconfirmed', language), value: 'pending' },
  { label: getString('approved', language), value: 'confirmed' },
  { label: getString('rejected', language), value: 'rejected' },
];

export const getDataReviewGroupByOptions = (
  language: string,
): { label: string; value: DataReviewGroupViewType }[] => [
  { label: getString('grower', language), value: 'operation' },
  { label: getString('dealer', language), value: 'agency' },
];

export const getDataReviewSortOptions = (
  language: string,
): { label: string; value: DataReviewSort }[] => [
  {
    label: `${getString('nameAtoZ', language)} (${getString('default', language).toLowerCase()})`,
    value: 'name-asc',
  },
  {
    label: getString('nameZtoA', language),
    value: 'name-desc',
  },
  {
    label: getString('newestFirst', language),
    value: 'date-desc',
  },
  {
    label: getString('oldestFirst', language),
    value: 'date-asc',
  },
];

export const getDataReviewSortingParams = (
  sortType: DataReviewSort,
): Pick<GroupedCollectionsListPayload, 'sortBy' | 'desc'> => {
  const descSortTypes: DataReviewSort[] = ['name-desc', 'date-desc'];
  const dateSortTypes: DataReviewSort[] = ['date-desc', 'date-asc'];
  const desc = descSortTypes.includes(sortType) ? 'True' : undefined;
  const sortBy = dateSortTypes.includes(sortType) ? 'date' : 'name';

  return { sortBy, desc };
};

export const sortDataReviewGroups = (
  sortBy: GroupedCollectionsListPayload['sortBy'],
  desc: GroupedCollectionsListPayload['desc'],
) => {
  return ([, a], [, b]) => {
    if (sortBy === 'date') {
      const aDate = new Date(a.mostRecentCollectionDate).getTime();
      const bDate = new Date(b.mostRecentCollectionDate).getTime();

      return desc ? aDate - bDate : bDate - aDate;
    }

    return desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
  };
};

/**
 * Munge the collections into groups by grower or dealer
 *
 * @param operations array of geojson features
 * @param groupBy grouping type
 * @param noDealerString text to use if no dealer is found
 * @returns object with grouped collections by operation (grower) or agency (dealer)
 */
export const prepGroupedCollections = (
  operations: GroupedCollectionsResponse['operations'],
  groupBy: DataReviewGroupViewType,
  noDealerString: string,
): DataReviewGroupedCollections => {
  return operations.reduce((groupedCollections, operationAndAgency) => {
    const { name: operationName, agencies, agency, id: operationId } = operationAndAgency;
    const groupObject = groupBy === 'operation' ? operationAndAgency : agency || operationAndAgency;
    const firstAgencyId = agencies[0]; // should always have at least one when groupBy = 'agency'
    const id = groupBy === 'operation' ? operationId : firstAgencyId;
    const fields = (groupedCollections[id]?.fields || []).concat(operationAndAgency.fields);
    const secondaryNames = groupedCollections[id]?.secondaryNames || [];

    const mostRecentCollectionDate: Date = fields.reduce(
      (mostRecent: Date, fieldFeatureCollection: EOCollectionFieldFeatCollection) => {
        const mostRecentDate =
          fieldFeatureCollection.features[0]?.properties.pending_eo_collections.reduce(
            (newestDate, collection) => {
              const collectionDate = new Date(collection.created_at);
              return collectionDate > newestDate ? collectionDate : newestDate;
            },
            new Date(0),
          );

        return mostRecentDate > mostRecent ? mostRecentDate : mostRecent;
      },
      new Date(0),
    );

    return {
      ...groupedCollections,
      [id]: {
        name: groupObject?.name || noDealerString,
        fields,
        mostRecentCollectionDate: mostRecentCollectionDate.toISOString(),
        secondaryNames: [
          ...secondaryNames,
          groupBy === 'operation' ? agency?.name || noDealerString : operationName,
        ],
      },
    };
  }, {} as DataReviewGroupedCollections);
};
