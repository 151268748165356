import React, { FunctionComponent } from 'react';
import { Button } from '@mantine/core';
import classNames from 'classnames';

import styles from './TabbedController.module.css';

type TabbedControllerProps = {
  activeIndex?: number;
  className?: string;
  onChange: Function;
  'data-test-id'?: string;
  options: { displayName: string; value?: string }[];
};

export const TabbedController: FunctionComponent<TabbedControllerProps> = ({
  activeIndex,
  className,
  onChange,
  options,
  'data-test-id': testId,
}) => (
  <Button.Group className={classNames(styles.Wrapper, className)} data-test-id={testId}>
    {options.map((option, index) => (
      <Button
        key={index}
        onClick={() => onChange(index)}
        variant={index === activeIndex ? 'filled' : 'outline'}
        id={`tab-${option.value || option.displayName}`}
      >
        {option.displayName}
      </Button>
    ))}
  </Button.Group>
);
