import React, { useEffect } from 'react';
import { Select, SelectProps } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getSoilProcessingOptions } from 'util/plates';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';

type SoilProcessingSelectorPropTypes = SelectProps & {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const SoilProcessingSelector = ({
  plate,
  onValueChange,
  ...props
}: SoilProcessingSelectorPropTypes) => {
  const language = useBroswerLanguage();
  const soilProcessingOptions = getSoilProcessingOptions(language);

  const handleOnChange = (value: string | null) => {
    if (value) {
      onValueChange('soil_processing_type', value);
    }
  };

  useEffect(() => {
    if (plate.soil_processing_type === undefined) {
      handleOnChange(soilProcessingOptions[0].value);
    }
  }, [plate]);

  return (
    <Select
      onChange={handleOnChange}
      data={soilProcessingOptions}
      value={plate.soil_processing_type}
      label={getString('soilProcessing', language)}
      {...props}
    />
  );
};
