import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { FiDelete } from 'react-icons/fi';
import { MdClear } from 'react-icons/md';
import { Group, Table, Text } from '@mantine/core';
import classNames from 'classnames';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import { formatDateMonthDayYear } from 'util/date';
import { getString } from 'strings/translation';
import { EOCollectionFeatureType } from 'store/eoCollections/types';

import DeleteCollectionModal from './DeleteCollectionModal';

import styles from './Container.module.css';

type Props = {
  collection: EOCollectionFeatureType;
  language: string;
  selectedCollectionId: number | null;
  isDeletable: boolean;
  onRowClick: (id: number | null) => void;
};

const DataReviewCollectionRow = ({
  collection,
  language,
  onRowClick,
  selectedCollectionId,
  isDeletable,
}: Props) => {
  const {
    created_at,
    id,
    name: collectionName,
    overlap_percentage,
    scanning_time_minutes,
    adcp_file_exists,
    emi_file_exists,
    assigned_to_scan,
  } = collection.properties;

  const [showModal, setShowModal] = useState(false);

  const scannedBy = assigned_to_scan
    ? `${assigned_to_scan.first_name} ${assigned_to_scan.last_name}`
    : getString('unavailable', language);

  const Row = (
    <Table.Tr
      onClick={() => onRowClick(id)}
      className={classNames(styles.CollectionRow, {
        [styles.CollectionRowSelected]: id === selectedCollectionId,
      })}
    >
      <Table.Td>
        <Group gap={0}>
          {isDeletable && (
            <FiDelete
              className={styles.DisableIcon}
              color="red"
              onClick={() => setShowModal(true)}
            />
          )}
          <Text fw={FONT_WEIGHT_BOLD} size="sm">
            {collectionName}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>{formatDateMonthDayYear(created_at, language)}</Table.Td>
      <Table.Td>{scannedBy}</Table.Td>
      <Table.Td>
        {`${Math.round(scanning_time_minutes)} ${getString('minutesAbbreviated', language)}`}
      </Table.Td>
      <Table.Td>{`${Math.round(overlap_percentage)}%`}</Table.Td>
      <Table.Td c={adcp_file_exists ? 'green' : 'red.8'}>
        {adcp_file_exists ? <FaCheck aria-label="yes" /> : <MdClear aria-label="no" />}
      </Table.Td>
      <Table.Td c={emi_file_exists ? 'green' : 'red.8'}>
        {emi_file_exists ? <FaCheck aria-label="yes" /> : <MdClear aria-label="no" />}
      </Table.Td>
    </Table.Tr>
  );

  return (
    <>
      {Row}
      <DeleteCollectionModal
        collectionId={id}
        language={language}
        onClose={() => setShowModal(false)}
        opened={showModal}
        onSuccess={() => onRowClick(null)}
      />
    </>
  );
};

export default DataReviewCollectionRow;
