import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';

import { RecommendationSetType } from './types';

export const requestAllRecommendationSets = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/recommendation_sets/`));

export const requestGetRecommendationSet = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/recommendation_set/${id}`));

export const requestCreateRecommendationSet = async (body: Partial<RecommendationSetType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/recommendation_set/`, { body }));

export const requestUpdateRecommendationSet = async (
  body: Partial<RecommendationSetType>,
  id: number,
) => handleJsonResponse(await requestPut(`${SERVICE_URL}/recommendation_set/${id}`, { body }));
