import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mantine/core';
import booleanContains from '@turf/boolean-contains';
import booleanIntersects from '@turf/boolean-intersects';
import center from '@turf/center';
import difference from '@turf/difference';
import {
  Feature,
  FeatureCollection,
  featureCollection,
  MultiPolygon,
  Point,
  Polygon,
} from '@turf/helpers';
import union from '@turf/union';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import mapboxgl, {
  EventData,
  GeoJSONSourceRaw,
  Layer,
  MapboxGeoJSONFeature,
  MapMouseEvent,
} from 'mapbox-gl';
import { v4 as uuid } from 'uuid';

import { OVERLAY_GREY } from 'constants/colors';
import {
  CIRCLE,
  FILL,
  LIGHT_GREY_LINE_PAINT,
  LINE,
  MODES,
  PARTIAL_ANALYTICS,
  POINT,
  POINT_STYLING,
  POLYGON,
  STATIC_OUTLINE,
  ZONE_SELECTED,
  ZONE_TYPES,
} from 'constants/mapbox';

import {
  clipZonesToLayer,
  getNewZonesFromDrag,
  processSingleZoneFeature,
  processZoneFeatureCollection,
  sortFeatures,
  splitMultipolygon,
  splitZone,
} from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { removeMapLayer } from 'util/mapbox';
import {
  getMapSelectedUuids,
  isCustomPointsOption,
  isGridsOption,
  isPointsOption,
} from 'util/samplePlan';
import { getString } from 'strings/translation';
import showToast, { ToastType } from 'actions/toastActions';
import { FieldType } from 'store/fields/types';
import { MapDrawReferenceType } from 'store/pricing/types';
import useMapboxGl from 'common/MapHooks';
import { type ViewPortProps } from 'common/Maps/types';

import DrawingTools from './DrawingToolsV3';
import {
  createPointFunction,
  handleDeleteFunction,
  handleSelectFunction,
  moveGridFunction,
  onClickPointFunction,
  resetDrawingModeFunction,
  rotateGridFunction,
  setLayerFunction,
} from './mapUtils';
import useAnalysisMapSetup from './useAnalysisMapSetup';

interface MapProps {
  field: FieldType;
  drawRef: MapDrawReferenceType;
}

type MouseRefType = (
  ev: MapMouseEvent & {
    features?: MapboxGeoJSONFeature[] | undefined;
  } & EventData,
) => void;

const AnalysisMapV3 = ({ field, drawRef }: MapProps) => {
  const language = useBroswerLanguage();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const mouseDownRef = useRef<MouseRefType>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });
  const [prevZones, setPrevZones] = useState(formValues.previewZones);
  const [drawAction, setDrawAction] = useState<string | null>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [mapCreationOption, setMapCreationOption] = useState(formValues.creationOption);
  const [mapDensity, setMapDensity] = useState<number>(formValues.density);
  const [mode, setMode] = useState(MODES.SELECT);

  const displayToast = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, () => {}, true);
  const { mapHasLoaded, initialViewport } = useAnalysisMapSetup(
    mapRef,
    wrapperRef,
    field,
    setMode,
    setViewport,
    viewport,
  );

  useEffect(() => {
    if (!formValues.zones) {
      drawRef.current?.deleteAll();
    }
  }, [formValues.zones, drawRef]);

  useEffect(() => {
    if (mapHasLoaded && formValues.zones) {
      drawRef.current?.deleteAll();
      drawRef.current?.add(formValues.zones);
    }
  }, [mapHasLoaded, drawRef, formValues.zones, formValues.zoneGeomType]);

  const clipNewZoneToMap = useCallback(
    (newFeature: Feature<Polygon>) => {
      const newUuid = newFeature.properties?.sample_uuid;
      const all: FeatureCollection<Polygon> = drawRef.current.getAll();
      const withUuids = all.features.filter(
        (feat) => feat.properties?.sample_uuid,
      ) as Feature<Polygon>[];
      // Preserve location of newFeature if it already exists
      const alreadyInFeatures = withUuids.some((feat) => feat.properties?.sample_uuid === newUuid);
      const allZoneFeatures = alreadyInFeatures ? withUuids : [...withUuids, newFeature];
      // Deletes contained zones, clips overlapping zones, splits multipolygons into polygons
      const overlappingZones = allZoneFeatures
        .map((existingFeat) => {
          if (
            existingFeat.properties?.sample_uuid !== newUuid &&
            booleanIntersects(existingFeat, newFeature)
          ) {
            if (booleanContains(newFeature, existingFeat)) {
              return [];
            }
            const diffBetween = difference(existingFeat, newFeature);
            if (diffBetween) {
              return splitMultipolygon(diffBetween) as Feature<Polygon>[];
            }
          }
          return [existingFeat];
        })
        .flat();
      // Push new zones without uuids to the end, preserving original order
      const sortedZones = sortFeatures(overlappingZones);
      const clippedZones = clipZonesToLayer(featureCollection(sortedZones), field);
      // Redo zone numbers in case a zone was removed
      // Add any new uuids to all packages, including uuid of newFeature unless updating an existing zone
      const processedZones = clippedZones.features.map((feat, index) => {
        const processed = processSingleZoneFeature(
          feat,
          index + 1,
          ZONE_TYPES.POLYGON,
        ) as Feature<Polygon>;
        return processed;
      }) as Feature<Polygon>[];
      form.setValues({
        zones: featureCollection(processedZones),
      });
    },
    [drawRef],
  );

  const createPolygon = useCallback(
    (e: FeatureCollection<Polygon>) => {
      try {
        const feat = e.features[0];
        if (!booleanIntersects(feat, field.features[0])) {
          drawRef.current.delete(feat.id);
        } else {
          const newFeat = {
            ...feat,
            properties: {
              sample_uuid: uuid(),
            },
          } as Feature<Polygon>;
          clipNewZoneToMap(newFeat);
        }
        setMode(MODES.SELECT);
      } catch (err) {
        displayToast(getString('errorWithFieldGeometryMsg', language), 'error', 7000);
      }
    },
    [drawRef, clipNewZoneToMap],
  );

  const moveGrid = useCallback(
    (e) => moveGridFunction(e, mapRef, formValues.previewZones),
    [mapRef, formValues.previewZones],
  );

  const onMouseUp = useCallback(
    (e) => {
      if (formValues.previewZones) {
        const newPreviewZoneFeatCollection = getNewZonesFromDrag(
          [e.lngLat.lng, e.lngLat.lat],
          formValues.previewZones,
        );
        if (newPreviewZoneFeatCollection && mouseDownRef.current) {
          form.setValues({
            previewZones: newPreviewZoneFeatCollection,
          });
          mapRef.current?.off('mousemove', moveGrid);
          mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
          setIsMouseDown(false);
        }
      }
    },
    // Circular dep issue, need to be able to shut off listener here
    [mapRef, moveGrid, formValues.previewZones, mouseDownRef],
  );

  const mouseDownHelper: any = useCallback(
    (e: any) => {
      e.preventDefault();
      mapRef.current?.on('mousemove', moveGrid);
      mapRef.current?.once('mouseup', onMouseUp);
    },
    [mapRef, moveGrid, onMouseUp],
  );

  const createPoint = useCallback(
    (e: FeatureCollection<Point>) => {
      createPointFunction(e, drawRef, 'zones', field, language, form.setValues);
    },
    [drawRef],
  );

  const updatePolygon = useCallback(
    (e: FeatureCollection<Polygon>) => {
      const feat = e.features[0];
      clipNewZoneToMap(feat);
    },
    [clipNewZoneToMap],
  );

  useEffect(() => {
    // Grids, turn off listener and turn back on when config changes
    if (
      formValues.density !== mapDensity ||
      formValues.creationOption !== mapCreationOption ||
      formValues.previewZones?.features !== prevZones?.features
    ) {
      if (mouseDownRef.current) {
        mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
      }
      setMapDensity(formValues.density);
      setMapCreationOption(formValues.creationOption);
      setIsMouseDown(false);
    }
  }, [
    mapRef,
    formValues.previewZones,
    formValues.density,
    mapDensity,
    mouseDownRef,
    moveGrid,
    formValues.creationOption,
    mapCreationOption,
    prevZones,
  ]);

  useEffect(() => {
    if (mapHasLoaded && mapRef.current) {
      if (isGridsOption(formValues.creationOption) && !isMouseDown) {
        setIsMouseDown(true);
        setPrevZones(formValues.previewZones);
        mapRef.current?.on('mousedown', 'preview-boundary', mouseDownHelper);
        // @ts-ignore
        mouseDownRef.current = mouseDownHelper;
      } else if (isPointsOption(formValues.creationOption)) {
        mapRef.current?.on('draw.create', createPoint);
        mapRef.current?.off('draw.create', createPolygon);
        mapRef.current?.off('draw.update', updatePolygon);
        if (isCustomPointsOption(formValues.creationOption)) {
          drawModeSetter(MODES.DRAW_POINT);
        }
      } else {
        mapRef.current?.off('draw.create', createPoint);
        mapRef.current?.on('draw.create', createPolygon);
        mapRef.current?.on('draw.update', updatePolygon);
      }
    }
  }, [
    mapRef,
    mapHasLoaded,
    formValues.zoneGeomType,
    createPoint,
    createPolygon,
    updatePolygon,
    mouseDownHelper,
    formValues.previewZones,
    isMouseDown,
    prevZones,
  ]);

  const resetStaticLine = useCallback(() => {
    if (mapRef.current?.getLayer(STATIC_OUTLINE)) {
      mapRef.current?.setPaintProperty(STATIC_OUTLINE, 'line-opacity', 1);
    }
  }, [mapRef]);

  const clickUnlockedHelper = useCallback(
    (e: any, geomType: typeof POLYGON | typeof POINT) =>
      onClickPointFunction(e, mapRef, drawRef, geomType),
    [drawRef, mapRef, formValues.zoneGeomType],
  );

  const onClickUnlockedZone = useCallback(
    (e: any) => clickUnlockedHelper(e, POLYGON),
    [clickUnlockedHelper],
  );

  const onClickUnlockedPoint = useCallback(
    (e: any) => clickUnlockedHelper(e, POINT),
    [clickUnlockedHelper],
  );

  const resetDrawingMode = useCallback(
    (locking: boolean) =>
      resetDrawingModeFunction(
        locking,
        mapRef,
        formValues.creationOption,
        resetStaticLine,
        setDrawAction,
        drawModeSetter,
        [onClickUnlockedZone, onClickUnlockedPoint],
      ),
    [mapRef, resetStaticLine, formValues.creationOption, onClickUnlockedZone, onClickUnlockedPoint],
  );

  const handleMerge = () => {
    const all: FeatureCollection<Polygon> = drawRef.current.getAll();
    const selectedUuids = getMapSelectedUuids(mapRef);
    const selectedZones = all.features.filter(
      (single) =>
        single.properties?.sample_uuid && selectedUuids.includes(single.properties.sample_uuid),
    );
    if (selectedZones.length > 1) {
      try {
        const selectedMerge = union(...selectedZones);
        const mergeZones = splitMultipolygon(selectedMerge as Feature<MultiPolygon | Polygon>);
        // Only merge if it results in a single polygon
        if (mergeZones.length === 1) {
          const filteredZones = all.features.filter(
            (feat) =>
              feat.properties?.sample_uuid && !selectedUuids.includes(feat.properties.sample_uuid),
          );
          const mUuid = uuid();
          const mergeZoneWithUuid = {
            ...mergeZones[0],
            properties: {
              sample_uuid: mUuid,
            },
          } as Feature<Polygon>;
          // Redo zone numbers to account for deleted zones
          const processedZones = processZoneFeatureCollection(
            featureCollection([...filteredZones, mergeZoneWithUuid]),
            POLYGON,
          );
          drawRef.current?.deleteAll();

          form.setValues({
            zones: processedZones as FeatureCollection<Polygon>,
          });
          removeMapLayer(mapRef, ZONE_SELECTED);
          resetDrawingMode(false);
          displayToast(getString('mergeZonesSuccess', language));
        } else {
          displayToast(getString('invalidZoneComboError', language), 'error');
        }
      } catch (e) {
        displayToast(getString('couldNotMergeSelectedError', language), 'error');
      }
    } else {
      displayToast(getString('selectZonesToMerge', language));
    }
  };

  const handleSplit = async () => {
    const all: FeatureCollection<Polygon> = drawRef.current.getAll();
    const selectedUuids = getMapSelectedUuids(mapRef);
    const selectedZones = all.features.filter(
      (single) =>
        single.properties?.sample_uuid && selectedUuids.includes(single.properties.sample_uuid),
    );

    if (selectedZones.length) {
      const splitCollections = await Promise.allSettled(
        selectedZones.map((zone) => splitZone(zone)),
      );
      if (splitCollections.some((promise) => promise.status === 'fulfilled')) {
        // Only remove uuids of zones that were successfully split
        const removeUuids: string[] = [];
        const newZones = selectedZones.reduce((list, zone, index) => {
          const splitPromise = splitCollections[index];

          if (splitPromise.status === 'fulfilled') {
            const splitZoner = splitPromise.value as FeatureCollection<Polygon>;

            const processedSplit = splitZoner.features.map((feature) =>
              processSingleZoneFeature(feature, 0, ZONE_TYPES.POLYGON),
            );

            const oldUuid = zone.properties?.sample_uuid;
            if (oldUuid) {
              removeUuids.push(oldUuid);
            }

            return list.concat(processedSplit as Feature<Polygon>[]);
          }

          return list;
        }, [] as Feature<Polygon>[][]);
        const filteredZones = all.features.filter(
          (feat) =>
            feat.properties?.sample_uuid && !removeUuids.includes(feat.properties.sample_uuid),
        );
        // Redo zone numbers to account for deleted zones
        const processedZones = processZoneFeatureCollection(
          featureCollection([...filteredZones, ...newZones.flat()]),
          POLYGON,
        );

        drawRef.current?.deleteAll();
        removeMapLayer(mapRef, ZONE_SELECTED);
        form.setValues({
          zones: processedZones as FeatureCollection<Polygon>,
        });
        resetDrawingMode(false);
        if (removeUuids.length === selectedUuids.length) {
          displayToast(getString('splitZonesSuccess', language));
        } else {
          displayToast(getString('splitEveryZoneError', language), 'error');
        }
      } else {
        displayToast(getString('splitEveryZoneError', language), 'error');
      }
    } else {
      displayToast(getString('selectZonesToMerge', language));
    }
  };

  useEffect(() => {
    if (formValues.disableMapTools) {
      resetDrawingMode(true);
    } else {
      resetDrawingMode(false);
    }
  }, [
    formValues.analysisMode,
    formValues.disableMapTools,
    formValues.zoneGeomType,
    mapRef,
    resetDrawingMode,
    formValues.creationOption,
    formValues.proPointCreationOption,
  ]);

  const setLayer = useCallback(
    (id: string, source: GeoJSONSourceRaw, layer: Partial<Layer>) =>
      setLayerFunction(mapRef, id, source, layer),
    [mapRef],
  );

  useEffect(() => {
    if (mapHasLoaded && mapRef) {
      if (formValues.previewZones) {
        removeMapLayer(mapRef, 'preview-boundary');
        removeMapLayer(mapRef, 'preview-outline');
        const source = {
          type: 'geojson',
          data: formValues.previewZones,
        } as GeoJSONSourceRaw;
        if (formValues.zoneGeomType === POINT) {
          setLayer('preview-boundary', source, {
            type: CIRCLE,
            paint: POINT_STYLING,
          });
          removeMapLayer(mapRef, 'preview-outline');
        } else {
          setLayer('preview-boundary', source, {
            type: FILL,
            paint: { 'fill-color': OVERLAY_GREY, 'fill-opacity': 0.4 },
          });
          setLayer('preview-outline', source, {
            type: LINE,
            paint: LIGHT_GREY_LINE_PAINT,
          });
        }
      } else {
        removeMapLayer(mapRef, 'preview-boundary');
        removeMapLayer(mapRef, 'preview-outline');
      }
    }
  }, [setLayer, mapRef, mapHasLoaded, formValues.previewZones, formValues.zoneGeomType]);

  useEffect(() => {
    if (mapHasLoaded && mapRef && formValues.disableMapTools) {
      removeMapLayer(mapRef, PARTIAL_ANALYTICS);
      resetStaticLine();
    }
  }, [mapRef, mapHasLoaded, resetStaticLine, formValues.analysisMode, formValues.disableMapTools]);

  const drawModeSetter = (drawType: any) => {
    setMode(drawType);
    if (drawRef.current) {
      drawRef.current.changeMode(drawType);
    }
  };

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  return (
    <Box h="100%" pos="relative" ref={wrapperRef} flex={1}>
      <Box ref={mapContainerRef} h="100%" w="100%" />
      <Button variant="white" onClick={recenterMap} bottom={10} pos="absolute" right="3rem">
        {getString('recenter', language)}
      </Button>
      <DrawingTools
        drawModeSetter={drawModeSetter}
        mode={mode}
        drawAction={drawAction}
        handleMerge={handleMerge}
        handleSplit={handleSplit}
        handleDelete={() =>
          handleDeleteFunction(
            mapRef,
            formValues.zones,
            'zones',
            language,
            form.setValues,
            resetDrawingMode,
          )
        }
        handleSelect={(action, start) =>
          handleSelectFunction(
            mapRef,
            drawRef,
            action,
            start,
            setDrawAction,
            formValues.zoneGeomType === POINT ? onClickUnlockedPoint : onClickUnlockedZone,
            resetDrawingMode,
          )
        }
        rotateGrid={(isClockwise) =>
          rotateGridFunction(
            mapRef,
            mouseDownRef,
            formValues.previewZones,
            'previewZones',
            isClockwise,
            formValues.gridAngle,
            form.setValues,
            setIsMouseDown,
          )
        }
      />
    </Box>
  );
};
export default AnalysisMapV3;
