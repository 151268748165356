import React from 'react';

import { useTranslation } from 'strings/translation';
import { EditIcon, UploadIcon } from 'common';

import IconButton from './IconButton/IconButton';

import styles from './OperationWithNoFields.module.css';

interface Props {
  operationId: string | undefined;
}

const OperationWithNoFields = ({ operationId }: Props) => {
  return (
    <div className={styles.Wrapper}>
      <h1 data-test-id="map-fields-heading" className={styles.Title}>
        {useTranslation('mapFieldsToOrder')}
      </h1>
      <h3>{useTranslation('setupASamplePlan')}</h3>
      <div className={styles.Buttons}>
        <div data-test-id="draw-boundary" className={styles.Button}>
          <IconButton
            icon={<EditIcon />}
            text={useTranslation('drawBoundariesOnMap')}
            to={`/field-boundary/${operationId}/new`}
          />
        </div>
        <div data-test-id="upload-shape-files" className={styles.Button}>
          <IconButton
            icon={<UploadIcon />}
            text={useTranslation('uploadShapefilesData')}
            to={`/operations/${operationId}/ingest_history`}
          />
        </div>
      </div>
    </div>
  );
};

export default OperationWithNoFields;
