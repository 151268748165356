import React, { MouseEvent, useCallback, useState } from 'react';
import { Button, Group, Modal, Space, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString, useTranslation } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestMarkAsDelivered } from 'store/operation/requests';
import { OperationType } from 'store/operation/types';

interface DeliveryCompleteModalProps {
  hideModal: (event: MouseEvent) => void;
  onSuccess: () => void;
  operation: OperationType;
  open: boolean;
}

const DeliveryCompleteModal = ({
  hideModal,
  operation,
  onSuccess,
  open,
}: DeliveryCompleteModalProps) => {
  const language = useBroswerLanguage();
  const [isLoading, toggleLoading] = useState(false);
  const handleHide = useCallback(() => {
    if (!isLoading) {
      hideModal({} as MouseEvent);
    }
  }, [hideModal, isLoading]);

  const handleMark = async () => {
    try {
      toggleLoading(true);
      await requestMarkAsDelivered(operation.id);
      onSuccess();
    } catch (e) {
      showToast(getString('errorMarkingPlanDelivered', language), 'error');
      hideModal({} as MouseEvent);
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      opened={open}
      onClose={handleHide}
      title={`${getString('deliveryComplete', language)} ${operation.name}?`}
      centered
    >
      <Text>{getString('deliverCompletePar', language)}</Text>
      <Space h="md" />
      <Group justify="flex-end">
        <Button disabled={isLoading} onClick={hideModal} variant="outline">
          {useTranslation('cancel')}
        </Button>
        <Button disabled={isLoading} onClick={handleMark}>
          {isLoading
            ? `${getString('loading', language)}...`
            : getString('markAsDelivered', language)}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeliveryCompleteModal;
