import {
  AMMONIUM_ACETATE,
  AMPLICON,
  GENOMIC,
  M3,
  NITRATE,
  OM,
  PH,
  PHOSPHATE,
  SOIL_TEXTURE,
  TOTAL_CARBON,
  TOTAL_INORGANIC_CARBON,
} from './analysis';
import {
  AA_ANALYSIS,
  M3_ANALYSIS,
  NITRATE_ANALYSIS,
  OM_ANALYSIS,
  PH_ANALYSIS,
  PHOSPHATE_ANALYSIS,
  SHOTGUN as SHOTGUN_PLATE,
  ST_ANALYSIS,
  TC_ANALYSIS,
  TIC_ANALYSIS,
} from './plates';

export const batchTypeList = [
  GENOMIC,
  M3,
  PH,
  NITRATE,
  AMMONIUM_ACETATE,
  OM,
  PHOSPHATE,
  AMPLICON,
  SOIL_TEXTURE,
  TOTAL_CARBON,
  TOTAL_INORGANIC_CARBON,
];
export const genomicBatchTypes = [GENOMIC];
export const chemistryBatchTypes = [
  M3,
  PH,
  NITRATE,
  AMMONIUM_ACETATE,
  OM,
  PHOSPHATE,
  SOIL_TEXTURE,
  TOTAL_CARBON,
  TOTAL_INORGANIC_CARBON,
];

export const numPlatesPerBatchType = {
  [GENOMIC as string]: 16,
  [NITRATE as string]: 15,
  [M3 as string]: 15,
  [PH as string]: 15,
  [AMMONIUM_ACETATE as string]: 15,
  [OM as string]: 15,
  [AMPLICON as string]: 16,
  [PHOSPHATE as string]: 15,
  [SOIL_TEXTURE as string]: 15,
  [TOTAL_CARBON as string]: 15,
  [TOTAL_INORGANIC_CARBON as string]: 15,
};

export const plateTypesForBatch = {
  [GENOMIC as string]: [SHOTGUN_PLATE],
  [NITRATE as string]: [NITRATE_ANALYSIS],
  [M3 as string]: [M3_ANALYSIS],
  [PH as string]: [PH_ANALYSIS],
  [AMMONIUM_ACETATE as string]: [AA_ANALYSIS],
  [OM as string]: [OM_ANALYSIS],
  [PHOSPHATE as string]: [PHOSPHATE_ANALYSIS],
  [SOIL_TEXTURE as string]: [ST_ANALYSIS],
  [TOTAL_CARBON as string]: [TC_ANALYSIS],
  [TOTAL_INORGANIC_CARBON as string]: [TIC_ANALYSIS],
};

export const batchTypesForPlate = {
  [SHOTGUN_PLATE as string]: GENOMIC,
  [NITRATE_ANALYSIS as string]: NITRATE,
  [M3_ANALYSIS as string]: M3,
  [PH_ANALYSIS as string]: PH,
  [AA_ANALYSIS as string]: AMMONIUM_ACETATE,
  [OM_ANALYSIS as string]: OM,
  [PHOSPHATE_ANALYSIS as string]: PHOSPHATE,
  [ST_ANALYSIS as string]: SOIL_TEXTURE,
  [TC_ANALYSIS as string]: TOTAL_CARBON,
  [TIC_ANALYSIS as string]: TOTAL_INORGANIC_CARBON,
};

export const EMPTY = 'empty';
export const PARTIAL = 'partial';
export const FULL = 'full';
export const RUN_PLANNED = 'run_planned';
export const RESULTS_UPLOADED = 'results_uploaded';
export const COMPLETED = 'completed';
export const DISCARDED = 'discarded';
export const STORAGE = 'storage';

export const batchStatusList = [
  RESULTS_UPLOADED,
  RUN_PLANNED,
  FULL,
  PARTIAL,
  EMPTY,
  COMPLETED,
  STORAGE,
  DISCARDED,
];

export const batchStatusDisplayNames = {
  [EMPTY as string]: 'empty',
  [PARTIAL as string]: 'partiallyFilled',
  [FULL as string]: 'full',
  [RUN_PLANNED as string]: 'runPlanned',
  [RESULTS_UPLOADED as string]: 'resultsUploaded',
  [COMPLETED as string]: 'completed',
  [DISCARDED as string]: 'discarded',
  [STORAGE as string]: 'storage',
};

export const runSheetFormat = {
  ICP: 'icp',
  FIA: 'fia',
};
