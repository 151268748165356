export const PARENT_AGENCY = 'parent_agency';
export const AGENCY = 'agency';
export const LOCATION = 'location';

export const agencyDisplayNames = {
  [PARENT_AGENCY as string]: 'Parent Agency',
  [AGENCY as string]: 'Agency',
  [LOCATION as string]: 'Location',
};

export const BLUEPRINT = 'blueprint';
export const LAVORO = 'lavoro';
export const REDSTAR = 'redstar';
export const EARTH_OPTICS = 'earthoptics';
export const COMPANY_NAME = 'EarthOptics';

export const regexEarthOptics = new RegExp(EARTH_OPTICS, 'gi');

export const whiteLabels = [BLUEPRINT, LAVORO, REDSTAR];

export const SELF_SAMPLING = 'self_sampling';
export const SELF_SCANNING = 'self_scanning';
