import center from '@turf/center';
import circle from '@turf/circle';

import { genomicPlateTypes, HOMOGENIZATION } from 'constants/plates';
import { ROOTWORM_ID, SHOTGUN_ROOTWORM_ID } from 'constants/results';
import { FIELD_DISPLAY_POINT_RADIUS } from 'constants/samplePlanning';
import {
  ISOLATES,
  PROCESS,
  replicaTypeDisplayNames,
  RERUN,
  RESIDUE,
  RHIZOSPHERE,
  ROOT_BALL,
  ROOT_TISSUE,
  sampleTypeDisplayNames,
  SOIL,
  TECHNICAL,
} from 'constants/samples';

import { getString } from 'strings/translation';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { FieldType, SampleType, SamplingPlanType } from 'store/fields/types';
import { LabSampleType } from 'store/labSamples/types';
import { SampleFeatureType } from 'store/samples/types';

import { sortByCreatedAt } from './date';
import { SampleGeoJSON } from './generalTypes';
import { isNumber } from './numUtils';
import {
  checkEnlargePointZone,
  getAnalyticFillColor,
  getSampleAnalyticQuantity,
  getSampleAnalyticRiskLevel,
  sortByRiskColor,
} from './results';

export const parseSample = (sample: LabSampleType) => {
  return {
    ...sample,
    field_name: sample.field?.name,
    farm_name: sample.field?.farm_name,
    operation_id: sample.operation?.id,
    operation_name: sample.operation?.name,
  };
};

export const sampleIsPrimary = (sample: SampleType) => sample.primary;
export const sampleIsActive = (sample: SampleType) =>
  sample.status !== 'cancelled' && sample.status !== "won't sample";

export const findPrimarySample = (samples: LabSampleType[]) => {
  const primaryOnly = samples.filter(sampleIsPrimary);
  return primaryOnly?.[0];
};

export const sortPrimaryFirst = (samples: LabSampleType[]) =>
  sortByCreatedAt(samples).sort((a) => (sampleIsPrimary(a) ? -1 : 1));

export const getFirstSampleReceivedDate = (samples: SampleFeatureType[]): string => {
  const sortedSamples = samples.sort((a, b) => {
    if (a.properties.received_at && b.properties.received_at) {
      return (
        new Date(a.properties.received_at).getTime() - new Date(b.properties.received_at).getTime()
      );
    }

    return 0;
  });

  return sortedSamples[0]?.properties.received_at || '';
};

export const getLastSampleProcessedDate = (samples: SampleFeatureType[]): string => {
  const sortedSamples = samples.sort((a, b) => {
    if (a.properties.processed_at && b.properties.processed_at) {
      return (
        new Date(b.properties.processed_at).getTime() -
        new Date(a.properties.processed_at).getTime()
      );
    }

    return 0;
  });

  return sortedSamples[0]?.properties.processed_at || '';
};

export const filterSamplesWithPlan = (
  samples: SampleGeoJSON[],
  samplingPlan?: SamplingPlanType | null,
) => {
  if (!(samples && samplingPlan)) {
    return [];
  }
  const hasAnyAnalysis = (sample: SampleGeoJSON) => {
    const { nitrate_panel, nutrient_panel, pressure_panel, performance_panel } = sample.properties;
    return [nitrate_panel, nutrient_panel, pressure_panel, performance_panel].some(isNumber);
  };
  const planSamples = samples.filter(
    (sample) => sample.properties.sampling_plan_id === samplingPlan.id && hasAnyAnalysis(sample),
  );
  return planSamples.map((sample) => ({
    ...sample,
    geometry: sample.geometry?.geometries?.find(
      (geom) => geom.type === 'Polygon' || geom.type === 'MultiPolygon' || null,
    ),
  }));
};

export const filterSamplesAndAddRisk = (
  samples: SampleGeoJSON[],
  samplingPlan: SamplingPlanType | undefined,
  analytic: AnalyticType,
  field: FieldType,
) =>
  analytic && samplingPlan && samples
    ? sortByRiskColor(
        filterSamplesWithPlan(samples, samplingPlan)
          .filter((sample) => sample.geometry !== null)
          .map((val) => ({
            ...val,
            geometry: checkEnlargePointZone(val as SampleGeoJSON, samplingPlan, 5, field)
              ? // @ts-ignore
                circle(center(val.geometry), FIELD_DISPLAY_POINT_RADIUS, {
                  units: 'meters',
                  steps: 100,
                }).geometry
              : val.geometry,
            properties: {
              ...val.properties,
              'fill-color': getAnalyticFillColor(val.properties, analytic, samplingPlan),
              quantity: getSampleAnalyticQuantity(val.properties, analytic),
              riskLevelForAnalytic: getSampleAnalyticRiskLevel(val.properties, analytic),
            },
          })) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[],
      )
    : [];

export const labSampleIsPlated = (sample: LabSampleType, plateType: string) => {
  const checkType = genomicPlateTypes.includes(plateType) ? HOMOGENIZATION : plateType;
  return Boolean(sample.plates_summary?.[checkType]?.length);
};

export const getSampleTypeOptions = (language: string) => [
  {
    id: 0,
    value: SOIL,
    displayName: getString(sampleTypeDisplayNames[SOIL], language),
  },
  {
    id: 1,
    value: RESIDUE,
    displayName: getString(sampleTypeDisplayNames[RESIDUE], language),
  },
  {
    id: 2,
    value: ROOT_BALL,
    displayName: getString(sampleTypeDisplayNames[ROOT_BALL], language),
  },
  {
    id: 3,
    value: RHIZOSPHERE,
    displayName: getString(sampleTypeDisplayNames[RHIZOSPHERE], language),
  },
  {
    id: 4,
    value: ROOT_TISSUE,
    displayName: getString(sampleTypeDisplayNames[ROOT_TISSUE], language),
  },
  {
    id: 5,
    value: ISOLATES,
    displayName: getString(sampleTypeDisplayNames[ISOLATES], language),
  },
];

export const getReplicaTypeOptions = (language: string) => [
  {
    id: 1,
    value: RERUN,
    displayName: getString(replicaTypeDisplayNames[RERUN], language),
  },
  {
    id: 2,
    value: TECHNICAL,
    displayName: getString(replicaTypeDisplayNames[TECHNICAL], language),
  },
  {
    id: 3,
    value: PROCESS,
    displayName: getString(replicaTypeDisplayNames[PROCESS], language),
  },
];

export const swapSecondaryAnalytic = (
  analytic: AnalyticType,
  analticObj: { [key: number]: SingleAnalyticType },
) => {
  if (!analticObj) {
    return;
  }
  if (analytic.id === SHOTGUN_ROOTWORM_ID && analticObj) {
    analticObj[SHOTGUN_ROOTWORM_ID] = isNumber(analticObj?.[SHOTGUN_ROOTWORM_ID]?.quantity)
      ? analticObj[SHOTGUN_ROOTWORM_ID]
      : analticObj[ROOTWORM_ID];
    return;
  }
  const analyticResult = analticObj?.[analytic.id];
  const secodaryAnalyticId = analytic.display_config?.secondary_analytic_id;
  const secondaryResult = secodaryAnalyticId ? analticObj[secodaryAnalyticId] : null;
  if (
    !isNumber(analyticResult?.quantity) &&
    secondaryResult &&
    isNumber(secondaryResult?.quantity)
  ) {
    analticObj[analytic.id] = secondaryResult;
  }
};

export const updateSampleAnalyticResults = (
  samples: SampleFeatureType[],
  analytics: AnalyticType[],
) => {
  const analyticsWithSecondary = analytics.filter((a) => a.display_config?.secondary_analytic_id);
  samples.forEach((sample) => {
    analyticsWithSecondary.forEach((analytic) => {
      if (analytic.display_config?.secondary_analytic_id) {
        const categoryResults = sample?.properties?.analytics?.[analytic.category];
        if (
          categoryResults?.[analytic.id] ||
          categoryResults?.[analytic.display_config?.secondary_analytic_id]
        ) {
          swapSecondaryAnalytic(analytic, categoryResults);
        }
      }
    });
  });

  return samples;
};

export const updatePlanAnalyticResults = (field: FieldType, analytics: AnalyticType[]) => {
  const plans = field?.features[0]?.properties?.sampling_plans;
  if (!plans?.length) {
    return field;
  }
  const analyticsWithSecondary = analytics.filter((a) => a.display_config?.secondary_analytic_id);

  const newField = { ...field };
  field.features.forEach((feat, featIdx) => {
    feat.properties.sampling_plans.forEach((plan, planIdx) => {
      analyticsWithSecondary.forEach((analytic) => {
        if (analytic.display_config?.secondary_analytic_id) {
          const categoryResults = plan?.analytics?.[analytic.category];
          if (
            categoryResults?.[analytic.id] ||
            categoryResults?.[analytic.display_config?.secondary_analytic_id]
          ) {
            swapSecondaryAnalytic(analytic, categoryResults);
            newField.features[featIdx].properties.sampling_plans[planIdx].analytics[
              analytic.category
            ] = categoryResults;
          }
        }
      });
    });
  });
  return newField;
};
