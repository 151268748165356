import React from 'react';
import { useSelector } from 'react-redux';
import { Stack, Table, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Agency } from 'store/agencies/types';
import { LoadingRows } from 'common';

import AgencyRow from './AgencyRow';

type AgenciesTablePropsType = {
  agencies: Agency[];
};

const AgenciesTable = ({ agencies }: AgenciesTablePropsType) => {
  const language = useBroswerLanguage();

  const { isFetchingAgencies, isFetchingMyAgencies } = useSelector((state: RootState) => ({
    isFetchingAgencies: state.agencies.isFetching,
    isFetchingMyAgencies: state.agencies.isFetchingMyAgencies,
  }));

  const TableHeadCol = (nameStr: string) => (
    <Table.Th key={nameStr}>
      <Stack h={40} gap="xs" align="flex-start">
        <Text size="sm" fw={700}>
          {getString(nameStr, language)}
        </Text>
      </Stack>
    </Table.Th>
  );

  return (
    <Table stickyHeader highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Stack h={40} gap="xs" align="flex-start">
              <Text size="sm" fw={700}>
                {getString('agencyName', language)}
              </Text>
              <Text size="xs" fs="italic">
                {getString('parentAgency', language)}
              </Text>
            </Stack>
          </Table.Th>
          {['agents', 'primaryAgent', 'phone', 'email'].map((nameStr) => TableHeadCol(nameStr))}
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {isFetchingAgencies || isFetchingMyAgencies ? (
          <LoadingRows rowSpan={10} colSpan={6} />
        ) : (
          agencies.map((agency) => <AgencyRow key={agency.id} agency={agency} />)
        )}
      </Table.Tbody>
    </Table>
  );
};

export default AgenciesTable;
