import { useState } from 'react';

import HeaderLogo from 'images/earthoptics-logo.svg';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { sendResetEmail } from 'requesters/authenticationRequester';
import { Button, Input } from 'common';

import styles from '../Shared/Container.module.css';

const ForgotPasswordContainer = () => {
  const language = useBroswerLanguage();
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = () => {
    setIsFetching(true);
    sendResetEmail({ email })
      .then(() => {
        setHasSubmitted(true);
        setErrorText(null);
      })
      .catch(() => setErrorText(getString('requestFailed', language)));
  };
  const handleInputChange = (e) => setEmail(e.target.value);

  const emailForm = (
    <>
      <Input
        className={styles.EmailInput}
        onChange={handleInputChange}
        placeholder={getString('emailAddress', language)}
        value={email}
      />
      <span className={styles.SmallBlurb}>{getString('willReceiveLinkMsg', language)}</span>
      {errorText && <span className={styles.Error}>{errorText}</span>}
      <Button disabled={!email.length || isFetching} onClick={handleSubmit} primary>
        {getString('sendRequestInstructions', language)}
      </Button>
    </>
  );
  const confirmation = 'Check your inbox for a link to reset your password.';

  return (
    <div className={styles.Wrapper}>
      <img alt="EarthOptics Logo" className={styles.Logo} src={HeaderLogo} />
      <h1>{getString('forgotPassword', language)}</h1>
      {hasSubmitted ? confirmation : emailForm}
    </div>
  );
};

export default ForgotPasswordContainer;
