import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UI_COLORS } from 'constants/colors';

import { dataReviewQueryKeys } from 'util/queryKeys';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { unapproveSamplingPlanCollections } from 'store/eoCollections/requests';
import { SuperAdminAccess } from 'common';

type Props = {
  disabled: boolean;
  language: string;
  samplingPlanId: number;
};

const UnapproveCollectionsModal = ({ language, samplingPlanId, disabled }: Props) => {
  const queryClient = useQueryClient();
  const [modalVisible, { open, close }] = useDisclosure(false);

  const unapproveCollectionsMutation = useMutation({
    mutationFn: () => unapproveSamplingPlanCollections(samplingPlanId),
    onSuccess: () => {
      showToast(getString('collectionsUnapprovedMsg', language), 'success');

      queryClient.invalidateQueries({
        queryKey: dataReviewQueryKeys.groupedCollections(),
      });
    },
  });

  return (
    <>
      <SuperAdminAccess>
        <Button
          color={UI_COLORS.error}
          component="span"
          disabled={disabled}
          role="button"
          size="compact-xs"
          variant="outline"
          onClick={(evt) => {
            evt.stopPropagation();
            open();
          }}
        >
          {getString('unapprove', language)}
        </Button>
      </SuperAdminAccess>
      <Modal
        opened={modalVisible}
        onClose={close}
        centered
        size="lg"
        title={getString('unapproveCollections', language)}
      >
        <Stack px="xl" ta="center" gap="xl">
          <Text mt="sm">{getString('confirmUnapproveCollections', language)}</Text>
          <Group justify="center">
            <Button size="md" onClick={close} variant="outline">
              {getString('cancel', language)}
            </Button>
            <Button
              size="md"
              loading={unapproveCollectionsMutation.isPending}
              onClick={() => unapproveCollectionsMutation.mutate()}
              color={UI_COLORS.error}
            >
              {getString('confirm', language)}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default UnapproveCollectionsModal;
