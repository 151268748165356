import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Center, Loader, Stack, Title, useCombobox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { PATTERN_PRODUCT_CROP_PROTECTION, SEED } from 'constants/cropPlan';
import { BIOFERTILITY, CROP_PROTECTION } from 'constants/results';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast, { ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import { CatalogType } from 'store/catalogs/types';
import { getPestGroups } from 'store/cropPlans/thunks';
import { Header } from 'common/Components/Mantine/Header';

import CropProtectionCatalog from './CropProtectionCatalog';
import ProductSearch from './ProductSearch';
import SeedCatalog from './SeedCatalog';

type ProductListPropsType = {
  catalog: Partial<CatalogType>;
  onError: (msg: string) => void;
  isSaving: boolean;
  toggleIsSaving: (saving: boolean) => void;
};

const ProductList = ({ onError, isSaving, catalog, toggleIsSaving }: ProductListPropsType) => {
  const language = useBroswerLanguage();
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { analytics, pestGroups } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    pestGroups: state.cropPlanning.pestGroups,
  }));

  useEffect(() => {
    const populatePestGroups = async () => {
      try {
        dispatch(getPestGroups());
      } catch (e) {
        onError('Failed to fetch pest groupings.');
      }
    };
    if (!pestGroups) {
      populatePestGroups();
    }
  }, [pestGroups]);

  const setToastMessage = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  if (!pestGroups) {
    return (
      <Center h="20vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <ProductSearch
        closeModal={close}
        isSaving={isSaving}
        onError={onError}
        modalOpened={opened}
        openModal={open}
        catalog={catalog}
        toggleIsSaving={toggleIsSaving}
      />
      <Stack mb="5rem">
        <Header title={getString('allInputs', language)} divider="sm" order={3} />
        <Accordion multiple defaultValue={[SEED, PATTERN_PRODUCT_CROP_PROTECTION]}>
          <Accordion.Item value={SEED}>
            <Accordion.Control>
              <Title order={3}>{getString('seedCatalog', language)}</Title>
            </Accordion.Control>
            <Accordion.Panel>
              <SeedCatalog
                analytics={analytics}
                pestGroups={pestGroups}
                catalog={catalog}
                seeds={Object.values(catalog.seeds || {})}
                setToastMessage={setToastMessage}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value={PATTERN_PRODUCT_CROP_PROTECTION}>
            <Accordion.Control>
              <Title
                order={3}
              >{`${getString(CROP_PROTECTION, language)} & ${getString(BIOFERTILITY, language)}`}</Title>
            </Accordion.Control>
            <Accordion.Panel>
              <CropProtectionCatalog
                analytics={analytics}
                catalog={catalog}
                products={Object.values(catalog.products || {})}
                pestGroups={pestGroups}
                setToastMessage={setToastMessage}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default ProductList;
