import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DateAttributesType } from './generalTypes';

dayjs.extend(utc);
dayjs.extend(timezone);

const MILLISECONDS_IN_DAY = 1000 * 3600 * 24;

export const convertUtcToLocale = (datestring: string) => {
  const lastCharIsZ = datestring.slice(-1) === 'Z';
  return new Date(lastCharIsZ ? datestring : `${datestring}Z`);
};

export const dayJsConvertUtcToLocale = (dateString: string, format: string) =>
  dayjs.utc(dateString).local().tz(dayjs.tz.guess()).format(format);

export const localDate = (dateString: string) => {
  return new Date(convertUtcToLocale(dateString));
};

const getMonthString = (month: number) => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      throw new Error('Invalid date');
  }
};

export const formatCalendarMonthDayYear = (dateString: string) => {
  const date = convertUtcToLocale(dateString);
  return new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date);
};

/**
 * Takes a Date and returns localized calendar date(ie: mm/dd/yy) and 24h timestamp(hh:mm).
 * IE: 04/10/20, 19:45
 * @param {string} date UTC Format
 */
export const formatToDateHourFormat = (dateString: string) => {
  const date = convertUtcToLocale(dateString);
  return new Intl.DateTimeFormat('default', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(date);
};

export const formatToDdMmYyyy = (dateString: string, separateWithHyphens?: boolean) => {
  const date = convertUtcToLocale(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return separateWithHyphens ? `${day}-${month}-${year}` : `${day}${month}${year}`;
};

export const formatToNumericDate = (dateString: string) => {
  const date = convertUtcToLocale(dateString);
  return new Intl.DateTimeFormat('default', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  }).format(date);
};

export const sortByDateAttribute = <T extends Pick<Partial<T>, DateAttributesType>>(
  list: Array<T>,
  attribute: DateAttributesType,
) => {
  return list.concat().sort((a, b) => {
    const dateA = a[attribute];
    const dateB = b[attribute];
    if (!dateA || !dateB) {
      return 0;
    }
    const date1 = new Date(dateA as string).getTime();
    const date2 = new Date(dateB as string).getTime();
    return date2 - date1;
  });
};

export const formatDateMonthDayYear = (dateString: string, language: string) => {
  return new Date(dateString).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const sortByCreatedAt = <T extends { created_at: string | null }>(
  list: T[],
  reverse?: boolean,
) =>
  list.concat().sort((a, b) => {
    const date1 = a.created_at ? new Date(a.created_at).getTime() : 0;
    const date2 = b.created_at ? new Date(b.created_at).getTime() : 0;
    return !reverse ? date2 - date1 : date1 - date2;
  });

export const areWithinNDays = <T extends { created_at: string }>(
  itemA: T,
  itemB: T,
  n: number,
): boolean => {
  const date1 = new Date(itemA.created_at).getTime();
  const date2 = new Date(itemB.created_at).getTime();
  const timeDifferenceInDays = Math.abs(date1 - date2) / MILLISECONDS_IN_DAY;

  return timeDifferenceInDays <= n;
};

export const defaultPlanDate = () => {
  const d = new Date();
  const month = getMonthString(d.getMonth());

  return `${month} ${d.getDate()} ${d.getFullYear()}`;
};

export const getYearOptions = (currentYear: number, yearsBack: number = 4) => {
  const yearList = Array.from(
    { length: currentYear - (currentYear - yearsBack) },
    (_, index) => currentYear - index,
  );

  return yearList.map((year, index) => ({
    id: index,
    value: String(year),
    label: String(year),
  }));
};

export const formatToYear = (dateString: string) => {
  const date = convertUtcToLocale(dateString);
  return new Intl.DateTimeFormat('default', {
    year: 'numeric',
  }).format(date);
};

export const sortByStartTime = <T extends { start_time: string | null }>(
  list: T[],
  reverse?: boolean,
) =>
  list.concat().sort((a, b) => {
    const date1 = a.start_time ? new Date(a.start_time).getTime() : 0;
    const date2 = b.start_time ? new Date(b.start_time).getTime() : 0;
    return reverse ? date1 - date2 : date2 - date1;
  });

export const dateIsBeforeTodayPlusNDays = (date: Date, n: number) => {
  const today = new Date();
  const todayPlusNDays = new Date(today.getTime() + n * MILLISECONDS_IN_DAY);
  return date < todayPlusNDays;
};
