import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAcreageUnitFromLang } from 'util/units';
import { RootState } from 'store';
import { getOperations } from 'store/operation/thunks';
import {
  userIsAdmin,
  userIsAgencyAdmin,
  userIsAgent,
  userIsLab,
  userIsSampler,
  userIsSuperAdmin,
} from 'store/user/selectors';

const OperationsDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const {
    isAdmin,
    isAgencyUser,
    isSuperAdmin,
    isLabOperator,
    isSampler,
    hasFetched,
    operationsCount,
    activeOperationId,
  } = useSelector((state: RootState) => ({
    isAdmin: userIsAdmin(state),
    isAgencyUser: userIsAgencyAdmin(state) || userIsAgent(state),
    isSuperAdmin: userIsSuperAdmin(state),
    isLabOperator: userIsLab(state),
    isSampler: userIsSampler(state),
    hasFetched: state.operations.hasFetched,
    operationsCount:
      state.operations.summary.items.length || Object.keys(state.operations.operationsById).length,
    activeOperationId: state.operations.activeOperationId,
  }));

  const [hasLoaded, setHasLoaded] = useState(false);

  const redirect = useCallback(() => {
    if (isAdmin || isSuperAdmin) {
      if (operationsCount) {
        navigate('/operations');
        return;
      }
      navigate('/operations/create');
      return;
    }

    if (isLabOperator) {
      navigate('/lab');
      return;
    }

    if (isSampler) {
      const currentYear = new Date().getFullYear();
      navigate(`/sampling-history/${currentYear}`);
      return;
    }

    if (isAgencyUser) {
      navigate('/operations/manage/all');
      return;
    }

    if (operationsCount) {
      navigate(operationsCount === 1 ? `/orders/${activeOperationId}` : '/operations/manage');
      return;
    }

    navigate('/welcome');
  }, [isAdmin, isSuperAdmin, isLabOperator, isSampler, operationsCount, activeOperationId]);

  useEffect(() => {
    if (!operationsCount) {
      dispatch(getOperations(getAcreageUnitFromLang(language), true));
    }
    setHasLoaded(true);
  }, [operationsCount]);

  useEffect(() => {
    if (hasFetched && hasLoaded) {
      redirect();
    }
  }, [hasFetched, hasLoaded, redirect]);

  return (
    <Center h="70vh">
      <Loader />
    </Center>
  );
};

export default OperationsDashboard;
