import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderLogo from 'images/earthoptics-logo.svg';

import { CROP_PROTECTION } from 'constants/results';
import { DEMO_OPERATION_ID } from 'constants/variables';

import { setActiveOperation } from 'store/operation/actions';
import { Button } from 'common';

import styles from './WelcomeAgent.module.css';

const WelcomeAgent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeOperation = useCallback(
    async (id: number) => {
      await dispatch(setActiveOperation(id));
    },
    [dispatch],
  );

  const viewOperation = async (id: number) => {
    await changeOperation(id);
    navigate(`/results/operation/${id}/${CROP_PROTECTION}`);
  };

  const handleCreateNewAccount = () => {
    navigate(`/operations/register`);
  };

  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>To place an order, create your first account:</h1>
      <Button className={styles.Button} onClick={handleCreateNewAccount}>
        <img className={styles.Logo} src={HeaderLogo} alt="EarthOptics Logo" />
        <div>Create New Account</div>
      </Button>
      <h2 className={styles.DemoAccountTitle}>
        Not ready to create an account? Check out our Demo Account:
      </h2>
      <Button className={styles.DemoButton} onClick={() => viewOperation(DEMO_OPERATION_ID)}>
        View Demo Account
      </Button>
    </div>
  );
};

export default WelcomeAgent;
