import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Loader, Text } from '@mantine/core';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { getAnalysisDisplayName } from 'util/analysis';
import { dateIsBeforeTodayPlusNDays, formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleQueryKeys } from 'util/queryKeys';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { editSampleValues } from 'store/labSamples/thunks';
import { LabSampleType } from 'store/labSamples/types';
import { SuperAdminAccess } from 'common/RoleBasedAccess';

import { ApplyToSamplingPlan } from '../../common/ApplyToSamplingPlan';

type DueDatePropsType = {
  analysisMethod: string;
  sample: LabSampleType;
  sampleQuery: UseQueryResult<LabSampleType, Error>;
};

export const DueDate = ({ analysisMethod, sample, sampleQuery }: DueDatePropsType) => {
  const rqc = useQueryClient();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dueDate, setDueDate] = useState(
    sampleQuery.data?.[0].rnd_meta?.due_dates?.[analysisMethod]
      ? new Date(sampleQuery.data?.[0].rnd_meta?.due_dates?.[analysisMethod])
      : null,
  );

  useEffect(() => {
    const fetchedDueDate = sampleQuery.data?.[0].rnd_meta?.due_dates?.[analysisMethod];
    if (fetchedDueDate) {
      setDueDate(new Date(fetchedDueDate));
    }
  }, [sampleQuery.data]);

  const applyDueDateToSample = async (dueDateToApply) => {
    const updateData: Partial<LabSampleType> = {
      rnd_meta: {
        ...sample.rnd_meta,
        due_dates: {
          ...sample.rnd_meta?.due_dates,
          [analysisMethod]: dueDateToApply,
        },
      },
    };

    await dispatch(editSampleValues(sample.id, sample.barcode, updateData));
    rqc.invalidateQueries({ queryKey: sampleQueryKeys.sample(sample.barcode) });
  };

  const updateSampleDueDate = async (newDueDate: DateValue | null) => {
    if (sample.barcode) {
      try {
        setIsEditing(false);
        setIsSubmitting(true);
        setDueDate(newDueDate);
        await applyDueDateToSample(newDueDate);
      } catch (e) {
        showToast(e.message, 'error');
      } finally {
        setIsEditing(false);
        setIsSubmitting(false);
      }
    }
  };

  const getStyleForDisplay = (dueDateForArithmetic) => {
    const today = new Date();

    const pastDue = dueDateForArithmetic && dueDateForArithmetic < today;
    const dueSoon = dueDateForArithmetic && dateIsBeforeTodayPlusNDays(dueDateForArithmetic, 3);

    if (pastDue) {
      return { color: 'red' };
    }
    if (dueSoon) {
      return { color: 'orange' };
    }
    return {};
  };

  const dueDateDisplay = () => {
    const dueDateFromQuery = sampleQuery.data?.[0].rnd_meta?.due_dates?.[analysisMethod];

    const dueDateToFormat = dueDateFromQuery || null;
    const dueDateForArithmetic = dueDateFromQuery ? new Date(dueDateFromQuery) : null;

    const style = getStyleForDisplay(dueDateForArithmetic);

    const formattedDate = dueDateToFormat
      ? formatDateMonthDayYear(dueDateToFormat, language)
      : NOT_APPLICABLE;

    return <Text style={style}>{formattedDate}</Text>;
  };

  const removeDueDate = async () => {
    updateSampleDueDate(null);
  };

  const removeDueDateButton = (
    <Group gap="xs" pos="relative">
      <Button
        variant="outline"
        onClick={removeDueDate}
        disabled={!dueDate || isEditing || isSubmitting}
      >
        {getString(isSubmitting ? 'submitting' : 'removeDueDate', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const updateDueDateButton = (
    <Group gap="xs" pos="relative">
      <Button variant="outline" onClick={() => setIsEditing(true)} disabled={isSubmitting}>
        {getString(isSubmitting ? 'submitting' : 'update', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const sampleDatePicker = (
    <DatePickerInput
      minDate={new Date()}
      value={dueDate ? new Date(dueDate) : undefined}
      onChange={(newDueDate) => updateSampleDueDate(newDueDate)}
      w="100%"
    />
  );

  const samplesInSamplingPlanRequestBody = {
    due_dates: {
      [analysisMethod]: dueDate,
    },
  };

  const confirmationMessage = `${getString(
    'applyDueDateToSamplingPlanConfirmation',
    language,
  )}: ${getAnalysisDisplayName(analysisMethod, language)} -- ${dueDate?.toDateString()}`;

  return (
    <Group>
      <Text fw="700">
        {`${getAnalysisDisplayName(analysisMethod, language)} ${getString('dueDate', language)}: `}{' '}
        {dueDateDisplay()}
      </Text>
      <SuperAdminAccess>
        <Group gap="sm" w="13.5rem" wrap="nowrap">
          {isEditing ? sampleDatePicker : updateDueDateButton}
          {removeDueDateButton}
          <ApplyToSamplingPlan
            sample={sample}
            samplesInSamplingPlanRequestBody={samplesInSamplingPlanRequestBody}
            confirmationMessage={confirmationMessage}
          />
        </Group>
      </SuperAdminAccess>
    </Group>
  );
};
