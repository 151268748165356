import React from 'react';
import { Button, Checkbox, Menu } from '@mantine/core';

import {
  COMPLETED,
  FIELDS,
  OPERATIONS_TABLE_LIST,
  PARTIAL,
  READY,
  SAMPLED,
  WAITING_TO_SAMPLE,
} from 'constants/samplePlanning';

import useBrowserLanguage from 'util/hooks/useLanguage';
import { formatBigNumberNotation } from 'util/numUtils';
import { getAcreageUnitFromLang } from 'util/units';
import { getString, useTranslation } from 'strings/translation';
import { OperationTotalStats, OperationType } from 'store/operation/types';
import { AdminAgentAccess, ColumnSorter, Table, TableCol, TableRow } from 'common';

import styles from './OperationsTable.module.css';

type OperationsTablePropsType = {
  enableRowCheckboxes: boolean;
  manageOrders: (id: number) => void;
  viewResults: (id: number) => void;
  editOperation: (id: number) => void;
  operations: OperationType[];
  operationTotals: OperationTotalStats;
  sortAttribute: string;
  sortOrder: string;
  setSortAttribute: (arg0: string) => void;
  setSortOrder: (arg0: string) => void;
  toggleDeliveryModal: (op: OperationType) => void;
  selectedOperationIds: number[];
  setSelectedOperationIds: (ids: number[]) => void;
};

const CHECKBOX_COL_WIDTH = 32;

const OperationsTable = ({
  manageOrders,
  viewResults,
  editOperation,
  operations,
  operationTotals,
  sortAttribute,
  sortOrder,
  setSortAttribute,
  setSortOrder,
  toggleDeliveryModal,
  enableRowCheckboxes,
  selectedOperationIds,
  setSelectedOperationIds,
}: OperationsTablePropsType) => {
  const language = useBrowserLanguage();

  const handleSorting = ({ attribute, order }: { attribute: string; order: string }) => {
    setSortAttribute(attribute);
    setSortOrder(order);
  };

  const translateAndUnit = (attribute: string) => {
    return `${getString(attribute, language)} (${getAcreageUnitFromLang(language)})`;
  };

  const checkboxAriaLabel = useTranslation('selectAccount');

  return (
    <Table className={styles.Table}>
      <TableRow borderless header>
        <TableCol className={styles.OpenPlans}>{useTranslation('activeSamplingPlans')}</TableCol>
        <TableCol shrink className={styles.TooltipCol} />
      </TableRow>
      <TableRow header className={styles.TableHeader}>
        {enableRowCheckboxes && <div style={{ width: CHECKBOX_COL_WIDTH }} />}
        <TableCol size="x2">
          <ColumnSorter
            attribute="name"
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
            className={styles.LeftAligned}
          >
            {useTranslation('accountName')}
          </ColumnSorter>
        </TableCol>
        <TableCol>{useTranslation('billingAgency')}</TableCol>
        <TableCol>
          <ColumnSorter
            attribute={FIELDS}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
            className={styles.LeftAligned}
          >
            {translateAndUnit(FIELDS)}
          </ColumnSorter>
        </TableCol>
        <TableCol>
          <ColumnSorter
            attribute={WAITING_TO_SAMPLE}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
            className={styles.LeftAligned}
          >
            {translateAndUnit('waiting')}
          </ColumnSorter>
        </TableCol>
        <TableCol>
          <ColumnSorter
            attribute={READY}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
            className={styles.LeftAligned}
          >
            {translateAndUnit(READY)}
          </ColumnSorter>
        </TableCol>
        <TableCol>
          <ColumnSorter
            attribute={SAMPLED}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
            className={styles.LeftAligned}
          >
            {translateAndUnit(SAMPLED)}
          </ColumnSorter>
        </TableCol>
        <TableCol>
          <ColumnSorter
            attribute={PARTIAL}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
            className={styles.LeftAligned}
          >
            {translateAndUnit(PARTIAL)}
          </ColumnSorter>
        </TableCol>
        <AdminAgentAccess>
          <TableCol>
            <ColumnSorter
              attribute={COMPLETED}
              sorting={{ attribute: sortAttribute, order: sortOrder }}
              setSorting={handleSorting}
              className={styles.LeftAligned}
            >
              {translateAndUnit('complete')}
            </ColumnSorter>
          </TableCol>
        </AdminAgentAccess>
        <TableCol />
      </TableRow>
      <TableRow className={styles.HeaderContent}>
        {enableRowCheckboxes && <div style={{ width: CHECKBOX_COL_WIDTH }} />}
        <TableCol className={styles.LeftAligned} size="x2">
          {useTranslation('allAccounts')}
        </TableCol>
        <TableCol />
        <TableCol className={styles.LeftAligned}>
          {`${formatBigNumberNotation(operationTotals.fields)} (${formatBigNumberNotation(
            operationTotals.acreage_count.operation_total_acres,
          )})`}
        </TableCol>
        {OPERATIONS_TABLE_LIST.map((status) => (
          <TableCol className={styles.LeftAligned} key={status}>
            {`${operationTotals.sampling_status[status]} (${formatBigNumberNotation(
              operationTotals.acreage_count[status],
            )})`}
          </TableCol>
        ))}
        <AdminAgentAccess>
          <TableCol className={styles.LeftAligned}>
            {`${operationTotals.sampling_status.completed} (${formatBigNumberNotation(
              operationTotals.acreage_count.completed,
            )})`}
          </TableCol>
        </AdminAgentAccess>
        <TableCol />
      </TableRow>
      {operations.map((operation) => (
        <TableRow key={operation.id}>
          {enableRowCheckboxes && (
            <Checkbox
              w={CHECKBOX_COL_WIDTH}
              aria-label={checkboxAriaLabel}
              checked={selectedOperationIds.includes(operation.id)}
              onChange={(event) =>
                setSelectedOperationIds(
                  event.currentTarget.checked
                    ? [...selectedOperationIds, operation.id]
                    : selectedOperationIds.filter((row) => row !== operation.id),
                )
              }
            />
          )}
          <TableCol size="x2">
            <div className={styles.Clickable} onClick={() => manageOrders(operation.id)}>
              {operation.name}
            </div>
          </TableCol>
          <TableCol>{operation.billing_agency_name}</TableCol>
          <TableCol>
            {operation.fields.length} ({operation.acreage_count.operation_total_acres})
          </TableCol>
          {OPERATIONS_TABLE_LIST.map((status) => (
            <TableCol key={status}>
              {operation.sampling_status[status]
                ? `${operation.sampling_status[status]} (${operation.acreage_count[status]})`
                : getString('none', language)}
            </TableCol>
          ))}
          <AdminAgentAccess>
            <TableCol className={operation.pending_delivery ? styles.PendingDelivery : undefined}>
              {operation.sampling_status?.completed
                ? `${operation.sampling_status.completed} (${operation.acreage_count.completed})`
                : getString('none', language)}
            </TableCol>
          </AdminAgentAccess>
          <TableCol>
            <Menu shadow="md">
              <Menu.Target>
                <Button variant="outline">{getString('actions', language)}</Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item onClick={() => manageOrders(operation.id)}>
                  {getString('manageOrders', language)}
                </Menu.Item>
                <Menu.Item onClick={() => viewResults(operation.id)}>
                  {getString('viewResults', language)}
                </Menu.Item>
                <Menu.Item onClick={() => editOperation(operation.id)}>
                  {getString('editAccount', language)}
                </Menu.Item>
                <Menu.Item
                  disabled={!operation.pending_delivery}
                  onClick={() => toggleDeliveryModal(operation)}
                >
                  {getString('markPlanDelivered', language)}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </TableCol>
        </TableRow>
      ))}
    </Table>
  );
};

export default OperationsTable;
