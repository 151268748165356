import React, { useState } from 'react';
import classNames from 'classnames';

import { getWellRowColLists, isWellEmpty } from 'util/plates';
import { DNAConcentrationLookupType, DNAConcentrationsType } from 'store/dnaConcentration/types';
import { PlateType, WellLookupType, WellType } from 'store/plates/types';

import styles from './Grid.module.css';

type WellGridPropsType = {
  plate?: PlateType;
  wells: WellLookupType | DNAConcentrationLookupType;
  onWellClick?: (well: WellType | DNAConcentrationsType) => void;
  disableHighlight?: boolean;
};

export const WellGrid = ({ plate, wells, onWellClick, disableHighlight }: WellGridPropsType) => {
  const [hoveredCell, setHoveredCell] = useState<{
    row: string;
    column: string;
  } | null>(null);

  const { rows, columns } = getWellRowColLists(plate?.plate_type);

  const handleOnClick = (well: WellType | DNAConcentrationsType) =>
    !disableHighlight && onWellClick && onWellClick(well);

  const handleMouseOver = (well: WellType | DNAConcentrationsType) => setHoveredCell(well);

  const handleMouseOutGrid = () => setHoveredCell(null);

  const getCellClassNames = (well: WellType | DNAConcentrationsType) => {
    const classList = [styles.Well];
    if (!isWellEmpty(well)) {
      classList.push(styles.WellFilled);
    }

    if (!disableHighlight && hoveredCell) {
      classList.push(styles.Clickable);
      if (hoveredCell.row === well.row && hoveredCell.column === well.column) {
        classList.push(styles.StrongHighlight);
      } else if (hoveredCell.row === well.row || hoveredCell.column === well.column) {
        classList.push(styles.SoftHighlight);
      }
    }

    return classNames(...classList);
  };

  return (
    <div
      className={styles.Grid}
      onMouseOut={() => handleMouseOutGrid()}
      onBlur={() => handleMouseOutGrid()}
    >
      <div className={styles.GridRow}>
        <div className={styles.GridCell}> </div>
        {columns.map((c) => (
          <div className={styles.GridCell} key={c}>
            {c}
          </div>
        ))}
      </div>
      {rows.map((r) => {
        return (
          <div key={r} className={styles.GridRow}>
            <div className={styles.GridCell}>{r.toUpperCase()}</div>
            {columns.map((c) => {
              const well = wells[r]?.[c] || { row: r, column: c };
              return (
                <div key={`${r}${c}`} className={styles.GridCell}>
                  <div
                    onMouseOver={() => handleMouseOver(well)}
                    onFocus={() => handleMouseOver(well)}
                    onClick={() => handleOnClick(well)}
                    className={getCellClassNames(well)}
                  >
                    {well && well.value}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
