import React from 'react';
import { Group } from '@mantine/core';

type Props = {
  children?: React.ReactNode;
};

const FullHeightLayoutFooter = ({ children }: Props) => {
  return (
    <Group
      bg="var(--mantine-color-body)"
      justify="space-between"
      py="md"
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: 'var(--mantine-color-gray-3)',
      }}
    >
      {children}
    </Group>
  );
};

export default FullHeightLayoutFooter;
