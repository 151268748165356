import React from 'react';
import { Grid } from '@mantine/core';

import { getPriceDisplay, pricePerAcre } from 'util/samplePlan';

import styles from './Confirmation.module.css';

type PlanTotalProps = {
  acreage: number;
  priceSummary: { [key: string]: { total: number; num_zones: number } };
};

const PlanTotal = ({ acreage, priceSummary }: PlanTotalProps) => {
  const totalPrice = Object.keys(priceSummary).reduce(
    (total, sid) => (priceSummary[sid].total ? priceSummary[sid].total + total : total),
    0,
  );
  const totalPerAcre = pricePerAcre(totalPrice, acreage);
  return (
    <Grid className={styles.Total}>
      <Grid.Col span={8} />
      <Grid.Col span={2}>{getPriceDisplay('', totalPrice)}</Grid.Col>
      <Grid.Col span={2}>{getPriceDisplay('', totalPerAcre)}</Grid.Col>
    </Grid>
  );
};

export default PlanTotal;
