import React, { Fragment } from 'react';
import classNames from 'classnames';

import { ARRIVED, ORDERED, SAMPLED } from 'constants/orders';
import {
  COMPLETE_BIO,
  DECISION_DASHBOARD,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
  productDisplayNames,
  productShortNames,
  ROOTWORM_PRESSURE_PANEL,
  SCN_PRESSURE_PANEL,
} from 'constants/products';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { retrieveProgressStatuses } from 'util/orders';
import { hasLegacyProducts, hasProducts } from 'util/product';
import { getString } from 'strings/translation';
import { SamplePlanType } from 'store/samplePlans/types';

import styles from '../TableRow.module.css';

type ProgressStatusPropsType = {
  samplingPlan: SamplePlanType;
  className?: string;
};

export const ProgressStatus = ({ samplingPlan, className }: ProgressStatusPropsType) => {
  const language = useBroswerLanguage();

  const {
    samplingStatus,
    analysisStatus,
    orderedStatus,
    sampledStatus,
    arrivedStatus,
    nutrientStatus,
    pressureStatus,
    performanceStatus,
  } = retrieveProgressStatuses(samplingPlan.sample_progress_statuses, language);

  const samplingList = [
    {
      label: getString(ORDERED, language),
      value: String(orderedStatus),
    },
    {
      label: getString(SAMPLED, language),
      value: String(sampledStatus),
    },
    {
      label: getString(ARRIVED, language),
      value: String(arrivedStatus),
    },
  ];

  const analysisList = hasLegacyProducts(samplingPlan)
    ? [
        {
          label: getString(productShortNames[ROOTWORM_PRESSURE_PANEL], language),
          value: pressureStatus,
          disabled: !hasProducts(samplingPlan, ROOTWORM_PRESSURE_PANEL, DECISION_DASHBOARD),
        },
        {
          label: getString(productShortNames[SCN_PRESSURE_PANEL], language),
          value: pressureStatus,
          disabled: !hasProducts(samplingPlan, SCN_PRESSURE_PANEL, DECISION_DASHBOARD),
        },
        {
          label: getString(productShortNames[DECISION_DASHBOARD], language),
          value: performanceStatus,
          disabled: !hasProducts(samplingPlan, DECISION_DASHBOARD),
        },
      ]
    : [
        {
          label: getString(productShortNames[NUTRIENT_PANEL], language),
          value: nutrientStatus,
          disabled: !hasProducts(samplingPlan, NUTRIENT_PANEL),
        },
        {
          label: getString(productShortNames[PRESSURE_PANEL], language),
          value: pressureStatus,
          disabled: !hasProducts(samplingPlan, PRESSURE_PANEL),
        },
        {
          label: productDisplayNames[COMPLETE_BIO],
          value: performanceStatus,
          disabled: !hasProducts(samplingPlan, PERFORMANCE_PANEL),
        },
      ];

  const generateStatus = (statusList: { label: string; value: string; disabled?: boolean }[]) => (
    <div className={styles.OrdersStatus}>
      {statusList.map((stat, index) => (
        <Fragment key={stat.label}>
          <div className={styles.Line} hidden={!index} />
          <div className={classNames(styles.StatusText, stat.disabled ? styles.NotOrdered : null)}>
            <div>
              {stat.label}
              <span className={styles.StatusColon}>{': '}</span>
            </div>
            <div className={styles.StatusBold}>{stat.value}</div>
          </div>
        </Fragment>
      ))}
    </div>
  );

  return (
    <div className={classNames(styles.StatusContainer, className)}>
      <div className={styles.Status}>
        <div>
          <span className={styles.StatusBold}>{`${getString('sampling', language)}: `}</span>
          <span>{samplingStatus}</span>
        </div>
        <div className={styles.StatusBox}>{generateStatus(samplingList)}</div>
      </div>
      <div className={styles.Status}>
        <div>
          <span className={styles.StatusBold}>{`${getString('analysis', language)}: `}</span>
          <span>{analysisStatus}</span>
        </div>
        <div className={styles.StatusBox}>{generateStatus(analysisList)}</div>
      </div>
    </div>
  );
};
