import { useState } from 'react';
import { Button, Group, Modal, Select, Space, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { projectsQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { updateCarbonCollectionFields } from 'store/projects/requests';
import { CarbonCollection } from 'store/projects/types';

import { useProjectsTranslations } from '../hooks';

type Props = {
  acres: number;
  carbonCollections: CarbonCollection[];
  selectedFieldIds: number[];
  reset: VoidFunction;
  samplingPlanId: number;
};

const CarbonCollectionMapBtns = ({
  selectedFieldIds,
  acres,
  carbonCollections,
  reset,
  samplingPlanId,
}: Props) => {
  const translations = useProjectsTranslations();
  const disabled = !selectedFieldIds.length;
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCollectionUuid, setSelectedCollectionUuid] = useState('');
  const { length: selectedFieldsCount } = selectedFieldIds;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSettled: close,
    onSuccess: () => {
      reset();
      showToast(translations.fieldsAddedToCollection, 'success');
      queryClient.invalidateQueries({
        queryKey: projectsQueryKeys.samplingPlan(samplingPlanId),
      });
    },
    mutationFn: (data: { collectionUuid: string; fieldIds: number[] }) => {
      return updateCarbonCollectionFields(data.collectionUuid, data.fieldIds);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!selectedCollectionUuid) return;

    mutation.mutate({
      collectionUuid: selectedCollectionUuid,
      fieldIds: selectedFieldIds,
    });
  };

  const getHelperText = () => {
    if (selectedFieldsCount) {
      return [selectedFieldsCount, translations.fieldsLower, translations.selectedForAssignment]
        .join(' ')
        .toLowerCase();
    }

    return translations.clickFieldsToAssignColln;
  };

  return (
    <Group>
      <Text>{getHelperText()}</Text>
      <Button disabled={disabled} onClick={open}>
        {translations.assignToCollection}
      </Button>
      <Button disabled={disabled} onClick={reset} variant="outline">
        {translations.cancel}
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        centered
        title={`${[
          translations.add,
          selectedFieldsCount,
          translations.fields,
          translations.toCollection,
        ].join(' ')}?`}
      >
        <Stack onSubmit={handleSubmit} component="form" gap="md">
          <Group justify="center">
            <Text fw="bold" id="collection-label" tt="capitalize">
              {translations.collection}
            </Text>
            <Select
              required
              aria-labelledby="collection-label"
              allowDeselect={false}
              onChange={(value) => setSelectedCollectionUuid(value || '')}
              data={carbonCollections
                .map((collection) => ({
                  label: collection.name,
                  value: collection.collection_uuid,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </Group>
          <Text ta="center">
            {translations.acres}: {acres}
          </Text>
          <Space />
          <Group gap="md" justify="center">
            <Button onClick={close} variant="outline">
              {translations.cancel}
            </Button>
            <Button type="submit">{translations.confirm}</Button>
          </Group>
        </Stack>
      </Modal>
    </Group>
  );
};

export default CarbonCollectionMapBtns;
