import React from 'react';

import HeaderLogo from 'images/earthoptics-logo.svg';

import styles from './WelcomeOperator.module.css';

const WelcomeOperator = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Container}>
      <img className={styles.Logo} src={HeaderLogo} alt="EarthOptics Logo" />
      <h1>
        You aren't connected to any accounts. Ask for an invite from your Agent or Regional Manager
        to get started!
      </h1>
    </div>
  </div>
);

export default WelcomeOperator;
