import { ZONE_TYPES } from './mapbox';
import { NITRATE_PANEL, NUTRIENT_PANEL, PERFORMANCE_PANEL, PRESSURE_PANEL } from './products';

export const PRICING = 'pricing';
export const SKUS = 'skus';

export const PRICING_TABS = [SKUS, PRICING];

export const IS_PRO = 'is_pro';
export const IS_TILL_RX = 'is_till_rx';
export const IS_590_analysis = 'is_590_analysis';

export const ANALYSIS = 'analysis';
export const SAMPLING = 'sampling';
export const SCAN = 'scan';
export const ADCP = 'adcp';
export const PRO_MAP_GENERATION = 'pro_map_generation';
export const TILL_RX_MAP_GENERATION = 'till_rx_map_generation';

export const PER_ACRE = 'per_acre';
export const PER_SAMPLE = 'per_sample';

export const PRICE_TYPES = [PER_ACRE, PER_SAMPLE];

export const DUMMY_USER_ID = 0; // default pseudo-user for sampling/scanning

export const skuProductTypes = [
  IS_PRO,
  IS_TILL_RX,
  PRESSURE_PANEL,
  PERFORMANCE_PANEL,
  NUTRIENT_PANEL,
  NITRATE_PANEL,
  IS_590_analysis,
];

export const skuTypes = [
  ANALYSIS,
  SAMPLING,
  SCAN,
  ADCP,
  PRO_MAP_GENERATION,
  TILL_RX_MAP_GENERATION,
];

export const skuSamplingTypes = [ZONE_TYPES.POLYGON, ZONE_TYPES.POINT];

// SKU IDs (UI-only)
export const CUSTOM_SKU_ID = -1; // UI-only
export const EXTERNAL_LAB_SKU_ID = -2; // UI-only

// Real SKU IDs
export const TRUNUTRIENT_SKU_ID = 1;
export const THREE_SIXTY_SKU_ID = 3;
export const TRUTILL_SKU_ID = 4;
export const TN_AND_TT_SKU_ID = 5;
export const STANDARD_NUTRIENT_AND_NITRATE_SKU_ID = 6;
export const NITRATE_ONLY_SKU_ID = 7;
export const TRUBIO_SKU_ID = 8;
export const THREE_SIXTY_PRO_SKU_ID = 9;
export const THREE_SIXTY_COMPLETE_SKU_ID = 10;
export const TB_AND_TT_SKU_ID = 11;
export const STANDARD_NUTRIENT_AND_WI_590_SKU_ID = 12;
export const TRUNUTRIENT_STANDARD_SKU_ID = 14;
export const TRUNUTRIENT_STANDARD_AND_NITRATE_SKU_ID = 18;
export const TRUNUTRIENT_WI_590_SKU_ID = 19;
export const TRUNUTRIENT_NITRATE_SKU_ID = 20;
export const POINT_SAMPLING_SKU_ID = 21;
export const ZONE_SAMPLING_SKU_ID = 22;
export const TN_AND_TT_SAMPLING_SKU_ID = 24;
export const TB_AND_TT_SAMPLING_SKU_ID = 25;
export const TRUNUTRIENT_SAMPLING_SKU_ID = 26;
export const THREE_SIXTY_PRO_SAMPLING_SKU_ID = 27;
export const TRUBIO_SAMPLING_SKU_ID = 28;
export const THREE_SIXTY_COMPLETE_SAMPLING_SKU_ID = 29;
export const ADCP_SKU_ID = 30;
export const SCAN_SKU_ID = 31;
export const TILL_MAP_GENERATION_PRO_SKU_ID = 32;
export const TILL_MAP_GENERATION_TILL_RX_SKU_ID = 33;
export const STANDARD_NUTRIENT_SKU_ID = 34;
export const WI_590_SKU_ID = 35;
export const TRUNUTRIENT_STANDARD_AND_WI_590_SKU_ID = 36;
export const AG_SOURCE_NUTRIENT_SKU_ID = 37;
export const AG_SOURCE_TRUNUTRIENT_SKU_ID = 38;
export const MIDWEST_LABS_TRUNUTRIENT_SKU_ID = 40;
export const MIDWEST_LABS_NUTRIENT_SKU_ID = 41;

// Packages that contain additional products. Does not include UI-only "Custom" package.
export const PRIMARY_PACKAGES_SKU_IDS = [
  THREE_SIXTY_COMPLETE_SKU_ID,
  THREE_SIXTY_PRO_SKU_ID,
  TN_AND_TT_SKU_ID,
  TB_AND_TT_SKU_ID,
  TRUNUTRIENT_SKU_ID,
  TRUBIO_SKU_ID,
  TRUTILL_SKU_ID,
  CUSTOM_SKU_ID,
];

export const NUTRIENT_ANALYSIS_PACKAGE_SKU_IDS = [
  STANDARD_NUTRIENT_SKU_ID,
  STANDARD_NUTRIENT_AND_NITRATE_SKU_ID,
  WI_590_SKU_ID,
  STANDARD_NUTRIENT_AND_WI_590_SKU_ID, // 👈 TODO: unsure about this one
];

export const NUTRIENT_TRUNUTRIENT_MAPPING = {
  [STANDARD_NUTRIENT_SKU_ID]: TRUNUTRIENT_STANDARD_SKU_ID,
  [STANDARD_NUTRIENT_AND_NITRATE_SKU_ID]: TRUNUTRIENT_STANDARD_AND_NITRATE_SKU_ID,
  [WI_590_SKU_ID]: TRUNUTRIENT_WI_590_SKU_ID,
  [STANDARD_NUTRIENT_AND_WI_590_SKU_ID]: TRUNUTRIENT_STANDARD_AND_WI_590_SKU_ID,
  [AG_SOURCE_NUTRIENT_SKU_ID]: AG_SOURCE_TRUNUTRIENT_SKU_ID,
  [MIDWEST_LABS_NUTRIENT_SKU_ID]: MIDWEST_LABS_TRUNUTRIENT_SKU_ID,
};

export const BIO_DISPLAY_TEXT_SKU_IDS = [
  THREE_SIXTY_COMPLETE_SKU_ID,
  THREE_SIXTY_PRO_SKU_ID,
  TB_AND_TT_SKU_ID,
  TRUBIO_SKU_ID,
];

export const SCAN_POINT_DISPLAY_TEXT_SKU_MAPPING = {
  [THREE_SIXTY_COMPLETE_SKU_ID]: 'zoneAnalysisDefaultScanPointMsg',
  [THREE_SIXTY_PRO_SKU_ID]: 'zoneAnalysisDefaultScanPointMsg',
  [TN_AND_TT_SKU_ID]: 'zoneAnalysisTNTTScanPointMsg',
  [TB_AND_TT_SKU_ID]: 'zoneAnalysisTruTillScanPointMsg',
  [TRUNUTRIENT_SKU_ID]: 'zoneAnalysisDefaultScanPointMsg',
  [TRUTILL_SKU_ID]: 'zoneAnalysisTruTillScanPointMsg',
};

// SKU names (UI-only) // TODO: translations
export const CUSTOM_SKU_NAME = 'Custom';
export const EXTERNAL_LAB_SKU_NAME = 'External Lab';

export const productDescripTranslationKeys: {
  [key in (typeof PRIMARY_PACKAGES_SKU_IDS)[number]]: string;
} = {
  [THREE_SIXTY_COMPLETE_SKU_ID]: 'productDesc360Complete',
  [THREE_SIXTY_PRO_SKU_ID]: 'productDesc360ProTnTb',
  [TN_AND_TT_SKU_ID]: 'productDescTnTt',
  [TB_AND_TT_SKU_ID]: 'productDescTbTt',
  [TRUNUTRIENT_SKU_ID]: 'productDescTruNutrient',
  [TRUBIO_SKU_ID]: 'productDescTruBio',
  [TRUTILL_SKU_ID]: 'productDescTruTill',
  [CUSTOM_SKU_ID]: 'productDescCustom',
};

export const NUTRIENT = 'nutrient';
export const TRUNUTRIENT = 'truNutrient';
export const TRUBIO = 'truBio';
export const TRUTILL = 'truTill';

export const SUB_PRODUCTS_LIST = [NUTRIENT, TRUNUTRIENT, TRUBIO, TRUTILL];

export const subProductsMapping = {
  [NUTRIENT]: STANDARD_NUTRIENT_SKU_ID,
  [TRUNUTRIENT]: TRUNUTRIENT_SKU_ID,
  [TRUBIO]: TRUBIO_SKU_ID,
  [TRUTILL]: TRUTILL_SKU_ID,
};

export const LIST_PRICE = 'listPrice';
export const PRICE = 'price';
export const FIELD_WORK = 'fieldWork';

export const EXTERNAL_LABS = {
  MIDWEST_LABS: {
    key: 'MIDWEST_LABS',
    truNutrientSkuId: MIDWEST_LABS_TRUNUTRIENT_SKU_ID,
    nutrientSkuId: MIDWEST_LABS_NUTRIENT_SKU_ID,
  },
  AG_SOURCE: {
    key: 'AG_SOURCE',
    truNutrientSkuId: AG_SOURCE_TRUNUTRIENT_SKU_ID,
    nutrientSkuId: AG_SOURCE_NUTRIENT_SKU_ID,
  },
};
