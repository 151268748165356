import React, { useEffect, useState } from 'react';
import { Button, Center, PinInput, Space, Stack, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PasswordlessUserEmailType } from 'store/user/types';

interface Props {
  emailCell: PasswordlessUserEmailType;
  loginType: string;
  clearLoginType: () => void;
  resendCode: () => void;
  submitCode: (code: string) => void;
}

const EnterCodeForm = ({ emailCell, loginType, clearLoginType, resendCode, submitCode }: Props) => {
  const language = useBroswerLanguage();

  const [pinCode, setPinCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await submitCode(pinCode);
    setLoading(false);
  };

  useEffect(() => {
    if (pinCode.length === 6) {
      handleSubmit();
    }
  }, [pinCode]);

  return (
    <Stack>
      <Center>
        <Text size="lg" fw={700}>
          {getString('welcomeBack', language)}
        </Text>
      </Center>
      <Center>
        <Text size="md">
          {getString('enterCodeSentTo', language)}{' '}
          {loginType === 'cell' ? `(***) ***-${emailCell.cell}` : emailCell.email}
        </Text>
      </Center>
      <Center>
        <PinInput type={/^[0-9]*$/} oneTimeCode length={6} onChange={setPinCode} />
      </Center>
      <Center>
        <Button disabled={loading} variant="outline" onClick={resendCode}>
          {getString('resendCode', language)}
        </Button>
        <Space w="xl" />
        <Button disabled={loading} variant="outline" onClick={clearLoginType}>
          {getString('signInDifferentWay', language)}
        </Button>
      </Center>
    </Stack>
  );
};

export default EnterCodeForm;
