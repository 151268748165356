import React from 'react';
import { SimpleGrid, Stack } from '@mantine/core';
import { Text } from 'recharts';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { SingleAnalyticType } from 'store/analytics/types';
import { RiskMapboxSample } from 'store/fields/types';

import ZoneDetailRow from './ZoneDetailRow';

import styles from './ZoneDetails.module.css';

type ZoneDetailsPropsType = {
  planAnalytic: SingleAnalyticType;
  samples: RiskMapboxSample[];
};

const ZoneDetails = ({ planAnalytic, samples }: ZoneDetailsPropsType) => {
  const language = useBroswerLanguage();
  const hasElevation = samples.some((sample) => sample.properties.elevation_level);
  return (
    <Stack>
      <SimpleGrid cols={3} className={styles.Header}>
        <Text className={styles.HeaderRowValue}>{getString('zone', language)}</Text>
        {hasElevation && (
          <Text className={styles.HeaderRowValue}>{getString('ele', language)}</Text>
        )}
        <Text className={styles.HeaderRowValue}>{`(${planAnalytic.unit})`}</Text>
      </SimpleGrid>
      {samples.map((sample, index) => (
        <ZoneDetailRow
          sample={sample}
          key={sample.properties.id}
          index={index + 1}
          hasElevation={hasElevation}
          planAnalytic={planAnalytic}
        />
      ))}
    </Stack>
  );
};

export default ZoneDetails;
