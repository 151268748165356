import { useEffect, useRef, useState } from 'react';
import { Box } from '@mantine/core';
import turfBbox from '@turf/bbox';
import center from '@turf/center';
import { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import { LIGHT_GREEN, WHITE } from 'constants/colors';
import { FIELD_OUTLINE, MAPBOX_FIT_PARAMS } from 'constants/mapbox';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { removeAllPrefixedSourcesAndLayers } from 'util/mapbox';
import {
  EOCollectionFeatureType,
  EOCollectionFieldFeatCollection,
} from 'store/eoCollections/types';
import useMapboxGl from 'common/MapHooks';
import { ViewPortProps } from 'common/Maps/types';

interface CollectionMapProps {
  field: EOCollectionFieldFeatCollection;
  selectedCollectionId: number | null;
  collections: EOCollectionFeatureType[];
}

const CollectionMap = ({ field, selectedCollectionId, collections }: CollectionMapProps) => {
  const language = useBroswerLanguage();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);

  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;
      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;

      if (map.getLayer(FIELD_OUTLINE)) {
        map.removeLayer(FIELD_OUTLINE);
        map.removeSource(FIELD_OUTLINE);
      }

      map.addLayer({
        id: FIELD_OUTLINE,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, MAPBOX_FIT_PARAMS);
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;

    if (mapHasLoaded && map) {
      const sourceAndLayerPrefix = 'collection-';

      removeAllPrefixedSourcesAndLayers(map, sourceAndLayerPrefix);

      collections.forEach((collection) => {
        const layerId = `${sourceAndLayerPrefix}${collection.properties.id}`;

        const source = {
          type: 'geojson',
          data: collection,
        } as GeoJSONSourceRaw;

        map.addLayer({
          id: layerId,
          type: 'line',
          source,
          paint: {
            'line-color': collection.properties.id === selectedCollectionId ? LIGHT_GREEN : WHITE,
            'line-width': 2,
          },
        });
      });

      map.getStyle();
    }
  }, [mapHasLoaded, mapRef, selectedCollectionId, language, collections]);

  return (
    <Box w="35%" h="25rem" ref={wrapperRef}>
      <Box ref={mapContainerRef} w="100%" h="100%" />
    </Box>
  );
};

export default CollectionMap;
