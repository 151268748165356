import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdPin } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import turfBbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import centroid from '@turf/centroid';
import difference from '@turf/difference';
import { FeatureCollection, Geometry, Polygon, polygon, Position, Properties } from '@turf/helpers';
import intersect from '@turf/intersect';
import { Feature, GeoJsonProperties, MultiPolygon } from 'geojson';
import {
  FillLayer,
  GeoJSONSource,
  LineLayer,
  LngLatBoundsLike,
  Map,
  MapMouseEvent,
} from 'mapbox-gl';
import { v4 as uuid } from 'uuid';

import {
  ALL_FIELD_BOUNDARIES_LAYER_CONFIG,
  CLOSE_FIELD_LABELS_LAYER_CONFIG,
  CLU_LAYERS,
  CLU_ZOOM_THRESHOLD,
  CLUS_SOURCE_ID,
  CLUSTER_COUNT_LAYER_CONFIG,
  CLUSTERS_LAYER_CONFIG,
  CLUSTERS_SOURCE_ID,
  FIELD_BOUNDARIES_SOURCE_ID,
  FIELD_LABELS_AND_PINS_LAYER_CONFIG,
  FIELD_POINTS_SOURCE_CONFIG,
  FIELD_SELECTED_FEATURE_STATE_KEY,
  FIELD_SOURCE_PROMOTE_ID_KEY,
  FIELDS_LAYER_IDS,
  MARKER_PIN_ICON_ID,
  SplitFieldCreateFate,
} from 'constants/fields';
import { MERGE, MODES, SPLIT } from 'constants/mapbox';

import {
  getFieldCentroids,
  getSplitMergeLayerConfig,
  getSplitMergeLayerId,
  setFieldMapClickListeners,
  setFieldMapHoverListeners,
} from 'util/field';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { symbolAsInlineImage } from 'util/mapImageryColors';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { OrdersRouteParams } from 'store';
import { clearFieldGeometries } from 'store/fields/actions';
import { requestMergeFields, requestSplitFields } from 'store/fields/requests';
import { FieldPropertiesType, FieldType } from 'store/fields/types';
import { receiveSingleOperation } from 'store/operation/actions';
import { requestSingleOperation } from 'store/operation/requests';
import { OperationType } from 'store/operation/types';
import useMapboxGl from 'common/MapHooks';
import Popup from 'common/Maps/Popup';
import { DrawMode, MapboxClickEvent, PopupState, ViewPortProps } from 'common/Maps/types';

import ClusButtons from './ClusButtons';
import DrawingToolsV2 from './DrawingToolsV2';
import FieldPopupContent from './FieldPopupContent';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './FieldBoundaryEditor.module.css';

type FieldEditorPropsType = {
  cluPolygons: Feature<MultiPolygon, GeoJsonProperties>[] | null;
  selectedClusPolygons: FeatureCollection<Geometry, Properties>;
  selectedClus: number[];
  setSelectedClus: React.Dispatch<React.SetStateAction<number[]>>;
  reloadClus: () => void;
  loadingClus: boolean;
  /**
   * Not actually added to map, only used for zoom. The feature being used is part of the full list.
   */
  featCollectionOfFieldBeingEdited: FieldType | undefined;
  allFieldGeometries: { [fieldId: number]: FieldType };
  drawRef: any;
  operation: OperationType;
  isNewField: boolean;
  mapContainerRef: any;
  mode: string;
  setMode: (mode: DrawMode) => void;
  setViewport: React.Dispatch<React.SetStateAction<ViewPortProps>>;
  viewport: ViewPortProps;
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
  mergeSplitType: string | null;
  setMergeSplitType: (type: typeof MERGE | typeof SPLIT | null) => void;
  clusHaveFetched: boolean;
};

const PinIcon = <IoMdPin />;

const FieldBoundaryEditor = ({
  reloadClus,
  loadingClus,
  cluPolygons,
  selectedClusPolygons,
  selectedClus,
  setSelectedClus,
  drawRef,
  featCollectionOfFieldBeingEdited,
  allFieldGeometries,
  operation,
  isNewField,
  mapContainerRef,
  mode,
  setMode,
  setViewport,
  viewport,
  mapRef,
  mergeSplitType,
  setMergeSplitType,
  clusHaveFetched,
}: FieldEditorPropsType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const { fieldId: fieldIdFromRoute, operationId } = useParams<OrdersRouteParams>();

  const [mergeSelectedPolygons, setMergeSelectedPolygons] = useState<number[]>([]);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [showClus, setShowClus] = useState(isNewField);
  const [isLoading, toggleLoading] = useState(false);
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);

  // Without the memo, the consuming useEffect fires on weird triggers like button clicks, moveend,
  // etc., which seem to have nothing to do with the field data changing 🤷
  const allActiveFieldFeaturesThisOperation = useMemo(() => {
    return Object.values(allFieldGeometries)
      .map((field) => field.features[0])
      .filter(
        ({ properties }) => properties.operation_id === Number(operationId) && properties.active,
      );
  }, [allFieldGeometries]);

  const activefieldCoords = featCollectionOfFieldBeingEdited?.features[0].geometry.coordinates;

  const updateCluFilter = (id: number) => {
    setSelectedClus((prev: number[]) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const updateMergeFilter = (fieldId) => {
    setMergeSelectedPolygons((prev) => {
      if (prev.includes(fieldId)) {
        return prev.filter((item) => item !== fieldId);
      }
      return [...prev, fieldId];
    });
  };

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, setMode, true, true);

  const handleMissingImage = (imageId: string, imgElem: HTMLImageElement) => {
    if (!mapRef.current?.hasImage(imageId)) {
      mapRef.current?.addImage(imageId, imgElem, { sdf: true });
      setMapHasLoaded(true);
    }
  };

  const clearAndResetEverything = () => {
    setMergeSplitType(null);
    setPopupInfo(null);
    setMergeSelectedPolygons([]);
    clearHighlightedSelected();
    drawRef.current.deleteAll();
    drawRef.current.changeMode(MODES.SELECT);
  };

  const highlightActiveField = (map: Map, numericFieldId: number) => {
    map.removeFeatureState({
      source: FIELD_BOUNDARIES_SOURCE_ID,
    });

    map.setFeatureState(
      { source: FIELD_BOUNDARIES_SOURCE_ID, id: numericFieldId },
      { [FIELD_SELECTED_FEATURE_STATE_KEY]: true },
    );
  };

  useEffect(() => {
    mode === MODES.DIRECT && setShowClus(false);
  }, [mode]);

  // Set the source data for the fields and clusters whenever field data changes
  useEffect(() => {
    const map = mapRef.current;

    if (!map || !mapHasLoaded) return;

    const fieldBoundarySource = map.getSource(FIELD_BOUNDARIES_SOURCE_ID) as GeoJSONSource;
    const clustersSource = map.getSource(CLUSTERS_SOURCE_ID) as GeoJSONSource;

    if (
      mapHasLoaded &&
      fieldBoundarySource &&
      clustersSource &&
      allActiveFieldFeaturesThisOperation
    ) {
      fieldBoundarySource.setData({
        type: 'FeatureCollection',
        features: allActiveFieldFeaturesThisOperation,
      });
      clustersSource.setData({
        type: 'FeatureCollection',
        features: getFieldCentroids(allActiveFieldFeaturesThisOperation),
      });
    }
  }, [allActiveFieldFeaturesThisOperation, mapHasLoaded]);

  /** Auto-zoom to selected field if available, otherwise zoom to all fields. */
  const autoZoom = (map: null | Map) => {
    if (
      !map ||
      !allActiveFieldFeaturesThisOperation.length ||
      (!isNewField && !featCollectionOfFieldBeingEdited)
    ) {
      return;
    }

    const sourceOfBbox: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: featCollectionOfFieldBeingEdited?.features || allActiveFieldFeaturesThisOperation,
    };

    const bbox = turfBbox(sourceOfBbox) as LngLatBoundsLike;
    const newZoom = map.fitBounds(bbox, { duration: 1000, padding: 50 }).getZoom();
    const newCenter = map.getCenter();

    setViewport((prev) => ({
      ...prev,
      zoom: newZoom,
      centerLatitude: newCenter.lat,
      centerLongitude: newCenter.lng,
    }));
  };

  const populateDrawToolWithFields = (coords: Position[][][]) => {
    const features = coords.map((geom) => {
      return polygon(geom, {
        id: uuid(),
        renderType: 'Polygon',
      });
    });

    drawRef.current.set({ type: 'FeatureCollection', features });
  };

  const handleAnyClick = useCallback(
    (evt: MapboxClickEvent) => {
      setFieldMapClickListeners({
        evt,
        updateCluFilter,
        mergeSplitSelect,
        mergeSplitType,
        fieldIdFromRoute,
        onFieldClick: (properties) => {
          setPopupInfo({
            ...evt.lngLat,
            content: (
              <FieldPopupContent clickedFieldFeatureProperties={properties} language={language} />
            ),
          });
        },
      });
    },
    [mergeSplitType, fieldIdFromRoute],
  );

  // Remove and re-add all click listeners. Does not seem to work if you pass in layerIds, so need
  // to be heavy-handed and add/remove all of them.
  useEffect(() => {
    const map = mapRef.current;

    if (!map || !mapHasLoaded) return;

    map.on('click', handleAnyClick);

    return () => {
      // CRITICAL, otherwise the click handlers will be stuck with the initial state values
      map.off('click', handleAnyClick);
    };
  }, [mapHasLoaded, handleAnyClick]);

  // When switching between fields, clear everything, zoom to + highlight the field being edited,
  // and populate the draw tool with the field's geometry.
  useEffect(() => {
    const map = mapRef.current;

    if (!map || !mapHasLoaded) return;

    clearAndResetEverything();
    autoZoom(map);

    if (activefieldCoords) {
      populateDrawToolWithFields(activefieldCoords);
      highlightActiveField(map, Number(fieldIdFromRoute));
    }
  }, [activefieldCoords, mapHasLoaded]);

  // Map setup via 'load' event
  useEffect(() => {
    if (!mapRef.current || mapHasLoaded || (!isNewField && !featCollectionOfFieldBeingEdited)) {
      return;
    }

    mapRef.current.on('load', ({ target: map }) => {
      if (!map.getSource(FIELD_BOUNDARIES_SOURCE_ID)) {
        map.addSource(FIELD_BOUNDARIES_SOURCE_ID, {
          type: 'geojson',
          promoteId: FIELD_SOURCE_PROMOTE_ID_KEY,
          data: { type: 'FeatureCollection', features: [] },
        });

        map.addLayer(ALL_FIELD_BOUNDARIES_LAYER_CONFIG);
      }

      if (!map.getSource(CLUSTERS_SOURCE_ID)) {
        map.addSource(CLUSTERS_SOURCE_ID, {
          ...FIELD_POINTS_SOURCE_CONFIG,
          data: { type: 'FeatureCollection', features: [] },
        });

        map.addLayer(CLUSTERS_LAYER_CONFIG);
        map.addLayer(CLUSTER_COUNT_LAYER_CONFIG);
        map.addLayer(FIELD_LABELS_AND_PINS_LAYER_CONFIG);
        map.addLayer(CLOSE_FIELD_LABELS_LAYER_CONFIG);
      }

      if (!map.getSource(CLUS_SOURCE_ID)) {
        map.addSource(CLUS_SOURCE_ID, {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [] },
        });

        CLU_LAYERS.forEach((layer) => {
          map.addLayer(layer as FillLayer | LineLayer, FIELDS_LAYER_IDS.clusters);
        });
      }

      symbolAsInlineImage(PinIcon).then((imgElem) => {
        handleMissingImage(MARKER_PIN_ICON_ID, imgElem);
      });

      map.on('styleimagemissing', ({ id }) => {
        symbolAsInlineImage(PinIcon).then((imgElem) => handleMissingImage(id, imgElem));
      });

      setFieldMapHoverListeners(map);
      setMapHasLoaded(true);
    });
  }, [featCollectionOfFieldBeingEdited, isNewField, allActiveFieldFeaturesThisOperation]);

  const addClusToEditor = useCallback(() => {
    if (!drawRef.current || !selectedClusPolygons.features.length) {
      return;
    }

    const ids = selectedClusPolygons.features
      .map((feature) =>
        feature.geometry.coordinates
          .map((geom) => drawRef.current.add(polygon(geom, { id: uuid(), renderType: 'Polygon' })))
          .flat(),
      )
      .flat();

    drawModeSetter(MODES.SELECT, ids)();
  }, [selectedClusPolygons]);

  // Filter selected CLUs layer based on selected CLUs
  useEffect(() => {
    if (mapRef.current?.getLayer(FIELDS_LAYER_IDS.cluFillSelected)) {
      mapRef.current.setFilter(FIELDS_LAYER_IDS.cluFillSelected, ['in', 'id', ...selectedClus]);
    }
  }, [selectedClus]);

  const highlightSplitMergeSelected = (feature) => {
    if (!mapRef.current) return;

    const newLayerId = getSplitMergeLayerId(feature.properties.id);

    if (mapRef.current.getLayer(newLayerId)) {
      mapRef.current.removeLayer(newLayerId).removeSource(newLayerId);
    } else {
      mapRef.current.addLayer(getSplitMergeLayerConfig(feature, newLayerId));
    }
  };

  const mergeSplitSelect = (event: MapMouseEvent) => {
    const features = event.target.queryRenderedFeatures(event.point, {
      layers: [FIELDS_LAYER_IDS.fieldBoundaries],
    });

    if (features.length) {
      const clickedFeature = features[0];
      const clickedFeatureProperties = clickedFeature.properties as FieldPropertiesType;

      const correspondingFullThing = allActiveFieldFeaturesThisOperation.find(
        (field) => field.properties.id === clickedFeatureProperties.id,
      );

      updateMergeFilter(clickedFeature.properties?.id);
      highlightSplitMergeSelected(correspondingFullThing);
    }
  };

  const clearHighlightedSelected = () => {
    setMergeSplitType(null);
    mergeSelectedPolygons.forEach((id) => {
      const layerId = getSplitMergeLayerId(id);

      if (mapRef.current?.getLayer(layerId)) {
        mapRef.current.removeLayer(layerId);
        mapRef.current.removeSource(layerId);
      }
    });
    setMergeSelectedPolygons([]);
  };

  // Set the source data for the CLUs whenever they change
  useEffect(() => {
    if (!mapHasLoaded || !cluPolygons) return;

    const clusSource = mapRef.current?.getSource(CLUS_SOURCE_ID) as GeoJSONSource | undefined;

    if (clusSource) {
      clusSource.setData({
        type: 'FeatureCollection',
        features: cluPolygons,
      });
    }
  }, [mapHasLoaded, cluPolygons]);

  const drawModeSetter = (drawType: DrawMode, ids?: string[]) => () => {
    setSelectedClus([]);
    setMode(drawType);
    if (drawType === MODES.SELECT && !showClus) {
      setShowClus(true);
    } else {
      setShowClus(false);
    }
    clearHighlightedSelected();
    const featureIds = ids || [];
    drawRef.current.changeMode(drawType, { featureIds: featureIds });
  };

  const handleDelete = () => {
    const selectedIds = drawRef.current.getSelectedIds();
    drawRef.current.delete(selectedIds);
  };

  const startMergeFields = () => {
    setPopupInfo(null);

    if (mergeSplitType !== MERGE) {
      setShowClus(false);
      setMergeSplitType(MERGE);
      drawRef.current.changeMode(MODES.SELECT);
      drawRef.current.deleteAll();
    }
  };

  const startSplitFields = () => {
    setPopupInfo(null);

    if (mergeSplitType !== SPLIT) {
      drawRef.current.deleteAll();
      setShowClus(false);
      setMergeSplitType(SPLIT);
      setMode(MODES.DRAW_POLYGON);
      drawRef.current.changeMode(MODES.DRAW_POLYGON);
    }
  };

  const updateOperation = async () => {
    try {
      const newOperation = await requestSingleOperation(operation.id);
      dispatch(receiveSingleOperation(newOperation));
      dispatch(clearFieldGeometries());
    } catch (err) {
      showToast(err.message, 'error');
    }
  };

  const confirmMerge = async () => {
    try {
      toggleLoading(true);
      await requestMergeFields(mergeSelectedPolygons[0], mergeSelectedPolygons.slice(1));
      showToast(getString('fieldsMergedSuccessMsg', language));
      await updateOperation();
    } catch (e) {
      showToast(e.message, 'error');
    } finally {
      toggleLoading(false);
      clearHighlightedSelected();
    }
  };

  const confirmSplit = async (newFieldName: string, fateAfterCreate: SplitFieldCreateFate) => {
    try {
      toggleLoading(true);

      const selectedPoly: FeatureCollection<Polygon> = drawRef.current.getAll();
      const selectedPolyCentroid = centroid(selectedPoly);
      const selectedField = allActiveFieldFeaturesThisOperation.find((single) =>
        booleanPointInPolygon(selectedPolyCentroid, single),
      );

      if (selectedField) {
        const intersectionArea = intersect(selectedField, selectedPoly.features[0]);
        const differenceArea = difference(selectedField, selectedPoly.features[0]);

        if (intersectionArea && differenceArea) {
          intersectionArea.properties = {
            name: newFieldName,
            farm_name: selectedField.properties.farm_name,
          };

          differenceArea.properties = selectedField.properties;

          const response = await requestSplitFields(selectedField.properties.id, [
            intersectionArea,
            differenceArea,
          ]);

          await updateOperation();
          showToast(getString('fieldSplitSuccessMsg', language));

          if (fateAfterCreate === 'view-new-field') {
            clearAndResetEverything();
            setMode(MODES.SELECT);

            const createdField = response.features.find(
              (feat) => feat.properties.name === newFieldName,
            );

            navigate(`../${createdField?.properties.id}`, { relative: 'path' });
          } else if (fateAfterCreate === 'split-parent-again') {
            startSplitFields();
          }
        } else {
          showToast(getString('missingSelectionOverlapMsg', language), 'error');
        }
      } else {
        showToast(getString('missingSelectionOverlapMsg', language), 'error');
      }
    } catch (e) {
      showToast(e.message, 'error');
    } finally {
      toggleLoading(false);
    }
  };

  const cluInstructions = () => {
    const isSelectMode = mode === MODES.SELECT;

    const message = (() => {
      if (showClus) {
        if (selectedClusPolygons.features.length) {
          return 'cluInstructions2';
        }

        if (viewport.zoom < CLU_ZOOM_THRESHOLD) {
          return 'cluInstructions3';
        }

        return cluPolygons?.length || loadingClus ? 'cluInstructions1' : 'cluInstructions0';
      }

      if (isSelectMode && mergeSplitType === MERGE) {
        return 'selectFieldsToMerge';
      }

      if (mode === MODES.DRAW_POLYGON && mergeSplitType === SPLIT) {
        return 'selectFieldsToSplit';
      }

      if (!isNewField && isSelectMode && drawRef.current?.getAll().features.length) {
        return 'clickFieldToEdit';
      }

      if (
        isSelectMode &&
        !featCollectionOfFieldBeingEdited?.features[0].properties.name &&
        drawRef.current?.getAll().features.length
      ) {
        return 'addFieldInstructions';
      }

      return '';
    })();

    return (
      <>
        {Boolean(message.length) && (
          <div className={styles.InstructionsContainer}>
            <p className={styles.InstructionText}>{getString(message, language)}</p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.Map}>
      <div ref={mapContainerRef} className={styles.Map}>
        {popupInfo && mapRef.current && (
          <Popup
            {...popupInfo}
            map={mapRef.current}
            anchor="bottom"
            onClose={() => setPopupInfo(null)}
          >
            {popupInfo.content}
          </Popup>
        )}
      </div>
      <DrawingToolsV2
        showClus={showClus}
        drawModeSetter={drawModeSetter}
        handleDelete={handleDelete}
        startMergeFields={startMergeFields}
        startSplitFields={startSplitFields}
        mode={mode}
        mergeSplitType={mergeSplitType}
        mergeSelectedPolygons={mergeSelectedPolygons}
        confirmMerge={confirmMerge}
        confirmSplit={confirmSplit}
        cancelSplitMerge={clearAndResetEverything}
        language={language}
        drawRef={drawRef}
        isLoading={isLoading}
      />
      {mode === MODES.SELECT && showClus && (
        <ClusButtons
          disableAdd={mode !== MODES.SELECT || !selectedClus?.length}
          disableReload={mode !== MODES.SELECT || viewport.zoom < CLU_ZOOM_THRESHOLD}
          isLoading={loadingClus}
          language={language}
          hasFetched={clusHaveFetched}
          onAddClick={addClusToEditor}
          onReloadClick={reloadClus}
        />
      )}
      <div className={styles.Recenter}>
        <Button variant="white" onClick={() => autoZoom(mapRef.current)}>
          {getString('recenter', language)}
        </Button>
      </div>
      {cluInstructions()}
    </div>
  );
};

export default FieldBoundaryEditor;
