import { Accordion, Grid, Text, Title } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { dollarsPerAcreToRealPerHectare, dollarsToReal } from 'util/units';

import OrderSummaryGrid from './OrderSummaryGrid';

type Props = {
  perAcreTotalPrice: number;
  perZoneTotalPrice: number;
  totalPrice: number;
  titleText: string;
  zoneCount: number;
};

const PaddedColumn = ({ text }: { text: string | number }) => {
  return (
    <Grid.Col span={1}>
      <Text pl="sm">{text}</Text>
    </Grid.Col>
  );
};

const OrderSummaryAccordionControl = ({
  perAcreTotalPrice,
  perZoneTotalPrice,
  totalPrice,
  titleText,
  zoneCount,
}: Props) => {
  const language = useBroswerLanguage();
  const perZoneTotalPriceString = perZoneTotalPrice
    ? `$${dollarsToReal(perZoneTotalPrice, language).toLocaleString()}`
    : '';
  const perAcreTotalPriceString = perAcreTotalPrice
    ? `$${dollarsPerAcreToRealPerHectare(perAcreTotalPrice, language).toLocaleString()}`
    : '';
  const total = dollarsToReal(totalPrice, language).toLocaleString();

  return (
    <Accordion.Control>
      <OrderSummaryGrid>
        <Grid.Col span={2}>
          <Title fw="bold" order={4}>
            {titleText}
          </Title>
        </Grid.Col>
        <PaddedColumn text={zoneCount} />
        <PaddedColumn text={perZoneTotalPriceString} />
        <PaddedColumn text={perAcreTotalPriceString} />
        <PaddedColumn text={`$${total}`} />
      </OrderSummaryGrid>
    </Accordion.Control>
  );
};

export default OrderSummaryAccordionControl;
