import { Button, Group, Modal, Stack, Text } from '@mantine/core';

import { useBillingTranslations } from './hooks';

type Props = {
  opened: boolean;
  onSave: (isPaymentOnly: boolean) => void;
  onClose: VoidFunction;
  salesOrderAmount: number;
  isLoading: boolean;
  isPaymentOnly: boolean;
};

const PaymentModal = ({
  onSave,
  onClose,
  opened,
  salesOrderAmount,
  isLoading,
  isPaymentOnly,
}: Props) => {
  const translations = useBillingTranslations();
  return (
    <Modal
      opened={opened}
      title={isPaymentOnly ? translations.createPaymentOnly : translations.createSalesOrders}
      onClose={onClose}
      centered
    >
      <Stack>
        <Text>
          <b>{translations.amount}:</b> ${salesOrderAmount}
        </Text>
        <Group justify="flex-end">
          <Button variant="outline" onClick={onClose} loading={isLoading}>
            {translations.cancel}
          </Button>
          <Button onClick={() => onSave(isPaymentOnly)}>{translations.create}</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default PaymentModal;
