import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BarChart } from '@mantine/charts';
import { Box, Button, Divider, Grid, Paper, Text, Tooltip } from '@mantine/core';
import { Bar, Cell, XAxis } from 'recharts';

import { RISK_FILL_COLORS } from 'constants/colors';
import {
  BASE_TREATMENT,
  INPUTS_MAP,
  PRODUCT_NONE_ID,
  SEED,
  SEED_TREATMENT,
  TRAITS,
  TREATMENT,
} from 'constants/cropPlan';
import { CROP_PLANNING_ADD_INPUT, CROP_PLANNING_REMOVE_INPUT } from 'constants/firebase';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';
import {
  ANAEROBIC_POTENTIAL_ID,
  BIOFERTILITY,
  DENITRIFICATION_POTENTIAL_ID,
  PLANNING_TABS,
} from 'constants/results';

import { getRiskColorFill, remToPx } from 'util/chartUtils';
import {
  getCatalogSeedsProducts,
  getChartPixelHeight,
  getMaxProtectionForAnalytic,
  getRatingColor,
  getRatingText,
  getRiskLevel,
} from 'util/cropPlans';
import useFirebase from 'util/hooks/useFirebase';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAnalyticFromPlan, getCategoryFromParams } from 'util/results';
import { getLastSamplingPlanWithResults } from 'util/samplePlan';
import { endEllipsis } from 'util/stringUtils';
import { getString } from 'strings/translation';
import showToast, { ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import { AnalyticType } from 'store/analytics/types';
import { CatalogType } from 'store/catalogs/types';
import { requestDeleteAssignInput, requestPutAssignInput } from 'store/cropPlans/requests';
import { CropPlanType, ProductConnectionType, SeedConnectionType } from 'store/cropPlans/types';
import { getFieldGeometry } from 'store/fields/thunks';
import { FieldType } from 'store/fields/types';

import AddInput from './AddInput';

import styles from './CropPlanChart.module.css';

interface Props {
  analytics: AnalyticType[];
  fieldGeometry: FieldType;
  cropType: string;
  defaultCatalog: CatalogType | null;
  selectedChartInfo: { label: string; value: string | number } | null;
  cropPlan: CropPlanType;
}

type ParamsType = {
  operationId: string;
  analysis: string;
};

const CropPlanChart = ({
  analytics,
  fieldGeometry,
  cropType,
  defaultCatalog,
  selectedChartInfo,
  cropPlan,
}: Props) => {
  const { analysis } = useParams<ParamsType>();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  const firebase = useFirebase();

  const isSeed = !!selectedChartInfo && [SEED, TRAITS].includes(selectedChartInfo?.label);

  const analyticCategory = getCategoryFromParams(analysis);

  const seedProductCats = selectedChartInfo
    ? getCatalogSeedsProducts(
        defaultCatalog,
        cropType,
        analyticCategory,
        selectedChartInfo?.label,
        isSeed,
        language,
      )
    : [];

  const baseTreatment =
    selectedChartInfo?.label === TREATMENT
      ? getCatalogSeedsProducts(
          defaultCatalog,
          cropType,
          analyticCategory,
          BASE_TREATMENT,
          isSeed,
          language,
        )
      : [];

  const chartColumnHeight = getChartPixelHeight(true, seedProductCats.length);
  const chartPaperHeight = getChartPixelHeight(false, seedProductCats.length);

  const { analyticCategories } = useSelector((state: RootState) => ({
    analyticCategories: state.analytics.analyticCategories,
  }));

  const collectedValues = (() =>
    analytics.reduce(
      (collected, analytic) => {
        const analyticId =
          analytic.display_config.secondary_analytic_id &&
          cropPlan.outstanding_risks.analytics.hasOwnProperty(
            analytic.display_config.secondary_analytic_id,
          )
            ? analytic.display_config.secondary_analytic_id
            : analytic.id;
        if (!cropPlan.outstanding_risks.analytics.hasOwnProperty(analyticId)) {
          return collected;
        }
        const samplingPlan = getLastSamplingPlanWithResults(
          fieldGeometry.features[0].properties,
          analyticCategories,
        );
        const analyticCoverage = cropPlan.outstanding_risks.analytics[analyticId || -1];
        const analyticValue = getAnalyticFromPlan(samplingPlan, analytic as AnalyticType);

        const invert =
          analyticCategory === BIOFERTILITY &&
          ![ANAEROBIC_POTENTIAL_ID, DENITRIFICATION_POTENTIAL_ID].includes(analyticId);

        const analyzed = analyticCoverage?.analyzed_risk || 0;
        const optimized = (invert ? analyzed : analyticCoverage?.adjusted_risk) || 0;
        const coverage = analyzed - (analyticCoverage?.adjusted_risk || 0);

        const optimizedBar = invert ? 3 - (optimized - (optimized > 0 ? 1 : 0)) : optimized || 1;
        const bioCoverage = Math.min(optimizedBar + coverage, 3) - optimizedBar;
        const pathogenCoverage = coverage > 0 ? coverage - (optimized ? 0 : 1) : coverage;
        const coverageBar = invert ? bioCoverage : pathogenCoverage;
        return collected.concat([
          {
            id: analyticId,
            analyticId: analyticId,
            analyticName: analytic.name,
            riskLevel: analyticCoverage?.adjusted_risk || '',
            optimized,
            optimizedBar,
            coverage,
            coverageBar,
            actual: analyticValue?.quantity,
            units: analyticValue?.unit || '',
            optimizedFill: getRiskColorFill(getRiskLevel(optimized)),
            optimizedOpacity: invert && coverageBar ? 0.6 : 1.0,
            coverageFill: invert ? RISK_FILL_COLORS.LOW_RISK : 'red',
            coverageOpacity: invert ? 1.0 : 0.2,
          },
        ]);
      },
      [] as {
        id: number;
        analyticId: number;
        analyticName: string;
        riskLevel: string | number;
        optimized: number;
        optimizedBar: number;
        coverage: number;
        coverageBar: number;
        actual: number | undefined;
        units: string | undefined;
        optimizedFill: string;
        optimizedOpacity: number;
        coverageFill: string;
        coverageOpacity: number;
      }[],
    ))();

  const analyticIds = collectedValues.map((barData) => barData.analyticId);

  const endBarData = {
    analyticName: '',
    quantity: 0,
    optimized: 0,
    optimizedBar: 0,
    coverage: 0,
    coverageBar: 0,
    id: undefined,
    analyticId: undefined,
    riskLevel: '',
    optimizedFill: 'transparent',
    coverageFill: 'transparent',
    actual: undefined,
    units: undefined,
    optimizedOpacity: 0,
    coverageOpacity: 0,
  };

  const barData = [endBarData, ...collectedValues, endBarData];

  const getRowProtection = (payload: { index: number; value: number }) => {
    if (!payload.index) {
      return (
        <Text fw={600} size="xs">
          {getString('protection', language)}
        </Text>
      );
    }
    if (payload.index === barData.length - 1) {
      return <Box />;
    }
    const barAnalytic = barData[payload.index];

    const maxProtectionRating = barAnalytic.analyticId
      ? getMaxProtectionForAnalytic(barAnalytic.analyticId, cropPlan)
      : 0;
    return (
      <Paper
        key={payload.index}
        bg={getRatingColor(maxProtectionRating)}
        h="2rem"
        className={styles.LevelPaper}
      >
        <Text size="xs">{getRatingText(maxProtectionRating, language, true)}</Text>
      </Paper>
    );
  };

  const setToastMessage = (message: string, type?: ToastType, seconds = 3000) =>
    showToast(message, type, seconds);

  const handleSelectInput = async (
    input: SeedConnectionType | ProductConnectionType,
    removing: boolean,
    category: string,
  ) => {
    try {
      toggleIsLoading(true);
      const inputIdKey = isSeed ? 'seed_id' : 'agronomic_product_id';
      if (removing) {
        await requestDeleteAssignInput(cropPlan.id, {
          [inputIdKey]: input.id,
          category: INPUTS_MAP[category],
        });
        firebase.logEvent(CROP_PLANNING_REMOVE_INPUT, { input_id: input.id });
      } else {
        await requestPutAssignInput(cropPlan.id, {
          [inputIdKey]: input.id,
          category: INPUTS_MAP[category],
        });
        firebase.logEvent(CROP_PLANNING_ADD_INPUT, { input_id: input.id });
      }
      setToastMessage(getString('updateFieldCropSuccessMsg', language));
      dispatch(getFieldGeometry(fieldGeometry.features[0].properties.id));
    } catch (error) {
      setToastMessage(getString('updateFieldCropErrorMsg', language), 'error');
    } finally {
      toggleIsLoading(false);
    }
  };

  const getInputOptions = (
    idx: number,
    payload: { index: number; value: number },
    cat: SeedConnectionType | ProductConnectionType,
    category: string,
  ) => {
    const productCategoryKey = category === TREATMENT ? SEED_TREATMENT : category;
    const inputOnPlan = isSeed
      ? cropPlan.seeds.find((input) => input.id === cat.id)
      : cropPlan.products.find(
          (product) => product.category === productCategoryKey && product.id === cat.id,
        );
    const btPlacement = category === BASE_TREATMENT ? !inputOnPlan : false;
    const name = isSeed
      ? (cat as SeedConnectionType)?.hybrid
      : (cat as ProductConnectionType)?.name;
    if (!payload.index) {
      return (
        <Tooltip label={name} position="top-start" color="blue">
          <Text
            fw={inputOnPlan || btPlacement ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD}
            size="xs"
            style={{
              border: inputOnPlan ? '1px solid' : '',
              borderRadius: '0.5rem',
              padding: '0 0.5rem',
              wordWrap: 'break-word',
            }}
            maw="5rem"
          >
            {endEllipsis(name, 14)}
          </Text>
        </Tooltip>
      );
    }
    if (payload.index === barData.length - 1) {
      return (
        <Button
          size="xs"
          variant={inputOnPlan ? 'filled' : 'outline'}
          onClick={() => handleSelectInput(cat, !!inputOnPlan, category)}
          loading={isLoading}
        >
          {getString(inputOnPlan ? 'remove' : 'select', language)}
        </Button>
      );
    }
    const analytic = barData[payload.index];
    const ratings = inputOnPlan ? inputOnPlan.ratings : cat.ratings;
    const analyticRating = ratings.find((rating) => rating.analytic_id === analytic.analyticId);
    return (
      <Paper
        bg={getRatingColor(analyticRating?.coverage_rating)}
        h="2rem"
        className={styles.LevelPaper}
      >
        <Text size="xs">{getRatingText(analyticRating?.coverage_rating, language)}</Text>
      </Paper>
    );
  };

  const ChartsHeader = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={-30}
          y={0}
          dy={0}
          width={80}
          height={chartColumnHeight}
          className={styles.HeaderForeign}
        >
          <Grid columns={1} justify="center" className={styles.StackNameAndRanges}>
            <Grid.Col span={1} h="3rem" className={styles.VerticalCenter}>
              <Text size="0.6rem">{payload.value}</Text>
            </Grid.Col>
            <Grid.Col span={1} h="3rem" className={!payload.index ? styles.LeftAlign : undefined}>
              {getRowProtection(payload)}
            </Grid.Col>
            {!!selectedChartInfo && (
              <>
                {!payload.index && (
                  <Grid.Col span={1} h="3rem" className={styles.LeftAlign}>
                    <Text fw={600} size="sm">
                      {getString('resistanceOptions', language)}
                    </Text>
                  </Grid.Col>
                )}
                {!!payload.index && <Grid.Col span={barData.length} h="3rem" />}
                {baseTreatment.length > 0 && (
                  <Grid.Col
                    span={1}
                    h="3rem"
                    className={!payload.index ? styles.LeftAlign : undefined}
                    key={0}
                  >
                    {getInputOptions(0, payload, baseTreatment[0], BASE_TREATMENT)}
                  </Grid.Col>
                )}
                {!!selectedChartInfo &&
                  seedProductCats.map((seedCat, idx) => (
                    <Grid.Col
                      span={1}
                      h="3rem"
                      className={!payload.index ? styles.LeftAlign : undefined}
                      key={idx}
                    >
                      {getInputOptions(idx, payload, seedCat, selectedChartInfo?.label)}
                    </Grid.Col>
                  ))}
              </>
            )}
          </Grid>
        </foreignObject>
      </g>
    );
  };

  const otherCategories = PLANNING_TABS.filter((tab) => tab !== analysis);

  const SelectedInputsHeader = (props: any) => {
    const { x, y, payload, label, inputs } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={-30}
          y={0}
          dy={0}
          width={80}
          height={chartColumnHeight}
          className={styles.HeaderForeign}
        >
          <Grid columns={1} justify="center" className={styles.StackNameAndRanges}>
            {!payload.index && (
              <Grid.Col span={1} h="3rem" className={styles.LeftAlign}>
                <Text fw={600} size="sm">
                  {getString(label, language)}
                </Text>
              </Grid.Col>
            )}
            {!!payload.index && <Grid.Col span={barData.length} h="3rem" />}
            {inputs.map((input, idx) => (
              <Grid.Col
                span={1}
                h="3rem"
                className={!payload.index ? styles.LeftAlign : undefined}
                key={idx}
              >
                {getSelectedRow(idx, payload, input)}
              </Grid.Col>
            ))}
          </Grid>
        </foreignObject>
      </g>
    );
  };

  const getSelectedRow = (
    idx: number,
    payload: { index: number; value: number },
    input: ProductConnectionType,
  ) => {
    if (!payload.index) {
      return (
        <Tooltip label={input.name} position="top-start" color="blue">
          <Text
            fw={FONT_WEIGHT_STANDARD}
            size="xs"
            style={{
              padding: '0 0.5rem',
              wordWrap: 'break-word',
            }}
            maw="5rem"
          >
            {endEllipsis(input.name, 14)}
          </Text>
        </Tooltip>
      );
    }
    if (payload.index < barData.length - 1) {
      const analytic = barData[payload.index];
      const analyticRating =
        input.ratings.find((rating) => rating.analytic_id === analytic.analyticId)
          ?.coverage_rating || 0;
      return (
        <Paper
          key={payload.index}
          bg={getRatingColor(analyticRating)}
          h="2rem"
          className={styles.LevelPaper}
        >
          <Text size="xs">{getRatingText(analyticRating, language, true)}</Text>
        </Paper>
      );
    }
    return <></>;
  };

  return (
    <Grid
      gutter="xs"
      columns={10}
      className={styles.ChartWrapper}
      style={{ minHeight: chartPaperHeight }}
    >
      <Paper className={styles.ChartPaper} style={{ minHeight: chartPaperHeight }}>
        <BarChart
          h={200}
          data={barData}
          dataKey="analyticName"
          style={{ marginLeft: -30, padding: -10 }}
          withXAxis={false}
          withTooltip={false}
          yAxisProps={{
            domain: [0, 4],
            tickCount: 3,
            tick: { dy: 15, dx: 30, fontSize: 10 },
            ticks: [1, 2, 3],
            tickFormatter: (value) => {
              if (value === 3) {
                return 'High';
              }
              if (value === 2) {
                return 'Mod';
              }
              return 'Low';
            },
          }}
          gridProps={{ horizontalValues: [0, 1, 2, 3] }}
          series={[]}
        >
          <XAxis
            dataKey="analyticName"
            minTickGap={2}
            xAxisId={1}
            interval={0}
            height={60}
            tick={<ChartsHeader />}
            orientation="bottom"
            axisLine={false}
          />
          <Bar dataKey="optimizedBar" stackId="a">
            {barData.map((entry, index) => (
              <Cell
                key={`cell-${index}-${entry.analyticId}`}
                fill={entry.optimizedFill}
                opacity={entry.optimizedOpacity}
              />
            ))}
          </Bar>
          <Bar dataKey="coverageBar" stackId="a">
            {barData.map((entry, index) => (
              <>
                <Cell
                  key={`cell-${index}-a-${entry.analyticId}`}
                  fill={entry.coverageFill}
                  opacity={entry.coverageOpacity}
                />
              </>
            ))}
          </Bar>
        </BarChart>
      </Paper>
      {selectedChartInfo && (
        <AddInput
          analyticCategory={analyticCategory}
          cropPlan={cropPlan}
          catalog={defaultCatalog}
          selectedChartInfo={selectedChartInfo}
          operationId={fieldGeometry.features[0].properties.operation_id}
        />
      )}
      {otherCategories.map((tab, tIndex) => {
        const selectedInputs = cropPlan.products.filter(
          (input) =>
            input.id !== PRODUCT_NONE_ID &&
            input.analytic_category === getCategoryFromParams(tab) &&
            input.ratings.filter(
              (cr) => cr.coverage_rating > 0 && analyticIds.includes(cr.analytic_id),
            ).length,
        );
        if (!selectedInputs.length) {
          return <></>;
        }
        return (
          <Paper
            className={styles.ChartPaper}
            style={{ minHeight: remToPx(selectedInputs.length + 2), paddingTop: 0 }}
            key={`${cropPlan.id}_${tab}`}
          >
            {tIndex === 0 && <Divider variant="dashed" size="sm" p="xs" mx="lg" />}
            <BarChart
              h={50}
              data={barData}
              dataKey="analyticName"
              style={{ marginLeft: -30, padding: -10 }}
              withXAxis={false}
              withTooltip={false}
              gridProps={{ horizontalValues: [0, 1, 2, 3] }}
              series={[]}
            >
              <XAxis
                minTickGap={2}
                xAxisId={2}
                interval={0}
                height={1}
                tick={<SelectedInputsHeader label={tab} inputs={selectedInputs} />}
                orientation="top"
                axisLine={false}
                tickSize={0}
              />
            </BarChart>
          </Paper>
        );
      })}
    </Grid>
  );
};

export default CropPlanChart;
