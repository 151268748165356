import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';

import { SamplePricePost } from './types';

export const getSamplingPrices = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/sample_prices/`));

export const postSamplePrice = async (body: SamplePricePost) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/sample_prices/`, { body }));

export const putSamplePrice = async (user_id: number, body: SamplePricePost) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/sample_prices/${user_id}`, { body }));

export const deleteSamplePrice = async (user_id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/sample_prices/${user_id}`));
