import React, { ReactNode, useRef, useState } from 'react';
import { Button, Container, Group, Modal, Space, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { UploadIcon } from 'common';

import styles from './UploadDialog.module.css';

type FileUploadDialogPropTypes = {
  minDimension?: number;
  maxFileSize?: number;
  dialogLabel?: string;
  onUpload: (file: File) => void;
  onClose: () => void;
  onAbort?: () => void;
  open: boolean;
  accept?: string;
  message?: string;
  children?: ReactNode;
};

export const FileUploadDialog = ({
  minDimension,
  maxFileSize,
  dialogLabel,
  onUpload,
  onClose,
  onAbort,
  open,
  accept = '.csv',
  message,
  children,
}: FileUploadDialogPropTypes) => {
  const language = useBroswerLanguage();
  const [uploadError, setUploadError] = useState('testing the thing breaks');
  const [isUploading, setIsUploading] = useState(false);
  const [imageDimension, setImageDimension] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadError('');
    setFile(e.target.files && e.target.files[0]);
    checkDimensions(e.target.files && e.target.files[0]);
  };

  const openFileSelect = () => inputRef.current?.click();

  const checkDimensions = (imageFile) => {
    if (minDimension && imageFile.type.includes('image')) {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        const findImageDimension = img.width / img.height;
        setImageDimension(findImageDimension);
      };
    }
  };

  const handleUpload = async () => {
    if (file) {
      // if image, check dimensions
      const dimensionsTooSmall = minDimension && imageDimension && imageDimension < minDimension;
      if (dimensionsTooSmall) {
        setUploadError(getString('incorrectImageDimensions', language));
        return;
      }
      // check file size
      const fileTooLarge = maxFileSize && file.size > maxFileSize;
      if (fileTooLarge) {
        setUploadError(getString('fileSizeTooLarge', language));
        return;
      }
      setIsUploading(true);
      try {
        await onUpload(file);
        onClose();
      } catch (e) {
        setUploadError(e.message);
      }
      setIsUploading(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      opened={open}
      centered
      title={dialogLabel || getString('upload', language)}
    >
      <Container>
        <Space h="md" />
        <Text size="md">{message}</Text>
        {!!message && <Space h="md" />}
        <Group>
          <Button className={styles.FileButton} disabled={isUploading} onClick={openFileSelect}>
            <>
              <UploadIcon />
              {getString('upload', language)}
            </>
          </Button>
          <Space h="md" />
          <input accept={accept} hidden onChange={handleFileSelect} ref={inputRef} type="file" />
          <div className={styles.FileName}>{file?.name || getString('noFileChosen', language)}</div>
        </Group>
        {children}
        <Space h="xl" />
        <Text color="red">{uploadError}</Text>
        <Group justify="flex-end">
          <Button disabled={isUploading || !file} onClick={handleUpload}>
            {isUploading
              ? `${getString('submitting', language)}...`
              : getString('submit', language)}
          </Button>
          {onAbort && (
            <Button disabled={!isUploading} variant="danger" onClick={onAbort}>
              {getString('abort', language)}
            </Button>
          )}
        </Group>
      </Container>
    </Modal>
  );
};
