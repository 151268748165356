import {
  BORON_ID,
  BPH_ID,
  BPH_SIKORA_ID,
  CALCIUM_ID,
  CEC_ID,
  COPPER_ID,
  IRON_ID,
  MAGNESIUM_ID,
  MANGANESE_ID,
  NITROGEN_ID,
  OM_ID,
  PH_ID,
  PHOSPHORUS_ID,
  POTASSIUM_ID,
  SULFUR_ID,
  ZINC_ID,
} from './results';

export const TILLAGE_LAYER_TEST_RESULTS = 'testResults';
export const TILLAGE_LAYER_RX = 'rx';

export const TILL_RX_PROPERTIES_KEY = 'till_to_inch';
export const TILL_TEST_RESULTS_PROPERTIES_KEY = 'psi';

export const RX_INCH_LOW = 0;
export const RX_INCH_HIGH = 18;
export const COMPACTION_PSI_LOW = 0;
export const COMPACTION_PSI_HIGH = 600;

export const tillagePolygonLayerIds = {
  /** Aka "Compaction" */
  testResults: TILLAGE_LAYER_TEST_RESULTS,
  rx: TILLAGE_LAYER_RX,
} as const;

export const DCP_POINTS_LAYER_ID = 'dcpPoints';

export const PRO_MAP_THRESHOLDS = {
  [NITROGEN_ID]: {
    low: 60,
    high: 120,
  },
  [PHOSPHORUS_ID]: {
    low: 15,
    high: 45,
  },
  [POTASSIUM_ID]: {
    low: 120,
    high: 240,
  },
  [CALCIUM_ID]: {
    low: 100,
    high: 400,
  },
  [MAGNESIUM_ID]: {
    low: 10,
    high: 40,
  },
  [PH_ID]: {
    low: 5,
    high: 8,
  },
  [BPH_SIKORA_ID]: {
    low: 5.5,
    high: 7,
  },
  // Same range as BPH_SIKORA_ID
  [BPH_ID]: {
    low: 5.5,
    high: 7,
  },
  [CEC_ID]: {
    low: 0,
    high: 30,
  },
  [OM_ID]: {
    low: 0,
    high: 3,
  },
  [BORON_ID]: {
    low: 0,
    high: 0.75,
  },
  [COPPER_ID]: {
    low: 0.45,
    high: 0.72,
  },
  [IRON_ID]: {
    low: 97.5,
    high: 112.5,
  },
  [MANGANESE_ID]: {
    low: 21.7,
    high: 27.7,
  },
  [SULFUR_ID]: {
    low: 9,
    high: 39,
  },
  [ZINC_ID]: {
    low: 1.05,
    high: 2.25,
  },
};
