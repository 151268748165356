import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Center, Loader, Stack, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { AnalyticSortOrderType } from 'store/analytics/types';

import AnalyticOrderRow from './AnalyticOrderRow';

import styles from './Container.module.css';

const AnalyticOrder = () => {
  const language = useBroswerLanguage();

  const { analyticSortOrder, allAnalytics } = useSelector((state: RootState) => ({
    analyticSortOrder: state.analytics.analyticSortOrder,
    allAnalytics: state.analytics.allAnalytics,
  }));

  const [tempCropAnalyticsOrder, setCropAnalyticsOrder] = useState<AnalyticSortOrderType | null>(
    null,
  );

  useEffect(() => {
    if (!tempCropAnalyticsOrder && analyticSortOrder) {
      setCropAnalyticsOrder(analyticSortOrder);
    }
  }, [tempCropAnalyticsOrder, analyticSortOrder]);

  if (!tempCropAnalyticsOrder) {
    return (
      <Center h="60vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Stack gap="lg">
      {Object.keys(tempCropAnalyticsOrder).map((category) => (
        <Stack key={category} pl="md" className={styles.BorderBottom}>
          <Text size="md" fw={600}>
            {getString(category, language)}
          </Text>
          {tempCropAnalyticsOrder &&
            Object.keys(tempCropAnalyticsOrder[category]).map((crop) => (
              <AnalyticOrderRow
                key={crop}
                cropType={crop}
                analyticCategory={category}
                analyticSortOrder={analyticSortOrder}
                tempCropAnalyticsOrder={tempCropAnalyticsOrder}
                allAnalytics={allAnalytics}
                setCropAnalyticsOrder={setCropAnalyticsOrder}
              />
            ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default AnalyticOrder;
