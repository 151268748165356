import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Center, Loader, Stack } from '@mantine/core';

import { LOCATION } from 'constants/agency';

import { RootState } from 'store';
import { getAgency, getAgencyAssociatedUsers, getAgencyContracts } from 'store/agencies/thunks';
import { ManageAgencyRouteParams } from 'store/agencies/types';
import { getRoles } from 'store/roles/thunks';
import { userIsSuperAdmin } from 'store/user/selectors';
import { AdminAccess, SuperAdminAccess } from 'common';

import ManageAgency from './Agency/ManageAgency';
import ManageAgencyHierarchy from './Agency/ManageAgencyHierarchy';
import AgencyImage from './Image/AgencyImage';
import ManagePricing from './Pricing/Container';
import ManagePricingV2 from './PricingV2/Container';
import { AgencySampling } from './Sampling/AgencySampling';
import ManageAgents from './Users/ManageAgents';
import ManageCommercialUsers from './Users/ManageCommercialUsers';

const ManageAgencyContainer = () => {
  const dispatch = useDispatch();
  const agencyId = Number(useParams<ManageAgencyRouteParams>().agencyId);

  const { isFetching, isAdminOrSuper, agency } = useSelector((state: RootState) => ({
    isFetching: state.agencies.isFetching || state.agencies.isFetchingUsers,
    isAdminOrSuper: userIsSuperAdmin(state),
    agency: state.agencies.byId[agencyId],
  }));

  const fetchAgencyUsers = () => {
    dispatch(getAgencyAssociatedUsers(agencyId));
  };

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getAgency(agencyId));
    dispatch(getAgencyAssociatedUsers(agencyId));
    if (isAdminOrSuper) {
      dispatch(getAgencyContracts(agencyId));
    }
  }, [dispatch, agencyId, isAdminOrSuper]);

  if (isFetching || !agency) {
    return (
      <Center h="80vh">
        <Loader />
      </Center>
    );
  }

  const isAgencyOrParent = agency.hierarchy !== LOCATION;

  return (
    <Stack>
      <ManageAgency agency={agency} />
      {isAgencyOrParent && (
        <AdminAccess>
          <AgencyImage agencyId={agencyId} />
        </AdminAccess>
      )}
      {isAgencyOrParent && (
        <SuperAdminAccess>
          <ManageCommercialUsers agencyId={agencyId} fetchAgencyUsers={fetchAgencyUsers} />
        </SuperAdminAccess>
      )}
      <ManageAgents agency={agency} />
      {isAgencyOrParent && <ManageAgencyHierarchy agency={agency} />}
      {isAgencyOrParent && (
        <AdminAccess>
          <AgencySampling agencyId={agencyId} />
        </AdminAccess>
      )}
      <SuperAdminAccess>
        <ManagePricingV2 agency={agency} />
      </SuperAdminAccess>
      <SuperAdminAccess>
        <ManagePricing agency={agency} />
      </SuperAdminAccess>
    </Stack>
  );
};

export default ManageAgencyContainer;
