import {
  ORDER_HISTORY_REQUEST_ERROR,
  OrderHistoryActionsType,
  OrderHistoryType,
  RECEIVE_ORDER_HISTORY,
  REQUEST_ORDER_HISTORY,
} from './types';

export const requestOrderHistory = (): OrderHistoryActionsType => ({
  type: REQUEST_ORDER_HISTORY,
});

export const receiveOrderHistory = (payload: {
  page: number;
  items: OrderHistoryType[];
}): OrderHistoryActionsType => {
  return {
    type: RECEIVE_ORDER_HISTORY,
    payload,
  };
};

export const orderHistoryRequestError = (payload: {
  message: string;
}): OrderHistoryActionsType => ({
  type: ORDER_HISTORY_REQUEST_ERROR,
  payload,
});
