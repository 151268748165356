import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CloseButton, Combobox, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE } from 'util/request';
import sortByName from 'util/sortByName';
import { capitalizeEveryWord } from 'util/stringUtils';
import { requestUserSearch } from 'store/user/requests';
import { User, UserSelectorType } from 'store/user/types';

type OperationSearchBarPropsType = {
  onSelect: (val: UserSelectorType) => void;
  placeholder?: string;
  className?: string;
  userSelected?: UserSelectorType;
  defaultValue?: string;
  onDeselect?: () => void;
};

export const UserSearch = ({
  onSelect,
  placeholder,
  className,
  userSelected,
  defaultValue,
  onDeselect,
}: OperationSearchBarPropsType) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState<string>(defaultValue || '');
  const [selectorOptions, setSelectorOptions] = useState<UserSelectorType[]>([]);

  const debounceFetchUsers = useDebouncedCallback(
    async (name: string) => {
      if (name) {
        const newUsers = await requestUserSearch(name);

        const users = newUsers.users.map((user: User) => {
          const role = user.roles[0];
          const roleName = capitalizeEveryWord(role.name.replace('_', ' '));

          return {
            user_id: user.id,
            displayName: `${roleName}: ${user.first_name} ${user.last_name}: ${user.email}`,
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
          };
        });
        setSelectorOptions(sortByName([...users]).slice(0, 20));
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    if (userSelected) {
      debounceFetchUsers(`${userSelected?.first_name} ${userSelected?.last_name}`);
    }
  }, [userSelected]);

  useEffect(() => {
    if (!selected && selectorOptions.length && !loaded) {
      setLoaded(true);
      if (userSelected) {
        setSelected(userSelected?.displayName);
      }
    }
  }, [selectorOptions, selected, loaded]);

  const handleUserSelection = (str: string) => {
    const user = selectorOptions.find((o) => o.displayName === str);
    if (user) {
      onSelect(user);
      setSelected(user?.displayName);
    }
  };

  const handleTextChange = (event: any) => {
    setSelected(event.currentTarget.value);
    debounceFetchUsers(event.currentTarget.value);
    combobox.resetSelectedOption();
    combobox.openDropdown();
  };

  const clearSelected = () => {
    setSelected('');
    combobox.closeDropdown();
    onDeselect && onDeselect();
  };

  const options = (selectorOptions || []).map((item) => (
    <Combobox.Option value={item.displayName} key={item.id}>
      {item.displayName}
    </Combobox.Option>
  ));

  const RightSelect = () => {
    if (userSelected) {
      return (
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={clearSelected}
        />
      );
    }
    return null;
  };

  return (
    <Combobox onOptionSubmit={handleUserSelection} withinPortal={false} store={combobox}>
      <Combobox.Target>
        <TextInput
          className={className}
          placeholder={placeholder}
          value={selected}
          leftSection={<FiSearch />}
          onChange={handleTextChange}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          rightSection={<RightSelect />}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={!selectorOptions.length} onClick={() => combobox.closeDropdown()}>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
