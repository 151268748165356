import { useState } from 'react';
import { Button, Group, Text, TextInput } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { projectsQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { createCarbonCollection } from 'store/projects/requests';

import { useProjectRouteParams, useProjectsTranslations } from '../hooks';

const CreateCollectionForm = () => {
  const { samplingPlanId } = useProjectRouteParams();
  const translations = useProjectsTranslations();
  const queryClient = useQueryClient();
  const [collectionName, setCollectionName] = useState('');

  const createCollectionMutation = useMutation({
    mutationFn: (name: string) =>
      createCarbonCollection({
        carbon_sample_plan_id: samplingPlanId,
        name,
      }),
    onSuccess: () => {
      showToast(translations.collectionCreated, 'success');
      setCollectionName('');

      queryClient.invalidateQueries({
        queryKey: projectsQueryKeys.samplingPlan(samplingPlanId),
      });
    },
  });

  return (
    <Group
      component="form"
      align="baseline"
      onSubmit={(evt) => {
        evt.preventDefault();
        createCollectionMutation.mutate(collectionName);
      }}
    >
      <Text id="collection-name">{translations.collectionName}</Text>
      <TextInput
        aria-labelledby="collection-name"
        required
        flex={1}
        value={collectionName}
        onChange={(evt) => setCollectionName(evt.currentTarget.value)}
      />
      <Button type="submit">{translations.save}</Button>
    </Group>
  );
};

export default CreateCollectionForm;
