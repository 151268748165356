import React from 'react';

import { getString } from 'strings/translation';
import { HeatMapAnalyticType } from 'store/heatMap/types';

import styles from './HeatMap.module.css';

type HeatPopupPropsType = {
  heat: HeatMapAnalyticType;
  language: string;
  viewResults: (h: HeatMapAnalyticType) => void;
};

export const HeatPopup = ({ heat, language, viewResults }: HeatPopupPropsType) => {
  return (
    <div>
      <div>
        <b>{getString('account', language)}: </b>
        {heat.operation_name}
      </div>
      <div>
        <b>{getString('field', language)}: </b>
        {heat.field_name}
      </div>
      <div>
        <b>{getString('acres', language)}: </b>
        {Math.round(heat.calculated_area_acres)}
      </div>
      <div>
        <b>{getString('fieldRisk', language)}: </b>
        {getString(heat.risk_level, language)}
      </div>
      <div>
        <button
          className={styles.ViewResults}
          onClick={() => viewResults(heat)}
        >{`${getString('viewResults', language)} >`}</button>
      </div>
    </div>
  );
};
