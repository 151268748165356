import React, { useEffect, useMemo } from 'react';
import { Stack, Text } from '@mantine/core';
import {
  Area,
  Bar,
  Cell,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { DARK_BLUE, LIGHT_BLUE, NO_RISK_FILL } from 'constants/colors';

import {
  ChartBarType,
  defineRiskAreas,
  getChartAreaProps,
  sortQuantityBasedOnRiskLevels,
} from 'util/chartUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { isNumber, roundThreeDecimal } from 'util/numUtils';
import {
  getAnalyticFromPlan,
  getAnalyticId,
  getDomainMaxFromRiskRanges,
  roundAnalyticValue,
} from 'util/results';
import { getLastSamplingPlanWithResults } from 'util/samplePlan';
import { capitalizeEveryWord, endEllipsis } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { AnalyticType } from 'store/analytics/types';
import { FieldType, SamplingPlanType } from 'store/fields/types';

import styles from './FieldComparison.module.css';

type SummaryPropsType = {
  activeAnalytic: AnalyticType;
  samplingPlan: SamplingPlanType;
  operationFields: FieldType[];
  currentField: FieldType;
  setFieldComparisonComp: (arg0: string) => void;
  analyticCategories: string[];
};

const FieldComparison = ({
  activeAnalytic,
  samplingPlan,
  operationFields,
  currentField,
  setFieldComparisonComp,
  analyticCategories,
}: SummaryPropsType) => {
  const language = useBroswerLanguage();

  const analyticValue =
    samplingPlan.analytics[activeAnalytic.category]?.[getAnalyticId(activeAnalytic, samplingPlan)];

  const collectedValues = (() => {
    return operationFields.reduce((all: any[], field) => {
      const { properties } = field.features[0];
      const plan = getLastSamplingPlanWithResults(properties, analyticCategories);
      const value = getAnalyticFromPlan(plan, activeAnalytic);
      if (value?.quantity !== null && isNumber(value?.quantity)) {
        const quantity: number = roundThreeDecimal(value?.quantity) || 0;
        return [
          ...all,
          {
            id: properties.id,
            fieldName: properties.name,
            riskLevel: value?.risk_level || '',
            fieldId: properties.id,
            quantity,
          },
        ];
      }
      return all;
    }, []);
  })();

  const maxFromFields = collectedValues.reduce(
    (max, field) => (field.quantity > max ? field.quantity : max),
    0,
  );
  const maxFromSummary = getDomainMaxFromRiskRanges(analyticValue.risk_summary.risk_ranges);
  const maxRiskRange = maxFromSummary || maxFromFields;

  const barData = useMemo(
    (): ChartBarType[] => [
      {
        barIndex: 1,
        raw: analyticValue.quantity || 0,
        actual: roundThreeDecimal(analyticValue.quantity) || 0,
        quantity:
          analyticValue.quantity <= maxRiskRange
            ? roundThreeDecimal(analyticValue.quantity) || 0
            : maxRiskRange,
        label: currentField.features[0].properties.name,
        color: DARK_BLUE,
      },
      ...sortQuantityBasedOnRiskLevels(
        collectedValues.map((field, i) => ({
          barIndex: 1 + i,
          raw: field.quantity || 0,
          actual: field.quantity || 0,
          // Need to check for 0
          quantity: field.quantity <= maxRiskRange ? field.quantity : maxRiskRange,
          label: field.fieldName,
          color: LIGHT_BLUE,
        })),
        analyticValue.risk_summary?.risk_ranges,
        analyticValue.risk_summary?.risk_level_order,
      ),
    ],
    [analyticValue, collectedValues, currentField, maxRiskRange],
  );

  const minBarHeight = Math.min(...barData.map((bar) => bar.raw));
  const maxBarHeight = Math.max(...barData.map((bar) => bar.raw));

  useEffect(() => {
    setFieldComparisonComp(
      `${operationFields.length} ${getString('fieldsLower', language)}, ${getString(
        'ranging',
        language,
      )} ${roundAnalyticValue(minBarHeight)} - ${roundAnalyticValue(maxBarHeight)} ${
        analyticValue.unit || ''
      }`,
    );
  }, [
    operationFields,
    analyticValue.unit,
    language,
    minBarHeight,
    maxBarHeight,
    setFieldComparisonComp,
  ]);

  const chartHeight = maxBarHeight * 1.5;

  const axisValues = (() => {
    if (analyticValue) {
      const { risk_level_order } = analyticValue.risk_summary;
      const riskLevelAreas: {
        lowBound: number;
        highBound: number;
        color: string;
      }[] = defineRiskAreas(risk_level_order, analyticValue);

      return {
        risk_level_order,
        riskAreas: riskLevelAreas.map((riskArea, index) => {
          const highBound =
            riskArea.highBound === 0 && index === riskLevelAreas.length - 1
              ? maxRiskRange
              : riskArea.highBound;
          return (
            <Area
              {...getChartAreaProps()}
              key={index}
              dataKey={() => highBound - (index > 0 ? riskArea.lowBound : 0)}
              fill={riskArea.color}
              stroke={LIGHT_BLUE}
            />
          );
        }),
      };
    }
    return {
      risk_level_order: undefined,
      riskAreas: [
        <Area
          {...getChartAreaProps()}
          key={Math.random()}
          dataKey={() => chartHeight}
          fill={NO_RISK_FILL}
          stroke={NO_RISK_FILL}
        />,
      ],
    };
  })();

  const CustomYAxisTick = ({ y, payload }: { y: number; payload: { value: string } }) => {
    return (
      <g transform={`translate(${0},${y})`}>
        <foreignObject
          x={0}
          y={-9}
          textAnchor="start"
          // eslint-disable-next-line react/no-unknown-property
          fill={DARK_BLUE}
          width={150}
          height={40}
        >
          <span className={styles.CustomYTick}>{endEllipsis(payload.value, 29)}</span>
        </foreignObject>
      </g>
    );
  };

  const domainMax = analyticValue.risk_summary.risk_ranges ? maxRiskRange : chartHeight;

  const avgLevelsStr = `${getString('average', language)} ${capitalizeEveryWord(
    activeAnalytic.name,
  )} ${getString('levels', language)} (${analyticValue.unit || getString('noUnits', language)})`;

  return (
    <Stack className={styles.Wrapper}>
      <Text fw={700} className={styles.TitleName}>
        {avgLevelsStr}
      </Text>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="vertical"
          margin={{
            top: 10,
            right: 50,
            left: 0,
            bottom: 20,
          }}
          data={barData.slice(0, 9)}
        >
          <XAxis type="number" hide domain={[0, domainMax]} allowDataOverflow xAxisId={0} />
          <YAxis dataKey="label" yAxisId={1} type="category" width={160} tick={CustomYAxisTick} />
          <YAxis dataKey="label" type="category" scale="point" yAxisId={2} hide />
          {axisValues.riskAreas?.length && <>{axisValues.riskAreas}</>}
          <Bar dataKey="quantity" barSize={30} yAxisId={1} isAnimationActive={false}>
            <LabelList dataKey="actual" position="right" offset={1} />
            {barData.slice(0, 9).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default FieldComparison;
