import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import turfBbox from '@turf/bbox';
import buffer from '@turf/buffer';
import { feature, featureCollection } from '@turf/helpers';
import pointGrid from '@turf/point-grid';
import squareGrid from '@turf/square-grid';
import transformRotate from '@turf/transform-rotate';
import {
  Feature,
  FeatureCollection,
  GeometryCollection,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';

import { TURF_ILLEGAL_ARGUMENT_EXCEPTION } from 'constants/errorTypes';
import { GEOMETRY_COLLECTION, MULTIPOLYGON, POINT, POLYGON, ZONE_TYPES } from 'constants/mapbox';
import {
  CUSTOM_POINTS,
  DEM_ZONES,
  GRID_POINTS,
  GRID_ZONES,
  SSURGO_ZONES,
} from 'constants/samplePlanning';

import { defaultPlanDate, sortByCreatedAt } from 'util/date';
import { SampleGeoJSON } from 'util/generalTypes';
import {
  acresToFeetSideLength,
  clipZonesToLayer,
  filterPointsWithinBoundaries,
  filterZonesWithinBoundaries,
  mergeZonesUnderThreshold,
  processZoneFeatureCollection,
} from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleTimingsQueryOptions } from 'util/queryOptions';
import { filterSamplesWithPlan } from 'util/sample';
import { getLastCompletedPlan, getTargetNumberOfZones, isGridsOption } from 'util/samplePlan';
import { getDefaultAnalysisV3, setupExistingAnalysisV3 } from 'util/samplePlanV3';
import { haToAcres } from 'util/units';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { PreviewZonesType } from 'store/pricing/types';
import { getAgronomicZones } from 'store/samplePlans/requests';
import { AgronomicZonesType, ANALYSIS_TYPES, SAMPLE_STATUSES } from 'store/samplePlans/types';
import { getSamples } from 'store/samples/requests';

import { useOrderFormContext } from './orderFormContext';
import {
  useAllSkusQuery,
  useDefaultPricesQuery,
  useFieldQuery,
  useOperationQuery,
  useOperationUsersQuery,
} from './queries';

export const useDefaultListPrice = () => {
  const defaultPricingQuery = useDefaultPricesQuery();
  const { getValues } = useOrderFormContext();
  const { primaryProductId: selectedSkuId } = getValues();
  const defaultPricingOfSelectedSku = defaultPricingQuery.data?.[selectedSkuId] || null;

  return defaultPricingOfSelectedSku?.prices[0].list_price;
};

export const useZoneSetup = () => {
  const language = useBroswerLanguage();
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const drawRef = useRef<any>(null);

  const { data: sampleTimings } = useQuery(sampleTimingsQueryOptions);
  const { data: operation } = useOperationQuery();
  const { data: operationUsers } = useOperationUsersQuery();
  const { data: fieldFeatureCollection } = useFieldQuery();
  const { data: allSkus } = useAllSkusQuery();

  const allQueriesLoaded = !!(
    sampleTimings &&
    operation &&
    operationUsers &&
    fieldFeatureCollection &&
    allSkus
  );

  useEffect(() => {
    if (allQueriesLoaded) {
      (async function populatePlan() {
        await setupInitialAnalysis();
      })();
    }
  }, [allQueriesLoaded]);

  if (!allQueriesLoaded) {
    return null;
  }

  const resetPreviewScanGrid = (density: number) => {
    const { geometry, properties } = fieldFeatureCollection.features[0];
    const cellWidth = acresToFeetSideLength(haToAcres(density, properties.acreage_unit));
    const units = 'feet';
    const bufferedBbox = turfBbox(buffer(geometry, cellWidth * 2, { units }));
    const gridPoints = pointGrid(bufferedBbox, cellWidth, { units });

    form.setValues({
      zoneGeomType: POINT,
      previewScanPoints: processZoneFeatureCollection(
        gridPoints,
        POINT,
      ) as FeatureCollection<Point>,
    });
  };

  const resetPreviewGrid = (
    creationType: typeof GRID_ZONES | typeof GRID_POINTS,
    density: number,
  ) => {
    const { geometry, properties } = fieldFeatureCollection.features[0];
    const cellWidth = acresToFeetSideLength(haToAcres(density, properties.acreage_unit));
    const units = 'feet';
    const bufferedBbox = turfBbox(buffer(geometry, cellWidth * 2, { units }));

    if (creationType === GRID_POINTS) {
      form.setValues({ zoneGeomType: POINT });
      const gridPoints = pointGrid(bufferedBbox, cellWidth, { units });

      return processZoneFeatureCollection(gridPoints, POINT) as FeatureCollection<Point>;
    }

    form.setValues({ zoneGeomType: POLYGON });
    const gridPolys = squareGrid(bufferedBbox, cellWidth, { units });

    return processZoneFeatureCollection(gridPolys, POLYGON) as FeatureCollection<Polygon>;
  };

  const createAgronomicZones = async (numZones: number, zoneType: AgronomicZonesType) => {
    try {
      const zoneGeoms: FeatureCollection = await getAgronomicZones(
        fieldFeatureCollection.features[0].properties.id,
        numZones,
        zoneType,
      );

      const validGeomsOnly = zoneGeoms.features.reduce((list, feat) => {
        if ([POLYGON, MULTIPOLYGON].includes(feat.geometry?.type)) {
          return list.concat(feat as Feature<Polygon>);
        }
        if (feat.geometry?.type === GEOMETRY_COLLECTION) {
          const polyGeometry = (feat.geometry as GeometryCollection).geometries
            .filter((geo) => [POLYGON, MULTIPOLYGON].includes(geo.type))
            .map((geo) => feature(geo) as Feature<Polygon>);
          return list.concat(polyGeometry);
        }
        return list;
      }, [] as Feature<Polygon>[]);

      if (!validGeomsOnly.length) {
        throw new Error('Invalid geometries received.');
      }

      const mergedGeoms = await mergeZonesUnderThreshold(
        clipZonesToLayer(featureCollection(validGeomsOnly), fieldFeatureCollection),
        numZones,
        fieldFeatureCollection,
      );

      return processZoneFeatureCollection(
        mergedGeoms as FeatureCollection<Polygon>,
        POLYGON,
      ) as FeatureCollection<Polygon>;
    } catch (error) {
      // Check for issues with trying to merge and clip zones with turf
      if (error?.name === TURF_ILLEGAL_ARGUMENT_EXCEPTION) {
        showToast(getString('errorWithfieldFeatureCollectionMsg', language), 'error', 7000);
      } else {
        showToast(getString('unableToLoadZonesMsg', language), 'error', 7000);
      }
    }
    return null;
  };

  const createFinalizedGrid = async (
    creationType: typeof GRID_ZONES | typeof GRID_POINTS,
    previewZones: PreviewZonesType,
  ) => {
    try {
      if (previewZones) {
        if (creationType === GRID_POINTS) {
          const filteredPoints = filterPointsWithinBoundaries(
            previewZones,
            fieldFeatureCollection,
          ) as FeatureCollection<Point>;
          return processZoneFeatureCollection(filteredPoints, POINT) as FeatureCollection<Point>;
        }

        const intersectingZones = filterZonesWithinBoundaries(
          previewZones as FeatureCollection<MultiPolygon | Polygon>,
          fieldFeatureCollection,
        ) as FeatureCollection<Polygon>;
        const clippedZones = clipZonesToLayer(
          intersectingZones,
          fieldFeatureCollection,
        ) as FeatureCollection<MultiPolygon | Polygon>;

        const targetNumZones = getTargetNumberOfZones(
          fieldFeatureCollection.features[0].properties.acreage,
          formValues.density,
        );
        const mergedGeoms = await mergeZonesUnderThreshold(
          clippedZones,
          targetNumZones,
          fieldFeatureCollection,
        );
        return processZoneFeatureCollection(mergedGeoms, POLYGON) as FeatureCollection<Polygon>;
      }
      return null;
    } catch (err) {
      showToast(getString('errorWithfieldFeatureCollectionMsg', language), 'error', 7000);
      return null;
    }
  };

  const prepareCreatingZones = (creationOption: string, previewZones: PreviewZonesType) => {
    const targetZones = getTargetNumberOfZones(
      fieldFeatureCollection.features[0].properties.acreage,
      formValues.density,
    );
    if (creationOption === SSURGO_ZONES || creationOption === DEM_ZONES) {
      return createAgronomicZones(targetZones, creationOption);
    }
    if (creationOption === GRID_POINTS || creationOption === GRID_ZONES) {
      form.setValues({
        zoneGeomType: creationOption === GRID_ZONES ? POLYGON : POINT,
      });
      return createFinalizedGrid(creationOption, previewZones);
    }
    if (creationOption === CUSTOM_POINTS) {
      form.setValues({ zoneGeomType: POINT });
    }
    return null;
  };

  const createZones = async (previewZones: PreviewZonesType, setUserZones: boolean = false) => {
    const newZones = await prepareCreatingZones(formValues.creationOption, previewZones);
    if (setUserZones) {
      form.setValues({
        zones: newZones,
      });
    }

    form.setValues({ previewZones: null });
  };

  const prepareResetZones = (creationOption: string, newDensity: number = formValues.density) => {
    form.setValues({ zoneGeomType: POLYGON });

    if (creationOption === GRID_ZONES || creationOption === GRID_POINTS) {
      form.setValues({
        zoneGeomType: creationOption === GRID_ZONES ? POLYGON : POINT,
      });
      return resetPreviewGrid(creationOption, newDensity);
    }

    if (creationOption === CUSTOM_POINTS) {
      form.setValues({ zoneGeomType: POINT });
    }

    return null;
  };

  const resetZones = async (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
    newDensity: number = formValues.density,
  ) => {
    const previews = await prepareResetZones(creationOption, newDensity);
    const previewZones =
      isGridsOption(creationOption) && previews
        ? transformRotate(previews, formValues.gridAngle)
        : previews;
    if (creationType === ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION) {
      form.setValues({
        scanPoints: null,
        previewScanPoints: previewZones,
      });
    } else {
      form.setValues({ previewZones, zones: null });
    }
  };

  const setCompletedSamples = async () => {
    const { properties } = fieldFeatureCollection.features[0];
    const latestPlan = getLastCompletedPlan(properties);

    if (!latestPlan) return;

    const lastIsProTill = latestPlan.is_pro || latestPlan.is_till_rx;
    const sampleResponse = await getSamples(properties.id);

    const samples = filterSamplesWithPlan(sampleResponse.features, latestPlan);
    form.setValues({
      completedPlan: latestPlan,
      completedSamples: (lastIsProTill
        ? samples.filter((s) => s.properties.zone_type === ZONE_TYPES.POLYGON)
        : samples) as SampleGeoJSON[],
      completedScanPoints: lastIsProTill
        ? (samples.filter((s) => s.properties.zone_type === ZONE_TYPES.POINT) as SampleGeoJSON[])
        : [],
    });
  };

  const setGridZonesAsDefault = async () => {
    // Default creation Option is Grid 10ac
    const previews = await prepareResetZones(formValues.creationOption);

    form.setValues({
      existingPlan: null,
      samplePlanName: defaultPlanDate(),
      disableMapTools: true,
      zonesLocked: false,
      previewZones: previews
        ? transformRotate(previews, formValues.gridAngle)
        : formValues.previewZones,
    });
  };

  const setupInitialAnalysis = async () => {
    if (fieldFeatureCollection && operation && sampleTimings) {
      const { sampling_plans } = fieldFeatureCollection.features[0].properties;

      const existingPlan = sortByCreatedAt(sampling_plans).find(
        (plan) =>
          ![SAMPLE_STATUSES.CANCELLED, SAMPLE_STATUSES.COMPLETED].includes(plan.sampling_status),
      );

      try {
        if (existingPlan) {
          const newAnalysis = await setupExistingAnalysisV3(
            operation,
            operationUsers.users,
            fieldFeatureCollection,
            existingPlan,
            sampleTimings,
            allSkus,
          );
          form.setValues(newAnalysis);
          // TODO: Setup completed plans when applicable
        } else {
          const newAnalysis = getDefaultAnalysisV3(
            operation,
            operationUsers.users,
            sampleTimings,
            fieldFeatureCollection,
          );

          form.setValues(newAnalysis);
          await setGridZonesAsDefault();
        }
        await setCompletedSamples();
      } catch (err) {
        setGridZonesAsDefault();
      }
    }
  };

  return {
    createZones,
    resetZones,
    resetPreviewScanGrid,
    createFinalizedGrid,
    drawRef,
    setupInitialAnalysis,
    fieldFeatureCollection,
  };
};
