import React, { useMemo } from 'react';
import { FiDelete } from 'react-icons/fi';
import { Box, Flex, Table, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import area from '@turf/area';

import { UI_COLORS } from 'constants/colors';
import { THREE_EM_DASH } from 'constants/defaultValues';
import { CUSTOM_ZONE_MAX, IMAGERY, RX_TYPE_REMOVAL, YIELD } from 'constants/prescription';

import { SQUARE_METERS_PER_ACRE } from 'util/geospatial';
import { isNumber, roundTwoDecimal } from 'util/numUtils';
import { FieldType } from 'store/fields/types';
import { RxCustomZoneFormType } from 'store/prescriptions/types';

import { RxSettingsStateType } from '../SinglePrescription/Settings';

import { PrescriptionStateType } from './Container';

type CustomZoneRowProps = {
  field: FieldType;
  form: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>;
  fullList: RxCustomZoneFormType[];
  index: number;
  isEditing: boolean;
  removeRow: (index: number) => void;
  updateZoneValue: (index: number, key: string, value: string) => void;
  zone: RxCustomZoneFormType;
};

const CustomZoneRow = ({
  field,
  form,
  fullList,
  index,
  isEditing,
  removeRow,
  updateZoneValue,
  zone,
}: CustomZoneRowProps) => {
  const { customMapLayer, customZones, rxType, sourceLayerGeojson } = form.getValues();
  const { min, max, amount } = zone;

  const numericMin = Number(min);
  const numericMax = Number(max);
  const numericAmount = Number(amount);

  const percentInRange = useMemo(() => {
    const placeholder = THREE_EM_DASH;
    if (min === null || max == null || !sourceLayerGeojson) {
      return placeholder;
    }
    const valueIsInRange = (value: number) =>
      index === fullList.length - 1
        ? value >= numericMin
        : value >= numericMin && value < numericMax;

    // for yield and imagery, we have constant sized polygons so we can improve speed by skipping acreage calculations
    if (
      ([YIELD, IMAGERY].includes(customMapLayer || '') || rxType === RX_TYPE_REMOVAL) &&
      sourceLayerGeojson
    ) {
      const numInRange = sourceLayerGeojson.features.filter((feature) =>
        valueIsInRange(feature.properties.raw_value),
      ).length;
      return Math.round((numInRange / (sourceLayerGeojson.features.length || 1)) * 100).toString();
    }

    const acreageInRange = sourceLayerGeojson.features.reduce((total, feature) => {
      const { raw_value } = feature.properties;
      if (valueIsInRange(raw_value)) {
        const featureAreaAcres = area(feature) / SQUARE_METERS_PER_ACRE;
        return total + featureAreaAcres;
      }
      return total;
    }, 0);

    return Math.round((acreageInRange / field.features[0].properties.acreage) * 100).toString();
  }, [field, sourceLayerGeojson]);

  const getDisplayColor = (value: number | null, forceError: boolean) =>
    value === null || forceError ? UI_COLORS.error : 'default';

  const roundValue = (value: string | null) => {
    if (!isNumber(value)) {
      return value;
    }
    const n = Number(value);
    // For any source layer with a max value <= 10, show 2 decimal places
    if (sourceLayerGeojson) {
      const rawValues = sourceLayerGeojson.features.map((feature) => feature.properties.raw_value);
      const maxValue = Math.max(...rawValues);
      if (maxValue <= 10) {
        return roundTwoDecimal(n);
      }
    }
    return Math.round(n);
  };

  const displayMax = () => {
    const nextMin = customZones[index + 1]?.min;
    const currentValueisEmpty = !max?.length;

    const isError =
      currentValueisEmpty ||
      (isNumber(min) && numericMax < numericMin) ||
      (isNumber(nextMin) ? numericMax > Number(nextMin) : false);
    if (isEditing && index < customZones.length - 1) {
      return (
        <TextInput
          c={getDisplayColor(numericMax, isError)}
          error={isError}
          min={0}
          onChange={(event) => updateZoneValue(index, CUSTOM_ZONE_MAX, event.target.value)}
          value={currentValueisEmpty ? '' : max || ''}
        />
      );
    }
    return (
      <Text c={getDisplayColor(numericMax, isError)}>
        {isNumber(max) ? roundValue(max) : THREE_EM_DASH}
      </Text>
    );
  };

  const displayMin = () => <Text>{min?.length ? roundValue(min) : THREE_EM_DASH}</Text>;

  const displayAmount = () => {
    const isError = amount === null;
    if (isEditing) {
      return (
        <TextInput
          c={getDisplayColor(numericAmount, isError)}
          error={isError}
          min={0}
          onChange={(event) => updateZoneValue(index, 'amount', event.target.value)}
          value={amount !== null ? Math.round(numericAmount).toString() : ''}
        />
      );
    }
    return (
      <Text c={getDisplayColor(numericAmount, isError)}>
        {amount?.length ? amount : THREE_EM_DASH}
      </Text>
    );
  };

  const getZoneBlock = () => {
    // Max color fill target for zone blocks is hsl(217 19% 35%)
    // The blocks are to decrease in lightness from white to target color evenly
    // determine index lightness percentage based on ratio of desired fill
    const lightnessDelta = 65 / fullList.length;
    const hsl = `hsl(217 19% ${100 - lightnessDelta * (index + 1)}%)`;
    return <Box bg={hsl} h="lg" w="lg" />;
  };

  return (
    <Table.Tr>
      <Table.Td>
        <Flex align="center">
          <Text w="md">{index + 1}</Text>
          {getZoneBlock()}
        </Flex>
      </Table.Td>
      <Table.Td>{displayMin()}</Table.Td>
      <Table.Td>{displayMax()}</Table.Td>
      <Table.Td>{percentInRange}</Table.Td>
      <Table.Td>{displayAmount()}</Table.Td>
      <Table.Td>
        {index > 0 ? (
          <FiDelete color="red" cursor="pointer" onClick={() => removeRow(index)} />
        ) : null}
      </Table.Td>
    </Table.Tr>
  );
};

export default CustomZoneRow;
