import React from 'react';
import { Stack } from '@mantine/core';

type Props = {
  children: React.ReactNode;
  isForm?: boolean;
  /** Needs to be used in tandem with `isForm` */
  onSubmit?: (evt: React.FormEvent<HTMLDivElement>) => void;
};

const FullHeightLayoutWrap = ({ children, isForm, onSubmit }: Props) => {
  return (
    <Stack
      flex={1}
      gap={0}
      maw={1300}
      mx="auto"
      w="100%"
      style={{ overflow: 'hidden' }}
      component={isForm ? 'form' : 'div'}
      onSubmit={onSubmit}
    >
      {children}
    </Stack>
  );
};

export default FullHeightLayoutWrap;
