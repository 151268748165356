import React, { useCallback, useState } from 'react';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { capitalizeEveryWord } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { AdjustmentsType } from 'store/payments/types';
import { Button, Container, Input, Label, Radio, Textarea } from 'common';

import styles from './AdjustmentCreation.module.css';

type AdjustmentCreationPropsType = {
  userId: number;
  userName?: string;
  onSubmit: (data: AdjustmentsType) => void;
  onClose: () => void;
};

const AdjustmentCreation = ({
  userId,
  userName,
  onSubmit,
  onClose,
}: AdjustmentCreationPropsType) => {
  const language = useBroswerLanguage();
  const [transactionType, setTransactionType] =
    useState<AdjustmentsType['transaction_type']>('credit');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const submit = useCallback(async () => {
    const adjustment = {
      user_id: userId,
      transaction_type: transactionType,
      description: description,
      amount: Number(amount),
    };
    await onSubmit(adjustment);
    onClose();
  }, [onSubmit, onClose, userId, transactionType, description, amount]);

  const handleTypeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'credit' || e.target.value === 'debit') {
      setTransactionType(e.target.value);
    }
  };

  const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value)) {
      setAmount(e.target.value);
    }
  };

  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDescription(e.target.value);

  return (
    <div className={styles.Wrapper}>
      <Container className={styles.InputColumn} vertical>
        <h1 className={styles.Title}>
          {getString('addAdjustmentsFor', language)}{' '}
          {userName ? capitalizeEveryWord(userName) : NOT_APPLICABLE}
        </h1>
        <div className={styles.InputWrapper}>
          <Label className={styles.AdjustmentLabel} label={getString('description', language)}>
            <Textarea
              className={styles.Description}
              onChange={handleDescription}
              placeholder={getString('reasonForAdjustments', language)}
              value={description}
            />
          </Label>
          <Label className={styles.AdjustmentLabel} label={getString('adjustmentAmount', language)}>
            <div className={styles.TransactionType}>
              <Radio
                value="debit"
                name="transactionType"
                checked={transactionType === 'debit'}
                onChange={handleTypeRadio}
                label={`${
                  userName ? capitalizeEveryWord(userName) : NOT_APPLICABLE
                } ${getString('paysPatternAg', language)}`}
              />
              <Radio
                value="credit"
                name="transactionType"
                checked={transactionType === 'credit'}
                onChange={handleTypeRadio}
                label={`${getString('patternAgPays', language)} ${
                  userName ? capitalizeEveryWord(userName) : NOT_APPLICABLE
                }`}
              />
            </div>
            <Input
              className={styles.Amount}
              type="number"
              onChange={handleAmount}
              placeholder="e.g. $500"
              value={amount}
            />
          </Label>
        </div>
        <div className={styles.SubmitWrapper}>
          <Button
            onClick={submit}
            primary
            disabled={!Number(amount) || !description || !transactionType}
          >
            {getString('addAdjustment', language)}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default AdjustmentCreation;
