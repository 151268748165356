import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Menu } from '@mantine/core';

import { projectsRoutes } from 'constants/routes';

import { CarbonProjectFieldRowData } from 'store/projects/types';

import { useProjectsTranslations } from './hooks';

type Props = {
  field: CarbonProjectFieldRowData;
  projectId: number;
};

const { routes } = projectsRoutes;

const ProjectFieldActionsMenu = ({ field, projectId }: Props) => {
  const { operation_id, id: fieldId, samplingPlanId } = field;
  const translations = useProjectsTranslations();
  const disableSamplesLink = !projectId || !samplingPlanId;

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button variant="outline" size="xs" rightSection={<FiChevronDown />}>
          {translations.actions}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component={Link}
          to={operation_id ? routes.field(projectId, operation_id, fieldId) : ''}
          disabled={!operation_id}
        >
          {translations.viewField}
        </Menu.Item>
        <Menu.Item
          component={Link}
          disabled={disableSamplesLink}
          to={
            disableSamplesLink ? '' : routes.samplingPlanField(projectId, samplingPlanId, fieldId)
          }
        >
          {translations.viewSamples}
        </Menu.Item>
        <Menu.Item component={Link} to={routes.fieldGates(projectId, fieldId)}>
          {translations.manageGates}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ProjectFieldActionsMenu;
