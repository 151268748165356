import { Button, Group, Stack, Text } from '@mantine/core';
import { FeatureCollection, featureCollection, Point, Polygon } from '@turf/helpers';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { GeoJsonProperties } from 'geojson';

import { UI_COLORS } from 'constants/colors';
import { ZONE_TYPES } from 'constants/mapbox';
import { SCAN_POINT_DISPLAY_TEXT_SKU_MAPPING } from 'constants/pricing';
import { NUTRIENT_PANEL } from 'constants/products';
import {
  COMPLETED_PLAN,
  CUSTOM_POINTS,
  densityOptions,
  GRID_POINTS,
  GRID_ZONES,
  MINIMUM_SCAN_POINTS_COUNT,
  MINIMUM_SCAN_POINTS_COUNT_WARNING,
} from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getDensity, getZonesForDisplay, isGridsOption } from 'util/samplePlan';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import { PreviewZonesType } from 'store/pricing/types';
import { ANALYSIS_TYPES } from 'store/samplePlans/types';

import UploadGridPointsButton from '../ZoneInputs/UploadGridPointsButton';

import ProPointScanDensitySelect from './ProPointScanDensitySelect';
import ProPointTypeSelect from './ProPointTypeSelect';

import styles from '../../Form.module.css';

export type SamplingPointsFormProps = {
  fieldFeatureCollection: FieldType;
  resetZones: (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
  ) => void;
  resetPreviewScanGrid: (dens: number) => void;
  drawRef: any;
  createFinalizedGrid: (
    gridType: typeof GRID_POINTS | typeof GRID_ZONES,
    previewZones: PreviewZonesType,
  ) => Promise<
    | FeatureCollection<Point, GeoJsonProperties>
    | FeatureCollection<Polygon, GeoJsonProperties>
    | null
  >;
};

const ScanPointsForm = ({
  resetZones,
  resetPreviewScanGrid,
  createFinalizedGrid,
  drawRef,
  fieldFeatureCollection,
}: SamplingPointsFormProps) => {
  const language = useBroswerLanguage();

  const form = useOrderFormContext();
  const formValues = form.getValues();
  const { scanPointsCount, is590DensityValid } = form.getTransformedValues();

  const isProGridPoints = formValues.proPointCreationOption === GRID_POINTS;
  const isProCustomPoints = formValues.proPointCreationOption === CUSTOM_POINTS;
  const isZonesFromCompleted = formValues.proPointCreationOption === COMPLETED_PLAN;

  const { acreage_unit, acreage } = fieldFeatureCollection.features[0]?.properties;

  const setupEditLockScanPoints = async (newDensity: number, isLockUnlockClicked?: boolean) => {
    form.setValues({
      disableScanMapTools: false,
    });
    if (isProCustomPoints) {
      return resetZones(ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION, CUSTOM_POINTS);
    }
    if (formValues.editScanPoints && isLockUnlockClicked) {
      const pointsMapped = isProGridPoints
        ? await createFinalizedGrid(GRID_POINTS, formValues.previewScanPoints)
        : drawRef.current.getAll();
      form.setValues({
        scanPoints: featureCollection(
          pointsMapped?.features?.map((feat) => ({
            ...feat,
            properties: {
              ...feat.properties,
              zone_type: ZONE_TYPES.POINT,
            },
          })) || [],
        ),
        zonesLocked: true,
        disableScanMapTools: false,
        editScanPoints: false,
        previewScanPoints: null,
      });
    } else {
      form.setValues({
        disableScanMapTools: true,
        editScanPoints: true,
        scanPoints: null,
      });
      resetPreviewScanGrid(newDensity);
    }
  };

  const selectScanCreationType = (creationOption?: string) => {
    const { completedPlan } = formValues;
    if (creationOption === COMPLETED_PLAN && (completedPlan?.is_pro || completedPlan?.is_till_rx)) {
      form.setValues({
        scanPoints: getZonesForDisplay(
          formValues.completedScanPoints,
          completedPlan,
        ) as FeatureCollection<Point>,
        zonesLocked: true,
        disableScanMapTools: false,
        editScanPoints: false,
        previewScanPoints: null,
      });
    } else {
      form.setValues({
        editScanPoints: true,
        disableScanMapTools: !!isGridsOption(creationOption || formValues.proPointCreationOption),
      });
      resetZones(
        ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
        creationOption || formValues.proPointCreationOption,
      );
    }
  };

  const getProPointButtonText = () => {
    if (isProCustomPoints) {
      return getString('resetPoints', language);
    }
    return getString(formValues.editScanPoints ? 'lockPoints' : 'generatePoints', language);
  };

  const getScanPointDisplayText = (skuId: number) => {
    const displayText = SCAN_POINT_DISPLAY_TEXT_SKU_MAPPING[skuId];
    return displayText ? getString(displayText, language) : '';
  };

  const scanPointsDensity = getDensity(scanPointsCount, acreage);
  const tooFewPoints = scanPointsCount < MINIMUM_SCAN_POINTS_COUNT;
  const isPointCountWarning = scanPointsCount < MINIMUM_SCAN_POINTS_COUNT_WARNING;

  const errorText = (() => {
    if (tooFewPoints) {
      return getString('minimalProPointsMsg', language);
    }
    return isPointCountWarning ? getString('minimalProPointsWarningMsg', language) : null;
  })();

  const textColor = (() => {
    if (tooFewPoints || !is590DensityValid) {
      return UI_COLORS.error;
    }
    return isPointCountWarning ? UI_COLORS.warning : UI_COLORS.darkBlue;
  })();

  return (
    <Stack w={425} h="100%" style={{ overflowY: 'auto' }}>
      <Text fw="bold">{getScanPointDisplayText(formValues.primaryProductId)}</Text>
      <Group gap="sm" align="flex-end" wrap="nowrap" className={styles.HighlightedColor}>
        <ProPointTypeSelect
          language={language}
          showTillMapperLabel={formValues.isTillRx && !formValues.products.includes(NUTRIENT_PANEL)}
          disabled={false}
          onChange={(val) => val && selectScanCreationType(val)}
        />
        {isProGridPoints && (
          <ProPointScanDensitySelect
            language={language}
            options={densityOptions[acreage_unit]}
            disabled={false}
            onChange={(density) => {
              if (density) {
                setupEditLockScanPoints(Number(density));
              }
            }}
          />
        )}
        <UploadGridPointsButton
          language={language}
          disabled={false}
          onMutate={() => selectScanCreationType()}
        />
        {!isZonesFromCompleted && (
          <Button
            variant="outline"
            onClick={() => setupEditLockScanPoints(formValues.scanDensity, true)}
          >
            {getProPointButtonText()}
          </Button>
        )}
      </Group>
      <Group>
        <Text size="xs" fs="italic" c={textColor}>
          {getString('points', language)}: <b>{scanPointsCount}</b>
        </Text>
        <Text size="xs" fs="italic" c={textColor}>
          {getString('density', language)}:{' '}
          <b>
            {acToHectares(scanPointsDensity, acreage_unit)} {getAcreageUnitFromLang(language)} /{' '}
            {getString('zone', language).toLowerCase()}
          </b>
        </Text>
      </Group>
      {!!errorText && (
        <Group>
          <Text size="xs" fs="italic" c={textColor}>
            *{errorText}
          </Text>
        </Group>
      )}
    </Stack>
  );
};

export default ScanPointsForm;
