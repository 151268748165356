import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Input, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { addUsersToOperation, getOperation, removeUserFromOperation } from 'store/operation/thunks';
import { OperationType } from 'store/operation/types';
import { getOperationAssociatedUsers } from 'store/user/thunks';
import { User } from 'store/user/types';

import InviteNewUserDialog from './InviteNewUserDialog';
import UsersTable from './UsersTable';

import styles from '../Container.module.css';

type ManageUsersPropsType = {
  operation: OperationType;
  users: User[];
};

const ManageUsers = ({ operation, users }: ManageUsersPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [isSubmitting, toggleSubmitting] = useState(false);
  const [emails, setEmails] = useState('');
  const [isInputVisible, setIsInputVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  const fetchUsers = useCallback(
    () => dispatch(getOperationAssociatedUsers(operation.id)),
    [dispatch, operation],
  );

  const addUsers = useCallback(
    (emailList: string[]) =>
      dispatch(addUsersToOperation({ id: operation.id, users_emails: emailList })),
    [dispatch, operation],
  );

  const fetchOperation = useCallback(
    () => dispatch(getOperation(operation.id)),
    [dispatch, operation],
  );

  const removeUser = useCallback(
    async (email: string) => {
      try {
        await dispatch(removeUserFromOperation({ email, operationId: operation.id }));
        fetchUsers();
        fetchOperation();
      } catch (error) {
        // catch error
      }
    },
    [dispatch, fetchUsers, fetchOperation, operation],
  );

  useEffect(() => {
    if (isInputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputVisible]);

  const getUsers = () => {
    if (!operation || !currentUser) {
      return [];
    }

    return operation.users
      .map((id) =>
        id === currentUser.id ? currentUser : users.find((user: User) => user.id === id),
      )
      .filter(Boolean) as User[];
  };

  const reset = () => {
    setEmails('');
    setIsInputVisible(false);
  };

  const handleSubmitSuccess = () => {
    fetchOperation();
    fetchUsers();
    reset();
  };

  const submit = async () => {
    toggleSubmitting(true);
    const whiteSpaceOrComma = /[ ,]+/;
    const emailList = emails
      .split(whiteSpaceOrComma)
      .map((email) => email.toLowerCase().trim())
      .filter((email) => email !== '');

    try {
      await addUsers(emailList);
      handleSubmitSuccess();
    } catch (error) {
      setShowInviteModal(true);
    }
    toggleSubmitting(false);
  };

  return (
    <>
      <div className={styles.TitleContainer}>
        <Text size="xl" className={styles.Title}>
          {getString('manageUsers', language)}
        </Text>
        <Group className={styles.TitleButtons}>
          {isInputVisible && (
            <Input
              data-test-id="user-email"
              className={styles.InviteInput}
              onChange={(e) => setEmails(e.target.value)}
              placeholder={`${getString('enterAnEmail', language)}...`}
              type="text"
              value={emails}
              ref={inputRef}
            />
          )}
          <Button
            data-test-id="invite-user-by-email"
            disabled={isSubmitting}
            onClick={() => (isInputVisible ? submit() : setIsInputVisible(!isInputVisible))}
          >
            {isInputVisible ? getString('invite', language) : getString('inviteByEmail', language)}
          </Button>
          {isInputVisible && (
            <Button variant="outline" onClick={reset}>
              {getString('cancel', language)}
            </Button>
          )}
        </Group>
      </div>
      <div className={styles.Divider} />
      {currentUser && (
        <UsersTable currentUserId={currentUser.id} removeUser={removeUser} users={getUsers()} />
      )}
      {showInviteModal && (
        <InviteNewUserDialog
          onClose={() => setShowInviteModal(false)}
          open={showInviteModal}
          onSuccess={handleSubmitSuccess}
          email={emails}
          operationId={operation.id}
        />
      )}
    </>
  );
};

export default ManageUsers;
