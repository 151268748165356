import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AMPLICON, GENOMIC } from 'constants/analysis';

import { formatToDateHourFormat } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPlateDisplayName } from 'util/plates';
import { getString } from 'strings/translation';
import { BatchType } from 'store/batches/types';
import { PlateType } from 'store/plates/types';
import { SortableTable, Spinner } from 'common';

import styles from './PlateList.module.css';

type PlatesListContentPropsType = {
  plates?: PlateType[];
  batchOrder?: boolean;
  batch?: BatchType;
};

export const PlatesListContent = ({ plates, batchOrder, batch }: PlatesListContentPropsType) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  if (!plates?.length) {
    return (
      <div className={styles.NoFiles}>
        {getString('noPlatesListMsg', language)}
        {plates === undefined && <Spinner className={styles.Spinner} />}
      </div>
    );
  }

  const tableColumns = [
    ...(batch?.type === GENOMIC
      ? [
          {
            name: getString('indexSet', language),
            attribute: 'quadrant1_index_set_name',
            sortable: true,
          },
        ]
      : []),
    ...(batchOrder
      ? [
          {
            name: getString('batchOrder', language),
            attribute: 'batch_order',
            sortable: true,
            cellFormatter: (order: number) => order + 1,
          },
        ]
      : []),
    ...(batch?.type && [AMPLICON, GENOMIC].includes(batch.type)
      ? [
          {
            name: getString('lane', language),
            attribute: 'lanes',
            sortable: true,
            cellFormatter: (lanes: number[]) => lanes.toString(),
          },
        ]
      : []),
    {
      name: getString('barcode', language),
      attribute: 'barcode',
      sortable: true,
      cellFormatter: (barcode: string) => {
        return (
          <Link className={styles.Link} to={`/lab/plates/details/${barcode}`}>
            {barcode}
          </Link>
        );
      },
    },
    {
      name: getString('type', language),
      attribute: 'plate_type',
      sortable: true,
      cellFormatter: (plate_type: string) => getPlateDisplayName(plate_type, language),
    },
    {
      name: getString('status', language),
      attribute: 'status',
      sortable: false,
      cellFormatter: (status: string) => getString(status, language),
    },
    ...(!batchOrder
      ? [
          {
            name: getString('createdAt', language),
            attribute: 'created_at',
            sortable: true,
            cellFormatter: (date: string) => `${formatToDateHourFormat(date)}`,
          },
        ]
      : []),
  ];

  const handleRowClick = (plate: PlateType) => navigate(`/lab/plates/details/${plate.barcode}`);

  return (
    <SortableTable
      title=""
      data={plates}
      columns={tableColumns}
      initialSortBy={batchOrder ? 'batch_order' : 'created_at'}
      onRowClick={handleRowClick}
    />
  );
};
