import { ComboboxData, Grid, Select } from '@mantine/core';
import { useProjectsTranslations } from 'apps/Projects/hooks';
import { useSamplePlanFormContext } from 'apps/Projects/SamplePlans/samplePlanFormContext';

import { CAR_STANDARD, REGEN_STANDARD } from 'constants/projects';

import { DefaultSamplingPlanConfigResponse, SamplePlanForm } from 'store/projects/types';
import InputAndLabelGridRow from 'common/Components/InputAndLabelGridRow';

import FormHeading from './FormHeading';

type Props = {
  defaultConfig: DefaultSamplingPlanConfigResponse | undefined;
};

const FORM_KEY: keyof SamplePlanForm = 'registry';

const OPTIONS: ComboboxData = [
  { label: REGEN_STANDARD, value: REGEN_STANDARD },
  { label: CAR_STANDARD, value: CAR_STANDARD },
];

const RegistrySection = ({ defaultConfig }: Props) => {
  const form = useSamplePlanFormContext();
  const translations = useProjectsTranslations();
  const inputProps = form.getInputProps(FORM_KEY);

  const handleRegistryChange = (registry: string | null) => {
    if (registry) {
      const registryConfig = defaultConfig?.[registry];
      const isCarProtocol = registry === CAR_STANDARD;

      form.setValues({
        hasTexture: !!registryConfig.measurement_type_info['Soil Texture'],
        textureDensity: isCarProtocol ? '3 per stratum' : null,
      });
    }

    inputProps.onChange(registry);
  };

  return (
    <div>
      <FormHeading text={translations.chooseRegistry} />
      <Grid align="center">
        <InputAndLabelGridRow
          elemId="registry-select"
          label={translations.registry}
          input={
            <Select
              key={form.key(FORM_KEY)}
              data={OPTIONS}
              id="registry-select"
              allowDeselect={false}
              aria-label={translations.registry}
              {...inputProps}
              onChange={handleRegistryChange}
            />
          }
        />
      </Grid>
    </div>
  );
};

export default RegistrySection;
