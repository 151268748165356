import React from 'react';

import { ToastType } from 'actions/toastActions';
import { SamplePricesResponse } from 'store/samplePrices/types';
import { Table, TableCol, TableRow } from 'common';

import SamplePricingRow from './SamplePricingRow';

import styles from './Container.module.css';

type SamplingPriceTablePropsType = {
  userPrices: SamplePricesResponse;
  setToastMessage: (message: string, type?: ToastType, time?: number) => void;
  editUserPrice: (user_id: number) => void;
};

const SamplePricingTable = ({
  userPrices,
  setToastMessage,
  editUserPrice,
}: SamplingPriceTablePropsType) => {
  return (
    <Table>
      <TableRow header className={styles.TableHeader}>
        <TableCol size="x2">USER</TableCol>
        <TableCol size="x2">EMAIL</TableCol>
        <TableCol size="x4">RANGES/PRICE</TableCol>
        <TableCol size="x2" />
      </TableRow>
      {Object.values(userPrices).map((userPrice) => (
        <SamplePricingRow
          key={userPrice.user.id}
          userPrice={userPrice}
          setToastMessage={setToastMessage}
          editUserPrice={editUserPrice}
        />
      ))}
    </Table>
  );
};

export default SamplePricingTable;
