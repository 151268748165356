import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  Box,
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Pagination,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getDataReviewSortingParams,
  prepGroupedCollections,
  sortDataReviewGroups,
} from 'util/dataReview';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { usePageTitle } from 'util/hooks/usePageTitle';
import { dataReviewQueryKeys, DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS } from 'util/queryKeys';
import { collectionGroupQueryOptions } from 'util/queryOptions';
import { userFullName, userIsAdmin, userIsAgencyUser, userIsSuperAdmin } from 'util/userRoles';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { requestConfirmCollections } from 'store/eoCollections/requests';
import {
  type CollectionConfirmationPayloadType,
  EOCollectionFieldType,
  type EOCollectionType,
  type GroupedCollectionsListPayload,
} from 'store/eoCollections/types';
import { SuperAdminAccess } from 'common';
import BackToTopBtn from 'common/Components/BackToTopBtn';
import CenteredErrorMessage from 'common/Components/CenteredErrorMessage';
import TableLoadingSkeletons from 'common/Components/TableLoadingSkeletons';

import AssignCollectionsModal from './AssignCollectionsModal';
import CollectionsGroup from './CollectionsGroup';
import DataReviewSearch from './DataReviewSearch';
import DataReviewSortMenu from './DataReviewSortMenu';
import DataReviewStatusSwitcher from './DataReviewStatusSwitcher';
import GroupDataSwitcher from './GroupDataSwitcher';

const BOTTOM_GUTTER = '5rem';
const MIN_SCAN_COVERAGE = 75;

const DataInboxContainer = () => {
  const language = useBroswerLanguage();
  const queryClient = useQueryClient();
  const [modalVisible, { open, close }] = useDisclosure(false);

  const [selectedFields, setSelectedFields] = useState<EOCollectionFieldType[]>([]);
  const [expandedGroupIds, setExpandedGroupIds] = useState<string[]>([]);

  const [filters, setFilters] = useState<GroupedCollectionsListPayload>(
    DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS,
  );

  const noDealerString = getString('noDealer', language);
  const dataReviewString = getString('dataReview', language);
  const queryOptions = collectionGroupQueryOptions(filters);
  const query = useQuery(queryOptions);

  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
  }));

  const confirmCollectionAssignmentMutation = useMutation<
    unknown,
    Error,
    CollectionConfirmationPayloadType
  >({
    mutationFn: (data) => requestConfirmCollections(data),
    onSuccess: () => {
      showToast(getString('dataConfirmationSuccess', language), 'success', undefined, {
        title: `${getString('success', language)}!`,
      });

      setSelectedFields([]);

      queryClient.invalidateQueries({
        queryKey: dataReviewQueryKeys.groupedCollections(),
      });
    },
  });

  usePageTitle(dataReviewString);

  if (!currentUser) {
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    );
  }

  const groupedCollections = prepGroupedCollections(
    query.data?.operations || [],
    filters.groupBy,
    noDealerString,
  );

  const { roles } = currentUser;
  const shouldShowGroupSwitcher =
    userIsAdmin(roles) ||
    userIsSuperAdmin(roles) ||
    (userIsAgencyUser(roles) && currentUser.agencies.length > 1);

  const getCollectionsFromFields = () => {
    return selectedFields.reduce(
      ({ collections, lowCoverageAtLeastOneField }, field) => {
        const pendingCollections = collections.concat(field.pending_eo_collections);

        if (lowCoverageAtLeastOneField) {
          return { collections: pendingCollections, lowCoverageAtLeastOneField };
        }

        const totalCoverage = field.pending_eo_collections.reduce(
          (acc, { overlap_percentage }) => acc + overlap_percentage,
          0,
        );

        return {
          collections: pendingCollections,
          lowCoverageAtLeastOneField: totalCoverage < MIN_SCAN_COVERAGE,
        };
      },
      {
        collections: [] as EOCollectionType[],
        lowCoverageAtLeastOneField: false,
      },
    );
  };

  const handleApproveSubmit = async () => {
    try {
      const { collections: collectionsInSelectedFields } = getCollectionsFromFields();

      const assignments = collectionsInSelectedFields.reduce(
        (result, collection) => {
          const { id: collectionId, sampling_plan_id } = collection;
          const ids = (result[sampling_plan_id] || []).concat(collectionId);
          return {
            ...result,
            [sampling_plan_id]: ids,
          };
        },
        {} as { [samplingPlanId: number]: number[] },
      );
      const payload = { assignments };
      confirmCollectionAssignmentMutation.mutate(payload);
      close();
    } catch (error) {
      showToast(getString('dataConfirmationErrorMsg', language), 'error', false);
    }
  };

  const toggleField = (fieldProperties: EOCollectionFieldType) => {
    const { id: fieldId } = fieldProperties;

    if (selectedFields.map((f) => f.id).includes(fieldId)) {
      setSelectedFields(selectedFields.filter((f) => f.id !== fieldId));
    } else {
      setSelectedFields(selectedFields.concat(fieldProperties));
    }
  };

  const getInnerContent = () => {
    if (query.isPending) {
      return <TableLoadingSkeletons />;
    }

    if (query.isError) {
      return <CenteredErrorMessage title={query.error.message} />;
    }

    if (Object.keys(groupedCollections).length) {
      return (
        <Accordion
          mb={BOTTOM_GUTTER}
          multiple
          value={expandedGroupIds}
          onChange={(ids) => setExpandedGroupIds(ids)}
        >
          {Object.entries(groupedCollections)
            .sort(sortDataReviewGroups(filters.sortBy, filters.desc))
            .map(([groupId, collections]) => (
              <CollectionsGroup
                key={groupId}
                collectionsGroup={collections}
                groupId={groupId}
                selectedFields={selectedFields}
                toggleField={toggleField}
                shouldRenderChildren={expandedGroupIds.includes(groupId)}
                currentGetPayload={filters}
              />
            ))}
        </Accordion>
      );
    }

    return (
      <Center h="50vh">
        <Title mb="md">{getString('noDataToReview', language)}</Title>
      </Center>
    );
  };

  const handleAnyFilterChange = (update: Partial<GroupedCollectionsListPayload>) => {
    setFilters((current) => ({ ...current, page: 1, ...update }));
    setSelectedFields([]);
  };

  return (
    <>
      <Box pos="sticky" top={0} bg="var(--mantine-color-body)" style={{ zIndex: 3 }} pt="xl">
        <Title mb="md">
          {dataReviewString} - {userFullName(currentUser)}
        </Title>
        <Group mb="lg" gap="xl">
          <DataReviewStatusSwitcher
            language={language}
            onChange={(val) => handleAnyFilterChange({ status: val })}
          />
          <DataReviewSortMenu
            language={language}
            onChange={(val) =>
              setFilters((current) => ({ ...current, ...getDataReviewSortingParams(val) }))
            }
          />
          {shouldShowGroupSwitcher && (
            <GroupDataSwitcher
              currentValue={filters.groupBy}
              language={language}
              onChange={(val) => handleAnyFilterChange({ groupBy: val })}
            />
          )}
          <DataReviewSearch
            language={language}
            groupViewBy={filters.groupBy}
            onChange={(val) => handleAnyFilterChange({ name: val })}
          />
        </Group>
        <Divider size="sm" />
      </Box>
      {getInnerContent()}
      <Box
        h="5rem"
        pos="fixed"
        bottom={0}
        left={0}
        right={0}
        bg="var(--mantine-color-body)"
        style={{
          zIndex: 2, // above Mapbox logo
        }}
      >
        <Divider mb="lg" size="sm" />
        <Group justify="space-between" gap="md" align="center" w="95%" px="6%">
          {/* Clone of actual button, for precise centering */}
          <Button style={{ visibility: 'hidden' }}>
            {getString('confirmFieldAssignment', language)}
          </Button>
          {(query.data?.pages && query.data.pages > 1 && (
            <Pagination
              value={filters.page || 1}
              total={query.data.pages}
              onChange={(page) => setFilters((current) => ({ ...current, page }))}
            />
          )) || <div />}
          <Group>
            <SuperAdminAccess>
              <Button
                disabled={!selectedFields.length || filters.status !== 'confirmed'}
                onClick={open}
              >
                {getString('unapprove', language)}
              </Button>
            </SuperAdminAccess>
            <Button disabled={!selectedFields.length} onClick={open}>
              {getString('confirmFieldAssignment', language)}
            </Button>
          </Group>
        </Group>
      </Box>
      <BackToTopBtn />
      <AssignCollectionsModal
        language={language}
        isLoading={confirmCollectionAssignmentMutation.isPending}
        opened={modalVisible}
        onClose={close}
        onSubmit={handleApproveSubmit}
        coverageIsLow={getCollectionsFromFields().lowCoverageAtLeastOneField}
      />
    </>
  );
};

export default DataInboxContainer;
