import { useDispatch } from 'react-redux';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';

import { UI_COLORS } from 'constants/colors';

import showToast from 'actions/toastActions';
import { requestDeleteTotalFarm } from 'store/operation/requests';
import { getOperation } from 'store/operation/thunks';
import { OperationType } from 'store/operation/types';

import { useEditOperationTranslations } from './hooks';

type Props = {
  operation: OperationType;
  opened: boolean;
  close: VoidFunction;
};

const CancelTotalFarmModal = ({ operation, close, opened }: Props) => {
  const dispatch = useDispatch();
  const translations = useEditOperationTranslations();
  const { total_farm } = operation;

  const cancelTotalFarmMutation = useMutation({
    mutationFn: () =>
      Promise.all((total_farm || []).map((single) => requestDeleteTotalFarm(single.id))),
    onSuccess: () => {
      dispatch(getOperation(operation.id));
      showToast(translations.totalFarmCancelledMsg, 'success');
      close();
    },
  });

  return (
    <Modal opened={opened} onClose={close} title={translations.cancelTotalFarm} centered>
      <Text>{translations.cancelTotalFarmMsg}</Text>
      <Space h="md" />
      <Group justify="flex-end" mb="md">
        <Button variant="outline" onClick={close}>
          {translations.keepTotalFarm}
        </Button>
        <Button
          color={UI_COLORS.error}
          onClick={() => cancelTotalFarmMutation.mutate()}
          loading={cancelTotalFarmMutation.isPending}
        >
          {translations.cancelTotalFarm}
        </Button>
      </Group>
    </Modal>
  );
};

export default CancelTotalFarmModal;
