import React from 'react';
import { Chip } from '@mantine/core';

import { productAbbrNames } from 'constants/products';
import { COMPLETED } from 'constants/samplePlanning';

import { getProductList, isProductComplete } from 'util/product';
import { SamplingPlanType } from 'store/fields/types';

import styles from './Status.module.css';

type StatusPropsType = {
  samplingPlan: SamplingPlanType;
};

export const generateStatusBubble = (
  product: string,
  hasPrimarySku?: boolean,
  isComplete?: boolean,
) => (
  <Chip
    key={product}
    styles={{
      iconWrapper: { display: 'none' },
    }}
    variant={isComplete ? 'filled' : 'outline'}
    checked={isComplete}
    size="xs"
  >
    {hasPrimarySku ? product : productAbbrNames[product]}
  </Chip>
);

const Status = ({ samplingPlan }: StatusPropsType) => {
  return (
    <div className={styles.Wrapper}>
      {getProductList(
        samplingPlan.products,
        samplingPlan.is_pro,
        samplingPlan.is_till_rx,
        samplingPlan.is_590_analysis,
        samplingPlan.primary_sku_name,
      ).map((product) =>
        generateStatusBubble(
          product,
          !!samplingPlan.primary_sku_name,
          samplingPlan?.sampling_status === COMPLETED || isProductComplete(samplingPlan, product),
        ),
      )}
    </div>
  );
};

export default Status;
