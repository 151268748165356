import React, { useMemo, useState } from 'react';

import { middleEllipsis } from 'util/stringUtils';
import { OperationFieldType } from 'store/operation/types';
import { Button, Container, Dialog, Input, Selector } from 'common';

import styles from './FileEditor.module.css';

type ChangeFieldModalPropsType = {
  currentFieldId: number;
  fields: OperationFieldType[];
  onChange: (newId: number, newFieldName?: string) => void;
  onCancel: () => void;
};

const ChangeFieldModal = ({
  currentFieldId,
  fields,
  onChange,
  onCancel,
}: ChangeFieldModalPropsType) => {
  const [showInputText, toggleShowInputText] = useState(false);
  const [newFieldValue, setNewFieldValue] = useState('');
  const fieldSelectorOptions = useMemo(() => {
    const theFields = fields.map((field) => ({
      id: field.id,
      displayName: middleEllipsis(field.name, 20),
    }));
    return [
      {
        id: 0,
        displayName: '+ Create New Field',
      },
    ].concat(theFields);
  }, [fields]);
  const [selectorActiveIndex, setActiveIndex] = useState(
    fieldSelectorOptions.findIndex((o) => o.id === currentFieldId),
  );
  const onConfirm = () => {
    onChange(fieldSelectorOptions[selectorActiveIndex].id, newFieldValue);
  };

  return (
    <Dialog onClose={onCancel}>
      <div className={styles.DialogContent}>
        <h4>Change Field Assignment</h4>
        <p>Which field would you like these file to be assigned to?</p>
        <Selector
          className={styles.DialogSelector}
          menuClassName={styles.DialogSelectorMenu}
          onChange={(idx) => {
            toggleShowInputText(idx === 0);
            setActiveIndex(idx);
            if (idx !== 0) {
              setNewFieldValue('');
            }
          }}
          activeIndex={selectorActiveIndex}
          options={fieldSelectorOptions}
        />
        {showInputText && (
          <Input
            placeholder="Add new Field name"
            className={styles.DialogFieldInput}
            value={newFieldValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewFieldValue(e.target.value)}
          />
        )}
        <Container className={styles.DialogButtons}>
          <Button danger onClick={onCancel}>
            Cancel
          </Button>
          <Button primary onClick={onConfirm}>
            Change
          </Button>
        </Container>
      </div>
    </Dialog>
  );
};

export default ChangeFieldModal;
