import React from 'react';
import { FiSearch } from 'react-icons/fi';

import { plateTypesForBatch } from 'constants/batches';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BatchFormType } from 'store/batches/types';
import { PlateSearchBar } from 'common';
import { TypeaheadPropsType } from 'common/Components/Mantine/Typeahead';

type SourceBarcodeInputPropsType = Omit<TypeaheadPropsType, 'onSelect' | 'data'> & {
  batch: BatchFormType;
  onValueChange: (
    attributeKey: keyof BatchFormType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
  index: number;
  showLabel?: boolean;
};

export const SourceBarcodeInput = ({
  batch,
  onValueChange,
  index,
  showLabel,
  ...props
}: SourceBarcodeInputPropsType) => {
  const language = useBroswerLanguage();

  const plateSourceBarcode = batch.plates[index]?.barcode;
  const filterPlateType = plateTypesForBatch[batch.type];

  const handleSelect = (barcode: string) => {
    const currentPlates = batch.plates;
    if (currentPlates[index]) {
      currentPlates[index].barcode = barcode;
    } else {
      currentPlates[index] = { barcode };
    }
    onValueChange('plates', currentPlates);
  };

  const handleDeselect = () => {
    const currentPlates = batch.plates;
    currentPlates.splice(index, 1);
    onValueChange('plates', currentPlates);
  };

  return (
    <PlateSearchBar
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      label={showLabel && `${getString('sourcePlateBarcode', language)} ${index + 1}`}
      placeholder={getString('scanBarcodeMsg', language)}
      value={plateSourceBarcode || ''}
      plateTypes={filterPlateType}
      leftSection={<FiSearch />}
      batchError
      {...props}
    />
  );
};
