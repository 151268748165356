import { useState } from 'react';
import { Button, Group, NumberInput, Popover, Space, Text } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBillingParams, useBillingTranslations } from 'apps/Finance/hooks';

import { financialQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { patchFinancialTransaction } from 'store/payments/requests';
import { AdjustmentTypeV2, FinancialTransactionType } from 'store/payments/types';

import Styles from '../../BillingUserContainer.module.css';

type Props = {
  transaction: FinancialTransactionType;
  opened: boolean;
  close: VoidFunction;
  open: VoidFunction;
};

const UpdateAmountPopover = ({ transaction, opened, close, open }: Props) => {
  const translations = useBillingTranslations();
  const { approvalStatus, billingUserId } = useBillingParams();
  const queryClient = useQueryClient();

  const [tempAmount, setTempAmount] = useState<number | string>(transaction.amount);

  const updateFTAmountMutation = useMutation<unknown, Error, Pick<AdjustmentTypeV2, 'amount'>>({
    mutationFn: (data) => patchFinancialTransaction(transaction.id, { ...data, is_active: true }),
    onError: (err) => showToast(err.message, 'error'),
    onSuccess: () => {
      showToast(translations.financialTransactionUpdatedSuccessMsg);
      queryClient.invalidateQueries({
        queryKey: financialQueryKeys.transactionsByUser(approvalStatus || '', billingUserId),
      });
      queryClient.invalidateQueries({
        queryKey: financialQueryKeys.individualSummaryTransactions(
          approvalStatus || '',
          billingUserId,
        ),
      });
      close();
    },
  });

  return (
    <Popover width="auto" trapFocus position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <Text
          onClick={() => !!transaction.amount && open()}
          className={transaction.amount ? Styles.Hover : undefined}
        >
          ${transaction.amount || 0}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text fw="bold">{translations.amount}</Text>
        <NumberInput leftSection="$" value={tempAmount} onChange={setTempAmount} />
        <Space h="md" />
        <Group justify="flex-end">
          <Button bg="darkRed" onClick={close}>
            {translations.cancel}
          </Button>
          <Button onClick={() => updateFTAmountMutation.mutate({ amount: Number(tempAmount) })}>
            {translations.save}
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default UpdateAmountPopover;
