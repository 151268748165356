import { useMutation } from '@tanstack/react-query';
import { FeatureCollection, Point, Polygon } from '@turf/helpers';

import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { OrderForm } from 'store/pricing/types';
import { postDensityGroupings } from 'store/samplePlans/requests';

export const useSplitDensityZoneMutation = (
  onSuccess: (groupedZones: FeatureCollection<Polygon | Point>) => void,
  language: string,
) => {
  return useMutation<
    FeatureCollection<Polygon | Point>,
    Error,
    { groupCount: number; zones: OrderForm['zones'] }
  >({
    onSuccess,
    mutationFn: ({ groupCount, zones }) => {
      return postDensityGroupings(groupCount, zones as FeatureCollection<Polygon | Point>);
    },
    onError: (error) => {
      showToast(error.message || getString('unableGenerateZoneGroupings', language), 'error');
    },
  });
};
