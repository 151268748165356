import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Modal,
  Pagination,
  SegmentedControl,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { UI_COLORS } from 'constants/colors';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAcreageUnitFromLang } from 'util/units';
import { RootState } from 'store';
import { setActiveOperation } from 'store/operation/actions';
import { getOperations } from 'store/operation/thunks';
import { userIsAdmin, userIsAgencyAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { UserAgencySelectorType } from 'store/user/types';
import { AgencyAdminAccess, UserAgencySearch } from 'common';

import {
  useEditProjectMutation,
  useProjectsTranslations,
  useSelectedOperationsAndFieldsText,
} from './hooks';
import { useProjectFieldsByOperationQueries } from './queries';

type Props = {
  currentProjectFieldIds: number[];
  currentProjectOperationIds: (number | null)[];
  projectId: number;
  operationNames: Array<string | null>;
  canAddAccounts: boolean;
};

type ViewingMode = 'allAccounts' | 'myAccounts';

const CHECKBOX_COL_WIDTH = 32;

const AddAcctsToProjectModal = ({
  currentProjectFieldIds,
  projectId,
  currentProjectOperationIds,
  operationNames,
  canAddAccounts,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const translations = useProjectsTranslations();
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const editProjectMutation = useEditProjectMutation();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOperationIds, setSelectedOperationIds] = useState<number[]>([]);
  const [isSearching, toggleIsSearching] = useState(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>('allAccounts');

  const [userOrAgencySelected, setUserOrAgencySelected] = useState<UserAgencySelectorType | null>(
    null,
  );

  const {
    activeOperationId,
    hasFetched,
    operations,
    isFetching,
    totalOperationPages,
    isAdmin,
    isAgencyAdmin,
  } = useSelector((state: RootState) => ({
    activeOperationId: state.operations.activeOperationId,
    hasFetched: state.operations.hasFetched,
    isFetching: state.operations.isFetching,
    operations: state.operations.summary.items,
    totalOperationPages: state.operations.summary.total,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
    isAgencyAdmin: userIsAgencyAdmin(state),
  }));

  const { isPending: projectFieldsQueriesPending, fields: fieldsToAdd } =
    useProjectFieldsByOperationQueries(selectedOperationIds);

  const showAllOperations = viewingMode === 'allAccounts' && (isAdmin || isAgencyAdmin);

  const selectionText = useSelectedOperationsAndFieldsText(
    selectedOperationIds.length,
    fieldsToAdd.length,
  );

  const accountViewingOptions: Array<{
    value: ViewingMode;
    label: string;
  }> = [
    {
      value: 'allAccounts',
      label: translations.allAccounts,
    },
    {
      value: 'myAccounts',
      label: translations.myAccounts,
    },
  ];

  const handleClose = () => {
    setSelectedOperationIds([]);
    setCurrentPage(1);
    close();
  };

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    editProjectMutation.mutate(
      {
        projectId,
        fieldIds: [...currentProjectFieldIds, ...fieldsToAdd.map((field) => field.id)],
      },
      { onSettled: handleClose },
    );
  };

  const removeFilter = () => setUserOrAgencySelected(null);

  const handleUserSelection = (selected: UserAgencySelectorType | null) => {
    if (!selected) {
      removeFilter();
    } else {
      const { id, displayName, user_id, agency_id, agency_primary_id } = selected;

      setCurrentPage(1);
      setUserOrAgencySelected({
        id,
        user_id,
        displayName,
        agency_id,
        agency_primary_id,
      });
    }
  };

  const handleOperationSelection = (
    evt: React.ChangeEvent<HTMLInputElement>,
    operationId: number,
  ) => {
    setSelectedOperationIds(
      evt.currentTarget.checked
        ? [...selectedOperationIds, operationId]
        : selectedOperationIds.filter((id) => id !== operationId),
    );
  };

  useEffect(() => {
    const search_users = userOrAgencySelected?.user_id ? [userOrAgencySelected?.user_id] : [];

    const search_agency =
      userOrAgencySelected?.agency_id && userOrAgencySelected?.agency_primary_id
        ? {
            agency_id: userOrAgencySelected.agency_id,
            agency_primary_id: userOrAgencySelected.agency_primary_id,
          }
        : null;

    dispatch(
      getOperations(
        getAcreageUnitFromLang(language),
        showAllOperations,
        currentPage,
        '',
        search_users,
        search_agency,
      ),
    );
  }, [currentPage, showAllOperations, userOrAgencySelected]);

  useEffect(() => {
    if (!activeOperationId && operations.length) {
      dispatch(setActiveOperation(operations[0].id));
    }
  }, [activeOperationId, hasFetched, operations, dispatch]);

  return (
    <Group align="baseline">
      <Text>
        <b>{translations.accounts}:</b> {operationNames.join(', ')}
      </Text>
      {canAddAccounts && (
        <div>
          <Button size="compact-xs" leftSection={<FaPlus />} variant="subtle" onClick={open}>
            {translations.addAccount}
          </Button>
        </div>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title={translations.addAccount}
        centered
        size="xl"
        styles={{
          content: {
            height: 650,
            display: 'flex',
            flexDirection: 'column',
          },
          body: {
            flex: 1,
            height: '100%',
          },
        }}
      >
        <Stack component="form" onSubmit={handleSubmit} gap="lg" justify="space-between" h="100%">
          <Group>
            {isAdmin || isAgencyAdmin ? (
              <SegmentedControl
                color={UI_COLORS.darkBlue}
                data={accountViewingOptions}
                value={viewingMode}
                onChange={(value) => {
                  setSelectedOperationIds([]);
                  setViewingMode(value as ViewingMode);
                }}
              />
            ) : (
              translations.myAccounts
            )}
            <AgencyAdminAccess>
              <UserAgencySearch
                placeholder={translations.searchByUserAgency}
                ariaLabel={translations.searchByUserAgency}
                onSelect={handleUserSelection}
                onDeselect={removeFilter}
                toggleIsSearching={toggleIsSearching}
                selected={userOrAgencySelected}
              />
            </AgencyAdminAccess>
          </Group>
          <Box pos="relative" flex={1}>
            <LoadingOverlay
              visible={isFetching || isSearching}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
            />
            <Table
              data={{
                head: [
                  '',
                  translations.accountName,
                  translations.billingAgency,
                  translations.fields,
                ],
                body: operations.map(({ id, name, billing_agency_name, fields }) => [
                  <Checkbox
                    key={id}
                    aria-label={translations.selectAccount}
                    disabled={currentProjectOperationIds.includes(id)}
                    w={CHECKBOX_COL_WIDTH}
                    onChange={(evt) => handleOperationSelection(evt, id)}
                    checked={
                      currentProjectOperationIds.includes(id) || selectedOperationIds.includes(id)
                    }
                  />,
                  name,
                  billing_agency_name,
                  fields.length,
                ]),
              }}
            />
          </Box>
          <Group justify="center">
            {totalOperationPages > 1 && (
              <Pagination
                value={currentPage}
                total={totalOperationPages}
                onChange={setCurrentPage}
              />
            )}
          </Group>
          <Group gap="xs" justify="end">
            <Text tt="lowercase" mr="auto">
              {selectionText}
            </Text>
            <Button variant="outline" onClick={handleClose}>
              {translations.cancel}
            </Button>
            <Button
              type="submit"
              loading={editProjectMutation.isPending || projectFieldsQueriesPending}
              disabled={!selectedOperationIds.length}
            >
              {translations.submit}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Group>
  );
};

export default AddAcctsToProjectModal;
