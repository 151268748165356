import { ENGLISH, PORTUGUESE } from 'constants/languages';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getWhiteLabelTranslation } from 'util/stringUtils';

import ENGLISH_STRINGS from './en';
import PORTUGUESE_STRINGS from './pt';

type TranslationKey = keyof typeof ENGLISH_STRINGS | keyof typeof PORTUGUESE_STRINGS;

export const getString = (key: string, language: string) => {
  const typedKey = key as TranslationKey;
  let translation = ENGLISH_STRINGS?.[typedKey] || typedKey;

  if (language !== ENGLISH) {
    translation = PORTUGUESE_STRINGS?.[typedKey] || typedKey;
  }

  return getWhiteLabelTranslation(translation);
};

/** Basis of getting a bunch of typesafe-keyed translations in one shot, e.g. for a new app/view */
const mapTranslations = (
  keys: TranslationKey[],
  language: string,
): Record<TranslationKey, string> => {
  return keys.reduce(
    (acc, key) => ({ ...acc, [key]: getString(key, language) }),
    {} as Record<TranslationKey, string>,
  );
};

export const useTranslation = (key: string) => {
  const language = useBroswerLanguage();

  return getString(key, language);
};

export const useTranslations = <T extends TranslationKey>(keys: T[]) => {
  const language = useBroswerLanguage();

  return mapTranslations(keys, language) as Record<T, string>;
};

export const listTranslate = (
  list: { id: number; value: string; displayName: string }[],
  lang: string,
) =>
  list.map((item) => ({
    ...item,
    displayName: getString(item.value, lang),
  }));

export const getLanguages = (language: string) => [
  {
    value: ENGLISH,
    displayName: getString('english', language),
    label: getString('english', language),
    id: 0,
  },
  {
    value: PORTUGUESE,
    displayName: getString('portuguese', language),
    label: getString('portuguese', language),
    id: 1,
  },
];
