import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Stack, Text } from '@mantine/core';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAppliedInputStrings, getPrescriptionSummaryValues } from 'util/prescription';
import { capitalize } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { PrescriptionType } from 'store/prescriptions/types';

import styles from './Map.module.css';

interface MapScriptProps {
  prescription: PrescriptionType | undefined;
  field: FieldType;
}

const MapScriptInfo = ({ prescription, field }: MapScriptProps) => {
  const language = useBroswerLanguage();
  const [isOpen, setIsOpen] = useState(true);

  const { inputs } = useSelector((state: RootState) => ({
    inputs: state.inputs.inputs,
  }));

  if (!prescription) {
    return null;
  }

  const { basedOn } = getPrescriptionSummaryValues(prescription, inputs, field, language);

  const getBaseText = () => {
    if (prescription.machine_data) {
      return prescription.machine_data?.start_time ? (
        <Text>
          {capitalize(getString('harvest', language))}{' '}
          {new Date(prescription.machine_data.start_time).getFullYear()}{' '}
          {capitalize(prescription.crop || '')}
        </Text>
      ) : null;
    }
    if (prescription.composite_imagery) {
      return (
        <Text>
          {capitalize(getString('imagery', language))} {prescription.composite_imagery.year}{' '}
          {capitalize(prescription.crop || '')}
        </Text>
      );
    }
    if (prescription.analytic) {
      return <Text>{prescription.analytic.name}</Text>;
    }
    return <Text>{basedOn}</Text>;
  };

  const appliedInputStrings = getAppliedInputStrings(prescription, language);

  return (
    <Stack gap="xs" className={styles.CreationInfo} onClick={() => setIsOpen(!isOpen)}>
      <Text fw={700}>{prescription.name}</Text>
      {isOpen && (
        <>
          {appliedInputStrings && (
            <Flex align="center" gap="xs">
              <Text fw={FONT_WEIGHT_BOLD}>{getString(appliedInputStrings[0], language)}:</Text>
              <Text>{appliedInputStrings[1]}</Text>
            </Flex>
          )}
          <Flex gap="xs">
            <Text fw={FONT_WEIGHT_BOLD}>{getString('from', language)}:</Text>
            {getBaseText()}
          </Flex>
        </>
      )}
    </Stack>
  );
};

export default MapScriptInfo;
