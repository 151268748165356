import {
  FeatureFlagsActionType,
  FeatureFlagType,
  RECEIVE_FEATURE_FLAG,
  RECEIVE_FEATURE_FLAGS,
  REQUEST_FEATURE_FLAGS,
  REQUEST_FEATURE_FLAGS_ERROR,
} from './types';

export const featureFlagRequestError = (message: string): FeatureFlagsActionType => ({
  type: REQUEST_FEATURE_FLAGS_ERROR,
  payload: { message },
});

export const receiveFeatureFlag = (featureFlag: FeatureFlagType): FeatureFlagsActionType => ({
  type: RECEIVE_FEATURE_FLAG,
  payload: { featureFlag },
});

export const receiveFeatureFlags = (featureFlags: FeatureFlagType): FeatureFlagsActionType => ({
  type: RECEIVE_FEATURE_FLAGS,
  payload: { featureFlags },
});

export const requestFeatureFlags = (): FeatureFlagsActionType => ({
  type: REQUEST_FEATURE_FLAGS,
});
