import React from 'react';
import classNames from 'classnames';

import { getLabIconForSample } from 'util/analysis';
import { LabSampleType } from 'store/labSamples/types';

import styles from './RequiredAnalysis.module.css';

type ProductBinPropsType = {
  sample: LabSampleType;
};

export const ProductBin = ({ sample }: ProductBinPropsType) => {
  const labIcon = getLabIconForSample(sample);
  return <div className={classNames(styles.AnalysisBox, styles[labIcon])}>{labIcon}</div>;
};
