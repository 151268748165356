import React from 'react';
import { Grid, Text } from '@mantine/core';

type Props = {
  /** `id` of the corresponding input element */
  elemId: string;
  /** Text or element that describes the input element */
  label: React.ReactNode;
  /** e.g. Checkbox, TextInput, Switch, or anything where `htmlLabelFor` will make sense */
  input: React.ReactNode;
  /** Adjusts the label's text color */
  disabled?: boolean;
};

/**
 * Mantine's `label` options on many elements do not lend themselves to a side-by-side grid layout,
 * which Product asks for very frequently. Usage of this component requires a Mantine `<Grid>`
 * parent, and an `elemId` that must match the corresponding input's `id` in order to be accessible.
 */
const InputAndLabelGridRow = ({ elemId, label, input, disabled }: Props) => {
  return (
    <>
      <Grid.Col span={6}>
        <Text
          component="label"
          htmlFor={elemId}
          c={disabled ? 'var(--mantine-color-gray-8)' : undefined}
        >
          {label}
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>{input}</Grid.Col>
    </>
  );
};

export default InputAndLabelGridRow;
