import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Center, Divider, Loader, Space, Stack, Text, Title } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { findPrimarySample } from 'util/sample';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { searchSample } from 'store/labSamples/thunks';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';

import { DueDateAndPriorityContainer as DueDateAndPriority } from './DueDateAndPriority/Container';
import { SampleFieldContainer as FieldAndSamplePlanDetails } from './Field/Container';
import { ProcessingContainer as ProcessingSummary } from './Processing/Container';
import { ReceivingContainer as ReceivingAndDataEntry } from './Receiving/Container';
import { InfoContainer as SampleSummary } from './SampleInfo/Container';

type DetailsControllerPropsType = {
  barcode?: string;
  searchBarFocus: () => void;
};

const DetailsController = ({ barcode, searchBarFocus }: DetailsControllerPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { samples, hasFetched, hasFailed, isAdmin } = useSelector((state: RootState) => ({
    samples: barcode ? state.labSamples.byBarcode[barcode] : [],
    hasFetched: state.labSamples.hasFetched,
    hasFailed: state.labSamples.hasFailed,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  useEffect(() => {
    if (barcode && !samples && !hasFailed) {
      dispatch(searchSample(barcode));
    }
  }, [dispatch, samples, hasFailed, barcode]);

  const primarySample = samples?.length ? findPrimarySample(samples) || samples[0] : null;

  useEffect(() => {
    if (primarySample?.field_id && isAdmin) {
      dispatch(getFieldGeometry(primarySample.field_id, true));
    }
  }, [dispatch, primarySample, isAdmin]);

  if (!hasFetched && !samples?.length) {
    return (
      <Center>
        <Stack align="center">
          <Loader size={30} type="oval" />
        </Stack>
      </Center>
    );
  }

  if (!primarySample) {
    return (
      <Center>
        <Stack align="center">
          <Title order={2}>{getString('sampleNotFound', language)}</Title>
          <Text>{getString('sampleNotFoundMsg', language)}</Text>
        </Stack>
      </Center>
    );
  }

  return (
    <>
      <ReceivingAndDataEntry sample={primarySample} searchBarFocus={searchBarFocus} />
      <Space />
      <Divider size="md" />
      <SampleSummary samples={samples} />
      <Space />
      <Divider size="md" />
      <ProcessingSummary sample={primarySample} />
      <Space />
      <Divider size="md" />
      <Space />
      <DueDateAndPriority sample={primarySample} />
      <Space />
      <FieldAndSamplePlanDetails sample={primarySample} />
      <Space />
    </>
  );
};

export default DetailsController;
