import { Grid } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { EMPTY_TEXT_FILLER } from 'constants/defaultValues';
import { PER_ACRE } from 'constants/pricing';

import { roundTwoDecimal } from 'util/numUtils';
import { FinancialTransactionType } from 'store/payments/types';

import UpdateAmountPopover from './UpdateAmountPopover';

interface Props {
  transaction: FinancialTransactionType;
}

const SamplingPlanTransactionRow = ({ transaction }: Props) => {
  const [opened, { close, open }] = useDisclosure(false);
  const { summary_json } = transaction;
  const isPerAcre = summary_json?.price_type === PER_ACRE;

  return (
    <Grid columns={8} c="gray.7" pl="lg" key={transaction.sampling_plan_sku_id}>
      <Grid.Col span={2}>{transaction.sku_name}</Grid.Col>
      <Grid.Col span={1}>{isPerAcre ? EMPTY_TEXT_FILLER : summary_json?.zone_count}</Grid.Col>
      <Grid.Col span={1}>{isPerAcre ? EMPTY_TEXT_FILLER : `$${summary_json?.price}`}</Grid.Col>
      <Grid.Col span={1}>{isPerAcre ? `$${summary_json?.price}` : EMPTY_TEXT_FILLER}</Grid.Col>
      <Grid.Col span={1} td={transaction.amount && 'underline'}>
        <UpdateAmountPopover transaction={transaction} opened={opened} close={close} open={open} />
      </Grid.Col>
      <Grid.Col span={1}>${roundTwoDecimal(summary_json?.total_list_price)}</Grid.Col>
    </Grid>
  );
};

export default SamplingPlanTransactionRow;
