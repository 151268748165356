export const segments = {
  list: 'billing',
};

export type approvalStatusType = typeof routeParams.open | typeof routeParams.approved;

export const routeParams = {
  billingUserId: 'billingUserId',
  open: 'open',
  approved: 'approved',
  approvalStatus: 'approvalStatus',
};

export const routes = {
  all: (status: approvalStatusType) => `/${segments.list}/${status}`,
  individual: (status: approvalStatusType, userId: string) =>
    `/${segments.list}/${status}/${userId}`,
};
