import React, { Fragment } from 'react';
import { Table, Text } from '@mantine/core';

import { LIGHT_GREY_BG } from 'constants/colors';

import { DefaultPriceType, SkuType } from 'store/pricing/types';

import DefaultSkuPriceRow from './DefaultSkuPriceRow';

interface Props {
  skuId: string;
  defaultPriceType: DefaultPriceType;
  skuOptions: SkuType[] | undefined;
}

const DefaultSkuPrices = ({ skuId, defaultPriceType, skuOptions }: Props) => {
  return (
    <Fragment key={skuId}>
      <Table.Tr bg={LIGHT_GREY_BG}>
        <Table.Td>
          <Text size="sm">{defaultPriceType.sku.name}</Text>
        </Table.Td>
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
      </Table.Tr>
      {defaultPriceType?.prices.map((priceSku) => (
        <DefaultSkuPriceRow key={priceSku.id} priceSku={priceSku} skuOptions={skuOptions} />
      ))}
    </Fragment>
  );
};

export default DefaultSkuPrices;
