import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Group, Space, Stack, Text } from '@mantine/core';

import { jobStatuses } from 'constants/prescription';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getJobStatus, getPrescriptionSummaryValues } from 'util/prescription';
import { capitalize } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import { PrescriptionType } from 'store/prescriptions/types';

import styles from './Container.module.css';

type PrescriptionSummaryProps = {
  field: FieldType;
  inputs: InputType[];
  prescription: PrescriptionType;
};

const PrescriptionSummary = ({ field, inputs, prescription }: PrescriptionSummaryProps) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const { fieldCost, fieldRate, formula, input, range } = useMemo(
    () => getPrescriptionSummaryValues(prescription, inputs, field, language),
    [field, inputs, prescription],
  );
  const { id, operation_id } = field.features[0].properties;

  const { exportedScript } = useSelector((state: RootState) => ({
    exportedScript: state.prescriptionZones.scriptExports[id]?.[prescription.id],
  }));

  const navigateToPrescription = useCallback(
    () => navigate(`/results/rx/${operation_id}/${id}/${prescription.id}`),
    [navigate, id, operation_id, prescription],
  );

  const getExportedScripts = () => {
    const cnhi = exportedScript?.cnhi;

    return (
      <>
        <Box>
          {[exportedScript?.field_view, exportedScript?.john_deere]
            .filter((account) => account)
            .map((acctJob) => (
              <Group gap="xs" justify="flex-start" key={acctJob.external_provider_name}>
                <Text fw={500}>
                  {getString(acctJob.external_provider_name, language)}{' '}
                  {getString('export', language)}:
                </Text>{' '}
                <Text className={acctJob.complete === jobStatuses.FAILED ? styles.Red : undefined}>
                  {getJobStatus(acctJob.complete, language)}
                </Text>
              </Group>
            ))}
        </Box>
        {cnhi && (
          <Group justify="flex-start" gap="xs">
            <Text fw={500}>
              {getString(cnhi.external_provider_name, language)} {getString('export', language)}:
            </Text>{' '}
            <Text className={cnhi.complete === jobStatuses.FAILED ? styles.Red : undefined}>
              {getJobStatus(cnhi.complete, language)}
            </Text>
          </Group>
        )}
      </>
    );
  };

  return (
    <Stack gap="xs" key={prescription.id} className={styles.Prescription}>
      <Space h="xs" />
      <Group justify="space-between">
        <b>{prescription.name}</b>
        <Button variant="outline" onClick={navigateToPrescription}>
          {getString('viewDetails', language)}
        </Button>
      </Group>
      <Group justify="space-between">
        <Text>
          <b>{getString('plannedCrop', language)}:</b> {capitalize(prescription.crop)}
        </Text>
        <Text>
          <b>{getString('input', language)}:</b> {input}
        </Text>
      </Group>
      <Group justify="space-between">
        <Text>
          <b>{getString('formula', language)}:</b> {formula}
        </Text>
        <Text>
          <b>{getString('fieldRate', language)}:</b> {fieldRate}
        </Text>
      </Group>
      <Group justify="space-between">
        <Text>
          <b>{getString('range', language)}:</b> {range}
        </Text>
        {prescription.seed_id === null && (
          <Text>
            <b>{getString('fieldCost', language)}:</b> {fieldCost}
          </Text>
        )}
      </Group>
      {getExportedScripts()}
    </Stack>
  );
};

export default PrescriptionSummary;
