import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatCalendarMonthDayYear } from 'util/date';
import useBrowserLanguage from 'util/hooks/useLanguage';
import { getUploadStatusDisplayName } from 'util/jobUtils';
import { getString } from 'strings/translation';
import { JOB_STATUSES, JobType } from 'store/jobs/types';
import { OperationType } from 'store/operation/types';
import { Button, Table, TableCol, TableRow } from 'common';

import styles from './JobTable.module.css';

interface JobTableProps {
  cancelJob: (id: number) => void;
  operation: OperationType;
  pastJobs: JobType[];
}

const JobTable = ({ cancelJob, operation, pastJobs }: JobTableProps) => {
  const navigate = useNavigate();
  const language = useBrowserLanguage();

  const tableRows = useMemo(() => {
    return pastJobs.map((job) => (
      <TableRow key={job.run_id}>
        <TableCol>{job.name}</TableCol>
        <TableCol>{formatCalendarMonthDayYear(job.created_at)}</TableCol>
        <TableCol>{getUploadStatusDisplayName(job, language)}</TableCol>
        <TableCol>
          {job.status === JOB_STATUSES.READY_FOR_INGEST && (
            <>
              <Button
                onClick={() => navigate(`/operations/${operation.id}/ingest_summary/${job.run_id}`)}
              >
                {getString('completeIngest', language)}
              </Button>
              <Button className={styles.Discard} danger onClick={() => cancelJob(job.id)}>
                {getString('discard', language)}
              </Button>
            </>
          )}
          {job.status === JOB_STATUSES.QUEUED && (
            <Button dataTestId="cancel-shapefile-ingest" danger onClick={() => cancelJob(job.id)}>
              {getString('cancelIngest', language)}
            </Button>
          )}
        </TableCol>
      </TableRow>
    ));
  }, [cancelJob, navigate, operation, pastJobs, language]);

  return (
    <>
      <p>{getString('pastFileUpload', language)}</p>
      <Table>
        <TableRow className={styles.Header}>
          <TableCol>{getString('filename', language)}</TableCol>
          <TableCol>{getString('upload', language)}</TableCol>
          <TableCol>{getString('status', language)}</TableCol>
          <TableCol />
        </TableRow>
        {tableRows}
      </Table>
    </>
  );
};

export default JobTable;
