import React from 'react';
import { Select, Table } from '@mantine/core';

import { AGENCY } from 'constants/products';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { joinStrings } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { Agency } from 'store/agencies/types';
import { RoleType } from 'store/roles/types';
import { User } from 'store/user/types';

interface RolesWithDisplayV2 {
  displayName: string;
  label: string;
  role: RoleType;
  value: string;
  id: number;
}

interface Props {
  onChange: Function;
  user: User;
  roles: RolesWithDisplayV2[];
  currentUserId: number;
  agency: Agency;
}

const UserTableRow = ({ user, roles, onChange, currentUserId, agency }: Props) => {
  const language = useBroswerLanguage();

  const role = roles.find((s) => s.id === user?.roles[0].id);

  return (
    <Table.Tr key={user.id}>
      <Table.Td>
        {joinStrings([user.first_name, user.last_name], ' ')}
        {` ${user.agency_primary ? `(${getString('primaryAgent', language)})` : ''}`}
      </Table.Td>
      {agency.hierarchy === AGENCY && <Table.Td>{user.agencies?.[0].name}</Table.Td>}
      <Table.Td>{user.email}</Table.Td>
      <Table.Td>
        {roles.find((s) => s.id === user?.roles[0].id) && (
          <Select
            data={roles}
            value={role?.value}
            disabled={user.agency_primary || currentUserId === user.id}
            onChange={onChange(user)}
          />
        )}
      </Table.Td>
    </Table.Tr>
  );
};

export default UserTableRow;
