import { useSelector } from 'react-redux';
import { Select, Stack, Text, Textarea } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useOperationUsersQuery } from 'apps/ZoneAnalysisV3/queries';

import { EXTERNAL_LAB_SKU_ID } from 'constants/pricing';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleTimingsQueryOptions } from 'util/queryOptions';
import { getSamplerOptions, getScannerOptions } from 'util/samplePlan';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { OperationType } from 'store/operation/types';
import { userIsSuperAdmin } from 'store/user/selectors';

type Props = {
  operation: OperationType;
};

const SamplingSection = ({ operation }: Props) => {
  const { isSuperAdmin } = useSelector((state: RootState) => ({
    isSuperAdmin: userIsSuperAdmin(state),
  }));

  const language = useBroswerLanguage();
  const operationUsersQuery = useOperationUsersQuery();
  const { data: operationUsersResponse } = operationUsersQuery;
  const operationUsers = operationUsersResponse?.users;
  const samplerOptions = getSamplerOptions({ operationUsers });
  const scannerOptions = getScannerOptions({ operationUsers });
  const sampleTimingsQuery = useQuery(sampleTimingsQueryOptions);
  const form = useOrderFormContext();
  const { sampledById, products, scannedById, nutrientAnalysisPackageId } = form.getValues();
  const { isProOrTillRx } = form.getTransformedValues();
  const isExternalLab = nutrientAnalysisPackageId === EXTERNAL_LAB_SKU_ID;

  if (sampleTimingsQuery.isPending) {
    return null;
  }

  return (
    <Stack maw={300} flex={1}>
      <Text fz="h4" fw="bold" component="h2">
        {getString('sampling', language)}
      </Text>
      {products.length ? (
        <>
          {operation?.billing_user_id ? (
            <Select
              key={form.key('sampledById')}
              data={samplerOptions}
              label={getString('sampledBy', language)}
              {...form.getInputProps('sampledById')}
              value={String(sampledById)}
              onChange={(value) => {
                form.setValues({ sampledById: Number(value) });
              }}
              error={
                !sampledById && isExternalLab && !isSuperAdmin
                  ? getString('sampledByExternalLabMsg', language)
                  : undefined
              }
            />
          ) : (
            <Text c="darkRed">**{getString('addBillingResponsibility', language)}**</Text>
          )}
        </>
      ) : null}
      {isProOrTillRx && (
        <Select
          key={form.key('scannedById')}
          data={scannerOptions}
          label={getString('scannedBy', language)}
          {...form.getInputProps('scannedById')}
          value={String(scannedById)}
          onChange={(value) => {
            form.setValues({ scannedById: Number(value) });
          }}
        />
      )}
      <Select
        key={form.key('sampleTiming')}
        required
        label={getString('sampleTiming', language)}
        data={sampleTimingsQuery.data?.sampling_timings.map((timing) => ({
          value: timing.value,
          label: timing.display_name,
        }))}
        {...form.getInputProps('sampleTiming')}
      />
      <Textarea
        key={form.key('samplerInstructions')}
        label={getString('samplerInstructions', language)}
        minRows={4}
        {...form.getInputProps('samplerInstructions')}
      />
    </Stack>
  );
};

export default SamplingSection;
