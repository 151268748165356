import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { approvalStatusType } from 'constants/routes/financeRoutes';

import { financialQueryKeys } from 'util/queryKeys';
import {
  getFinancialTransactionsByUser,
  getSummaryFinancialTransactionsByUser,
  getSummmaryFinancialTransactions,
} from 'store/payments/requests';
import { getIncompleteSalesOrders } from 'store/pricing/requests';

export const useSummaryFTsQuery = (approvalStatus: approvalStatusType) => {
  return useQuery({
    queryKey: financialQueryKeys.summaryTransactions(approvalStatus),
    queryFn: () => getSummmaryFinancialTransactions(approvalStatus, 1),
    placeholderData: keepPreviousData,
  });
};

export const useIndividualSummaryFtQuery = (approvalStatus: approvalStatusType, userId: number) => {
  return useQuery({
    queryKey: financialQueryKeys.individualSummaryTransactions(approvalStatus, userId),
    queryFn: () => getSummaryFinancialTransactionsByUser(approvalStatus, userId),
  });
};

export const useTransactionsByUserQuery = (approvalStatus: approvalStatusType, userId: number) => {
  return useQuery({
    queryKey: financialQueryKeys.transactionsByUser(approvalStatus, userId),
    queryFn: () => getFinancialTransactionsByUser(approvalStatus, userId),
  });
};

export const useIncompleteSalesOrdersQuery = () => {
  return useQuery({
    queryKey: financialQueryKeys.incompleteSalesOrders,
    queryFn: getIncompleteSalesOrders,
  });
};
