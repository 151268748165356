import { Flex } from '@mantine/core';

import SplitDensityForm from './SplitDensityForm';

type Props = {
  map: React.ReactNode;
};

const SplitDensityStep = (props: Props) => {
  const { map } = props;

  return (
    <Flex h="100%" w="100%" gap="xl">
      <SplitDensityForm />
      {map}
    </Flex>
  );
};

export default SplitDensityStep;
