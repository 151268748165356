import React, { ReactElement } from 'react';
import { Box, Divider, Stack, Title } from '@mantine/core';

import {
  BD_COARSE_DRY_MASS,
  BD_FINE_DRY_MASS,
  BD_TOTAL_DRY_MASS,
  TOTAL_CARBON,
  TOTAL_INORGANIC_CARBON,
  TOTAL_ORGANIC_CARBON,
} from 'constants/analysis';

import { getAnalysisDisplayName, getCarbonValue } from 'util/analysis';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';

type CarbonDetailsProps = {
  language: string;
  sample: LabSampleType;
  cellTemplate: (label: string, values: (string | number)[], links?: string[]) => ReactElement;
};

const CarbonDetails = ({ language, sample, cellTemplate }: CarbonDetailsProps) => {
  const generateCarbonValueCell = (analysis: string) => {
    const label = getAnalysisDisplayName(analysis, language);
    const carbonValue = getCarbonValue(sample, analysis);
    const nonNullCarbonValueArray = carbonValue ? [carbonValue] : ['No data'];
    return cellTemplate(label, nonNullCarbonValueArray);
  };
  return (
    <Stack gap="sm" justify="space-between">
      <Title size="1.5rem" fw={500}>
        {getString('carbonDetails', language)}
      </Title>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: 'var(--mantine-spacing-xs)',
        }}
      >
        {generateCarbonValueCell(BD_TOTAL_DRY_MASS)}
        {generateCarbonValueCell(TOTAL_CARBON)}
        {generateCarbonValueCell(BD_FINE_DRY_MASS)}
        {generateCarbonValueCell(TOTAL_INORGANIC_CARBON)}
        {generateCarbonValueCell(BD_COARSE_DRY_MASS)}
        {generateCarbonValueCell(TOTAL_ORGANIC_CARBON)}
      </Box>
      <Divider />
    </Stack>
  );
};
export default CarbonDetails;
