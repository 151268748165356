import { Link } from 'react-router-dom';
import { Anchor, Button, Table } from '@mantine/core';

import { projectsRoutes } from 'constants/routes';

import { convertUtcToLocale } from 'util/date';
import { ProjectType } from 'store/projects/types';

import { useProjectsTranslations } from './hooks';

const { Th, Td, Tr, Thead, Tbody } = Table;

type Props = {
  projects: ProjectType[];
};

const ProjectsTable = ({ projects }: Props) => {
  const translations = useProjectsTranslations();

  const rows = projects.map((project) => (
    <Tr key={project.id}>
      <Td>
        <Anchor fw="bold" component={Link} to={projectsRoutes.routes.project(project.id)}>
          {project.name}
        </Anchor>
      </Td>
      <Td>{project.fields.length}</Td>
      <Td>{project.total_acreage}</Td>
      <Td>{convertUtcToLocale(project.created_at).getFullYear()}</Td>
      <Td>{/* Status... */}</Td>
      <Td>
        <Button
          component={Link}
          to={projectsRoutes.routes.project(project.id)}
          size="xs"
          variant="outline"
        >
          {translations.viewProject}
        </Button>
      </Td>
    </Tr>
  ));

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{translations.projectName}</Th>
          <Th>{translations.fields}</Th>
          <Th>{translations.acres}</Th>
          <Th>{translations.year}</Th>
          <Th>{translations.status}</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>{rows}</Tbody>
    </Table>
  );
};

export default ProjectsTable;
