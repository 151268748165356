import {
  CountryType,
  RECEIVE_ALERT_NAMES,
  RECEIVE_COUNTRIES,
  RECEIVE_LABS,
  RECEIVE_OPERATION_ASSOCIATED_USERS,
  RECEIVE_PATTERN_SAMPLERS,
  RECEIVE_SINGLE_USER,
  RECEIVE_USER_SET,
  REQUEST_USERS,
  SET_USER_FETCHING,
  User,
  UserActionType,
  USERS_REQUEST_ERROR,
  UserSummary,
} from './types';

export const userRequestError = (id?: number): UserActionType => {
  return {
    type: USERS_REQUEST_ERROR,
    payload: {
      id,
    },
  };
};

export const requestUsers = (id?: number): UserActionType => {
  return {
    type: REQUEST_USERS,
    payload: {
      id,
    },
  };
};

export const receiveUserSet = (summary: UserSummary): UserActionType => ({
  type: RECEIVE_USER_SET,
  payload: {
    summary,
  },
});

export const setUserFetching = (isFetching: boolean): UserActionType => ({
  type: SET_USER_FETCHING,
  payload: {
    isFetching,
  },
});

export const receiveSingleUser = (user: User): UserActionType => ({
  type: RECEIVE_SINGLE_USER,
  payload: user,
});

export const receiveOperationUsers = (users: User[]): UserActionType => {
  return {
    type: RECEIVE_OPERATION_ASSOCIATED_USERS,
    payload: {
      users,
    },
  };
};

export const receivePatternSamplers = (users: User[]): UserActionType => {
  return {
    type: RECEIVE_PATTERN_SAMPLERS,
    payload: {
      users,
    },
  };
};

export const receiveCountries = (countries: CountryType[]): UserActionType => {
  return {
    type: RECEIVE_COUNTRIES,
    payload: {
      countries,
    },
  };
};

export const receiveAlertNames = (alertNames: { [alertType: string]: string }): UserActionType => {
  return {
    type: RECEIVE_ALERT_NAMES,
    payload: {
      alertNames,
    },
  };
};

export const receiveLabs = (labs: string[]): UserActionType => {
  return {
    type: RECEIVE_LABS,
    payload: {
      labs,
    },
  };
};
