import { useQueries, useQuery } from '@tanstack/react-query';
import { Feature, featureCollection, MultiPolygon } from '@turf/helpers';

import { projectsQueryKeys } from 'util/queryKeys';
import { fieldQueryOptions, operationQueryOptions } from 'util/queryOptions';
import { requestClusResults } from 'store/fields/requests';
import { FieldPropertiesType } from 'store/fields/types';
import { ProjectFieldsUseQueriesResult } from 'store/projects/types';

export const useProjectFieldsByOperationQueries = (
  operationIds: number[],
): ProjectFieldsUseQueriesResult => {
  return useQueries({
    queries: operationIds.map((operationId) => operationQueryOptions(operationId)),
    combine: (results) => ({
      isPending: results.some((result) => result.isPending),
      fields: results
        .map(
          ({ data }) =>
            data?.fields.map((field) => ({
              ...field,
              operationName: data.name,
              operationId: data.id,
            })) || [],
        )
        .flat(),
    }),
  });
};

export const useFieldsQueries = (
  fieldIds: number[],
): {
  isPending: boolean;
  fieldFeatures: (Feature<MultiPolygon, FieldPropertiesType> | undefined)[];
} => {
  return useQueries({
    queries: fieldIds.map((fieldId) => fieldQueryOptions(fieldId)),
    combine: (results) => ({
      isPending: results.some((result) => result.isPending),
      fieldFeatures: results.map(({ data }) => data?.features).flat(),
    }),
  });
};

export const useClusQuery = (bounds: null | [number, number, number, number]) => {
  return useQuery({
    queryKey: projectsQueryKeys.clus(bounds),
    queryFn: () => requestClusResults(bounds as [number, number, number, number]),
    enabled: !!bounds,
    select: ({ features }) => {
      return featureCollection(
        features.map((cluFeature, i) => ({
          ...cluFeature,
          id: i,
          properties: { id: i },
        })),
      );
    },
  });
};
