import { useState } from 'react';
import { Button, Space } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import { SplitFieldCreateFate } from 'constants/fields';
import { MERGE, MODES, SPLIT } from 'constants/mapbox';

import { getString } from 'strings/translation';
import { DrawMode } from 'common/Maps/types';

import SplitFieldModal from './SplitFieldModal';

import styles from './DrawingTools.module.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,@typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// fixes a mapbox-gl issue, will not be needed soon

type DrawingToolsPropType = {
  showClus: boolean;
  drawModeSetter: (
    arg0: DrawMode,
  ) => (((event: any) => void) & ((event: string) => void)) | undefined;
  handleDelete: () => void;
  startMergeFields: () => void;
  startSplitFields: () => void;
  mergeSplitType: string | null;
  mergeSelectedPolygons: number[];
  confirmMerge: () => void;
  confirmSplit: (newName: string, fateAfterCreate: SplitFieldCreateFate) => void;
  cancelSplitMerge: () => void;
  mode: string;
  language: string;
  drawRef: any;
  isLoading: boolean;
};

const DrawingToolsV2 = ({
  showClus,
  drawModeSetter,
  handleDelete,
  startMergeFields,
  startSplitFields,
  mergeSplitType,
  mergeSelectedPolygons,
  confirmMerge,
  confirmSplit,
  cancelSplitMerge,
  mode,
  language,
  drawRef,
  isLoading,
}: DrawingToolsPropType) => {
  const [showSplitFieldModal, setShowSplitFieldModal] = useState(false);

  const isMerge = mergeSplitType === MERGE;
  const isSplit = mergeSplitType === SPLIT;
  const isNotMergeOrSplit = !(isMerge || isSplit);

  return (
    <div className={styles.Controls}>
      {isMerge && (
        <>
          <Button
            disabled={isLoading || (mergeSelectedPolygons || [])?.length < 2}
            onClick={confirmMerge}
          >
            {getString('confirmMerge', language)}
          </Button>
          <Button variant="white" onClick={cancelSplitMerge} disabled={isLoading}>
            {getString('cancelMerge', language)}
          </Button>
        </>
      )}
      {isSplit && (
        <>
          <SplitFieldModal
            language={language}
            open={showSplitFieldModal}
            onClose={() => setShowSplitFieldModal(false)}
            onSubmit={confirmSplit}
            isLoading={isLoading}
          />
          <Space h="xs" />
          <Button
            onClick={() => setShowSplitFieldModal(true)}
            style={{ width: '11rem' }}
            disabled={
              !drawRef.current?.getAll()?.features?.every((f) => {
                return f.geometry.coordinates[0].some((c) => c);
              }) || isLoading
            }
          >
            {getString('confirmSplit', language)}
          </Button>
          <Button
            onClick={cancelSplitMerge}
            disabled={isLoading}
            style={{ width: '11rem' }}
            variant="white"
          >
            {getString('cancelSplit', language)}
          </Button>
        </>
      )}
      {isNotMergeOrSplit && (
        <>
          <Button
            onClick={drawModeSetter(MODES.SELECT)}
            variant={showClus && mode === MODES.SELECT ? 'filled' : 'white'}
            disabled={isLoading}
          >
            {getString('selectClus', language)}
          </Button>
          <Button
            onClick={drawModeSetter(MODES.DRAW_POLYGON)}
            variant={mode === MODES.DRAW_POLYGON && !mergeSplitType ? 'filled' : 'white'}
          >
            {getString('drawPolygon', language)}
          </Button>
          <Button
            onClick={drawModeSetter(MODES.DRAW_RECTANGLE)}
            variant={mode === MODES.DRAW_RECTANGLE ? 'filled' : 'white'}
            disabled={isLoading}
          >
            {getString('drawRectangle', language)}
          </Button>
          <Button color="darkRed" onClick={handleDelete} disabled={isLoading}>
            {getString('delete', language)}
          </Button>
          <Button
            onClick={startMergeFields}
            disabled={isLoading}
            variant={isMerge && mode === MODES.SELECT ? 'filled' : 'white'}
          >
            {getString('mergeFields', language)}
          </Button>
          <Button
            variant={isSplit ? 'filled' : 'white'}
            disabled={isLoading}
            onClick={() => {
              drawRef.current?.deleteAll();
              startSplitFields();
            }}
          >
            {getString('splitFields', language)}
          </Button>
        </>
      )}
    </div>
  );
};

export default DrawingToolsV2;
