import React from 'react';
import { Button, Group, Modal } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

type Props = {
  opened: boolean;
  changeFunction: VoidFunction;
  onClose: VoidFunction;
  modalTitle: string;
  modalBody: string;
};

export const ConfirmActionModal = ({
  opened,
  changeFunction,
  onClose,
  modalTitle,
  modalBody,
}: Props) => {
  const language = useBroswerLanguage();

  const handleConfirmedChange = () => {
    changeFunction();
    onClose();
  };

  const handleCancelledChange = () => {
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title={modalTitle} centered>
      <p>{getString(modalBody, language)}</p>
      <Group justify="center">
        <Button onClick={handleConfirmedChange}>{getString('yes', language)}</Button>
        <Button onClick={handleCancelledChange}>{getString('no', language)}</Button>
      </Group>
    </Modal>
  );
};
