import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { phCalibrations } from 'constants/controls';
import { chemistryPlateTypes } from 'constants/plates';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getAllControls } from 'store/controls/thunks';
import { getAllNaptControls } from 'store/naptControls/thunks';
import { NaptControlType } from 'store/naptControls/types';
import { PlateType, WellType } from 'store/plates/types';
import { Label, Typeahead } from 'common';

import { SharedFormInputs } from './FormInputs/SharedInputs';

import styles from './Container.module.css';

type ControlSampleFormPropsType = {
  plate: PlateType;
  well: WellType;
  onValueChange: (
    attributeKey: keyof WellType,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => void;
};

export const ControlSampleForm = ({ plate, well, onValueChange }: ControlSampleFormPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { controls, hasFetched, naptControls, hasFetchedNapt } = useSelector(
    (state: RootState) => ({
      controls: state.controls.controls,
      hasFetched: state.controls.hasFetched,
      naptControls: state.naptControls.naptControls,
      hasFetchedNapt: state.naptControls.hasFetched,
    }),
  );

  const isChemistry = chemistryPlateTypes.includes(plate.plate_type);
  const formControls = isChemistry
    ? naptControls.concat(phCalibrations as NaptControlType[])
    : controls;
  const controlNameKey = isChemistry ? 'control_code' : 'barcode';

  useEffect(() => {
    if (!controls?.length && !hasFetched) {
      dispatch(getAllControls());
    }
  }, [dispatch, controls]);

  useEffect(() => {
    if (!naptControls?.length && !hasFetchedNapt) {
      dispatch(getAllNaptControls());
    }
  }, [dispatch, naptControls]);

  const controlOptions = (formControls || [])
    .sort((a, b) => a[controlNameKey].localeCompare(b[controlNameKey]))
    .map((control, cindex) => ({
      id: cindex,
      displayName: control[controlNameKey],
    }));

  const handleControlSelection = (idx: number) => {
    const selectedControl = controlOptions[idx];
    onValueChange('sample_barcode', selectedControl.displayName);
  };

  const handleDeselection = () => {
    onValueChange('sample_barcode', '');
  };

  return (
    <>
      <Label className={styles.InputLabel} label={getString('controlType', language)}>
        <Typeahead
          className={styles.ControlSelector}
          placeholder={well.sample_barcode ? well.sample_barcode : getString('selectOne', language)}
          onSelect={handleControlSelection}
          onDeselect={handleDeselection}
          onNewOption={() => {}}
          options={controlOptions}
          icon="SearchIcon"
        />
      </Label>
      <SharedFormInputs well={well} onValueChange={onValueChange} />
    </>
  );
};
