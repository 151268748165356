import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  SegmentedControl,
  Space,
  Text,
  Title,
} from '@mantine/core';
import { useMutation } from '@tanstack/react-query';

import { UI_COLORS } from 'constants/colors';
import { financeRoutes } from 'constants/routes';

import showToast from 'actions/toastActions';
import { retryIncompleteSalesOrder } from 'store/pricing/requests';
import CenteredLoader from 'common/CenteredLoader';

import SummaryTable from './Components/SummaryTable';
import { useBillingTranslations } from './hooks';
import { useIncompleteSalesOrdersQuery, useSummaryFTsQuery } from './queries';

const FinanceContainer = () => {
  const translations = useBillingTranslations();
  const navigate = useNavigate();
  const { approvalStatus } = useParams<Record<keyof typeof financeRoutes.routeParams, string>>();
  const ftQuery = useSummaryFTsQuery(approvalStatus || financeRoutes.routeParams.open);
  const incompleteSalesOrdersQuery = useIncompleteSalesOrdersQuery();

  const [isRetryAvailable, toggleIsRetryAvailable] = useState(true);

  const incompleteSalesOrderJobMutation = useMutation({
    mutationFn: retryIncompleteSalesOrder,
    onSuccess: () => {
      showToast(translations.salesOrderCreatedMsg, 'success');
      toggleIsRetryAvailable(false);
    },
  });

  const openApprovedSelects = [
    { value: financeRoutes.routeParams.open, label: translations.open },
    { value: financeRoutes.routeParams.approved, label: translations.approved },
  ];

  if (ftQuery.isPending || incompleteSalesOrdersQuery.isPending) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Box pos="sticky" top={0} bg="var(--mantine-color-body)" style={{ zIndex: 3 }} pt="xl">
        <Group justify="space-between">
          <Title mb="md">
            {translations.billing} - {translations.activityDetails}
          </Title>
          <SegmentedControl
            value={approvalStatus}
            data={openApprovedSelects}
            onChange={(val) => navigate(financeRoutes.routes.all(val))}
          />
        </Group>
        <Divider size="sm" />
        {!!incompleteSalesOrdersQuery.data?.length && (
          <Accordion>
            <Accordion.Item value="incompleteSalesOrders">
              <Accordion.Control c={UI_COLORS.white} bg={UI_COLORS.error}>
                <Group justify="space-between">
                  <Text>{translations.failedSalesOrderJobs}</Text>
                  {isRetryAvailable && (
                    <Button
                      variant="subtle"
                      mr="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        incompleteSalesOrderJobMutation.mutate();
                      }}
                    >
                      {translations.retry}
                    </Button>
                  )}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {incompleteSalesOrdersQuery.data.map((salesOrderJob) => (
                  <Grid key={salesOrderJob.id}>
                    <Grid.Col span={3}>
                      <b>{translations.agency}: </b> {salesOrderJob.agency_name}
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <b>{translations.operation}: </b> {salesOrderJob.operation_name}
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <b>{translations.field}: </b> {salesOrderJob.field_name}
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <b>{translations.error}: </b> {salesOrderJob.errors}
                    </Grid.Col>
                  </Grid>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )}
      </Box>
      <Space h="lg" />
      <SummaryTable
        summaryData={ftQuery.data?.items}
        approvalStatus={approvalStatus}
        showNavigate
      />
    </>
  );
};

export default FinanceContainer;
