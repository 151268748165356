import React, { FunctionComponent, PropsWithChildren } from 'react';

import styles from './Container.module.css';

interface Props {
  title: string;
}

const Section: FunctionComponent<
  PropsWithChildren<Props | React.HTMLAttributes<HTMLDivElement>>
> = ({ title, children, ...rest }) => {
  return (
    <div className={styles.Section} {...rest}>
      <h2 className={styles.SectionTitle}>{title}</h2>
      <div className={styles.Divider} />
      <div>{children}</div>
    </div>
  );
};

export default Section;
