import center from '@turf/center';
import { Point } from 'geojson';
import { FillLayer, GeoJSONSource, LngLatLike, Map } from 'mapbox-gl';

import {
  CLUSTERS_SOURCE_ID,
  EVERY_CLICKABLE_LAYER_ID,
  FIELD_POPUP_LAYER_IDS,
  FIELDS_LAYER_IDS,
  SPLIT_MERGE_SELECTED_PAINT,
} from 'constants/fields';
import { MERGE, SPLIT } from 'constants/mapbox';

import { FieldPropertiesType, FieldType, SamplingPlanType } from 'store/fields/types';
import { MapboxClickEvent } from 'common/Maps/types';

import { sampleIsActive, sampleIsPrimary } from './sample';
import { haToAcres } from './units';

export const getFieldName = (fieldGeometry: FieldType, samplingPlan: SamplingPlanType) => {
  const { acreage, name } = fieldGeometry.features[0].properties;

  const acreageString = `${Math.round(acreage)} Acres`;

  if (!samplingPlan) {
    return `${name} (${acreageString})`;
  }

  const { samples } = samplingPlan;
  const primarySamples = samples.filter(sampleIsActive).filter(sampleIsPrimary);
  const samplesString =
    primarySamples && `${primarySamples.length} Sample${primarySamples.length !== 1 ? 's' : ''}`;

  return `${name} (${acreageString} / ${samplesString})`;
};

export const getFieldAcresDisplay = (fieldGeometry: FieldType): string => {
  const { acreage, acreage_unit } = fieldGeometry.features[0].properties;
  const convertedAcres = haToAcres(acreage, acreage_unit);
  return `${convertedAcres.toFixed(0)} ${acreage_unit}`;
};

export const hasGeom = (field: FieldType) => {
  return field.features[0]?.geometry !== null;
};

/** Also adds the field's `name` to the resulting features' `properties`.  */
export const getFieldCentroids = (
  fieldFeatures: FieldType['features'],
): Array<GeoJSON.Feature<GeoJSON.Point, Pick<FieldPropertiesType, 'name' | 'id'>>> => {
  return fieldFeatures.map((feature) => ({
    ...feature,
    geometry: {
      type: 'Point',
      coordinates: center(feature).geometry.coordinates,
    },
  }));
};

export const getSplitMergeLayerConfig = (feature: GeoJSON.Feature, layerId: string): FillLayer => ({
  id: layerId,
  type: 'fill',
  paint: SPLIT_MERGE_SELECTED_PAINT,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [feature] },
  },
});

export const getSplitMergeLayerId = (id: number): string => {
  return `${FIELDS_LAYER_IDS.splitMergePrefix}${id}`;
};

export const setFieldMapHoverListeners = (
  map: Map,
  layerIds = [
    FIELDS_LAYER_IDS.clusters,
    FIELDS_LAYER_IDS.cluFillUnselected,
    ...FIELD_POPUP_LAYER_IDS,
  ],
) => {
  map.on('mouseenter', layerIds, ({ target: mapInstance }) => {
    mapInstance.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', layerIds, ({ target: mapInstance }) => {
    mapInstance.getCanvas().style.cursor = '';
  });
};

/**
 * Store every click listener in one spot. Be sure to run `map.off` in a useEffect cleanup function,
 * a `map.on` in the effect any time something dynamic changes, and don't include layer IDs as an
 * argument, does not seem to work that way.
 */
export const setFieldMapClickListeners = ({
  evt,
  updateCluFilter,
  onFieldClick,
  mergeSplitSelect,
  mergeSplitType,
  fieldIdFromRoute,
}: {
  evt: MapboxClickEvent;
  updateCluFilter: (clusId: number) => void;
  onFieldClick: (properties: FieldPropertiesType) => void;
  mergeSplitSelect: (evt: MapboxClickEvent) => void;
  mergeSplitType: string | null;
  fieldIdFromRoute: string | undefined;
}) => {
  const map = evt.target;
  const features = map.queryRenderedFeatures(evt.point, { layers: EVERY_CLICKABLE_LAYER_ID });
  const topMostFeature = features[0];

  if (mergeSplitType === SPLIT || !topMostFeature) {
    return;
  }

  const layerId = topMostFeature.layer.id;
  const topMostFeatureProperties = topMostFeature.properties;
  const fieldProperties = topMostFeatureProperties as FieldPropertiesType;

  if (mergeSplitType === MERGE) {
    mergeSplitSelect(evt);
  } else if (layerId === FIELDS_LAYER_IDS.clusters) {
    (map.getSource(CLUSTERS_SOURCE_ID) as GeoJSONSource).getClusterExpansionZoom(
      topMostFeatureProperties?.cluster_id,
      (err, zoom) => {
        if (err) return;

        map.easeTo({
          center: (topMostFeature.geometry as Point).coordinates as LngLatLike,
          zoom,
        });
      },
    );
  } else if (layerId === FIELDS_LAYER_IDS.cluFillUnselected) {
    updateCluFilter(topMostFeature.properties?.id as number);
  } else if (
    FIELD_POPUP_LAYER_IDS.includes(layerId) &&
    fieldProperties.id.toString() !== fieldIdFromRoute
  ) {
    onFieldClick(fieldProperties);
  }
};

export default { getFieldName };
