import { featureCollection } from '@turf/helpers';

import showToast from 'actions/toastActions';
import { AppThunk } from 'store';
import { putSample } from 'store/samples/requests';

import {
  labSampleRequestError,
  receiveFieldLabSamples,
  receiveLabSamples,
  requestLabSamples,
} from './actions';
import {
  requestGetLabSamplesForField,
  requestGetSearchSample,
  requestPutMarkSampleInstanceArrived,
  requestPutMarkSampleInstanceSterilized,
  requestPutSampleChemistryResults,
} from './requests';
import { LabSampleType } from './types';

export const searchSample =
  (searchValue: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabSamples());
    try {
      const samples = await requestGetSearchSample(searchValue);
      if (samples.length) {
        dispatch(receiveLabSamples(samples));
      } else {
        throw Error('Sample does not exist. Please try again.');
      }
      return samples;
    } catch (e) {
      showToast(e.message, 'error');
      return dispatch(labSampleRequestError({ message: e.message }));
    }
  };

export const getLabSamples =
  (fieldId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabSamples());
    try {
      const samples = await requestGetLabSamplesForField(fieldId);
      dispatch(receiveFieldLabSamples({ id: fieldId, samples: samples }));
    } catch (e) {
      dispatch(labSampleRequestError({ message: e.message }));
    }
  };

export const markSampleArrived =
  (sampleUuid: string, barcode: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabSamples());
    try {
      await requestPutMarkSampleInstanceArrived(sampleUuid);
      if (barcode) {
        dispatch(searchSample(barcode));
      }
    } catch (e) {
      showToast(e.message, 'error');
      return dispatch(labSampleRequestError({ message: e.message }));
    }
  };

export const markSampleSterilized =
  (sampleUuid: string, barcode: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabSamples());
    try {
      await requestPutMarkSampleInstanceSterilized(sampleUuid);
      if (barcode) {
        dispatch(searchSample(barcode));
      }
    } catch (e) {
      showToast(e.message, 'error');
      return dispatch(labSampleRequestError({ message: e.message }));
    }
  };

export const editSampleValues =
  (sample_id: number, barcode: string, properties: Partial<LabSampleType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabSamples());
    try {
      const body = featureCollection([
        {
          geometry: {
            geometries: [],
          },
          type: 'Feature',
          properties: properties,
        },
      ]);
      await putSample(sample_id, body);
      if (barcode) {
        dispatch(searchSample(barcode));
      }
    } catch (e) {
      showToast(e.message, 'error');
      return dispatch(labSampleRequestError({ message: e.message }));
    }
  };

export const editChemistryResults =
  (barcode: string, ph: number | null, bph: number | null): AppThunk =>
  async (dispatch) => {
    dispatch(requestLabSamples());
    try {
      await requestPutSampleChemistryResults(barcode, ph, bph);
      if (barcode) {
        dispatch(searchSample(barcode));
      }
    } catch (e) {
      showToast(e.message, 'error');
      return dispatch(labSampleRequestError({ message: e.message }));
    }
  };
