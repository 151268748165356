import { Group, Stack } from '@mantine/core';

import { projectsRoutes } from 'constants/routes';

import { ProjectField } from 'store/projects/types';
import { Header } from 'common';

import AddAcctsToProjectModal from './AddAcctsToProjectModal';
import EditProjectNameModal from './EditProjectNameModal';
import { useProjectsTranslations } from './hooks';

type Props = {
  projectId: number;
  projectFields: ProjectField[];
  fieldsListIsEditable: boolean;
  operationNames: Array<string | null>;
  projectName?: string;
  children?: React.ReactNode;
};

const CarbonProjectHeader = ({
  projectName = '',
  children,
  projectId,
  projectFields,
  fieldsListIsEditable,
  operationNames,
}: Props) => {
  const translations = useProjectsTranslations();

  const projectNameText = projectName
    ? `${translations.project}: ${projectName}`
    : translations.project;

  return (
    <Header
      breadcrumbsItems={[
        { title: translations.allProjects, to: projectsRoutes.routes.base },
        { title: projectNameText },
      ]}
      title={
        <Stack gap="xs" justify="start">
          <Group gap={0}>
            {projectNameText}
            <EditProjectNameModal projectId={projectId} currentProjectName={projectName} />
          </Group>
          <AddAcctsToProjectModal
            currentProjectFieldIds={projectFields.map((field) => field.id)}
            currentProjectOperationIds={projectFields.map((field) => field.operation_id)}
            projectId={projectId}
            operationNames={operationNames}
            canAddAccounts={fieldsListIsEditable}
          />
        </Stack>
      }
    >
      {children}
    </Header>
  );
};

export default CarbonProjectHeader;
