import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Group, Input, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import turfArea from '@turf/area';
import { feature, FeatureCollection, featureCollection, Properties } from '@turf/helpers';
import { useClusQuery } from 'apps/Projects/queries';
import { GeoJsonProperties, Geometry } from 'geojson';

import { NEW_FIELD_ID_ROUTE_VALUE } from 'constants/fields';
import { MODES } from 'constants/mapbox';
import { BRAZIL_VIEWPORT, US_MIDWEST_VIEWPORT } from 'constants/mapViewport';
import { projectsRoutes } from 'constants/routes';

import { getBoundsFromViewport, getFieldArea, squareMetersToAcres } from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { usePageTitle } from 'util/hooks/usePageTitle';
import { isNumber } from 'util/numUtils';
import { carbonProjectQueryOptions } from 'util/queryOptions';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { OrdersRouteParams, RootState } from 'store';
import { getFieldGeometries, getFieldGeometry, updateFieldGeometry } from 'store/fields/thunks';
import { operationRequestError, receiveSingleField } from 'store/operation/actions';
import { postField } from 'store/operation/requests';
import { getOperation, setActiveOperation, updateField } from 'store/operation/thunks';
import { Header, Spinner } from 'common';
import { DrawMode, ViewPortProps } from 'common/Maps/types';

import FieldBoundaryEditor from './FieldBoundaryEditor';

import styles from './Container.module.css';

type ArrayOfFourNumbers = [number, number, number, number];

const FieldBoundaryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();

  const { fieldId, operationId, projectId } = useParams<OrdersRouteParams>();
  const numericFieldId = Number(fieldId);
  const numericOperationId = Number(operationId);
  const isNewField = fieldId === NEW_FIELD_ID_ROUTE_VALUE;

  const drawRef = useRef<any>(null);
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [fieldName, setFieldName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState<DrawMode>(MODES.SELECT);
  const [selectedClus, setSelectedClus] = useState<number[]>([]);
  const [mergeSplitType, setMergeSplitType] = useState<string | null>(null);
  const [clusBounds, setClusBounds] = useState<null | ArrayOfFourNumbers>(null);

  const clusQuery = useClusQuery(clusBounds);

  const projectQuery = useQuery({
    enabled: !!projectId,
    ...carbonProjectQueryOptions(Number(projectId)),
  });

  const {
    operation,
    allFieldGeometries,
    featCollectionOfFieldBeingEdited,
    hasFailedList,
    hasFetchedList,
    user,
  } = useSelector((state: RootState) => {
    return {
      operation: state.operations.operationsById[numericOperationId],
      allFieldGeometries: state.fieldGeometry.geometries,
      hasFetchedList: state.fieldGeometry.hasFetchedList,
      hasFailedList: state.fieldGeometry.hasFailedList,
      user: state.user.currentUser,
      featCollectionOfFieldBeingEdited: isNumber(numericFieldId)
        ? state.fieldGeometry.geometries[numericFieldId]
        : undefined,
    };
  });

  const [viewport, setViewport] = useState<ViewPortProps>({
    ...(user?.user_locale === 'en' ? US_MIDWEST_VIEWPORT : BRAZIL_VIEWPORT),
    width: 0,
    height: 0,
  });

  const reloadClus = () => {
    setClusBounds(getBoundsFromViewport(viewport));
    setSelectedClus([]);
  };

  usePageTitle(fieldName);

  useEffect(() => {
    if (!featCollectionOfFieldBeingEdited && !isNewField) {
      dispatch(getFieldGeometry(numericFieldId));
    }
  }, [numericFieldId, featCollectionOfFieldBeingEdited]);

  useEffect(() => {
    if (featCollectionOfFieldBeingEdited) {
      dispatch(
        setActiveOperation(featCollectionOfFieldBeingEdited.features[0].properties.operation_id),
      );
      const { properties } = featCollectionOfFieldBeingEdited.features[0];
      setFieldName(properties.name);
    }
  }, [featCollectionOfFieldBeingEdited]);

  useEffect(() => {
    if (operation) {
      const newFieldIds = operation.fields.map((field) => field.id);
      dispatch(getFieldGeometries(newFieldIds));
    } else {
      dispatch(getOperation(numericOperationId));
    }
  }, [operation, numericOperationId]);

  // Fix loading flash, load page correctly
  useEffect(() => {
    const fieldIds = operation?.fields.map((field) => field.id);
    const fetchedOrFailedList = [...hasFetchedList, ...hasFailedList];

    if (fieldIds?.every((id) => fetchedOrFailedList.includes(id))) {
      setIsLoadingPage(false);
    }
  }, [hasFetchedList, hasFailedList, operation]);

  useEffect(() => {
    if (clusQuery.error) {
      showToast(getString(clusQuery.error.message || 'failedToLoadClusMsg', language), 'error');
    }
  }, [clusQuery.error]);

  useEffect(() => {
    !!mergeSplitType && setFieldName('');
  }, [mergeSplitType]);

  const selectedCluPolygons = featureCollection(
    (clusQuery.data?.features || []).filter((f) => selectedClus.includes(f.properties?.id)),
  );

  const submit = async () => {
    if (drawRef.current) {
      try {
        setIsSubmitting(true);

        const polygons: FeatureCollection<Geometry, GeoJsonProperties> | undefined =
          drawRef.current.getAll();

        if (polygons?.features) {
          const acres = squareMetersToAcres(turfArea(polygons));
          if (acres < 2.1) {
            setIsSubmitting(false);
            return showToast(getString('supportUnderTwoAcres', language), 'error');
          }
          polygons.features[0].properties = {
            name: fieldName,
            operation_id: operation.id,
          };

          if (isNewField) {
            const response = await postField(polygons);
            dispatch(receiveSingleField(response));
            const { id } = response.features[0].properties;
            dispatch(updateFieldGeometry(polygons, id));
          } else {
            dispatch(
              updateField(featureCollection([feature(null, { name: fieldName })]), numericFieldId),
            );
            dispatch(updateFieldGeometry(polygons, numericFieldId));
          }
        }
        dispatch(getOperation(operation.id));
        !!projectId && showToast(getString('fieldSavedMsg', language), 'success');
        navigate(projectId ? projectsRoutes.routes.project(projectId) : `/orders/${operation.id}`);
      } catch (err) {
        dispatch(operationRequestError);
        setIsSubmitting(false);
        showToast(getString('fieldAlreadyExists', language), 'error');
      }
    }
  };

  if (isLoadingPage || !operation) {
    return <Spinner fill />;
  }

  const fieldNameInput = (
    <Input
      className={styles.FieldNameInput}
      onChange={(e) => setFieldName(e.currentTarget.value)}
      placeholder={`${getString('fieldName', language)}...`}
      value={fieldName}
      disabled={!!mergeSplitType}
    />
  );

  // using selectedCluPolygons here allows us to display the acreage before adding the clus to the editor
  const fieldArea = selectedCluPolygons.features.length
    ? getFieldArea(selectedCluPolygons as FeatureCollection<Geometry, Properties>, language)
    : getFieldArea(drawRef.current?.getAll(), language);

  const operationName = operation ? `${operation.name} - ` : '';

  const title = `${operationName}${
    isNewField ? getString('create', language) : getString('edit', language)
  } ${getString('field', language)}`;

  const numPolygons = drawRef.current?.getAll().features.length || 0;

  const breadcrumbs =
    projectQuery.data && projectId
      ? [
          {
            title: getString('allProjects', language),
            to: projectsRoutes.routes.base,
          },
          {
            title: `${getString('project', language)}: ${projectQuery.data.name}`,
            to: projectsRoutes.routes.project(projectId),
          },
          { title: fieldName },
        ]
      : undefined;

  return (
    <>
      {isSubmitting && (
        <div className={styles.LoadingOverlay}>
          <Spinner className={styles.Spinner} fill />
        </div>
      )}
      <Header
        className={styles.Header}
        title={projectId ? <Box mt="md">{title}</Box> : title}
        breadcrumbsItems={breadcrumbs}
      >
        {fieldNameInput}
        <Group justify="space-between">
          <Text fw={500}>{getString('acres', language)}:</Text> <Text>{fieldArea.toFixed(2)}</Text>
          <Button data-test-id="save" disabled={!fieldName || numPolygons < 1} onClick={submit}>
            {getString('save', language)}
          </Button>
          <Button
            data-test-id="cancel"
            variant="outline"
            component={Link}
            to={projectId ? projectsRoutes.routes.project(projectId) : `/orders/${operationId}`}
          >
            {getString('cancel', language)}
          </Button>
        </Group>
      </Header>
      <FieldBoundaryEditor
        drawRef={drawRef}
        reloadClus={reloadClus}
        loadingClus={clusQuery.isFetching} // isPending is true if query is inactive 🤷
        cluPolygons={clusQuery.data?.features || []}
        selectedClusPolygons={selectedCluPolygons}
        selectedClus={selectedClus}
        setSelectedClus={setSelectedClus}
        featCollectionOfFieldBeingEdited={featCollectionOfFieldBeingEdited}
        allFieldGeometries={allFieldGeometries}
        isNewField={isNewField}
        mode={mode}
        setMode={setMode}
        setViewport={setViewport}
        viewport={viewport}
        operation={operation}
        mapRef={mapRef}
        mapContainerRef={mapContainerRef}
        mergeSplitType={mergeSplitType}
        setMergeSplitType={setMergeSplitType}
        clusHaveFetched={clusQuery.isFetched}
      />
    </>
  );
};

export default FieldBoundaryContainer;
