import { ActionIcon, Group, GroupProps, Stack, Text, Title, Tooltip } from '@mantine/core';

import { RESTRICTED_RED } from 'constants/colors';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { AMPLICON_QPCR, STORAGE } from 'constants/plates';

import { getAnalysisDisplayName } from 'util/analysis';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPlateDisplayName, getPlateStatusDisplayName } from 'util/plates';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';

type PlateHeaderProps = GroupProps & {
  plate: PlateType;
};

export const PlateHeader = ({ plate, ...props }: PlateHeaderProps) => {
  const language = useBroswerLanguage();

  const plateType = plate.plate_type;

  const barcodeSummary = () => {
    const { plate_type: type, barcode, related_plates } = plate;
    if (type !== AMPLICON_QPCR) {
      return barcode;
    }
    const plateIds = (related_plates?.parents_plates || []).map((p) => p.homogenization_plate_id);
    const barcodeSuffix = plateIds
      .filter(Boolean)
      .map((id) => `P${id}`)
      .join('-');

    return barcodeSuffix ? `${barcode} (${barcodeSuffix})` : barcode;
  };

  return (
    <Group gap="xs" {...props}>
      {plate.homogenization_plate_id && (
        <Group
          align="center"
          style={{
            borderRadius: '4px',
            border: '2px solid',
          }}
          p="0 0.6rem"
          bg={plate.is_regulated ? RESTRICTED_RED : undefined}
        >
          {`P${plate.homogenization_plate_id}`}
        </Group>
      )}
      <Stack gap="0">
        <Title order={2}>
          {`${getPlateDisplayName(plateType, language)}${
            plate.analysis_type ? ` - ${getAnalysisDisplayName(plate.analysis_type, language)}` : ''
          }`}
        </Title>
        <Group align="center" gap="0.25rem">
          <Text>
            {`${barcodeSummary()} - ${getPlateStatusDisplayName(plate.status, language)}`}
          </Text>
          {plate.status === STORAGE && (
            <Tooltip
              label={
                <Stack gap="0">
                  {['temp', 'freezer', 'rack', 'shelf'].map((attr) => (
                    <Text
                      key={attr}
                      fw={600}
                      size="sm"
                    >{`${getString(attr, language)}: ${plate.meta_data?.[attr] || NOT_APPLICABLE}`}</Text>
                  ))}
                </Stack>
              }
              c="blue"
              bg="white"
              style={{
                borderRadius: '4px',
                border: '2px solid',
              }}
              position="bottom-start"
            >
              <ActionIcon variant="outline" radius="lg" size="xs">
                <Text fw={600} size="sm">
                  ?
                </Text>
              </ActionIcon>
            </Tooltip>
          )}
          {plate.is_regulated && (
            <Group gap="0.25rem">
              <Text>{'-'}</Text>
              <Text c={RESTRICTED_RED}>{`(${getString('regulated', language)})`}</Text>
            </Group>
          )}
        </Group>
      </Stack>
    </Group>
  );
};
