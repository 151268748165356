import { useEffect, useState } from 'react';
import { DataTable, DataTableSortStatus, useDataTableColumns } from 'mantine-datatable';

import { sortArrOfObjects, sortCaseInsensitive } from 'util/sortByName';
import { CarbonProjectFieldRowData } from 'store/projects/types';

import getColumns from './getProjectFieldsColumns';
import { useProjectsTranslations } from './hooks';

type Props = {
  enableSelection: boolean;
  fields: CarbonProjectFieldRowData[];
  projectId: number;
  selectedFields: CarbonProjectFieldRowData[];
  setSelectedFields: (fields: CarbonProjectFieldRowData[]) => void;
};

const STORE_COLUMNS_KEY = 'project-fields-columns';

const ProjectFieldsTable = ({
  selectedFields,
  setSelectedFields,
  fields,
  enableSelection,
  projectId,
}: Props) => {
  const translations = useProjectsTranslations();

  const [sortedRecords, setSortedRecords] = useState(fields);
  const [fieldNameSearchText, setFieldNameSearchText] = useState('');
  const [filteredOperationNames, setFilteredOperationNames] = useState<string[]>([]);

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<CarbonProjectFieldRowData>>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const allOperationNames = fields
    .reduce<string[]>((acc, { operation_name }) => {
      return acc.includes(operation_name) ? acc : [...acc, operation_name];
    }, [])
    .sort(sortCaseInsensitive);

  const columns = getColumns({
    projectId,
    translations,
    fieldNameSearchText,
    setFieldNameSearchText,
    setFilteredOperationNames,
    filteredOperationNames,
    allOperationNames,
  });

  const { effectiveColumns } = useDataTableColumns<CarbonProjectFieldRowData>({
    key: STORE_COLUMNS_KEY,
    columns,
  });

  // Sort records
  useEffect(() => {
    const sortedData = fields.sort(
      sortArrOfObjects<CarbonProjectFieldRowData>(
        sortStatus.columnAccessor as keyof CarbonProjectFieldRowData,
      ),
    );

    if (sortStatus.direction === 'desc') {
      sortedData.reverse();
    }

    setSortedRecords([...sortedData]);
  }, [fields, sortStatus]);

  // Filter records
  useEffect(() => {
    const updatedRecords = fields.filter(({ name, operation_name }) => {
      if (
        fieldNameSearchText !== '' &&
        !name.toLowerCase().includes(fieldNameSearchText.trim().toLowerCase())
      ) {
        return false;
      }

      return (
        !filteredOperationNames.length || filteredOperationNames.some((d) => d === operation_name)
      );
    });

    setSortedRecords(updatedRecords);
  }, [fieldNameSearchText, filteredOperationNames]);

  return (
    <DataTable
      columns={effectiveColumns}
      highlightOnHover
      records={sortedRecords}
      selectedRecords={enableSelection ? selectedFields : undefined}
      sortStatus={sortStatus}
      storeColumnsKey={STORE_COLUMNS_KEY}
      onSelectedRecordsChange={enableSelection ? setSelectedFields : undefined}
      onSortStatusChange={setSortStatus}
    />
  );
};

export default ProjectFieldsTable;
