import { THREE_SIXTY_PRO_SKU_ID, TRUBIO_SKU_ID, TRUNUTRIENT_SKU_ID } from './pricing';

export const SKU_ABBREVIATIONS = {
  [THREE_SIXTY_PRO_SKU_ID]: '360 Pro',
  [TRUBIO_SKU_ID]: 'TruBio',
  [TRUNUTRIENT_SKU_ID]: 'TruNutrient',
};

export const PERCENT_ABBREVIATIONS = {
  '1/4': 0.25,
  '1/3': 0.33,
  '1/2': 0.5,
};
