import React, { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';
import { PlateSearchBar } from 'common';
import { TypeaheadPropsType } from 'common/Components/Mantine/Typeahead';

type QuadrantBarcodeInputPropsType = Omit<TypeaheadPropsType, 'onSelect' | 'data'> & {
  plate: Partial<PlateType>;
  quadrant?: number;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const QuadrantBarcodeInput = ({
  plate,
  quadrant = 1,
  onValueChange,
  ...props
}: QuadrantBarcodeInputPropsType) => {
  const language = useBroswerLanguage();
  const location = useLocation();

  const quadrantKey = `quadrant${quadrant}_plate_barcode` as keyof PlateType;
  const plateSourceBarcode = plate[quadrantKey] as string | undefined;

  const handleBarcodeChange = (barcode: string) => {
    onValueChange(quadrantKey, barcode);
  };

  useEffect(() => {
    // Only populate if no barcode has been entered yet
    if (plateSourceBarcode === undefined && quadrant === 1) {
      const copyPlateBarcode = location.state && location.state.sourcePlateBarcode;
      onValueChange(quadrantKey, copyPlateBarcode);
    }
  }, [plateSourceBarcode, location]);

  const labelString = `${getString(
    'quadrant',
    language,
  )} ${quadrant} ${getString('plateBarcode', language)} *`;
  return (
    <PlateSearchBar
      label={labelString}
      onSelect={handleBarcodeChange}
      onDeselect={() => handleBarcodeChange('')}
      placeholder={getString('scanBarcodeMsg', language)}
      value={plateSourceBarcode || ''}
      leftSection={<FiSearch />}
      all
      {...props}
    />
  );
};
