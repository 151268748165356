import React, { useEffect } from 'react';
import { FiRotateCcw, FiSave, FiX } from 'react-icons/fi';
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Modal,
  Space,
  Table,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { UseMutateFunction } from '@tanstack/react-query';

import { LIGHT_GREY_BG } from 'constants/colors';

import showToast from 'actions/toastActions';
import { SkuType } from 'store/pricing/types';

interface Props {
  sku: SkuType;
  mutateSku: UseMutateFunction<
    any,
    Error,
    Partial<SkuType> & { isDelete?: boolean | undefined },
    unknown
  >;
}

const SkuRow = ({ sku, mutateSku }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const skuForm = useForm<Partial<SkuType>>({
    mode: 'uncontrolled',
    initialValues: sku,
    validate: {
      name: (value) => (!value ? 'Name is required' : null),
      name_pt: (value) => (!value ? 'Name (pt) is required' : null),
      netsuite_name: (value) => (!value ? 'Netsuite name is required' : null),
    },
  });

  useEffect(() => {
    if (sku) {
      skuForm.setInitialValues({
        ...sku,
      });
      skuForm.reset();
    }
  }, [sku]);

  const updateSku = (isDelete?: boolean) => {
    if (skuForm.validate()?.hasErrors) {
      return showToast('Sku values are invalid.', 'error');
    }
    mutateSku({
      ...sku,
      ...skuForm.getValues(),
      isDelete,
    });
    close();
  };

  const isProductInSku = (product: boolean) => (product ? 'X' : '');

  return (
    <Table.Tr key={sku.id} bg={LIGHT_GREY_BG}>
      <Modal opened={opened} onClose={close} title="Deactivate SKU?" centered>
        <Text>This will deactivate the SKU and prices connected to it.</Text>
        <Space h="lg" />
        <Group justify="flex-end">
          <Button color="darkRed" onClick={() => updateSku(true)}>
            Deactivate
          </Button>
        </Group>
      </Modal>
      <Table.Td>
        <TextInput
          key={skuForm.key('name')}
          {...skuForm.getInputProps('name', { type: 'input' })}
        />
      </Table.Td>
      <Table.Td>
        <TextInput
          key={skuForm.key('name_pt')}
          {...skuForm.getInputProps('name_pt', { type: 'input' })}
        />
      </Table.Td>
      <Table.Td>
        <TextInput
          key={skuForm.key('netsuite_name')}
          {...skuForm.getInputProps('netsuite_name', { type: 'input' })}
        />
      </Table.Td>
      <Table.Td>{sku.type}</Table.Td>
      <Table.Td>{isProductInSku(sku.is_pro)}</Table.Td>
      <Table.Td>{isProductInSku(sku.is_till_rx)}</Table.Td>
      <Table.Td>{isProductInSku(sku.pressure_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.performance_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.nutrient_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.nitrate_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.is_590_analysis)}</Table.Td>
      <Table.Td>{sku.analysis_sku_name}</Table.Td>
      <Table.Td>
        <Flex gap="sm">
          <Tooltip label="Save" bg="blue">
            <ActionIcon
              onClick={() => updateSku()}
              disabled={!skuForm.isDirty() || !skuForm.isValid()}
            >
              <FiSave />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Clear changes" bg="blue">
            <ActionIcon
              variant="outline"
              onClick={() => skuForm.reset()}
              disabled={!skuForm.isDirty()}
            >
              <FiRotateCcw />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Table.Td>
      <Table.Td>
        <Tooltip label="Deactivate SKU" bg="blue">
          <ActionIcon variant="outline" color="darkRed" onClick={open}>
            <FiX />
          </ActionIcon>
        </Tooltip>
      </Table.Td>
    </Table.Tr>
  );
};

export default SkuRow;
