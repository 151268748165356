import { COMPANY_NAME } from 'constants/agency';

import acToHectares from 'util/units';
import { User } from 'store/user/types';

import { Agency } from '../agencies/types';

import {
  AcreageCountType,
  OPERATION_REQUEST_ERROR,
  OperationActionsType,
  OperationTotalStats,
  OperationType,
  RECEIVE_CONNECTED_ACCOUNTS,
  RECEIVE_OPERATION_USERS,
  RECEIVE_OPERATIONS,
  RECEIVE_RESULT_PREFERENCES,
  RECEIVE_RESULTS_LINK,
  RECEIVE_SINGLE_FIELD,
  RECEIVE_SINGLE_OPERATION,
  RECEIVE_TOTAL_STATS,
  REQUEST_OPERATIONS,
  SET_ACTIVE_OPERATION,
} from './types';

export type OperationStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  activeOperationId: number | null;
  operationsById: {
    [key: number]: OperationType;
  };
  summary: {
    items: OperationType[];
    agencies: Agency[];
    page: number;
    total: number;
    totalStats: OperationTotalStats;
  };
};

export const emptySamplingStats = {
  created: 0,
  ready: 0,
  unassigned: 0,
  sampled: 0,
  received: 0,
  partial: 0,
  completed: 0,
  cancelled: 0,
  'waiting to sample': 0,
  operation_total_acres: 0,
};

export const initialState: OperationStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  activeOperationId: null,
  operationsById: {},
  summary: {
    items: [],
    agencies: [],
    page: 1,
    total: 1,
    totalStats: {
      operations: 0,
      fields: 0,
      locked_in_acres: 0,
      sampling_status: emptySamplingStats,
      acreage_count: emptySamplingStats,
    },
  },
};

const convertAcreage = (acreageCount: AcreageCountType, acreageUnit: string) => {
  return Object.keys(acreageCount).reduce((all, key) => {
    return {
      ...all,
      [key]: Math.round(acToHectares(acreageCount[key], acreageUnit)),
    };
  }, {}) as AcreageCountType;
};

const convertOperationAcreages = (operations: OperationType[], acreageUnit: string) =>
  operations.map((operation) => ({
    ...operation,
    acreage_count: convertAcreage(operation.acreage_count, acreageUnit),
  }));

export const operationReducer = (
  state = initialState,
  action: OperationActionsType,
): OperationStateType => {
  switch (action.type) {
    case REQUEST_OPERATIONS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
      };
    case RECEIVE_OPERATIONS:
      return {
        ...state,
        activeOperationId: state.activeOperationId || action.payload.items[0]?.id || null,
        hasFetched: true,
        isFetching: false,
        summary: {
          ...state.summary,
          items: convertOperationAcreages(action.payload.items, action.acreageUnit),
          agencies: action.payload.agencies,
          page: action.payload.page,
          total: action.payload.per_page
            ? Math.ceil(action.payload.total / action.payload.per_page)
            : 1,
        },
      };
    case RECEIVE_SINGLE_FIELD:
      return {
        ...state,
        isFetching: false,
      };
    case RECEIVE_SINGLE_OPERATION:
      return {
        ...state,
        activeOperationId: state.activeOperationId || action.payload.id || null,
        hasFetched: true,
        isFetching: false,
        operationsById: {
          ...state.operationsById,
          [action.payload.id]: {
            ...state.operationsById[action.payload.id],
            ...action.payload,
          },
        },
      };
    case RECEIVE_TOTAL_STATS:
      return {
        ...state,
        summary: {
          ...state.summary,
          totalStats: {
            ...action.payload,
            acreage_count: convertAcreage(action.payload.acreage_count, action.acreageUnit),
          },
        },
      };
    case RECEIVE_RESULTS_LINK:
      return {
        ...state,
        operationsById: {
          ...state.operationsById,
          [action.payload.id]: {
            ...state.operationsById[action.payload.id],
            result_links: action.payload.links,
          },
        },
      };
    case RECEIVE_CONNECTED_ACCOUNTS:
      return {
        ...state,
        operationsById: {
          ...state.operationsById,
          [action.payload.id]: {
            ...state.operationsById[action.payload.id],
            connectedAccounts: action.payload.connectedAccounts,
          },
        },
      };
    case RECEIVE_RESULT_PREFERENCES:
      return {
        ...state,
        operationsById: {
          ...state.operationsById,
          [action.payload.id]: {
            ...state.operationsById[action.payload.id],
            result_preferences: action.payload.preferences,
          },
        },
      };
    case RECEIVE_OPERATION_USERS:
      return {
        ...state,
        operationsById: {
          ...state.operationsById,
          [action.payload.id]: {
            ...state.operationsById[action.payload.id],
            operationUsers: [
              { first_name: COMPANY_NAME, last_name: '', id: 0 } as User,
              ...action.payload.operationUsers,
            ],
          },
        },
      };
    case SET_ACTIVE_OPERATION:
      return {
        ...state,
        activeOperationId: action.payload,
      };
    case OPERATION_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
