import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Set document title. Extremely useful for routing, history, and Google Analytics.
 *
 * @param pageTitle text to use for <title>
 */
export const usePageTitle = (pageTitle?: string): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Empty string is an easy way to disable, e.g. child doesn't know title
    if (pageTitle) {
      document.title = pageTitle;
    }

    return () => {
      document.title = 'EarthOptics';
    };
  }, [pageTitle, pathname]);
};
