import {
  CircleLayer,
  FillLayer,
  FillPaint,
  GeoJSONSourceRaw,
  SymbolLayer,
  SymbolLayout,
  SymbolPaint,
} from 'mapbox-gl';

import { RISK_FILL_COLORS } from 'constants/colors';

import { FieldPropertiesType } from 'store/fields/types';

type LayerIshType = {
  id: string;
  type: string;
  source: any;
  paint: any;
  filter: any[];
};

export const FIELD_BOUNDARIES_SOURCE_ID = 'field-boundaries-source';
export const CLUSTERS_SOURCE_ID = 'field-points-cluster-source';
export const CLUS_SOURCE_ID = 'clus-source';
export const MARKER_PIN_ICON_ID = 'marker-pin';

export const FIELD_SELECTED_FEATURE_STATE_KEY = 'selected';
export const FIELD_SOURCE_PROMOTE_ID_KEY: keyof FieldPropertiesType = 'id';

const LABEL_TEXT_COLOR = 'hsl(0, 0%, 100%)';

export const FIELD_BOUNDARIES_MAP_COLORS = {
  allFieldsFill: 'hsl(0, 0%, 80%)',
  cluLineColor: RISK_FILL_COLORS.MODERATE_RISK,
  clusterAndIcon: 'hsl(26, 86.46%, 62.35%)', // TODO: tighten up with EO palette
  clusterOutline: LABEL_TEXT_COLOR,
  fieldBeingEditedFill: 'hsl(60, 100%, 50%)',
  labelText: LABEL_TEXT_COLOR,
  splitMergeFill: 'hsl(39, 100%, 50%)',
  textHalo: 'hsl(0, 0%, 0%)',
};

const colors = FIELD_BOUNDARIES_MAP_COLORS;

export const CLU_LATITUDE_BUFFER = 1.6;
export const CLU_LONGITUTE_BUFFER = 1;
export const CLU_ZOOM_THRESHOLD = 11.5;
export const CLU_ZOOM_BUFFER = 0.9;

export const FIELDS_LAYER_IDS = {
  cluFillSelected: 'clus-fill-selected',
  cluFillUnselected: 'clus-fill-unselected',
  cluLineUnselected: 'clus-line-unselected',
  clusters: 'clusters',
  fieldBoundaries: 'field-boundaries',
  fieldLabelsAndPins: 'field-labels',
  fieldLabelsClose: 'field-labels-close',
  splitMergePrefix: 'split-merge-',
};

export const FIELD_POPUP_LAYER_IDS = [
  FIELDS_LAYER_IDS.fieldLabelsClose,
  FIELDS_LAYER_IDS.fieldBoundaries,
  FIELDS_LAYER_IDS.fieldLabelsAndPins,
];

export const EVERY_CLICKABLE_LAYER_ID = [
  ...FIELD_POPUP_LAYER_IDS,
  FIELDS_LAYER_IDS.clusters,
  FIELDS_LAYER_IDS.cluFillUnselected,
];

export const CLU_LAYERS: LayerIshType[] = [
  {
    id: FIELDS_LAYER_IDS.cluLineUnselected,
    type: 'line',
    source: CLUS_SOURCE_ID,
    paint: { 'line-color': colors.cluLineColor },
    filter: ['all'],
  },
  {
    id: FIELDS_LAYER_IDS.cluFillUnselected,
    type: 'fill',
    source: CLUS_SOURCE_ID,
    paint: { 'fill-color': colors.cluLineColor, 'fill-opacity': 0.1 },
    filter: ['all'],
  },
  {
    id: FIELDS_LAYER_IDS.cluFillSelected,
    type: 'fill',
    source: CLUS_SOURCE_ID,
    paint: { 'fill-color': colors.cluLineColor, 'fill-opacity': 1 },
    filter: ['all'],
  },
];

const POINT_COUNT_KEY = 'point_count';
const LABEL_FIELD_KEY: keyof FieldPropertiesType = 'name';

export const NEW_FIELD_ID_ROUTE_VALUE = 'new';

export const FIELD_POINTS_SOURCE_CONFIG: GeoJSONSourceRaw = {
  type: 'geojson',
  cluster: true,
  clusterMaxZoom: 12,
  clusterRadius: 50,
};

export const ALL_FIELD_BOUNDARIES_LAYER_CONFIG: FillLayer = {
  id: FIELDS_LAYER_IDS.fieldBoundaries,
  type: 'fill',
  source: FIELD_BOUNDARIES_SOURCE_ID,
  paint: {
    'fill-opacity': 0.4,
    'fill-color': [
      'case',
      ['boolean', ['feature-state', FIELD_SELECTED_FEATURE_STATE_KEY], false],
      colors.fieldBeingEditedFill,
      colors.allFieldsFill,
    ],
  },
};

export const SPLIT_MERGE_SELECTED_PAINT: FillPaint = {
  'fill-color': colors.splitMergeFill,
  'fill-opacity': 0.6,
};

export const CLUSTERS_LAYER_CONFIG: CircleLayer = {
  id: FIELDS_LAYER_IDS.clusters,
  source: CLUSTERS_SOURCE_ID,
  type: 'circle',
  filter: ['has', POINT_COUNT_KEY],
  paint: {
    'circle-color': colors.clusterAndIcon,
    'circle-radius': ['step', ['get', POINT_COUNT_KEY], 20, 100, 30, 750, 40],
    'circle-stroke-color': colors.clusterOutline,
    'circle-stroke-width': 1,
  },
};

export const CLUSTER_COUNT_LAYER_CONFIG: SymbolLayer = {
  id: 'cluster-count',
  source: CLUSTERS_SOURCE_ID,
  type: 'symbol',
  filter: ['has', POINT_COUNT_KEY],
  paint: {
    'text-color': colors.labelText,
  },
  layout: {
    'text-field': ['get', 'point_count_abbreviated'],
    'text-font': ['Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

const FIELD_POINT_PINS_MAX_ZOOM = 13;

const fieldLabelsPaint: SymbolPaint = {
  'text-color': colors.labelText,
  'text-halo-color': colors.textHalo,
  'text-halo-width': 1,
};

const fieldLabelsLayoutCommon: SymbolLayout = {
  'text-field': ['get', LABEL_FIELD_KEY],
  'text-size': 14,
};

/** Field points' map pins + offset labels */
export const FIELD_LABELS_AND_PINS_LAYER_CONFIG: SymbolLayer = {
  id: FIELDS_LAYER_IDS.fieldLabelsAndPins,
  type: 'symbol',
  source: CLUSTERS_SOURCE_ID,
  filter: ['!', ['has', POINT_COUNT_KEY]],
  maxzoom: FIELD_POINT_PINS_MAX_ZOOM,
  paint: {
    'icon-color': colors.clusterAndIcon,
    ...fieldLabelsPaint,
  },
  layout: {
    ...fieldLabelsLayoutCommon,
    'icon-allow-overlap': true,
    'icon-image': MARKER_PIN_ICON_ID,
    'icon-offset': [0, -12],
    'icon-size': 1,
    'text-anchor': 'bottom-right',
    'text-justify': 'auto',
    'text-offset': [5, 4],
    'text-radial-offset': 1.5,
    'text-variable-anchor': ['bottom-left', 'top-left', 'bottom-right', 'top-right'],
  },
};

/** Shown when field point marker pins are not */
export const CLOSE_FIELD_LABELS_LAYER_CONFIG: SymbolLayer = {
  id: FIELDS_LAYER_IDS.fieldLabelsClose,
  type: 'symbol',
  source: CLUSTERS_SOURCE_ID,
  filter: ['!', ['has', POINT_COUNT_KEY]],
  paint: fieldLabelsPaint,
  minzoom: FIELD_POINT_PINS_MAX_ZOOM,
  layout: {
    ...fieldLabelsLayoutCommon,
    'text-variable-anchor': ['center'],
  },
};

export type SplitFieldCreateFate = 'view-new-field' | 'split-parent-again';

export const SPLIT_MODAL_FORM_KEYS = {
  name: 'name',
  fateAfterSplit: 'fate-after-split',
};
