import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Modal, Stack, Text, Title } from '@mantine/core';

import { CROP_PLANNING_OPTIMIZE } from 'constants/firebase';

import { getLatestCropPlan } from 'util/cropPlans';
import useFirebase from 'util/hooks/useFirebase';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { optimizeCropPlans } from 'store/fields/thunks';
import { FieldType } from 'store/fields/types';

type OptimizePlansModalPropsType = {
  onClose: () => void;
  opened: boolean;
  operationId: number;
  fields: FieldType[];
};

export const OptimizePlansModal = ({
  onClose,
  opened,
  operationId,
  fields,
}: OptimizePlansModalPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const handleSubmit = async () => {
    try {
      const cropPlanIds = fields
        .filter((field) => field.features[0].properties.crop_plans.length)
        .map((field) => getLatestCropPlan(field).id);
      await dispatch(
        optimizeCropPlans(
          cropPlanIds,
          getString('optimizedPlansSuccessMsg', language),
          getString('optimizedPlansErrorMsg', language),
        ),
      );
      firebase.logEvent(CROP_PLANNING_OPTIMIZE, { operation_id: operationId });
    } catch (e) {
      showToast(getString('serverErrorMsg', language), 'error');
    }
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} centered size="xl">
      <Stack align="center" gap="lg" pb="md">
        <Title order={2} fw="bold">
          {getString('optimizePlans', language)}
        </Title>
        <Text>{getString('overrideInputsMsg', language)}</Text>
        <Group pt="lg">
          <Button onClick={onClose} color="red.8">
            {getString('cancel', language)}
          </Button>
          <Button onClick={handleSubmit}>{getString('save', language)}</Button>
        </Group>
      </Stack>
    </Modal>
  );
};
