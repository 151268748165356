import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Text } from '@mantine/core';

import { ACTIVE_HEATMAP_AGENCY_ID } from 'constants/auth';
import { US_STATE_NAMES_DICT } from 'constants/states';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { removeLocalStorageItem } from 'util/localStorage';
import { capitalize } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getAllAgencies, getMyAgencies } from 'store/agencies/thunks';
import { Agency } from 'store/agencies/types';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { AdminAccess, DropdownArrowIcon } from 'common';
import { Typeahead } from 'common/Components/Mantine/Typeahead';

type MyAgenciesSelectorPropsType = {
  setView: (agencyId: number | null | string) => void;
  agency?: Agency;
  stateName?: string;
  agencyNotSelected: string | undefined;
  isSelecting: boolean;
  toggleIsSelecting: (value: boolean) => void;
};

export const MyAgenciesSelector = ({
  setView,
  agency,
  stateName,
  agencyNotSelected,
  isSelecting,
  toggleIsSelecting,
}: MyAgenciesSelectorPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string>('');
  const [stateSelected, setStateSelected] = useState<string>('');

  const { allAgencies, myAgencies, isAdmin } = useSelector((state: RootState) => ({
    allAgencies: state.agencies.allAgencies,
    myAgencies: state.agencies.myAgencies,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  const agencies = isAdmin ? allAgencies : myAgencies;

  useEffect(() => {
    if (isAdmin) {
      dispatch(getAllAgencies());
    }
    dispatch(getMyAgencies());
  }, [isAdmin]);

  useEffect(() => {
    if (agency || stateName || agencyNotSelected) {
      toggleIsSelecting(false);
    }
  }, [agency, stateName, agencyNotSelected]);

  useEffect(() => {
    if (agency) {
      setSelected(agency.name);
      setStateSelected('');
    }
  }, [agency]);

  useEffect(() => {
    if (stateName) {
      setSelected('');
      setStateSelected(stateName);
    }
  }, [stateName]);

  const agencyOptions = agencies
    .filter((a) => a.name)
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((a) => ({
      id: a.id,
      label: a.name,
      value: a.id,
    }));

  const handleTextChangeAgency = (value: string) => {
    setSelected(value);
  };

  const handleSelectAgency = (value: number) => {
    toggleIsSelecting(false);
    setView(value);
  };

  const handleDeselectAgency = () => {
    setSelected('');
    removeLocalStorageItem(ACTIVE_HEATMAP_AGENCY_ID);
  };

  const handleSelectMyAccounts = (view: string) => {
    removeLocalStorageItem(ACTIVE_HEATMAP_AGENCY_ID);
    toggleIsSelecting(false);
    setView(view);
  };

  const stateOptions = Object.entries(US_STATE_NAMES_DICT)
    .sort((a, b) => (a[1] > b[1] ? 1 : -1))
    .map((entry, index) => ({
      id: index,
      label: entry[1],
      value: entry[1],
    }));

  const handleTextChangeState = (value: string) => {
    setStateSelected(value);
  };

  const handleSelectState = (value: string) => {
    toggleIsSelecting(false);
    setView(value.toLowerCase());
  };

  const handleDeselectState = () => {
    setStateSelected('');
  };

  const getViewName = () => {
    if (agencyNotSelected) {
      return getString(agencyNotSelected, language);
    }
    if (stateName) {
      return capitalize(stateName);
    }
    if (agency !== undefined) {
      return agency?.name;
    }
  };

  return !isSelecting && (agency !== undefined || stateName !== undefined || agencyNotSelected) ? (
    <Group onClick={() => toggleIsSelecting(true)} style={{ cursor: 'pointer' }} gap="xs">
      <Text>{getViewName()}</Text>
      <DropdownArrowIcon />
    </Group>
  ) : (
    <Group gap="xs">
      <Typeahead
        onTextChange={handleTextChangeAgency}
        onSelect={handleSelectAgency}
        onDeselect={handleDeselectAgency}
        value={selected}
        data={agencyOptions}
        placeholder={getString('selectAgencyMsg', language)}
        leftSection={<FiSearch size="1rem" />}
        w="15rem"
      />
      <Text>{getString('or', language)}</Text>
      <AdminAccess>
        <Typeahead
          onTextChange={handleTextChangeState}
          onSelect={handleSelectState}
          onDeselect={handleDeselectState}
          value={stateSelected}
          data={stateOptions}
          placeholder={getString('state', language)}
          w="8rem"
        />
        <Text>{'|'}</Text>
      </AdminAccess>
      <Button variant="outline" onClick={() => handleSelectMyAccounts('my-accounts')}>
        {getString('myAccounts', language)}
      </Button>
      <Text>{'|'}</Text>
      <Button variant="outline" onClick={() => handleSelectMyAccounts('demo')}>
        {getString('viewDemo', language)}
      </Button>
    </Group>
  );
};
