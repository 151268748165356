import React, { useEffect } from 'react';
import { PillsInputProps } from '@mantine/core';

import { PLATE_LANES } from 'constants/analysis';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BatchFormType } from 'store/batches/types';
import { MultiSelect } from 'common/Components/Mantine/MultiSelect';

type LaneSelectorPropsType = Omit<PillsInputProps, 'onChange'> & {
  batch: BatchFormType;
  onValueChange: (
    attributeKey: keyof BatchFormType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
  index: number;
  defaultLane?: number;
  showLabel?: boolean;
};

export const LaneSelector = ({
  batch,
  onValueChange,
  index,
  defaultLane = 1,
  showLabel,
  ...props
}: LaneSelectorPropsType) => {
  const language = useBroswerLanguage();
  const barcode = batch.plates[index]?.barcode?.toString();
  const lanes = batch.plates[index]?.lanes || [];

  const laneOptions = PLATE_LANES.map((laneNum) => ({
    id: laneNum,
    label: String(laneNum),
    value: laneNum,
  }));

  const handleOnChange = (laneNums: number[]) => {
    if (laneNums && batch.plates[index]) {
      const allEmpty = batch.plates.every((p) => !p.lanes?.length);
      const existingPlates = batch.plates.map((p, pindex) =>
        (p && allEmpty) || pindex === index ? { ...p, lanes: laneNums } : p,
      );
      onValueChange('plates', existingPlates);
    }
  };

  useEffect(() => {
    if (!lanes && batch.plates[index]) {
      handleOnChange([defaultLane]);
    }
  }, [batch, index, defaultLane]);

  return (
    <MultiSelect
      onChange={handleOnChange}
      data={laneOptions}
      value={lanes}
      disabled={!barcode}
      label={showLabel && getString('lane', language)}
      {...props}
    />
  );
};
