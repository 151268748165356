import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bool, func } from 'prop-types';

import { userType } from 'constants/propTypes/user';

import { userIsAgencyAdmin } from 'util/userRoles';
import { matchingUser, userHasFetched, userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { getUserSet, submitUserDetails } from 'store/user/thunks';
import { Spinner } from 'common';

import ButtonFooter from './ButtonFooter';

import styles from './Container.module.css';

const EditUserContainer = ({ editUser, fetchUsers, hasFetched, isAdmin, isSuperAdmin, user }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
    }
  }, [user]);

  useEffect(() => {
    if (hasFetched && (!isAdmin || !isSuperAdmin)) {
      navigate('/');
    }
  }, [hasFetched, isAdmin, isSuperAdmin, navigate]);

  const hasFormChanged = () => firstName !== user.first_name || lastName !== user.last_name;

  const resetForm = () => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
  };

  const submit = () =>
    editUser({ first_name: firstName, last_name: lastName }, user.id, false).then(fetchUsers);

  if (!user) {
    return <Spinner fill />;
  }

  return (
    <div className={styles.Wrapper}>
      Edit User
      <hr className={styles.Divider} />
      <div className={styles.FormWrapper}>
        First name:
        <input onChange={(e) => setFirstName(e.target.value)} type="text" value={firstName} />
        Last name:
        <input onChange={(e) => setLastName(e.target.value)} type="text" value={lastName} />
        Email:
        <input className={styles.EmailInput} disabled type="email" value={user.email} />
        <ButtonFooter hasFormChanged={hasFormChanged()} reset={resetForm} submit={submit} />
      </div>
    </div>
  );
};

EditUserContainer.propTypes = {
  editUser: func.isRequired,
  fetchUsers: func.isRequired,
  hasFetched: bool.isRequired,
  isAdmin: bool.isRequired,
  isSuperAdmin: bool.isRequired,
  user: userType,
};

EditUserContainer.defaultProps = {
  user: null,
};

const mapStateToProps = (state, props) => {
  const userSelector = matchingUser(props.match.params.id);
  return {
    hasFetched: userHasFetched(state),
    isAdmin: userIsAdmin(state),
    isAgencyAdmin: userIsAgencyAdmin,
    isSuperAdmin: userIsSuperAdmin(state),
    user: userSelector(state, props),
  };
};
const mapDispatchToProps = {
  editUser: submitUserDetails,
  fetchUsers: getUserSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserContainer);
