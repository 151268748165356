import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Input, Modal, Select, SimpleGrid, Space, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import sortByName from 'util/sortByName';
import { getRoleListDisplayName, userIsAgencyAdmin, userIsAgent } from 'util/userRoles';
import { cleanPhoneNumber, createAgentFormValidate } from 'util/validators';
import { getString } from 'strings/translation';
import showToast, { ToastType } from 'actions/toastActions';
import { registerUserInAgency } from 'store/agencies/requests';
import { getAgencyAssociatedUsers } from 'store/agencies/thunks';
import { Agency } from 'store/agencies/types';
import { selectRoles } from 'store/roles/actions';
import { RegisterAgentForm, UserRole } from 'store/user/types';
import { Spinner } from 'common';

import styles from './ManageUsers.module.css';

interface Props {
  open: boolean;
  hideModal: () => void;
  agency: Agency;
}

const CreateAgentModal = ({ hideModal, agency, open }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const storeRoles = useSelector(selectRoles);
  const roles = sortByName(
    getRoleListDisplayName(
      (storeRoles || []).filter((rol) => userIsAgent([rol]) || userIsAgencyAdmin([rol])),
    ),
  );

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cell, setCell] = useState('');
  const [role, setRole] = useState(roles.find((rol) => rol.value === UserRole.Agent));
  const [submitting, setSubmitting] = useState(false);

  const setToastMessage = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  const submitUser = async (body: RegisterAgentForm) => {
    try {
      if (agency.id) {
        const newUser: RegisterAgentForm = {
          ...body,
          agency_id: agency.id,
        };
        await registerUserInAgency(newUser);
        await dispatch(getAgencyAssociatedUsers(agency.id));
        setToastMessage(getString('userCreatedMsg', language));
        hideModal();
      }
    } catch (error) {
      setToastMessage(getString('emailAlreadyExistsMsg', language));
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);
    const data: RegisterAgentForm = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      role_id: role?.id,
      cell: cleanPhoneNumber(cell.trim()),
      email: email.trim().toLowerCase(),
    };

    const error = createAgentFormValidate(data, language);

    if (error) {
      setToastMessage(error, 'error');
      setSubmitting(false);
      return;
    }

    if (!data.cell) {
      delete data.cell;
    }

    try {
      await submitUser(data);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setToastMessage(getString('thatEmailHasAcctMsg', language));
    }
  };

  const updateRole = (role_name: string | null) => {
    const control = roles.find((rol) => rol.value === role_name);
    if (control?.id) {
      setRole(control);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        opened={open}
        onClose={hideModal}
        title={getString('createNewAgent', language)}
        centered
      >
        <Text>{getString('inviteAgentCreateAcctMsg', language)}:</Text>
        <Space h="xl" />
        <SimpleGrid cols={3}>
          <Input.Wrapper label={`${getString('email', language)}*`}>
            <Input
              data-test-id="agent-email-input"
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
          </Input.Wrapper>
          <Select
            data-test-id="agent-role-select"
            label={`${getString('role', language)}*`}
            data={roles || []}
            value={role?.value}
            onChange={updateRole}
          />
          <Input.Wrapper label={`${getString('firstName', language)}*`}>
            <Input
              data-test-id="agent-first-name-input"
              onChange={(event) => setFirstName(event.currentTarget.value)}
            />
          </Input.Wrapper>
          <Input.Wrapper label={`${getString('lastName', language)}*`}>
            <Input
              data-test-id="agent-last-name-input"
              onChange={(event) => setLastName(event.currentTarget.value)}
            />
          </Input.Wrapper>
          <Input.Wrapper label={getString('phone', language)}>
            <Input
              data-test-id="agent-cell-input"
              onChange={(event) => setCell(event.currentTarget.value)}
            />
          </Input.Wrapper>
        </SimpleGrid>
        <Space h="xl" />
        <Group justify="flex-end">
          {submitting && <Spinner className={styles.SmallSpinner} />}
          <Button disabled={submitting} onClick={onSubmit} data-test-id="modal-create-new-agent">
            {getString('createNewAgent', language)}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default CreateAgentModal;
