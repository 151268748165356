import React from 'react';

import {
  AA_ANALYSIS,
  AMPLICON_QPCR,
  CHEMISTRY_BACKUP,
  DNA_EXTRACTION,
  HOMOGENIZATION,
  M3_ANALYSIS,
  NITRATE_ANALYSIS,
  NORMALIZED_DNA,
  OM_ANALYSIS,
  PH_ANALYSIS,
  PHOSPHATE_ANALYSIS,
  QPCR,
  SHOTGUN,
  ST_ANALYSIS,
  TC_ANALYSIS,
  TIC_ANALYSIS,
} from 'constants/plates';

import { PlateType } from 'store/plates/types';

import { AmpliconLayout } from './PlateTypes/AmpliconQPCR';
import { ChemistryAnalysisLayout } from './PlateTypes/ChemistryAnalysis';
import { DNAExtractionLayout } from './PlateTypes/DNAExtraction';
import { HomogenizationLayout } from './PlateTypes/Homogenization';
import { NormalizedDNALayout } from './PlateTypes/NormalizedDNA';
import { QPCRLayout } from './PlateTypes/QPCR';
import { ShotgunLayout } from './PlateTypes/Shotgun';

type PlateLayoutContainerPropsType = {
  plate: PlateType;
};

export const PlateLayoutContainer = ({ plate }: PlateLayoutContainerPropsType) => {
  const getPlateLayout = () => {
    switch (plate.plate_type) {
      case HOMOGENIZATION:
        return <HomogenizationLayout plate={plate} />;
      case DNA_EXTRACTION:
        return <DNAExtractionLayout plate={plate} />;
      case NORMALIZED_DNA:
        return <NormalizedDNALayout plate={plate} />;
      case SHOTGUN:
        return <ShotgunLayout plate={plate} />;
      case QPCR:
        return <QPCRLayout plate={plate} />;
      case M3_ANALYSIS:
      case PH_ANALYSIS:
      case NITRATE_ANALYSIS:
      case AA_ANALYSIS:
      case OM_ANALYSIS:
      case PHOSPHATE_ANALYSIS:
      case ST_ANALYSIS:
      case TC_ANALYSIS:
      case TIC_ANALYSIS:
      case CHEMISTRY_BACKUP:
        return <ChemistryAnalysisLayout plate={plate} />;
      case AMPLICON_QPCR:
        return <AmpliconLayout plate={plate} />;
      default:
        return <></>;
    }
  };

  return getPlateLayout();
};
