import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NUTRIENT, SHOTGUN, SHOTGUN_QPCR } from 'constants/analysis';
import { HOMOGENIZATION, homogenStatusList, PROCESSING } from 'constants/plates';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPlateAnalysisOptions, getPlateStatusOptions, getPlateTypeOptions } from 'util/plates';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getPlates } from 'store/plates/thunks';
import {
  FilterDropdown,
  Pagination,
  PlateSearchBar,
  Spinner,
  Table,
  TableCol,
  TableRow,
} from 'common';

import { PlatesRow } from './PlateRow';

import styles from './Container.module.css';

type PlatesOverviewPropsType = {
  plateBarcode?: string;
  plateTypes?: string[];
  analysisTypes?: string[];
  plateStatuses?: string[];
  hideTypeColumn?: boolean;
  analysis?: string;
};

export const PlatesOverview = ({
  plateBarcode = '',
  plateTypes,
  analysisTypes,
  plateStatuses,
  hideTypeColumn,
  analysis,
}: PlatesOverviewPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const defaultType = plateTypes?.includes(HOMOGENIZATION) ? [HOMOGENIZATION] : [];
  const [filter, setFilter] = useState<{
    page: number;
    search: string;
    status: string[];
    analysis: string[];
    type: string[];
    labId: string[];
  }>({
    page: 1,
    search: plateBarcode,
    type: defaultType,
    analysis: [],
    status: [PROCESSING],
    labId: [],
  });
  const { plates, totalPages, isFetching, hasFetched } = useSelector((state: RootState) => ({
    plates: state.plates.plates.items,
    totalPages: state.plates.plates.total,
    isFetching: state.plates.isFetching,
    hasFetched: state.plates.hasFetched,
  }));

  useEffect(() => {
    dispatch(
      getPlates(
        filter.page,
        filter.search,
        !filter.type.length && plateTypes ? plateTypes : filter.type,
        filter.analysis.includes(SHOTGUN)
          ? filter.analysis.concat([SHOTGUN_QPCR])
          : filter.analysis,
        filter.status.length === 1 && filter.status.includes(PROCESSING)
          ? filter.status.concat(homogenStatusList)
          : filter.status,
        filter.labId,
      ),
    );
  }, [dispatch, filter, plateTypes]);

  useEffect(() => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      page: 1,
      search: plateBarcode,
      type: plateBarcode ? [] : defaultType,
      analysis: [],
      status: plateBarcode ? [] : [PROCESSING],
      labID: [],
    }));
  }, [plateBarcode, plateTypes]);

  const labelConfig =
    analysis === NUTRIENT
      ? {
          type: 'pipeline',
          analysis: 'extractionMethod',
          allTypes: 'allPipelines',
          allAnalyses: 'allExtractions',
        }
      : {
          type: 'type',
          analysis: 'analysis',
          allTypes: 'allTypes',
          allAnalyses: 'allAnalyses',
        };

  const plateAnalysisOptions = [
    {
      id: 0,
      displayName: getString(labelConfig.allAnalyses, language),
      value: null,
    },
    ...getPlateAnalysisOptions(
      language,
      analysisTypes?.filter((a) => a !== SHOTGUN_QPCR),
    ),
  ];
  const plateTypeOptions = [
    {
      id: 0,
      displayName: getString(labelConfig.allTypes, language),
      label: getString(labelConfig.allTypes, language),
      value: null,
    },
    ...getPlateTypeOptions(language, plateTypes),
  ];
  const plateStatusOptions = [
    {
      id: 0,
      displayName: getString('allStatuses', language),
      value: null,
    },
    ...getPlateStatusOptions(language, plateStatuses),
  ];

  const changeFilterValue = (value: any, key: string) => {
    setFilter({
      ...filter,
      page: 1,
      [key]: value,
    });
  };

  const changeTypeFilter = (values: string[]) => {
    /*
      Remove 'processing' from filter if a more specific status is selected to view.
      Do this when solely viewing homogenization plates.
    */
    const homogenOnly = filter.type.length === 1 && filter.type.includes(HOMOGENIZATION);
    const removeProcessing =
      homogenOnly &&
      filter.status.includes(PROCESSING) &&
      values.some((t) => homogenStatusList.includes(t));
    const filteredValues = removeProcessing ? values.filter((t) => t !== PROCESSING) : values;
    changeFilterValue(filteredValues, 'status');
  };

  const setPage = (page: number) => {
    setFilter({ ...filter, page });
  };

  return (
    <div>
      <div className={styles.Filter}>
        <div className={styles.FilterLabel}>{getString('showMe', language)}</div>
        {plateTypes?.includes(HOMOGENIZATION) && (
          <>
            <PlateSearchBar
              placeholder={getString('labId', language)}
              onSelect={(value: string) => {
                const labId = value ? [value] : [];
                changeFilterValue(labId, 'labId');
              }}
              onDeselect={() => changeFilterValue([], 'labId')}
              plateTypes={[HOMOGENIZATION]}
              labIdMode
              forceSelect
            />
            <div className={styles.FilterLabel}>{getString('with', language)}</div>
          </>
        )}
        {!hideTypeColumn && (
          <>
            <FilterDropdown
              filter={filter.type}
              options={plateTypeOptions}
              onChangeFilter={(values: string[]) => changeFilterValue(values, 'type')}
            />
            <div className={styles.FilterLabel}>{getString('with', language)}</div>
          </>
        )}
        <FilterDropdown
          filter={filter.analysis}
          options={plateAnalysisOptions}
          onChangeFilter={(values: string[]) => changeFilterValue(values, 'analysis')}
        />
        <div className={styles.FilterLabel}>{getString('with', language)}</div>
        <FilterDropdown
          filter={filter.status}
          options={plateStatusOptions}
          onChangeFilter={(values: string[]) => changeTypeFilter(values)}
        />
      </div>
      <Table className={styles.Table}>
        <TableRow header className={styles.TableHeader}>
          <TableCol size="x2">{getString('barcode', language)}</TableCol>
          {!hideTypeColumn && (
            <TableCol size="x2">{getString(labelConfig.type, language)}</TableCol>
          )}
          <TableCol size="x2">{getString(labelConfig.analysis, language)}</TableCol>
          <TableCol size="x2">{getString('status', language)}</TableCol>
          <TableCol>{getString('wells', language)}</TableCol>
          <TableCol>{getString('batch', language)}</TableCol>
          <TableCol size="x2">{getString('firstSampleArrived', language)}</TableCol>
          <TableCol size={hideTypeColumn ? 'x4' : 'x2'}>{getString('comments', language)}</TableCol>
          <TableCol size="x2">{getString('createdAt', language)}</TableCol>
        </TableRow>
        {isFetching ? (
          <TableRow className={styles.ExpandingColumn}>
            <TableCol size="x4">
              <Spinner fill />
            </TableCol>
          </TableRow>
        ) : (
          plates.map((plate) => {
            return <PlatesRow plate={plate} hideTypeColumn={hideTypeColumn} key={plate.id} />;
          })
        )}
        {hasFetched && !plates?.length && (
          <TableRow>
            <TableCol size="x4">{getString('noPlatesFoundMsg', language)}</TableCol>
          </TableRow>
        )}
        <TableRow className={styles.PaginationRow}>
          <TableCol size="x4">
            <Pagination page={filter.page} setPage={setPage} totalPages={totalPages} />
          </TableCol>
        </TableRow>
      </Table>
    </div>
  );
};
