import React from 'react';
import { Flex, Text } from '@mantine/core';

import { getCompactionMeta } from 'util/proMaps';
import { SingleAnalyticType } from 'store/analytics/types';
import { FieldType } from 'store/fields/types';

import 'mapbox-gl/dist/mapbox-gl.css';

type Props = {
  field: FieldType;
  language: string;
  planAnalytic?: SingleAnalyticType;
};

export const MapbookCompactionMeta = ({ field, planAnalytic, language }: Props) => {
  if (!planAnalytic) {
    return null;
  }

  const { score, average, compaction, maxDepth, minDepth } = getCompactionMeta(
    language,
    planAnalytic,
    field.features[0].properties,
  );

  return (
    <Flex
      columnGap="md"
      rowGap="xs"
      display="grid"
      style={{
        gridTemplateColumns: 'auto auto 1fr',
        gridTemplateRows: 'auto auto',
      }}
    >
      <Text>
        <b>{score.label}: </b>
        {score.value}
      </Text>
      <Text>
        <b>{average.label}: </b>
        {average.value}
      </Text>
      <Text>
        <b>{compaction.label}: </b>
        {compaction.value}
      </Text>
      <Text>
        <b>{minDepth.label}: </b>
        {minDepth.value}
      </Text>
      <Text>
        <b>{maxDepth.label}: </b>
        {maxDepth.value}
      </Text>
    </Flex>
  );
};
