import { useQuery } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useAllSkusQuery } from 'apps/ZoneAnalysisV3/queries';

import { EXTERNAL_LAB_SKU_ID } from 'constants/pricing';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { externalLabsQueryOptions } from 'util/queryOptions';
import { getAnalysisPackageOptions } from 'util/samplePlanV3';
import { getString } from 'strings/translation';
import { OrderForm } from 'store/pricing/types';
import SelectWithInlineLabel from 'common/Components/SelectWithInlineLabel';

type Props = {
  disabled: boolean;
};

const NUTRIENT_PACKAGE_FORM_KEY: keyof OrderForm = 'nutrientAnalysisPackageId';
const EXTERNAL_LAB_FORM_KEY: keyof OrderForm = 'externalLabKey';

const AnalysisPackageSelect = ({ disabled }: Props) => {
  const language = useBroswerLanguage();
  const form = useOrderFormContext();
  const allSkusQuery = useAllSkusQuery();
  const formValues = form.getValues();

  const externalLabsQuery = useQuery({
    ...externalLabsQueryOptions,
    select: (data) => {
      return Object.entries(data).map(([identifier, values]) => ({ ...values, identifier }));
    },
  });

  const { data: externalLabs = [] } = externalLabsQuery;
  const hasExternalLabs = !!externalLabs.length;
  const analysisPackageInputProps = form.getInputProps(NUTRIENT_PACKAGE_FORM_KEY);
  const labPackageInputProps = form.getInputProps(EXTERNAL_LAB_FORM_KEY);

  const { nutrientAnalysisPackageId: selectedPackageId } = form.getValues();

  const handlePackageChange = (currentPackage: string | null) => {
    if (!currentPackage) {
      return;
    }
    analysisPackageInputProps.onChange(Number(currentPackage));
    form.setValues({
      externalLabKey:
        Number(currentPackage) !== EXTERNAL_LAB_SKU_ID ? '' : formValues.externalLabKey,
    });
  };

  const handleExternalLabChange = (currentLab: string | null) => {
    labPackageInputProps.onChange(currentLab);
    if (currentLab) {
      form.setValues({ externalLabKey: currentLab });
    }
  };

  const externalLabsDisplay = externalLabs.map(({ name, identifier, tests }) => ({
    value: identifier,
    label: `${name}${tests.length ? ` (${tests.join(' + ')})` : ''}`,
  }));

  return (
    <>
      <SelectWithInlineLabel
        labelId="analysis-package-label"
        labelText={getString('labPackage', language)}
        rootFlexProps={{ flex: 1 }}
        selectProps={{
          key: form.key(NUTRIENT_PACKAGE_FORM_KEY),
          flex: 1,
          maw: 275,
          data: getAnalysisPackageOptions(hasExternalLabs, allSkusQuery.data || []),
          disabled,
          ...analysisPackageInputProps,
          onChange: handlePackageChange,
          value: String(form.getValues()[NUTRIENT_PACKAGE_FORM_KEY]),
        }}
      />
      {selectedPackageId === EXTERNAL_LAB_SKU_ID && (
        <SelectWithInlineLabel
          labelId="external-lab-label"
          labelText="External lab"
          rootFlexProps={{ flex: 1 }}
          selectProps={{
            key: form.key(EXTERNAL_LAB_FORM_KEY),
            flex: 1,
            data: externalLabsDisplay,
            ...labPackageInputProps,
            value: String(form.getValues()[EXTERNAL_LAB_FORM_KEY]),
            onChange: handleExternalLabChange,
          }}
        />
      )}
    </>
  );
};

export default AnalysisPackageSelect;
