import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { DEBOUNCE } from 'util/request';
import { getAcreageUnitFromLang } from 'util/units';
import { RootState } from 'store';
import { getOperations } from 'store/operation/thunks';
import { OperationType } from 'store/operation/types';

import { Typeahead, TypeaheadPropsType } from './Components/Mantine/Typeahead';

type OperationSearchBarPropsType = Omit<TypeaheadPropsType, 'onSelect' | 'data'> & {
  onSelect: (operation: OperationType) => void;
  onDeselect?: () => void;
  autofocus?: boolean;
};

export const OperationSearchBar = ({
  onSelect,
  onDeselect,
  autofocus,
  ...props
}: OperationSearchBarPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [filterName, setFilterName] = useState('');
  const [triggerSearch, setTriggerSearch] = useState(false);

  const { operations, isFetching } = useSelector((state: RootState) => ({
    operations: state.operations.summary.items,
    isFetching: state.operations.isFetching,
  }));

  const debounceFetchOperations = useDebouncedCallback(
    (name: string) => {
      dispatch(getOperations(getAcreageUnitFromLang(language), true, 1, name));
      setTriggerSearch(false);
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    if (triggerSearch) {
      debounceFetchOperations(filterName);
    }
  }, [debounceFetchOperations, filterName]);

  const operationOptions = useMemo(() => {
    return operations.map((op) => ({
      id: op.id,
      label: op.name,
      value: op,
    }));
  }, [operations]);

  const handleSelect = (value: OperationType) => {
    setFilterName(value.name);
    onSelect(value);
  };

  const handleDeselect = () => {
    setFilterName('');
    onDeselect && onDeselect();
  };

  const handleTextChange = (text: string) => {
    setTriggerSearch(true);
    setFilterName(text);
  };

  return (
    <Typeahead
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onTextChange={handleTextChange}
      data={operationOptions}
      value={filterName}
      autofocus={autofocus}
      waitForKeyPress
      isLoading={isFetching}
      {...props}
    />
  );
};
