import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { agencyRoles, getRoleOptions } from 'util/userRoles';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { reassignUserToAgency } from 'store/agencies/requests';
import { getAllAgencies } from 'store/agencies/thunks';
import { Agency } from 'store/agencies/types';
import { RoleType } from 'store/roles/types';
import { User, UserRole } from 'store/user/types';
import { Button, Container, Dialog, Input, Label, Selector, Typeahead } from 'common';

import styles from './AgencyReassignment.module.css';

type AgencyReassignmentDialogPropTypes = {
  user: User;
  onClose: () => void;
  onSubmit?: (role: RoleType) => void;
};

export const AgencyReassignmentDialog = ({
  user,
  onClose,
  onSubmit,
}: AgencyReassignmentDialogPropTypes) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { roles, agencies, hasFetched, hasFailed } = useSelector((state: RootState) => ({
    roles: state.roles.roles,
    agencies: state.agencies.allAgencies,
    hasFetched: state.agencies.hasFetched,
    hasFailed: state.agencies.hasFailed,
  }));

  useEffect(() => {
    if (!hasFetched && !hasFailed) {
      dispatch(getAllAgencies());
    }
  }, [dispatch]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [roleIndex, setRoleIndex] = useState(0);
  const roleOptions = getRoleOptions(agencyRoles(roles), language);

  const [selectedAgency, setSelectedAgency] = useState<Agency | null | undefined>(undefined);
  const [newAgencyName, setNewAgencyName] = useState('');

  const validForm = Boolean(
    selectedAgency?.id || (selectedAgency === null && newAgencyName.length),
  );

  const agencyOptions = [
    {
      id: 0,
      displayName: getString('createNewAgency', language),
      value: null,
    },
    ...agencies.map((a, idx) => ({
      id: idx + 1,
      displayName: a.name,
      value: a,
    })),
  ];

  const handleAgencySelect = (idx: number) => {
    const newOption = agencyOptions[idx].value;
    setSelectedAgency(newOption);
    if (newOption === null) {
      setRoleIndex(roleOptions.findIndex((r) => r.value.name === UserRole.AgencyAdmin));
    } else if (newOption !== null) {
      setNewAgencyName('');
    }
  };

  const handleDeselect = () => {
    setSelectedAgency(undefined);
    setNewAgencyName('');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const selectedRole = roleOptions[roleIndex].value;
      const agencyId = selectedAgency?.id;
      const agencyName = selectedAgency === null ? newAgencyName : undefined;
      await reassignUserToAgency(user.id, selectedRole.name, agencyId, agencyName);
      onSubmit && onSubmit(selectedRole);
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      setIsSubmitting(false);
      showToast(e.message, 'error');
    }
  };

  return (
    <Dialog onClose={onClose}>
      <Container className={styles.Wrapper} vertical>
        <div className={styles.Title}>{getString('assignUserToAgency', language)}</div>
        <div>{getString('selectAgencyReassignMsg', language)}</div>
        <div className={styles.Grid}>
          <Label label={getString('firstName', language)}>
            <Input value={user.first_name} disabled />
          </Label>
          <Label label={getString('lastName', language)}>
            <Input value={user.last_name} disabled />
          </Label>
          <Label label={getString('email', language)}>
            <Input value={user.email} disabled />
          </Label>
          <Label label={getString('role', language)}>
            <Selector
              className={styles.Selector}
              activeIndex={roleIndex}
              onChange={setRoleIndex}
              options={roleOptions}
            />
          </Label>
          <Label label={getString('agency', language)}>
            <Typeahead
              onSelect={handleAgencySelect}
              onDeselect={handleDeselect}
              options={agencyOptions}
              placeholder={getString('searchAgencyName', language)}
              icon="SearchIcon"
            />
          </Label>
          <Label label={getString('name', language)}>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewAgencyName(e.target.value)
              }
              value={newAgencyName}
              placeholder={selectedAgency === null ? getString('newAgencyName', language) : ''}
              disabled={selectedAgency !== null}
            />
          </Label>
        </div>
        <div className={styles.SubmitButton}>
          <Button primary onClick={handleSubmit} disabled={!validForm || isSubmitting}>
            {isSubmitting
              ? `${getString('submitting', language)}...`
              : getString('submit', language)}
          </Button>
        </div>
      </Container>
    </Dialog>
  );
};
