import {
  RECEIVE_SAMPLING_HISTORY,
  REQUEST_SAMPLING_HISTORY,
  SAMPLING_HISTORY_REQUEST_ERROR,
  SamplingHistoryActionsType,
  SamplingHistoryStateType,
} from './types';

const initialState: SamplingHistoryStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: true,
  paginated: {
    items: [],
    page: 1,
    total: 1,
  },
};

export const SamplingHistoryReducer = (
  state = initialState,
  action: SamplingHistoryActionsType,
) => {
  switch (action.type) {
    case REQUEST_SAMPLING_HISTORY:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
      };
    case RECEIVE_SAMPLING_HISTORY:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        paginated: {
          items: action.payload.items,
          page: action.payload.page || 1,
          total: action.payload.per_page
            ? Math.ceil(action.payload.total / action.payload.per_page)
            : 1,
        },
      };
    case SAMPLING_HISTORY_REQUEST_ERROR:
      return {
        ...state,
        hasFetched: false,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
