import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { isNumber } from 'util/numUtils';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { getFieldGeometry, getFieldPrescription } from 'store/fields/thunks';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { putPrescriptionZone } from 'store/prescriptions/requests';
import getZonesForPrescription, { getProPrescriptions } from 'store/prescriptions/thunks';
import { PrescriptionZoneType } from 'store/prescriptions/types';
import getSamplesForField from 'store/samples/thunks';

import PanelContainer from './Panels/Container';
import Map from './Map';

import styles from './Container.module.css';

type paramsType = {
  fieldId: string;
  operationId: string;
  prescriptionId: string;
};

const FieldPrescriptionsContainer = () => {
  const params = useParams<paramsType>();

  const fieldId = Number(params.fieldId);
  const operationId = Number(params.operationId);
  const prescriptionId = Number(params.prescriptionId);

  const isOverview = params.prescriptionId === 'all' || params.prescriptionId === 'create';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();

  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);

  const [selectedZone, setSelectedZone] = useState<number | null>(null);
  const [selectedField, setSelectedField] = useState<number | undefined>(fieldId);
  const [prescriptionZones, setPrescriptionZones] = useState<PrescriptionZoneType[]>([]);
  const { allFields, analytics, field, samples, inputs, operation, proPrescriptions, zones } =
    useSelector((state: RootState) => ({
      allFields: state.fieldGeometry.geometries,
      analytics: state.analytics.analytics,
      field: state.fieldGeometry.geometries[fieldId],
      inputs: state.inputs.inputs,
      operation: state.operations.operationsById[operationId],
      proPrescriptions: state.prescriptionZones.proPrescriptions[fieldId] || [],
      samples: state.samples.samples[fieldId],
      zones: !isOverview ? state.prescriptionZones.zones[prescriptionId] : null,
    }));

  useEffect(() => {
    setPrescriptionZones(zones || []);
  }, [zones]);

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(operationId));
    } else {
      dispatch(setActiveOperation(operation.id));
    }
  }, [dispatch, operationId, operation]);

  useEffect(() => {
    if (!field) {
      dispatch(getFieldGeometry(fieldId));
    }
  }, [field, dispatch, fieldId]);

  useEffect(() => {
    if (!isOverview && field) {
      dispatch(getZonesForPrescription(prescriptionId, field.features[0].properties.acreage_unit));
    }
  }, [dispatch, isOverview, prescriptionId, field]);

  useEffect(() => {
    if (field && !samples) {
      dispatch(getSamplesForField(fieldId, analytics));
    }
  }, [field, fieldId, samples, analytics]);

  useEffect(() => {
    dispatch(getProPrescriptions(fieldId));
  }, [fieldId]);

  const setNewField = async (id: number) => {
    setSelectedField(id);
    if (!allFields[id]) {
      dispatch(getFieldGeometry(id));
    }
    navigate(`/results/rx/${operation.id}/${id}/all`);
  };

  const updatePrescriptionZones = (zoneId: number, mapValue: string) => {
    const newValue = Number(mapValue);
    if (isNumber(newValue) && newValue >= 0) {
      setPrescriptionZones((oldSamples) =>
        oldSamples.map((s) =>
          s.properties.id === zoneId
            ? { ...s, properties: { ...s.properties, amount: newValue } }
            : s,
        ),
      );
    }
  };

  const saveZoneAmount = async (zoneId: number, amount: number) => {
    try {
      const currentAmount = zones?.find((z) => z.properties.id === zoneId)?.properties.amount;

      if (
        zoneId &&
        currentAmount !== null &&
        currentAmount !== undefined &&
        currentAmount !== amount
      ) {
        await putPrescriptionZone(Number(zoneId), { amount });
        showToast(getString('updatePrescriptionZoneSuccessMsg', language));
        dispatch(getFieldPrescription(fieldId, prescriptionId));
      }
    } catch (e) {
      showToast(getString('updatePrescriptionZoneErrorMsg', language), 'error');
    }
  };

  if (!field || !operation) {
    return (
      <Center h="95vh">
        <Loader />
      </Center>
    );
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.MenuWrapper}>
        <div className={styles.PanelContainerWrapper}>
          <PanelContainer
            analytics={analytics}
            field={field}
            inputs={inputs}
            isOverview={isOverview}
            operation={operation}
            proPrescriptions={proPrescriptions}
            samples={samples || []}
            selectedField={selectedField}
            setNewField={setNewField}
            zones={prescriptionZones}
            updatePrescriptionZones={updatePrescriptionZones}
            setSelectedZone={setSelectedZone}
            saveZoneAmount={saveZoneAmount}
          />
        </div>
        <div className={styles.MapField}>
          <Map
            field={field}
            mapRef={mapRef}
            mapContainerRef={mapContainerRef}
            zones={prescriptionZones}
            selectedZone={selectedZone}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldPrescriptionsContainer;
