import { Button, Group, Modal } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UI_COLORS } from 'constants/colors';

import { projectsQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { deleteFieldGate } from 'store/projects/requests';

import { useProjectsTranslations } from '../hooks';

type Props = {
  gateId: number | undefined;
  fieldId: number;
  onClose: VoidFunction;
};

const DeleteGateModal = ({ gateId, onClose, fieldId }: Props) => {
  const translations = useProjectsTranslations();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => deleteFieldGate(gateId as number),
    onSettled: onClose,
    onSuccess: () => {
      showToast(translations.success, 'success');

      queryClient.invalidateQueries({
        queryKey: projectsQueryKeys.fieldGates(fieldId),
      });
    },
  });

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    mutation.mutate();
  };

  return (
    <Modal opened={!!gateId} onClose={onClose} title={`${translations.deleteGate}?`} centered>
      <form onSubmit={handleSubmit}>
        <Group>
          <Button style={{ marginLeft: 'auto' }} onClick={onClose} variant="outline">
            {translations.cancel}
          </Button>
          <Button type="submit" color={UI_COLORS.error}>
            {translations.deleteGate}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default DeleteGateModal;
