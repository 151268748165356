import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CloseButton, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import { inputSearchKeys } from 'util/queryKeys';
import { DEBOUNCE } from 'util/request';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestGetSeedSearch } from 'store/cropPlans/requests';
import { SeedType } from 'store/cropPlans/types';
import { Typeahead } from 'common/Components/Mantine/Typeahead';

import { RxSettingsStateType } from '../SinglePrescription/Settings';

import { PrescriptionStateType } from './Container';

interface SeedSearchProps {
  defaultSeed: SeedType | null;
  language: string;
  onSave?: VoidFunction;
  rxForm: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>;
}

const SeedSearch = ({ defaultSeed, language, onSave, rxForm }: SeedSearchProps) => {
  const { crop, seed } = rxForm.getValues();

  const [seedName, setSeedName] = useState(seed?.hybrid || '');
  const [debouncedSeedName] = useDebouncedValue(seedName, DEBOUNCE);

  const { data, refetch } = useQuery<SeedType[], Error>({
    queryKey: inputSearchKeys.seedSearch(seedName, crop),
    queryFn: async (): Promise<SeedType[]> => {
      try {
        if (!debouncedSeedName) {
          return [];
        }
        return await requestGetSeedSearch(debouncedSeedName);
      } catch (err) {
        showToast(getString('failedToFetchSeeds', language), 'error');
        throw err;
      }
    },
    enabled: !!seedName,
  });

  // Trigger API immediately on first keystroke, then rely on debounce
  useEffect(() => {
    if (seedName) {
      refetch();
    }
  }, [debouncedSeedName]);

  const seeds: SeedType[] = Array.isArray(data) ? data : [];

  const options = seeds.map((s) => ({
    id: s.id,
    label: s.hybrid,
    value: s.id,
  }));

  const updateField = <T,>(
    form: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>,
    key: keyof T,
    value: T[keyof T],
  ): void => {
    (form.setFieldValue as (key: keyof T, value: T[keyof T]) => void)(key, value);
  };

  const reset = () => {
    updateField(rxForm, 'seed', null);
    setSeedName('');
  };

  const handleSelect = (value: string) => {
    const optionId = parseInt(value, 10);
    const item = seeds.find((p) => p.id === optionId);
    if (item) {
      updateField(rxForm, 'seed', item);
      setSeedName(item.hybrid);
      if (onSave) {
        onSave();
      }
    }
  };

  return (
    <>
      <Text>{getString('hybridVariety', language)}</Text>
      <Typeahead
        onBlur={() => setSeedName(seed?.hybrid || seedName)}
        data={options}
        disabled={Boolean(seed) && !defaultSeed}
        leftSection={<FiSearch />}
        onSelect={handleSelect}
        onTextChange={setSeedName}
        placeholder={`${getString('hybrid', language)}...`}
        rightSection={
          <CloseButton onClick={reset} onMouseDown={(e) => e.preventDefault()} size="sm" />
        }
        value={seedName || defaultSeed?.hybrid}
      />
    </>
  );
};

export default SeedSearch;
