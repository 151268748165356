import React from 'react';
import { Box, Center, NavLink, Text } from '@mantine/core';
import classNames from 'classnames';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { IMAGERY, SOIL_TEST, YIELD } from 'constants/prescription';

import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';

import styles from './Map.module.css';

interface MissingDataMessageProps {
  field: FieldType;
  handleGenerateFromChange: (val: string) => void;
  language: string;
  type: typeof IMAGERY | typeof YIELD | typeof SOIL_TEST;
}

const MissingDataMessage = ({
  field,
  handleGenerateFromChange,
  language,
  type,
}: MissingDataMessageProps) => {
  const { operation_id, composite_imagery_layers } = field.features[0].properties;
  const getHeaderTextKey = () => {
    if (type === YIELD) {
      return 'yieldDataNotFound';
    }
    if (type === IMAGERY) {
      return 'imageryNotFound';
    }
    return 'soilDataNotFound';
  };
  const getDescriptionTextKey = () => {
    if (type === YIELD) {
      return 'yieldDataNotFoundDescription';
    }
    if (type === IMAGERY) {
      return 'imageryNotFoundDescription';
    }
    return 'soilDataNotFoundDescription';
  };
  return (
    <Box className={styles.FieldMap}>
      <Box className={classNames(styles.MapWrapper, styles.MissingData)} mt="xs" p="sm">
        <Center>
          <Text fw={FONT_WEIGHT_BOLD} size="xl">
            {getString(getHeaderTextKey(), language)}
          </Text>
        </Center>
        <Text>{getString(getDescriptionTextKey(), language)}</Text>
        <NavLink
          href={`/operations/${operation_id}/ingest_history`}
          label={getString('manualUpload', language)}
          td="underline"
        />
        <NavLink
          href="/connected-accounts"
          label={getString('connectOutsideAccounts', language)}
          td="underline"
        />
        {type === YIELD && !!composite_imagery_layers.length && (
          <NavLink
            label={getString('useImagery', language)}
            onClick={() => handleGenerateFromChange(IMAGERY)}
            td="underline"
          />
        )}
      </Box>
    </Box>
  );
};

export default MissingDataMessage;
