import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Group } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PROJECT_NAME_FORM_KEY } from 'constants/projects';
import { projectsRoutes } from 'constants/routes';

import { projectsQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { createProject } from 'store/projects/requests';
import { ProjectType } from 'store/projects/types';

import { useProjectsTranslations, useSelectedOperationsAndFieldsText } from './hooks';
import ProjectNameInput from './ProjectNameInput';
import { useProjectFieldsByOperationQueries } from './queries';

type Props = {
  operationIds: number[];
};

const CreateProjectForm = ({ operationIds }: Props) => {
  const translations = useProjectsTranslations();
  const navigate = useNavigate();
  const { isPending: projectFieldsQueriesPending, fields } =
    useProjectFieldsByOperationQueries(operationIds);
  const selectionText = useSelectedOperationsAndFieldsText(operationIds.length, fields.length);
  const queryClient = useQueryClient();

  const createProjectMutation = useMutation({
    mutationFn: (data: { projectName: string; fieldIds: number[] }) => {
      return createProject({
        field_ids: data.fieldIds,
        project: {
          name: data.projectName,
        },
      });
    },
    onSuccess: (project: ProjectType) => {
      showToast(translations.success, 'success');
      navigate(projectsRoutes.routes.project(project.id));
      queryClient.invalidateQueries({ queryKey: projectsQueryKeys.listProjects });
    },
  });

  const handleCreateProjectSubmit = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get(PROJECT_NAME_FORM_KEY) as string;

    createProjectMutation?.mutate({
      projectName: name,
      fieldIds: fields.map((field) => field.id),
    });
  };

  return (
    <Group component="form" onSubmit={handleCreateProjectSubmit} align="end">
      <ProjectNameInput description={selectionText} />
      <Button
        type="submit"
        loading={createProjectMutation?.isPending || projectFieldsQueriesPending}
      >
        {translations.submit}
      </Button>
    </Group>
  );
};

export default CreateProjectForm;
