import React from 'react';
import { useDispatch } from 'react-redux';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { updateDNAConcentration } from 'store/dnaConcentration/thunks';
import { FileUploadButton } from 'common';

type ImportDataButtonPropTypes = {
  plateBarcode: string;
  quadrant: number;
  isUpdating: boolean;
};

export const ImportDataButton = ({
  plateBarcode,
  quadrant,
  isUpdating,
}: ImportDataButtonPropTypes) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handleUpload = (file: File) => {
    dispatch(updateDNAConcentration(plateBarcode, file, quadrant));
  };

  const buttonLabel = isUpdating
    ? getString('updateData', language)
    : getString('importData', language);
  const dialogLabel = isUpdating
    ? getString('updateData', language)
    : getString('importData', language);
  const messsage = isUpdating ? getString('updateConcentrationDataMsg', language) : '';

  return (
    <FileUploadButton
      buttonLabel={buttonLabel}
      dialogLabel={dialogLabel}
      onUpload={handleUpload}
      accept=".csv"
      message={messsage}
      danger={isUpdating}
    />
  );
};
