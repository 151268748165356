import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mantine/core';
import center from '@turf/center';
import { FeatureCollection, Point } from '@turf/helpers';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import mapboxgl, {
  EventData,
  GeoJSONSourceRaw,
  Layer,
  MapboxGeoJSONFeature,
  MapMouseEvent,
} from 'mapbox-gl';

import {
  CIRCLE,
  MODES,
  PARTIAL_ANALYTICS,
  POINT,
  POINT_STYLING,
  STATIC_OUTLINE,
} from 'constants/mapbox';

import { getNewZonesFromDrag } from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { removeMapLayer } from 'util/mapbox';
import { isGridsOption, isPointsOption } from 'util/samplePlan';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import useMapboxGl from 'common/MapHooks';
import { DrawMode, type ViewPortProps } from 'common/Maps/types';

import DrawingTools from './DrawingToolsV3';
import {
  createPointFunction,
  handleDeleteFunction,
  handleSelectFunction,
  moveGridFunction,
  onClickPointFunction,
  resetDrawingModeFunction,
  rotateGridFunction,
  setLayerFunction,
} from './mapUtils';
import useAnalysisMapSetup from './useAnalysisMapSetup';
import useBioZoneDisplay from './useBioZoneDisplay';

interface MapProps {
  field: FieldType;
  drawRef: { current: any | null };
}

type MouseRefType = (
  ev: MapMouseEvent & {
    features?: MapboxGeoJSONFeature[] | undefined;
  } & EventData,
) => void;

const SamplingPointsMap = ({ field, drawRef }: MapProps) => {
  const language = useBroswerLanguage();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const mouseDownRef = useRef<MouseRefType>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const form = useOrderFormContext();
  const formValues = form.getValues();

  const [prevZones, setPrevZones] = useState(formValues.previewScanPoints);
  const [drawAction, setDrawAction] = useState<string | null>(null);
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [proMapCreationOption, setProMapCreationOption] = useState(
    formValues.proPointCreationOption,
  );
  const [mapDensity, setMapDensity] = useState<number>(formValues.scanDensity);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });
  const [mode, setMode] = useState(MODES.SELECT);

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, () => {}, true);
  const { mapHasLoaded, initialViewport } = useAnalysisMapSetup(
    mapRef,
    wrapperRef,
    field,
    setMode,
    setViewport,
    viewport,
  );

  useEffect(() => {
    if (!formValues.scanPoints) {
      drawRef.current?.deleteAll();
    }
  }, [formValues.scanPoints, drawRef]);

  useEffect(() => {
    if (mapHasLoaded && formValues.scanPoints) {
      drawRef.current?.deleteAll();
      drawRef.current?.add(formValues.scanPoints);
    }
  }, [mapHasLoaded, drawRef, formValues.scanPoints]);

  const moveGrid = useCallback(
    (e) => moveGridFunction(e, mapRef, formValues.previewScanPoints),
    [mapRef, formValues.previewScanPoints],
  );

  const onMouseUp = useCallback(
    (e) => {
      if (formValues.previewScanPoints) {
        const newPreviewZoneFeatCollection = getNewZonesFromDrag(
          [e.lngLat.lng, e.lngLat.lat],
          formValues.previewScanPoints,
        );
        if (newPreviewZoneFeatCollection && mouseDownRef.current) {
          form.setValues({
            previewScanPoints: newPreviewZoneFeatCollection,
          });
          mapRef.current?.off('mousemove', moveGrid);
          mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
          setIsMouseDown(false);
        }
      }
    },
    // Circular dep issue, need to be able to shut off listener here
    [mapRef, moveGrid, formValues.previewScanPoints, mouseDownRef],
  );

  const mouseDownHelper: any = useCallback(
    (e: any) => {
      e.preventDefault();
      mapRef.current?.on('mousemove', moveGrid);
      mapRef.current?.once('mouseup', onMouseUp);
    },
    [mapRef, moveGrid, onMouseUp],
  );

  const createPoint = useCallback(
    (e: FeatureCollection<Point>) =>
      createPointFunction(e, drawRef, 'scanPoints', field, language, form.setValues),
    [drawRef],
  );

  useEffect(() => {
    // Grids, turn off listener and turn back on when config changes
    if (
      formValues.scanDensity !== mapDensity ||
      formValues.proPointCreationOption !== proMapCreationOption ||
      formValues.previewScanPoints?.features !== prevZones?.features
    ) {
      if (mouseDownRef.current) {
        mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
      }
      setMapDensity(formValues.scanDensity);
      setProMapCreationOption(formValues.proPointCreationOption);
      setIsMouseDown(false);
    }
  }, [
    mapRef,
    formValues.previewScanPoints,
    formValues.scanDensity,
    mapDensity,
    mouseDownRef,
    moveGrid,
    proMapCreationOption,
    formValues.proPointCreationOption,
    prevZones,
  ]);

  useEffect(() => {
    if (mapHasLoaded && mapRef.current) {
      if (isGridsOption(formValues.proPointCreationOption) && !isMouseDown) {
        setIsMouseDown(true);
        setPrevZones(formValues.previewScanPoints);
        mapRef.current?.on('mousedown', 'preview-boundary', mouseDownHelper);
        // @ts-ignore
        mouseDownRef.current = mouseDownHelper;
      } else if (isPointsOption(formValues.proPointCreationOption)) {
        mapRef.current?.on('draw.create', createPoint);
      } else {
        mapRef.current?.off('draw.create', createPoint);
      }
    }
  }, [
    mapRef,
    mapHasLoaded,
    createPoint,
    mouseDownHelper,
    formValues.previewScanPoints,
    isMouseDown,
    prevZones,
  ]);

  const resetStaticLine = useCallback(() => {
    if (mapRef.current?.getLayer(STATIC_OUTLINE)) {
      mapRef.current?.setPaintProperty(STATIC_OUTLINE, 'line-opacity', 1);
    }
  }, [mapRef]);

  const setToDefaultMode = useCallback(
    (modeStr: DrawMode) => {
      setMode(modeStr);
      drawRef.current?.changeMode(modeStr);
    },
    [drawRef],
  );

  const onClickUnlockedPoint = useCallback(
    (e: any) => onClickPointFunction(e, mapRef, drawRef, POINT),
    [drawRef, mapRef],
  );

  const resetDrawingMode = useCallback(
    (locking: boolean) =>
      resetDrawingModeFunction(
        locking,
        mapRef,
        formValues.proPointCreationOption,
        resetStaticLine,
        setDrawAction,
        setToDefaultMode,
        [onClickUnlockedPoint],
      ),
    [
      mapRef,
      resetStaticLine,
      setToDefaultMode,
      formValues.proPointCreationOption,
      onClickUnlockedPoint,
    ],
  );

  useEffect(() => {
    if (formValues.disableScanMapTools) {
      resetDrawingMode(true);
    } else {
      resetDrawingMode(false);
    }
  }, [formValues.disableScanMapTools, mapRef, resetDrawingMode, formValues.proPointCreationOption]);

  const setLayer = useCallback(
    (id: string, source: GeoJSONSourceRaw, layer: Partial<Layer>) =>
      setLayerFunction(mapRef, id, source, layer),
    [mapRef],
  );

  useEffect(() => {
    if (mapHasLoaded && mapRef) {
      if (formValues.previewScanPoints) {
        removeMapLayer(mapRef, 'preview-boundary');
        removeMapLayer(mapRef, 'preview-outline');
        const source = {
          type: 'geojson',
          data: formValues.previewScanPoints,
        } as GeoJSONSourceRaw;
        setLayer('preview-boundary', source, {
          type: CIRCLE,
          paint: POINT_STYLING,
        });
        removeMapLayer(mapRef, 'preview-outline');
      } else {
        removeMapLayer(mapRef, 'preview-boundary');
        removeMapLayer(mapRef, 'preview-outline');
      }
    }
  }, [setLayer, mapRef, mapHasLoaded, formValues.previewScanPoints]);

  useBioZoneDisplay(mapRef, mapHasLoaded);

  useEffect(() => {
    if (mapHasLoaded && mapRef && formValues.disableScanMapTools) {
      removeMapLayer(mapRef, PARTIAL_ANALYTICS);
      resetStaticLine();
    }
  }, [mapRef, mapHasLoaded, resetStaticLine, formValues.disableScanMapTools]);

  const rotateGrid = useCallback(
    (isClockwise: boolean) =>
      rotateGridFunction(
        mapRef,
        mouseDownRef,
        formValues.previewScanPoints,
        'previewScanPoints',
        isClockwise,
        formValues.gridAngle,
        form.setValues,
        setIsMouseDown,
      ),
    [formValues.previewScanPoints, mapRef, mouseDownRef, formValues.gridAngle],
  );

  const drawModeSetter = (drawType: any) => {
    setMode(drawType);
    if (drawRef.current) {
      drawRef.current.changeMode(drawType);
    }
  };

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  return (
    <Box h="100%" pos="relative" ref={wrapperRef} flex={1}>
      <Box ref={mapContainerRef} h="100%" w="100%" />
      <Button variant="white" onClick={recenterMap} bottom={10} pos="absolute" right="3rem">
        {getString('recenter', language)}
      </Button>
      <DrawingTools
        drawModeSetter={drawModeSetter}
        mode={mode}
        drawAction={drawAction}
        handleDelete={() =>
          handleDeleteFunction(
            mapRef,
            formValues.scanPoints,
            'scanPoints',
            language,
            form.setValues,
            resetDrawingMode,
          )
        }
        handleSelect={(action, start) =>
          handleSelectFunction(
            mapRef,
            drawRef,
            action,
            start,
            setDrawAction,
            onClickUnlockedPoint,
            resetDrawingMode,
          )
        }
        rotateGrid={rotateGrid}
        isScanPoints
      />
    </Box>
  );
};
export default SamplingPointsMap;
