import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Group, SegmentedControl, Stack, Table, TextInput } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getAllCatalogs } from 'store/catalogs/thunks';
import { CatalogType } from 'store/catalogs/types';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { AdminAccess, LoadingRows } from 'common';
import { Header } from 'common/Components/Mantine/Header';

const CatalogListContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { catalogs, hasFailed, isFetching, isAdmin } = useSelector((state: RootState) => ({
    catalogs: state.catalogs.all,
    hasFailed: state.catalogs.hasFailed,
    isFetching: state.catalogs.isFetching,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));
  const [filterName, setFilterName] = useState('');
  const debouncedGetCatalogs = useDebouncedCallback(
    () => {
      dispatch(getAllCatalogs(undefined, undefined, filterName, viewingAll));
    },
    200,
    { trailing: true },
  );

  const viewingAll = isAdmin && location.pathname === '/catalogs/all';

  useEffect(() => {
    debouncedGetCatalogs();
  }, [debouncedGetCatalogs, viewingAll, filterName]);

  const viewOptions = [
    {
      label: getString('myCatalogs', language),
      value: '/catalogs',
    },
    {
      label: getString('allCatalogs', language),
      value: '/catalogs/all',
    },
  ];

  return (
    <Stack>
      <Header title={getString('manageCatalogs', language)}>
        <Group>
          <AdminAccess>
            <TextInput onChange={(e) => setFilterName(e.target.value)} value={filterName} />
          </AdminAccess>
          <Button onClick={() => navigate('/catalog/create')}>
            {getString('create', language)}
          </Button>
        </Group>
      </Header>
      <AdminAccess>
        <SegmentedControl
          color="blue"
          value={viewOptions.find((url) => url.value === location.pathname)?.value}
          onChange={(value) => navigate(value)}
          data={viewOptions}
          w="20rem"
        />
      </AdminAccess>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w="40%">{getString('name', language)}</Table.Th>
            <Table.Th w="40%">{getString('user', language)}</Table.Th>
            <Table.Th />
            <Table.Th w="5rem">{getString('active', language)}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {isFetching || hasFailed ? (
            <LoadingRows rowSpan={4} colSpan={2} />
          ) : (
            catalogs.map((row: CatalogType) => (
              <Table.Tr key={row.id} onClick={() => navigate(`/catalog/${row.id}`)}>
                <Table.Td w="40%">{row.name}</Table.Td>
                <Table.Td w="40%">{row.user_name}</Table.Td>
                <Table.Td />
                <Table.Td w="5rem">{getString(row.show_in_ui ? 'yes' : 'no', language)}</Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};

export default CatalogListContainer;
