import turfBbox from '@turf/bbox';
import buffer from '@turf/buffer';
import center from '@turf/center';
import {
  Feature,
  feature,
  FeatureCollection,
  featureCollection,
  MultiPolygon,
  Point,
  Polygon,
} from '@turf/helpers';
import pointGrid from '@turf/point-grid';
import squareGrid from '@turf/square-grid';

import { ALL_PLANS, ALL_PRO_OR_TILL, NON_PRO_TILL, PRO_ONLY, TILL_ONLY } from 'constants/alerts';
import { BR, US } from 'constants/countries';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { POINT, POLYGON, ZONE_SELECTED, ZONE_TYPES } from 'constants/mapbox';
import { DISCOUNT, NITRATE_PANEL, NUTRIENT_PANEL } from 'constants/products';
import {
  boronIds,
  bufferPhIds,
  calciumIds,
  CEC_ID,
  copperIds,
  ironIds,
  magnesiumIds,
  manganeseIds,
  NITROGEN_ID,
  PH_ID,
  phosphorusIds,
  potassiumIds,
  PRO_NUTRIENT_ANALYTIC_IDS,
  SOIL_ATTRIBUTES,
  SULFUR_ID,
  zincIds,
} from 'constants/results';
import {
  ACCEPTED,
  ADCP_NEEDED_NOT_COMPLETE,
  ALL_ASSIGNMENTS,
  ALL_READINESS,
  CANCELLED,
  COMPLETED,
  COMPLETED_PLAN,
  CREATED,
  CUSTOM_POINTS,
  DECLINED,
  DEM_ZONES,
  EMI_NEEDED_NOT_COMPLETE,
  GRID_POINTS,
  GRID_ZONES,
  NOT_READY,
  PARTIAL,
  PARTIALLY_RECEIVED,
  PARTIALLY_SAMPLED,
  PARTIALLY_SHIPPED,
  PER_ACRE_SAMPLING_KEYS,
  POINT_BASED,
  READY,
  RECEIVED,
  SAMPLED,
  SAMPLES_NOT_COMPLETE,
  SHIPPED,
  SPLIT_DENSITY,
  splitDensities,
  SSURGO_ZONES,
  UNASSIGNED,
  UNASSIGNED_EO,
  UNASSIGNED_PATTERN,
  UPLOAD_ZONES,
  ZONE_BY_ZONE,
} from 'constants/samplePlanning';
import { WISCONSIN } from 'constants/states';
import { ALL } from 'constants/variables';

import { getString } from 'strings/translation';
import { AnalyticType } from 'store/analytics/types';
import { FieldPropertiesType, FieldType, SampleType, SamplingPlanType } from 'store/fields/types';
import { CreationOption, OrderForm } from 'store/pricing/types';
import { PriceSummaryType, SamplePlanTrackingType } from 'store/samplePlans/types';
import { User } from 'store/user/types';

import {
  formatCalendarMonthDayYear,
  localDate,
  sortByCreatedAt,
  sortByDateAttribute,
} from './date';
import { DateAttributesType, SampleGeoJSON } from './generalTypes';
import {
  acresToFeetSideLength,
  convertToDisplayPoint,
  processSingleZoneFeature,
  processZoneFeatureCollection,
} from './geospatial';
import { roundTwoDecimal } from './numUtils';
import { isNutrientPanel } from './product';
import { getWhiteLabelTranslation, joinStrings } from './stringUtils';
import { haToAcres } from './units';

export const getSamplePlanStatuses = (language: string) => [
  { label: getString(CREATED, language), id: 1, value: CREATED },
  { label: getString(PARTIALLY_SAMPLED, language), id: 2, value: PARTIALLY_SAMPLED },
  { label: getString(SAMPLED, language), id: 3, value: SAMPLED },
  { label: getString(PARTIALLY_SHIPPED, language), id: 4, value: PARTIALLY_SHIPPED },
  { label: getString(SHIPPED, language), id: 5, value: SHIPPED },
  { label: getString(PARTIALLY_RECEIVED, language), id: 6, value: PARTIALLY_RECEIVED },
  { label: getString(RECEIVED, language), id: 7, value: RECEIVED },
  { label: getString(PARTIAL, language), id: 8, value: PARTIAL },
  { label: getString(COMPLETED, language), id: 9, value: COMPLETED },
];

export const getAssignmentOptions = (language: string) => [
  { label: getString(ALL_ASSIGNMENTS, language), id: 0, value: ALL_ASSIGNMENTS },
  { label: getString('unassigned_all', language), value: UNASSIGNED },
  { label: getString('unassigned_pattern', language), value: UNASSIGNED_PATTERN },
  { label: getString('unassigned_eo', language), value: UNASSIGNED_EO },
  { label: getString(ACCEPTED, language), value: ACCEPTED },
  { label: getString(DECLINED, language), value: DECLINED },
];

export const getReadinessOptions = (language: string) => [
  { label: getString(ALL_READINESS, language), id: 0, value: ALL_READINESS },
  { label: getString(READY, language), id: 1, value: READY },
  { label: getString(NOT_READY, language), id: 2, value: NOT_READY },
];

export const getProSelectionOptions = (language: string) => [
  { label: getString(ALL_PLANS, language), id: 0, value: ALL_PLANS },
  { label: getString(ALL_PRO_OR_TILL, language), id: 1, value: ALL_PRO_OR_TILL },
  { label: getString(PRO_ONLY, language), id: 2, value: PRO_ONLY },
  { label: getString(TILL_ONLY, language), id: 3, value: TILL_ONLY },
  { label: getString(NON_PRO_TILL, language), id: 4, value: NON_PRO_TILL },
];

export const getPlanCompletenessOptions = (language: string) => [
  { label: getString(SAMPLES_NOT_COMPLETE, language), value: SAMPLES_NOT_COMPLETE },
  { label: getString(EMI_NEEDED_NOT_COMPLETE, language), value: EMI_NEEDED_NOT_COMPLETE },
  { label: getString(ADCP_NEEDED_NOT_COMPLETE, language), value: ADCP_NEEDED_NOT_COMPLETE },
];

export const isPlanCancelled = (plan: SamplingPlanType) => plan.sampling_status === CANCELLED;
export const isPlanCompleted = (plan: SamplingPlanType) => plan.sampling_status === COMPLETED;
export const isPlanCreated = (plan: SamplingPlanType) => plan.sampling_status === CREATED;
export const sortPlansByDate = (plans: SamplingPlanType[]) => sortByCreatedAt(plans);

export const getMonthString = (month: number) => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    default:
      return 'Dec';
  }
};

export const getPlanName = (plan: SamplingPlanType | SamplePlanTrackingType) => {
  const planDate = localDate((plan as SamplingPlanType).sampled_at || plan.created_at);
  const month = getMonthString(planDate.getUTCMonth());
  const year = planDate.getUTCFullYear();
  const day = planDate.getUTCDate();
  return `${month} ${day}, ${year}`;
};

export const getRecentDateByStatus = (
  samples: SampleType[],
  status: SamplingPlanType['sampling_status'],
): string | null => {
  // Map from sampling status to sample attribute
  const dateAttributes = {
    created: 'created_at',
    sampled: 'sampled_at',
    received: 'received_at',
    partial: 'processed_at',
    completed: 'processed_at',
    cancelled: 'created_at',
    ready: 'updated_at',
    unassigned: 'updated_at',
  };
  const samplesByStatus = samples.filter(
    (sample) => sample[dateAttributes[status] as DateAttributesType],
  );

  const lastSample = sortByDateAttribute(
    samplesByStatus,
    dateAttributes[status] as DateAttributesType,
  )[0];
  if (!lastSample) {
    return null;
  }
  return lastSample[dateAttributes[status] as Partial<keyof SampleType>] as string | null;
};

export const getSortedAndActivePlans = (fieldProps: FieldPropertiesType) => {
  return sortPlansByDate(fieldProps.sampling_plans || []).filter((p) => !isPlanCancelled(p));
};

export const getSortedPlansWithResults = (
  fieldProps: FieldPropertiesType,
  analyticCategories: string[],
) => {
  return sortPlansByDate(fieldProps.sampling_plans || []).filter((p) =>
    hasResultsByCategory(p, analyticCategories),
  );
};

export const hasResultsByCategory = (
  samplingPlan: SamplingPlanType,
  analyticCategories: string[],
) =>
  !isPlanCreated(samplingPlan) &&
  analyticCategories.some((category) => {
    const results = samplingPlan.analytics?.[category];
    return results && Object.keys(results).length;
  });

export const getAllPlansWithResultsForFields = (
  fieldPropsArray: FieldPropertiesType[],
  analyticCategories: string[],
) => {
  return fieldPropsArray.flatMap((fieldProps) =>
    sortPlansByDate(fieldProps.sampling_plans || []).filter((p) =>
      hasResultsByCategory(p, analyticCategories),
    ),
  );
};

export const getLastSamplingPlan = (fieldProps: FieldPropertiesType): SamplingPlanType | null => {
  const [lastSamplingPlan] = getSortedAndActivePlans(fieldProps);
  return lastSamplingPlan;
};

export const getLastCompletedPlan = (fieldProps: FieldPropertiesType): SamplingPlanType | null => {
  const [lastSamplingPlan] = getSortedAndActivePlans(fieldProps).filter((p) => isPlanCompleted(p));
  return lastSamplingPlan;
};

// Mainly for plans that were done twice in the same year (Earth Optics)
export const getSamplingPlanWithResultsForAnalytics = (
  plans: SamplingPlanType[] | undefined,
  analyticIds: number[],
) =>
  plans?.find((plan) => {
    const allPlanAnalytics = Object.keys(plan.analytics)
      .map((key) => Object.keys(plan.analytics[key]))
      .flat(1);
    return allPlanAnalytics?.some((key) => analyticIds.includes(Number(key)));
  });

export const getLastSamplingPlanWithResults = (
  fieldProps: FieldPropertiesType,
  analyticCategories: string[],
): SamplingPlanType | null => {
  const [lastSamplingPlan] = getSortedPlansWithResults(fieldProps, analyticCategories);
  return lastSamplingPlan;
};

export const isAgronomicOption = (creationOption: string) =>
  [SSURGO_ZONES, DEM_ZONES].includes(creationOption);
export const isGridsOption = (creationOption: string) =>
  [GRID_ZONES, GRID_POINTS].includes(creationOption);
export const isUploadOption = (creationOption: string) => creationOption === UPLOAD_ZONES;
export const isCustomPointsOption = (creationOption: string) => creationOption === CUSTOM_POINTS;
export const isPointsOption = (creationOption: string) =>
  [CUSTOM_POINTS, GRID_POINTS].includes(creationOption);

export const unlockingButtonText = (
  creationOption: string,
  isLoading: boolean,
  language: string,
) => {
  if (isAgronomicOption(creationOption)) {
    return isLoading
      ? `${getString('generating', language)}...`
      : getString('reselectDensity', language);
  }
  if (isUploadOption(creationOption) && isLoading) {
    return `${getString('uploading', language)}...`;
  }
  if (isGridsOption(creationOption)) {
    return getString('editZones', language);
  }
  return getString('resetMap', language);
};

export const lockingButtonText = (creationOption: string, isLoading: boolean, language: string) => {
  if (isAgronomicOption(creationOption)) {
    return isLoading
      ? `${getString('generating', language)}...`
      : getString('generateZones', language);
  }
  if (isUploadOption(creationOption)) {
    return getString('upload_zones', language);
  }
  return getString('lockZones', language);
};

export const getStatusDate = (
  samplingPlan: SamplingPlanType,
  status: SamplingPlanType['sampling_status'],
) => {
  if (samplingPlan) {
    const lastDate = getRecentDateByStatus(samplingPlan.samples, status);
    return lastDate ? formatCalendarMonthDayYear(lastDate) : '';
  }
  return NOT_APPLICABLE;
};

export const getIncompleteProducts = (
  selectedPackages: { [product: string]: string[] },
  totalZones: number,
) =>
  Object.keys(selectedPackages).filter((product) => {
    const uuidCount = selectedPackages[product].length;
    return uuidCount > 0 && uuidCount < totalZones;
  });

export const hasSoilAttributes = (samplingPlan: SamplingPlanType) =>
  Object.keys(samplingPlan.analytics[SOIL_ATTRIBUTES]).length;

export const isPointBased = (samplingPlan: SamplingPlanType) =>
  samplingPlan.zone_type && POINT_BASED.includes(samplingPlan.zone_type);

export const pricePerAcre = (totalPrice: number, acreage: number) => totalPrice / acreage;

export const getDensity = (zones: number, acreage: number) =>
  zones ? Number((acreage / zones).toFixed(1)) : 0;

export const getPriceDisplay = (prod: string, totalPrice: number) => {
  const price = roundTwoDecimal(totalPrice) || 0;
  if (prod.includes(DISCOUNT)) {
    return `-$${price * -1}`;
  }
  return `$${price}`;
};

export const countryTabs = (language: string) => [
  {
    displayName: 'USA',
    value: US,
    label: 'USA',
  },
  {
    displayName: getString('all', language),
    value: ALL,
    label: getString('all', language),
  },
  {
    displayName: 'BR',
    value: BR,
    label: 'BR',
  },
];

export const mergeAnalysisProZones = (
  currentZonesFeatureCollection: OrderForm['zones'],
  newZonesFeatureCollection: FeatureCollection<MultiPolygon | Polygon | Point>,
) =>
  featureCollection([
    ...(currentZonesFeatureCollection?.features || []),
    ...(newZonesFeatureCollection?.features.map((feat, idx) =>
      processSingleZoneFeature(
        feature(convertToDisplayPoint(feat as Feature<Polygon>)),
        idx,
        ZONE_TYPES.POINT,
      ),
    ) || []),
  ]);

export const operationAllowsMultiplePlans = (operationUsers: User[] | undefined) => {
  return Boolean(
    operationUsers?.some((user) =>
      user.agencies?.some((agency) => agency.allow_multiple_plans === true),
    ),
  );
};

export const resetPreviewGrid = (
  creationType: string,
  density: number,
  fieldGeometry: FieldType,
) => {
  const { geometry, properties } = fieldGeometry.features[0];
  const cellWidth = acresToFeetSideLength(haToAcres(density, properties.acreage_unit));
  const units = 'feet';

  const bufferedBbox = turfBbox(buffer(geometry, cellWidth * 2, { units }));
  if (creationType === GRID_POINTS) {
    const gridPoints = pointGrid(bufferedBbox, cellWidth, { units });
    return processZoneFeatureCollection(gridPoints, POINT) as FeatureCollection<Point>;
  }
  const gridPolys = squareGrid(bufferedBbox, cellWidth, { units });
  return processZoneFeatureCollection(gridPolys, POLYGON) as FeatureCollection<Polygon>;
};

export const prepareResetZones = (
  previewZones: FeatureCollection<Polygon | Point> | null,
  density: number,
  creationOption: string | CreationOption,
  fieldGeometry: FieldType,
) => {
  return {
    previewZones: isGridsOption(creationOption)
      ? resetPreviewGrid(creationOption, density, fieldGeometry)
      : previewZones,
    zoneGeomType: creationOption.toLowerCase().includes(ZONE_TYPES.POINT) ? POINT : POLYGON,
  };
};

export const canAddAllUuidsToAnalysis = (fieldGeometry: FieldType, product: string) => {
  const { properties } = fieldGeometry.features[0];
  if (properties.county?.restricted && [NUTRIENT_PANEL, NITRATE_PANEL].includes(product)) {
    return false;
  }
  return true;
};

export const getSplitDensityOptions = (totalZones: number) =>
  splitDensities
    .reduce((list, percent) => {
      const split = Math.floor(percent * totalZones);
      return split && !list.includes(split) ? list.concat([split]) : list;
    }, [] as number[])
    .map((split, index) => {
      return {
        id: index,
        displayName: split,
        value: split,
      };
    });

export const getZoneType = (sample: SampleGeoJSON, samplingPlan: SamplingPlanType) => {
  if (sample.properties?.zone_type) {
    return sample.properties.zone_type;
  }
  if (isPointBased(samplingPlan)) {
    return ZONE_TYPES.POINT;
  }
  return ZONE_TYPES.POLYGON;
};

export const getPerProduct = (
  fieldGeometry: FieldType,
  uncancelledSamples: SampleGeoJSON[],
  existingPlan: SamplingPlanType,
) => {
  const polygonGeometries = uncancelledSamples
    .filter((sample: SampleGeoJSON) =>
      sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
    )
    .map((sample: SampleGeoJSON) => ({
      ...sample,
      geometry: sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
      properties: {
        products: sample.properties.products.filter(
          (product) =>
            existingPlan.is_pro ||
            existingPlan.is_till_rx ||
            canAddAllUuidsToAnalysis(fieldGeometry, product),
        ),
        sample_grouping_id: sample.properties.sample_grouping_id,
        sample_uuid: sample.properties.sample_uuid, // we create a new uuid on submit
        id: sample.properties.id,
        field_id: sample.properties.field_id,
        sampling_plan_id: sample.properties.sampling_plan_id,
        status: sample.properties.status,
        zone_type: getZoneType(sample, existingPlan),
      },
    })) as SampleGeoJSON[];

  const existingZones = featureCollection(
    polygonGeometries.map((sample: SampleGeoJSON, index: number) => ({
      ...sample,
      geometry:
        getZoneType(sample, existingPlan) === ZONE_TYPES.POINT
          ? // @ts-ignore
            center(sample.geometry).geometry
          : sample.geometry,
      properties: {
        ...sample.properties,
        zone_number: index + 1,
        sample_group: sample.properties.sample_grouping_id,
        zone_type: getZoneType(sample, existingPlan),
      },
    })),
  ) as FeatureCollection<Polygon | Point>;

  return {
    zones: existingZones,
    analysisMode: existingZones.features.some((sample) => sample.properties?.sample_group)
      ? SPLIT_DENSITY
      : ZONE_BY_ZONE,
  };
};

export const getZonesForDisplay = (samples: SampleGeoJSON[], plan: SamplingPlanType) =>
  featureCollection(
    samples.map((sample: SampleGeoJSON, index: number) => ({
      ...sample,
      geometry:
        getZoneType(sample, plan) === ZONE_TYPES.POINT
          ? // @ts-ignore
            center(sample.geometry).geometry
          : sample.geometry,
      properties: {
        ...sample.properties,
        zone_number: index + 1,
        sample_group: sample.properties.sample_grouping_id,
        zone_type: getZoneType(sample, plan),
      },
    })),
  ) as FeatureCollection<Polygon | Point>;

export const getZonesBasedOnAnalysis = (
  isProOrTillRx: boolean,
  existingPlan: OrderForm['existingPlan'],
  existingSamples: OrderForm['existingSamples'],
) => {
  if (!isProOrTillRx && existingPlan) {
    const existingZones = existingSamples.filter(
      (sample) => sample.properties?.zone_type !== ZONE_TYPES.POINT,
    );
    const existingPointZones = existingSamples.filter(
      (sample) => sample.properties?.zone_type === ZONE_TYPES.POINT,
    );
    return getZonesForDisplay(
      existingZones.length ? existingZones : existingPointZones,
      existingPlan,
    );
  }
  if (isProOrTillRx && existingPlan && !isPointBased(existingPlan)) {
    return getZonesForDisplay(existingSamples, existingPlan);
  }
  return null;
};

export const getSamplerOptions = (operation: { operationUsers?: User[] }) =>
  operation.operationUsers?.map((user) => ({
    ...user,
    id: user.id,
    label: getWhiteLabelTranslation(joinStrings([user.first_name, user.last_name], ' ')),
    value: String(user.id),
    can_scan: user.can_scan,
  })) || [];

export const getScannerOptions = (operation: { operationUsers?: User[] }) =>
  operation.operationUsers
    ?.filter((user) => user.id === 0 || user.agencies?.[0]?.self_scanning || user.can_scan)
    .map((user) => ({
      ...user,
      id: user.id,
      label: getWhiteLabelTranslation(joinStrings([user.first_name, user.last_name], ' ')),
      value: String(user.id),
      can_scan: true,
    })) || [];

export const getMapSelectedUuids = (mapRef: any) => {
  try {
    // dangling _ otherwise, just catch it and return []
    const { _data } = mapRef.current?.getSource(ZONE_SELECTED) as any;
    return (_data as FeatureCollection).features.reduce(
      (list, feat) =>
        feat.properties?.sample_uuid ? [...list, feat.properties?.sample_uuid] : list,
      [] as string[],
    );
  } catch (err) {
    return [];
  }
};

export const getZoneCreationOptions = (
  language: string,
  completedPlan: SamplingPlanType | null,
  isProOrTill?: boolean,
) => {
  const currentSelections = [
    {
      id: 2,
      label: getString(SSURGO_ZONES, language),
      value: SSURGO_ZONES,
    },
    {
      id: 4,
      label: getString(GRID_ZONES, language),
      value: GRID_ZONES,
    },
    {
      id: 5,
      label: getString(GRID_POINTS, language),
      value: GRID_POINTS,
    },
    {
      id: 6,
      label: getString(CUSTOM_POINTS, language),
      value: CUSTOM_POINTS,
    },
    {
      id: 1,
      label: getString(UPLOAD_ZONES, language),
      value: UPLOAD_ZONES,
    },
  ].filter((option) => !isProOrTill || ![GRID_POINTS, CUSTOM_POINTS].includes(option.value));

  if (completedPlan) {
    currentSelections.push({
      id: 7,
      label: `${getString('completedPlan', language)}: ${getPlanName(completedPlan)}`,
      value: COMPLETED_PLAN,
    });
  }
  return currentSelections;
};

export const getProPointCreationOptions = (
  language: string,
  completedPlan: SamplingPlanType | null,
) => {
  const scanPointSelections = [
    {
      label: getString(GRID_POINTS, language),
      value: GRID_POINTS,
    },
    {
      label: getString(CUSTOM_POINTS, language),
      value: CUSTOM_POINTS,
    },
  ];
  if (completedPlan) {
    scanPointSelections.push({
      label: `${getString('completedPlan', language)}: ${getPlanName(completedPlan)}`,
      value: COMPLETED_PLAN,
    });
  }
  return scanPointSelections;
};

export const getProScanGrid = (analysis: OrderForm) => {
  const { isProScan, existingPlan, products } = analysis;
  if (!isProScan && existingPlan?.zone_type) {
    return existingPlan.zone_type;
  }
  if (isProScan && isNutrientPanel(products)) {
    return GRID_POINTS;
  }
  return GRID_ZONES;
};

export const getTargetNumberOfZones = (acreage: number, density: number) => {
  return Math.round((Math.floor(acreage / density) * density) / density) || 1;
};

export const getBioZonesCount = (isProOrTillRx: boolean, zones: OrderForm['zones']) => {
  if (isProOrTillRx) {
    return zones?.features.filter((feat) => feat.properties?.zone_type === ZONE_TYPES.POLYGON)
      .length;
  }
  return zones?.features.length;
};

export const getBioZonesDensity = (
  isProOrTillRx: boolean,
  zones: OrderForm['zones'],
  field: FieldType,
) => {
  const bioCount = getBioZonesCount(isProOrTillRx, zones);
  return bioCount ? getDensity(bioCount, field.features[0].properties.acreage) : 0;
};

export const mergePlanAnalytics = (
  selectedPlan: SamplingPlanType | undefined,
  secondPlan: SamplingPlanType | undefined,
) => {
  if (!selectedPlan) {
    return {};
  }
  if (!secondPlan) {
    return selectedPlan?.analytics;
  }

  return Object.keys(selectedPlan.analytics).reduce((all, key) => {
    all[key] = {
      ...secondPlan.analytics[key],
      ...selectedPlan.analytics[key],
    };
    return all;
  }, {});
};

/**
 * IMPORTANT: keep this list up to date as new nutrient layers arrive, otherwise there will not be a
 * Pro layer available for the new nutrient.
 */
const getProNutrientLayerNameByAnalyticId = (id: number): string | undefined => {
  if (id === PH_ID) {
    return 'ph';
  }
  if (id === CEC_ID) {
    return 'cec';
  }
  if (id === SULFUR_ID) {
    return 'sulfur';
  }
  if (id === NITROGEN_ID) {
    return 'nitrogen';
  }
  if (bufferPhIds.includes(id)) {
    return 'buffer_ph';
  }
  if (potassiumIds.includes(id)) {
    return 'potassium';
  }
  if (calciumIds.includes(id)) {
    return 'calcium';
  }
  if (magnesiumIds.includes(id)) {
    return 'magnesium';
  }
  if (boronIds.includes(id)) {
    return 'boron';
  }
  if (ironIds.includes(id)) {
    return 'iron';
  }
  if (manganeseIds.includes(id)) {
    return 'manganese';
  }
  if (copperIds.includes(id)) {
    return 'copper';
  }
  if (zincIds.includes(id)) {
    return 'zinc';
  }
  if (phosphorusIds.includes(id)) {
    return 'phosphorus';
  }
};

export const getProNutrientMapLayer = (
  samplePlan: SamplingPlanType,
  activeAnalytic: AnalyticType,
) => {
  if (!(samplePlan.is_pro && PRO_NUTRIENT_ANALYTIC_IDS.includes(activeAnalytic.id))) {
    return null;
  }

  const layerName = getProNutrientLayerNameByAnalyticId(activeAnalytic.id);

  if (layerName) {
    return samplePlan.nutrient_layers.find((layer) => layer.layer_name === `${layerName}_polys`);
  }
};

export const getAnalysisOverviewFromSummary = (summary: PriceSummaryType) => {
  return Object.keys(summary).reduce(
    (all, key) => {
      const { total, num_zones } = summary[key];
      return {
        total: all.total + total,
        num_zones:
          num_zones > all.num_zones && !PER_ACRE_SAMPLING_KEYS.includes(key)
            ? num_zones
            : all.num_zones,
      };
    },
    { total: 0, num_zones: 0 },
  );
};

const MIN_DENSITY_590_WI = 5;
const MIN_DENSITY_590_IA_MN = 10;

export const is590DensityValid = (
  is590Analysis: OrderForm['is590Analysis'],
  zones: OrderForm['zones'],
  isProScan: OrderForm['isProScan'],
  field: FieldType | undefined,
) => {
  if (!field) {
    return false;
  }
  if (is590Analysis && zones) {
    const { acreage, state } = field.features[0].properties;
    const targetDensity = state === WISCONSIN ? MIN_DENSITY_590_WI : MIN_DENSITY_590_IA_MN;

    const nutrientZones = isProScan
      ? zones.features.filter((zone) => zone.properties?.zone_type === ZONE_TYPES.POINT)
      : zones.features;
    const planDensity = getDensity(nutrientZones.length, acreage);

    return planDensity <= targetDensity;
  }
  return true;
};
