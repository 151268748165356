import { combineReducers } from 'redux';

import { AgencyReducer } from 'store/agencies/reducer';
import { analyticsReducer } from 'store/analytics/reducer';
import attributesReducer from 'store/attributes/reducer';
import BatchReducer from 'store/batches/reducer';
import { CatalogReducer } from 'store/catalogs/reducer';
import { chemistryResultsReducer } from 'store/chemistryResults/reducer';
import { TerritoryReducer } from 'store/commercialTerritories/reducer';
import controlsReducer from 'store/controls/reducer';
import cropPlanningReducer from 'store/cropPlans/reducer';
import { CurrencyReducer } from 'store/currencyConversion/reducers';
import DNAConcentrationsReducer from 'store/dnaConcentration/reducer';
import { featureFlagsReducer } from 'store/featureFlags/reducers';
import { FieldEventsReducer } from 'store/fieldEvents/reducers';
import { fieldGeometryReducer } from 'store/fields/reducers';
import { heatMapReducer } from 'store/heatMap/reducer';
import indexSetReducer from 'store/indexSets/reducer';
import { inputsReducer } from 'store/inputs/reducer';
import { JobsReducer } from 'store/jobs/reducers';
import { LabSamplesReducer } from 'store/labSamples/reducer';
import { LabShipmentReducer } from 'store/labShipments/reducer';
import { naptControlsReducer } from 'store/naptControls/reducer';
import { operationReducer } from 'store/operation/reducers';
import { OrderHistoryReducer } from 'store/orderHistory/reducer';
import { paymentsReducer } from 'store/payments/reducers';
import { PlateReducer } from 'store/plates/reducer';
import { PrescriptionZoneReducer } from 'store/prescriptions/reducer';
import { RecommendationsReducer } from 'store/recommendations/reducer';
import { RecommendationSetReducer } from 'store/recommendationSets/reducer';
import RoleReducer from 'store/roles/reducers';
import { SamplePlanReducer } from 'store/samplePlans/reducer';
import { SamplePricesReducer } from 'store/samplePrices/reducer';
import { SampleReducer } from 'store/samples/reducer';
import { SamplingHistoryReducer } from 'store/samplingHistory/reducer';
import { TimingReducer } from 'store/samplingTimings/reducer';
import { ShippingReducer } from 'store/shipping/reducer';
import { stateGeometryReducer } from 'store/states/reducers';
import { UserReducer } from 'store/user/reducers';

const rootReducer = combineReducers({
  agencies: AgencyReducer,
  analytics: analyticsReducer,
  attributes: attributesReducer,
  batches: BatchReducer,
  catalogs: CatalogReducer,
  chemistryResults: chemistryResultsReducer,
  controls: controlsReducer,
  cropPlanning: cropPlanningReducer,
  currency: CurrencyReducer,
  DNAConcentrations: DNAConcentrationsReducer,
  featureFlags: featureFlagsReducer,
  fieldEvents: FieldEventsReducer,
  fieldGeometry: fieldGeometryReducer,
  stateGeometry: stateGeometryReducer,
  heatMap: heatMapReducer,
  indexSets: indexSetReducer,
  inputs: inputsReducer,
  jobs: JobsReducer,
  // @ts-ignore
  labSamples: LabSamplesReducer,
  labShipments: LabShipmentReducer,
  naptControls: naptControlsReducer,
  operations: operationReducer,
  orderHistory: OrderHistoryReducer,
  payments: paymentsReducer,
  plates: PlateReducer,
  prescriptionZones: PrescriptionZoneReducer,
  roles: RoleReducer,
  recommendations: RecommendationsReducer,
  recommendationSets: RecommendationSetReducer,
  samples: SampleReducer,
  samplePlans: SamplePlanReducer,
  samplingHistory: SamplingHistoryReducer,
  timings: TimingReducer,
  user: UserReducer,
  shipping: ShippingReducer,
  samplePrices: SamplePricesReducer,
  commercialTerritories: TerritoryReducer,
});

export default rootReducer;
