import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getIndexSets } from 'store/indexSets/thunks';
import { Button, Header, Table, TableCol, TableRow } from 'common';

import styles from './Container.module.css';

const IndexSetDetail = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indexSetName } = useParams<{ indexSetName: string }>();
  const { hasFetched, hasFailed, indexSet } = useSelector((state: RootState) => ({
    hasFetched: state.indexSets.hasFetched,
    hasFailed: state.indexSets.hasFailed,
    indexSet: indexSetName ? state.indexSets.setsByName[indexSetName] : [],
  }));
  const setName = indexSet?.length ? indexSet[0].name : NOT_APPLICABLE;

  useEffect(() => {
    if (!indexSet?.length && !hasFetched && !hasFailed) {
      dispatch(getIndexSets());
    }
  }, [dispatch, indexSet, hasFetched, hasFailed]);

  // Fetch index sets and filter them by name
  return (
    <div className={styles.Wrapper}>
      <Button onClick={() => navigate(-1)} className={styles.GoBack}>
        {getString('backToList', language)}
      </Button>
      <Header title={<div className={styles.Header}>{setName}</div>} />
      <Table className={styles.Table}>
        <TableRow header className={styles.TableHeader}>
          <TableCol>{getString('well', language)}</TableCol>
          <TableCol>i7_index_id</TableCol>
          <TableCol>index</TableCol>
          <TableCol>rc_index</TableCol>
          <TableCol>i5_index_id</TableCol>
          <TableCol>index2</TableCol>
          <TableCol>rc_index2</TableCol>
        </TableRow>
        {indexSet?.map((item) => {
          return (
            <TableRow key={item.id}>
              <TableCol>
                {item.row}
                {item.column}
              </TableCol>
              <TableCol>{item.i7_index_id}</TableCol>
              <TableCol>{item.index}</TableCol>
              <TableCol>{item.rc_index}</TableCol>
              <TableCol>{item.i5_index_id}</TableCol>
              <TableCol>{item.index2}</TableCol>
              <TableCol>{item.rc_index2}</TableCol>
            </TableRow>
          );
        })}
      </Table>
    </div>
  );
};

export default IndexSetDetail;
