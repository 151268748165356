import { AppThunk } from 'store';

import {
  receiveAllShippingAddresses,
  receiveShippingBatches,
  requestShippingAddresses,
  requestShippingBatches,
  shippingRequestError,
} from './actions';
import { requestAllShippingAddresses, requestBatchLabels } from './requests';

export const getAllShippingAddresses = (): AppThunk => async (dispatch) => {
  dispatch(requestShippingAddresses());
  try {
    const response = await requestAllShippingAddresses();
    return dispatch(receiveAllShippingAddresses(response));
  } catch (e) {
    return dispatch(shippingRequestError());
  }
};

export const getShippingBatches = (): AppThunk => async (dispatch) => {
  dispatch(requestShippingBatches());
  try {
    const response = await requestBatchLabels();
    return dispatch(receiveShippingBatches(response));
  } catch (e) {
    return dispatch(shippingRequestError());
  }
};
