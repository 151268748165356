import { AppThunk } from 'store';

import {
  featureFlagRequestError,
  receiveFeatureFlag,
  receiveFeatureFlags,
  requestFeatureFlags,
} from './actions';
import { getFeatureFlags, postFeatureFlag, putFeatureFlag } from './requests';
import { FeatureFlagType } from './types';

export const createFeatureFlag =
  (body: FeatureFlagType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestFeatureFlags);
      const flag = await postFeatureFlag(body);
      dispatch(receiveFeatureFlag(flag));
    } catch (error) {
      dispatch(featureFlagRequestError('Failed to create feature flag. Try again later.'));
    }
  };

export const editFeatureFlag =
  (id: number, body: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestFeatureFlags);
      const flag = await putFeatureFlag(id, body);
      dispatch(receiveFeatureFlag(flag));
    } catch (error) {
      dispatch(featureFlagRequestError('Failed to edit feature flag. Try again later.'));
    }
  };

export const getAllFeatureFlags = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestFeatureFlags);
    const flags = await getFeatureFlags();
    dispatch(receiveFeatureFlags(flags));
  } catch (error) {
    dispatch(featureFlagRequestError(error && error.message));
  }
};
