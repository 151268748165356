import React, { useMemo, useState } from 'react';
import { Box, Center, Loader } from '@mantine/core';

import { NO_DATA as NoDataText } from 'constants/defaultValues';
import { HIGH_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { ANALYTIC_DETAILS, FIELD_COMPARISON, SUMMARY, ZONE_DETAILS } from 'constants/results';

import useBroswerLanguage from 'util/hooks/useLanguage';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import { getFieldRisk, getResultRecommendation, roundAnalyticValue } from 'util/results';
import { capitalize } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { FieldType, RiskMapboxSample, SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import { RecommendationType } from 'store/recommendations/types';
import { Accordion, AdminAgentAccess } from 'common';

import Recommendations from '../../../common/Recommendations';

import AnalyticDetails from './AnalyticDetails';
import FieldComparison from './FieldComparison';
import Summary from './Summary';
import ZoneDetails from './ZoneDetails';

import styles from './SingleAnalytic.module.css';

type FormPropsType = {
  operation: OperationType;
  field: FieldType;
  planAnalytic: SingleAnalyticType;
  samplingPlan: SamplingPlanType;
  activeAnalytic: AnalyticType;
  samples: RiskMapboxSample[];
  isFieldsLoading: boolean;
  analyticAccordionsOpen: string[];
  toggleAccordion: (arg0: string) => void;
  analyticCategories: string[];
  recommendations: RecommendationType[];
};

const SingleAnalytic = ({
  operation,
  field,
  samplingPlan,
  activeAnalytic,
  samples,
  isFieldsLoading,
  analyticAccordionsOpen,
  toggleAccordion,
  planAnalytic,
  analyticCategories,
  recommendations,
}: FormPropsType) => {
  const language = useBroswerLanguage();
  const [fieldComparisonComp, setFieldComparisonComp] = useState(' ');
  const [editRecommendation, toggleEditRecommendation] = useState(false);

  const risk = getFieldRisk(planAnalytic, samplingPlan, activeAnalytic);

  const recommendation = getResultRecommendation(
    recommendations,
    activeAnalytic.id,
    planAnalytic.risk_level,
  ) as RecommendationType;

  const { allFieldGeometries } = useOperationFieldGeometries(operation);

  const operationFields = useMemo(() => {
    if (!operation) {
      return [];
    }
    const otherFields = operation.fields.filter(
      (single) => single.id !== field.features[0].properties.id,
    );
    return otherFields.map((single) => allFieldGeometries[single.id]).filter(Boolean);
  }, [allFieldGeometries, field, operation]);

  const samplesAtRisk = samples.filter(
    (sample) =>
      sample.properties.riskLevelForAnalytic === MODERATE_RISK ||
      sample.properties.riskLevelForAnalytic === HIGH_RISK,
  ).length;

  return (
    <Box className={styles.SingleWrapper}>
      {editRecommendation && planAnalytic && recommendation && (
        <AdminAgentAccess>
          <Recommendations
            className={styles.EditRecommendation}
            analyticId={activeAnalytic.id}
            riskLevel={planAnalytic.risk_level}
            samplingPlan={samplingPlan}
            recommendation={recommendation}
            operation={operation}
            onClose={() => toggleEditRecommendation(false)}
          />
          <div className={styles.Overlay} />
        </AdminAgentAccess>
      )}
      <div className={styles.AccordionPrint}>
        <Accordion
          title={getString('summary', language)}
          toggleOpen={() => toggleAccordion(SUMMARY)}
          open={analyticAccordionsOpen.includes(SUMMARY)}
          placeholder={`${activeAnalytic.name}, ${getString(
            'fieldAvg',
            language,
          )}: ${roundAnalyticValue(planAnalytic.quantity)} ${planAnalytic.unit || ''}, (${capitalize(
            planAnalytic.risk_level || NoDataText,
          )} ${getString('risk', language)})`}
        >
          <Summary
            recommendation={recommendation}
            activeAnalytic={activeAnalytic}
            samples={samples}
            planAnalytic={planAnalytic}
            risk={risk}
            toggleEditRecommendation={toggleEditRecommendation}
          />
        </Accordion>
        <Accordion
          title={getString('zoneDetails', language)}
          toggleOpen={() => toggleAccordion(ZONE_DETAILS)}
          open={analyticAccordionsOpen.includes(ZONE_DETAILS)}
          placeholder={`${samplesAtRisk}/${samples.length} ${getString('zonesAtRisk', language)}`}
        >
          <ZoneDetails samples={samples} planAnalytic={planAnalytic} />
        </Accordion>
      </div>
      <Box className={styles.AccordionPrint}>
        <Accordion
          title={getString('fieldComparison', language)}
          toggleOpen={() => toggleAccordion(FIELD_COMPARISON)}
          open={analyticAccordionsOpen.includes(FIELD_COMPARISON)}
          placeholder={fieldComparisonComp}
        >
          {!isFieldsLoading ? (
            <FieldComparison
              activeAnalytic={activeAnalytic}
              samplingPlan={samplingPlan}
              currentField={field}
              operationFields={operationFields}
              setFieldComparisonComp={setFieldComparisonComp}
              analyticCategories={analyticCategories}
            />
          ) : (
            <Center h="150">
              <Loader />
            </Center>
          )}
        </Accordion>
        <Accordion
          title={getString('analyticDetails', language)}
          toggleOpen={() => toggleAccordion(ANALYTIC_DETAILS)}
          open={analyticAccordionsOpen.includes(ANALYTIC_DETAILS)}
        >
          <AnalyticDetails activeAnalytic={activeAnalytic} planAnalytic={planAnalytic} />
        </Accordion>
      </Box>
    </Box>
  );
};

export default SingleAnalytic;
