import { LinePaint } from 'mapbox-gl';

import darkBlueMarker from 'images/icons/mapMarkers/darkBlueMarker.png';
import darkRedMarker from 'images/icons/mapMarkers/darkRedMarker.png';
import darkYellowMarker from 'images/icons/mapMarkers/darkYellowMarker.png';
import highRiskMarker from 'images/icons/mapMarkers/highRiskMarker.png';
import lightBlueMarker from 'images/icons/mapMarkers/lightBlueMarker.png';
import lightRedMarker from 'images/icons/mapMarkers/lightRedMarker.png';
import lightYellowMarker from 'images/icons/mapMarkers/lightYellowMarker.png';
import lowRiskMarker from 'images/icons/mapMarkers/lowRiskMarker.png';
import moderateRiskMarker from 'images/icons/mapMarkers/moderateRiskMarker.png';
import redMarker from 'images/icons/mapMarkers/redMarker.png';

import {
  BORDER_GREY,
  DARK_BLUE,
  DRAW_DARK_BLUE,
  DRAW_DARK_GREY,
  DRAW_LIGHT_BLUE,
  DRAW_ORANGE,
  WHITE,
} from 'constants/colors';

import { DrawMode } from 'common/Maps/types';

export const MODES: Record<string, DrawMode> = {
  SELECT: 'simple_select',
  DIRECT: 'direct_select',
  DRAW_POLYGON: 'draw_polygon',
  DRAW_RECTANGLE: 'draw_rectangle',
  DRAW_POINT: 'draw_point',
  STATIC: 'static',
};

export const DRAW_MODES = [MODES.DRAW_POLYGON, MODES.DRAW_RECTANGLE, MODES.DRAW_POINT];

const MAPBOX_CONSTANTS = {
  getCursor: () => 'pointer',
  style: 'mapbox://styles/mapbox/satellite-v9',
  styleWithLabels: 'mapbox://styles/mapbox/satellite-streets-v9',
  styleOutdoors: 'mapbox://styles/mapbox/outdoors-v12',
  token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
};

export const GEOMETRY_COLLECTION = 'GeometryCollection';
export const MULTIPOLYGON = 'MultiPolygon';
export const POLYGON = 'Polygon';
export const POINT = 'Point';

export const ZONE_TYPES = {
  POLYGON: 'polygon',
  POINT: 'point',
};

export const CIRCLE = 'circle';
export const FILL = 'fill';
export const LINE = 'line';

export const FIELD_BOUNDARY = 'field-boundary';
export const FIELD_OUTLINE = 'field-outline';
export const ZONE_SELECTED = 'zone-selected';
export const PARTIAL_ANALYTICS = 'partial-analytics';
export const GROUPED_ZONES = 'grouped-zones';
export const STATIC_OUTLINE = 'gl-draw-polygon-stroke-static.cold';

export const MARKERS = 'markers';
export const MARKERS_IDS = 'markers-ids';
export const MARKERS_SELECTED = 'markers-selected';

export const DELETE = 'delete';
export const MERGE = 'merge';
export const SPLIT = 'split';

export const GROUP_MAX_OPACITY = 0.8;
export const LOW_FILL_OPACITY = 0.25;
export const MID_FILL_OPACTIY = 0.5;
export const LINE_WIDTH = 2;
export const SMALL_CIRCLE_RADIUS = 3;
export const MID_CIRCLE_RADIUS = 5;
export const LARGE_CIRCLE_RADIUS = 7;
export const SMALL_LINE_STROKE = 1;
export const MID_LINE_STROKE = 3;
export const LARGE_LINE_STROKE = 5;

export const POINT_STYLING = {
  'circle-color': DARK_BLUE,
  'circle-opacity': LOW_FILL_OPACITY,
  'circle-radius': {
    base: 1.75,
    stops: [
      [2, 0.75],
      [12, 1.5],
      [22, 600],
    ],
  },
  'circle-stroke-color': WHITE,
  'circle-stroke-opacity': MID_FILL_OPACTIY,
  'circle-stroke-width': SMALL_LINE_STROKE,
};

export const HIGHLIGHT_ZONE = {
  'fill-color': '#ffff67',
  'fill-outline-color': WHITE,
  'fill-opacity': LOW_FILL_OPACITY,
};

export const HIGHLIGHT_POINT = {
  ...POINT_STYLING,
  'circle-color': '#ffff67',
};

export const PARTIAL_ZONE = {
  'fill-outline-color': WHITE,
  'fill-color': DARK_BLUE,
  'fill-opacity': LOW_FILL_OPACITY,
};

export const PARTIAL_CIRCLE = {
  'circle-radius': POINT_STYLING['circle-radius'],
  'circle-color': DARK_BLUE,
  'circle-opacity': MID_FILL_OPACTIY,
};

export const WHITE_OUTLINE = {
  'line-color': WHITE,
  'line-width': LINE_WIDTH,
};

export const POINT_STYLING_IDS = [
  // 'gl-draw-point-inactive.cold',
  'gl-draw-point-stroke-active.cold',
  'gl-draw-point-active.cold',
  // 'gl-draw-point-static.cold',
];

export const OUTLINE_STYLING_IDS = [FIELD_OUTLINE, STATIC_OUTLINE];

export const LIGHT_GREY_LINE_PAINT: LinePaint = {
  'line-color': BORDER_GREY,
  'line-width': 2,
};

// Options to modify the drawn polygons
// prettier-ignore
export const DRAW_STYLING = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': WHITE,
      'fill-outline-color': DRAW_LIGHT_BLUE,
      'fill-opacity': LOW_FILL_OPACITY,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': DRAW_ORANGE,
      'fill-outline-color': DRAW_ORANGE,
      'fill-opacity': MID_FILL_OPACTIY,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': SMALL_CIRCLE_RADIUS,
      'circle-color': DRAW_ORANGE,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': DRAW_DARK_BLUE,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': DRAW_ORANGE,
      'line-dasharray': [0.2, 2],
      'line-width': LINE_WIDTH,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': DRAW_LIGHT_BLUE,
      'line-width': LINE_WIDTH,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': DRAW_ORANGE,
      'line-dasharray': [0.2, 2],
      'line-width': LINE_WIDTH,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': MID_CIRCLE_RADIUS,
      'circle-color': WHITE,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': SMALL_CIRCLE_RADIUS,
      'circle-color': DRAW_ORANGE,
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      ...POINT_STYLING,
      'circle-stroke-width': MID_LINE_STROKE,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': LARGE_CIRCLE_RADIUS,
      'circle-color': WHITE,
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': SMALL_CIRCLE_RADIUS,
      'circle-color': DRAW_ORANGE,
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': WHITE,
      'fill-outline-color': WHITE,
      'fill-opacity': LOW_FILL_OPACITY,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': WHITE,
      'line-width': LINE_WIDTH,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': DRAW_DARK_GREY,
      'line-width': LINE_WIDTH,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      ...POINT_STYLING,
      'circle-color': WHITE,
    },
  },
];

export const LEGEND_MAX_WIDTH = 100;

export const DEFAULT_MAX_ZOOM = 22;
// mapbox raises an error if 2 steps in an interpolation have the same value
// provide a small offset to prevent
export const COLOR_INTERPOLATION_OFFSET = 0.001;
export const MAPBOX_FIT_PARAMS = { duration: 3, padding: 30 };

export const MAP_GUTTERS = {
  default: 20,
};

export const MapMarkers = {
  DARK_BLUE_MARKER: { name: 'dark-blue-marker', image: darkBlueMarker },
  DARK_RED_MARKER: { name: 'dark-red-marker', image: darkRedMarker },
  DARK_YELLOW_MARKER: { name: 'dark-yellow-marker', image: darkYellowMarker },
  LIGHT_BLUE_MARKER: { name: 'light-blue-marker', image: lightBlueMarker },
  LIGHT_RED_MARKER: { name: 'light-red-marker', image: lightRedMarker },
  LIGHT_YELLOW_MARKER: { name: 'light-yellow-marker', image: lightYellowMarker },
  RED_MARKER: { name: 'red-marker', image: redMarker },
  HIGH_RISK_MARKER: { name: 'high-risk-marker', image: highRiskMarker },
  MODERATE_RISK_MARKER: { name: 'moderate-risk-marker', image: moderateRiskMarker },
  LOW_RISK_MARKER: { name: 'low-risk-marker', image: lowRiskMarker },
};

export default MAPBOX_CONSTANTS;
