import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { getControlDilutionType } from 'util/controls';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { disableControl, editControl, getAllControls } from 'store/controls/thunks';
import { ControlType } from 'store/controls/types';
import { Button, Header, Spinner, Table, TableCol, TableRow } from 'common';

import ControlCreation from './ControlCreation';

import styles from './Container.module.css';

const ControlsContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { controls, hasFetched } = useSelector((state: RootState) => ({
    controls: state.controls.controls,
    hasFetched: state.controls.hasFetched,
  }));

  useEffect(() => {
    dispatch(getAllControls());
  }, [dispatch]);

  if (!hasFetched && !controls.length) {
    return <Spinner fill />;
  }

  const sortedControls = controls.concat().sort((a, b) => {
    if (a.barcode < b.barcode) {
      return -1;
    }

    return 1;
  });

  const handleEnableControl = (id: any) => {
    dispatch(editControl(id, { enabled: true }));
  };

  const handleDisableControl = (id: any) => {
    dispatch(disableControl(id));
  };

  const getRow = (control: ControlType) => {
    const type = getControlDilutionType(control);
    const actionButton = control.enabled ? (
      <Button danger onClick={() => handleDisableControl(control.id)}>
        {getString('disable', language)}
      </Button>
    ) : (
      <Button onClick={() => handleEnableControl(control.id)}>
        {getString('enable', language)}
      </Button>
    );
    return (
      <TableRow key={control.barcode}>
        <TableCol>{control.barcode}</TableCol>
        <TableCol size="x2">{control.notes}</TableCol>
        <TableCol>{getString(type, language)}</TableCol>
        <TableCol>{control.dilution.zymo || NOT_APPLICABLE}</TableCol>
        <TableCol>{control.dilution.soil || NOT_APPLICABLE}</TableCol>
        <TableCol className={styles.Action}>{actionButton}</TableCol>
      </TableRow>
    );
  };

  return (
    <div className={styles.Wrapper}>
      <Header title={getString('manageControls', language)} />
      <ControlCreation />
      <Table className={styles.Table}>
        <TableRow className={styles.TableHeader}>
          <TableCol>{getString('name', language)}</TableCol>
          <TableCol size="x2">{getString('description', language)}</TableCol>
          <TableCol>{getString('type', language)}</TableCol>
          <TableCol>{getString('zymoDilution', language)}</TableCol>
          <TableCol>{getString('soilDilution', language)}</TableCol>
          <TableCol />
        </TableRow>
        {sortedControls.map(getRow)}
      </Table>
    </div>
  );
};

export default ControlsContainer;
