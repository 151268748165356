import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { byKey } from 'util/collectionUtils';
import { RootState } from 'store';
import { getAllAttributes } from 'store/attributes/thunks';
import { WellType } from 'store/plates/types';
import { Button, Input, Label, Selector } from 'common';

import styles from '../Container.module.css';

type RnDFieldSetPropsType = {
  onValueChange: (
    attributeKey: keyof WellType,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => void;
  rndAttributes: { [key: string]: any };
};

const RnDFieldSet = ({ onValueChange, rndAttributes }: RnDFieldSetPropsType) => {
  const dispatch = useDispatch();
  const [newAttributeIndex, setNewAttributeIndex] = useState(0);
  const { attributes, hasFetched } = useSelector((state: RootState) => ({
    attributes: state.attributes.attributes,
    hasFetched: state.attributes.hasFetched,
  }));

  useEffect(() => {
    if (!attributes?.length && !hasFetched) {
      dispatch(getAllAttributes());
    }
  }, [dispatch, attributes]);

  const [attributeLookupTable, controlLookupTable] = useMemo(() => {
    const attributeList = attributes.filter((attribute) => !attribute.allowed_values?.length);
    const controlList = attributes.filter((attribute) => attribute.allowed_values?.length);
    return [byKey(attributeList, 'name'), byKey(controlList, 'name')];
  }, [attributes]);

  const rndMetadata: any = rndAttributes || {};

  const makeRndAttributes = () => {
    const editKeyValue = (key: string, value: string) => {
      const newMeta = {
        ...rndMetadata,
        [key]: value,
      };
      onValueChange('rnd_meta', newMeta);
    };

    const makeInputHandler = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
      editKeyValue(key, event.target.value);

    const makeSelectorHandler = (key: string) => (index: number) => {
      const control = controlLookupTable[key];
      const value = (control.allowed_values || [])[index];
      editKeyValue(key, value);
    };

    return Object.keys(rndMetadata).map((key) => {
      if (attributeLookupTable[key]) {
        return (
          <Label className={styles.Attribute} key={key} label={key}>
            <Input onChange={makeInputHandler(key)} value={rndMetadata[key] || ''} />
          </Label>
        );
      }

      if (controlLookupTable[key]) {
        const control = controlLookupTable[key];
        const valueIndex = control.allowed_values?.indexOf(rndMetadata[key]);

        const options = control.allowed_values?.map((value: string, index: number) => ({
          id: `selector-${key}-${index}`,
          displayName: value,
        }));

        return (
          <Label className={styles.Attribute} key={key} label={key}>
            <Selector
              activeIndex={valueIndex}
              onChange={makeSelectorHandler(key)}
              options={options}
            />
          </Label>
        );
      }

      return null;
    });
  };

  const unusedAttributes = attributes
    .filter((attribute) => rndMetadata[attribute.name] === undefined)
    .map((attribute) => ({
      id: attribute.id || 0,
      displayName: attribute.name,
      allowedValues: attribute.allowed_values || '',
    }));

  const addNewAttribute = () => {
    const newAttribute = unusedAttributes[newAttributeIndex];
    const { allowedValues, displayName } = newAttribute;

    const newRndMetadata = {
      ...rndMetadata,
      [displayName]: allowedValues ? allowedValues[0] : '',
    };
    onValueChange('rnd_meta', newRndMetadata);
    setNewAttributeIndex(0);
  };

  return (
    <>
      <Label className={styles.InputLabel} label="R&D Attributes: ">
        <Selector
          className={styles.InputSelector}
          activeIndex={newAttributeIndex}
          onChange={setNewAttributeIndex}
          options={unusedAttributes}
        />
      </Label>
      <Button onClick={addNewAttribute}>Add Attribute</Button>
      {makeRndAttributes()}
    </>
  );
};

export default RnDFieldSet;
