import React, { useEffect, useState } from 'react';

import { deleteAnalyticImage, updateAnalyticImage } from 'store/analytics/requests';
import { AnalyticImageType } from 'store/analytics/types';
import { Button, Input } from 'common';
import SortableTable from 'common/SortedTable';

import styles from '../Container.module.css';

type AnalyticImagesPropsType = {
  images: AnalyticImageType[];
  onUpdate: (images: AnalyticImageType[]) => void;
  onError: (message: string) => void;
};

const AnalyticImages = ({ images, onUpdate, onError }: AnalyticImagesPropsType) => {
  const [tableData, setTableData] = useState<(AnalyticImageType & { isEditing: boolean })[]>([]);

  useEffect(() => {
    setTableData(images?.map((i) => ({ ...i, isEditing: false })));
  }, [images]);

  const updateTableRow = (
    id: number,
    attr: keyof AnalyticImageType | 'isEditing',
    value: string | boolean,
  ) => {
    const rowIdx = tableData.findIndex((r) => r.id === id);
    const row = { ...tableData[rowIdx], [attr]: value };
    setTableData(
      tableData.map((r) => {
        return r.id === id ? row : r;
      }),
    );
  };

  const createHandleUpdate =
    (attr: keyof AnalyticImageType | 'isEditing', id: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateTableRow(id, attr, e.target.value);
    };

  const handleImageDelete = async (image: AnalyticImageType) => {
    try {
      await deleteAnalyticImage(image);
      onUpdate(tableData);
    } catch (e) {
      onError('Error removing image');
    }
  };

  const handleUpdate = async (image: AnalyticImageType) => {
    try {
      await updateAnalyticImage(image);
      onUpdate(tableData);
    } catch (e) {
      onError('Error updating image');
    }
  };

  return (
    <SortableTable
      columns={[
        {
          name: 'Image',
          attribute: 'id',
          sortable: false,
          cellFormatter: (id, image) => (
            <div className={styles.AnalyticImage}>
              <a href={image.url} target="_blank" rel="noopener noreferrer">
                <img alt={image.alt_text} src={image.url} height="50" />
              </a>
            </div>
          ),
        },
        {
          name: 'Image Label',
          attribute: 'alt_text',
          sortable: true,
          cellFormatter: (altText, image) =>
            image.isEditing ? (
              <Input onChange={createHandleUpdate('alt_text', image.id)} value={altText} />
            ) : (
              altText
            ),
        },
        {
          name: 'Source',
          attribute: 'source',
          sortable: true,
          cellFormatter: (source, image) =>
            image.isEditing ? (
              <Input onChange={createHandleUpdate('source', image.id)} value={source} />
            ) : (
              source
            ),
        },
        {
          name: 'Actions',
          attribute: 'id',
          sortable: false,
          cellFormatter: (id, image) =>
            !image.isEditing ? (
              <>
                <Button
                  onClick={() => {
                    updateTableRow(id, 'isEditing', true);
                  }}
                >
                  Edit
                </Button>
                <Button onClick={() => handleImageDelete(image)}>Delete</Button>
              </>
            ) : (
              <>
                <Button primary onClick={() => handleUpdate(image)}>
                  Save
                </Button>
                <Button
                  onClick={() => {
                    updateTableRow(id, 'isEditing', false);
                  }}
                >
                  Cancel
                </Button>
              </>
            ),
        },
      ]}
      data={tableData}
      title="Analytic Images"
      initialSortBy="id"
    />
  );
};

export default AnalyticImages;
