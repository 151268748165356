import { FaExclamationCircle } from 'react-icons/fa';
import { Alert } from '@mantine/core';

import { UI_COLORS } from 'constants/colors';

import { useTranslations } from 'strings/translation';

type Props = {
  title?: React.ReactNode;
  content?: React.ReactNode;
};

const ErrorAlert = ({ title, content }: Props) => {
  const { error: defaultTitle } = useTranslations(['error']);

  return (
    <Alert
      variant="light"
      color={UI_COLORS.error}
      title={title || defaultTitle}
      icon={<FaExclamationCircle />}
    >
      {content}
    </Alert>
  );
};

export default ErrorAlert;
