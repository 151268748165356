import {
  NAPT_CONTROLS_REQUEST_ERROR,
  NaptControlsActionType,
  NaptControlType,
  RECEIVE_NAPT_CONTROLS,
  REQUEST_NAPT_CONTROLS,
} from './types';

export const requestNaptControls = (): NaptControlsActionType => ({
  type: REQUEST_NAPT_CONTROLS,
});

export const receiveNaptControls = (controls: NaptControlType[]): NaptControlsActionType => {
  return {
    type: RECEIVE_NAPT_CONTROLS,
    payload: {
      controls,
    },
  };
};

export const naptControlRequestError = (payload: { message: string }): NaptControlsActionType => ({
  type: NAPT_CONTROLS_REQUEST_ERROR,
  payload,
});
