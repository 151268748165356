import React, { useMemo, useState } from 'react';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { capitalize, middleEllipsis } from 'util/stringUtils';
import { EVENT_TYPES, FieldEventType } from 'store/fieldEvents/types';
import { FieldType } from 'store/fields/types';
import { OperationFieldType, OperationType } from 'store/operation/types';
import { Button, FieldThumbnail, TableCol, TableRow, TooltipWrapper } from 'common';

import ChangeFieldModal from './ChangeFieldModal';

import styles from './FileEditor.module.css';

type FieldEditorRowPropsType = {
  event: FieldEventType;
  operation: OperationType;
  fieldGeometries: { [id: number]: FieldType };
  excludedIds: number[];
  updateEvent: (event: FieldEventType, newFieldId: number, newFieldName?: string) => void;
  onInclude: () => void;
  onExclude: () => void;
};

const FieldEditorRow = ({
  event,
  operation,
  fieldGeometries,
  excludedIds,
  onInclude,
  onExclude,
  updateEvent,
}: FieldEditorRowPropsType) => {
  const [showChangeModal, toggleShowChangeModal] = useState(false);

  const fieldsById = useMemo(
    () =>
      operation.fields.concat(operation.inactive_fields).reduce(
        (result, field) => ({
          ...result,
          [field.id]: field,
        }),
        {},
      ) as { [id: number]: OperationFieldType },
    [operation],
  );
  const fieldGeometry = useMemo(() => fieldGeometries[event.field_id], [fieldGeometries, event]);

  const handleChange = (newId: number, newFieldName?: string) => {
    updateEvent(event, newId, newFieldName);
  };

  return (
    <TableRow key={event.id}>
      <TableCol size="x2">
        <div className={styles.FieldSelector}>
          {fieldGeometry?.features[0].geometry && (
            <div className={styles.Thumbnail}>
              <FieldThumbnail features={fieldGeometry} />
            </div>
          )}
          <TooltipWrapper
            content={fieldsById[event.field_id].name}
            tooltipClassName={styles.Tooltip}
          >
            {middleEllipsis(fieldsById[event.field_id].name, 25)}
          </TooltipWrapper>
        </div>
      </TableCol>
      <TableCol size="x2">
        <TooltipWrapper content={event.shape_file_name} tooltipClassName={styles.Tooltip}>
          {middleEllipsis(event.shape_file_name, 25)}
        </TooltipWrapper>
      </TableCol>
      <TableCol>
        {event.event_type === EVENT_TYPES.APPLICATION
          ? 'Application'
          : capitalize(event.event_type)}
      </TableCol>
      <TableCol>
        {event.started_at ? new Date(event.started_at).getFullYear() : NOT_APPLICABLE}
      </TableCol>
      <TableCol size="x2">
        <div className={styles.ActionButtons}>
          <Button onClick={() => toggleShowChangeModal(true)}>Change Field</Button>
          {excludedIds.includes(event.id) ? (
            <Button onClick={onInclude}>Include</Button>
          ) : (
            <Button danger onClick={onExclude}>
              Remove
            </Button>
          )}
        </div>
      </TableCol>
      {showChangeModal && (
        <ChangeFieldModal
          currentFieldId={event.field_id}
          fields={operation.fields}
          onChange={(newId, newFieldName) => {
            handleChange(newId, newFieldName);
            toggleShowChangeModal(false);
          }}
          onCancel={() => {
            toggleShowChangeModal(false);
          }}
        />
      )}
    </TableRow>
  );
};

export default FieldEditorRow;
