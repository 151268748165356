import { AppThunk } from 'store';

import {
  chemistryResultsRequestError,
  receiveChemistryResults,
  requestChemistryResults,
} from './actions';
import { requestChemistryResultsForSample } from './requesters';

const getChemistryResults =
  (barcode: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestChemistryResults());
    try {
      const response = await requestChemistryResultsForSample(barcode);
      return dispatch(receiveChemistryResults(barcode, response.chemistry_results));
    } catch (e) {
      return dispatch(chemistryResultsRequestError({ message: e.message }));
    }
  };

export default getChemistryResults;
