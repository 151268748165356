import { Link } from 'react-router-dom';
import { Button, Group, Title } from '@mantine/core';

type Props = {
  routeTo: string;
  buttonText: string;
  titleText: string;
  onClick: VoidFunction;
};

const BasicPopupContent = ({ routeTo, buttonText, titleText, onClick }: Props) => {
  return (
    <>
      <Title mb="lg" order={3} component="h2">
        {titleText}
      </Title>
      <Group justify="center">
        <Button component={Link} to={routeTo} onClick={onClick}>
          {buttonText}
        </Button>
      </Group>
    </>
  );
};

export default BasicPopupContent;
