import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Group, Menu } from '@mantine/core';

import { projectsRoutes } from 'constants/routes';

import { useProjectsTranslations } from '../hooks';

import SamplingPlanDownloadMenu from './SamplingPlanDownloadMenu';

type Props = {
  carbonSamplingPlanId: number;
  projectId: number;
};

const ManagePlanFeaturesMenu = ({ carbonSamplingPlanId, projectId }: Props) => {
  const translations = useProjectsTranslations();

  return (
    <Group gap="sm" justify="end">
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button rightSection={<FiChevronDown />}>{translations.manageFeatures}</Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            component={Link}
            tt="capitalize"
            to={projectsRoutes.routes.samplingPlanCollections(projectId, carbonSamplingPlanId)}
          >
            {translations.collections}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <SamplingPlanDownloadMenu samplingPlanId={carbonSamplingPlanId} />
    </Group>
  );
};

export default ManagePlanFeaturesMenu;
