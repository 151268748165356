import {
  FeatureFlagsActionType,
  FeatureFlagsState,
  FeatureFlagType,
  RECEIVE_FEATURE_FLAG,
  RECEIVE_FEATURE_FLAGS,
  REQUEST_FEATURE_FLAGS,
  REQUEST_FEATURE_FLAGS_ERROR,
} from './types';

export const initialState: FeatureFlagsState = {
  errorMessage: null,
  flags: [],
  hasFetched: false,
  isFetching: false,
};

const processNewFlag = (flags: FeatureFlagType[], newFlag: FeatureFlagType): FeatureFlagType[] => {
  const duplicatesRemoved = flags.filter((flag) => flag.id !== newFlag.id);
  return duplicatesRemoved.concat(newFlag);
};

export const featureFlagsReducer = (state = initialState, action: FeatureFlagsActionType) => {
  switch (action.type) {
    case RECEIVE_FEATURE_FLAG:
      return {
        ...state,
        errorMessage: null,
        flags: processNewFlag(state.flags, action.payload.featureFlag),
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_FEATURE_FLAGS:
      return {
        ...state,
        errorMessage: null,
        flags: action.payload.featureFlags,
        hasFetched: true,
        hasFailed: false,
      };
    case REQUEST_FEATURE_FLAGS:
      return {
        ...state,
        errorMessage: null,
        hasFetched: false,
        isFetching: true,
      };
    case REQUEST_FEATURE_FLAGS_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
