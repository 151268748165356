import {
  ApprovedPaymentsType,
  OpenTransactionsType,
  PAYMENTS_REQUEST_ERROR,
  PaymentsActionType,
  RECEIVE_APPROVED_PAYMENT,
  RECEIVE_APPROVED_PAYMENTS,
  RECEIVE_OPEN_TRANSACTION,
  RECEIVE_OPEN_TRANSACTIONS,
  REQUEST_PAYMENTS,
} from './types';

export type PaymentsStateType = {
  approved: {
    byId: {
      [id: string]: ApprovedPaymentsType;
    };
    items: ApprovedPaymentsType[];
    page: number;
    total: number;
  };
  open: {
    byId: {
      [id: string]: OpenTransactionsType;
    };
    items: OpenTransactionsType[];
    page: number;
    total: number;
  };
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const initialState: PaymentsStateType = {
  approved: {
    byId: {},
    items: [],
    page: 1,
    total: 1,
  },
  open: {
    byId: {},
    items: [],
    page: 1,
    total: 1,
  },
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const paymentsReducer = (
  state = initialState,
  action: PaymentsActionType,
): PaymentsStateType => {
  switch (action.type) {
    case RECEIVE_OPEN_TRANSACTIONS:
      return {
        ...state,
        open: {
          ...state.open,
          items: action.payload.open,
          page: action.payload.page,
          total: action.payload.per_page
            ? Math.ceil(action.payload.total / action.payload.per_page)
            : 1,
        },
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_OPEN_TRANSACTION:
      return {
        ...state,
        open: {
          ...state.open,
          byId: {
            [action.payload.id]: action.payload.openById,
          },
        },
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_APPROVED_PAYMENTS:
      return {
        ...state,
        approved: {
          ...state.approved,
          items: action.payload.approved,
          page: action.payload.page,
          total: action.payload.per_page
            ? Math.ceil(action.payload.total / action.payload.per_page)
            : 1,
        },
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_APPROVED_PAYMENT:
      return {
        ...state,
        approved: {
          ...state.approved,
          byId: {
            [action.payload.uuid]: action.payload.approvedById,
          },
        },
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_PAYMENTS:
      return {
        ...state,
        hasFailed: false,
        hasFetched: false,
        isFetching: true,
      };
    case PAYMENTS_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
