import { useParams } from 'react-router-dom';

import { financeRoutes } from 'constants/routes';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

export const useBillingParams = () => {
  const { approvalStatus, billingUserId } =
    useParams<Record<keyof typeof financeRoutes.routeParams, string>>();

  return {
    approvalStatus,
    billingUserId: Number(billingUserId),
  };
};

export const useBillingTranslations = () => {
  const language = useBroswerLanguage();
  return {
    openTransactions: getString('openTransactions', language),
    summary: getString('summary', language),
    adjustments: getString('adjustments', language),
    farm: getString('farm', language),
    field: getString('field', language),
    acres: getString('acres', language),
    package: getString('package', language),
    status: getString('status', language),
    completed: getString('completed', language),
    dealerPrice: getString('dealerPrice', language),
    customerPrice: getString('customerPrice', language),
    zones: getString('zones', language),
    dealer: getString('dealer', language),
    zone: getString('zone', language),
    dealerTotal: getString('dealerTotal', language),
    customerTotal: getString('customerTotal', language),
    acre: getString('acre', language),
    analysis: getString('analysis', language),
    sampling: getString('sampling', language),
    payor: getString('payor', language),
    role: getString('role', language),
    earliestFtCreation: getString('earliestFtCreation', language),
    balance: getString('balance', language),
    open: getString('open', language),
    approved: getString('approved', language),
    billing: getString('billing', language),
    activityDetails: getString('activityDetails', language),
    netValue: getString('netValue', language),
    description: getString('description', language),
    approvedBy: getString('approvedBy', language),
    type: getString('type', language),
    amount: getString('amount', language),
    createAdjustment: getString('createAdjustment', language),
    remove: getString('remove', language),
    none: getString('none', language),
    totalAdjustments: getString('totalAdjustments', language),
    completedOrders: getString('completedOrders', language),
    notInvoiced: getString('notInvoiced', language),
    totalOrders: getString('totalOrders', language),
    create: getString('create', language),
    cancel: getString('cancel', language),
    adjustmentCreatedSuccessMsg: getString('adjustmentCreatedSuccessMsg', language),
    createSalesOrders: getString('createSalesOrders', language),
    save: getString('save', language),
    financialTransactionUpdatedSuccessMsg: getString(
      'financialTransactionUpdatedSuccessMsg',
      language,
    ),
    addAdjustment: getString('addAdjustment', language),
    adjustmentRemovedSuccessMsg: getString('adjustmentRemovedSuccessMsg', language),
    deleteAdjustment: getString('deleteAdjustment', language),
    delete: getString('delete', language),
    salesOrderCreatedMsg: getString('salesOrderCreatedMsg', language),
    custom: getString('custom', language),
    createPaymentOnly: getString('createPaymentOnly', language),
    operation: getString('operation', language),
    agency: getString('agency', language),
    error: getString('error', language),
    failedSalesOrderJobs: getString('failedSalesOrderJobs', language),
    retry: getString('retry', language),
  };
};
