import React, { useState } from 'react';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabShipment, LabShipmentFormType } from 'store/labShipments/types';
import { ShippingLabel } from 'store/shipping/types';
import { Button, Container, Header, Input } from 'common';

import { UPSLabelsList } from './UPSLabelsList';

import styles from './UPSLabels.module.css';

type UPSLabelsPropsType = {
  shipment: LabShipment | LabShipmentFormType;
  addLabel: (label: string) => void;
};

export const UPSLabels = ({ shipment, addLabel }: UPSLabelsPropsType) => {
  const language = useBroswerLanguage();
  const [input, setInput] = useState('');
  const labelsList: ShippingLabel[] = shipment.shipping_labels || [];

  const handleAddLabel = async () => {
    addLabel(input);
  };

  return (
    <Container vertical>
      <Header title={getString('upsLabels', language)} underline="none" size="H2">
        <div className={styles.Row}>
          <Input
            className={styles.Input}
            value={input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
          />
          <Button onClick={() => handleAddLabel()} disabled={!input.length} primary>
            {getString('addLabel', language)}
          </Button>
        </div>
      </Header>
      <UPSLabelsList labels={labelsList} />
    </Container>
  );
};
