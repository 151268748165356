import {
  CHEMISTRY_RESULTS_REQUEST_ERROR,
  ChemistryResultActionType,
  ChemistryResultsStateType,
  RECEIVE_CHEMISTRY_RESULTS,
  REQUEST_CHEMISTRY_RESULTS,
} from './types';

export const initialState: ChemistryResultsStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  byBarcode: {},
};

export const chemistryResultsReducer = (
  state = initialState,
  action: ChemistryResultActionType,
): ChemistryResultsStateType => {
  switch (action.type) {
    case REQUEST_CHEMISTRY_RESULTS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
      };
    case RECEIVE_CHEMISTRY_RESULTS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        byBarcode: {
          ...state.byBarcode,
          [action.payload.barcode]: action.payload.results,
        },
      };
    case CHEMISTRY_RESULTS_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
