import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Button, Stack, Text } from '@mantine/core';
import { FieldSummaryRouteParams } from 'apps/Results/common/types';

import { currencyFormatFromLanguage, getAcreageUnitFromLang } from 'util/units';
import { getString } from 'strings/translation';
import { RootState } from 'store';

import { ActivateTillMapperModal } from './ActivateTillMapperModal';
import Pitches from './Pitches';

type CallToAction = 'order' | 'activate';

type Props = {
  callToAction: CallToAction;
  language: string;
  activateModalProps?: {
    acres: number;
  };
};

const getTranslatedText = (type: CallToAction, language: string, baseTillageCost: number) => {
  if (type === 'order') {
    return {
      ctaPrecursor: getString('contactYourAgronomist', language),
      title: getString('optimizeTillage', language),
    };
  }

  if (type === 'activate') {
    const formattedBaseCost = currencyFormatFromLanguage(baseTillageCost, language);
    const acresUnit = getAcreageUnitFromLang(language);
    const availableText = getString('tillMapperAvailable', language);

    return {
      ctaPrecursor: `${availableText} ${formattedBaseCost} / ${acresUnit}`,
      title: getString('turnOnTillMapper', language),
    };
  }
};

const TillageTeaserMessage = ({ callToAction, language, activateModalProps }: Props) => {
  const [activateModalIsOpen, setActivateModalIsOpen] = useState(false);
  const { operationId, fieldId, planId } = useParams<FieldSummaryRouteParams>();

  const { tillage_map_msrp } = useSelector((state: RootState) => ({
    tillage_map_msrp: state.agencies.tillage_map_msrp,
  }));

  const text = getTranslatedText(callToAction, language, tillage_map_msrp);

  if (!text) {
    return null;
  }

  const shouldShowActivation = activateModalProps && callToAction === 'activate' && planId;

  return (
    <Stack ta="center" py="xl" gap="xl">
      <Text component="h1" fw="bold" fz="h3">
        {text.title}
      </Text>
      <Pitches language={language} />
      <Text fw="bold">{text.ctaPrecursor}</Text>
      <div>
        {shouldShowActivation && (
          <>
            <Button onClick={() => setActivateModalIsOpen(true)} variant="filled">
              {getString('activateTillMapper', language)}
            </Button>
            <ActivateTillMapperModal
              open={activateModalIsOpen}
              onClose={() => setActivateModalIsOpen(false)}
              language={language}
              fieldId={Number(fieldId)}
              samplePlanId={Number(planId)}
              acres={activateModalProps.acres}
              baseTillageCost={tillage_map_msrp}
            />
          </>
        )}
        {callToAction === 'order' && (
          <Button
            to={`/zone-analysis/${operationId}/${fieldId}/${planId}`}
            component={RouterLink}
            variant="filled"
          >
            {getString('orderTillMapperNow', language)}
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default TillageTeaserMessage;
