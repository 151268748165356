import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CREATED, READY, UNASSIGNED } from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getSortedAndActivePlans } from 'util/samplePlan';
import { getString, useTranslation } from 'strings/translation';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { FieldPropertiesType, FieldType, GeometryType } from 'store/fields/types';
import { OperationFieldType, OperationType } from 'store/operation/types';
import { SamplePlanType } from 'store/samplePlans/types';
import {
  userIsAdmin,
  userIsAgencyAdmin,
  userIsAgent,
  userIsSuperAdmin,
} from 'store/user/selectors';
import { Table, TableCol, TableRow } from 'common';

import FieldsTableRow from './TableRow';

import styles from './Table.module.css';

type FieldsTablePropsType = {
  operation: OperationType;
  fields: OperationFieldType[];
  fieldGeometries: GeometryType;
  isFetching: FieldPropertiesType['id'][];
  hasFetchedList: FieldPropertiesType['id'][];
};

const FieldsTable = ({
  operation,
  fields,
  fieldGeometries,
  isFetching,
  hasFetchedList,
}: FieldsTablePropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { canSeeContractError } = useSelector((state: RootState) => ({
    canSeeContractError:
      userIsSuperAdmin(state) ||
      userIsAdmin(state) ||
      userIsAgencyAdmin(state) ||
      userIsAgent(state),
  }));

  const samplePlans = useMemo(() => {
    const operationGeometries = fields.map((field) => fieldGeometries[field.id]);
    return operationGeometries.reduce(
      (
        allPlans: {
          hasPlans: boolean;
          plans: { [id: string]: SamplePlanType[] };
        },
        field: FieldType,
      ) => {
        if (!field) {
          return allPlans;
        }
        const lastSamplingPlan = getSortedAndActivePlans(field.features[0].properties);
        return {
          plans: {
            ...allPlans.plans,
            [field.features[0].properties.id]: lastSamplingPlan,
          },
          hasPlans: true,
        };
      },
      { plans: {}, hasPlans: false },
    );
  }, [fields, fieldGeometries]);

  const getFieldsWithUpdates = () => {
    Object.keys(samplePlans.plans).forEach((planId) => {
      const plan = samplePlans.plans[planId];
      if (
        plan.length &&
        !plan[0].assigned_to_id &&
        [CREATED, UNASSIGNED, READY].includes(plan[0].sampling_status)
      ) {
        dispatch(getFieldGeometry(plan[0].field_id));
      }
    });
  };

  const billingContractNotice = (() => {
    if (!operation?.billing_user_id) {
      return <span className={styles.Red}>{getString('noBillingUserMsg', language)}</span>;
    }

    if (
      canSeeContractError &&
      operation?.billing_agency_id &&
      !operation?.has_active_agency_contract
    ) {
      return (
        <span className={styles.Orange}>{getString('agencyContractWarningMsg', language)}</span>
      );
    }
  })();

  return (
    <>
      {hasFetchedList.length !== 0 && isFetching.length === 0 && !samplePlans.hasPlans && (
        <h1 className={styles.Highlight}>
          {getString('toOrderFirstPlan', language)}
          <span className={styles.Blue}>{getString('orderSamplePlan', language)}</span>
          {getString('belowForMappedFields', language)}
        </h1>
      )}
      <Table dataTestId="field-table">
        <TableRow dataTestId="field-table-row" className={styles.FieldsTableHeader}>
          <TableCol>{useTranslation('field')}</TableCol>
          <TableCol>{useTranslation('samplePlan')}</TableCol>
          <TableCol>{useTranslation('acres')}</TableCol>
          <TableCol>{useTranslation('status')}</TableCol>
          <TableCol size="x4">{billingContractNotice}</TableCol>
        </TableRow>
        {fields.map((field) => (
          <FieldsTableRow
            key={field.id}
            field={fieldGeometries[field.id] || { features: [{ properties: field }] }}
            hasFetched={hasFetchedList.includes(field.id) && !isFetching.includes(field.id)}
            samplePlans={samplePlans.plans[field.id] || []}
            getFieldsWithUpdates={getFieldsWithUpdates}
          />
        ))}
      </Table>
    </>
  );
};

export default FieldsTable;
