import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Button } from './Button';
import { CloseIcon } from './Icon';
import { IconInput } from './IconInput';

import styles from './Typeahead.module.css';

type Option = {
  displayName: string;
  id: number | string;
};

type TypeaheadProps = {
  className?: string;
  menuClassName?: string;
  inputClassName?: string;
  onNewOption?: (value: string) => void;
  onSelect: (idx: number) => void;
  onDeselect?: () => void;
  options: Option[];
  placeholder?: string;
  dataTestId?: string;
  value?: string | null;
  waitForKeyPress?: boolean;
  clearOnSelect?: boolean;
  icon: string;
  disabled?: boolean;
  error?: boolean;
  right?: boolean;
};

export const Typeahead = React.forwardRef<HTMLInputElement, TypeaheadProps>(
  (
    {
      className,
      menuClassName,
      inputClassName,
      onNewOption,
      onSelect,
      onDeselect,
      options,
      placeholder,
      dataTestId,
      value,
      waitForKeyPress,
      clearOnSelect,
      icon,
      disabled,
      error,
      right,
    },
    forwardRef,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [selectOptions, setSelectOptions] = useState([...options]);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
      setSelectOptions(
        options.filter(
          (o) => o.displayName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
        ),
      );
    }, [options, searchValue]);

    useEffect(() => {
      if (value) {
        setSearchValue(value);
      }
    }, [value]);

    const resetSearchValue = () => {
      setSearchValue('');
      onDeselect && onDeselect();
    };

    const handleOnSelect = (item: Option) => {
      setSearchValue(clearOnSelect ? '' : item.displayName);
      setIsMenuVisible(false);
      onSelect(options.findIndex((o) => o.id === item.id));
    };

    const handleNewOption = (newValue: string) => {
      setSearchValue(newValue);
      const matchingOption = options.find((o) => o.displayName === newValue);
      if (!matchingOption && onNewOption) {
        onNewOption(newValue);
      }
    };

    const handleFocusOutside = (event: MouseEvent | FocusEvent) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        event.target instanceof Node &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleFocusOutside);
      document.addEventListener('focusin', handleFocusOutside);
      return () => {
        document.removeEventListener('mousedown', handleFocusOutside);
        document.removeEventListener('focusin', handleFocusOutside);
      };
    }, []);

    const Menu = (
      <div className={classNames(styles.Menu, menuClassName)}>
        {selectOptions.map((option) => (
          <Button
            light
            square
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              handleOnSelect(option);
            }}
            key={option.id}
          >
            {option.displayName}
          </Button>
        ))}
      </div>
    );

    return (
      <div
        data-test-id={dataTestId}
        ref={wrapperRef}
        className={classNames(styles.Wrapper, className)}
      >
        {searchValue && onDeselect && (
          <Button
            className={classNames(styles.ClearButton, right ? styles.RightSpace : '')}
            onClick={resetSearchValue}
          >
            <CloseIcon />
          </Button>
        )}
        <IconInput
          disabled={disabled}
          autoComplete="off"
          name="search"
          data-test-id="typeahead-search-input"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleNewOption((e.target as HTMLInputElement).value);
            }
          }}
          onChange={(e) => {
            setSearchValue((e.target as HTMLInputElement).value);
            if (!isMenuVisible) {
              setIsMenuVisible(true);
            }
          }}
          onFocus={() => {
            if (!waitForKeyPress || searchValue.length) {
              setIsMenuVisible(true);
            }
          }}
          value={searchValue}
          placeholder={placeholder}
          ref={forwardRef}
          icon={icon}
          labelClassName={styles.Input}
          className={inputClassName}
          error={error}
          right={right}
        />
        {isMenuVisible && selectOptions.length > 0 ? Menu : null}
      </div>
    );
  },
);

Typeahead.displayName = 'Typeahead';
Typeahead.defaultProps = {
  className: '',
  placeholder: '',
  onNewOption: () => {},
  dataTestId: '',
};
