import {
  JOB_REQUEST_ERROR,
  JobActionType,
  JobType,
  RECEIVE_JOB,
  RECEIVE_JOBS,
  REQUEST_JOBS,
} from './types';

export type JobsStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  jobsByOperation: {
    [id: number]: JobType[];
  };
};

export const initialState: JobsStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  jobsByOperation: {},
};

const jobUpdateHelper = (jobs: JobType[], newJob: JobType) => {
  const filteredJobs = jobs.filter((job) => job.id !== newJob.id);
  return filteredJobs.concat(newJob);
};

export const JobsReducer = (state = initialState, action: JobActionType): JobsStateType => {
  switch (action.type) {
    case REQUEST_JOBS:
      return {
        ...state,
        hasFailed: false,
        hasFetched: false,
        isFetching: true,
      };
    case RECEIVE_JOBS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        jobsByOperation: {
          ...state.jobsByOperation,
          [action.payload.operation_id]: action.payload.jobs,
        },
      };
    case RECEIVE_JOB:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        jobsByOperation: {
          ...state.jobsByOperation,
          [action.payload.operation_id]: jobUpdateHelper(
            state.jobsByOperation[action.payload.operation_id],
            action.payload.job,
          ),
        },
      };
    case JOB_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
        jobsByOperation: {
          ...state.jobsByOperation,
          [action.payload.operation_id]: [],
        },
      };
    default:
      return state;
  }
};
