import React, {
  FunctionComponent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import cls from 'classnames';

import styles from './Table.module.css';

type TableProps = {
  className?: string;
  dataTestId?: string;
  children?: ReactNode;
};

export const Table: FunctionComponent<TableProps> = ({ className, children, dataTestId }) => {
  return (
    <div className={cls(styles.Table, className)} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

Table.defaultProps = {
  className: '',
  dataTestId: '',
};

type TableRowProps = {
  borderless?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
  onHover?: () => ReactElement | ReactElement[];
  header?: boolean;
  dataTestId?: string;
  children?: ReactNode;
};

export const TableRow: FunctionComponent<TableRowProps> = ({
  borderless,
  className,
  onClick,
  onHover,
  header,
  children,
  dataTestId,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const timeout = useRef<any | null>(null);

  const onMouseEnter: MouseEventHandler = () => {
    if (timeout.current !== null) clearTimeout(timeout.current);
    setTooltipVisible(true);
  };

  const onMouseLeave: MouseEventHandler = () => {
    if (timeout.current !== null) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setTooltipVisible(false);
    }, 150);
  };

  return (
    <div
      onMouseEnter={onHover && onMouseEnter}
      onMouseLeave={onHover && onMouseLeave}
      className={cls(styles.TableRow, className, {
        [styles.Borderless]: borderless,
        [styles.Clickable]: !!onClick,
        [styles.Header]: header,
        [styles.Hoverable]: !!onHover,
      })}
      onClick={onClick}
      data-test-id={dataTestId}
    >
      {children}
      {onHover && tooltipVisible && <div className={styles.TooltipWrapper}>{onHover()}</div>}
    </div>
  );
};

TableRow.defaultProps = {
  borderless: false,
  className: '',
  header: false,
  dataTestId: '',
};

type TableColProps = {
  className?: string;
  size?: 'x2' | 'x3' | 'x4';
  shrink?: boolean;
  dataTestId?: string;
  children?: ReactNode;
};

export const TableCol: FunctionComponent<TableColProps> = ({
  className,
  size,
  children,
  shrink,
  dataTestId,
}) => {
  return (
    <div
      className={cls(styles.TableCol, className, size && styles[size], {
        [styles.Shrink]: shrink,
      })}
      data-test-id={dataTestId}
    >
      <div className={styles.TableColWrapper}>{children}</div>
    </div>
  );
};

TableCol.defaultProps = {
  className: '',
  shrink: false,
  dataTestId: '',
};
