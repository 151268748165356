import { Table } from '@mantine/core';

import { capitalize } from 'util/stringUtils';
import { CarbonCollection } from 'store/projects/types';

import { useProjectsTranslations } from '../hooks';

type Props = {
  carbonCollections: CarbonCollection[] | undefined;
};

const ExistingCollectionsTable = ({ carbonCollections = [] }: Props) => {
  const translations = useProjectsTranslations();

  return (
    <Table
      data={{
        head: [
          capitalize(translations.collection),
          translations.fields,
          translations.acres,
          // translations.samples
        ],
        body: carbonCollections.map(({ name, collection_fields: fields }) => {
          const acreage = fields.reduce((acc, { field }) => {
            return acc + field.acreage;
          }, 0);

          return [
            name, // TODO: delete btn (translations.deleteGate + <FaTrash />) when API ready
            fields.length,
            acreage,
            // samples?.length || 'N/A' // TODO: restore if we know it will be populated, otherwise rm
          ];
        }),
      }}
    />
  );
};

export default ExistingCollectionsTable;
