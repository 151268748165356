import {
  RECEIVE_RECOMMENDATIONS,
  RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS,
  RECOMMENDATIONS_REQUEST_ERROR,
  RecommendationsActionsType,
  RecommendationType,
  REQUEST_RECOMMENDATIONS,
} from './types';

export const receiveRecommendations = (
  recommendations: RecommendationType[],
): RecommendationsActionsType => ({
  type: RECEIVE_RECOMMENDATIONS,
  payload: {
    recommendations,
  },
});

export const receiveSamplePlanRecommendations = (
  planId: number,
  recommendations: RecommendationType[],
): RecommendationsActionsType => ({
  type: RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS,
  payload: {
    planId,
    recommendations,
  },
});

export const requestRecommendations = (): RecommendationsActionsType => ({
  type: REQUEST_RECOMMENDATIONS,
});

export const recommendationsRequestError = (): RecommendationsActionsType => ({
  type: RECOMMENDATIONS_REQUEST_ERROR,
});
