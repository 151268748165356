import { FaCheck, FaSearch } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ActionIcon, Button, Group, MultiSelect, TextInput } from '@mantine/core';
import { DataTableColumn } from 'mantine-datatable';

import { projectsRoutes } from 'constants/routes';

import { formatCalendarMonthDayYear } from 'util/date';
import { CarbonProjectFieldRowData } from 'store/projects/types';

import { useProjectsTranslations } from './hooks';
import ProjectFieldActionsMenu from './ProjectFieldActionsMenu';
import ProjectFieldThumbnail from './ProjectFieldThumbnail';

const getColumns = ({
  projectId,
  translations,
  fieldNameSearchText,
  setFieldNameSearchText,
  allOperationNames,
  filteredOperationNames,
  setFilteredOperationNames,
}: {
  projectId: number;
  allOperationNames: string[];
  fieldNameSearchText: string;
  filteredOperationNames: string[];
  setFieldNameSearchText: (query: string) => void;
  setFilteredOperationNames: (operationNames: string[]) => void;
  translations: ReturnType<typeof useProjectsTranslations>;
}): DataTableColumn<CarbonProjectFieldRowData>[] => [
  {
    accessor: 'field-thumbnail',
    title: '',
    width: 75,
    render: ({ id }) => (
      <Group justify="center">
        <ProjectFieldThumbnail fieldId={id} />
      </Group>
    ),
  },
  {
    accessor: 'name',
    filtering: fieldNameSearchText !== '',
    resizable: true,
    sortable: true,
    title: translations.field,
    width: 150,
    filter: (
      <TextInput
        label={translations.field}
        placeholder={`${translations.search} ${translations.fields}`}
        value={fieldNameSearchText}
        onChange={(e) => setFieldNameSearchText(e.currentTarget.value)}
        leftSection={<FaSearch size={16} />}
        rightSection={
          <ActionIcon
            size="sm"
            variant="transparent"
            c="dimmed"
            onClick={() => setFieldNameSearchText('')}
          >
            <MdClear size={14} />
          </ActionIcon>
        }
      />
    ),
  },
  {
    accessor: 'operation_name',
    filtering: filteredOperationNames.length > 0,
    resizable: true,
    sortable: true,
    title: translations.account,
    width: 150,
    filter: (
      <MultiSelect
        clearable
        searchable
        comboboxProps={{ withinPortal: false }}
        data={allOperationNames}
        label={translations.accounts}
        leftSection={<FaSearch size={16} />}
        onChange={setFilteredOperationNames}
        placeholder={`${translations.search} ${translations.accounts}`}
        value={filteredOperationNames}
      />
    ),
  },
  {
    sortable: true,
    accessor: 'acreage',
    width: 100,
    render: ({ acreage, acreage_unit }) => {
      return !!acreage && `${Math.floor(acreage)} ${acreage_unit}`;
    },
  },
  {
    sortable: true,
    accessor: 'created_at',
    width: 120,
    render: ({ created_at }) => formatCalendarMonthDayYear(created_at),
    title: translations.dateAdded,
  },
  {
    sortable: true,
    accessor: 'updated_at',
    width: 120,
    render: ({ updated_at }) => (updated_at ? formatCalendarMonthDayYear(updated_at) : null),
    title: translations.lastEdited,
  },
  {
    accessor: 'coordinates.lat',
    width: 100,
    title: translations.latitude,
  },
  {
    accessor: 'coordinates.lng',
    width: 100,
    title: translations.longitude,
  },
  {
    sortable: true,
    accessor: 'has_gates',
    title: translations.gates,
    width: 80,
    render: ({ has_gates }) =>
      has_gates && (
        <Group justify="center">
          <FaCheck aria-label={translations.yes} />
        </Group>
      ),
  },
  {
    accessor: 'actions',
    title: translations.actions,
    width: 100,
    render: (row) => {
      const { samplingPlanId, id } = row;

      return (
        <Group gap="sm">
          {samplingPlanId && (
            <Button
              component={Link}
              to={projectsRoutes.routes.samplingPlanField(projectId, samplingPlanId, id)}
              variant="outline"
              size="xs"
            >
              {translations.viewSamples}
            </Button>
          )}
          <ProjectFieldActionsMenu field={row} projectId={projectId} />
        </Group>
      );
    },
  },
];

export default getColumns;
