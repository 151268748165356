import { Box, Checkbox, Flex, Stack, Switch, Text } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useAllSkusQuery, useFieldQuery, usePriceEstimateQuery } from 'apps/ZoneAnalysisV3/queries';

import {
  CUSTOM_SKU_ID,
  productDescripTranslationKeys,
  SUB_PRODUCTS_LIST,
  subProductsMapping,
  TRUBIO,
  TRUNUTRIENT,
  TRUTILL,
} from 'constants/pricing';
import { NUTRIENT } from 'constants/products';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getDefaultMapFormInfo } from 'util/samplePlanV3';
import { getString } from 'strings/translation';

import AnalysisPackageSelect from './AnalysisPackageSelect';
import AnalysisProductSelect from './AnalysisProductSelect';

const getTranslations = (language: string) => ({
  analysis: getString('analysis', language),
  product: getString('product', language),
});

const AnalysisSamplingSection = () => {
  const language = useBroswerLanguage();
  const translations = getTranslations(language);
  const form = useOrderFormContext();
  const allSkusQuery = useAllSkusQuery();
  const { targetBioDensity, targetScanDensity } = usePriceEstimateQuery();
  const { data: fieldFeatureCollection } = useFieldQuery();
  const formValues = form.getValues();
  const { primaryProductId, nutrient, nutrientAnalysisPackageId, truNutrient } = formValues;
  const { canSplitDensity } = form.getTransformedValues();
  const disableNonCustom = primaryProductId !== CUSTOM_SKU_ID;
  const disableNutrientDependents = !nutrient || disableNonCustom;
  const disableLabPackage = !nutrient;
  const translationKey = productDescripTranslationKeys[primaryProductId];

  const nutrientProps = form.getInputProps('nutrient', { type: 'checkbox' });
  const truNutrientProps = form.getInputProps('truNutrient', { type: 'checkbox' });
  const truBioProps = form.getInputProps('truBio', { type: 'checkbox' });
  const truTillProps = form.getInputProps('truTill', { type: 'checkbox' });
  const splitDensityProps = form.getInputProps('isSplitDensity', { type: 'checkbox' });

  const setCustomProduct = (
    skuNameType: (typeof SUB_PRODUCTS_LIST)[number],
    isChecked: boolean,
  ) => {
    // Get the products from the selected SKUs and add them to the form
    const skuIds = SUB_PRODUCTS_LIST.filter((skuName) =>
      skuName === skuNameType ? isChecked : formValues[skuName],
    ).map((skuName) => subProductsMapping[skuName]);
    if (nutrient && nutrientAnalysisPackageId) {
      skuIds.push(nutrientAnalysisPackageId);
    }
    const selectedSkus = allSkusQuery.data?.filter((sku) => skuIds.includes(sku.id)) || [];
    form.setValues({
      ...getDefaultMapFormInfo(fieldFeatureCollection, targetBioDensity, targetScanDensity),
      primaryProductId: CUSTOM_SKU_ID,
      isProScan: selectedSkus.some((sku) => sku.is_pro),
      isTillRx: selectedSkus.some((sku) => sku.is_till_rx),
    });
  };

  return (
    <Box maw={1000}>
      <Text fz="h4" fw="bold" component="h2" mb="sm">
        {translations.analysis}
      </Text>
      <Flex justify="space-between" align="flex-start" gap="xl" mb="xl">
        <AnalysisProductSelect label={translations.product} onChange={() => {}} />
        <Text size="sm" fs="italic" flex={1}>
          {translationKey ? getString(translationKey, language) : ''}
        </Text>
      </Flex>
      <Flex gap="lg" flex={1} align="center" mb="sm">
        <Switch
          size="md"
          label="Nutrient"
          key={form.key('nutrient')}
          {...nutrientProps}
          disabled={disableNonCustom}
          onChange={(event) => {
            nutrientProps.onChange(event);
            setCustomProduct(NUTRIENT, event.currentTarget.checked);
            if (!event.currentTarget.checked && truNutrient) {
              truNutrientProps.onChange(false);
              setCustomProduct(TRUNUTRIENT, false);
            }
          }}
        />
        <Checkbox
          size="sm"
          label="TruNutrient"
          key={form.key('truNutrient')}
          {...truNutrientProps}
          disabled={disableNutrientDependents}
          onChange={(event) => {
            truNutrientProps.onChange(event);
            setCustomProduct(TRUNUTRIENT, event.currentTarget.checked);
          }}
        />
        <AnalysisPackageSelect disabled={disableLabPackage} />
      </Flex>
      <Stack display="inline-flex">
        <Switch
          size="md"
          label="TruBio"
          key={form.key('truBio')}
          {...truBioProps}
          disabled={disableNonCustom}
          onChange={(event) => {
            truBioProps.onChange(event);
            setCustomProduct(TRUBIO, event.currentTarget.checked);
          }}
        />
        <Switch
          size="md"
          label="TruTill"
          key={form.key('truTill')}
          {...truTillProps}
          disabled={disableNonCustom}
          onChange={(event) => {
            truTillProps.onChange(event);
            setCustomProduct(TRUTILL, event.currentTarget.checked);
          }}
        />
        <Checkbox
          size="sm"
          label={getString('splitDensity', language)}
          key={form.key('isSplitDensity')}
          {...splitDensityProps}
          disabled={!canSplitDensity}
          onChange={(event) => {
            splitDensityProps.onChange(event);
          }}
        />
      </Stack>
    </Box>
  );
};

export default AnalysisSamplingSection;
