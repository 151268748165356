import React, { MouseEvent } from 'react';
import { Feature, MultiPolygon } from 'geojson';

import { CommercialTerritoryType } from 'store/commercialTerritories/types';
import { User } from 'store/user/types';
import { ClickOutsideWrapper } from 'common';

import TerritoryMap from './Map';
import TerritoryUsers from './Users';

import styles from './Container.module.css';

interface Props {
  hideModal: (event: MouseEvent) => void;
  submit: (geom: Feature<MultiPolygon>) => void;
  user: User;
  userTerritory: CommercialTerritoryType;
  setUserTerritory: (territory: CommercialTerritoryType) => void;
}

const TerritoryModal = ({ hideModal, submit, userTerritory, user, setUserTerritory }: Props) => {
  return (
    <>
      <ClickOutsideWrapper className={styles.Modal} onClickOutside={hideModal}>
        <div className={styles.Wrapper}>
          <TerritoryUsers setUserTerritory={setUserTerritory} userTerritory={userTerritory} />
          <TerritoryMap
            submit={submit}
            user={user}
            userTerritory={userTerritory}
            hideModal={hideModal}
          />
        </div>
      </ClickOutsideWrapper>
      <div className={styles.Overlay} />
    </>
  );
};

export default TerritoryModal;
