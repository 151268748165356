import { Loader } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { fieldQueryOptions } from 'util/queryOptions';
import FieldThumbnail from 'common/FieldThumbnail';

type Props = {
  fieldId: number;
};

const ProjectFieldThumbnail = ({ fieldId }: Props) => {
  const fieldQuery = useQuery(fieldQueryOptions(fieldId));

  if (fieldQuery.isPending) {
    return <Loader />;
  }

  return fieldQuery.data ? <FieldThumbnail features={fieldQuery.data} /> : null;
};

export default ProjectFieldThumbnail;
