import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as EyeIcon } from 'images/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from 'images/icons/eye-off.svg';

import saveLoginToLocalStorage from 'util/auth';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { resetForgottenPassword } from 'requesters/authenticationRequester';
import { Button, Input } from 'common';

import commonStyles from '../Shared/Container.module.css';
import styles from './Container.module.css';

const PasswordResetForm = () => {
  const location = useLocation();
  const language = useBroswerLanguage();
  const [errorText, setErrorText] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');

  const token = useMemo(() => location.hash.slice(1), [location.hash]);

  const handleClick = () => {
    setIsFetching(true);
    resetForgottenPassword({ token, password })
      .then((response) => {
        saveLoginToLocalStorage(response);
        setHasSubmitted(true);
        setErrorText(null);
      })
      .catch((response) => {
        setIsFetching(false);
        setErrorText(response.message || getString('requestFailed', language));
      });
  };

  const regex = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/;

  const passwordForm = (
    <>
      <div className={styles.PasswordInputWrapper}>
        <Input
          className={styles.Input}
          onChange={(e) => setPassword(e.target.value)}
          type={isPasswordVisible ? 'test' : 'password'}
          value={password}
        />
        <Button
          className={styles.IconButton}
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          {isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
        </Button>
      </div>
      <span className={styles.SmallBlurb}>{getString('passwordReqMsg', language)}</span>
      {errorText && <span className={commonStyles.Error}>{errorText}</span>}
      <Button
        className={styles.SetPasswordButton}
        disabled={!regex.test(password) || isFetching}
        onClick={handleClick}
        primary
      >
        {getString('setPassword', language)}
      </Button>
    </>
  );
  const confirmation = (
    <>
      {getString('yourPasswordChanged', language)}
      <Link className={styles.LoginLink} to="/auth/login">
        {getString('login', language)}
      </Link>
    </>
  );

  return (
    <>
      <h1 className={styles.Title}>
        {getString('thanksForJoiningPatter', language)}
        <br />
        {getString('letsSetYourPassword', language)}:
      </h1>
      {hasSubmitted ? confirmation : passwordForm}
    </>
  );
};

export default PasswordResetForm;
