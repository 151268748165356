import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Input, Loader, Text } from '@mantine/core';

import { SAMPLE_TYPES } from 'constants/emptyWell';
import { KEYS } from 'constants/keyCodes';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { formatFloatString } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { editSampleValues } from 'store/labSamples/thunks';
import { LabSampleType } from 'store/labSamples/types';

type EditMassPropsType = {
  sample: LabSampleType;
  searchBarFocus: () => void;
};

export const EditMass = ({ sample, searchBarFocus }: EditMassPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(
    sample.arrived_at &&
      !sample.sample_weight &&
      sample.sample_type_repr === SAMPLE_TYPES.COMMERCIAL,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sampleMass, setSampleMass] = useState(String(sample.sample_weight || ''));

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const resetValues = () => {
    setSampleMass(String(sample.sample_weight || ''));
    setIsEditing(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    try {
      setIsEditing(false);
      setIsSubmitting(true);
      await dispatch(
        editSampleValues(sample.id, sample.barcode, { sample_weight: parseFloat(sampleMass) }),
      );
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const handleOnKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER) {
      await handleSubmit();
      searchBarFocus();
    }
  };

  const massDisplay = <Text>{sample.sample_weight || getString('noData', language)}</Text>;

  const massInput = (
    <Input
      ref={inputRef}
      maw="5rem"
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setSampleMass(formatFloatString(event.target.value))
      }
      onKeyUp={handleOnKeyUp}
      value={String(sampleMass)}
    />
  );

  const startEditButton = (
    <Group gap="xs" pos="relative">
      <Button
        variant="outline"
        onClick={() => setIsEditing(true)}
        disabled={!sample.arrived_at || isSubmitting}
      >
        {getString(isSubmitting ? 'submitting' : 'update', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const finishEditButtons = (
    <Group gap="xs">
      <Button variant="outline" onClick={handleSubmit} disabled={!sampleMass.length}>
        {getString('submit', language)}
      </Button>
      <Button variant="filled" color="red.8" onClick={resetValues}>
        {getString('cancel', language)}
      </Button>
    </Group>
  );

  return (
    <Group>
      <Group gap="xs" w="13.5rem" wrap="nowrap">
        <Text
          fw="700"
          style={{ textWrap: 'nowrap' }}
        >{`${getString('mass', language)} (g): `}</Text>
        {isEditing ? massInput : massDisplay}
      </Group>
      {isEditing ? finishEditButtons : startEditButton}
    </Group>
  );
};
