import React from 'react';

import { JobType } from 'store/jobs/types';
import { OperationType } from 'store/operation/types';

import JobTable from './JobTable';
import Uploader from './Uploader';

import styles from './Container.module.css';

interface UploadProps {
  cancelJob: (id: number) => void;
  operation: OperationType;
  pastJobs: JobType[];
  fetchJobs: () => void;
}

const UploadContainer = ({ fetchJobs, cancelJob, pastJobs, operation }: UploadProps) => {
  return (
    <div className={styles.Container}>
      <Uploader fetchJobs={fetchJobs} operation={operation} />
      <JobTable cancelJob={cancelJob} operation={operation} pastJobs={pastJobs} />
    </div>
  );
};

export default UploadContainer;
