import { Checkbox, Grid, Group, Select, Switch, TextInput } from '@mantine/core';
import { useProjectsTranslations } from 'apps/Projects/hooks';
import { useSamplePlanFormContext } from 'apps/Projects/SamplePlans/samplePlanFormContext';

import { METER_CORE, METER_CORE_DENSITY_OPTIONS } from 'constants/projects';

import useBroswerLanguage from 'util/hooks/useLanguage';
import acToHectares from 'util/units';
import { DefaultSamplingPlanConfigResponse, SamplePlanForm } from 'store/projects/types';
import InputAndLabelGridRow from 'common/Components/InputAndLabelGridRow';

import FormHeading from './FormHeading';

type Props = {
  defaultConfig: DefaultSamplingPlanConfigResponse | undefined;
};

type FormKey = keyof SamplePlanForm;

const ENABLE_MC_FORM_KEY: FormKey = 'hasMeterCoreDetails';
const DENSITY_FORM_KEY: FormKey = 'density';
const SPLIT_CORE_FORM_KEY: FormKey = 'isSplitCore';
const LAB_FORM_KEY: FormKey = 'lab';

const MeterCoreDetailsSection = ({ defaultConfig }: Props) => {
  const { getInputProps, key: formKey, getValues } = useSamplePlanFormContext();
  const translations = useProjectsTranslations();
  const { analytics, registry } = getValues();
  const registryConfig = defaultConfig?.[registry];
  const soilTextureInfo = registryConfig?.measurement_type_info[METER_CORE];
  const language = useBroswerLanguage();

  return (
    <div>
      <Group align="center" mb="md">
        <FormHeading text={translations.meterCoreSampleDetails} mb={0} />
        <Switch
          key={formKey(ENABLE_MC_FORM_KEY)}
          aria-label={translations.enableMeterCoreDetails}
          disabled // TODO: when backend is ready, remove this?
          {...getInputProps(ENABLE_MC_FORM_KEY, { type: 'checkbox' })}
        />
      </Group>
      <Grid align="center">
        <InputAndLabelGridRow
          elemId="density"
          label={translations.density}
          input={
            <Select
              key={formKey(DENSITY_FORM_KEY)}
              id="density"
              allowDeselect={false}
              data={METER_CORE_DENSITY_OPTIONS.map((value) => ({
                value,
                label: `1 ${translations.per} ${acToHectares(Number(value), language)} ${translations.acres}`,
              }))}
              {...getInputProps(DENSITY_FORM_KEY)}
            />
          }
        />
        <InputAndLabelGridRow
          elemId="split-core"
          label={translations.splitCore}
          input={
            <Checkbox
              key={formKey(SPLIT_CORE_FORM_KEY)}
              id="split-core"
              disabled
              {...getInputProps(SPLIT_CORE_FORM_KEY, { type: 'checkbox' })}
            />
          }
        />
        <InputAndLabelGridRow
          elemId="splits"
          disabled
          label={translations.splits}
          input={<TextInput id="splits" disabled value={soilTextureInfo?.action_name || ''} />}
        />
        <InputAndLabelGridRow
          elemId="analytics"
          disabled
          label={translations.analytics}
          input={<TextInput id="analytics" disabled value={analytics.join(', ')} />}
        />
        <InputAndLabelGridRow
          elemId="lab"
          disabled
          label={translations.lab}
          input={
            <TextInput
              key={formKey(LAB_FORM_KEY)}
              id="lab"
              disabled
              {...getInputProps(LAB_FORM_KEY)}
            />
          }
        />
      </Grid>
    </div>
  );
};

export default MeterCoreDetailsSection;
