import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Group, Menu, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import TotalFarmModal from 'apps/Operations/Common/TotalFarmModal';

import { UI_COLORS } from 'constants/colors';
import { THREE_SIXTY_PRO_SKU_ID } from 'constants/pricing';

import useBroswerLanguage from 'util/hooks/useLanguage';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import { getString, useTranslation } from 'strings/translation';
import showToast from 'actions/toastActions';
import { OrdersRouteParams, RootState } from 'store';
import { requestPostTotalFarm } from 'store/operation/requests';
import { getOperation, getOperationUsers } from 'store/operation/thunks';
import { getSamplingTimings } from 'store/samplingTimings/thunks';
import { userCanEditPlans, userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { getPatternSamplers } from 'store/user/thunks';
import { AdminAccess, Header } from 'common';
import DisplayBanner from 'common/DisplayBanner';

import OperationWithNoFields from './OperationWithNoFields/OperationWithNoFields';
import FieldsTableContainer from './OrderFields/Table';

import styles from './Container.module.css';

const FullProductReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const [totalFarmOpened, { open: openTotalFarm, close: closeTotalFarm }] = useDisclosure();
  const [isLoading, toggleIsLoading] = useState(false);

  const { operationId } = useParams<OrdersRouteParams>();
  const searchRef = useRef<HTMLInputElement>(null);
  const numericId = Number(operationId);

  const { operation, samplers, hasFailedSamplers, isAdmin, userCanEdit } = useSelector(
    (state: RootState) => ({
      operation: state.operations.operationsById[numericId],
      samplers: state.user.patternSamplers,
      hasFailedSamplers: state.user.hasFailed,
      isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
      userCanEdit: userCanEditPlans(state, numericId),
    }),
  );

  const { allFieldGeometries, isFetchingList, willFetchList, hasFetchedList, hasFailedList } =
    useOperationFieldGeometries(operation, true, false);

  const filteredFields = useMemo(() => {
    return (operation.fields || []).map((field) => {
      const fieldGeometry = allFieldGeometries[field.id];
      return fieldGeometry ? fieldGeometry.features[0].properties : field;
    });
  }, [operation, allFieldGeometries]);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(numericId)),
    [dispatch, numericId],
  );

  useEffect(() => {
    if (!operation) {
      fetchOperation();
    }
  }, [fetchOperation, operation]);

  useEffect(() => {
    dispatch(getOperationUsers(numericId));
    dispatch(getSamplingTimings());
  }, []);

  useEffect(() => {
    if (!samplers && !hasFailedSamplers && isAdmin) {
      dispatch(getPatternSamplers());
    }
  }, [samplers, hasFailedSamplers]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const dismissTotalFarm = async () => {
    try {
      toggleIsLoading(true);
      await requestPostTotalFarm({
        total_estimated_price: 0,
        total_estimated_price_per_acre: 0,
        total_estimated_list_price: 0,
        total_estimated_list_price_per_acre: 0,
        sku_price_estimates: [
          {
            sku_id: THREE_SIXTY_PRO_SKU_ID,
            estimated_price: 0,
            estimated_list_price: 0,
            estimated_price_per_acre: 0,
            estimated_list_price_per_acre: 0,
            acres: 0,
            is_active: false,
            percentage: 0,
            operation_id: numericId,
            sku_prices: [],
          },
        ],
      });
      dispatch(getOperation(operation.id));
      showToast(getString('totalFarmDismissedMsg', language), 'success');
      closeTotalFarm();
    } catch (error) {
      showToast(error.message, 'error');
    }
    toggleIsLoading(false);
  };

  const missingOperationInfo = !operation.billing_user_id;

  const getAccountBadges = () => {
    if (missingOperationInfo) {
      return (
        <Tooltip
          label={getString('noBillingUserMsg', language)}
          position="bottom"
          style={(theme) => ({
            backgroundColor: theme.colors.blue[0],
            cursor: 'pointer',
          })}
          onClick={() => navigate(`/operations/${operationId}/edit`)}
        >
          <Badge size="lg" circle color="darkRed">
            !
          </Badge>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div className={styles.Wrapper}>
      <TotalFarmModal opened={totalFarmOpened} onClose={closeTotalFarm} operation={operation} />
      <Header title={`${useTranslation('orders')} - ${operation.name}`}>
        <Group gap="xs">
          {operation.plan_acres_since_lock_in !== null && (
            <span className={styles.AcreCommit}>
              <b>{getString('acreCommit', language)}:</b>{' '}
              {Math.round(operation.plan_acres_since_lock_in)} / {operation.locked_in_acres}
            </span>
          )}
          <Button
            variant="outline"
            className={styles.Button}
            data-test-id="map-field-boundaries-button"
            onClick={() => navigate(`/field-boundary/${operationId}/new`)}
          >
            {useTranslation('addEditBoundaries')}
          </Button>
          <Button
            variant="outline"
            className={styles.Button}
            data-test-id="upload-boundaries-button"
            onClick={() => navigate(`/operations/${operationId}/ingest_history`)}
          >
            {useTranslation('uploadBoundaries')}
          </Button>
          <Menu shadow="md" trigger="hover">
            <Menu.Target>
              <Button variant="outline" data-test-id="manage-account">
                {useTranslation('manageAccount')}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                data-test-id="edit-account"
                className={missingOperationInfo ? styles.RedButton : ''}
                onClick={() => navigate(`/operations/${operationId}/edit`)}
              >
                {getString('editAccount', language)}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <AdminAccess>{getAccountBadges()}</AdminAccess>
        </Group>
      </Header>
      {!operation.is_total_farm_dismissed && userCanEdit && (
        <DisplayBanner>
          <Group justify="center">
            <Text fw="bold">{getString('totalFarm', language)}: </Text>
            <Text>{getString('totalFarmOperationMsg', language)}</Text>
            <Button bg={UI_COLORS.green} onClick={openTotalFarm}>
              {getString('activateTotalFarm', language)}
            </Button>
            <Button variant="white" loading={isLoading} onClick={dismissTotalFarm}>
              {getString('dismiss', language)}
            </Button>
          </Group>
        </DisplayBanner>
      )}
      {operation.fields?.length === 0 ? (
        <OperationWithNoFields operationId={operationId} />
      ) : (
        <FieldsTableContainer
          operation={operation}
          fields={filteredFields}
          fieldGeometries={allFieldGeometries}
          isFetching={[...isFetchingList, ...willFetchList]}
          hasFetchedList={[...hasFetchedList, ...hasFailedList]}
        />
      )}
    </div>
  );
};

export default FullProductReport;
