import React, { useEffect, useRef } from 'react';
import centroid from '@turf/centroid';
import { geoIdentity, geoPath } from 'd3-geo';
import { select } from 'd3-selection';

import { FieldType } from 'store/fields/types';
import { StateType } from 'store/states/types';

import styles from './FieldInState.module.css';

type FieldInStateProps = {
  stateGeometry: StateType;
  field: FieldType;
};
const FieldInState = ({ stateGeometry, field }: FieldInStateProps) => {
  const thumbnailRef = useRef(null);

  useEffect(() => {
    const projection = geoIdentity().reflectY(true).fitSize([150, 150], stateGeometry);
    const path = geoPath().projection(projection);

    select(thumbnailRef.current)
      .selectAll('path')
      .data(stateGeometry.features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', '#E6E6E6')
      .style('stroke', '#999999');

    const center = centroid(field);

    // Position[] to number[]...
    // @ts-ignore Parsing error: Cannot read property 'map' of undefined
    const proj = projection(center.geometry.coordinates);
    if (proj) {
      select(thumbnailRef.current)
        .append('g')
        .selectAll('circle')
        .data(center.geometry.coordinates)
        .enter()
        .append('circle')
        .attr('r', 4)
        .attr('cx', proj[0])
        .attr('cy', proj[1])
        .attr('fill', 'red');
    }
  }, [stateGeometry, field]);

  if (!stateGeometry) {
    return null;
  }

  return (
    <div className={styles.FieldCell}>
      <div className={styles.FieldThumbnail}>
        <svg width={150} height={150}>
          <g ref={thumbnailRef} />
        </svg>
      </div>
    </div>
  );
};

export default FieldInState;
