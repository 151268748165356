import { AppThunk } from 'store';
import {
  requestDeleteAttribute,
  requestGetAttributes,
  requestPostAttribute,
  requestPutAttribute,
} from 'store/attributes/requests';

import {
  attributeRequestError,
  receiveAttribute,
  receiveAttributes,
  requestAttributes,
} from './actions';
import { AttributeType } from './types';

export const getAllAttributes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestAttributes);
    const attributes = await requestGetAttributes();
    dispatch(receiveAttributes(attributes));
  } catch (e) {
    dispatch(attributeRequestError(e.message));
  }
};

export const createAttribute =
  (body: AttributeType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestAttributes);
      const attribute = await requestPostAttribute(body);
      dispatch(receiveAttribute(attribute));
    } catch (e) {
      dispatch(attributeRequestError(e.message));
    }
  };

export const disableAttribute =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestAttributes);
      await requestDeleteAttribute(id);

      // Fetch all the attributes to filter the disabled ones.
      dispatch(getAllAttributes());
    } catch (e) {
      dispatch(attributeRequestError(e.message));
    }
  };

export const editAttribute =
  (id: number, body: { enabled: boolean }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestAttributes);
      const attribute = await requestPutAttribute(id, body);
      dispatch(receiveAttribute(attribute));
    } catch (e) {
      dispatch(attributeRequestError(e.message));
    }
  };
