import { ReactNode } from 'react';
import {
  Feature,
  FeatureCollection,
  featureCollection,
  Geometry,
  GeometryCollection,
} from '@turf/helpers';
import { PrescriptionStateType } from 'apps/Results/Prescriptions/Panels/ScriptCreator/Container';
import { FillPaint, GeoJSONSource, GeoJSONSourceRaw, Map } from 'mapbox-gl';

import { K, N, P, PH } from 'constants/chemistry';
import { DARK_GREEN, PRESCRIPTION_COLORS, RISK_FILL_COLORS, WHITE } from 'constants/colors';
import { BR, US } from 'constants/countries';
import { NO_DATA, NOT_APPLICABLE } from 'constants/defaultValues';
import { NDVI_KEY } from 'constants/imagery';
import { DEFAULT_HARVEST_MAX, DEFAULT_HARVEST_MIN, YIELD_KEY } from 'constants/machineData';
import {
  AG_LEADER_RX,
  AMOUNT_KEY,
  CNH_RX,
  CUSTOM_CCE_ISU,
  CUSTOM_CCE_SDSU,
  CUSTOM_ENP_OHIO,
  CUSTOM_NV_MICHIGAN,
  CUSTOM_PRODUCT_ID,
  CUSTOM_RNP_BRAZIL,
  CUSTOM_RNV_ENM_ISU,
  CUSTOM_RNV_INDIANA,
  CUSTOM_RNV_SDSU,
  DEFAULT_CORN_TARGET_YIELD,
  DEFAULT_SOYBEAN_TARGET_YIELD,
  formulaDisplayNames,
  IMAGERY,
  INPUT_10_34_0_20_PERCENT_ID,
  ISU_REMOVAL_FORMULAS,
  JOHN_DEERE_RX,
  LIME_ISU_60,
  LIME_ISU_65,
  LIME_ISU_69,
  LIME_SDSU,
  LIME_TRI_STATE_60,
  LIME_TRI_STATE_65,
  LIME_TRI_STATE_68,
  LIME_TRI_STATE_OH_60,
  LIME_TRI_STATE_OH_65,
  LIME_TRI_STATE_OH_68,
  PERCENT,
  PHOSPHORUS_BRAZIL,
  PHOSPHORUS_ISU,
  PHOSPHORUS_SDSU_MN_BRAY,
  PHOSPHORUS_SDSU_MN_OLSEN,
  PHOSPHORUS_TRI_STATE,
  POTASSIUM_BRAZIL,
  POTASSIUM_ISU,
  POTASSIUM_SDSU_MN,
  POTASSIUM_TRI_STATE,
  PRO_PRESCRIPTIONS_NO_OUTLINE_DENSITIES,
  RAVEN_ENVIZIOPRO_RX,
  RAVEN_VIPER4_RX,
  RAVEN_VIPERPPRO_RX,
  RAW_VALUE_KEY,
  REMOVAL_K_ISU,
  REMOVAL_K_SDSU,
  REMOVAL_K_TRI_STATE,
  REMOVAL_P_ISU,
  REMOVAL_P_SDSU,
  REMOVAL_P_TRI_STATE,
  RX_TYPE_REMOVAL,
  SATURATION_BRAZIL,
  SOIL_TEST,
  YIELD,
} from 'constants/prescription';
import {
  BASE_SATURATION,
  BCSR,
  K_AMMONIUM_ACETATE_ID,
  P_BRAY_ID,
  P_OLSEN_ID,
  PH_ID,
  PHOSPHORUS_ID,
  POTASSIUM_ID,
  PRO_PRESCRIPTION_SYMBOL_BLACKLIST,
  SHAPEFILE_FORMAT,
  SOIL_ATTRIBUTES,
} from 'constants/results';
import { CORN, CORN_SILAGE, OATS, SORGHUM, SOYBEANS, WHEAT } from 'constants/variables';

import { AnalyticType } from 'store/analytics/types';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { CompositeImageryType, FieldType, MachineDataType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import { OperationType } from 'store/operation/types';
import {
  PrescriptionType,
  PrescriptionZoneType,
  RxCustomZoneFormType,
  RxCustomZoneType,
} from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';

import { getString } from '../strings/translation';

import { sortByCreatedAt } from './date';
import { convertDecimalToPercent, isNumber, roundTwoDecimal } from './numUtils';
import { capitalizeEveryWord } from './stringUtils';
import { getTonSize, getUnitBuAc, getUnitLbs, getUnitLbsAc } from './units';

export const NUTRIENTS = 'nutrients';
export const REMOVAL = 'removal';
const DEFAULT_YIELD_MAP_STEPS = [
  DEFAULT_HARVEST_MIN,
  RISK_FILL_COLORS.HIGH_RISK,
  DEFAULT_HARVEST_MAX,
  RISK_FILL_COLORS.LOW_RISK,
];

const PRESCRIPTION_YIELD_MULTIPLIER = 0.2;

const getYieldMapSteps = (prescription?: PrescriptionType) => {
  if (!(prescription?.source_layer_max && prescription?.source_layer_min)) {
    return DEFAULT_YIELD_MAP_STEPS;
  }
  return [
    prescription.source_layer_min,
    RISK_FILL_COLORS.HIGH_RISK,
    prescription.source_layer_max,
    RISK_FILL_COLORS.LOW_RISK,
  ];
};

const NDVI_STEPS = [
  0.2,
  RISK_FILL_COLORS.HIGH_RISK,
  0.4,
  RISK_FILL_COLORS.MODERATE_RISK,
  0.6,
  RISK_FILL_COLORS.LOW_RISK,
  0.8,
  DARK_GREEN,
];

export const getNdviMapSteps = (imageryData: CompositeImageryType): (string | number)[] => {
  const { max_value, mean, min_value, standard_deviation } = imageryData;
  if (mean && standard_deviation) {
    return [
      mean - 2 * standard_deviation,
      RISK_FILL_COLORS.HIGH_RISK,
      mean - standard_deviation,
      RISK_FILL_COLORS.MODERATE_RISK,
      mean,
      RISK_FILL_COLORS.LOW_RISK,
      mean + standard_deviation,
      DARK_GREEN,
    ];
  }

  if (mean && max_value && min_value) {
    return [
      min_value,
      RISK_FILL_COLORS.HIGH_RISK,
      mean,
      RISK_FILL_COLORS.LOW_RISK,
      max_value,
      DARK_GREEN,
    ];
  }

  return NDVI_STEPS;
};

export const getExportTypes = (
  language: string,
  external_connected_accounts?: OperationType['external_connected_accounts'],
) => [
  {
    id: 0,
    label: getString('downloadShpfile', language),
    displayName: getString('downloadShpfile', language),
    value: SHAPEFILE_FORMAT,
    leaf_user_uuid: null,
  },
  {
    id: 1,
    label: 'John Deere - GS 2, GS 3, Gen 4',
    displayName: 'John Deere - GS 2, GS 3, Gen 4',
    value: JOHN_DEERE_RX,
    leaf_user_uuid: null,
  },
  {
    id: 2,
    label: 'CNH Pro 700 / 1200 & Intelliview IV',
    displayName: 'CNH Pro 700 / 1200 & Intelliview IV',
    value: CNH_RX,
    leaf_user_uuid: null,
  },
  {
    id: 3,
    label: 'Ag Leader - Versa / InCommand',
    displayName: 'Ag Leader - Versa / InCommand',
    value: AG_LEADER_RX,
    leaf_user_uuid: null,
  },
  {
    id: 4,
    label: 'Raven - Viper Pro',
    displayName: 'Raven - Viper Pro',
    value: RAVEN_VIPERPPRO_RX,
    leaf_user_uuid: null,
  },
  {
    id: 5,
    label: 'Raven - Viper 4',
    displayName: 'Raven - Viper 4',
    value: RAVEN_VIPER4_RX,
    leaf_user_uuid: null,
  },
  {
    id: 6,
    label: 'Raven - Envizio Pro',
    displayName: 'Raven - Envizio Pro',
    value: RAVEN_ENVIZIOPRO_RX,
    leaf_user_uuid: null,
  },
  ...(external_connected_accounts?.accounts.map((accountName, idx) => ({
    id: idx + 7,
    label: `${getString('sendTo', language)} ${getString(accountName, language)}`,
    displayName: `${getString('sendTo', language)} ${getString(accountName, language)}`,
    value: accountName,
    leaf_user_uuid: external_connected_accounts.leaf_user_uuid,
  })) || []),
];

export const getFormulaDisplayName = (formula: string, language: string) =>
  getString(formulaDisplayNames[formula], language);

export const getTimingOptions = (language: string) => [
  {
    id: 1,
    displayName: getString('preplant', language),
    value: 'preplant',
  },
  {
    id: 2,
    displayName: getString('starter', language),
    value: 'starter',
  },
  {
    id: 3,
    displayName: getString('sidedress', language),
    value: 'sidedress',
  },
  {
    id: 4,
    displayName: getString('foliar', language),
    value: 'foliar',
  },
];

export const getCropOptions = (language: string, formula: string) => {
  const baseCrops = [
    {
      id: 1,
      displayName: getString(CORN, language),
      value: CORN,
    },
    {
      id: 2,
      displayName: getString(SOYBEANS, language),
      value: SOYBEANS,
    },
  ];
  const additionalCrops = ISU_REMOVAL_FORMULAS.includes(formula)
    ? [
        {
          id: 3,
          displayName: getString(CORN_SILAGE, language),
          value: CORN_SILAGE,
        },
        {
          id: 4,
          displayName: getString(OATS, language),
          value: OATS,
        },
        {
          id: 5,
          displayName: getString(SORGHUM, language),
          value: SORGHUM,
        },
        {
          id: 6,
          displayName: getString(WHEAT, language),
          value: WHEAT,
        },
      ]
    : [];
  return baseCrops.concat(additionalCrops);
};

export const getTillageOptions = (language: string) => [
  {
    id: 1,
    displayName: getString('conventionalTillage', language),
    value: 'conventionalTillage',
  },
  {
    id: 2,
    displayName: getString('reducedNoTill', language),
    value: 'reducedNoTill',
  },
];

export const getNutrientSelectorOptions = (rxType: string, language: string) => {
  const baseOptions = [
    {
      id: PHOSPHORUS_ID,
      value: P,
      displayName: getString('phosphorus', language),
    },
    {
      id: POTASSIUM_ID,
      value: K,
      displayName: getString('potassium', language),
    },
    {
      id: PH_ID,
      value: PH,
      displayName: getString('lime', language),
    },
  ];

  return rxType === RX_TYPE_REMOVAL
    ? baseOptions.filter((option) => option.value !== PH)
    : baseOptions;
};

const ALLOWED_TRI_STATE_OH_RX_USER_IDS = [1, 2, 422, 3568, 505, 750, 1180];

export type RxFormula = {
  id: number;
  value: string;
  label: string;
  nutrient: string;
  countryCodes: string[];
};

const getNutrientOptions = (language: string): RxFormula[] => [
  {
    id: 1,
    value: PHOSPHORUS_ISU,
    label: getFormulaDisplayName(PHOSPHORUS_ISU, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 2,
    value: LIME_ISU_60,
    label: getFormulaDisplayName(LIME_ISU_60, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 3,
    value: LIME_ISU_65,
    label: getFormulaDisplayName(LIME_ISU_65, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 4,
    value: LIME_ISU_69,
    label: getFormulaDisplayName(LIME_ISU_69, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 5,
    value: POTASSIUM_ISU,
    label: getFormulaDisplayName(POTASSIUM_ISU, language),
    nutrient: K,
    countryCodes: [US],
  },
  {
    id: 6,
    value: PHOSPHORUS_TRI_STATE,
    label: getFormulaDisplayName(PHOSPHORUS_TRI_STATE, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 7,
    value: POTASSIUM_TRI_STATE,
    label: getFormulaDisplayName(POTASSIUM_TRI_STATE, language),
    nutrient: K,
    countryCodes: [US],
  },
  {
    id: 8,
    value: LIME_TRI_STATE_60,
    label: getFormulaDisplayName(LIME_TRI_STATE_60, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 9,
    value: LIME_TRI_STATE_65,
    label: getFormulaDisplayName(LIME_TRI_STATE_65, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 10,
    value: LIME_TRI_STATE_68,
    label: getFormulaDisplayName(LIME_TRI_STATE_68, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 14,
    value: PHOSPHORUS_SDSU_MN_OLSEN,
    label: getFormulaDisplayName(PHOSPHORUS_SDSU_MN_OLSEN, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 15,
    value: PHOSPHORUS_SDSU_MN_BRAY,
    label: getFormulaDisplayName(PHOSPHORUS_SDSU_MN_BRAY, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 16,
    value: POTASSIUM_SDSU_MN,
    label: getFormulaDisplayName(POTASSIUM_SDSU_MN, language),
    nutrient: K,
    countryCodes: [US],
  },
  {
    id: 17,
    value: LIME_SDSU,
    label: getFormulaDisplayName(LIME_SDSU, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 18,
    value: SATURATION_BRAZIL,
    label: getFormulaDisplayName(SATURATION_BRAZIL, language),
    nutrient: PH,
    countryCodes: [BR],
  },
  {
    id: 19,
    value: PHOSPHORUS_BRAZIL,
    label: getFormulaDisplayName(PHOSPHORUS_BRAZIL, language),
    nutrient: P,
    countryCodes: [BR],
  },
  {
    id: 20,
    value: POTASSIUM_BRAZIL,
    label: getFormulaDisplayName(POTASSIUM_BRAZIL, language),
    nutrient: K,
    countryCodes: [BR],
  },
];

const getTriStateOHOptions = (language: string) => [
  {
    id: 11,
    value: LIME_TRI_STATE_OH_60,
    label: getFormulaDisplayName(LIME_TRI_STATE_OH_60, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 12,
    value: LIME_TRI_STATE_OH_65,
    label: getFormulaDisplayName(LIME_TRI_STATE_OH_65, language),
    nutrient: PH,
    countryCodes: [US],
  },
  {
    id: 13,
    value: LIME_TRI_STATE_OH_68,
    label: getFormulaDisplayName(LIME_TRI_STATE_OH_68, language),
    nutrient: PH,
    countryCodes: [US],
  },
];

const getRemovalOptions = (language: string) => [
  {
    id: 21,
    value: REMOVAL_P_ISU,
    label: getFormulaDisplayName(REMOVAL_P_ISU, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 22,
    value: REMOVAL_K_ISU,
    label: getFormulaDisplayName(REMOVAL_K_ISU, language),
    nutrient: K,
    countryCodes: [US],
  },
  {
    id: 23,
    value: REMOVAL_P_TRI_STATE,
    label: getFormulaDisplayName(REMOVAL_P_TRI_STATE, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 24,
    value: REMOVAL_K_TRI_STATE,
    label: getFormulaDisplayName(REMOVAL_K_TRI_STATE, language),
    nutrient: K,
    countryCodes: [US],
  },
  {
    id: 23,
    value: REMOVAL_P_SDSU,
    label: getFormulaDisplayName(REMOVAL_P_SDSU, language),
    nutrient: P,
    countryCodes: [US],
  },
  {
    id: 24,
    value: REMOVAL_K_SDSU,
    label: getFormulaDisplayName(REMOVAL_K_SDSU, language),
    nutrient: K,
    countryCodes: [US],
  },
];

const formulaFilterCreator =
  (country_code = US, nutrient = '') =>
  (option) => {
    if (option.nutrient !== nutrient) {
      return false;
    }
    return option.countryCodes.includes(country_code);
  };

export const getFormulaOptions = (
  language: string,
  field: FieldType,
  nutrient = '',
  userId?: number,
) => {
  const formulaFilter = formulaFilterCreator(
    field.features[0].properties.country_code || '',
    nutrient,
  );
  const baseList = getNutrientOptions(language).filter(formulaFilter);
  const triStateOHOptions = getTriStateOHOptions(language).filter(formulaFilter);

  const nutrientList =
    userId && ALLOWED_TRI_STATE_OH_RX_USER_IDS.includes(userId)
      ? baseList.concat(triStateOHOptions)
      : baseList;

  return {
    NUTRIENTS: nutrientList,
    REMOVAL: getRemovalOptions(language).filter(formulaFilter),
  };
};

export const getFieldCost = (
  prescription: PrescriptionType,
  input: InputType,
  field: FieldType,
  sum: number,
) => {
  const { acreage_unit } = field.features[0].properties;
  const inputCost = prescription.cost_per_ton || 0;
  const tons = sum / getTonSize(acreage_unit);
  const costPerAcre = (tons * inputCost) / (field.features[0].properties.acreage || 1);
  return `$${Math.round(costPerAcre)} / ${acreage_unit}`;
};

export const getNutrientName = (input: InputType) => {
  if (input.nutrient === N) {
    return 'Nitrogen';
  }
  if (input.nutrient === P) {
    return 'Phosphorus';
  }
  if (input.nutrient === K) {
    return 'Potassium';
  }
  return 'Lime';
};

export const getAppliedInputStrings = (prescription: PrescriptionType, language: string) => {
  if (prescription.seed) {
    return ['hybridVariety', prescription.seed.hybrid];
  }
  const nameString = 'input';
  if (prescription.agronomic_product) {
    return [
      nameString,
      prescription.agronomic_product_id === CUSTOM_PRODUCT_ID
        ? getString('customProduct', language)
        : prescription.agronomic_product.name,
    ];
  }
  if (prescription.input) {
    return [nameString, prescription.input.name];
  }
  return null;
};

export const getPrescriptionSummaryValues = (
  prescription: PrescriptionType,
  inputs: InputType[],
  field: FieldType,
  language: string,
) => {
  const input = inputs.find((input_) => input_.id === prescription.input_id);
  const formulaName = getFormulaDisplayName(prescription.formula_name, language);
  const typeName = prescription.type.replaceAll('_', ' ');
  const { acreage_unit } = field.features[0].properties;

  const appliedUnit = prescription.seed ? '' : getUnitLbs(acreage_unit);
  const appliedUnitPerAcre = prescription.seed ? '' : getUnitLbsAc(acreage_unit);

  const averageDisplay = isNumber(prescription.field_rate_average)
    ? `${roundTwoDecimal(prescription.field_rate_average)} ${appliedUnitPerAcre}`
    : NO_DATA;
  const totalApplied = isNumber(prescription.total_amount_applied)
    ? `${Math.round(prescription.total_amount_applied || 0)} ${appliedUnit}`
    : NO_DATA;
  return {
    basedOn: capitalizeEveryWord(typeName),
    expectedYield: `${prescription.expected_yield || NOT_APPLICABLE} ${getUnitBuAc(acreage_unit)}`,
    fieldCost:
      input && getFieldCost(prescription, input, field, prescription.total_amount_applied || 0),
    fieldRate: averageDisplay,
    formula: capitalizeEveryWord(formulaName || ''),
    minimumRate: `${prescription.minimum_rate || 0} ${appliedUnitPerAcre}`,
    maximumRate: prescription.maximum_rate
      ? `${prescription.maximum_rate} ${appliedUnitPerAcre}`
      : NOT_APPLICABLE,
    totalApplied,
    input: input?.name || NOT_APPLICABLE,
    range: `${Math.round(prescription.amount_range[0] || 0)}-${Math.round(prescription.amount_range[1] || 0)} ${appliedUnit}`,
  };
};

export const formatZonesWithOpacity = (zones: PrescriptionZoneType[]) => {
  const zoneAmounts = zones.map((zone) => zone.properties.amount);
  const max = Math.max(...zoneAmounts);
  return zones.map((zone) => ({
    ...zone,
    properties: {
      ...zone.properties,
      amount: Math.round(zone.properties.amount),
      fillPercent: zone.properties.amount / (max || 1),
    },
  }));
};

export const getPrescriptionAnalyticId = (prescription: PrescriptionType) => {
  if (prescription.analytic_id) {
    return prescription.analytic_id;
  }
  if (prescription.input?.nutrient === P) {
    if (prescription.formula_name === PHOSPHORUS_SDSU_MN_BRAY) {
      return P_BRAY_ID;
    }
    if (prescription.formula_name === PHOSPHORUS_SDSU_MN_OLSEN) {
      return P_OLSEN_ID;
    }
    return PHOSPHORUS_ID;
  }
  if (prescription.input?.nutrient === K) {
    if (prescription.formula_name === POTASSIUM_SDSU_MN) {
      return K_AMMONIUM_ACETATE_ID;
    }
    return POTASSIUM_ID;
  }
  if (prescription.formula_name === SATURATION_BRAZIL) {
    return BASE_SATURATION;
  }
  return PH_ID;
};

export const getSampleValueForPrescriptionAnalytic = (
  prescription: PrescriptionType,
  sample?: SampleFeatureType,
) => {
  if (prescription.analytic) {
    const { analytic } = prescription;
    return sample?.properties.analytics[analytic.category]?.[analytic.id];
  }
  const prescriptionAnalyticId = getPrescriptionAnalyticId(prescription);
  const category = prescriptionAnalyticId === BASE_SATURATION ? BCSR : SOIL_ATTRIBUTES;
  return sample?.properties.analytics[category]?.[prescriptionAnalyticId];
};

export const getZoneDollarsPerAcre = (
  rx: PrescriptionType,
  zone: PrescriptionZoneType,
  acreage_unit,
) => {
  // Formula: $/lb * lb/ac = $/ac
  const cost_per_ton = rx.cost_per_ton || 0;
  return (cost_per_ton / getTonSize(acreage_unit)) * zone.properties.amount;
};

export const sortZones = (zones: PrescriptionZoneType[]) =>
  zones.sort((a, b) => (a.properties.id > b.properties.id ? 1 : -1));

export const inputFilter = (input: InputType, nutrient: string, formula: string) => {
  if (input.customizeable && [LIME_ISU_60, LIME_ISU_65, LIME_ISU_69].includes(formula)) {
    return [CUSTOM_CCE_ISU, CUSTOM_RNV_ENM_ISU].includes(input.id);
  }
  if (input.customizeable && formula === LIME_SDSU) {
    return [CUSTOM_CCE_SDSU, CUSTOM_RNV_SDSU].includes(input.id);
  }
  if (
    [
      LIME_TRI_STATE_60,
      LIME_TRI_STATE_65,
      LIME_TRI_STATE_68,
      LIME_TRI_STATE_OH_60,
      LIME_TRI_STATE_OH_65,
      LIME_TRI_STATE_OH_68,
    ].includes(formula)
  ) {
    return [CUSTOM_RNV_INDIANA, CUSTOM_NV_MICHIGAN, CUSTOM_ENP_OHIO].includes(input.id);
  }
  if (formula === SATURATION_BRAZIL) {
    return input.id === CUSTOM_RNP_BRAZIL;
  }
  return input.nutrient === nutrient && input.id !== INPUT_10_34_0_20_PERCENT_ID;
};

export const getInputOptions = (inputs: InputType[], nutrient: string, formula: string) =>
  inputs
    .filter((input) => inputFilter(input, nutrient, formula))
    .map((input) => ({ id: input.id, displayName: input.name, value: input }));

export const convertCCEForSubmission = (input: InputType, value: number) => {
  if (input.unit === PERCENT) {
    return value / 100;
  }
  return value;
};

export const convertCCEForEditing = (input: InputType, value: number) => {
  if (input.unit === PERCENT) {
    return convertDecimalToPercent(value);
  }
  return value;
};

export const convertTargetValueForSubmission = (formula: string, value: number) => {
  if (formula === SATURATION_BRAZIL) {
    return value / 100;
  }
  return value;
};

export const convertTargetValueForEditing = (formula: string, value: number) => {
  if (formula === SATURATION_BRAZIL) {
    return convertDecimalToPercent(value);
  }
  return value;
};

export const getJobStatus = (complete: number | undefined, language: string) => {
  if (complete === 1) {
    return getString('success', language);
  }
  if (complete === 0) {
    return getString('incomplete', language);
  }
  if (complete === -1) {
    return getString('failed', language);
  }
  return '';
};

const getProPrescriptionMapColors = (prescription: PrescriptionType) => {
  const min = prescription.amount_range[0];
  const max = prescription.amount_range[1];

  if (min === max) {
    return [min, PRESCRIPTION_COLORS[4][1]];
  }

  const delta = prescription.amount_range[1] - prescription.amount_range[0];
  return PRESCRIPTION_COLORS.reduce(
    (acc, pair) => {
      // Set color steps as the percentile values between min and max rx amounts
      const percentage = pair[0];
      const color = pair[1];
      return [...acc, min + delta * percentage, color];
    },
    [] as (string | number)[],
  );
};

const addQuantityLayer = (map: Map, quantityId: string, mapId: string, key: string) => {
  map.getCanvas().style.cursor = 'default';
  map.addLayer({
    id: quantityId,
    type: 'symbol',
    source: mapId,
    layout: {
      'text-field': ['get', key],
      'text-justify': 'center',
      'text-size': 12,
    },
    paint: {
      'text-color': WHITE,
    },
  });
};

const addTooltipHandler = (
  map: Map,
  mapId: string,
  setPopupInfo: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  getPopupContent: (amount: number, rawValue?: number) => ReactNode,
) => {
  map.getCanvas().style.cursor = 'pointer';
  map.on('mousemove', mapId, (e) => {
    if (!e.features?.length) {
      setPopupInfo(null);
      return;
    }
    const amount = e.features[0].properties?.[AMOUNT_KEY];
    const rawValue = e.features[0].properties?.[RAW_VALUE_KEY];

    if (isNumber(amount)) {
      setPopupInfo({
        ...e.lngLat,
        content: getPopupContent(amount, rawValue),
      });
    }
  });
  map.on('mouseleave', mapId, () => {
    setPopupInfo(null);
  });
};

const clearTooltipHandler = (map: Map, mapId) => {
  map.on('mousemove', mapId, () => {});
};

export const addProPrescriptionToMap = (
  prescription: PrescriptionType,
  map: Map,
  mapId: string,
  setPopupInfo: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  getPopupContent: (amount: number) => ReactNode,
  key = AMOUNT_KEY,
) => {
  if (!(prescription.geojson_uri && prescription.pro_density)) {
    return;
  }
  const quantityId = `${mapId}-quantities`;

  const getMapColors = () => {
    if (key === RAW_VALUE_KEY) {
      return prescription.composite_imagery
        ? getNdviMapSteps(prescription.composite_imagery)
        : getYieldMapSteps(prescription);
    }
    return getProPrescriptionMapColors(prescription);
  };
  key === RAW_VALUE_KEY
    ? getYieldMapSteps(prescription)
    : getProPrescriptionMapColors(prescription);

  const paint: FillPaint = {
    'fill-color': ['interpolate', ['linear'], ['get', key], ...getMapColors()],
    'fill-outline-color': PRO_PRESCRIPTIONS_NO_OUTLINE_DENSITIES.includes(
      prescription.pro_density as string,
    )
      ? 'rgba(0,0,0,0)'
      : WHITE,
  };

  const shouldIncludeSymbols = !PRO_PRESCRIPTION_SYMBOL_BLACKLIST.includes(
    prescription.pro_density,
  );

  if (map.getLayer(mapId)) {
    const source = map.getSource(mapId) as GeoJSONSource;
    source.setData(prescription.geojson_uri);

    map.setPaintProperty(mapId, 'fill-color', paint['fill-color']);
    map.setPaintProperty(mapId, 'fill-outline-color', paint['fill-outline-color']);

    const quantityLayer = map.getLayer(quantityId);

    if (quantityLayer && !shouldIncludeSymbols) {
      map.removeLayer(quantityId);
    }
    if (!quantityLayer && shouldIncludeSymbols) {
      addQuantityLayer(map, quantityId, mapId, key);
    }

    if (
      !shouldIncludeSymbols ||
      prescription.machine_data_id ||
      prescription.composite_imagery_id
    ) {
      addTooltipHandler(map, mapId, setPopupInfo, getPopupContent);
    } else {
      clearTooltipHandler(map, mapId);
    }
    return;
  }
  map.addSource(mapId, {
    type: 'geojson',
    data: prescription.geojson_uri,
  });

  map.addLayer({
    id: mapId,
    type: 'fill',
    source: mapId,
    paint,
  });

  if (shouldIncludeSymbols) {
    addQuantityLayer(map, quantityId, mapId, key);
  }
  if (!shouldIncludeSymbols || prescription.machine_data) {
    addTooltipHandler(map, mapId, setPopupInfo, getPopupContent);
  }
};

export const addMachineDataToMap = (
  machineData: MachineDataType,
  map: Map,
  mapId: string,
  prescription?: PrescriptionType,
  setPopupInfo?: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  getPopupContent?: (amount: number) => ReactNode,
) => {
  map.addLayer({
    id: mapId,
    type: 'fill',
    source: {
      type: 'geojson',
      data: machineData.processed_geojson_uri,
    } as GeoJSONSourceRaw,
    paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', YIELD_KEY],
        ...getYieldMapSteps(prescription),
      ],
    },
  });
  if (setPopupInfo && getPopupContent) {
    map.on('mousemove', mapId, (e) => {
      if (!e.features?.length) {
        setPopupInfo(null);
        return;
      }
      const amount = e.features[0].properties?.[YIELD_KEY];

      if (isNumber(amount)) {
        setPopupInfo({
          ...e.lngLat,
          content: getPopupContent(amount),
        });
      }
    });
    map.on('mouseleave', mapId, () => {
      setPopupInfo(null);
    });
  }
};

export const getGenerateFromOptions = (rxType: string, language: string) => {
  if (rxType === RX_TYPE_REMOVAL) {
    return [
      {
        id: 2,
        label: getString('yieldData', language),
        value: YIELD,
      },
      {
        id: 3,
        label: getString('imagery', language),
        value: IMAGERY,
      },
    ];
  }
  return [
    {
      id: 1,
      label: getString('soilTest', language),
      value: SOIL_TEST,
    },
  ];
};

export const addImageryDataToMap = (
  imageryData: CompositeImageryType,
  map: Map,
  mapId: string,
  setPopupInfo?: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  getPopupContent?: (ndvi: number) => ReactNode,
) => {
  map.addLayer({
    id: mapId,
    type: 'fill',
    source: {
      type: 'geojson',
      data: imageryData.geojson_uri,
    } as GeoJSONSourceRaw,
    paint: {
      'fill-color': ['interpolate', ['linear'], ['get', NDVI_KEY], ...getNdviMapSteps(imageryData)],
    },
  });
  if (setPopupInfo && getPopupContent) {
    map.on('mousemove', mapId, (e) => {
      if (!e.features?.length) {
        setPopupInfo(null);
        return;
      }
      const ndvi = e.features[0].properties?.[NDVI_KEY];

      if (isNumber(ndvi)) {
        setPopupInfo({
          ...e.lngLat,
          content: getPopupContent(ndvi),
        });
      }
    });
    map.on('mouseleave', mapId, () => {
      setPopupInfo(null);
    });
  }
};

export const getDefaultCropYields = (field: FieldType, crop: string) => {
  const adjustBaseValue = (value: number) => ({
    defaultMaxYield: Math.round(value * (1 + PRESCRIPTION_YIELD_MULTIPLIER)),
    defaultMinYield: Math.round(value * (1 - PRESCRIPTION_YIELD_MULTIPLIER)),
  });

  if (field.features[0].properties.county?.yield_data?.[crop]) {
    const baseValue = field.features[0].properties.county?.yield_data?.[crop];
    return adjustBaseValue(baseValue);
  }

  const baseValue = crop === SOYBEANS ? DEFAULT_SOYBEAN_TARGET_YIELD : DEFAULT_CORN_TARGET_YIELD;
  return adjustBaseValue(baseValue);
};

export const deduplicateLayersByValue = (
  layers: {
    id: number;
    label: string;
    value: string;
  }[],
) => {
  return layers.filter(
    (layer, index, self) => index === self.findIndex((item) => item.id === layer.id),
  );
};

export const isMachineDataRxEnabled = (machineData: MachineDataType) => {
  return (
    machineData.start_time !== null &&
    machineData.processed_geojson_uri &&
    machineData.pro_densities.length !== 0
  );
};

export const isImageryRxEnabled = (imageryData: CompositeImageryType) => {
  return (
    imageryData.percentile_15 !== null &&
    imageryData.percentile_85 !== null &&
    imageryData.pro_densities.length !== 0 &&
    imageryData.geojson_uri !== null
  );
};

const DEFAULT_NUM_CUSTOM_ZONES = 3;

const getCustomZoneBuckets = (
  min: number,
  max: number,
  n = DEFAULT_NUM_CUSTOM_ZONES,
): RxCustomZoneType[] => {
  if (min === max) {
    return [
      {
        min: Math.floor(min),
        max: Math.ceil(max),
        amount: 0,
      },
    ];
  }
  if (n <= 0 || min >= max) {
    return [];
  }

  const bucketWidth = (max - min) / n;
  const bucketEdges = Array.from({ length: n + 1 }, (_, i) => min + i * bucketWidth);
  return bucketEdges.slice(0, -1).map((edge, index) => ({
    min: roundTwoDecimal(edge),
    max: roundTwoDecimal(bucketEdges[index + 1]),
    amount: 0,
  }));
};

export const getDefaultRxCustomZones = (
  samples: SampleFeatureType[],
  generateFrom: string,
  analytic?: AnalyticType | null,
  sourceLayer?: EOInferenceLayerType | MachineDataType | CompositeImageryType | null,
  sourceGeojson?: FeatureCollection<
    Geometry | GeometryCollection,
    { [RAW_VALUE_KEY]: number }
  > | null,
): RxCustomZoneType[] => {
  if (sourceGeojson) {
    const rawValues = sourceGeojson.features.map((f) => f.properties[RAW_VALUE_KEY]);
    const min = Math.min(...rawValues);
    const max = Math.max(...rawValues);
    if (isNumber(min) && isNumber(max)) {
      return getCustomZoneBuckets(min, max);
    }
  }
  if (sourceLayer) {
    const { max_value, min_value } = sourceLayer;
    if (max_value !== null && min_value !== null) {
      return getCustomZoneBuckets(min_value, max_value);
    }
  }
  if (generateFrom === SOIL_TEST && analytic) {
    const sampleValues = samples
      .map((sample) => {
        const { analytics } = sample.properties;
        return analytics[analytic.category]?.[analytic.id]?.quantity;
      })
      .filter(Boolean);
    if (sampleValues.length > 1) {
      return getCustomZoneBuckets(Math.min(...sampleValues), Math.max(...sampleValues));
    }
  }

  return [];
};

export const formatSourceLayerGeojson = (
  geojson: FeatureCollection<Geometry, Record<string, number>>,
  key: string,
): FeatureCollection<Geometry, { [RAW_VALUE_KEY]: number }> => {
  return {
    ...geojson,
    features: geojson.features
      .map((feature) => ({
        ...feature,
        properties: {
          [RAW_VALUE_KEY]: feature.properties[key],
        },
      }))
      .sort((a, b) => a.properties[RAW_VALUE_KEY] - b.properties[RAW_VALUE_KEY]),
  };
};

export const formatSamplesSourceLayer = (
  samples: SampleFeatureType[],
  activeAnalytic: AnalyticType,
) => {
  const sampleFeatures = samples
    .map((sample) => {
      const analyticQuantity =
        sample.properties.analytics[activeAnalytic.category]?.[activeAnalytic.id]?.quantity;
      return {
        ...sample,
        geometry: sample.geometry.geometries.find((geom) => geom.type === 'Polygon'),
        properties: {
          [RAW_VALUE_KEY]: analyticQuantity ?? null,
        },
      };
    })
    .filter((feature) => feature.geometry && feature.properties[RAW_VALUE_KEY] !== null) as Feature<
    Geometry,
    { [RAW_VALUE_KEY]: number }
  >[];
  const sortedFeatures = sampleFeatures.sort(
    (a, b) => a.properties[RAW_VALUE_KEY] - b.properties[RAW_VALUE_KEY],
  );

  return featureCollection(sortedFeatures);
};

export const getEqualRangeCustomZones = (customZones: RxCustomZoneType[]): RxCustomZoneType[] => {
  if (customZones.length <= 1) {
    return customZones;
  }
  const { min } = customZones[0];
  const { max } = customZones[customZones.length - 1];
  if (max === null || min == null) {
    return customZones;
  }
  const delta = (max - min) / customZones.length;

  return customZones.map((zone, index) => {
    const zoneMin = min + delta * index;
    return {
      amount: zone.amount,
      max: index === customZones.length - 1 ? max : roundTwoDecimal(zoneMin + delta),
      min: index === 0 ? min : roundTwoDecimal(zoneMin),
    };
  });
};

export const getEqualAreaCustomZones = (
  customZones: RxCustomZoneType[],
  sourceLayerGeojson: PrescriptionStateType['sourceLayerGeojson'],
): RxCustomZoneType[] => {
  if (!sourceLayerGeojson || customZones.length === 0) {
    return customZones;
  }

  const sourceValues = sourceLayerGeojson.features.map((f) => f.properties.raw_value);
  const min = Math.min(...sourceValues);
  const max = Math.max(...sourceValues);
  const numPerBucket = Math.floor(sourceValues.length / customZones.length);

  return customZones.map((zone, index) => {
    // Math.min to account for final bucket and last element of array
    const startIndex = index * numPerBucket;
    const stopIndex = Math.min((index + 1) * numPerBucket, sourceValues.length - 1);
    return {
      amount: zone.amount,
      max: index === customZones.length - 1 ? max : roundTwoDecimal(sourceValues[stopIndex]),
      min: index === 0 ? min : roundTwoDecimal(sourceValues[startIndex]),
    };
  });
};

export const validateCustomZones = (zones: RxCustomZoneType[]) =>
  zones.every((zone, index, arr) => {
    // fail check if either value is null
    if (zone.min === null || zone.max === null || zone.amount === null) {
      return false;
    }
    // fail check if min > max
    if (zone.min > zone.max) {
      return false;
    }
    // if not last in array, validate that max <= next min
    const next = arr[index + 1];
    return next ? next.min !== null && zone.max <= next.min : true;
  });

export const convertNumericCustomZonesToString = (
  zones: RxCustomZoneType[],
): RxCustomZoneFormType[] =>
  zones.map((zone) => ({
    max: zone.max?.toString() || null,
    min: zone.min?.toString() || null,
    amount: zone.amount?.toString() || null,
  }));

export const convertStringCustomZonesToNumeric = (
  zones: RxCustomZoneFormType[],
): RxCustomZoneType[] =>
  zones.map((zone) => ({
    max: isNumber(zone.max) ? Number(zone.max) : null,
    min: isNumber(zone.min) ? Number(zone.min) : null,
    amount: isNumber(zone.amount) ? Number(zone.amount) : null,
  }));

export const getDefaultRxCrop = (field: FieldType) => {
  const { crop_plans } = field.features[0].properties;
  const crop_plan = sortByCreatedAt(crop_plans || [])?.[0];
  return crop_plan?.crop || CORN;
};

export const sourceLayerIsConstantValue = (
  sourceLayerGeojson: PrescriptionStateType['sourceLayerGeojson'],
) => {
  if (!sourceLayerGeojson) {
    return false;
  }
  const rawValues = sourceLayerGeojson.features.map((f) => f.properties[RAW_VALUE_KEY]);
  return rawValues.length > 0 && rawValues.every((value) => value === rawValues[0]);
};
