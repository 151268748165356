import React from 'react';
import classNames from 'classnames';

import { CREATED } from 'constants/samplePlanning';

import { hasGeom } from 'util/field';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { OperationManagerAccess, TableCol } from 'common';

import Actions from '../common/Actions';
import FieldsTableRowCommon from '../common/FieldRow';

import styles from './TableRow.module.css';

type TableRowSamplingPlansPropsType = {
  samplingPlans: SamplingPlanType[];
  field: FieldType;
};

const TableRowSamplingPlans = ({ samplingPlans, field }: TableRowSamplingPlansPropsType) => {
  const fieldProps = field.features[0].properties;

  return (
    <>
      {samplingPlans.map((plan) => (
        <FieldsTableRowCommon
          className={styles.SamplingPlanRow}
          field={field}
          samplePlan={plan}
          hasFetched
          key={plan.id}
        >
          <TableCol className={classNames(styles.TableCol, styles.TooltipCol, styles.HideForPrint)}>
            <OperationManagerAccess>
              <Actions
                operationId={fieldProps.operation_id}
                fieldId={fieldProps.id}
                samplingPlan={plan}
                hasGeom={hasGeom(field)}
                hideEdit={plan.sampling_status !== CREATED}
              />
            </OperationManagerAccess>
          </TableCol>
        </FieldsTableRowCommon>
      ))}
    </>
  );
};

export default TableRowSamplingPlans;
