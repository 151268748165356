import React from 'react';
import { Box, Flex, Text } from '@mantine/core';

import { NDVI_KEY } from 'constants/imagery';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { REMOVAL_RATES } from 'constants/prescription';

import { isNumber } from 'util/numUtils';
import { getUnitBuAc, getUnitLbsAc } from 'util/units';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import { PrescriptionType } from 'store/prescriptions/types';

import styles from './Container.module.css';

// currying function so prescription map function doesn't need to be aware of field/language
// in broader scope
export const getPopupContent = (
  field: FieldType,
  language: string,
  prescription: PrescriptionType,
) => {
  return function PopupContent(amount: number, rawValue?: number) {
    if (isNumber(rawValue)) {
      const removalRate = REMOVAL_RATES[prescription.formula_name]?.[prescription.crop];
      return (
        <Box className={styles.Popup}>
          <Flex align="center" direction={'column'}>
            <Flex>
              <Text fw={FONT_WEIGHT_BOLD} mr="sm">
                {getString('yield', language)}:
              </Text>
              <Text>{`${Math.round(rawValue || 0)} ${getUnitBuAc(field.features[0].properties.acreage_unit)}`}</Text>
            </Flex>
            {isNumber(removalRate) && (
              <Flex>
                <Text fw={FONT_WEIGHT_BOLD} mr="sm">
                  {getString('removalRate', language)}:
                </Text>
                <Text>{`${removalRate}`}</Text>
              </Flex>
            )}
            <Flex>
              <Text fw={FONT_WEIGHT_BOLD} mr="sm">
                {getString(prescription.seed ? 'plantingRate' : 'applicationRate', language)}:
              </Text>
              <Text>{`${amount} ${getUnitLbsAc(field.features[0].properties.acreage_unit)}`}</Text>
            </Flex>
          </Flex>
        </Box>
      );
    }
    return (
      <Box className={styles.Popup}>
        <Flex align="center">
          <Text fw={FONT_WEIGHT_BOLD} mr="sm">
            {getString('amount', language)}:
          </Text>
          <Text>{`${amount} ${getUnitLbsAc(field.features[0].properties.acreage_unit)}`}</Text>
        </Flex>
      </Box>
    );
  };
};

export const getYieldPopupContent = (field: FieldType, language: string) => {
  return function PopupContent(amount: number) {
    return (
      <Box className={styles.Popup}>
        <Flex align="center">
          <Text fw={FONT_WEIGHT_BOLD} mr="sm">
            {getString('yield', language)}:
          </Text>
          <Text>{`${Math.round(amount || 0)} ${getUnitBuAc(field.features[0].properties.acreage_unit)}`}</Text>
        </Flex>
      </Box>
    );
  };
};

export const getNdviPopupContent = (field: FieldType, language: string) => {
  return function PopupContent(ndvi: number) {
    return (
      <Box className={styles.Popup}>
        <Flex align="center">
          <Text fw={FONT_WEIGHT_BOLD} mr="sm">
            {getString(NDVI_KEY, language)}:
          </Text>
          <Text>{`${(ndvi || 0).toFixed(2)}`}</Text>
        </Flex>
      </Box>
    );
  };
};
