import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAnalysisDisplayName } from 'util/analysis';
import {
  getbatchAnalysisTypeOptions,
  getBatchStatusDisplayName,
  getbatchStatusOptions,
} from 'util/batches';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getBatches } from 'store/batches/thunks';
import { FilterDropdown, Pagination, Spinner, Table, TableCol, TableRow } from 'common';

import styles from './Container.module.css';

type BatchOverviewPropsType = {
  batchTypes?: string[];
  batchStatuses?: string[];
};

export const BatchOverview = ({ batchTypes, batchStatuses }: BatchOverviewPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching, hasFetched, batches, totalPages, errorMessage } = useSelector(
    (state: RootState) => {
      return state.batches;
    },
  );
  const [filter, setFilter] = useState<{
    page: number;
    type: string[];
    status: string[];
  }>({
    page: 1,
    type: [],
    status: [],
  });

  useEffect(() => {
    dispatch(
      getBatches(
        filter.page,
        !filter.type.length && batchTypes ? batchTypes : filter.type,
        !filter.status.length && batchStatuses ? batchStatuses : filter.status,
      ),
    );
  }, [dispatch, filter, batchTypes]);

  const changeFilterValue = (value: any, key: string) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const setPage = (page: number) => {
    setFilter({ ...filter, page });
  };

  const batchTypeOptions = [
    ...(batchTypes === undefined || batchTypes.length
      ? [
          {
            id: 0,
            displayName: getString('allTypes', language),
            label: getString('allTypes', language),
            value: null,
          },
        ]
      : []),
    ...getbatchAnalysisTypeOptions(language, batchTypes),
  ];

  const batchStatusOptions = getbatchStatusOptions(
    language,
    batchStatuses,
    Boolean(batchTypes === undefined || batchTypes.length),
  );

  return (
    <div>
      <div className={styles.Filter}>
        <div className={styles.FilterLabel}>{getString('showMe', language)}</div>
        <FilterDropdown
          filter={filter.type}
          options={batchTypeOptions}
          onChangeFilter={(values: string[]) => changeFilterValue(values, 'type')}
        />
        <div className={styles.FilterLabel}>{getString('with', language)}</div>
        <FilterDropdown
          filter={filter.status}
          options={batchStatusOptions}
          onChangeFilter={(values: string[]) => changeFilterValue(values, 'status')}
        />
      </div>
      <Table className={styles.Table}>
        <TableRow header className={styles.TableHeader}>
          <TableCol>{getString('name', language)}</TableCol>
          <TableCol>{getString('type', language)}</TableCol>
          <TableCol>{getString('status', language)}</TableCol>
          <TableCol>{getString('createdAt', language)}</TableCol>
        </TableRow>
        {isFetching && (
          <TableRow className={styles.ExpandingColumn}>
            <TableCol size="x4">
              <Spinner fill />
            </TableCol>
          </TableRow>
        )}
        {hasFetched &&
          batches.map(({ id, type, status, created_at }) => {
            const createdAt = new Date(created_at);
            return (
              <TableRow
                key={id}
                onClick={() => {
                  navigate(`/lab/batches/details/${id}`);
                }}
              >
                <TableCol>{id}</TableCol>
                <TableCol>{getAnalysisDisplayName(type, language)}</TableCol>
                <TableCol>{getBatchStatusDisplayName(status, language)}</TableCol>
                <TableCol>
                  {createdAt.toLocaleDateString()}{' '}
                  {createdAt.toLocaleTimeString(undefined, {
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableCol>
              </TableRow>
            );
          })}
        {hasFetched && batches.length === 0 && (
          <TableRow>
            <TableCol size="x3">{getString('noBatchesFound', language)}</TableCol>
          </TableRow>
        )}
        {totalPages > 0 && (
          <TableRow className={styles.PaginationRow}>
            <TableCol size="x4">
              <Pagination page={filter.page} setPage={setPage} totalPages={totalPages} />
            </TableCol>
          </TableRow>
        )}
        {Boolean(errorMessage) && (
          <TableRow>
            <TableCol size="x3">{errorMessage}</TableCol>
          </TableRow>
        )}
      </Table>
    </div>
  );
};
