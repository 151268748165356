import React from 'react';
import { Button } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { getString } from 'strings/translation';

type Props = {
  language: string;
  onClick: () => void;
};

const ResetMapButton = ({ language, onClick }: Props) => {
  const form = useOrderFormContext();
  const { zonesFile } = form.getValues();

  if (!zonesFile) {
    return null;
  }

  return (
    <Button variant="outline" onClick={onClick}>
      {getString('resetMap', language)}
    </Button>
  );
};

export default ResetMapButton;
