export const ALL = 'all';
export const SORT_ORDER = 'sort_order';

export const DEMO_OPERATION_ID = 541;

export const DEFAULT_COMMITTED_SAMPLES = 400;

export const SUGAR_BEETS = 'sugar-beets';
export const CORN = 'corn';
export const SOYBEANS = 'soybeans';
export const COTTON = 'cotton';
export const WHEAT = 'wheat';
export const CORN_SILAGE = 'corn_silage';
export const OATS = 'oats';
export const SORGHUM = 'sorghum';

export const SUGAR_BEETS_CROP = 'sugar_beets';
export const allCrops = [CORN, SOYBEANS, COTTON, SUGAR_BEETS_CROP, WHEAT];

export const NONE = 'None';

export const MY_ACCOUNTS = 'My Accounts';

export const MAX_FIELDS_FOR_SAMPLE_LOAD = 100;
export const FIELDS_PAGINATE_BY = 10;

export const PER_ACRE = 'per_acre';
export const PER_ZONE = 'per_zone';
