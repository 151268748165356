import { Button, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import TotalFarmModal from 'apps/Operations/Common/TotalFarmModal';

import { UI_COLORS } from 'constants/colors';

import { formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { roundTwoDecimal } from 'util/numUtils';
import { OperationType } from 'store/operation/types';

import CancelTotalFarmModal from './CancelTotalFarmModal';
import { useEditOperationTranslations } from './hooks';

type Props = {
  operation: OperationType;
};

const TotalFarm = ({ operation }: Props) => {
  const language = useBroswerLanguage();
  const translations = useEditOperationTranslations();
  const [tFCreateOpened, { open: openCreateTf, close: closeCreateTf }] = useDisclosure(false);
  const [tFCancelOpened, { open: openCancelTf, close: closeCancelTf }] = useDisclosure(false);
  const { total_farm } = operation;

  return (
    <>
      <TotalFarmModal opened={tFCreateOpened} onClose={closeCreateTf} operation={operation} />
      <CancelTotalFarmModal opened={tFCancelOpened} close={closeCancelTf} operation={operation} />
      {total_farm ? (
        <>
          <Group justify="space-between">
            <Group>
              <Text fw="bold">{translations.subscriptionRenewal}:</Text>
              <Text>{formatDateMonthDayYear(total_farm[0].expires_at, language)}</Text>
              <Group gap="xs">
                <Text fw="bold">
                  {translations.list} ({translations.agency}) {translations.price}:
                </Text>
                <Text>
                  ${total_farm[0].summary_json?.total_estimated_price_per_acre} ($
                  {total_farm[0].summary_json?.total_estimated_list_price_per_acre})
                </Text>
              </Group>
            </Group>
            <Button color={UI_COLORS.error} onClick={openCancelTf}>
              {translations.cancel}
            </Button>
          </Group>
          {total_farm.map((singleTF) => (
            <Group key={singleTF.id}>
              <Group gap="xs">
                <Text fw="bold">{translations.package}:</Text>
                <Text>
                  {singleTF.sku.name}({Math.round(singleTF.summary_json.percentage * 100)}%)
                </Text>
              </Group>
              <Group gap="xs">
                <Text fw="bold">{translations.acres}:</Text>
                <Text>{roundTwoDecimal(singleTF.acres)}</Text>
              </Group>
            </Group>
          ))}
        </>
      ) : (
        <Group>
          <Text>{translations.totalFarmOperationMsg}</Text>
          <Button onClick={openCreateTf}>{translations.activateTotalFarm}</Button>
        </Group>
      )}
    </>
  );
};

export default TotalFarm;
