import React from 'react';

import { GENERIC, RESULTS } from 'constants/fileUpload';
import { chemistryPlateTypes, PH_ANALYSIS } from 'constants/plates';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';
import { FilesTable } from 'common';

import { DocumentUpload } from './UploadButtons/DocumentUpload';
import { ResultsUpload } from './UploadButtons/ResultsUpload';

type PlateDocumentsContainerPropsType = {
  plate: PlateType;
};

export const PlateDocumentsContainer = ({ plate }: PlateDocumentsContainerPropsType) => {
  const language = useBroswerLanguage();

  const genericDocuments = plate.related_documents?.filter(
    (d) => d.document_type === GENERIC || d.document_type === RESULTS,
  );

  const isChemistry = chemistryPlateTypes.includes(plate.plate_type);
  const title = isChemistry
    ? getString('uploadedResults', language)
    : getString('relatedDocuments', language);
  return (
    <FilesTable title={title} files={genericDocuments}>
      {plate.plate_type === PH_ANALYSIS ? (
        <ResultsUpload plate={plate} />
      ) : (
        <DocumentUpload plate={plate} documentType={GENERIC} />
      )}
    </FilesTable>
  );
};
