import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { ActionIcon, Affix, type AffixBaseProps, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

type Props = {
  position?: AffixBaseProps['position'];
};

const DEFAULT_POSITION = { bottom: 20, right: 20 };

const BackToTopBtn = ({ position = DEFAULT_POSITION }: Props) => {
  const [scroll, scrollTo] = useWindowScroll();
  const language = useBroswerLanguage();

  return (
    <Affix position={position}>
      <Transition transition="slide-up" mounted={scroll.y > 0}>
        {(transitionStyles) => (
          <ActionIcon
            aria-label={getString('backToTop', language)}
            size="lg"
            style={transitionStyles}
            onClick={() => scrollTo({ y: 0 })}
          >
            <FaArrowUp />
          </ActionIcon>
        )}
      </Transition>
    </Affix>
  );
};

export default BackToTopBtn;
