import React from 'react';

import { DEALER_TERMS_CONDITIONS_URL, EULA_URL, PRIVACY_POLICY_URL } from 'constants/urls';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { UserRole } from 'store/user/types';

interface Props {
  role: UserRole;
}

const UserAgreementLinks = ({ role }: Props) => {
  const language = useBroswerLanguage();
  return (
    <div>
      {[UserRole.Agent, UserRole.AgencyAdmin].includes(role) && (
        <>
          <a href={DEALER_TERMS_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
            {getString('termsAndConditions', language)}
          </a>
          &nbsp;|&nbsp;
        </>
      )}
      <a href={EULA_URL} target="_blank" rel="noopener noreferrer">
        {getString('endUserLicenseAgreement', language)}
      </a>
      &nbsp;|&nbsp;
      <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
        {getString('privacyPolicy', language)}
      </a>
    </div>
  );
};

export default UserAgreementLinks;
