import React from 'react';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { formatFloatString } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { LabShipment, LabShipmentFormType } from 'store/labShipments/types';
import { Container, Header, Input } from 'common';

import styles from './Samples.module.css';

type ShipmentSamplesPropsType = {
  shipment: LabShipment | LabShipmentFormType;
  updateShipment: (attributeName: string, newValue: number) => void;
};

export const ShipmentSamples = ({ shipment, updateShipment }: ShipmentSamplesPropsType) => {
  const language = useBroswerLanguage();

  const updateSampleCount = (inputString: string) => {
    const countNumber = formatFloatString(inputString || '0');
    updateShipment('nutrient_sample_count', parseInt(countNumber, 10));
  };

  return (
    <Container vertical>
      <Header
        title={
          <span>
            <span>{`${getString('nutrientSamples', language)}: `}</span>
            <Input
              className={styles.Input}
              autoComplete="off"
              value={String(shipment.nutrient_sample_count || '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateSampleCount(event.target.value)
              }
            />
          </span>
        }
        underline="thin"
        size="H2"
      />
    </Container>
  );
};
