import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { updatePlate } from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { Button, Container, Header, Spinner, Textarea } from 'common';

import styles from './Inputs.module.css';

type EditNotesPropsType = {
  plate: PlateType;
};

export const EditNotes = ({ plate }: EditNotesPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState(plate.notes || '');

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const resetValues = () => {
    setNotes(plate.notes || '');
    setIsEditing(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    try {
      setIsEditing(false);
      setIsSubmitting(true);
      await dispatch(updatePlate(plate.barcode, { notes }));
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const notesDisplay = plate.notes || '';

  const notesInput = (
    <Textarea
      ref={inputRef}
      className={styles.NotesInput}
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(event.target.value)}
      value={notes}
    />
  );

  const startEditButton = (
    <div className={styles.SubmitButton}>
      {isSubmitting && <Spinner className={styles.SmallSpinner} />}
      <Button className={styles.Button} onClick={() => setIsEditing(true)} disabled={isSubmitting}>
        {getString(isSubmitting ? 'submitting' : 'editNotes', language)}
      </Button>
    </div>
  );

  const finishEditButtons = (
    <div className={styles.SubmitButton}>
      <Button className={styles.Button} onClick={handleSubmit} disabled={!notes.length}>
        {getString('submit', language)}
      </Button>
      <Button className={styles.Button} danger onClick={resetValues}>
        {getString('cancel', language)}
      </Button>
    </div>
  );

  return (
    <Container vertical>
      <Header title={getString('notes', language)} underline="none" size="H2">
        {isEditing ? finishEditButtons : startEditButton}
      </Header>
      <div className={styles.Text}>{isEditing ? notesInput : notesDisplay}</div>
    </Container>
  );
};
