import { GeoJSON } from 'geojson';

import { User, UserSelectorType } from '../user/types';

export const RECEIVE_ALL_TERRITORIES = 'RECEIVE_ALL_TERRITORIES';
export const RECEIVE_SINGLE_TERRITORY = 'RECEIVE_SINGLE_TERRITORY';
export const REQUEST_TERRITORIES = 'REQUEST_TERRITORIES';
export const TERRITORIES_REQUEST_ERROR = 'TERRITORIES_REQUEST_ERROR';

export type TerritoryStateType = {
  myTerritory: CommercialTerritoryType | null;
  allTerritories: CommercialTerritoryType[];
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export type CommercialTerritoryProperties = {
  created_by_id?: number;
  id?: number;
  users: (User | UserSelectorType)[];
  active?: boolean;
  created_at?: string;
  owner_id?: number;
  name: string;
  owner?: User;
};

export type CommercialTerritoryType = GeoJSON.Feature<
  GeoJSON.MultiPolygon | null,
  CommercialTerritoryProperties
>;

interface RequestTerritoriesAction {
  type: typeof REQUEST_TERRITORIES;
  payload: {
    id?: number;
  };
}

interface RecieveAllTerritoriesAction {
  type: typeof RECEIVE_ALL_TERRITORIES;
  payload: {
    territories: CommercialTerritoryType[];
  };
}

interface RecieveSingleTerritoryAction {
  type: typeof RECEIVE_SINGLE_TERRITORY;
  payload: { id: number; territory: CommercialTerritoryType };
}

interface AgenciesRequestError {
  type: typeof TERRITORIES_REQUEST_ERROR;
  payload: {
    id?: number;
  };
}

export type TerritoryActionType =
  | RequestTerritoriesAction
  | RecieveAllTerritoriesAction
  | RecieveSingleTerritoryAction
  | AgenciesRequestError;
