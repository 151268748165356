import { AppThunk } from 'store';

import { orderHistoryRequestError, receiveOrderHistory, requestOrderHistory } from './actions';
import { requestGetOrderHistory } from './requests';

export const getOrderHistory =
  (year?: number, operationName?: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(requestOrderHistory());
    try {
      const response = await requestGetOrderHistory(year, operationName);
      dispatch(receiveOrderHistory(response));
    } catch (e) {
      dispatch(orderHistoryRequestError({ message: e.message }));
    }
  };
