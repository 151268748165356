import { PLATE_LANES } from 'constants/analysis';
import { batchStatusDisplayNames, batchStatusList, batchTypeList } from 'constants/batches';

import { getAnalysisDisplayName, getAnalysisShortName } from 'util/analysis';
import { getString } from 'strings/translation';
import { BatchFormType } from 'store/batches/types';

export const getBatchNamePlusText = (batchType: string, stringKey: string, language: string) => {
  return `${getAnalysisShortName(batchType, language)} ${getString(stringKey, language)}`;
};

export const getBatchStatusDisplayName = (statusType: string, language: string) =>
  getString(batchStatusDisplayNames[statusType], language);

export const getbatchAnalysisTypeOptions = (language: string, batches: string[] = batchTypeList) =>
  batches.map((analysis, idx) => ({
    id: idx + 1,
    displayName: getAnalysisDisplayName(analysis, language),
    label: getAnalysisDisplayName(analysis, language),
    value: analysis,
  }));

export const getbatchStatusOptions = (
  language: string,
  batches: string[] = batchStatusList,
  all: boolean = false,
) => [
  ...(all
    ? [
        {
          id: 0,
          displayName: getString('allStatuses', language),
          value: null,
        },
      ]
    : []),
  ...batches.map((status, idx) => ({
    id: idx + 1,
    displayName: getBatchStatusDisplayName(status, language),
    value: status,
  })),
];

export const formatBatchForSubmission = (batch: BatchFormType) => {
  const batchPlates = {};

  if (batch.plates.every((p) => p.lanes?.length)) {
    batch.plates.forEach((p) =>
      p.lanes
        ?.filter((l) => PLATE_LANES.includes(l))
        .forEach((l) => {
          if (batchPlates[l]) {
            batchPlates[l].push(p.barcode);
          } else {
            batchPlates[l] = [p.barcode];
          }
        }),
    );
  }
  return {
    type: batch.type,
    plates_barcodes: batch.plates.map((p) => p.barcode),
    barcode_lanes: Object.keys(batchPlates).length ? batchPlates : undefined,
  };
};
