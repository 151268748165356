import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Container, Space, Text, Title } from '@mantine/core';

import { CROP_PROTECTION } from 'constants/results';

import { useTranslation } from 'strings/translation';
import { RootState } from 'store';
import { setActiveOperation } from 'store/operation/thunks';
import { OperationAgencySearchBar, SuperAdminAccess } from 'common';

import AlertSummaryContainer from '../AlertSummary/Container';

import styles from './Container.module.css';

const MultipleOperationsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hasFetched, summary, activeOperationId } = useSelector((state: RootState) => ({
    hasFetched: state.operations.hasFetched,
    summary: state.operations.summary.items,
    activeOperationId: state.operations.activeOperationId,
  }));

  const changeOperation = useCallback((id: number) => dispatch(setActiveOperation(id)), [dispatch]);

  const viewOperation = async (id: number) => {
    changeOperation(id);
    navigate(`/results/operation/${id}/${CROP_PROTECTION}`);
  };

  const handleOperationSelection = (operation: { opAgId: number } | undefined) => {
    if (operation) {
      viewOperation(operation.opAgId);
    }
  };

  // We only redirect on initial page load, not while using the search bar
  if (!summary.length && !activeOperationId && hasFetched) {
    navigate(`/welcome`);
  }

  return (
    <Container>
      <Space h="xl" />
      <Space h="xl" />
      <Center>
        <Title order={2}>{useTranslation('searchAccountOrViewAll')}</Title>
      </Center>
      <div className={styles.ActionBox}>
        <OperationAgencySearchBar
          onSelect={handleOperationSelection}
          className={styles.Input}
          placeholder={useTranslation('enterAccountName')}
        />
        <Space w="md" />
        <Text size="xl">{useTranslation('or')}</Text>
        <Space w="md" />
        <Button
          variant="outline"
          data-test-id="view-all-accounts"
          onClick={() => navigate('/operations/manage/all')}
        >
          {useTranslation('viewAllAccounts')}
        </Button>
      </div>
      <SuperAdminAccess>
        <AlertSummaryContainer />
      </SuperAdminAccess>
    </Container>
  );
};

export default MultipleOperationsContainer;
