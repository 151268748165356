import { useState } from 'react';
import { Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { FIELD_BOUNDARY } from 'constants/mapbox';
import { PROJECT_MAP_COLORS } from 'constants/projects';

import { setFieldMapHoverListeners } from 'util/field';
import useReactMapbox from 'util/hooks/useReactMapbox';
import { carbonSamplingPlanQueryOptions } from 'util/queryOptions';
import { FieldFeature } from 'store/fields/types';
import { MapboxClickEvent } from 'common/Maps/types';

import { useProjectFieldsLayer, useProjectRouteParams } from '../hooks';
import { useFieldsQueries } from '../queries';

import CarbonCollectionMapBtns from './CollectionsMapBtns';

const CollectionFieldsMap = () => {
  const { samplingPlanId, fieldId } = useProjectRouteParams();
  const samplingPlanQuery = useQuery(carbonSamplingPlanQueryOptions(samplingPlanId));

  const { mapContainerRef, mapRef, mapHasLoaded } = useReactMapbox({
    onLoad: (map) => setFieldMapHoverListeners(map, [FIELD_BOUNDARY]),
  });

  const [selectedFieldIds, setSelectedFieldIds] = useState<number[]>([]);

  const collectionFieldIds =
    samplingPlanQuery.data?.carbon_collections.flatMap((c) =>
      c.collection_fields.map(({ field }) => field.id),
    ) || [];

  const fieldsQueries = useFieldsQueries(collectionFieldIds);
  const isLoading = samplingPlanQuery.isPending || fieldsQueries.isPending;

  const selectedFieldsAcres = fieldsQueries.fieldFeatures.reduce((acc, feature) => {
    if (!feature) return acc;

    const { id, acreage } = feature.properties;
    return selectedFieldIds.includes(Number(id)) ? acc + acreage : acc;
  }, 0);

  const handleFieldClick = (evt: MapboxClickEvent) => {
    const clickedFeature = evt.features?.[0];
    if (!clickedFeature) return;

    const properties = clickedFeature.properties as FieldFeature;
    const { id } = properties;
    const numericId = Number(id);

    setSelectedFieldIds((prev) => {
      const isAlreadySelected = prev.includes(numericId);

      evt.target.setFeatureState({ source: FIELD_BOUNDARY, id }, { selected: !isAlreadySelected });

      return isAlreadySelected ? prev.filter((fid) => fid !== numericId) : [...prev, numericId];
    });
  };

  useProjectFieldsLayer({
    mapHasLoaded: mapHasLoaded && !samplingPlanQuery.isPending,
    mapRef,
    fieldIds: collectionFieldIds,
    currentFieldId: fieldId,
    onClick: handleFieldClick,
    fillPaint: {
      'fill-opacity': 0.4,
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        PROJECT_MAP_COLORS.selectedCollectionFieldsFill,
        'transparent',
      ],
    },
  });

  const reset = () => {
    setSelectedFieldIds([]);
    mapRef.current?.removeFeatureState({ source: FIELD_BOUNDARY });
  };

  return (
    <Stack h="100%" flex={1}>
      <CarbonCollectionMapBtns
        samplingPlanId={samplingPlanId}
        carbonCollections={samplingPlanQuery.data?.carbon_collections || []}
        acres={selectedFieldsAcres}
        reset={reset}
        selectedFieldIds={selectedFieldIds}
      />
      <div
        ref={mapContainerRef}
        style={{
          flex: 1,
          height: '100%',
          width: '100%',
          visibility: isLoading ? 'hidden' : 'visible',
        }}
      />
    </Stack>
  );
};

export default CollectionFieldsMap;
