import React from 'react';
import { Group, Text } from '@mantine/core';

import { roundThreeDecimal } from 'util/numUtils';
import { getString } from 'strings/translation';

type Props = {
  language: string;
  rangeValueText: string;
  fieldAtRiskAcres: number;
  fieldTotalAcres: number;
  quantity?: number;
};

const MapbookMetadataSection = ({
  language,
  rangeValueText,
  fieldAtRiskAcres,
  fieldTotalAcres,
  quantity,
}: Props) => {
  return (
    <Group>
      <Text>
        <b>{getString('avg', language)}: </b>
        {quantity && roundThreeDecimal(quantity)}{' '}
      </Text>
      <Text>
        <b>{getString('range', language)}: </b>
        {rangeValueText}
      </Text>
      <Text>
        <b>{getString('acAtRisk', language)}: </b>
        {Math.round(fieldAtRiskAcres)} {getString('of', language)} {Math.round(fieldTotalAcres)}
        {` (${Math.round((100 * fieldAtRiskAcres) / fieldTotalAcres)}%)`}
      </Text>
    </Group>
  );
};

export default MapbookMetadataSection;
