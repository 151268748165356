import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HOMOGENIZATION } from 'constants/plates';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Header, PlateSearchBar } from 'common';

import { PlatesOverview } from './PlateOverview';

import styles from './Container.module.css';

const PlatesOverviewContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState<string>();

  return (
    <div className={styles.Wrapper}>
      <Header
        title={
          <div className={styles.Header}>
            <span>{getString('plates', language)}</span>
            <Button
              className={styles.NewButton}
              primary
              onClick={() => navigate(`/lab/plates/create/${HOMOGENIZATION}`)}
            >
              {getString('newPlate', language)}
            </Button>
          </div>
        }
      >
        <PlateSearchBar
          onSelect={setSearchString}
          onDeselect={() => setSearchString('')}
          placeholder={getString('searchByBarcode', language)}
        />
      </Header>
      <PlatesOverview plateBarcode={searchString} />
    </div>
  );
};

export default PlatesOverviewContainer;
