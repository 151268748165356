import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Box, Button, Group, SegmentedControl, Space, Stack, Text } from '@mantine/core';
import center from '@turf/center';
import mapboxgl from 'mapbox-gl';

import {
  BINARY_OPTIONS,
  NO_DATA,
  NOT_ANALYZED,
  NOT_DETECTED,
  RISK_AREA_TYPES,
} from 'constants/fieldRisks';
import { TILLAGE_LAYER_RX, TILLAGE_LAYER_TEST_RESULTS } from 'constants/proMaps';
import { COMPACTION_ID, MAP_ANALYTIC, mapDisplayTypes, OM_ID } from 'constants/results';

import { getHighestRiskFromOrder, getRiskColorFill } from 'util/chartUtils';
import { useSetCompactionFillColor } from 'util/hooks/compactionMap';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useMapLoaded from 'util/hooks/useMapLoaded';
import { getLegendMarks, getLegendTitle, getMapLegendColorStops } from 'util/mapImageryColors';
import { getFieldResultsConfig } from 'util/overviewResultsDisplay';
import {
  addCompactionSourcesAndLayers,
  getNonTillageProMapPaintFill,
  getProLayerHighLow,
  setNonTillageProHoverHandlers,
  setTillageHoverHandlers,
} from 'util/proMaps';
import {
  addFieldAndGetZoom,
  addSampleLayersToMap,
  getMinMaxSampleRange,
  getSampleRangeText,
  planHasMissingSample,
  setupNonTillageProLayer,
} from 'util/results';
import { getProNutrientMapLayer } from 'util/samplePlan';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { AnalyticType } from 'store/analytics/types';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FieldType, MapboxSample, SamplingPlanType } from 'store/fields/types';
import { RecommendationType } from 'store/recommendations/types';
import { AdminAgentAccess } from 'common';
import useMapboxGl from 'common/MapHooks';
import Popup from 'common/Maps/Popup';
import { PopupState } from 'common/Maps/types';

import { MapbookCompactionMeta } from '../FieldSummary/Compaction/MapbookCompactionMeta';
import { TillageLayerId, TillRxMapCtrls } from '../FieldSummary/Compaction/TillRxMapCtrls';

import { LegendWrap } from './MapLegend/LegendWrap';
import MapbookMetadataSection from './MapbookMetadataSection';
import MapbookRangesSection from './MapbookRangesSection';
import MapbookRecommendations from './MapbookRecommendations';
import MapbookSampleWarnings from './MapbookSampleWarnings';
import { MapDisplayTypeSelector } from './MapDisplayTypeSelector';
import { getProNutrientPopupSettings } from './MapPopup';
import Recommendations from './Recommendations';
import { FieldSummaryRouteParams } from './types';

import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './Mapbook.module.css';

interface Props {
  activeAnalytic: AnalyticType;
  field: FieldType;
  samples: MapboxSample[];
  samplingPlan: SamplingPlanType;
  recommendations: RecommendationType[];
  isModifyRecommendation?: boolean;
  removeNavigation?: boolean;
}

type MapDisplayType = keyof typeof mapDisplayTypes;

const Mapbook = ({
  activeAnalytic,
  field,
  samples,
  samplingPlan,
  recommendations,
  isModifyRecommendation,
  removeNavigation,
}: Props) => {
  const { operationId } = useParams<FieldSummaryRouteParams>();
  const { search } = useLocation();
  const browserLang = useBroswerLanguage();
  const language = new URLSearchParams(search).get('language') || browserLang;
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const proLayer = getProNutrientMapLayer(samplingPlan, activeAnalytic);

  const mapPointsId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}-points`;
  const mapProId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}-pro`;
  const pointsQuantityId = `${mapPointsId}-quantity`;

  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });

  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const [editRecommendation, toggleEditRecommendation] = useState(false);
  const [samplingPlanId, setSamplingPlanId] = useState<number | null>(null);
  const [analyticId, setAnalyticId] = useState(activeAnalytic.id);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const activeTabIsCompaction = analyticId === COMPACTION_ID;

  const [activeTillageLayerId, setActiveTillageLayerId] = useState<TillageLayerId>(
    TILLAGE_LAYER_TEST_RESULTS,
  );

  const [mapDisplayType, setMapDisplayType] = useState<MapDisplayType>(
    proLayer || activeTabIsCompaction ? mapDisplayTypes.pro : mapDisplayTypes.points,
  );

  const { operation } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[Number(operationId)],
  }));

  useMapboxGl(
    mapContainerRef,
    mapRef,
    null,
    viewport,
    setViewport,
    () => {},
    !removeNavigation,
    null,
    null,
  );

  const mapHasLoaded = useMapLoaded(mapRef);

  const {
    planAnalytic,
    risk,
    recommendation,
    fieldAtRiskAcres,
    fieldTotalAcres,
    fieldOmQuantities,
  } = getFieldResultsConfig(activeAnalytic, samplingPlan, samples, recommendations, field);

  const shouldShowViewDetailsBtn =
    ![NO_DATA, NOT_ANALYZED, NOT_DETECTED].includes(risk) && planAnalytic?.quantity !== null;

  const riskLevelOrder =
    planAnalytic?.risk_summary?.risk_level_order || RISK_AREA_TYPES.LOW_MODERATE_HIGH;
  const highestRiskOrder = getHighestRiskFromOrder(riskLevelOrder);

  const shouldShowRangesSection =
    !activeTabIsCompaction && !BINARY_OPTIONS.includes(riskLevelOrder);

  useEffect(() => {
    if (mapHasLoaded && mapRef.current) {
      const zoom = addFieldAndGetZoom(mapRef.current, field);

      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  const resetMapLayers = () => {
    const map = mapRef.current;

    if (mapHasLoaded && map) {
      [mapPointsId, mapProId, pointsQuantityId].forEach((layer) => {
        if (map.getLayer(layer)) {
          map.removeLayer(layer);
          map.removeSource(layer);
        }
      });
    }
  };

  const [inferenceLayer, setInferenceLayer] = useState<EOInferenceLayerType | null | undefined>(
    proLayer,
  );

  const activeTillageLayerIsTestResults = activeTillageLayerId === TILLAGE_LAYER_TEST_RESULTS;
  const activeTillageLayerIsRx = activeTillageLayerId === TILLAGE_LAYER_RX;
  const sampleRange = getMinMaxSampleRange(samples, activeAnalytic);

  const proHighLow = getProLayerHighLow(
    mapDisplayType === 'highContrast',
    activeAnalytic.id,
    sampleRange,
    activeTillageLayerId,
    planAnalytic?.data_summary,
    inferenceLayer,
  );

  const legendMarks = proHighLow ? getLegendMarks(proHighLow.high, proHighLow.low) : [];

  useEffect(() => {
    const map = mapRef.current;

    if (mapHasLoaded && map) {
      resetMapLayers();

      if (activeTabIsCompaction) {
        addCompactionSourcesAndLayers(map, language, setPopupInfo, proHighLow);
        setTillageHoverHandlers(map);
      } else if (mapDisplayType === 'points') {
        addSampleLayersToMap(activeAnalytic, samplingPlan, map, field, samples, {
          points: mapPointsId,
          pointsQuantity: pointsQuantityId,
        });
      } else if (proLayer && planAnalytic?.data_summary && !map.getLayer(mapProId)) {
        const fillColor = getNonTillageProMapPaintFill(
          activeAnalytic,
          planAnalytic.data_summary,
          proLayer,
          proHighLow,
        );

        const popupSettings = getProNutrientPopupSettings(
          activeAnalytic.id,
          proLayer,
          planAnalytic.unit,
        );

        setNonTillageProHoverHandlers(map, mapProId);

        setupNonTillageProLayer(
          map,
          mapProId,
          fillColor,
          proLayer.geojson_uri,
          (info) => setPopupInfo(info),
          popupSettings,
        );
      }

      map.getStyle();
      setAnalyticId(activeAnalytic.id);
      setSamplingPlanId(samplingPlan.id);
    }
  }, [
    mapHasLoaded,
    samples,
    analyticId,
    activeAnalytic,
    samplingPlan,
    field,
    samplingPlanId,
    mapDisplayType,
    language,
  ]);

  useSetCompactionFillColor(activeTillageLayerId, mapHasLoaded, mapRef.current, proHighLow);

  const handleActiveTillageLayerChange = (layerId: TillageLayerId) => {
    setActiveTillageLayerId(layerId);
    setPopupInfo(null);
  };

  const changeMapDisplayType = (element: string) => {
    resetMapLayers();
    setMapDisplayType(element as MapDisplayType);
    setPopupInfo(null);
  };

  const rangeValueText =
    analyticId === OM_ID
      ? `${fieldOmQuantities.min} - ${fieldOmQuantities.max}`
      : getSampleRangeText(sampleRange.lowest, sampleRange.highest);

  return (
    <Box className={styles.FieldMap} id={`analytic-${activeAnalytic.id}`}>
      {planAnalytic && editRecommendation && activeAnalytic && samplingPlan && recommendation && (
        <AdminAgentAccess>
          <Recommendations
            analyticId={activeAnalytic.id}
            riskLevel={planAnalytic.risk_level}
            samplingPlan={samplingPlan}
            recommendation={recommendation}
            operation={operation}
            onClose={() => toggleEditRecommendation(false)}
          />
          <div className={styles.Overlay} />
        </AdminAgentAccess>
      )}
      <Box className={styles.FieldMapInfo}>
        <Group
          justify="space-between"
          className={styles.AnalyticHeader}
          style={{
            backgroundColor: getRiskColorFill(planAnalytic?.risk_level),
          }}
        >
          <Group gap="sm">
            <Text fw={500}>
              {activeAnalytic.name} {planAnalytic?.unit && `(${planAnalytic.unit})`}
            </Text>
            <Text className={styles.HideForPrint}>
              <b>{getString('risk', language)}:</b> {getString(risk, language)}
            </Text>
          </Group>
          {shouldShowViewDetailsBtn && (
            <Button
              variant="white"
              className={styles.HideForPrint}
              component={Link}
              to={`/results/analytic/${operationId}/${samplingPlan.field_id}/${samplingPlan.id}/${activeAnalytic.id}`}
            >
              {getString('viewDetails', language)}
            </Button>
          )}
        </Group>
        <Space h="xs" />
        <Group className={styles.DisplayPrintOnly}>
          <Text>
            <b>{getString('risk', language)}: </b>
            {getString(risk, language)}
          </Text>
        </Group>
        <Space h="xs" />
        {activeTabIsCompaction ? (
          <MapbookCompactionMeta field={field} language={language} planAnalytic={planAnalytic} />
        ) : (
          <MapbookMetadataSection
            language={language}
            quantity={planAnalytic?.quantity}
            fieldAtRiskAcres={fieldAtRiskAcres}
            fieldTotalAcres={fieldTotalAcres}
            rangeValueText={rangeValueText}
          />
        )}
        <Space h="xs" />
        {shouldShowRangesSection && planAnalytic?.data_summary && (
          <>
            <MapbookRangesSection language={language} dataSummary={planAnalytic.data_summary} />
            <Space h="xs" />
          </>
        )}
        <MapbookRecommendations
          language={language}
          isModifyRecommendation={!!isModifyRecommendation}
          risk={risk}
          toggleEditRecommendation={toggleEditRecommendation}
          recommendationText={recommendation?.recommendation}
        />
        {!activeTabIsCompaction && (
          <MapbookSampleWarnings
            language={language}
            hasMissingSample={planHasMissingSample(samples, activeAnalytic)}
          />
        )}
      </Box>
      <Stack justify="flex-start" className={styles.MapWrapper}>
        <Box className={styles.Map} ref={mapContainerRef}>
          {mapDisplayType !== 'points' && (
            <LegendWrap
              showPlus
              title={getLegendTitle(
                activeAnalytic.id,
                activeTillageLayerIsTestResults,
                activeTillageLayerIsRx,
                planAnalytic?.unit,
                inferenceLayer,
              )}
              marks={legendMarks}
              colorStops={getMapLegendColorStops(
                getHighestRiskFromOrder(highestRiskOrder) || '',
                activeAnalytic.id,
              )}
            />
          )}
          {popupInfo && mapRef.current && (
            <Popup
              {...popupInfo}
              map={mapRef.current}
              anchor="bottom"
              onClose={() => setPopupInfo(null)}
            >
              {popupInfo.content}
            </Popup>
          )}
        </Box>
        {activeTabIsCompaction && (
          <TillRxMapCtrls
            field={field.features[0]}
            samplingPlanId={samplingPlan.id}
            mapHasLoaded={mapHasLoaded}
            setInferenceLayer={(layer) => setInferenceLayer(layer)}
            mapRef={mapRef}
            activeTillageLayerId={activeTillageLayerId}
            onActiveLayerChange={handleActiveTillageLayerChange}
            onSliderChange={() => setPopupInfo(null)}
            mapDisplayTypeToggle={
              activeTillageLayerIsTestResults ? (
                <SegmentedControl
                  value={mapDisplayType}
                  onChange={changeMapDisplayType}
                  data={[
                    {
                      label: getString('proResults', language),
                      value: mapDisplayTypes.pro,
                    },
                    {
                      label: getString('highContrast', language),
                      value: mapDisplayTypes.highContrast,
                    },
                  ]}
                />
              ) : null
            }
          />
        )}
        {!!proLayer && (
          <MapDisplayTypeSelector
            language={language}
            onChange={changeMapDisplayType}
            value={mapDisplayType}
          />
        )}
      </Stack>
    </Box>
  );
};

export default Mapbook;
