import { Center, Loader, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { sampleTimingsQueryOptions } from 'util/queryOptions';

import SampleOrderSteps from './Components/PlanSteps/SampleOrderSteps';
import {
  useAllAgenciesQuery,
  useAllSkusQuery,
  useDefaultPricesQuery,
  useFieldQuery,
  useOperationQuery,
  useOperationUsersQuery,
} from './queries';

const ZoneAnalysisContainerV3 = () => {
  const operationQuery = useOperationQuery();
  const fieldQuery = useFieldQuery();
  const operationUsersQuery = useOperationUsersQuery();
  const defaultPricingQuery = useDefaultPricesQuery();
  const sampleTimingsQuery = useQuery(sampleTimingsQueryOptions);
  const allSkusQuery = useAllSkusQuery();
  const operationAgencies = useAllAgenciesQuery();

  const shouldShowLoader =
    operationQuery.isPending ||
    fieldQuery.isPending ||
    operationUsersQuery.isPending ||
    sampleTimingsQuery.isPending ||
    defaultPricingQuery.isPending ||
    allSkusQuery.isPending ||
    operationAgencies.isPending;

  if (shouldShowLoader) {
    return (
      <Center h="85vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Stack style={{ overflow: 'hidden' }} flex={1} gap={0} maw={1300} mx="auto" w="100%">
      <SampleOrderSteps
        operationUsers={operationUsersQuery.data?.users}
        operation={operationQuery.data}
        fieldFeatureCollection={fieldQuery.data}
        timingsConfig={sampleTimingsQuery.data}
      />
    </Stack>
  );
};

export default ZoneAnalysisContainerV3;
