// Base and UI colors
export const WHITE = '#FFFFFF';
export const RED = '#D82A3B';
export const LIGHT_BLUE = '#8B97AF';
export const DARK_BLUE = '#0F3161';
export const GREY_BLUE = '#4e5c6a';
export const LIGHT_GREEN = '#92FE88';
export const DARK_GREEN = '#036E3A';

export const UI_COLORS = {
  warning: 'orange.7',
  error: 'red.8',
  darkBlue: 'blue',
  lightBlue: 'blue.4',
  green: 'green.9',
  white: WHITE,
  selectedMarker: 'red',
};

export const OVERLAY_GREY = '#C4C4C4';
export const BORDER_GREY = '#404040';
export const RESTRICTED_RED = '#E88484';
export const MAP_HEADER_GRAY = '#8897B1';
export const LIGHT_GREY_BG = '#f0f0f0';

// Draw tools
export const DRAW_LIGHT_BLUE = '#3bb2d0';
export const DRAW_DARK_BLUE = '#0e6996';
export const DRAW_ORANGE = '#fbb03b';
export const DRAW_DARK_GREY = '#404040';

// App-or-concept-specific
export const RISK_FILL_COLORS = {
  HIGH_RISK: '#E68080',
  MODERATE_RISK: '#FFBD80',
  LOW_RISK: '#80DBA3',
  NOT_DETECTED: '#C4C4C4',
};

export const SALES_FLOW_COLORS = {
  DARK_BLUE: '#0F3161',
  GREEN: '#72CE97',
  RED: '#DA7272',
  GREY: '#8797B0',
};

export const PH_COLORS = {
  high: '#1d3564',
  mid: '#8a5796',
  low: '#f4bd4f',
};

export const CROP_PLAN_YELLOW = '#FFEC99';
export const NO_RISK_FILL = '#00B84A';
export const NOT_ANALYZED_FILL = '#000000';
export const NO_DATA_FILL = '#dddddd';

export const PRESCRIPTION_COLORS: [number, string][] = [
  [0.1, '#89b6ff'],
  [0.2, '#76a9ff'],
  [0.3, '#629dff'],
  [0.4, '#4e91ff'],
  [0.5, '#3b85ff'],
  [0.6, '#2778ff'],
  [0.7, '#146cff'],
  [0.8, '#0060ff'],
  [0.9, '#0059eb'],
  [1, '#0051d8'],
];
