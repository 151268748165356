import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Label.module.css';

type LabelProps = {
  className?: string;
  label: any;
  dataTestId?: string;
  children?: ReactNode;
};

export const Label: FunctionComponent<LabelProps> = ({
  children,
  className = '',
  label,
  dataTestId,
}) => {
  return (
    <label className={classNames(styles.Label, className)} data-test-id={dataTestId}>
      <span className={styles.LabelText}>{label}</span>
      <div className={styles.Input}>{children}</div>
    </label>
  );
};
