import React from 'react';

import { accountDisplayNames } from 'constants/connectAccounts';

import { formatCalendarMonthDayYear } from 'util/date';
import useBrowserLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { OperationType } from 'store/operation/types';
import { Button, Spinner, TableCol, TableRow } from 'common';

import styles from './Container.module.css';

type AccountsTableRowPropsType = {
  accountType: string;
  accountLogo: string;
  beginConnect: () => void;
  authorized?: boolean | null;
  operation: OperationType;
  loading: string | null;
};

const AccountsTableRow = ({
  accountType,
  accountLogo,
  beginConnect,
  authorized,
  operation,
  loading,
}: AccountsTableRowPropsType) => {
  const language = useBrowserLanguage();
  const connectAccount = () => {
    // If we have access to it, we're pinging it for new fields already
    if (authorized) {
      return `${getString('lastSync', language)}: ${
        operation.connectedAccounts?.last_sync
          ? formatCalendarMonthDayYear(operation.connectedAccounts.last_sync)
          : getString('notSyncedYet', language)
      }`;
    }
    const loadingThisAccount = loading === accountType;
    return (
      <>
        {loadingThisAccount && <Spinner className={styles.SmallSpinner} />}
        <Button disabled={loadingThisAccount} primary onClick={beginConnect}>
          {loadingThisAccount
            ? `${getString('connecting', language)}...`
            : `${getString('connect', language)} ${accountDisplayNames[accountType]}`}
        </Button>
      </>
    );
  };

  return (
    <TableRow>
      <TableCol size="x2" shrink className={styles.LogoColumn}>
        <img
          alt={`${accountDisplayNames[accountType]} Logo`}
          className={styles.Logo}
          src={accountLogo}
        />
      </TableCol>
      <TableCol size="x2">
        {`${getString('connectYourDataFrom', language)} ${accountDisplayNames[accountType]}`}
      </TableCol>
      <TableCol>{connectAccount()}</TableCol>
    </TableRow>
  );
};

export default AccountsTableRow;
