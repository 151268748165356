import React, { useState } from 'react';
import { Button, Group, Loader, Radio, Space, Stack, Text } from '@mantine/core';

import { getLoginSelectionList } from 'util/auth';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PasswordlessUserEmailType } from 'store/user/types';

import styles from '../../Shared/Container.module.css';

interface Props {
  emailCell: PasswordlessUserEmailType;
  handleNextSelectedLogin: (selected: string) => void;
  clearEmailCell: () => void;
}

const SelectLoginTypeForm = ({ emailCell, handleNextSelectedLogin, clearEmailCell }: Props) => {
  const language = useBroswerLanguage();
  const loginSelectList = getLoginSelectionList(language, emailCell);
  const [selected, setSelected] = useState<string>(emailCell.cell ? 'cell' : 'email');
  const [loading, setLoading] = useState(false);

  const handleClickNext = async () => {
    setLoading(true);
    await handleNextSelectedLogin(selected);
    setLoading(false);
  };

  return (
    <Stack align="flex-start" className={styles.LoginSelectForm}>
      <Text size="lg" fw={700}>
        {getString('howWouldyouLikeToSignIn', language)}
      </Text>
      {loginSelectList.map((val) => (
        <Group justify="space-between" key={val.value} className={styles.LoginSelectForm}>
          <Text className={styles.WrapText} size="md">
            {val.label}
          </Text>
          <Radio
            data-test-id={val.dataTestId}
            labelPosition="left"
            checked={selected === val.value}
            onChange={() => setSelected(val.value)}
          />
        </Group>
      ))}
      <Group justify="space-between" className={styles.LoginSelectForm}>
        <Button variant="outline" onClick={clearEmailCell}>
          {getString('goBack', language)}
        </Button>
        <Space w="xl" />
        <Button data-test-id="next-button" onClick={handleClickNext} disabled={loading}>
          {loading ? <Loader size={15} /> : getString('next', language)}
        </Button>
      </Group>
    </Stack>
  );
};

export default SelectLoginTypeForm;
