import React, { useState } from 'react';
import { Select, Text } from '@mantine/core';

import { getDataReviewSortOptions } from 'util/dataReview';
import { getString } from 'strings/translation';
import { DataReviewSort } from 'store/eoCollections/types';

type Props = {
  language: string;
  onChange: (sort: DataReviewSort) => void;
};

const DataReviewSortMenu = ({ onChange, language }: Props) => {
  const options = getDataReviewSortOptions(language);
  const [value, setValue] = useState<DataReviewSort>('name-asc');

  return (
    <Select
      data={options}
      value={value}
      label={
        <Text fw="bolder" size="sm">
          {getString('sort', language)}
        </Text>
      }
      wrapperProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
          gap: 'var(--mantine-spacing-sm)',
        },
      }}
      onChange={(val) => {
        const status = val as DataReviewSort;

        setValue(status);
        onChange(status);
      }}
    />
  );
};

export default DataReviewSortMenu;
