import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import turfBbox from '@turf/bbox';
import center from '@turf/center';
import { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import { WHITE } from 'constants/colors';
import {
  FIELD_BOUNDARY,
  FIELD_OUTLINE,
  FILL,
  LINE,
  MAPBOX_FIT_PARAMS,
  MODES,
  POINT_STYLING_IDS,
  WHITE_OUTLINE,
} from 'constants/mapbox';
import { US_MIDWEST_VIEWPORT } from 'constants/mapViewport';

import { FieldType } from 'store/fields/types';
import { MapReferenceType } from 'store/pricing/types';
import { DrawMode, type ViewPortProps } from 'common/Maps/types';

interface ViewPortType {
  latitude: number;
  longitude: number;
  zoom: number;
  width?: number;
  height?: number;
}

const useAnalysisMapSetup = (
  mapRef: MapReferenceType,
  wrapperRef: RefObject<HTMLDivElement> | null,
  field: FieldType,
  setMode: (mode: DrawMode) => void,
  setViewport: (viewport: ViewPortType) => void,
  viewport: ViewPortType,
) => {
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const [initialViewport, setInitialViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);

  useEffect(() => {
    if (mapRef.current && mapHasLoaded && viewport.width && viewport.height) {
      const bbox = turfBbox(field) as LngLatBoundsLike;
      mapRef.current.fitBounds(bbox, MAPBOX_FIT_PARAMS);
      const zoom = mapRef.current.getZoom();
      setInitialViewport((prevViewport) => ({
        ...prevViewport,
        zoom,
      }));
    }
  }, [mapRef, mapHasLoaded, viewport.width, viewport.height, field]);

  useEffect(() => {
    mapRef.current?.on('load', () => {
      const bbox = turfBbox(field) as LngLatBoundsLike;
      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (!mapRef.current?.getLayer(FIELD_BOUNDARY)) {
        mapRef.current?.addLayer({
          id: FIELD_BOUNDARY,
          type: FILL,
          source,
          paint: { 'fill-color': WHITE, 'fill-opacity': 0.4 },
        });
      }
      if (!mapRef.current?.getLayer(FIELD_OUTLINE)) {
        mapRef.current?.addLayer({
          id: FIELD_OUTLINE,
          type: LINE,
          source,
          paint: WHITE_OUTLINE,
        });
      }
      // Move point styling to render above the field layer
      POINT_STYLING_IDS.forEach((id) => mapRef.current?.moveLayer(id));
      mapRef.current?.fitBounds(bbox, MAPBOX_FIT_PARAMS);

      const zoom = mapRef.current?.getZoom();
      const viewportSetter: ViewPortType = {
        latitude: centerLatitude,
        longitude: centerLongitude,
        zoom: zoom || US_MIDWEST_VIEWPORT.zoom,
        width: viewport.width,
        height: viewport.height,
      };
      // with the TS definition as necessary
      setViewport(viewportSetter);
      setInitialViewport(viewportSetter);
    });
  }, [centerLatitude, centerLongitude, field, mapRef]);

  const updateSize = useCallback(() => {
    const width = wrapperRef?.current?.clientWidth || 0;
    const height = window.innerHeight - (wrapperRef?.current?.offsetTop || 0);
    setViewport({
      ...viewport,
      width,
      height,
    });
  }, [wrapperRef]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wrapperRef, updateSize]);

  useEffect(() => {
    if (mapRef.current && !mapHasLoaded) {
      mapRef.current.on('load', () => {
        setMapHasLoaded(true);
        setMode(MODES.SELECT);
      });
    }
  }, [mapRef, mapHasLoaded]);

  return { mapHasLoaded, initialViewport, viewport, setViewport };
};

export default useAnalysisMapSetup;
