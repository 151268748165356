import React from 'react';

import { labDisplayNames } from 'constants/shipping';

import { formatCalendarMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabShipment } from 'store/labShipments/types';
import { TableCol, TableRow } from 'common';

import { ShipmentActionsModal } from './Actions/ShipmentActions';

type LabShipmentTableRowPropsType = {
  shipment: LabShipment;
  reloadPage: () => {};
};

const LabShipmentTableRow = ({ shipment, reloadPage }: LabShipmentTableRowPropsType) => {
  const language = useBroswerLanguage();

  return (
    <TableRow>
      <TableCol size="x2">{shipment.group_id}</TableCol>
      <TableCol>
        {shipment.ship_from_lab
          ? labDisplayNames[shipment.ship_from_lab]
          : getString('noData', language)}
      </TableCol>
      <TableCol>{labDisplayNames[shipment.ship_to_lab]}</TableCol>
      <TableCol>{getString(shipment?.is_regulated ? 'yes' : 'no', language)}</TableCol>
      <TableCol>{`${shipment.shipped_count}/${shipment.total_labels_count}`}</TableCol>
      <TableCol>{`${shipment.delivered_count}/${shipment.total_labels_count}`}</TableCol>
      <TableCol>{shipment.nutrient_sample_count || 0}</TableCol>
      <TableCol>{shipment.genomic_plate_count || 0}</TableCol>
      <TableCol>{shipment.genomic_sample_count || 0}</TableCol>
      <TableCol>{formatCalendarMonthDayYear(shipment.group_created_at)}</TableCol>
      <TableCol>
        <ShipmentActionsModal shipment={shipment} reloadPage={reloadPage} />
      </TableCol>
    </TableRow>
  );
};

export default LabShipmentTableRow;
