import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Table } from '@mantine/core';

import { financeRoutes } from 'constants/routes';
import { approvalStatusType } from 'constants/routes/financeRoutes';

import { formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { roundTwoDecimal } from 'util/numUtils';
import { SummaryFtType } from 'store/payments/types';

import { useBillingTranslations } from '../hooks';

interface Props {
  summaryData?: SummaryFtType[];
  approvalStatus: approvalStatusType | undefined;
  showNavigate?: boolean;
}

const SummaryTable = ({ summaryData, approvalStatus, showNavigate }: Props) => {
  const language = useBroswerLanguage();
  const translations = useBillingTranslations();
  const navigate = useNavigate();

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{translations.payor}</Table.Th>
          <Table.Th>{translations.role}</Table.Th>
          <Table.Th>{translations.earliestFtCreation}</Table.Th>
          <Table.Th>{translations.balance}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {summaryData?.map((ftRow) => (
          <Table.Tr key={ftRow.billing_user_id}>
            <Table.Td>{ftRow.agency_name || `${ftRow.first_name} ${ftRow.last_name}`}</Table.Td>
            <Table.Td>{ftRow.role_name}</Table.Td>
            <Table.Td>{formatDateMonthDayYear(ftRow.earliest_created_at, language)}</Table.Td>
            <Table.Td>${roundTwoDecimal(ftRow.balance)}</Table.Td>
            {showNavigate && (
              <Table.Th>
                <ActionIcon
                  variant="transparent"
                  onClick={() =>
                    approvalStatus &&
                    navigate(
                      financeRoutes.routes.individual(
                        approvalStatus,
                        String(ftRow.billing_user_id),
                      ),
                    )
                  }
                >
                  <FiArrowRight />
                </ActionIcon>
              </Table.Th>
            )}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default SummaryTable;
