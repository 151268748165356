import { useEffect } from 'react';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { GeoJSONSourceRaw } from 'mapbox-gl';

import { LIGHT_GREY_LINE_PAINT, LINE } from 'constants/mapbox';

import removeMapLayer from 'util/mapbox';
import { MapReferenceType } from 'store/pricing/types';

import { setLayerFunction } from './mapUtils';

// Display bio zones in the background of the map
const useBioZoneDisplay = (mapRef: MapReferenceType, mapHasLoaded: boolean) => {
  const form = useOrderFormContext();
  const formValues = form.getValues();

  useEffect(() => {
    if (mapHasLoaded && mapRef) {
      const layerName = 'bio-zones-outline';
      if (formValues.zones) {
        removeMapLayer(mapRef, layerName);
        const source = {
          type: 'geojson',
          data: formValues.zones,
        } as GeoJSONSourceRaw;
        setLayerFunction(mapRef, layerName, source, {
          type: LINE,
          paint: LIGHT_GREY_LINE_PAINT,
        });
      } else {
        removeMapLayer(mapRef, layerName);
      }
    }
  }, [mapRef, mapHasLoaded, formValues.zones]);
};

export default useBioZoneDisplay;
