import showToast from 'actions/toastActions';
import { AppThunk } from 'store';

import { receiveRoles, requestRoles, roleRequestError } from './actions';
import { requestAllRoles } from './requests';

export const getRoles = (): AppThunk => async (dispatch) => {
  dispatch(requestRoles);
  try {
    const response = await requestAllRoles();
    dispatch(receiveRoles(response.role));
  } catch (e) {
    showToast(e.message, 'error');
    dispatch(dispatch(roleRequestError));
  }
};
