export const PROJECT_NAME_FORM_KEY = 'project-name';

export const DEFAULT_MC_DENSITY_STR = '45';

export const METER_CORE_DENSITY_OPTIONS = [
  '10',
  '20',
  '25',
  '30',
  DEFAULT_MC_DENSITY_STR,
  '60',
  '75',
  '90',
  '125',
  '150',
  '200',
] as const;

export const REGEN_STANDARD = 'Regen Standard';
export const CAR_STANDARD = 'CAR';

export const METER_CORE = 'Meter Core';
export const SOIL_TEXTURE = 'Soil Texture';

export const PROJECT_MAP_COLORS = {
  selectedField: 'magenta',
  otherFields: 'orange',
  selectedCollectionFieldsFill: 'orange',
};
