import { FaDownload } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { Button, Menu } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';

import showToast from 'actions/toastActions';
import { download811csv, downloadSoilCollectorExport } from 'store/projects/requests';

import { useProjectsTranslations } from '../hooks';

type Props = {
  samplingPlanId: number;
};

const SamplingPlanDownloadMenu = ({ samplingPlanId }: Props) => {
  const translations = useProjectsTranslations();

  const download811mutation = useMutation({
    mutationFn: () => download811csv(samplingPlanId),
    onMutate: () => {
      showToast(translations.thisMayTakeSeconds, undefined, undefined, {
        title: translations.download811msg,
      });
    },
  });

  const downloadsoilCollectorExportMuation = useMutation({
    mutationFn: () => downloadSoilCollectorExport(samplingPlanId),
    onMutate: () => {
      showToast(translations.thisMayTakeSeconds, undefined, undefined, {
        title: translations.downloadSoilCollectorExportMsg,
      });
    },
  });

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button leftSection={<FaDownload />} size="sm" rightSection={<FiChevronDown />}>
          {translations.download}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => download811mutation.mutate()}>811 CSV</Menu.Item>
        <Menu.Item onClick={() => downloadsoilCollectorExportMuation.mutate()}>
          {translations.soilCollectorOutput}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SamplingPlanDownloadMenu;
