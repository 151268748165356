import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Loader, Text } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import { ALL_PLANS } from 'constants/alerts';
import { BR, US } from 'constants/countries';
import { ENGLISH } from 'constants/languages';
import { AGENCY } from 'constants/products';
import { OPERATION } from 'constants/propTypes/operation';
import {
  ALL_ASSIGNMENTS,
  ALL_READINESS,
  CREATED,
  PLAN_TRACKING_FILTERS,
} from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { setLocalStorage } from 'util/localStorage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { receiveSamplePlansTracking } from 'store/samplePlans/actions';
import { getSamplePlansTrackingPage } from 'store/samplePlans/thunks';
import { ColumnSortType, OperationAgencyListType } from 'store/samplePlans/types';
import { UserSelectorType } from 'store/user/types';
import { Header } from 'common';

import TrackingFilters from './TrackingFilters';
import TrackingMap from './TrackingMap';
import TrackingTable from './TrackingTable';

const SampleTrackingContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const drawRef = useRef<any>(null);

  const [sortingCol, setSortingCol] = useState<ColumnSortType>({
    col: undefined,
    asc: false,
  });
  const [isDisplayMap, setIsDisplayMap] = useState(false);
  const [page, setPage] = useState(1);
  const [showAllAccounts, setShowAllAccounts] = useState(true);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [assignment, setAssignment] = useState(ALL_ASSIGNMENTS);
  const [readiness, setReadiness] = useState(ALL_READINESS);
  const [alerts, setAlerts] = useState<string[]>([]);
  const [proSelection, setProSelection] = useState(ALL_PLANS);
  const [selectedCountry, setSelectedCountry] = useState<string>(language === ENGLISH ? US : BR);
  const [currentMapBounds, setCurrentMapBounds] = useState<number[][] | null>(null);
  const [operationOrAgency, setOperationOrAgency] = useState<OperationAgencyListType | undefined>(
    undefined,
  );
  const [samplerUser, setSamplerUser] = useState<UserSelectorType | undefined>(undefined);
  const [isSamplerOnly, setIsSamplerOnly] = useState(false);
  const [completeness, setCompleteness] = useState<string[]>([]);
  const [searchObj, setSearchObj] = useState({
    showAllAccounts,
    statuses,
    assignment,
    readiness,
    alerts,
    operationOrAgency,
    samplerUser,
    page,
    sortingCol,
    selectedCountry,
    currentMapBounds,
    proSelection,
    isSamplerOnly,
    completeness,
  });
  const [initSearch, toggleInitSearch] = useState(false);

  const { isFetchingTracking, isLastPage, samplePlans, alertNames } = useSelector(
    (state: RootState) => ({
      samplePlans: state.samplePlans.summary.items,
      isFetchingTracking: state.samplePlans.isFetchingTracking,
      isLastPage: state.samplePlans.summary.isLastPage,
      alertNames: state.user.alertNames,
    }),
  );

  useEffect(() => {
    const filters = localStorage.getItem(PLAN_TRACKING_FILTERS);
    if (filters && alertNames.length) {
      const parsedFilters = JSON.parse(filters);
      setSortingCol(parsedFilters?.sortingCol || { col: undefined, asc: false });
      setShowAllAccounts(parsedFilters?.showAllAccounts);
      setStatuses(parsedFilters?.statuses || []);
      setAssignment(parsedFilters?.assignment || ALL_ASSIGNMENTS);
      setReadiness(parsedFilters?.readinessIndex || ALL_READINESS);
      setAlerts(parsedFilters?.alerts || []);
      setOperationOrAgency(parsedFilters?.operationOrAgency);
      setSamplerUser(parsedFilters?.samplerUser);
      setSelectedCountry(parsedFilters?.selectedCountry ? parsedFilters.selectedCountry : US);
      setProSelection(parsedFilters?.proSelection || ALL_PLANS);
      setPage(parsedFilters?.page || 1);
      setIsSamplerOnly(parsedFilters?.isSamplerOnly || false);
      setCompleteness(parsedFilters?.completeness || []);
      toggleInitSearch(true);
    } else if (alertNames.length) {
      dispatch(getSamplePlansTrackingPage(page, true));
    }
  }, [alertNames]);

  useEffect(() => {
    if (
      initSearch ||
      searchObj.page !== page ||
      searchObj.showAllAccounts !== showAllAccounts ||
      JSON.stringify(searchObj.statuses || []) !== JSON.stringify(statuses) ||
      searchObj.assignment !== assignment ||
      searchObj.readiness !== readiness ||
      JSON.stringify(searchObj.alerts) !== JSON.stringify(alerts) ||
      searchObj.operationOrAgency !== operationOrAgency ||
      searchObj.proSelection !== proSelection ||
      searchObj.samplerUser !== samplerUser ||
      searchObj.sortingCol !== sortingCol ||
      searchObj.selectedCountry !== selectedCountry ||
      searchObj.currentMapBounds !== currentMapBounds ||
      searchObj.isSamplerOnly !== isSamplerOnly ||
      searchObj.completeness !== completeness
    ) {
      toggleInitSearch(false);
      dispatch(receiveSamplePlansTracking([], page));
      dispatch(
        getSamplePlansTrackingPage(
          searchObj.page === page ? 1 : page,
          showAllAccounts,
          operationOrAgency?.type === OPERATION ? operationOrAgency?.opAgId : undefined,
          operationOrAgency?.type === AGENCY ? operationOrAgency?.opAgId : undefined,
          Number(samplerUser?.id) || undefined,
          statuses.length ? statuses : undefined,
          assignment === ALL_ASSIGNMENTS ? undefined : assignment,
          readiness === ALL_READINESS ? undefined : readiness,
          alerts.length ? alerts : undefined,
          proSelection === ALL_PLANS ? undefined : proSelection,
          completeness.length ? completeness : undefined,
          isSamplerOnly,
          selectedCountry,
          currentMapBounds,
          sortingCol,
        ),
      );
      setSearchObj({
        showAllAccounts,
        statuses,
        assignment,
        readiness,
        alerts,
        operationOrAgency,
        proSelection,
        samplerUser,
        page,
        sortingCol,
        selectedCountry,
        currentMapBounds,
        isSamplerOnly,
        completeness,
      });
      setLocalStorage(
        PLAN_TRACKING_FILTERS,
        JSON.stringify({
          showAllAccounts,
          statuses,
          assignment,
          readiness,
          proSelection,
          isSamplerOnly,
          alerts,
          operationOrAgency,
          samplerUser,
          selectedCountry,
          sortingCol,
          completeness,
        }),
      );
    }
  }, [
    initSearch,
    page,
    showAllAccounts,
    searchObj,
    statuses,
    assignment,
    proSelection,
    readiness,
    alerts,
    operationOrAgency,
    samplerUser,
    sortingCol,
    selectedCountry,
    isSamplerOnly,
    currentMapBounds,
    completeness,
  ]);

  const setStatusToCreated = (str: string) => {
    if (str !== ALL_ASSIGNMENTS) {
      setStatuses([CREATED]);
    }
  };

  const updateReadiness = (str: string) => {
    setStatusToCreated(str);
    setReadiness(str);
  };

  const updateAssignment = (str: string) => {
    setStatusToCreated(str);
    setAssignment(str);
  };

  if (!alertNames.length) {
    return <Loader h="40vh" />;
  }

  return (
    <div>
      <Header title={getString('samplePlanTracking', language)} />
      <TrackingFilters
        operationOrAgency={operationOrAgency}
        showAllAccounts={showAllAccounts}
        setShowAllAccounts={setShowAllAccounts}
        setSamplerUser={setSamplerUser}
        samplerUser={samplerUser}
        setOperationOrAgency={setOperationOrAgency}
        setAlerts={setAlerts}
        setAssignment={updateAssignment}
        setReadiness={updateReadiness}
        setStatuses={setStatuses}
        setProSelection={setProSelection}
        proSelection={proSelection}
        statuses={statuses}
        assignment={assignment}
        readiness={readiness}
        alerts={alerts}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        setIsDisplayMap={setIsDisplayMap}
        isDisplayMap={isDisplayMap}
        samplingPlanLength={Object.values(samplePlans).flatMap((samplePlan) => samplePlan).length}
        isSamplerOnly={isSamplerOnly}
        setIsSamplerOnly={setIsSamplerOnly}
        setCompleteness={setCompleteness}
        completeness={completeness}
      />
      {isDisplayMap ? (
        <TrackingMap
          mapContainerRef={mapContainerRef}
          mapRef={mapRef}
          drawRef={drawRef}
          setMapSearchBounds={setCurrentMapBounds}
          currentMapBounds={currentMapBounds}
          toggleInitSearch={toggleInitSearch}
        />
      ) : (
        <>
          <TrackingTable
            toggleInitSearch={toggleInitSearch}
            sortingCol={sortingCol}
            setSortingCol={setSortingCol}
            setPage={setPage}
          />
          <Group justify="center" mt="3rem">
            {isFetchingTracking && <Loader />}
            {isLastPage && !isFetchingTracking && (
              <Text c="dimmed">{getString('endOfResults', language)}</Text>
            )}
          </Group>
        </>
      )}
    </div>
  );
};

export default SampleTrackingContainer;
