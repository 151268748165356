import turfArea from '@turf/area';
import turfBbox from '@turf/bbox';
import turfBearing from '@turf/bearing';
import booleanIntersects from '@turf/boolean-intersects';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import center from '@turf/center';
import centroid from '@turf/centroid';
import circle from '@turf/circle';
import cleanCoords from '@turf/clean-coords';
import turfDistance from '@turf/distance';
import {
  AllGeoJSON,
  Feature,
  FeatureCollection,
  featureCollection,
  GeometryCollection,
  lineString,
  MultiPolygon,
  Point,
  Polygon,
  polygon,
  Position,
} from '@turf/helpers';
import inside from '@turf/inside';
import intersect from '@turf/intersect';
import turfLength from '@turf/length';
import lineOverlap from '@turf/line-overlap';
import transformRotate from '@turf/transform-rotate';
import transformTranslate from '@turf/transform-translate';
import union from '@turf/union';
import unkinkPolygon from '@turf/unkink-polygon';
import voronoi from '@turf/voronoi';
import { GeoJsonProperties, Geometry } from 'geojson';
import { v4 as uuid } from 'uuid';

import { CLU_LATITUDE_BUFFER, CLU_LONGITUTE_BUFFER, CLU_ZOOM_BUFFER } from 'constants/fields';
import { MULTIPOLYGON, POINT, POLYGON, ZONE_TYPES } from 'constants/mapbox';
import { POINT_RADIUS } from 'constants/samplePlanning';

import { MapboxSample } from 'store/fields/types';
import { postSplitZone } from 'store/samplePlans/requests';
import { ViewPortProps } from 'common/Maps/types';

import acToHectares from './units';

type PolyOrMultiPoly = GeoJSON.Polygon | GeoJSON.MultiPolygon;
type PolyOrMultiPolyFeature = GeoJSON.Feature<PolyOrMultiPoly>;

const SQUARE_FEET_PER_ACRE = 43560;
export const SQUARE_METERS_PER_ACRE = 4046.86;
const MINIMUM_THRESHOLD_ZONE_ACRE = 0.25;
const MINIMUM_FIELD_THRESHOLD_PERCENT = 0.1;

const isCoordinateSetWellFormed = (set: Position[]) => {
  const firstPair = set[0];
  const lastPair = set[set.length - 1];
  return firstPair[0] === lastPair[0] && firstPair[1] === lastPair[1];
};

export const unionizeMultipolygon = (multipolygon: FeatureCollection<MultiPolygon | Polygon>) => {
  const cleaned = {
    ...multipolygon,
    features: multipolygon.features.map((coords) => cleanCoords(coords)),
  };
  const merged = union(...cleaned.features) as Feature<MultiPolygon | Polygon>;
  // there is a bug in turf's unkink-polygon and clean-coords packages
  // see here: https://github.com/Turfjs/turf/issues/1679
  // clean-coords only removes sequential duplicate coordinates while unkink-polygon
  // checks for any duplicate vertices outside of the first and last pairs when it does
  // its check for all unique points. I recommend we use a try/catch to conditionally
  // attempt to unkink the polygon, but if there are issues with polygon kinks in the future
  // this method should be updated to manually detect duplicate vertices and move them a very small
  // amount (i.e. centimeters away) so that the uniqueness checks will pass without affecting the
  // shape of the field
  try {
    const unkinked = unkinkPolygon(merged);
    return union(...unkinked.features);
  } catch (error) {
    return merged;
  }
};

export const splitMultipolygon = (feature: Feature<MultiPolygon | Polygon>) => {
  if (feature.geometry.type === MULTIPOLYGON) {
    return feature.geometry.coordinates.map((coords, index) => {
      return {
        ...polygon(coords),
        properties: index === 0 ? feature.properties : {},
      };
    });
  }
  return [feature] as Feature<Polygon>[];
};

/**
 * Convert circle zones into centroids as point geometries
 * Create a voronoi tessellation from centroids
 * Clip tesselation to field, which may generate more zones that the number of points
 * Merge extra zones until the number of zones == number of points
 * Convert the polygon features to geometry collection features so they may be cast as SampleFeatureType
 * @param circleZones
 * @param multipolygon
 * @returns Feature<GeometryCollection>[]
 */
export const generateZonesForPoints = (
  circleZones: Feature<GeometryCollection>[],
  multipolygon: FeatureCollection<MultiPolygon | Polygon>,
) => {
  // samples that were collected outside the field boundary cause voronoi tessellation to fail. Simplest fix is to filter
  // collection point geometry as it is not relevant to tessellation.
  const nonPointGeometries = circleZones
    .map((zone) => ({
      ...zone,
      geometry: {
        ...zone.geometry,
        geometries: zone.geometry.geometries.filter((g) => g.type !== 'Point'),
      },
    }))
    .filter((zone) => zone.geometry.geometries.length);
  const zoneCentroids = nonPointGeometries.map((zone) =>
    center(zone, { properties: zone.properties }),
  );
  const voronoiZones = voronoiTessellation(featureCollection(zoneCentroids), multipolygon);
  const clippedZones = clipZonesToLayer(voronoiZones, multipolygon);

  const cleanedZones = cleanupVoronoiZones(
    clippedZones as FeatureCollection<Polygon>,
    zoneCentroids,
  );
  return cleanedZones.map(
    (polygonFeat) =>
      ({
        ...polygonFeat,
        geometry: {
          type: 'GeometryCollection',
          geometries: [polygonFeat.geometry],
        },
        type: 'Feature',
      }) as Feature<GeometryCollection>,
  );
};

export const voronoiTessellation = (
  points: FeatureCollection<Point>,
  multipolygon: FeatureCollection<MultiPolygon | Polygon>,
) => {
  const bbox = turfBbox(multipolygon);
  const tessellation = voronoi(points, { bbox });
  return {
    ...tessellation,
    features: tessellation.features.map((feature, index) => ({
      ...feature,
      properties: {
        ...points.features[index].properties,
      },
    })),
  };
};

export const filterZonesWithinBoundaries = (
  zones: FeatureCollection<MultiPolygon | Polygon>,
  multipolygon: FeatureCollection<MultiPolygon | Polygon>,
) => {
  const joinedPolygon = unionizeMultipolygon(multipolygon) as Feature<MultiPolygon | Polygon>;
  const features = zones.features
    .filter((feature) => feature.geometry)
    .filter((feature) => intersect(feature, joinedPolygon));
  return featureCollection(features.flat());
};

export const filterPointsWithinBoundaries = (
  points: FeatureCollection<Polygon | Point>,
  multipolygon: FeatureCollection<MultiPolygon | Polygon>,
) => {
  const joinedPolygon = unionizeMultipolygon(multipolygon);
  const filtered = points.features
    .filter((feature) => feature.geometry) // @ts-ignore
    .filter((feature) => inside(feature as Feature<Point>, joinedPolygon));
  return featureCollection(filtered.flat());
};

export const clipZonesToLayer = (
  zones: FeatureCollection<MultiPolygon | Polygon>,
  multipolygon: FeatureCollection<MultiPolygon | Polygon>,
) => {
  const joinedPolygon = unionizeMultipolygon(multipolygon) as Feature<MultiPolygon | Polygon>;
  return clipToFeature(zones, joinedPolygon) as FeatureCollection<Polygon>;
};

const clipToFeature = (
  zones: FeatureCollection<MultiPolygon | Polygon>,
  clipFeature: Feature<MultiPolygon | Polygon>,
) => {
  const featuresLists = zones.features.map((feature) => {
    try {
      const intersection = intersect(feature, clipFeature);
      const polygonZones = splitMultipolygon({
        ...intersection,
        properties: feature.properties,
      } as Feature<MultiPolygon | Polygon>);
      const areTilesWellFormed = polygonZones.every((feat) =>
        // @ts-ignore
        feat.geometry.coordinates.every((set: Position[][] | Position[]) =>
          isCoordinateSetWellFormed(set[0] as Position[]),
        ),
      );
      return areTilesWellFormed ? polygonZones : [feature];
    } catch (error) {
      return [feature];
    }
  });
  const filtered = featuresLists.flat().filter((feature) => feature.geometry);
  return featureCollection(filtered);
};

export const removeFirstPoint = (points: Position[]) =>
  points
    .slice(1, points.length - 1)
    .concat([points[1]])
    .filter(Boolean);

const isFirstPoint = (selectedIndex: number, points: Position[]) =>
  selectedIndex === points.length - 1 || selectedIndex === 0;

export const removePointAtIndex = (polygonFeat: Feature<Polygon>, index: number) => {
  const coordinates = polygonFeat.geometry.coordinates[0];
  return {
    ...polygonFeat,
    geometry: {
      coordinates: [
        isFirstPoint(index, coordinates as Position[])
          ? removeFirstPoint(coordinates as Position[])
          : coordinates.filter((_, idx) => idx !== index),
      ],
      type: POLYGON,
    },
  };
};

export const acresToFeetSideLength = (squareAcres: number) =>
  Math.sqrt(squareAcres * SQUARE_FEET_PER_ACRE);

export const squareMetersToAcres = (squareMeters: number) => squareMeters / SQUARE_METERS_PER_ACRE;

export const getFieldArea = (
  polygons: FeatureCollection<Geometry, GeoJsonProperties> | undefined,
  unitsLanguage: string,
) => {
  if (polygons) {
    return acToHectares(squareMetersToAcres(turfArea(polygons)), unitsLanguage);
  }
  return 0;
};

export const convertToCircle = (feature: Feature<Point>) =>
  circle(feature, POINT_RADIUS, {
    units: 'meters',
    steps: 100,
  });

interface CoordFeatType {
  center: Number[];
  geometry: {
    type: String;
    coordinates: Number[];
  };
  place_name: String;
  place_type: String[];
  properties: {};
  type: String;
}

export const coordinatesGeocoder = (query: string) => {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  // This is using a Mapbox example
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
  if (!matches) {
    return null;
  }
  const coordinateFeature = (lng: number, lat: number) => {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: `Lat: ${lat} Lng: ${lng}`,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    };
  };
  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes: CoordFeatType[] = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};

export const processSingleZoneFeature = (
  newFeat: Feature<MultiPolygon | Polygon | Point>,
  zoneNum: number,
  geomType: string,
) => ({
  ...newFeat,
  properties: {
    ...newFeat.properties,
    zone_number: zoneNum,
    sample_uuid: newFeat.properties?.sample_uuid || uuid(),
    renderType: geomType,
    zone_type: (geomType || '').toLowerCase().includes(ZONE_TYPES.POINT)
      ? ZONE_TYPES.POINT
      : ZONE_TYPES.POLYGON,
  },
});

export const processZoneFeatureCollection = (
  newFeats: FeatureCollection<MultiPolygon | Polygon | Point>,
  geomType: string,
) => ({
  ...newFeats,
  features: newFeats.features.map((geom, index) =>
    processSingleZoneFeature(geom, index + 1, geomType),
  ),
});

// Push new zones without uuids to the end, preserving original order
export const sortFeatures = (featsList: Feature<Polygon>[]) =>
  featsList.sort((a, b) => {
    const aUuid = a.properties?.sample_uuid;
    const bUuid = b.properties?.sample_uuid;
    const ternaryB = !bUuid ? -1 : 0;
    return !aUuid ? 1 : ternaryB;
  });

const sortSmallestToLargest = (a: Feature<Polygon>, b: Feature<Polygon>) =>
  a.properties?.zoneSize < b.properties?.zoneSize ? -1 : 1;

const addAreaPropAndSort = (features: Feature<Polygon>[]) =>
  features
    .map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        zoneSize: squareMetersToAcres(turfArea(feature)),
      },
    }))
    .sort((a, b) => sortSmallestToLargest(a, b));

/**
 * Takes a 2D array containing uuid tuples
 * For each pair, check if part of an existing group. If yes, add them to the group
 * * If part of multiple groups, combine both groups together
 * If part of no group, create a new group entry containing the current pair
 * Returns a dictionary object containing arrays of uuid strings
 * @param pairs
 * @returns Object
 */
const convertPairsToGroups = (pairs: string[][]) => {
  const groups: { [n: string]: string[] } = {};
  for (const pair of pairs) {
    let foundGroup: string | null = null;
    for (const groupName in groups) {
      if (groups[groupName].includes(pair[0]) || groups[groupName].includes(pair[1])) {
        if (foundGroup) {
          groups[foundGroup] = groups[foundGroup].concat(groups[groupName]);
          groups[groupName] = [];
        } else {
          if (!groups[groupName].includes(pair[0])) {
            groups[groupName].push(pair[0]);
          }
          if (!groups[groupName].includes(pair[1])) {
            groups[groupName].push(pair[1]);
          }
          foundGroup = groupName;
        }
      }
    }
    if (!foundGroup) {
      groups[pair[0]] = pair;
    }
  }

  return groups;
};

/**
 * Takes smallFeatures and pairs them with a neighbor sharing the longest border
 * Maps the list of pairs into groups based on overlapping pairs
 * For each group, merges all of the features together and replace all of the originals
 * If a targetNumZones is specified, stops merging once the desired number of features is reached
 * Returns a feature collection containing the merged features and features that weren't used in pairing
 * @param smallFeatures
 * @param allFeatures
 * @param targetNumZones
 * @returns FeatureCollection
 */
export const mergeSmallZonesHelper = (
  smallFeatures: Feature<Polygon>[],
  allFeatures: Feature<Polygon>[],
  targetNumZones?: number,
) => {
  // Create merge mapping for small features
  const featurePairs: string[][] = [];
  const removedUuids: string[] = [];
  for (const smallFeat of smallFeatures) {
    if (targetNumZones && targetNumZones === allFeatures.length - removedUuids.length) {
      break;
    }

    // Convert to linestring since the zones share borders
    const smallFeatString = lineString(
      smallFeat.geometry.coordinates[0] as Position[],
      smallFeat.properties,
    );

    // Zones technically intersect, but turf intersect shows null
    const neighborFeatures = allFeatures.filter(
      (neighborFeat) =>
        booleanIntersects(smallFeat, neighborFeat) &&
        smallFeat.properties?.sample_uuid !== neighborFeat.properties?.sample_uuid,
    );

    // Find zone with largest shared border
    const largestBorderingNeighbor = neighborFeatures.reduce(
      (featMax, zone) => {
        const polyToLine = lineString(zone.geometry.coordinates[0] as Position[], zone.properties);
        const overlapDistance = turfLength(lineOverlap(polyToLine, smallFeatString));
        if (overlapDistance >= featMax.overlap) {
          return {
            id: zone.properties?.sample_uuid,
            overlap: overlapDistance,
          };
        }
        return featMax;
      },
      { id: null, overlap: 0 } as { id: string | null; overlap: number },
    ).id;

    if (largestBorderingNeighbor) {
      featurePairs.push([smallFeat.properties?.sample_uuid, largestBorderingNeighbor]);
      if (!removedUuids.includes(largestBorderingNeighbor)) {
        removedUuids.push(smallFeat.properties?.sample_uuid);
      }
    }
  }
  const featureGroups = convertPairsToGroups(featurePairs);

  const mergedFeatures = Object.keys(featureGroups).reduce((list, group) => {
    const features = allFeatures.filter((feature) =>
      featureGroups[group].includes(feature.properties?.sample_uuid),
    ) as Feature<Polygon>[];
    if (features.length) {
      const unionized = union(...features) as Feature<Polygon>;
      return list.concat([unionized]);
    }
    return list;
  }, [] as Feature<Polygon>[]);

  const mergedIds = Object.keys(featureGroups).flatMap((group) => featureGroups[group]);
  const nonMergedFeatures = allFeatures.filter(
    (feature) => !mergedIds.includes(feature.properties?.sample_uuid),
  );

  const recalulatedAreas = addAreaPropAndSort([...nonMergedFeatures, ...mergedFeatures]);

  return featureCollection(recalulatedAreas);
};

/**
 * Takes allFeatures sorts them largest to smallest
 * Pops largest zone from the list, then splits and pushes the resulting zones back into the list
 * Sorts the list again before the next iteration
 * Repeats the above two steps until the target number of zones is reached
 * Returns a feature collection containing the sorted features
 * @param allFeatures
 * @param targetNumZones
 * @returns FeatureCollection
 */
export const splitLargeZonesHelper = async (
  allFeatures: Feature<Polygon>[],
  targetNumZones: number,
) => {
  const sortSmallToLarge = addAreaPropAndSort(allFeatures) as Feature<Polygon>[];

  // Split the largest zone every iteration
  while (sortSmallToLarge.length && sortSmallToLarge.length < targetNumZones) {
    const largest = sortSmallToLarge.pop();
    if (largest) {
      const split = await splitZone(largest);
      const withArea = addAreaPropAndSort(split.features);
      // Use existing array
      sortSmallToLarge.push(...withArea);
      sortSmallToLarge.sort((a, b) => sortSmallestToLargest(a, b));
    }
  }

  return featureCollection(sortSmallToLarge);
};

/**
 * Finds all features that have an area less than the minimum required for the multipolygon and targetNumZones.
 * Merges each of the features into its neighbors, replacing both the original and the neighbor.
 * If this results in too many zones remaining, merge again based on desired targetNumZones
 * If this results in too few zones remaining, split zones based on desired targetNumZones
 * Returns a feature collection containing the merged and unmerged features.
 * @param collection
 * @param targetNumZones
 * @param multipolygon
 * @returns FeatureCollection
 */
export const mergeZonesUnderThreshold = async (
  collection: FeatureCollection<MultiPolygon | Polygon>,
  targetNumZones: number,
  multipolygon: FeatureCollection<MultiPolygon | Polygon>,
  threshold: number = MINIMUM_FIELD_THRESHOLD_PERCENT,
) => {
  const joinedPolygon = unionizeMultipolygon(multipolygon) as Feature<MultiPolygon | Polygon>;
  const fieldArea = squareMetersToAcres(turfArea(joinedPolygon));
  const targetAvgArea = fieldArea / targetNumZones;
  const minimumAreaByPercentage = targetAvgArea * threshold;
  const minimumArea = Math.max(minimumAreaByPercentage, MINIMUM_THRESHOLD_ZONE_ACRE);
  const sortSmallToLarge = addAreaPropAndSort(
    processZoneFeatureCollection(collection, POLYGON).features as Feature<Polygon>[],
  );
  const tooSmallFeatures = sortSmallToLarge.filter(
    (feature) => feature.properties?.zoneSize < minimumArea,
  );

  // Remove small zones
  const aboveThreshFeats = mergeSmallZonesHelper(tooSmallFeatures, sortSmallToLarge).features;

  if (aboveThreshFeats.length > targetNumZones) {
    // Remove zones if still above target number
    const numToRemove = aboveThreshFeats.length - targetNumZones;
    const mergedSmall = mergeSmallZonesHelper(
      aboveThreshFeats.slice(0, numToRemove),
      aboveThreshFeats,
    );
    const removedMicroZones = mergedSmall.features.filter(
      (feature) => feature.properties?.zoneSize > MINIMUM_THRESHOLD_ZONE_ACRE,
    );
    return featureCollection(removedMicroZones);
  }
  const removedMicroZones = aboveThreshFeats.filter(
    (feature) => feature.properties?.zoneSize > MINIMUM_THRESHOLD_ZONE_ACRE,
  );
  if (removedMicroZones.length < targetNumZones) {
    // Split some zones if below target number
    return splitLargeZonesHelper(removedMicroZones, targetNumZones);
  }
  return featureCollection(removedMicroZones);
};

/**
 * Sorts all features by area, selecting (collection size - targetNumZones) of the smallest ones to process.
 * Merges each of the features into its neighbors, replacing both the original and the neighbor.
 * Returns a feature collection containing the merged and unmerged features.
 * @param collection
 * @param targetNumZones
 * @returns FeatureCollection
 */
export const mergeSmallZones = (
  collection: FeatureCollection<MultiPolygon | Polygon>,
  targetNumZones: number,
) => {
  const sortSmallToLarge = addAreaPropAndSort(
    processZoneFeatureCollection(collection, POLYGON).features as Feature<Polygon>[],
  );
  if (sortSmallToLarge.length === targetNumZones) {
    return featureCollection([...sortSmallToLarge]);
  }

  return mergeSmallZonesHelper(sortSmallToLarge, sortSmallToLarge, targetNumZones);
};

/**
 * Finds for any feature that does not contain a specific voronoi centroid and merges them with their neighbors.
 * Clipping voronoi tessalation to a field can generate 'orphan' zones that do not contain any of the original point geometries.
 * This algorithm first assigns sample_uuids to any zone missing them (orphan zones)
 * Then, grabs all zones that do not contain any of the point geometries.
 * These 'orphans' are merged into their longest bordering neighbor (see mergeSmallZoneHelper)
 * Repeats this process while the number of zones > number of points
 * Once done, reassign properties to zones based on what point geometry they contain. Filter any zones that still do not contain any points.
 * Returns a feature collection containing the merged and unmerged features.
 * @param collection
 * @param points
 * @returns Feature[]
 */
export const cleanupVoronoiZones = (
  collection: FeatureCollection<Polygon>,
  points: Feature<Point>[],
) => {
  if (collection.features.length === points.length) {
    return collection.features;
  }

  const sortSmallToLarge = addAreaPropAndSort(
    processZoneFeatureCollection(collection, POLYGON).features as Feature<Polygon>[],
  ) as Feature<Polygon>[];

  const mergedZones = recursiveVoronoiMerge(sortSmallToLarge, points);

  // Re-assign properties to zones. Exclude any zones that do not contain original point.
  return mergedZones.reduce((featList, feat) => {
    const containsPoint = points.find((point) => booleanPointInPolygon(point, feat));
    if (containsPoint) {
      return featList.concat({
        ...feat,
        properties: containsPoint.properties,
      });
    }
    return featList;
  }, [] as Feature<Polygon>[]);
};

const recursiveVoronoiMerge = (
  zones: Feature<Polygon>[],
  points: Feature<Point>[],
  n: number = 0,
): Feature<Polygon>[] => {
  // Attempt to merge orphan zones up to 5 loops.
  if (zones.length <= points.length || n >= 5) {
    return zones;
  }

  const orphanZones = zones.filter(
    (zone) => !points.find((point) => booleanPointInPolygon(point, zone)),
  );
  const mergedZones = mergeSmallZonesHelper(orphanZones, zones, points.length).features;
  return recursiveVoronoiMerge(mergedZones, points, n + 1);
};

export const getNewZonesFromDrag = (
  latLong: [number, number],
  previewZones: FeatureCollection<Polygon | Point>,
) => {
  if (previewZones?.features) {
    const centerPoly = center(previewZones);
    const angleOfMove = turfBearing(centerPoly, latLong);
    const distanceOfMove = turfDistance(centerPoly, latLong);
    return {
      ...previewZones,
      features: previewZones.features.map((feat) =>
        transformTranslate(feat, distanceOfMove, angleOfMove),
      ),
    };
  }
  return previewZones;
};

export const getRotatedGridZones = (
  isClockwise: boolean,
  previewZones: FeatureCollection<Polygon | Point>,
) => {
  const centerOfGrid = center(previewZones);
  const rotationAngle = isClockwise ? 0.5 : -0.5;
  return {
    rotationAngle,
    newPreviewZones: previewZones?.features.map((feat) =>
      transformRotate(feat, rotationAngle, {
        pivot: centerOfGrid,
      }),
    ),
  };
};

export const splitZone = async (feature: Feature<Polygon | MultiPolygon>) => {
  const zoneCentroids = await postSplitZone(feature);
  const bbox = turfBbox(feature);
  const voronoiZones = voronoi(zoneCentroids, { bbox });
  // Maintain true shape of feature, don't unionize
  const clippedZones = clipToFeature(voronoiZones, feature);
  return mergeSmallZones(clippedZones, 2);
};

// Used for uploading pro points. Take whatever Polygons they give us and convert them to Points
export const convertToDisplayPoint = (feature: Feature<Point | Polygon>) =>
  convertToPolygon(centroid(feature), POINT);

// Convert a point to a circle to store correctly on the backend
// -- used in plan submission
export const convertToPolygon = (feat: Feature<Point | Polygon>, zoneGeomType: string) => {
  if (zoneGeomType === POINT && feat.geometry?.type === POINT) {
    const converted = convertToCircle(feat as Feature<Point>);
    return converted.geometry;
  }
  return feat.geometry;
};

// Point zones are created with 101 coordinates in turf
const POINT_COORD_ARRAY_LEN = 101;
export const isPointZone = (feat) => feat.geometry?.coordinates?.length === POINT_COORD_ARRAY_LEN;

/**
 * Turf doesn't have a proper clip function, so we have to use intersect.
 *
 * @param features poly/multipoly features to clip
 * @param boundary poly/multipoly boundary to clip to
 * @returns feature collection of clipped features
 */
export const clipFeaturesToBoundary = (
  features: PolyOrMultiPolyFeature[],
  boundary: PolyOrMultiPolyFeature,
): GeoJSON.FeatureCollection<PolyOrMultiPoly> => {
  return featureCollection(
    features
      .map((f) => {
        const intersection = intersect(f, boundary, f.properties || {});

        if (!intersection) {
          return null;
        }

        return {
          ...intersection,
          properties: f.properties,
        };
      })
      .filter(Boolean) as PolyOrMultiPolyFeature[],
  );
};

/**
 * For 590 maps, we need to display the sample id underneath sample geometry.
 * Preserving position at each zoom level is required so we can't rely on text-offset.
 * Translate geojson 80% of height due south to align to sample geometry.
 *
 * @param samples mapbox samples to translate
 * @returns list of translated samples
 */
export const translate590Layer = (samples: MapboxSample[]): Feature<Geometry>[] => {
  return samples.map((sample) => {
    const bbox = turfBbox(sample);
    const height = bbox[3] - bbox[1];
    return transformTranslate(sample as AllGeoJSON, height * 0.8, 180, {
      units: 'degrees',
    }) as Feature<Geometry>;
  });
};

/** Get `[minX, maxX, minY, maxY]` from map viewport */
export const getBoundsFromViewport = (viewport: ViewPortProps) => {
  const { latitude, longitude, zoom } = viewport;

  return [
    longitude - CLU_ZOOM_BUFFER / (CLU_LONGITUTE_BUFFER * zoom),
    longitude + CLU_ZOOM_BUFFER / (CLU_LONGITUTE_BUFFER * zoom),
    latitude - CLU_ZOOM_BUFFER / (CLU_LATITUDE_BUFFER * zoom),
    latitude + CLU_ZOOM_BUFFER / (CLU_LATITUDE_BUFFER * zoom),
  ] as [number, number, number, number];
};
