import React from 'react';
import { Box, Text } from '@mantine/core';

import { getString } from 'strings/translation';

type Props = {
  language: string;
  hasMissingSample: boolean;
};

const MapbookSampleWarnings = ({ language, hasMissingSample }: Props) => {
  return (
    <>
      {hasMissingSample && (
        <Box>
          <Text size="xs" fs="italic">
            * {getString('averageAppliedToZone', language)}
          </Text>
        </Box>
      )}
    </>
  );
};

export default MapbookSampleWarnings;
