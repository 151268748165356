import { createSelector } from 'reselect';

import {
  userIsAdmin as userHasAdminRole,
  userIsAgencyAdmin as userHasAgencyAdminRole,
  userIsAgent as userHasAgentRole,
  userIsLab as userHasLabRole,
  userIsOperator as userHasOperatorRole,
  userIsSampler as userHasSamplerRole,
  userIsSuperAdmin as userHasSuperAdminRole,
} from 'util/userRoles';
import { RootState } from 'store';

export const allUsers = (state: RootState) => state.user.allUsers;

export const user = (state: RootState) => state.user.currentUser;
export const userHasFailed = (state: RootState) => state.user.hasFailed;
export const userHasFetched = (state: RootState) => state.user.hasFetched;
const userRoles = (state: RootState) => state.user.currentUser?.roles || [];

export const userIsAdmin = createSelector(userRoles, userHasAdminRole);
export const userIsAgencyAdmin = createSelector(userRoles, userHasAgencyAdminRole);

export const matchingUser = (id) =>
  createSelector([allUsers, id], (users, userId) => users.find((u) => u.id === userId));

export const userIsLab = createSelector(userRoles, userHasLabRole);
export const userIsAgent = createSelector(userRoles, userHasAgentRole);
export const userIsOperator = createSelector(userRoles, userHasOperatorRole);
export const userIsSampler = createSelector(userRoles, userHasSamplerRole);

export const userIsSuperAdmin = createSelector(userRoles, userHasSuperAdminRole);

export const userCanEditPlans = (state: RootState, operationId: number) => {
  if (userIsAdmin(state) || userIsSuperAdmin(state)) {
    return true;
  }
  const { currentUser } = state.user;
  const operation = state.operations.operationsById[operationId];
  const agencies = state.user.currentUser?.agencies;
  const editableAgencies = agencies?.[0]?.editable_agencies || [];
  if (agencies) {
    editableAgencies.push(agencies[0]?.id);
  }
  const agencyPrimaryId = agencies?.[0]?.primary_user?.id;
  if (
    userIsAgencyAdmin(state) &&
    operation.billing_agency_id &&
    editableAgencies.includes(operation.billing_agency_id)
  ) {
    return true;
  }

  if (userIsAgent(state) && agencyPrimaryId === operation?.billing_user_id) {
    return true;
  }
  if (
    (userIsAgencyAdmin(state) || userIsAgent(state)) &&
    currentUser &&
    operation.users.includes(currentUser.id)
  ) {
    return true;
  }
  return (
    userIsOperator(state) &&
    state.user.currentUser &&
    operation &&
    operation.billing_user_id === state.user.currentUser.id
  );
};
