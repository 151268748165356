import { useQuery } from '@tanstack/react-query';

import { TRUBIO_SKU_ID, TRUNUTRIENT_SKU_ID } from 'constants/pricing';

import { totalFarmQueryKeys } from 'util/queryKeys';
import { generateTotalFarmPriceEstimate } from 'store/operation/requests';

type TotalFarmQueryType = {
  operationId: number;
  totalFarmAcres: number;
  truBioPercent: number;
  truNutrientPercent: number;
  enabled: boolean;
};

export const useTotalFarmPriceQuery = ({
  operationId,
  totalFarmAcres,
  truBioPercent,
  truNutrientPercent,
  enabled,
}: TotalFarmQueryType) => {
  return useQuery({
    queryKey: totalFarmQueryKeys.operationPriceEstimate({
      operationId,
      totalFarmAcres,
      truBioPercent,
      truNutrientPercent,
    }),
    queryFn: () =>
      generateTotalFarmPriceEstimate({
        operation_id: operationId,
        total_acres: totalFarmAcres,
        sku_percentages: [
          {
            sku_id: TRUBIO_SKU_ID,
            percentage: truBioPercent,
          },
          {
            sku_id: TRUNUTRIENT_SKU_ID,
            percentage: truNutrientPercent,
          },
        ].filter((sku) => sku.percentage),
      }),
    enabled,
  });
};
