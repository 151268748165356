import { Stack, Table } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { dataReviewQueryKeys } from 'util/queryKeys';
import { getString } from 'strings/translation';
import { getGroupedCollectionsForOneField } from 'store/eoCollections/requests';
import {
  EOCollectionFeatureType,
  EOCollectionFieldFeatCollection,
  GroupedCollectionsListPayload,
} from 'store/eoCollections/types';
import CenteredLoader from 'common/CenteredLoader';
import CenteredErrorMessage from 'common/Components/CenteredErrorMessage';
import ErrorAlert from 'common/ErrorAlert';

import CollectionMap from './CollectionMap';
import DataReviewCollectionRow from './DataReviewCollectionRow';

type Props = {
  fieldFeatureCollection: EOCollectionFieldFeatCollection;
  fieldId: number;
  language: string;
  selectedCollectionId: number | null;
  currentGetPayload: GroupedCollectionsListPayload;
  onRowClick: (id: number | null) => void;
};

const MILLISECONDS_IN_TWO_DAYS = 48 * 60 * 60 * 1000;

const scansWereCollectedTooFarApart = (collections: EOCollectionFeatureType[]): boolean => {
  if (collections.length < 2) return false;

  const sortedDates = collections
    .map((item) => new Date(item.properties.created_at).getTime()) // convert to timestamps
    .sort((a, b) => a - b);

  for (let i = 1; i < sortedDates.length; i += 1) {
    if (sortedDates[i] - sortedDates[i - 1] > MILLISECONDS_IN_TWO_DAYS) {
      return true;
    }
  }

  return false;
};

const DataReviewItemContent = ({
  fieldFeatureCollection,
  fieldId,
  language,
  onRowClick,
  selectedCollectionId,
  currentGetPayload,
}: Props) => {
  const query = useQuery({
    queryKey: dataReviewQueryKeys.collectionsByField(fieldId, currentGetPayload.status),
    queryFn: () => getGroupedCollectionsForOneField(currentGetPayload, fieldId),
  });

  if (query.isPending) {
    return <CenteredLoader />;
  }

  if (query.isError) {
    return <CenteredErrorMessage title={query.error.message} centerProps={{ h: '6rem' }} />;
  }

  if (query.data) {
    const collections = query.data.eo_collections;

    return (
      <>
        <Stack flex={1}>
          {scansWereCollectedTooFarApart(collections) && (
            <ErrorAlert
              title={getString('twoDayCollnWarnTitle', language)}
              content={getString('twoDayCollnWarnMsg', language)}
            />
          )}
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{getString('collectionName', language)}</Table.Th>
                <Table.Th>{getString('collectionDate', language)}</Table.Th>
                <Table.Th>{getString('scannedBy', language)}</Table.Th>
                <Table.Th>{getString('scanningTime', language)}</Table.Th>
                <Table.Th>{getString('fieldCoverage', language)}</Table.Th>
                <Table.Th>ADCP</Table.Th>
                <Table.Th>EMI</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {collections.map((collection) => (
                <DataReviewCollectionRow
                  key={collection.properties.id}
                  isDeletable={currentGetPayload.status === 'pending'}
                  collection={collection}
                  language={language}
                  onRowClick={onRowClick}
                  selectedCollectionId={selectedCollectionId}
                />
              ))}
            </Table.Tbody>
          </Table>
        </Stack>
        <CollectionMap
          field={fieldFeatureCollection}
          selectedCollectionId={selectedCollectionId}
          collections={collections}
        />
      </>
    );
  }

  return null;
};

export default DataReviewItemContent;
