import { Select } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { getProPointCreationOptions } from 'util/samplePlan';
import { getString } from 'strings/translation';

type Props = {
  language: string;
  disabled: boolean;
  onChange: (val: string | null) => void;
  showTillMapperLabel: boolean;
};

const ProPointTypeSelect = ({ language, onChange, disabled, showTillMapperLabel }: Props) => {
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const inputProps = form.getInputProps('proPointCreationOption');
  const options = getProPointCreationOptions(
    language,
    formValues.completedScanPoints.length ? formValues.completedPlan : null,
  );

  return (
    <Select
      key={form.key('proPointCreationOption')}
      label={getString(showTillMapperLabel ? 'tillMapperPoints' : 'nutrientPoints', language)}
      data={options}
      w="11rem"
      disabled={disabled}
      {...inputProps}
      onChange={(value) => {
        if (value) {
          inputProps.onChange(value);
          onChange(value);
        }
      }}
      value={formValues.proPointCreationOption}
    />
  );
};

export default ProPointTypeSelect;
