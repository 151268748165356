import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Input, Loader, Space, Stack, Switch, Text, TextInput } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import {
  createRecommendationSet,
  editRecommendationSet,
  getAllRecommendationSets,
} from 'store/recommendationSets/thunks';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { UserAgencySelectorType } from 'store/user/types';
import { UserAgencySearch } from 'common';

import styles from '../Container.module.css';

type SetAttributesPropsType = {
  recSet?: Partial<RecommendationSetType>;
};

const SetAttributes = ({ recSet }: SetAttributesPropsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const [isSaving, toggleIsSaving] = useState(false);
  const [isSearching, toggleIsSearching] = useState(false);
  const [newRecSet, setNewRecSet] = useState<Partial<RecommendationSetType>>({
    name: '',
    show_in_ui: true,
    recommendations: [],
    agency: null,
  });

  useEffect(() => {
    if (recSet) {
      setNewRecSet(recSet);
    }
  }, [recSet]);

  const saveForm = () => {
    toggleIsSaving(true);
    try {
      const body = {
        ...newRecSet,
        agency_id: newRecSet?.agency?.id || 0,
      };
      if (recSet?.id) {
        dispatch(editRecommendationSet(recSet?.id, body));
      } else {
        dispatch(createRecommendationSet(body));
      }
      toggleIsSaving(false);
      dispatch(getAllRecommendationSets());
      navigate('/product/recommendation_sets');
    } catch (e) {
      showToast((e as Error).message, 'error');
    }
  };

  const handleAgencySelection = (selected: UserAgencySelectorType | null) => {
    if (selected) {
      const { agency_id, displayName } = selected;
      if (agency_id) {
        setNewRecSet((prev) => ({
          ...prev,
          agency: selected ? { id: agency_id, name: displayName } : null,
        }));
      }
    }
  };

  const updateRecSet = (key: string, value: any) => {
    setNewRecSet((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDeselect = () => {
    setNewRecSet((prev) => ({
      ...prev,
      agency: null,
    }));
  };

  return (
    <Stack>
      <Space h="xl" />
      <Text size="lg">Recommendation Set Attributes</Text>
      <Group gap="xl">
        <Input.Wrapper label="Recommendation Set Name">
          <TextInput
            onChange={(event) => updateRecSet('name', event.currentTarget.value)}
            value={newRecSet?.name || ''}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Show In UI">
          <Switch
            checked={newRecSet?.show_in_ui}
            className={styles.Toggle}
            onChange={() => updateRecSet('show_in_ui', !newRecSet?.show_in_ui)}
          />
        </Input.Wrapper>
        <Input.Wrapper label={<>Agency {isSearching && <Loader size="0.7rem" />}</>}>
          <UserAgencySearch
            placeholder={getString('searchAgency', language)}
            onSelect={handleAgencySelection}
            onDeselect={handleDeselect}
            toggleIsSearching={toggleIsSearching}
            selected={
              newRecSet?.agency
                ? { id: String(newRecSet?.agency?.id), displayName: newRecSet?.agency?.name }
                : null
            }
            isAgencyOnly
          />
        </Input.Wrapper>
        <Input.Wrapper label="Created By Id">
          <div>{recSet?.created_by_id}</div>
        </Input.Wrapper>
      </Group>
      <Group justify="flex-end">
        <Button loading={isSaving} onClick={saveForm}>
          {isSaving ? 'Saving' : 'Save'}
        </Button>
        <Button variant="outline" onClick={() => navigate(`/product/recommendation_sets`)}>
          Cancel
        </Button>
      </Group>
    </Stack>
  );
};

export default SetAttributes;
