import React from 'react';

import { BR, COUNTRY_OPTIONS } from 'constants/countries';
import { BRAZIL_STATE_OPTIONS, STATE_OPTIONS } from 'constants/states';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BillingAddress } from 'store/user/types';
import { Input, Label, Selector } from 'common';

import styles from './ContactInfo.module.css';

interface Props {
  updateBillingAddress: (addressKey: string, addressValue: string) => void;
  updateZipcode: (value: string) => void;
  address: BillingAddress;
}

const BillingInfo = ({ updateBillingAddress, address, updateZipcode }: Props) => {
  const language = useBroswerLanguage();

  const stateOptions = (address.country_code === BR ? BRAZIL_STATE_OPTIONS : STATE_OPTIONS).map(
    (state) => ({
      displayName: state,
      value: state,
      id: state,
    }),
  );

  return (
    <>
      <div className={styles.WrapperRow}>
        <Label label={getString('country', language)}>
          <Selector
            className={styles.ThreeWideInput}
            dataTestId="country-code"
            activeIndex={COUNTRY_OPTIONS.findIndex(
              (country) => country.value === address?.country_code,
            )}
            onChange={(idx) => updateBillingAddress('country_code', COUNTRY_OPTIONS[idx].value)}
            options={COUNTRY_OPTIONS}
          />
        </Label>
      </div>
      <div className={styles.WrapperRow}>
        <Label label={getString('billingAddress', language)}>
          <Input
            dataTestId="street-name"
            className={styles.StreetInput}
            onChange={(e) => updateBillingAddress('street', e.target.value)}
            value={address?.street}
          />
        </Label>
      </div>
      <div className={styles.WrapperRow}>
        <Label label={getString('city', language)} className={styles.ThreeWideInput}>
          <Input
            dataTestId="city"
            className={styles.ThreeWideInput}
            onChange={(e) => updateBillingAddress('city', e.target.value)}
            value={address?.city}
          />
        </Label>
        <Label label={getString('state', language)}>
          <Selector
            className={styles.ThreeWideInput}
            dataTestId="state"
            activeIndex={stateOptions.findIndex((state) => state.value === address?.state)}
            onChange={(idx) => updateBillingAddress('state', stateOptions[idx].value)}
            options={stateOptions}
          />
        </Label>
        <Label label={getString('zipcode', language)}>
          <Input
            dataTestId="zip-code"
            className={styles.ThreeWideInput}
            onChange={(e) => updateZipcode(e.target.value)}
            value={address?.zip}
          />
        </Label>
      </div>
    </>
  );
};

export default BillingInfo;
