import React from 'react';
import { Button, Flex, Modal, Stack, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RED } from 'constants/colors';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import { dataReviewQueryKeys } from 'util/queryKeys';
import { getString } from 'strings/translation';
import showToast, { getToastColor, getToastIcon } from 'actions/toastActions';
import { deleteCollection } from 'store/eoCollections/requests';

type Props = {
  collectionId: number;
  language: string;
  opened: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const getToastId = (collectionId: number) => `deletingCollection-${collectionId}`;

const DeleteCollectionModal = ({ language, collectionId, onClose, opened, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const toastId = getToastId(collectionId);

  const mutation = useMutation({
    mutationFn: () => deleteCollection(collectionId),
    onMutate: () =>
      showToast(getString('deletingCollection', language), undefined, undefined, {
        id: toastId,
      }),
    onError: (err) => showToast(err.message || getString('requestFailed', language), 'error'),
    onSuccess: () => {
      notifications.update({
        id: toastId,
        message: getString('collectionDeleted', language),
        color: getToastColor('success'),
        icon: getToastIcon('success'),
      });

      queryClient.invalidateQueries({
        type: 'active',
        queryKey: dataReviewQueryKeys.groupedCollections(),
      });

      onSuccess();
      onClose();
    },
  });

  return (
    <Modal opened={opened} onClose={onClose} centered withCloseButton={false} size="lg" ta="center">
      <Stack p="2rem">
        <Title
          order={1}
          fw={FONT_WEIGHT_BOLD}
        >{`${getString('deleteCollection', language)}?`}</Title>
        <Text>{getString('deleteCollectionConfirm', language)}</Text>
        <Flex justify="flex-end">
          <Button bg={RED} onClick={onClose} variant="filled">
            {getString('cancel', language)}
          </Button>
          <Button ml="1rem" onClick={() => mutation.mutate()}>
            {getString('confirm', language)}
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};

export default DeleteCollectionModal;
