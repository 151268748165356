import { Checkbox, Grid, TextInput } from '@mantine/core';
import { useProjectsTranslations } from 'apps/Projects/hooks';
import { useSamplePlanFormContext } from 'apps/Projects/SamplePlans/samplePlanFormContext';

import { SamplePlanForm } from 'store/projects/types';
import InputAndLabelGridRow from 'common/Components/InputAndLabelGridRow';

import FormHeading from './FormHeading';

type FormKey = keyof SamplePlanForm;

const STRATIFY_FORM_KEY: FormKey = 'stratify';
const MIN_CORE_SAMPLES_KEY: FormKey = 'minMeterCoreSamplesPerStratum';

const StratificationSection = () => {
  const form = useSamplePlanFormContext();
  const translations = useProjectsTranslations();

  return (
    <div>
      <FormHeading text={translations.stratification} />
      <Grid>
        <InputAndLabelGridRow
          elemId="stratify"
          disabled
          label={`${translations.stratify}?`}
          input={
            <Checkbox
              key={form.key(STRATIFY_FORM_KEY)}
              id="stratify"
              disabled
              {...form.getInputProps(STRATIFY_FORM_KEY, { type: 'checkbox' })}
            />
          }
        />
        <InputAndLabelGridRow
          elemId="min-core-samples"
          disabled
          label={translations.minimumMeterCoreSamples}
          input={
            <TextInput
              key={form.key(MIN_CORE_SAMPLES_KEY)}
              id="min-core-samples"
              disabled
              {...form.getInputProps(MIN_CORE_SAMPLES_KEY)}
            />
          }
        />
      </Grid>
    </div>
  );
};

export default StratificationSection;
