import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getApprovedPayments } from 'store/payments/thunks';
import { Pagination, Spinner } from 'common';

import ApprovedTable from '../common/Tables/ApprovedTable';

import styles from './Container.module.css';

type ApprovedListPropsType = {
  filter: string;
  page: number;
  setPage: (page: number) => void;
  selectedRole: string | undefined;
};

const ApprovedList = ({ filter, page, setPage, selectedRole }: ApprovedListPropsType) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const fetchApprovedPayments = useCallback(
    (pageNum, filterVal, role) => dispatch(getApprovedPayments(pageNum, filterVal, role)),
    [dispatch],
  );

  const { approved, totalApprovedPages, isFetching } = useSelector((state: RootState) => ({
    approved: state.payments.approved.items,
    approvedPage: state.payments.approved.page,
    totalApprovedPages: state.payments.approved.total,
    isFetching: state.payments.isFetching,
  }));

  useEffect(() => {
    fetchApprovedPayments(page, filter, selectedRole);
  }, [fetchApprovedPayments, page, filter, selectedRole]);

  const approvedRow = (paymentUuid: string) => {
    navigate(`/payments/approved/details/${paymentUuid}`);
  };

  return isFetching ? (
    <>
      <Spinner fill />
    </>
  ) : (
    <>
      {approved.length ? (
        <>
          <ApprovedTable rowClick={approvedRow} payments={approved} />
          <div className={styles.Pagination}>
            <Pagination page={page} setPage={setPage} totalPages={totalApprovedPages} />
          </div>
        </>
      ) : (
        <div className={styles.NoPaymentsWrapper}>
          <h2>{getString('youHaveNoApprovedMsg', language)}</h2>
        </div>
      )}
    </>
  );
};

export default ApprovedList;
