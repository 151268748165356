import React from 'react';
import { Box, BoxProps, Group, Text } from '@mantine/core';

import { LEGEND_MAX_WIDTH } from 'constants/mapbox';

import { MapLegendItem } from '../types';

import { LegendGradient } from './LegendGradient';

import styles from './MapLegend.module.css';

type Props = {
  /** No gradient shown if neither colorStops nor marks are passed. */
  colorStops?: string[];
  marks?: (number | string)[];
  showPlus?: boolean;
  title: React.ReactNode;
  rootBoxProps?: BoxProps;
  maxWidth?: number;
  /**
   * Non-gradient symbology. List of symbol/label pairs, either icon + label, or swatch + label. See
   * `MapLegendItem` docs for more info.
   */
  items?: MapLegendItem[];
};

export const LegendWrap = ({
  colorStops,
  marks,
  showPlus,
  title,
  rootBoxProps = {},
  maxWidth,
  items,
}: Props) => {
  return (
    <Box
      className={styles.legendWrap}
      px="xs"
      pt={4}
      pb="md"
      {...rootBoxProps}
      style={{
        maxWidth: maxWidth || LEGEND_MAX_WIDTH,
        ...rootBoxProps.style,
      }}
    >
      <Text
        fw="bold"
        component="h3"
        fz="sm"
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {title || ' '}
      </Text>
      {(colorStops || marks) && (
        <LegendGradient colorStops={colorStops} marks={marks} showPlus={showPlus} />
      )}
      {items?.map((item, index) => (
        <Group
          key={index}
          gap="xs"
          align="center"
          display="grid"
          style={{ gridTemplateColumns: '32px 1fr' }}
        >
          {item.icon || (
            <Box
              w={28}
              h={18}
              style={{
                backgroundColor: item.fillColor,
                borderColor: item.outlineColor,
                borderWidth: 2,
                borderStyle: 'solid',
                borderRadius: 4,
              }}
            />
          )}
          <Text fz="xs">{item.label}</Text>
        </Group>
      ))}
    </Box>
  );
};
