import React from 'react';
import { Group, SegmentedControl, Text } from '@mantine/core';

import { getDataReviewGroupByOptions } from 'util/dataReview';
import { getString } from 'strings/translation';
import { DataReviewGroupViewType } from 'store/eoCollections/types';

type Props = {
  currentValue: DataReviewGroupViewType;
  language: string;
  onChange: (value: DataReviewGroupViewType) => void;
};

const GroupDataSwitcher = ({ currentValue, language, onChange }: Props) => {
  return (
    <Group gap="sm">
      <Text size="sm" fw="bolder">
        {getString('groupBy', language)}
      </Text>
      <SegmentedControl
        value={currentValue}
        onChange={(val) => onChange(val as DataReviewGroupViewType)}
        data={getDataReviewGroupByOptions(language)}
      />
    </Group>
  );
};

export default GroupDataSwitcher;
