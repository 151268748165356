import React from 'react';

import HeaderLogo from 'images/earthoptics-logo.svg';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

import Styles from './Container.module.css';

const TextOptOut = () => {
  const language = useBroswerLanguage();

  return (
    <div className={Styles.Wrapper}>
      <div>
        <img alt="EarthOptics Logo" className={Styles.Logo} src={HeaderLogo} />
        <div data-test-id="text-opt-out" className={Styles.OptOutText}>
          {getString('optOutText', language)}
        </div>
      </div>
    </div>
  );
};

export default TextOptOut;
