import { FaPencil } from 'react-icons/fa6';
import { Button, Group, Modal, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { PROJECT_NAME_FORM_KEY } from 'constants/projects';

import { useEditProjectMutation, useProjectsTranslations } from './hooks';
import ProjectNameInput from './ProjectNameInput';

type Props = {
  projectId: number;
  currentProjectName: string;
};

const EditProjectNameModal = ({ projectId, currentProjectName }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const translations = useProjectsTranslations();
  const editProjectMutation = useEditProjectMutation(close);

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const projectName = formData.get(PROJECT_NAME_FORM_KEY) as string;

    editProjectMutation.mutate({ projectName, projectId });
  };

  return (
    <>
      <div>
        <Button onClick={open} aria-label={translations.editProjectName} variant="subtle">
          <FaPencil />
        </Button>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title={translations.editProjectName}
        centered
        component="form"
        onSubmit={handleSubmit}
      >
        <Stack>
          <ProjectNameInput defaultValue={currentProjectName} autoFocus />
          <Group gap="xs" justify="end">
            <Button variant="outline" onClick={close}>
              {translations.cancel}
            </Button>
            <Button type="submit" loading={editProjectMutation.isPending}>
              {translations.submit}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default EditProjectNameModal;
