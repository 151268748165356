import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useBroswerLanguage from 'util/hooks/useLanguage';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { completeFieldEventIngest } from 'store/fieldEvents/requests';
import { editFieldEventFieldId } from 'store/fieldEvents/thunks';
import { FieldEventType } from 'store/fieldEvents/types';
import getJobs from 'store/jobs/thunks';
import { getOperation } from 'store/operation/thunks';
import { Button, Header, Spinner } from 'common';

import FieldEditor from './FieldEditor';
import FileSummary from './FileSummary';

import styles from './Container.module.css';

const IngestSummaryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, runId } = useParams<{ id: string; runId: string }>();
  const operationId = Number(id);
  const jobId = Number(runId);
  const language = useBroswerLanguage();
  const [idsToExclude, setIdsToExclude] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(operationId)),
    [dispatch, operationId],
  );

  const fetchJobs = useCallback(() => dispatch(getJobs(operationId)), [dispatch, operationId]);

  const { fieldEvents, jobs, operation } = useSelector((state: RootState) => ({
    fieldEvents: state.fieldEvents.events,
    jobs: state.jobs.jobsByOperation[operationId] || [],
    operation: state.operations.operationsById[operationId],
  }));

  useEffect(() => {
    fetchOperation();
    fetchJobs();
  }, [fetchJobs, fetchOperation]);

  const { allFieldGeometries } = useOperationFieldGeometries(operation, true, true);

  const runData = useMemo(() => {
    const runJobs = jobs.filter((job) => job.run_id === jobId);

    const allFieldEvents = Object.values(fieldEvents).reduce(
      (result, fieldList) => result.concat(fieldList),
      [],
    );
    return {
      runFieldEvents: allFieldEvents.reduce((allEvents: FieldEventType[], event) => {
        if (
          runJobs.map((job) => job.id).includes(event.job_id as number) &&
          !allEvents.map((ev) => ev.field_id).includes(event.field_id)
        ) {
          return [...allEvents, event];
        }
        return allEvents;
      }, []),
      runJobs,
    };
  }, [fieldEvents, jobId, jobs]);

  const updateEvent = (event: FieldEventType, newId: number, newFieldName?: string) => {
    dispatch(editFieldEventFieldId(event, newId, newFieldName, operationId));
  };

  const completeQueuedIngest = async (ids: number[]) => {
    for (let i = 0; i < ids.length; i += 10) {
      const chunk = ids.slice(i, i + 10);
      const eventIdsToIngest = chunk.map((eventId) => completeFieldEventIngest(eventId));
      await Promise.all(eventIdsToIngest);
    }
  };

  const completeIngest = useCallback(async () => {
    setIsSubmitting(true);
    const allFieldEventIds = runData.runFieldEvents.map((event) => event.id);

    const eventIdsToIngest = allFieldEventIds.filter((eventId) => !idsToExclude.includes(eventId));

    await completeQueuedIngest(eventIdsToIngest);
    await fetchOperation();

    navigate(`/operations/${operationId}/ingest_history`);
  }, [fetchOperation, navigate, idsToExclude, operationId, runData.runFieldEvents]);

  if (!operation || !jobs) {
    return (
      <div className={styles.Spinner}>
        <Spinner />
      </div>
    );
  }

  const hasNotLoaded = Object.keys(allFieldGeometries).length === 0;

  return (
    <div className={styles.Container}>
      <Header title={`${operation.name} - Ingest`}>
        <Button disabled={isSubmitting || hasNotLoaded} onClick={completeIngest} primary>
          {isSubmitting ? getString('submitting', language) : getString('completeIngest', language)}
        </Button>
      </Header>
      <FileSummary runData={runData} />
      <FieldEditor
        fieldEvents={runData.runFieldEvents}
        fieldGeometries={allFieldGeometries}
        idsToExclude={idsToExclude}
        operation={operation}
        setIdsToExclude={setIdsToExclude}
        updateEvent={updateEvent}
      />
    </div>
  );
};

export default IngestSummaryContainer;
