import React from 'react';
import { Group, Stack, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleQueryKeys } from 'util/queryKeys';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { getSampleByBarcode } from 'store/samples/requests';

import { DueDates } from './DueDates';
import { Priority } from './Priority';

type DueDateAndPriorityContainerPropsType = {
  sample: LabSampleType;
};

export const DueDateAndPriorityContainer = ({ sample }: DueDateAndPriorityContainerPropsType) => {
  const language = useBroswerLanguage();

  const sampleQuery = useQuery({
    queryKey: sampleQueryKeys.sample(sample.barcode),
    queryFn: () => getSampleByBarcode(sample.barcode),
  });

  return (
    <Stack>
      <Title order={2} fw={700}>
        {getString('dueDateAndPriority', language)}
      </Title>
      <Group gap="xs" align="flex-start" flex={1}>
        <Priority sample={sample} />
      </Group>
      <Group gap="xs" align="flex-start" flex={1}>
        <DueDates sample={sample} sampleQuery={sampleQuery} />
      </Group>
    </Stack>
  );
};
