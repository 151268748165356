import { ReactNode } from 'react';
import { Link, RelativeRoutingType } from 'react-router-dom';
import { Anchor, Breadcrumbs, Text } from '@mantine/core';
import classNames from 'classnames';

import styles from './Header.module.css';

type HeaderPropsType = {
  title: ReactNode | string;
  size?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
  underline?: 'thin' | 'thick' | 'none';
  className?: string;
  children?: ReactNode;
  breadcrumbsItems?: {
    title: string;
    /** Will just show regular text if undefined, e.g. the name of the current page */
    to?: string;
    relative?: RelativeRoutingType;
  }[];
};

export const Header = ({
  className,
  title,
  size = 'H1',
  underline = 'thick',
  children,
  breadcrumbsItems,
}: HeaderPropsType) => {
  return (
    <>
      {!!breadcrumbsItems && (
        <Breadcrumbs>
          {breadcrumbsItems.map((item, index) =>
            item.to ? (
              <Anchor
                key={item.to}
                component={Link}
                to={item.to}
                underline="always"
                relative={item.relative || 'route'}
              >
                {index === 0 && '<'} {item.title}
              </Anchor>
            ) : (
              <Text key={item.title}>{item.title}</Text>
            ),
          )}
        </Breadcrumbs>
      )}
      <div
        className={classNames(styles.Wrapper, className, {
          [styles.Thick]: underline === 'thick',
          [styles.Thin]: underline === 'thin',
          [styles.None]: underline === 'none',
        })}
      >
        <div className={classNames(styles.Title, styles[size])}>{title}</div>
        <div className={styles.Toolbar}>{children}</div>
      </div>
    </>
  );
};
