import { getString } from 'strings/translation';
import { OrderForm, OrderFormTransformedType } from 'store/pricing/types';

// TODO: consistentize stepper names, dynamic buttons, and use Cam's wording
export const getNavButtonsText = (
  formValues: OrderForm,
  transformedValues: OrderFormTransformedType,
  language: string,
): Record<number, { previous: string | null; next: string }> => {
  const { isSplitDensity } = formValues;
  const { isProOrTillRx, isScanPointsOnly } = transformedValues;

  // Default to sampling and scanning steps
  let steps = [
    { previous: null, next: getString('setSamplingZones', language) },
    { previous: getString('back', language), next: getString('setSamplingPoints', language) },
    { previous: getString('back', language), next: getString('confirmAnalysis', language) },
    { previous: getString('back', language), next: getString('orderSamplePlan', language) },
  ];
  if (isScanPointsOnly) {
    // Only Pro / Till Rx (Can have Nutrient Panel)
    steps = [
      { previous: null, next: getString('setSamplingPoints', language) },
      { previous: getString('back', language), next: getString('confirmAnalysis', language) },
      { previous: getString('back', language), next: getString('orderSamplePlan', language) },
    ];
  }
  if (!isProOrTillRx && !isSplitDensity) {
    // Non Pro / Till Rx -- Sampling only
    steps = [
      { previous: null, next: getString('setSamplingZones', language) },
      { previous: getString('back', language), next: getString('confirmAnalysis', language) },
      { previous: getString('back', language), next: getString('orderSamplePlan', language) },
    ];
  }
  if (!isProOrTillRx && isSplitDensity) {
    // Non Pro / Till Rx -- Split Density
    steps = [
      { previous: null, next: getString('setSamplingZones', language) },
      { previous: getString('back', language), next: getString('setSplitDensity', language) },
      { previous: getString('back', language), next: getString('confirmAnalysis', language) },
      { previous: getString('back', language), next: getString('orderSamplePlan', language) },
    ];
  }

  // Convert array to indexed record
  return steps.reduce(
    (acc, step, index) => {
      acc[index] = step;
      return acc;
    },
    {} as Record<number, { previous: string | null; next: string }>,
  );
};
