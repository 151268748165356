import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { formatCalendarMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { markPlateArrived } from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { AdminAccess, Button, Container, Header, Spinner } from 'common';

import styles from './Inputs.module.css';

type MarkArrivedPropsType = {
  plate: PlateType;
};

export const MarkArrived = ({ plate }: MarkArrivedPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMarkArrived = async (arrived: boolean = true) => {
    try {
      setIsSubmitting(true);
      await dispatch(markPlateArrived(plate.barcode, arrived));
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <Container vertical>
      <Header
        title={`${getString('plateArrived', language)}: ${
          plate.arrived_at ? formatCalendarMonthDayYear(plate.arrived_at) : NOT_APPLICABLE
        }`}
        underline="none"
        size="H2"
      >
        <>
          {isSubmitting && <Spinner className={styles.SmallSpinner} />}
          {plate.arrived_at ? (
            <AdminAccess>
              <Button
                className={styles.Button}
                onClick={() => handleMarkArrived(false)}
                disabled={isSubmitting}
                danger
              >
                {getString(isSubmitting ? 'submitting' : 'markNotArrived', language)}
              </Button>
            </AdminAccess>
          ) : (
            <Button
              className={styles.Button}
              onClick={() => handleMarkArrived()}
              disabled={Boolean(plate.arrived_at) || isSubmitting}
            >
              {getString(isSubmitting ? 'submitting' : 'markArrived', language)}
            </Button>
          )}
        </>
      </Header>
    </Container>
  );
};
