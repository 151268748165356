import React from 'react';
import classNames from 'classnames';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { formatCalendarMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { capitalizeEveryWord } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { TableCol, TableRow } from 'common';

import styles from './PaymentTables.module.css';

type ApprovedRowPropTypes = {
  rowClick?: (param?: any) => void;
  paymentUuid: string;
  paymentId: number;
  billingName: string;
  type: string | null;
  amount: number;
  updated?: string | null;
  status: string;
};

const ApprovedRow = ({
  rowClick,
  paymentUuid,
  paymentId,
  billingName,
  type,
  amount,
  updated,
  status,
}: ApprovedRowPropTypes) => {
  const language = useBroswerLanguage();

  return (
    <TableRow
      onClick={() => rowClick && rowClick(paymentUuid)}
      className={classNames(styles.HoverClick, styles.RowPadding)}
    >
      <TableCol className={styles.TransactionTitleColumn}>{paymentId}</TableCol>
      <TableCol className={styles.TransactionTitleColumn}>
        {capitalizeEveryWord(billingName)}
      </TableCol>
      <TableCol>{type ? getString(type, language) : NOT_APPLICABLE}</TableCol>
      <TableCol>{`$${Math.abs(amount)}`}</TableCol>
      <TableCol>{updated ? formatCalendarMonthDayYear(updated) : NOT_APPLICABLE}</TableCol>
      <TableCol>{status ? getString(status, language) : NOT_APPLICABLE}</TableCol>
    </TableRow>
  );
};

export default ApprovedRow;
