import {
  RECEIVE_SAMPLE_PRICES,
  REQUEST_SAMPLE_PRICES,
  SAMPLE_PRICE_REQUEST_ERROR,
  SamplePricesActionType,
  SamplePricesResponse,
} from './types';

export const requestSamplePrices = (): SamplePricesActionType => ({
  type: REQUEST_SAMPLE_PRICES,
});

export const receiveSamplePrices = (payload: {
  samplePrices: SamplePricesResponse;
}): SamplePricesActionType => ({
  type: RECEIVE_SAMPLE_PRICES,
  payload,
});

export const samplePricesRequestError = (payload: { message: string }): SamplePricesActionType => ({
  type: SAMPLE_PRICE_REQUEST_ERROR,
  payload,
});
