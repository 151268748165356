import { AnalyticType } from 'store/analytics/types';
import { FieldType } from 'store/fields/types';

type SortingFunction<T> = (a: T, b: T) => number;
interface ObjectWithNameInterface {
  name: string;
}

interface ObjectWithFarmNameInterface {
  name: string;
  farm_name: string | null;
}

const sortByName = <T extends ObjectWithNameInterface>(list: Array<T>): Array<T> => {
  return list.concat().sort((a, b) => {
    const name1 = a.name ? a.name.toLowerCase() : 'zzz';
    const name2 = b.name ? b.name.toLowerCase() : 'zzz';
    if (name1 < name2) {
      return -1;
    }
    return name1 > name2 ? 1 : 0;
  });
};

export const sortByValue = <T>(list: Array<T>, sortedBy: string): Array<T> => {
  return list.concat().sort((a, b) => {
    const name1 = a[sortedBy] ? a[sortedBy].toLowerCase() : 'zzz';
    const name2 = b[sortedBy] ? b[sortedBy].toLowerCase() : 'zzz';
    if (name1 < name2) {
      return -1;
    }
    return name1 > name2 ? 1 : 0;
  });
};

export const sortFarmThenFieldName = <T extends ObjectWithFarmNameInterface>(
  list: Array<T>,
): Array<T> => {
  return list.concat().sort((a, b) => {
    const farmName1 = a.farm_name ? a.farm_name.toLowerCase() : 'zzz';
    const name1 = a.name ? a.name.toLowerCase() : 'zzz';
    const farmName2 = b.farm_name ? b.farm_name.toLowerCase() : 'zzz';
    const name2 = b.name ? b.name.toLowerCase() : 'zzz';

    if (farmName1 > farmName2) {
      return 1;
    }
    if (farmName1 < farmName2) {
      return -1;
    }

    // Else go to the 2nd item
    if (name1 < name2) {
      return -1;
    }
    if (name1 > name2) {
      return 1;
    } // nothing to split them
    return 0;
  });
};

export const sortByFieldName = (fields: FieldType[]) =>
  fields.sort((a, b) =>
    a.features[0].properties.name.toLowerCase() > b.features[0].properties.name.toLowerCase()
      ? 1
      : -1,
  );

export const sortAnalyticsById = (analytics: AnalyticType[], sortedArr: number[] | undefined) =>
  sortedArr
    ? analytics.sort((a, b) => sortedArr.indexOf(a.id) - sortedArr.indexOf(b.id))
    : analytics;

export default sortByName;

// CRED: https://stackoverflow.com/a/38641281/1048518
const compareCaseInsensitiveStrNumMix = (a: string, b: string): number => {
  return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

// CRED: https://stackoverflow.com/a/9645447/1048518
export const sortCaseInsensitive = (a: string, b: string): number =>
  a.toLowerCase().localeCompare(b.toLowerCase());

/**
 * Sort an array of objects based on the supplied key
 *
 * {@link https://github.com/Language-Mapping/language-map/commit/1af5f56cde67bcfd56daaa7a331b2e90887ea724 ELA map repo commit}
 *
 * @param key property of the object to use as the basis of sorting
 * @param stringsMayContainNumbers if true, `BD1, BD2, BD10`, not `BD1, BD10, BD2`
 * @returns comparator function for use with `Array.sort()`
 */
export const sortArrOfObjects = <T>(
  key: keyof T,
  stringsMayContainNumbers?: boolean,
): SortingFunction<T> => {
  const comparator = (a: T, b: T): number => {
    let comparison = 0;

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      if (stringsMayContainNumbers) {
        return compareCaseInsensitiveStrNumMix(a[key] as string, b[key] as string);
      }

      return sortCaseInsensitive(a[key] as string, b[key] as string);
    }

    if (a[key] > b[key]) {
      comparison = 1;
    } else if (a[key] < b[key]) {
      comparison = -1;
    }

    return comparison;
  };

  return comparator;
};
