import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { hasGeom } from 'util/field';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { userCanEditPlans } from 'store/user/selectors';
import { Button } from 'common';

type StatusPropsType = {
  field: FieldType;
  primary?: boolean;
  light?: boolean;
  square?: boolean;
  className?: string;
};

const OrderPlanButton = ({ field, primary, light, square, className }: StatusPropsType) => {
  const navigate = useNavigate();
  const fieldProps = field.features[0].properties;
  const language = useBroswerLanguage();

  const { userCanEdit, operation } = useSelector((state: RootState) => ({
    userCanEdit: userCanEditPlans(state, fieldProps.operation_id),
    operation: state.operations.operationsById[fieldProps.operation_id],
  }));

  return (
    <>
      {hasGeom(field) ? (
        <Button
          dataTestId="order-sampling-plan"
          primary={primary}
          light={light}
          square={square}
          className={className}
          disabled={!userCanEdit || !operation?.billing_user_id}
          onClick={() => {
            navigate(`/zone-analysis/${fieldProps.operation_id}/${fieldProps.id}`);
          }}
        >
          {getString('orderSamplingPlan', language)}
        </Button>
      ) : (
        <Button
          primary={primary}
          light={light}
          square={square}
          className={className}
          onClick={() => {
            navigate(`/field-boundary/${fieldProps.operation_id}/${fieldProps.id}`);
          }}
        >
          {getString('editFieldBoundary', language)}
        </Button>
      )}
    </>
  );
};

export default OrderPlanButton;
