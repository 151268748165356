import {
  AnalyticsActionType,
  AnalyticsState,
  RECEIVE_ALL_ANALYTICS,
  RECEIVE_ANALYTIC_CATEGORIES,
  RECEIVE_ANALYTIC_SORT_ORDER,
  RECEIVE_ANALYTICS,
  RECEIVE_BENCHMARKS,
  RECEIVE_SINGLE_ANALYTIC,
  REQUEST_ANALYTICS,
  REQUEST_ANALYTICS_ERROR,
} from './types';

export const initialState: AnalyticsState = {
  analytics: [],
  allAnalytics: [],
  analyticCategories: [],
  byId: {},
  midwestBenchmarksById: {},
  analyticSortOrder: {},
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const analyticsReducer = (
  state = initialState,
  action: AnalyticsActionType,
): AnalyticsState => {
  switch (action.type) {
    case RECEIVE_ANALYTICS:
      return {
        ...state,
        analytics: action.payload.analytics,
        isFetching: false,
        hasFetched: true,
      };
    case RECEIVE_ALL_ANALYTICS:
      return {
        ...state,
        allAnalytics: action.payload.analytics,
        isFetching: false,
        hasFetched: true,
      };
    case RECEIVE_ANALYTIC_CATEGORIES:
      return {
        ...state,
        analyticCategories: action.payload.analytic_categories,
      };
    case RECEIVE_ANALYTIC_SORT_ORDER:
      return {
        ...state,
        analyticSortOrder: action.payload,
      };

    case RECEIVE_SINGLE_ANALYTIC:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.analytic.id]: action.payload.analytic,
        },
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_BENCHMARKS:
      return {
        ...state,
        midwestBenchmarksById: action.payload.benchmarks,
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_ANALYTICS:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_ANALYTICS_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
