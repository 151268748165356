import React from 'react';
import { Alert, Center, type CenterProps } from '@mantine/core';

type Props = {
  title: string;
  content?: React.ReactNode;
  /**
   * Props applied to `<Center>` wrapper.
   * @default DEFAULT_CENTER_PROPS
   */
  centerProps?: CenterProps;
};

const DEFAULT_CENTER_PROPS: CenterProps = {
  maw: 400,
  mx: 'auto',
  h: '50vh',
};

const CenteredErrorMessage = ({ title, content, centerProps = DEFAULT_CENTER_PROPS }: Props) => {
  return (
    <Center {...centerProps}>
      <Alert color="red.8" title={title}>
        {content}
      </Alert>
    </Center>
  );
};

export default CenteredErrorMessage;
