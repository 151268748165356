import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Stack, Table, Text } from '@mantine/core';

import { CONTRACT_METRIC_STATUSES } from 'constants/orders';
import { CONTRACT_METRIC_PRODUCTS, NUTRIENT_PANEL } from 'constants/products';

import { formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getContractMetricsByAgency } from 'store/agencies/thunks';
import { Agency, ContractType } from 'store/agencies/types';
import { Header } from 'common/Components/Mantine/Header';

type ContractDetailsPropsType = {
  contract: ContractType;
  agency: Agency;
};

export const ContractDetails = ({ contract, agency }: ContractDetailsPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const samplingMetrics = useSelector(
    (state: RootState) => state.agencies.byId[agency.id]?.metricsByContractId?.[contract.id],
  );

  useEffect(() => {
    if (!samplingMetrics) {
      dispatch(getContractMetricsByAgency(agency.id, contract.id));
    }
  }, [contract, agency, samplingMetrics]);

  const getFirstColumnName = (sampleType: string) => {
    const firstColumnName = getString(sampleType, language);
    if (sampleType === NUTRIENT_PANEL) {
      return `${firstColumnName} / ${getString('splitDensity', language)}`;
    }
    return firstColumnName;
  };

  return (
    <Stack gap="0.25rem">
      <Header
        title={
          <>
            <>{`${contract.is_current ? `${getString('current', language)} ` : ''}${getString('contractDetails', language)}: `}</>
            <b>{getString(contract.contract_type, language)}</b>
          </>
        }
        order={4}
        divider="sm"
      />
      <Group justify="space-between">
        <Group gap="xs">
          <Text fw="bold">{`${getString('dateSigned', language)}:`}</Text>
          <Text>{formatDateMonthDayYear(contract.signed_at, language)}</Text>
        </Group>
        <Group gap="xs">
          <Text fw="bold">{`${getString('committedSamples', language)}:`}</Text>
          <Text>{contract.committed_samples}</Text>
        </Group>
        <Group gap="xs">
          <Text fw="bold">{`${getString('expirationDate', language)}:`}</Text>
          <Text>{formatDateMonthDayYear(contract.expired_at, language)}</Text>
        </Group>
      </Group>
      <Table>
        <Table.Tbody>
          <Table.Tr>
            <Table.Th />
            <Table.Th ta="right">{getString('totalPlannedSamples', language)}</Table.Th>
            <Table.Th ta="right">{getString('totalSamplesTaken', language)}</Table.Th>
            <Table.Th ta="right">{getString('totalCompletedSamples', language)}</Table.Th>
          </Table.Tr>
          {CONTRACT_METRIC_PRODUCTS.map((sampleType) => (
            <Table.Tr key={sampleType}>
              <Table.Td>{getFirstColumnName(sampleType)}</Table.Td>
              {CONTRACT_METRIC_STATUSES.map((metric) => (
                <Table.Td key={metric} ta="right">
                  {samplingMetrics?.[metric]?.[sampleType]}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};
