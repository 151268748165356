import {
  RECEIVE_STATE_GEOMETRY,
  REQUEST_STATE_GEOMETRY,
  STATE_GEOMETRY_ERROR,
  StateActionsType,
  StateType,
} from './types';

export const requestStateGeometry = (stateId: string): StateActionsType => ({
  type: REQUEST_STATE_GEOMETRY,
  payload: { stateId },
});

export const receiveStateGeometry = (
  stateId: string,
  countryId: string,
  geometry: StateType,
): StateActionsType => ({
  type: RECEIVE_STATE_GEOMETRY,
  payload: { stateId, countryId, geometry },
});

export const stateGeometryError = (stateId: string): StateActionsType => ({
  type: STATE_GEOMETRY_ERROR,
  payload: { stateId },
});
