import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Input, Modal, SimpleGrid, Space, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { cleanPhoneNumber, emailRegExp, phoneRegExp } from 'util/validators';
import { getString } from 'strings/translation';
import { requestRegisterForOperation } from 'store/operation/requests';
import { selectRoles } from 'store/roles/actions';
import { getOperationAssociatedUsers } from 'store/user/thunks';
import { RegisterUserForOperationType, UserRole } from 'store/user/types';

import styles from './InviteNewUserDialog.module.css';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  email: string;
  operationId: number;
  open: boolean;
}

const InviteNewUserDialog = ({ onClose, onSuccess, email, operationId, open }: Props) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const operationRoleId = useSelector(selectRoles).find((role) =>
    role.name.includes(UserRole.Operator),
  )?.id;
  const [userInvited, setUserInvited] = useState({
    first_name: '',
    last_name: '',
    email: '',
    cell: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (email && !userInvited.email) {
      setUserInvited({ ...userInvited, email });
    }
  }, [email]);

  const validate = () => {
    if (!userInvited.first_name || !userInvited.last_name || !userInvited.email) {
      return getString('fieldsWithErrors', language);
    }
    if (!emailRegExp.test(userInvited.email)) {
      return getString('enterValidEmail', language);
    }
    if (userInvited.cell && !phoneRegExp.test(userInvited.cell)) {
      return getString('enterValidPhone', language);
    }
  };

  const submitUser = async () => {
    setSubmitting(true);
    setErrorMessage('');
    const cleanedCell = userInvited.cell && cleanPhoneNumber(userInvited.cell);
    if (cleanedCell && cleanedCell.length < 10) {
      setSubmitting(false);
      return setErrorMessage(getString('enterValidPhone', language));
    }
    const data: RegisterUserForOperationType = {
      ...userInvited,
      email: userInvited.email.toLowerCase(),
      cell: cleanedCell,
    };

    if (!data.cell) {
      delete data.cell;
    }

    if (operationRoleId) {
      data.role_id = operationRoleId;
    }
    const errors = validate();
    if (errors) {
      setSubmitting(false);
      return setErrorMessage(errors);
    }
    try {
      await requestRegisterForOperation(operationId, data);
      onClose();
      await dispatch(getOperationAssociatedUsers(Number(operationId)));
      onSuccess();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      opened={open}
      size="xl"
      title={getString('inviteNewUser', language)}
      centered
    >
      <Text>{getString('inviteUserMsg', language)}</Text>
      <Space h="md" />
      <SimpleGrid cols={2}>
        <Input.Wrapper label={`*${getString('firstName', language)}`}>
          <Input
            value={userInvited.first_name}
            onChange={(e) => setUserInvited({ ...userInvited, first_name: e.currentTarget.value })}
          />
        </Input.Wrapper>
        <Input.Wrapper label={`*${getString('lastName', language)}`}>
          <Input
            value={userInvited.last_name}
            onChange={(e) => setUserInvited({ ...userInvited, last_name: e.currentTarget.value })}
          />
        </Input.Wrapper>
        <Input.Wrapper label={`*${getString('email', language)}`}>
          <Input
            value={userInvited.email}
            onChange={(e) => setUserInvited({ ...userInvited, email: e.currentTarget.value })}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('cellPhone', language)}>
          <Input
            value={userInvited.cell}
            onChange={(e) => setUserInvited({ ...userInvited, cell: e.currentTarget.value })}
          />
        </Input.Wrapper>
      </SimpleGrid>
      {errorMessage && (
        <Text size="sm" color="darkRed">
          {errorMessage}
        </Text>
      )}
      <Space h="md" />
      <Group justify="center">
        <Button className={styles.Submit} onClick={submitUser} loading={isSubmitting}>
          {getString('invite', language)}
        </Button>
      </Group>
    </Modal>
  );
};

export default InviteNewUserDialog;
