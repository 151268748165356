import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import turfBbox from '@turf/bbox';
import { feature, featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import MAPBOX_CONSTANTS, { MapMarkers, MARKERS } from 'constants/mapbox';
import { US_MIDWEST_VIEWPORT } from 'constants/mapViewport';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getMapPinColorRiskLevels } from 'util/mapImageryColors';
import { AnalyticType } from 'store/analytics/types';
import { HeatMapAnalyticType } from 'store/heatMap/types';
import useMapboxGl from 'common/MapHooks';

import { HeatPopup } from './HeatPopup';

import styles from './HeatMap.module.css';

type HeatMapProps = {
  mapContainerRef: { current: HTMLDivElement | null };
  mapRef: { current: mapboxgl.Map | null };
  heatMap: HeatMapAnalyticType[];
  analytic: AnalyticType;
};

type ViewPortProps = {
  centerLongitude?: number;
  centerLatitude?: number;
  latitude: number;
  longitude: number;
  zoom: number;
  width?: number;
  height?: number;
};

const HeatMap = ({ mapContainerRef, mapRef, heatMap, analytic }: HeatMapProps) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const {
    longitude: centerLongitude,
    latitude: centerLatitude,
    zoom: centerZoom,
  } = US_MIDWEST_VIEWPORT;

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: centerZoom,
    width: 0,
    height: 0,
  });

  useMapboxGl(
    mapContainerRef,
    mapRef,
    undefined,
    viewport,
    setViewport,
    () => {},
    true,
    false,
    MAPBOX_CONSTANTS.styleOutdoors,
  );

  const viewResults = (heat: HeatMapAnalyticType) =>
    navigate(
      `/results/analytic/${heat.operation_id}/${heat.field_id}/${heat.id}/${heat.analytic_id}`,
    );

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('load', () => {
        Object.values(MapMarkers).forEach((marker, index) => {
          mapRef.current?.loadImage(marker.image, (error, image) => {
            if (error) {
              throw error;
            }
            // @ts-ignore
            mapRef.current?.addImage(marker.name, image);
            if (index === Object.values(MapMarkers).length - 1) {
              setIsMapLoaded(true);
            }
          });
        });
      });
    }
  }, [mapRef]);

  useEffect(() => {
    if (isMapLoaded && mapRef.current?.getSource(`markers`)) {
      mapRef.current?.removeLayer(`markers`);
      mapRef.current?.removeSource(`markers`);
    }
  }, [isMapLoaded, mapRef, heatMap, analytic]);

  useEffect(() => {
    if (
      isMapLoaded &&
      heatMap.length &&
      analytic &&
      mapRef.current &&
      !mapRef.current?.getSource(`markers`)
    ) {
      const popups = document.querySelectorAll('.mapboxgl-popup');
      popups.forEach((popup) => popup.remove());
      const source = {
        type: 'geojson',
        data: featureCollection(
          heatMap.map((heat) =>
            feature(heat.field_centroid, {
              heat,
              pinColor: getMapPinColorRiskLevels(heat.risk_level),
            }),
          ),
        ),
      } as GeoJSONSourceRaw;
      mapRef.current?.addLayer({
        id: MARKERS,
        type: 'symbol',
        source: source,
        layout: {
          'icon-image': ['get', 'pinColor'],
          'icon-allow-overlap': true,
        },
      });
      const bbox = turfBbox(source.data) as LngLatBoundsLike;
      mapRef.current.fitBounds(bbox, {
        padding: 50,
        maxZoom: 10,
      });
      const zoom = mapRef.current.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom: zoom > 13 ? zoom : 10,
      }));
      mapRef.current.on('click', MARKERS, (e: any) => {
        const heat = JSON.parse(e.features[0].properties.heat);
        const pop = HeatPopup({ heat, language, viewResults });

        const placeholder = document.createElement('div');
        const root = createRoot(placeholder);
        root.render(pop);
        if (mapRef.current instanceof mapboxgl.Map) {
          new mapboxgl.Popup({
            closeOnClick: true,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(placeholder)
            .addTo(mapRef.current as mapboxgl.Map);
        }
      });

      mapRef.current.on('mouseenter', MARKERS, () => {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        }
      });

      mapRef.current.on('mouseleave', MARKERS, () => {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = '';
        }
      });
    }
  }, [heatMap, analytic, mapRef.current, isMapLoaded]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.MapWrapper} ref={wrapperRef}>
        <div ref={mapContainerRef} className={styles.MapWrapper} />
      </div>
    </div>
  );
};

export default HeatMap;
