import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HIGH_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { RESULT_DISPLAY_CATEGORIES } from 'constants/results';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAnalysisViewOptions, getAnalyticsPerTypeCrop } from 'util/results';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { AnalyticType } from 'store/analytics/types';
import { CropPlanType } from 'store/cropPlans/types';
import { SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import { Accordion } from 'common';

import AnalyticBar from '../../../common/AnalyticBar';

type ListPropsType = {
  samplingPlan: SamplingPlanType;
  analytics: AnalyticType[];
  operation: OperationType;
  cropPlan: CropPlanType | null;
};

const AnalyticCategoryList = ({ samplingPlan, analytics, operation, cropPlan }: ListPropsType) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const [accordionsOpen, setAccordionOpen] = useState<string | undefined>(undefined);

  const { analyticSortOrder } = useSelector((state: RootState) => ({
    analyticSortOrder: state.analytics.analyticSortOrder,
  }));

  const toggleAccordion = (panelType: string) =>
    accordionsOpen === panelType ? setAccordionOpen(undefined) : setAccordionOpen(panelType);

  const analyticsInField = samplingPlan
    ? Object.keys(samplingPlan.analytics).flatMap((cat) => Object.keys(samplingPlan.analytics[cat]))
    : [];

  const analyticsPerAccordion = getAnalyticsPerTypeCrop(
    analyticSortOrder,
    analytics,
    accordionsOpen,
    cropPlan?.crop,
    analyticsInField,
  );

  const anaylyticBars = (() =>
    analyticsPerAccordion.map((analytic) => (
      <AnalyticBar
        textSize="sm"
        activeAnalytic={analytic}
        samplingPlan={samplingPlan}
        onClick={() =>
          navigate(
            `/results/analytic/${operation.id}/${samplingPlan.field_id}/${samplingPlan.id}/${analytic.id}`,
          )
        }
        displayRiskLevels={[MODERATE_RISK, HIGH_RISK]}
        key={analytic.id}
      />
    )))();

  const barDisplay = (() => {
    if (!accordionsOpen) {
      return <div />;
    }
    if (anaylyticBars.length) {
      return anaylyticBars;
    }
    return getString('noResultsForCategory', language);
  })();

  return (
    <div>
      {getAnalysisViewOptions(language, RESULT_DISPLAY_CATEGORIES).map((panel) => (
        <Accordion
          key={panel.displayName}
          title={panel.displayName}
          toggleOpen={() => toggleAccordion(panel.value)}
          open={accordionsOpen === panel.value}
        >
          {barDisplay}
        </Accordion>
      ))}
    </div>
  );
};

export default AnalyticCategoryList;
