import {
  INDEX_SET_REQUEST_ERROR,
  IndexSetType,
  RECEIVE_INDEX_SETS,
  RECEIVE_INDEX_SETS_NAMES,
  REQUEST_INDEX_SETS,
} from './types';

export const requestIndexSets = () => ({ type: REQUEST_INDEX_SETS });

export const receiveIndexSets = (indexSets: IndexSetType[]) => ({
  type: RECEIVE_INDEX_SETS,
  payload: indexSets,
});

export const receiveIndexSetsNames = (indexNames: string[]) => ({
  type: RECEIVE_INDEX_SETS_NAMES,
  payload: indexNames,
});

export const indexSetRequestError = (payload: { message: string }) => ({
  type: INDEX_SET_REQUEST_ERROR,
  payload,
});
