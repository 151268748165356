import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isProtocol from 'util/attributeUtil';
import useBroswerLanguage from 'util/hooks/useLanguage';
import sortByName from 'util/sortByName';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { disableAttribute, editAttribute, getAllAttributes } from 'store/attributes/thunks';
import { AttributeType } from 'store/attributes/types';
import { Button, Header, Spinner, Table, TableCol, TableRow } from 'common';

import ProtocolCreation from './ProtocolCreation';

import styles from './Container.module.css';

const ProtocolsContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { attributes, hasFetched } = useSelector((state: RootState) => ({
    attributes: state.attributes.attributes,
    hasFetched: state.attributes.hasFetched,
  }));

  useEffect(() => {
    dispatch(getAllAttributes());
  }, [dispatch]);

  if (!hasFetched && !attributes.length) {
    return <Spinner fill />;
  }

  const protocols = attributes.filter((attribute) => isProtocol(attribute));
  const sortedAttributes = sortByName(protocols);

  const handleEnableControl = async (id: any) => {
    dispatch(editAttribute(id, { enabled: true }));
  };

  const handleDisableControl = async (id: any) => {
    dispatch(disableAttribute(id));
  };

  const getRow = (attribute: AttributeType) => {
    const actionButton = attribute.enabled ? (
      <Button danger onClick={() => handleDisableControl(attribute.id)}>
        {getString('disable', language)}
      </Button>
    ) : (
      <Button onClick={() => handleEnableControl(attribute.id)}>
        {getString('enable', language)}
      </Button>
    );
    return (
      <TableRow key={attribute.id}>
        <TableCol>{attribute.name}</TableCol>
        <TableCol size="x2">{attribute.description}</TableCol>
        <TableCol size="x2">{(attribute.allowed_values || []).join(', ')}</TableCol>
        <TableCol className={styles.Action}>{actionButton}</TableCol>
      </TableRow>
    );
  };

  return (
    <div className={styles.Wrapper}>
      <Header title={getString('rndProtocols', language)} />
      <ProtocolCreation />
      <Table className={styles.Table}>
        <TableRow className={styles.TableHeader}>
          <TableCol>{getString('name', language)}</TableCol>
          <TableCol size="x2">{getString('description', language)}</TableCol>
          <TableCol size="x2">{getString('allowedValues', language)}</TableCol>
          <TableCol />
        </TableRow>
        {sortedAttributes.map(getRow)}
      </Table>
    </div>
  );
};

export default ProtocolsContainer;
