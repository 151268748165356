import React from 'react';

import { analysesWithDueDates } from 'util/analysis';
import { dateIsBeforeTodayPlusNDays, formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { Banner } from 'common';

type Props = {
  sample: LabSampleType;
};

const createPastDueBannerIfApplicable = (formattedDate, language, dueDateForArithmetic, today) => {
  if (dueDateForArithmetic && dueDateForArithmetic < today) {
    const headingText = `${getString('pastDueDateHeader', language)}: `;
    const bodyText = `${getString('pastDueDateMessage', language)} ${formattedDate}. ${getString('seeDetailsBelow', language)}`;
    return <Banner headingText={headingText} bodyText={bodyText} />;
  }
  return null;
};

const createDueSoonBannerIfApplicable = (formattedDate, language, dueDateForArithmetic) => {
  if (dueDateForArithmetic && dateIsBeforeTodayPlusNDays(dueDateForArithmetic, 3)) {
    const headingText = `${getString('imminentDueDateHeader', language)}:`;
    const bodyText = `${getString('imminentDueDateMessage', language)} ${formattedDate}. ${getString('seeDetailsBelow', language)}`;
    return <Banner headingText={headingText} bodyText={bodyText} warn />;
  }
};

const createBanner = (sample: LabSampleType, language, bannerCreationFunction) => {
  const today = new Date();

  return analysesWithDueDates
    .map((analysisWithDueDate) => {
      const dueDateForAnalysisFromSample = sample.rnd_meta?.due_dates?.[analysisWithDueDate];

      const formattedDate = dueDateForAnalysisFromSample
        ? formatDateMonthDayYear(dueDateForAnalysisFromSample, language)
        : null;

      const dueDateForArithmetic = dueDateForAnalysisFromSample
        ? new Date(dueDateForAnalysisFromSample)
        : null;

      return bannerCreationFunction(formattedDate, language, dueDateForArithmetic, today);
    })
    .find(Boolean);
};

export const ImminentDueDate = ({ sample }: Props) => {
  const language = useBroswerLanguage();

  const pastDueBanner = createBanner(sample, language, createPastDueBannerIfApplicable);
  if (pastDueBanner) {
    return pastDueBanner;
  }
  const dueSoonBanner = createBanner(sample, language, createDueSoonBannerIfApplicable);
  return dueSoonBanner || null;
};
