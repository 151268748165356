import {
  CropPlanningActionType,
  PEST_GROUP_ERROR,
  PestGroupPayloadType,
  RECEIVE_PEST_GROUPS,
  REQUEST_PEST_GROUPS,
} from './types';

export const requestPestGroups: CropPlanningActionType = {
  type: REQUEST_PEST_GROUPS,
};

export const receivePestGroups = (payload: PestGroupPayloadType): CropPlanningActionType => ({
  type: RECEIVE_PEST_GROUPS,
  payload,
});

export const pestRequestError = (payload: { message: string }): CropPlanningActionType => ({
  type: PEST_GROUP_ERROR,
  payload,
});
