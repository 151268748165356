import { useState } from 'react';
import { Container, Space, Stack, Text } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { usePriceEstimateQuery } from 'apps/ZoneAnalysisV3/queries';

import { ANALYSIS, FIELD_WORK, LIST_PRICE } from 'constants/pricing';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { OrderSummaryMode, PriceSummary } from 'store/pricing/types';
import CenteredLoader from 'common/CenteredLoader';

import OrderSummary from './OrderSummary/OrderSummary';
import SamplingExecution from './OrderSummary/SamplingExecution';

const ReviewOrderStep = () => {
  const language = useBroswerLanguage();
  const { priceEstimateQuery } = usePriceEstimateQuery();
  const { data } = priceEstimateQuery;
  const form = useOrderFormContext();
  const { sampledById, scannedById, sampleTiming, isNotBilled } = form.getValues();
  const [mode, setMode] = useState<OrderSummaryMode>(LIST_PRICE);
  const { price_summary = [] } = data || {};
  const isListPrice = mode === LIST_PRICE;

  const displayedProducts = price_summary.reduce(
    (acc: { analysis: PriceSummary[]; fieldWork: PriceSummary[] }, product) => {
      if (!product.is_not_billed || isListPrice) {
        const key = product.sku_type === ANALYSIS ? ANALYSIS : FIELD_WORK;
        acc[key].push(product);
      }
      return acc;
    },
    { analysis: [], fieldWork: [] },
  );

  if (priceEstimateQuery.isPending) {
    return <CenteredLoader />;
  }

  return (
    <Container size="md">
      <Stack align="center">
        <Text fz="h2" fw="bold" component="h2" tt="capitalize">
          {getString('reviewOrder', language)}
        </Text>
        {data && (
          <OrderSummary
            estimatedListPrice={data?.estimated_list_price}
            estimatedListPricePerAcre={data?.estimated_list_price_per_acre}
            estimatedPrice={data?.estimated_price}
            estimatedPricePerAcre={data?.estimated_price_per_acre}
            displayedProducts={displayedProducts}
            mode={mode}
            setMode={setMode}
            isNotBilled={isNotBilled}
          />
        )}
        <Space />
        <SamplingExecution
          sampleTiming={sampleTiming}
          sampledById={sampledById}
          scannedById={scannedById}
        />
      </Stack>
    </Container>
  );
};

export default ReviewOrderStep;
