import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouterProps, Navigate } from 'react-router-dom';

import {
  userIsAdmin,
  userIsAgencyAdmin,
  userIsAgent,
  userIsSuperAdmin,
} from 'store/user/selectors';

export type PrivateRoleRouteProps = {
  isAgentRoute?: boolean;
  isAdminRoute?: boolean;
  isSuperAdminRoute: boolean;
  isAgencyAdminRoute: boolean;
} & BrowserRouterProps;

export default function PrivateRoleRoute({
  isAgentRoute,
  isAdminRoute,
  isSuperAdminRoute,
  isAgencyAdminRoute,
  children,
}: PrivateRoleRouteProps): JSX.Element {
  const isAgent = useSelector(userIsAgent);
  const isAdmin = useSelector(userIsAdmin);
  const isSuperAdmin = useSelector(userIsSuperAdmin);
  const isAgencyAdmin = useSelector(userIsAgencyAdmin);

  let isAllowed = false;

  if (
    (isAgencyAdminRoute && isAgencyAdmin) ||
    (isAgentRoute && isAgent) ||
    (isAdminRoute && isAdmin) ||
    (isSuperAdminRoute && isSuperAdmin)
  ) {
    isAllowed = true;
  }

  if (isAllowed) {
    return <>{children}</>;
  }
  return <Navigate to="/" />;
}
