import { Table } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { ContractPricingPayload, ContractPricingType } from 'store/pricing/types';

import { ContractPricingFormProvider, useContractPricingForm } from './contractPricingContext';
import ContractPricingInputs from './ContractPricingInputs';
import PricingTableRows from './PricingTableRows';

type Props = {
  initialContractData: ContractPricingType;
  onModalClose: VoidFunction;
  agencyIdString: string;
  onSubmit: (
    payload: ContractPricingPayload & {
      /** Will be undefined for creation/POST */
      contractId?: number | null;
    },
  ) => void;
};

const { Thead, Th, Tr } = Table;

const PricingTable = ({ initialContractData, agencyIdString, onSubmit, onModalClose }: Props) => {
  const language = useBroswerLanguage();

  const form = useContractPricingForm({
    mode: 'uncontrolled',
    initialValues: {
      committedSamples: initialContractData.committed_samples || 0,
      contractSkuPrices: initialContractData.sku_prices,
      signedAt: initialContractData.signed_at
        ? new Date(initialContractData.signed_at)
        : new Date(),
      expiredAt: initialContractData.expired_at
        ? new Date(initialContractData.expired_at)
        : new Date(),
    },
    validate: {
      committedSamples: (value) => {
        if (value < 1) {
          return 'Need at least one committed sample';
        }
      },
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    form.validate();

    onSubmit({
      agency_id: Number(agencyIdString),
      committed_samples: values.committedSamples,
      expired_at: values.expiredAt.toISOString(),
      signed_at: values.signedAt.toISOString(),
      contractId: initialContractData.id,
      sku_prices: Object.values(values.contractSkuPrices).reduce(
        (acc, curr) => [...acc, ...curr],
        [],
      ),
    });

    onModalClose();
  };

  return (
    <ContractPricingFormProvider form={form}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Table
          withTableBorder
          withColumnBorders
          stickyHeader
          stickyHeaderOffset={55}
          styles={{
            thead: { zIndex: 3 },
          }}
        >
          <Thead>
            <Tr>
              <Th>{getString('productName', language)}</Th>
              <Th>{getString('priceType', language)}</Th>
              <Th>{getString('price', language)}</Th>
              <Th>{getString('listPrice', language)}</Th>
              <Th>{getString('minDensity', language)}</Th>
              <Th>{getString('maxDensity', language)}</Th>
              <Th>{getString('minScanDensity', language)}</Th>
              <Th>{getString('maxScanDensity', language)}</Th>
            </Tr>
          </Thead>
          <PricingTableRows />
        </Table>
        <ContractPricingInputs language={language} onCancelClick={onModalClose} />
      </form>
    </ContractPricingFormProvider>
  );
};

export default PricingTable;
