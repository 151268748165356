import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Loader, Stack, Text, TextInput } from '@mantine/core';
import { LaneSelector } from 'apps/Lab/Batches/Create/BatchForms/FormInputs/LaneSelector';

import { formatBatchForSubmission } from 'util/batches';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import {
  requestGenerateSampleSheetV2,
  requestGetBatch,
  requestUpdateBatch,
} from 'store/batches/requests';
import { getBatch } from 'store/batches/thunks';
import { BatchType } from 'store/batches/types';
import { BatchSearchBar } from 'common';

type SampleSheetFormPropsType = {
  batch: BatchType;
};

export const SampleSheetForm = ({ batch }: SampleSheetFormPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const [batches, setBatches] = useState<(BatchType | null)[]>([batch]);
  const [indexesChanged, setIndexesChanged] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSetBatches = (index: number, newValue: BatchType | null) => {
    setBatches((current) => {
      const newBatches = [...current];
      newBatches[index] = newValue;
      return newBatches;
    });
  };

  const handleSelectBatch = async (id: number, index: number) => {
    try {
      const response: BatchType = await requestGetBatch(id);
      if (response.id) {
        handleSetBatches(index, response);
      }
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const handleDeselectBatch = (index: number) => {
    handleSetBatches(index, null);
    setIndexesChanged((current) => current.filter((i) => i !== index));
  };

  const updateBatchPlate = (
    index: number,
    attributeKey: keyof BatchType,
    newValue: string | string[] | { [key: string]: any },
  ) => {
    handleSetBatches(index, {
      ...batches[index],
      [attributeKey]: newValue,
    } as BatchType);
    setIndexesChanged((current) => [...current].concat([index]));
  };

  const handleAddBatch = () => setBatches((current) => current.concat([null]));

  const submitPlateChanges = async () => {
    try {
      setIsLoading(true);
      const promises = (
        batches.filter((b, bindex) => b?.id && indexesChanged.includes(bindex)) as BatchType[]
      ).map((b) => requestUpdateBatch(b.id, formatBatchForSubmission(b)));
      await Promise.allSettled(promises);
      setIsLoading(false);
      setIndexesChanged([]);
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const generateSampleSheets = async () => {
    try {
      setIsLoading(true);
      const batchIds = (batches.filter((b) => b?.id) as BatchType[]).map((b) => b.id);
      const response: BatchType[] = await requestGenerateSampleSheetV2(batchIds);
      const dlBatch = response[0];
      if (!dlBatch || !dlBatch.miseq_sample_sheet_url || !dlBatch.sample_sheet_url) {
        throw Error(`Sample Sheet URL is not present`);
      }
      window.open(dlBatch.miseq_sample_sheet_url);
      window.open(dlBatch.sample_sheet_url);
      dispatch(getBatch(Number(batch.id)));
      setIsLoading(false);
    } catch (e) {
      showToast(e, 'error');
    }
  };

  return (
    <Stack>
      <Stack>
        <Group>
          <Text flex={2}>{getString('batch', language)}</Text>
          <Text flex={3}>{getString('plateBarcode', language)}</Text>
          <Text flex={2}>{getString('lane', language)}</Text>
          <Text flex={1}>{getString('indexSet', language)}</Text>
        </Group>
        {batches.map((b, bindex) => {
          return (
            <Group key={bindex} align="flex-start">
              <BatchSearchBar
                flex={2}
                onSelect={(id: number) => handleSelectBatch(id, bindex)}
                onDeselect={() => handleDeselectBatch(bindex)}
                value={b?.id}
                batchTypes={[batch.type]}
                placeholder={'search for batch'}
              />
              <Stack flex={6}>
                {b?.plates
                  .sort((b1, b2) => (b1.batch_order > b2.batch_order ? 1 : -1))
                  .map((p, pindex) => {
                    return (
                      <Group key={pindex}>
                        <Group gap="xs" flex={3}>
                          <Text>{`${p.batch_order + 1}.`}</Text>
                          <TextInput value={p.barcode} readOnly flex={1} />
                        </Group>
                        <LaneSelector
                          batch={b}
                          flex={2}
                          miw={0}
                          onValueChange={(attributeKey, newValue) =>
                            updateBatchPlate(bindex, attributeKey, newValue)
                          }
                          index={pindex}
                        />
                        <TextInput value={p.quadrant1_index_set_name} readOnly flex={1} />
                      </Group>
                    );
                  })}
                {b && !b.plates.length && (
                  <Text pl="xl">{getString('noPlatesListMsg', language)}</Text>
                )}
              </Stack>
            </Group>
          );
        })}
        <Group>
          <Button
            variant="subtle"
            c="blue.5"
            onClick={handleAddBatch}
            disabled={batches.length >= 16}
          >
            {`+ ${getString('addBatch', language)}`}
          </Button>
        </Group>
      </Stack>
      <Group>
        {indexesChanged.length ? (
          <Button onClick={submitPlateChanges} disabled={isLoading}>
            {getString('saveChanges', language)}
          </Button>
        ) : (
          <Button onClick={generateSampleSheets} disabled={isLoading}>
            {getString('dlSampleSheet', language)}
          </Button>
        )}
        {isLoading && <Loader size="sm" />}
      </Group>
    </Stack>
  );
};
