import {
  RECEIVE_FIELD_SAMPLE_PLANS,
  RECEIVE_SAMPLE_PLANS_TRACKING,
  REQUEST_SAMPLE_PLAN,
  REQUEST_SAMPLE_PLANS_TRACKING,
  SAMPLE_PLAN_REQUEST_ERROR,
  SAMPLE_PLAN_TRACKING_REQUEST_ERROR,
  SamplePlanActionsType,
  SamplePlanTrackingType,
  SamplePlanType,
} from './types';

export const receiveFieldPlans = (
  id: number,
  features: SamplePlanType[],
): SamplePlanActionsType => ({
  type: RECEIVE_FIELD_SAMPLE_PLANS,
  payload: { id, features },
});
export const requestSamplePlan = (id: number): SamplePlanActionsType => ({
  type: REQUEST_SAMPLE_PLAN,
  payload: { id },
});
export const samplePlanRequestError = (id: number): SamplePlanActionsType => ({
  type: SAMPLE_PLAN_REQUEST_ERROR,
  payload: { id },
});

export const requestSamplePlansTracking = (): SamplePlanActionsType => ({
  type: REQUEST_SAMPLE_PLANS_TRACKING,
});

export const samplePlanTrackingRequestError = (): SamplePlanActionsType => ({
  type: SAMPLE_PLAN_TRACKING_REQUEST_ERROR,
});

export const receiveSamplePlansTracking = (
  response: SamplePlanTrackingType[],
  page: number,
): SamplePlanActionsType => {
  return {
    type: RECEIVE_SAMPLE_PLANS_TRACKING,
    payload: {
      plans: response,
      page,
      per_page: response.length,
    },
  };
};
