import React from 'react';
import classNames from 'classnames';

import * as icons from './Icon';
import { Input, InputProps } from './Input';

import styles from './IconInput.module.css';

type IconInputProps = {
  icon: string;
  labelClassName?: string;
  className?: string;
  dataTestId?: string;
  right?: boolean;
} & InputProps;

export const IconInput = React.forwardRef<HTMLInputElement, IconInputProps>(
  ({ icon, labelClassName, right, className, dataTestId, ...props }, ref) => {
    labelClassName = classNames(styles.Label, labelClassName);
    className = classNames(right ? styles.InputRight : styles.InputLeft, className);

    const Icon = (icons as any)[icon];

    return (
      <label className={labelClassName}>
        {icon && <Icon className={classNames(styles.Icon, right ? styles.Right : styles.Left)} />}
        <Input data-test-id={dataTestId} className={className} {...props} ref={ref} />
      </label>
    );
  },
);

IconInput.displayName = 'IconInput';
IconInput.defaultProps = {
  className: '',
  labelClassName: '',
  dataTestId: '',
};
