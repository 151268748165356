import { useEffect, useRef, useState } from 'react';
import { Box, Flex, Stack, Switch, Text } from '@mantine/core';
import turfBbox from '@turf/bbox';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import { RISK_FILL_COLORS, WHITE } from 'constants/colors';
import { FIELD_OUTLINE, MAPBOX_FIT_PARAMS, MARKERS, MARKERS_IDS } from 'constants/mapbox';

import { translate590Layer } from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { FieldType, MapboxSample } from 'store/fields/types';
import useMapboxGl from 'common/MapHooks';
import { ViewPortProps } from 'common/Maps/types';

import styles from '../common/Mapbook.module.css';

interface FiveNinetyMapProps {
  field: FieldType;
  samples: MapboxSample[];
}

const FiveNinetyMap = ({ field, samples }: FiveNinetyMapProps) => {
  const language = useBroswerLanguage();

  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);

  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  const boundMapAndSetViewport = (map: mapboxgl.Map) => {
    const bbox = turfBbox(field) as LngLatBoundsLike;
    map.fitBounds(bbox, MAPBOX_FIT_PARAMS);
    const zoom = map.getZoom();
    setViewport((prevViewport) => ({
      ...prevViewport,
      centerLatitude,
      centerLongitude,
      zoom,
    }));
  };

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const source: GeoJSONSourceRaw = { type: 'geojson', data: field };

      if (map.getLayer(FIELD_OUTLINE)) {
        map.removeLayer(FIELD_OUTLINE);
        map.removeSource(FIELD_OUTLINE);
      }

      map.addLayer({
        id: FIELD_OUTLINE,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      boundMapAndSetViewport(map);

      if (map.getLayer(MARKERS)) {
        map.removeLayer(MARKERS);
        map.removeSource(MARKERS);
      }
      if (map.getLayer(MARKERS_IDS)) {
        map.removeLayer(MARKERS_IDS);
        map.removeSource(MARKERS_IDS);
      }
      const samplesWith590 = samples.filter((sample) => sample.properties.is_590_analysis);
      const samplesSource: GeoJSONSourceRaw = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: samplesWith590,
        },
      };
      const samplesIdsSource: GeoJSONSourceRaw = {
        type: 'geojson',
        data: featureCollection(translate590Layer(samplesWith590)),
      };
      map.addLayer({
        id: MARKERS,
        type: 'line',
        source: samplesSource,
        paint: {
          'line-color': WHITE,
        },
      });

      map.addLayer({
        id: MARKERS_IDS,
        type: 'symbol',
        source: samplesIdsSource,
        layout: {
          'text-allow-overlap': true,
          'text-field': ['get', 'id'],
          'text-justify': 'center',
          'text-size': 12,
        },
        paint: {
          'text-color': WHITE,
        },
      });
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    // Mapbox doesn't autodetect when the wrapper size changes so we need to call resize on the map
    // manually when the container size changes.
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current?.resize();
        boundMapAndSetViewport(mapRef.current);
      }
      // Slight delay to ensure CSS has applied
    }, 150);
  }, [mapRef, checked]);

  return (
    <Flex className={styles.FieldMap} direction={checked ? 'column' : 'row'}>
      <Box className={checked ? styles.FieldMapInfoFullWidth : styles.FieldMapInfo} mb="sm">
        <Flex
          align="center"
          bg={RISK_FILL_COLORS.NOT_DETECTED}
          className={styles.AnalyticHeader}
          justify="space-between"
        >
          <Text fw={500}>{getString('sampleIdMap', language)}</Text>
          <Switch
            checked={checked}
            label={getString('enlargeMap', language)}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Flex>
      </Box>
      <Stack
        className={checked ? styles.MapWrapperFullWidth : styles.MapWrapper}
        justify="flex-start"
      >
        <Box className={styles.Map} mih={checked ? '36rem' : '18rem'} ref={mapContainerRef} />
      </Stack>
    </Flex>
  );
};

export default FiveNinetyMap;
