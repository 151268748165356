import { approvalStatusType } from 'constants/routes/financeRoutes';

import {
  handleJsonResponse,
  requestGet,
  requestPatch,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';

import {
  AdjustmentsType,
  AdjustmentTypeV2,
  FinancialTransactionGroupResponse,
  SummaryFtType,
  SummaryTransactionsResponse,
} from './types';

export const getAllOpenTransactions = (
  search: string,
  page: number = 1,
  selectedRole: string | undefined,
) =>
  requestGet(
    `${SERVICE_URL}/open_transactions/?page=${page}${
      search.length > 0 ? `&search=${search}` : ''
    }&role=${selectedRole}`,
  ).then(handleJsonResponse);

export const getAllApprovedPayments = (
  search: string,
  page: number = 1,
  selectedRole: string | undefined,
) =>
  requestGet(
    `${SERVICE_URL}/payments/?page=${page}${
      search.length > 0 ? `&search=${search}` : ''
    }&role=${selectedRole}`,
  ).then(handleJsonResponse);

export const getOpenTransaction = async (id: number, planIds: number[]) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/open_transaction/${id}`, {
      body: {
        plan_ids: planIds,
      },
    }),
  );

export const getApprovedPayment = (uuid: string) =>
  requestGet(`${SERVICE_URL}/payment/${uuid}`).then(handleJsonResponse);

export const postPaymentToApprove = (
  user_id: number,
  is_qb: boolean,
  included_plans: number[],
  payment_term?: string,
) =>
  requestPost(`${SERVICE_URL}/payment/`, {
    body: {
      user_id,
      is_qb,
      included_plans,
      payment_term,
    },
  }).then(handleJsonResponse);

export const postQuickbooksToken = async (realm_id: string, auth_token: string) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/quickbooks/authorization/${realm_id}/${auth_token}`),
  );

export const postLoginToQuickbooks = async () =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/quickbooks/login`));

export const postAdjustment = (body: AdjustmentsType) =>
  requestPost(`${SERVICE_URL}/adjustment/`, { body }).then(handleJsonResponse);

export const putPaymentAsSettled = (uuid: string) =>
  requestPut(`${SERVICE_URL}/settle_payment/${uuid}`).then(handleJsonResponse);

export const getSummmaryFinancialTransactions = async (
  approvalStatus: approvalStatusType,
  page: number,
): Promise<SummaryTransactionsResponse> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/transactions/${approvalStatus}?page=${page}`),
  );

export const getSummaryFinancialTransactionsByUser = async (
  approvalStatus: approvalStatusType,
  userId: number,
): Promise<SummaryFtType> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/transactions/${approvalStatus}/${userId}`));

export const getFinancialTransactionsByUser = async (
  approvalStatus: approvalStatusType,
  userId: number,
): Promise<FinancialTransactionGroupResponse> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/user_transactions/${approvalStatus}/${userId}`),
  );

export const putAdjustmentV2 = async (
  body: Pick<AdjustmentTypeV2, 'amount' | 'description' | 'billing_user_id'>,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/billing_user_adjustment/${body.billing_user_id}`, { body }),
  );

export const patchFinancialTransaction = async (
  id: number,
  body: Pick<AdjustmentTypeV2, 'amount' | 'is_active'>,
) => handleJsonResponse(await requestPatch(`${SERVICE_URL}/financial_transaction/${id}`, { body }));
