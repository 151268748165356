import React from 'react';
import { Button, Group, Loader, Modal, Space, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Agency } from 'store/agencies/types';

interface Props {
  deleteModalOpen: Agency | null;
  setDeleteModalOpen: (arg0: Agency | null) => void;
  isSubmitting: boolean;
  deleteAgencyConnection: () => void;
  open: boolean;
}

const DeleteAgencyOperationConnection = ({
  deleteModalOpen,
  setDeleteModalOpen,
  isSubmitting,
  deleteAgencyConnection,
  open,
}: Props) => {
  const language = useBroswerLanguage();

  return (
    <Modal
      centered
      opened={open}
      size="lg"
      onClose={() => setDeleteModalOpen(null)}
      title={`${getString('deleteAgencyOperationConnection', language)} ${deleteModalOpen?.name}?`}
    >
      <Text>{getString('removeAgencyConnectionMsg', language)}</Text>
      <Space h="md" />
      <Group justify="flex-end">
        {isSubmitting && <Loader size="sm" />}
        <Button color="darkRed" onClick={deleteAgencyConnection}>
          {getString('remove', language)}
        </Button>
        <Button variant="outline" onClick={() => setDeleteModalOpen(null)}>
          {getString('cancel', language)}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteAgencyOperationConnection;
