import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Flex, Input, Slider, Stack, Text, Title } from '@mantine/core';

import { handleJsonResponse, requestPut, SERVICE_URL } from 'util/request';
import showToast, { ToastType } from 'actions/toastActions';

const TestEOCollections = () => {
  const [planId, setPlanId] = useState('');
  const [start, setStart] = useState(0);
  const [stop, setStop] = useState(100);
  const navigate = useNavigate();

  const setToast = (msg: string, status: ToastType = 'success') => showToast(msg, status);

  const handleSubmit = async () => {
    try {
      const body = {
        start,
        stop,
      };
      const response = await handleJsonResponse(
        await requestPut(`${SERVICE_URL}/create_test_collections/${planId}`, { body }),
      );
      if (response.message === 'ok') {
        setToast('Collection created successfully');
      } else {
        setToast('Request failed', 'error');
      }
    } catch (error) {
      showToast('Request failed', 'error');
    } finally {
      setPlanId('');
    }
  };

  return (
    <Stack>
      <Title order={1}>Create Test EO Collection For Sampling Plan</Title>
      <Center h="50vh">
        <Stack align="center">
          <Flex align="center" justify="space-between" w="15rem">
            <Text>Start</Text>
            <Slider
              marks={[
                { value: 25, label: '25%' },
                { value: 50, label: '50%' },
                { value: 75, label: '75%' },
              ]}
              max={100}
              min={0}
              onChange={setStart}
              value={start}
              w="80%"
            />
          </Flex>
          <Flex align="center" justify="space-between" w="15rem">
            <Text>Stop</Text>
            <Slider
              marks={[
                { value: 25, label: '25%' },
                { value: 50, label: '50%' },
                { value: 75, label: '75%' },
              ]}
              max={100}
              min={0}
              onChange={setStop}
              value={stop}
              w="80%"
            />
          </Flex>
          <Flex>
            <Input
              placeholder="Plan ID"
              value={planId}
              onChange={(event) => setPlanId(event.currentTarget.value)}
            />
            <Button disabled={start >= stop || !planId} ml="1rem" onClick={handleSubmit}>
              Submit
            </Button>
          </Flex>
          <Button onClick={() => navigate('/data-review')} w="10rem">
            Go To Data Inbox
          </Button>
        </Stack>
      </Center>
    </Stack>
  );
};

export default TestEOCollections;
