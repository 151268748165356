import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getIndexSets } from 'store/indexSets/thunks';
import { Header, Spinner, Table, TableCol, TableRow } from 'common';

import { CreateIndexSetButton } from './Create/CreateIndexSet';

import styles from './Container.module.css';

const IndexSetsOverview = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching, hasFetched, hasFailed, indexSets, setsByName } = useSelector(
    (state: RootState) => ({
      isFetching: state.indexSets.isFetching,
      hasFetched: state.indexSets.hasFetched,
      hasFailed: state.indexSets.hasFailed,
      indexSets: state.indexSets.sets,
      setsByName: state.indexSets.setsByName,
    }),
  );

  useEffect(() => {
    if (!isFetching && !hasFetched && !hasFailed) {
      dispatch(getIndexSets());
    }
  }, [dispatch, isFetching, hasFetched, hasFailed]);

  // Fetch index sets and filter them by name
  return (
    <div className={styles.Wrapper}>
      <Header title={<div className={styles.Header}>{getString('indexSets', language)}</div>}>
        <CreateIndexSetButton />
      </Header>
      <Table className={styles.Table}>
        <TableRow header className={styles.TableHeader}>
          <TableCol>{getString('name', language)}</TableCol>
        </TableRow>
        {isFetching && (
          <TableRow className={styles.ExpandingColumn}>
            <TableCol size="x4">
              <Spinner fill />
            </TableCol>
          </TableRow>
        )}
        {hasFetched &&
          Object.keys(setsByName).map((name) => {
            return (
              <TableRow
                key={name}
                onClick={() => {
                  navigate(`/lab/index_sets/details/${name}`);
                }}
              >
                <TableCol>{name}</TableCol>
              </TableRow>
            );
          })}
        {hasFetched && indexSets.length === 0 && (
          <TableRow>
            <TableCol size="x4">{getString('noIndexSetsFound', language)}</TableCol>
          </TableRow>
        )}
      </Table>
    </div>
  );
};

export default IndexSetsOverview;
