import React from 'react';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { joinStrings } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { CommercialTerritoryType } from 'store/commercialTerritories/types';
import { UserSelectorType } from 'store/user/types';
import { Button, Header, Input, Table, TableCol, TableRow, UserSearch } from 'common';

import styles from './Container.module.css';

interface TerritoryUsersProps {
  userTerritory: CommercialTerritoryType;
  setUserTerritory: (territory: CommercialTerritoryType) => void;
}

const TerritoryUsers = ({ userTerritory, setUserTerritory }: TerritoryUsersProps) => {
  const language = useBroswerLanguage();

  const addUser = async (user: UserSelectorType) => {
    if (!userTerritory.properties.users.find((u) => u.id === user.id)) {
      setUserTerritory({
        ...userTerritory,
        properties: {
          ...userTerritory.properties,
          users: [...userTerritory.properties.users, user],
        },
      });
    }
  };

  const removeUser = async (id: string | number) => {
    setUserTerritory({
      ...userTerritory,
      properties: {
        ...userTerritory.properties,
        users: userTerritory.properties.users.filter((user) => user.id !== id),
      },
    });
  };

  return (
    <div className={styles.UsersList}>
      <Header title={getString('territory', language)}>
        <Input
          className={styles.TerritoryNameInput}
          data-test-id="email"
          onChange={(e) =>
            setUserTerritory({
              ...userTerritory,
              properties: {
                ...userTerritory.properties,
                name: e.target.value,
              },
            })
          }
          placeholder={`${getString('territoryName', language)}...`}
          value={userTerritory.properties?.name || ''}
        />
      </Header>
      <div className={styles.UsersInput}>
        <UserSearch placeholder="Search by user" onSelect={addUser} className={styles.SearchBar} />
      </div>
      <Table>
        <TableRow className={styles.TerritoryUsersTable}>
          <TableCol>{getString('name', language)}</TableCol>
          <TableCol>{getString('email', language)}</TableCol>
          <TableCol>{getString('owner', language)}</TableCol>
          <TableCol />
        </TableRow>
      </Table>
      {userTerritory.properties.users?.map((user, index) => (
        <TableRow key={user.id} className={styles.TerritoryUsersTable}>
          <TableCol>{joinStrings([user.first_name, user.last_name], ' ')}</TableCol>
          <TableCol>{user.email}</TableCol>
          <TableCol>
            {userTerritory.properties.owner_id === user.id ||
            (index === 0 && !userTerritory.properties.id)
              ? getString('yes', language)
              : ''}
          </TableCol>
          <TableCol className={styles.Remove}>
            <Button danger onClick={() => removeUser(user.id)}>
              X
            </Button>
          </TableCol>
        </TableRow>
      ))}
    </div>
  );
};

export default TerritoryUsers;
