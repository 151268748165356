import { SAMPLE, SAMPLE_SCAN, SCAN } from 'constants/samplePlanning';

import { getString } from 'strings/translation';
import { SamplePlanTrackingType } from 'store/samplePlans/types';

export const hasNoProducts = (plan: SamplePlanTrackingType) => {
  const { nutrient_ordered_count, pressure_ordered_count, performance_ordered_count } = plan;

  return !(nutrient_ordered_count || pressure_ordered_count || performance_ordered_count);
};

export const getSamplingPlanBulkBuckets = (plans: SamplePlanTrackingType[]) =>
  plans.reduce(
    (acc, plan) => {
      const { is_pro, is_till_rx, assigned_to_id, assigned_to_scan_id } = plan;
      const isProOrTillRx = is_pro || is_till_rx;
      if (!isProOrTillRx) {
        acc[SAMPLE].push(plan);
      } else if (is_till_rx && hasNoProducts(plan)) {
        acc[SCAN].push(plan);
      } else if (isProOrTillRx) {
        if (assigned_to_id && !assigned_to_scan_id) {
          acc[SCAN].push(plan);
        } else if (!assigned_to_id && assigned_to_scan_id) {
          acc[SAMPLE].push(plan);
        } else {
          acc[SAMPLE_SCAN].push(plan);
        }
      }

      return acc;
    },
    { [SAMPLE]: [], [SAMPLE_SCAN]: [], [SCAN]: [] } as { [key: string]: SamplePlanTrackingType[] },
  );

export const getBulkAssignTabs = (
  plans: { [key: string]: SamplePlanTrackingType[] },
  language: string,
) => {
  return [
    {
      id: 1,
      label: getString('sampleOnly', language),
      value: SAMPLE,
      disabled: !plans[SAMPLE].length,
    },
    {
      id: 2,
      label: getString('samplePlusScan', language),
      value: SAMPLE_SCAN,
      disabled: !plans[SAMPLE_SCAN].length,
    },
    {
      id: 3,
      label: getString('scanOnly', language),
      value: SCAN,
      disabled: !plans[SCAN].length,
    },
  ];
};
