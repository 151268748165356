import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from 'store';
import { getPlate } from 'store/plates/thunks';
import { Spinner } from 'common';

import PageHeader from './Header/PageHeader';
import { PlateLayoutContainer as PlateLayout } from './PlateLayouts/Container';

import styles from './Container.module.css';

const PlatesDetailsContainer = () => {
  const { plateId: plateBarcode } = useParams<{ plateId: string }>();
  const dispatch = useDispatch();

  const { plate } = useSelector((state: RootState) => ({
    plate: plateBarcode ? state.plates.byBarcode[plateBarcode] : null,
  }));

  useEffect(() => {
    if (plateBarcode) {
      dispatch(getPlate(plateBarcode));
    }
  }, [plateBarcode]);

  return !plate || !plate.barcode ? (
    <div className={styles.Wrapper}>
      <Spinner fill />
    </div>
  ) : (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <PageHeader plate={plate} />
      </div>
      <div className={styles.Row}>
        <PlateLayout plate={plate} />
      </div>
    </div>
  );
};

export default PlatesDetailsContainer;
