import {
  CropPlanningActionType,
  CropPlanningStateType,
  PEST_GROUP_ERROR,
  RECEIVE_PEST_GROUPS,
  REQUEST_PEST_GROUPS,
} from './types';

export const initialState: CropPlanningStateType = {
  hasFetched: false,
  isFetching: true,
  hasFailed: false,
  pestGroups: null,
};

const cropPlanningReducer = (state = initialState, action: CropPlanningActionType) => {
  switch (action.type) {
    case REQUEST_PEST_GROUPS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
        hasFailed: false,
      };
    case RECEIVE_PEST_GROUPS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
        pestGroups: action.payload,
      };
    case PEST_GROUP_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};

export default cropPlanningReducer;
