import React from 'react';
import { BarChart } from '@mantine/charts';
import { Paper, Text } from '@mantine/core';
import { XAxis, YAxis } from 'recharts';

import { DARK_BLUE } from 'constants/colors';
import { ND } from 'constants/fieldRisks';
import { ROOTWORM_ID } from 'constants/results';

import { getQuantityFromPercentInLevel, getRiskColorFill } from 'util/chartUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { adjustAnalyticsForCompaction, getAnalyticValueCompaction } from 'util/proMaps';
import { getAnalyticFromPlan, roundAnalyticValue } from 'util/results';
import { getString } from 'strings/translation';
import { AnalyticType, AnalyticWithChartKeys, SingleAnalyticType } from 'store/analytics/types';
import { SamplingPlanType } from 'store/fields/types';

import styles from './Container.module.css';

interface ChartType {
  samplingPlan: SamplingPlanType;
  onClickChartBar: (id: number) => void;
  analyticsPerTab: AnalyticType[];
  isCompaction?: boolean;
}

interface ChartTooltipProps {
  label: string;
  payload: Record<string, any>[] | undefined;
}

const SummaryChart = ({
  samplingPlan,
  onClickChartBar,
  analyticsPerTab,
  isCompaction,
}: ChartType) => {
  const language = useBroswerLanguage();

  const compactionAdjustedAnalytics: AnalyticWithChartKeys[] = isCompaction
    ? adjustAnalyticsForCompaction(analyticsPerTab, language)
    : analyticsPerTab;

  const collectedValues = (() =>
    compactionAdjustedAnalytics.map((analytic) => {
      const analyticValue: SingleAnalyticType | null | undefined = isCompaction
        ? getAnalyticValueCompaction(samplingPlan, analytic)
        : getAnalyticFromPlan(samplingPlan, analytic);

      const newQuantity = getQuantityFromPercentInLevel(analyticValue) || 0.1;

      return {
        id: analytic.id,
        analyticName: analytic.name,
        riskLevel: analyticValue?.risk_level || '',
        quantity: newQuantity || null,
        actual: analyticValue?.quantity,
        units: analyticValue?.unit,
        moderateDataRange: analyticValue?.data_summary?.moderate,
        highDataRange: analyticValue?.data_summary?.high,
        lowDataRange: analyticValue?.data_summary?.low,
        tooltipName: analytic.tooltipName,
      };
    }))();

  const barData = collectedValues.map((analytic, i) => {
    return {
      x: 1 + i,
      y: analytic.quantity || 0,
      actual: analytic.actual,
      label: analytic.analyticName,
      fill: getRiskColorFill(analytic.riskLevel),
      units: analytic.units || '',
      id: analytic.id,
      moderateDataRange: analytic.moderateDataRange,
      highDataRange: analytic.highDataRange,
      lowDataRange: analytic.lowDataRange,
      tooltipName: analytic.tooltipName,
    };
  });

  const ChartsHeader = (props: any) => {
    const { x, y, payload, index } = props;
    const bar = barData[index];
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={-40} y={0} dy={0} width={80} height={70} className={styles.HeaderForeign}>
          <div
            className={styles.StackNameAndRanges}
            style={{ color: index % 2 === 0 && !isCompaction ? '#647594' : '#113063' }}
          >
            <div>
              <span className={styles.ChartHeaderTick} onClick={() => onClickChartBar(bar.id)}>
                {payload.value}
              </span>
            </div>
          </div>
        </foreignObject>
      </g>
    );
  };

  const isOverviewNd = (id: number, actual: number | undefined) => {
    const displayConfig = analyticsPerTab.find((analytic) => analytic.id === id);
    return (id === ROOTWORM_ID || displayConfig?.display_config?.is_overview_nd) && actual === 0;
  };

  const CustomBarLabel = (props: any) => {
    const { x, y, index } = props;
    const bar = barData[index];
    return (
      <text x={x + 25} y={y} dy={-4} fontSize="12" fill={DARK_BLUE} textAnchor="middle">
        {isOverviewNd(bar.id, bar.actual) ? ND : roundAnalyticValue(bar.actual)}
      </text>
    );
  };

  const ChartTooltip = ({ label, payload }: ChartTooltipProps) => {
    if (!payload?.[0]) return null;
    const item = payload[0].payload;
    return (
      <Paper px="md" py="sm" withBorder shadow="md" radius="md">
        <Text fw={500}>{item.tooltipName || label}</Text>
        <Text key={item.name} c={item.color} fz="sm" className={styles.PaddingLeft}>
          {getString('value', language)}:{' '}
          {isOverviewNd(item.id, item.actual) ? ND : roundAnalyticValue(item.actual)} {item.units}
        </Text>
        <Text fw={500}>{getString('ranges', language)}:</Text>
        <Text key={item.name} c={item.color} fz="sm" className={styles.PaddingLeft}>
          {getString('high', language)}: {item.highDataRange?.join(' - ')}
        </Text>
        <Text key={item.name} c={item.color} fz="sm" className={styles.PaddingLeft}>
          {getString('moderate', language)}: {item.moderateDataRange?.join(' - ')}
        </Text>
        <Text key={item.name} c={item.color} fz="sm" className={styles.PaddingLeft}>
          {getString('low', language)}: {item.lowDataRange?.join(' - ')}
        </Text>
      </Paper>
    );
  };

  return (
    <div className={styles.ChartWrapper}>
      <BarChart
        h="100%"
        data={barData}
        dataKey="label"
        legendProps={{ verticalAlign: 'bottom' }}
        withYAxis={false}
        withXAxis={false}
        series={[{ name: 'y', color: 'violet.6' }]}
        yAxisProps={{ domain: [0, 3] }}
        gridProps={{ horizontalValues: [0, 1, 2, 3] }}
        barProps={{ barSize: 50, label: CustomBarLabel, onClick: (e) => onClickChartBar(e.id) }}
        tooltipProps={{
          content: ({ label, payload }) => <ChartTooltip label={label} payload={payload} />,
        }}
      >
        <XAxis
          dataKey="label"
          minTickGap={2}
          xAxisId={1}
          interval={0}
          height={60}
          tick={<ChartsHeader />}
          orientation="bottom"
          axisLine={false}
        />
        <YAxis
          type="number"
          ticks={[1, 2, 3]}
          tickLine={false}
          minTickGap={0.9}
          tickMargin={-4}
          yAxisId={0}
          interval={0}
          mirror
          style={{
            fontSize: '0.8rem',
            fill: DARK_BLUE,
          }}
          tick={false}
          domain={[0, 3]}
          allowDataOverflow
        />
      </BarChart>
    </div>
  );
};

export default SummaryChart;
