import { Link } from 'react-router-dom';
import { Button, Flex, Stack, Title } from '@mantine/core';

import { getString } from 'strings/translation';
import { FieldPropertiesType } from 'store/fields/types';

type Props = {
  clickedFieldFeatureProperties: FieldPropertiesType;
  language: string;
};

const FieldPopupContent = ({ clickedFieldFeatureProperties, language }: Props) => {
  return (
    <Stack>
      <Title order={4}>{clickedFieldFeatureProperties.name}</Title>
      <Flex justify="center">
        <Button
          component={Link}
          relative="path"
          to={`../${clickedFieldFeatureProperties.id}`}
          mx="auto"
          size="xs"
        >
          {getString('viewEdit', language)} {getString('field', language)}
        </Button>
      </Flex>
    </Stack>
  );
};

export default FieldPopupContent;
