import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';

export const requestGetLabShipments = async (page: number = 1) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/lab_shipments/?page=${page}`));

export const requestGetLabShipment = async (id: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/lab_shipment/${id}`));

export const requestPutLabShipment = async (
  id: string,
  body: {
    is_regulated?: boolean;
    nutrient_sample_count?: number | null;
    plate_barcodes?: string[];
    new_tracking_numbers?: string[];
  },
) => handleJsonResponse(await requestPut(`${SERVICE_URL}/lab_shipment/${id}`, { body }));

export const requestPostLabShipment = async (body: {
  is_regulated?: boolean;
  nutrient_sample_count?: number | null;
  plate_barcodes?: string[];
  tracking_numbers?: string[];
}) => handleJsonResponse(await requestPost(`${SERVICE_URL}/lab_shipment/`, { body }));

export const requestDeleteLabShipment = async (id: string) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/lab_shipment/${id}`));
