import React, { useState } from 'react';
import { Button, Table } from '@mantine/core';

import { AGENCY, LOCATION, PARENT_AGENCY } from 'constants/agency';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Agency } from 'store/agencies/types';
import { Header } from 'common';
import CreateAgencyModal from 'common/Modals/CreateAgencyModal';

import ManageAgencyHierarchyRow from './ManageAgencyHierarchyRow';

import styles from './ManageAgencyHierarchy.module.css';

type PropTypes = {
  agency: Agency;
};

const ManageAgencyHierarchy = ({ agency }: PropTypes) => {
  const language = useBroswerLanguage();

  const [showCreateAgencyModal, toggleShowCreateAgencyModal] = useState(false);

  return (
    <div>
      {showCreateAgencyModal && (
        <CreateAgencyModal
          open={showCreateAgencyModal}
          onClose={() => toggleShowCreateAgencyModal(false)}
          isParentAgency={agency.hierarchy === PARENT_AGENCY}
          agency={agency}
        />
      )}
      <Header
        title={
          agency.hierarchy === PARENT_AGENCY
            ? getString('manageAgencies', language)
            : getString('manageLocations', language)
        }
      >
        <Button data-test-id="add-agency-button" onClick={() => toggleShowCreateAgencyModal(true)}>
          {agency.hierarchy === PARENT_AGENCY
            ? getString('addAgency', language)
            : getString('addLocation', language)}
        </Button>
      </Header>
      {agency?.child_agencies?.length ? (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{getString('agencyName', language)}</Table.Th>
              <Table.Th>{getString('primaryAgent', language)}</Table.Th>
              <Table.Th>{getString('email', language)}</Table.Th>
              <Table.Th>{getString('agents', language)}</Table.Th>
              <Table.Th>
                {agency.hierarchy === PARENT_AGENCY
                  ? getString('agencies', language)
                  : getString('locations', language)}
              </Table.Th>
              <Table.Th className={styles.ShrinkedColumn} />
            </Table.Tr>
          </Table.Thead>
          {agency.child_agencies.map((child) => (
            <ManageAgencyHierarchyRow agency={child} key={child.id} parentAgency={agency} />
          ))}
        </Table>
      ) : (
        <div className={styles.NoAgenciesExist}>
          **{getString('add', language)}{' '}
          {getString(agency.hierarchy === PARENT_AGENCY ? AGENCY : LOCATION, language)}**
        </div>
      )}
    </div>
  );
};

export default ManageAgencyHierarchy;
