import { useNavigate } from 'react-router-dom';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { TransformedValues, UseFormReturnType } from '@mantine/form';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { projectsRoutes } from 'constants/routes';

import { projectsQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { createSamplingPlan } from 'store/projects/requests';
import {
  CarbonSamplingPlanType,
  CreateSamplingPlanMutationData,
  SamplePlanForm,
} from 'store/projects/types';

import { useProjectsTranslations } from '../hooks';

type Props = {
  onClose: VoidFunction;
  opened: boolean;
  projectId: number;
  form: UseFormReturnType<SamplePlanForm, (values: SamplePlanForm) => SamplePlanForm>;
  fieldIds: number[];
};

const CarbonPlanOrderConfModal = ({ projectId, onClose, opened, form, fieldIds }: Props) => {
  const translations = useProjectsTranslations();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation<CarbonSamplingPlanType, Error, CreateSamplingPlanMutationData>({
    mutationFn: (mutationData) => {
      return createSamplingPlan({
        field_ids: mutationData.fieldIds,
        sample_plan: {
          project_id: projectId,
          density: Number(mutationData.density),
          registry: mutationData.registry,
        },
      });
    },
    onSuccess: () => {
      showToast(translations.samplingPlanCreated, 'success');

      queryClient.invalidateQueries({
        queryKey: projectsQueryKeys.projectSamplingPlanCount(projectId),
      });

      navigate(projectsRoutes.routes.project(projectId));
    },
  });

  const handleFormSubmit = ({ density, registry }: TransformedValues<typeof form>) => {
    mutation.mutate({ density, registry, fieldIds });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={translations.carbonPlanConfirmTitle}
      centered
      component="form"
      // @ts-expect-error // Mantine's `component` does not play well with `onSubmit`
      onSubmit={form.onSubmit(handleFormSubmit)}
    >
      <Text style={{ whiteSpace: 'pre-line' }}>{translations.carbonPlanConfirmBody}</Text>
      <Space h="lg" />
      <Group justify="center">
        <Button variant="outline" onClick={onClose}>
          {translations.cancel}
        </Button>
        <Button type="submit" disabled={mutation.isPending}>
          {translations.confirm}
        </Button>
      </Group>
    </Modal>
  );
};

export default CarbonPlanOrderConfModal;
