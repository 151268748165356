import { Select, SelectProps } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import { getString } from 'strings/translation';

type Props = {
  language: string;
  options: SelectProps['data'];
  onChange: (newDensity: string) => void;
};

const ZoneDensitySelect = ({ language, options, onChange }: Props) => {
  const form = useOrderFormContext();

  return (
    <Select
      key={form.key('density')}
      label={getString('density', language)}
      data={options}
      w="5rem"
      {...form.getInputProps('density')}
      value={String(form.getValues().density)}
      onChange={(density) => {
        if (density) {
          onChange(density);
          form.getInputProps('density').onChange(density);
        }
      }}
    />
  );
};

export default ZoneDensitySelect;
