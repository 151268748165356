import React from 'react';
import {
  ComboboxData,
  ComboboxItem,
  Divider,
  Flex,
  Input,
  SegmentedControl,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { P } from 'constants/chemistry';
import { BR } from 'constants/countries';
import { THREE_EM_DASH } from 'constants/defaultValues';
import {
  CONVENTIONAL_RX_TYPES,
  CUSTOM_RX_TYPES,
  IMAGERY,
  PRESCRIPTION_FORMULA_DETAILS,
  REMOVAL_P_ISU,
  REMOVAL_REC_FORMULAS,
  RX_NUTRIENT_ANALYTIC_MAP,
  RX_TYPE_BUILDUP,
  RX_TYPE_CUSTOM,
  RX_TYPE_REMOVAL,
  RX_TYPE_SEED,
  RX_ZONE_TYPE_CUSTOM,
  RX_ZONE_TYPE_FORMULA,
  SOIL_TEST,
  SOIL_TEST_FORMULAS,
  TARGET_VALUE_FORMULAS,
  YIELD,
  YIELD_FORMULAS,
} from 'constants/prescription';
import { DEFAULT_RX_DENSITY, PHOSPHORUS_ID, POTASSIUM_ID } from 'constants/results';

import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  convertNumericCustomZonesToString,
  getCropOptions,
  getDefaultCropYields,
  getDefaultRxCrop,
  getDefaultRxCustomZones,
  getFormulaOptions,
} from 'util/prescription';
import { getString } from 'strings/translation';
import { AnalyticType } from 'store/analytics/types';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { CompositeImageryType, FieldType, MachineDataType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import { SampleFeatureType } from 'store/samples/types';

import { PrescriptionStateType, RxTypeType } from './Container';
import LayerSelector from './LayerSelector';
import ProductSearch from './ProductSearch';
import SeedSearch from './SeedSearch';

type ScriptMetadataProps = {
  allFormulaOptions: ComboboxItem[];
  analytics: AnalyticType[];
  field: FieldType;
  getAvailableInputs: (nutrient?: string) => {
    id: number;
    displayName: string;
    value: InputType;
  }[];
  harvestYearOptions: ComboboxItem[];
  imageryYearOptions: ComboboxItem[];
  inputOptions: ComboboxData;
  planNutrientOptions: { id: number; value: string; label: string }[];
  prescriptionForm: UseFormReturnType<PrescriptionStateType>;
  proDensityOptions: ComboboxItem[];
  proLayer: EOInferenceLayerType | null | undefined;
  samples?: SampleFeatureType[];
  samplingPlanOptions: ComboboxItem[];
};

const ScriptMetadata = ({
  allFormulaOptions,
  analytics,
  field,
  getAvailableInputs,
  harvestYearOptions,
  imageryYearOptions,
  inputOptions,
  planNutrientOptions,
  prescriptionForm,
  proDensityOptions,
  proLayer,
  samples,
  samplingPlanOptions,
}: ScriptMetadataProps) => {
  const language = useBroswerLanguage();

  const {
    analytic,
    crop,
    customMapLayer,
    formula,
    generateFrom,
    harvestLayer,
    imageryLayer,
    input,
    maxRate,
    maxYield,
    minYield,
    nutrient,
    proDensity,
    rxType,
    rxZoneType,
    samplePlan,
  } = prescriptionForm.getValues();

  const { composite_imagery_layers, country_code, harvest_data_files } =
    field.features[0].properties;
  const isBrazilK = country_code === BR && Number(nutrient) === POTASSIUM_ID;
  const defaultCrop = getDefaultRxCrop(field);

  const handleChangeRxType = (val: string) => {
    if (![RX_TYPE_BUILDUP, RX_TYPE_REMOVAL, RX_TYPE_SEED, RX_TYPE_CUSTOM].includes(val)) {
      return;
    }
    const formulaOptions = getFormulaOptions(language, field, nutrient);
    prescriptionForm.setFieldValue('rxType', val as RxTypeType);

    const defaultHarvestOption = harvestYearOptions[0];
    // when switching to removal/custom for yield files, we need to update the rx crop.
    // By default, set it to the crop value of the first harvest year option. Since these are nullable,
    // fall back to default crop or (in the case of prescription for a field with no crop plans) corn
    const defaultHarvestLayer = harvest_data_files.find(
      (file) => file.processed_geojson_uri === defaultHarvestOption?.value,
    );
    const defaultHarvestCrop = defaultHarvestLayer?.crop || defaultCrop;

    // buildup and removal type should revert to P if a non-convential analytic is selected
    const phosphorusAnalytic = analytics.find((a) => a.id === PHOSPHORUS_ID);
    const pInputs = getAvailableInputs(P);
    const pInput = String(pInputs[0]?.value.id || '');
    const buildupRemovalDefaults = {
      analytic: phosphorusAnalytic || null,
      input: pInput,
      nutrient: P,
    };
    if (val === RX_TYPE_BUILDUP) {
      prescriptionForm.setValues({
        ...buildupRemovalDefaults,
        crop: defaultCrop,
        formula: formulaOptions.NUTRIENTS[0]?.value || formula,
        generateFrom: SOIL_TEST,
      });
    } else if (val === RX_TYPE_REMOVAL) {
      const newGenerateFrom =
        imageryYearOptions.length && !harvestYearOptions.length ? IMAGERY : YIELD;
      prescriptionForm.setValues({
        ...buildupRemovalDefaults,
        crop: newGenerateFrom === YIELD ? defaultHarvestCrop : defaultCrop,
        formula: REMOVAL_P_ISU,
        generateFrom: newGenerateFrom,
        harvestLayer: newGenerateFrom === YIELD ? defaultHarvestOption?.value : null,
        imageryLayer: newGenerateFrom === IMAGERY ? imageryYearOptions[0]?.value : null,
      });
    }

    // reset seed to null for everything besides seed
    if (val !== RX_TYPE_SEED) {
      prescriptionForm.setFieldValue('seed', null);
    }
    if (val !== RX_TYPE_CUSTOM) {
      prescriptionForm.setFieldValue('agronomicProduct', null);
    }

    if (CUSTOM_RX_TYPES.includes(val)) {
      prescriptionForm.setValues({
        generateFrom: '',
        input: null,
      });
      const firstImageryLayer = composite_imagery_layers.find(
        (file) => file.geojson_uri === imageryYearOptions[0]?.value,
      );
      if (defaultHarvestLayer) {
        prescriptionForm.setValues({
          analytic: null,
          crop: defaultHarvestCrop,
          customMapLayer: YIELD,
          customZones: convertNumericCustomZonesToString(
            getDefaultRxCustomZones([], YIELD, null, defaultHarvestLayer),
          ),
          harvestLayer: defaultHarvestLayer.processed_geojson_uri,
        });
      } else if (firstImageryLayer) {
        prescriptionForm.setValues({
          analytic: null,
          customMapLayer: IMAGERY,
          customZones: convertNumericCustomZonesToString(
            getDefaultRxCustomZones([], YIELD, null, firstImageryLayer),
          ),
          imageryLayer: firstImageryLayer.geojson_uri,
        });
      } else {
        // if no harvest/imagery data, keep analytic the same and populate the custom layer with analytic id
        prescriptionForm.setValues({
          customMapLayer: analytic?.id.toString() || '',
          customZones: convertNumericCustomZonesToString(
            getDefaultRxCustomZones(samples || [], SOIL_TEST, analytic, proLayer),
          ),
          harvestLayer: null,
          imageryLayer: null,
        });
      }
    }
    // for seed and custom scripts, zone type is always custom. otherwise default to formula
    if (CUSTOM_RX_TYPES.includes(val)) {
      prescriptionForm.setFieldValue('rxZoneType', RX_ZONE_TYPE_CUSTOM);
    } else {
      prescriptionForm.setValues({
        customMapLayer: null,
        rxZoneType: RX_ZONE_TYPE_FORMULA,
      });
    }
  };

  const getSourceLayer = () => {
    if (generateFrom === IMAGERY) {
      return composite_imagery_layers.find((layer) => layer.geojson_uri === imageryLayer);
    }
    if (generateFrom === YIELD) {
      return harvest_data_files.find((layer) => layer.processed_geojson_uri === harvestLayer);
    }
    if (generateFrom === SOIL_TEST && proLayer) {
      return proLayer;
    }
  };

  const populateDefaultCustomZones = (
    forceGenerateFrom?: string,
    forceAnalytic?: AnalyticType,
    forceLayer?: MachineDataType | CompositeImageryType | EOInferenceLayerType,
  ) => {
    const defaultZones = convertNumericCustomZonesToString(
      getDefaultRxCustomZones(
        samples || [],
        forceGenerateFrom || generateFrom,
        forceAnalytic || analytic,
        forceLayer || getSourceLayer(),
      ),
    );
    prescriptionForm.setFieldValue('customZones', defaultZones);
  };

  const handleChangeRxZoneType = (val: string) => {
    // TS doesn't like [].includes
    if (val === RX_ZONE_TYPE_FORMULA || val === RX_ZONE_TYPE_CUSTOM) {
      prescriptionForm.setFieldValue('rxZoneType', val);
    }
    if (val === RX_ZONE_TYPE_CUSTOM) {
      populateDefaultCustomZones();
    } else if (val === RX_ZONE_TYPE_FORMULA) {
      prescriptionForm.setFieldValue('customZones', []);
    }
  };

  // Nutrient selector
  const handleNutrientChange = (val: string) => {
    const optionsByCategory = getFormulaOptions(language, field, val);
    const allOptions =
      rxType === RX_TYPE_BUILDUP ? optionsByCategory.NUTRIENTS : optionsByCategory.REMOVAL;
    if (allOptions.length) {
      prescriptionForm.setFieldValue('formula', allOptions[0].value);
    }
    const availableInputs = getAvailableInputs(val);
    if (availableInputs.length) {
      prescriptionForm.setFieldValue('input', String(availableInputs[0].value.id));
    }
    if (rxZoneType === RX_ZONE_TYPE_CUSTOM) {
      const nutrientOptionSelected = planNutrientOptions?.find((option) => option.value === val);
      const newNutrient = analytics.find((a) => a.id === nutrientOptionSelected?.id);
      if (newNutrient) {
        populateDefaultCustomZones(undefined, newNutrient);
      }
    }
    prescriptionForm.setFieldValue('nutrient', val);

    const analyticId = RX_NUTRIENT_ANALYTIC_MAP[val];
    if (analyticId) {
      const newAnalytic = analytics.find((a) => a.id === analyticId);
      prescriptionForm.setFieldValue('analytic', newAnalytic || null);
    }
  };

  // Generate from selector
  const handleGenerateFromChange = (val: string) => {
    prescriptionForm.setFieldValue('generateFrom', val);
    if (val === SOIL_TEST) {
      prescriptionForm.setValues({
        harvestLayer: null,
        imageryLayer: null,
      });
    } else if (val === YIELD) {
      prescriptionForm.setFieldValue('imageryLayer', null);
      if (!harvestLayer) {
        const newHarvestLayer = harvest_data_files.find(
          (file) => file.processed_geojson_uri === harvestYearOptions[0]?.value,
        );
        if (newHarvestLayer) {
          prescriptionForm.setFieldValue('harvestLayer', newHarvestLayer.processed_geojson_uri);
          if (rxZoneType === RX_ZONE_TYPE_CUSTOM) {
            populateDefaultCustomZones(undefined, undefined, newHarvestLayer);
          }
        }
      }
    } else if (val === IMAGERY) {
      prescriptionForm.setFieldValue('harvestLayer', null);
      if (!imageryLayer) {
        const { defaultMaxYield, defaultMinYield } = getDefaultCropYields(field, crop);
        const newImageryLayer = composite_imagery_layers.find(
          (file) => file.geojson_uri === imageryYearOptions[0]?.value,
        );
        prescriptionForm.setValues({
          maxYield: defaultMaxYield,
          minYield: defaultMinYield,
        });
        if (newImageryLayer) {
          prescriptionForm.setFieldValue('imageryLayer', newImageryLayer.geojson_uri);
          if (rxZoneType === RX_ZONE_TYPE_CUSTOM) {
            populateDefaultCustomZones(undefined, undefined, newImageryLayer);
          }
        }
      }
    }
  };

  // Formula options
  const handleFormulaChange = (value = '') => {
    prescriptionForm.setFieldValue('formula', value);
    if (REMOVAL_REC_FORMULAS.includes(value)) {
      if (!harvestLayer && generateFrom === YIELD) {
        prescriptionForm.setFieldValue('harvestLayer', harvestYearOptions[0]?.value || null);
      }
      if (!imageryLayer && generateFrom === IMAGERY) {
        prescriptionForm.setFieldValue('imageryLayer', imageryYearOptions[0]?.value || null);
      }
      if (!proDensity) {
        prescriptionForm.setFieldValue('proDensity', DEFAULT_RX_DENSITY.value);
      }
    } else {
      prescriptionForm.setFieldValue('harvestLayer', null);
      prescriptionForm.setFieldValue('imageryLayer', null);
    }
  };

  const isHarvest = generateFrom === YIELD || customMapLayer === YIELD;
  const isImagery = generateFrom === IMAGERY || customMapLayer === IMAGERY;

  const showYieldSource = isHarvest || isImagery;
  const showDensity = showYieldSource || proLayer;
  const cropOptions = getCropOptions(language, formula).map((option) => ({
    ...option,
    label: option.displayName,
  }));

  const handleHarvestLayerChange = (val: string) => {
    const newHarvestLayer = harvest_data_files.find((file) => file.processed_geojson_uri === val);
    prescriptionForm.setValues({
      crop: newHarvestLayer?.crop || crop,
      harvestLayer: val,
    });
  };

  const densitySelector = (
    <Flex gap="xs" justify="space-between">
      <Text>{getString('density', language)}</Text>
      <Select
        data={proDensityOptions}
        onChange={(val) => val && prescriptionForm.setFieldValue('proDensity', val)}
        value={proDensity}
      />
    </Flex>
  );

  return (
    <Stack w="25rem">
      <SegmentedControl
        data={[
          { label: getString('buildup', language), value: RX_TYPE_BUILDUP },
          { label: getString('removal', language), value: RX_TYPE_REMOVAL },
          { label: getString('seed', language), value: RX_TYPE_SEED },
          { label: getString('custom', language), value: RX_TYPE_CUSTOM },
        ]}
        onChange={handleChangeRxType}
        value={rxType}
      />

      <Flex gap="xs" justify="space-between">
        {CONVENTIONAL_RX_TYPES.includes(rxType) ? (
          <>
            <Text>{getString('nutrient', language)}</Text>
            <Select
              data={planNutrientOptions}
              onChange={(val) => val && handleNutrientChange(val)}
              value={nutrient}
            />
          </>
        ) : (
          <LayerSelector
            field={field}
            language={language}
            proLayer={proLayer}
            rxForm={prescriptionForm}
            samples={samples}
          />
        )}
      </Flex>
      {CONVENTIONAL_RX_TYPES.includes(rxType) && (
        <Flex gap="xs" justify="space-between">
          <Text>{getString('input', language)}</Text>
          <Select
            data={inputOptions}
            onChange={(val) => val && prescriptionForm.setFieldValue('input', val)}
            value={input}
          />
        </Flex>
      )}
      {rxType === RX_TYPE_BUILDUP && showDensity && densitySelector}
      {CONVENTIONAL_RX_TYPES.includes(rxType) && (
        <Flex align="center" justify="space-between">
          <Text>{getString('rxBase', language)}</Text>
          <SegmentedControl
            data={[
              { label: getString('formula', language), value: RX_ZONE_TYPE_FORMULA },
              { label: getString('customZones', language), value: RX_ZONE_TYPE_CUSTOM },
            ]}
            disabled={[RX_TYPE_SEED, RX_TYPE_CUSTOM].includes(rxType)}
            onChange={handleChangeRxZoneType}
            value={rxZoneType}
          />
        </Flex>
      )}
      {rxType === RX_TYPE_REMOVAL && (
        <Flex align="center" justify="space-between">
          <Text>{getString('generateFrom', language)}</Text>
          <SegmentedControl
            data={[
              { label: getString('yield', language), value: YIELD },
              { label: getString('imagery', language), value: IMAGERY },
            ]}
            onChange={handleGenerateFromChange}
            value={generateFrom}
          />
        </Flex>
      )}
      {showYieldSource && (
        <Flex justify="space-between">
          <Text mr="xs">{getString('year', language)}</Text>
          {generateFrom === YIELD || customMapLayer === YIELD ? (
            <Select
              data={harvestYearOptions}
              disabled={!harvestYearOptions.length}
              onChange={(val) => val && handleHarvestLayerChange(val)}
              value={harvestLayer}
            />
          ) : (
            <Select
              data={imageryYearOptions}
              disabled={!imageryYearOptions.length}
              onChange={(val) => val && prescriptionForm.setFieldValue('imageryLayer', val)}
              value={imageryLayer}
            />
          )}
        </Flex>
      )}
      {isImagery && rxZoneType !== RX_ZONE_TYPE_CUSTOM && (
        <>
          <Flex gap="xs" justify="space-between">
            <Text>{getString('previousCrop', language)}:</Text>
            <Select
              data={cropOptions}
              onChange={(val) => val && prescriptionForm.setFieldValue('crop', val)}
              value={crop}
            />
          </Flex>
          <Flex gap="xs" justify="space-between">
            <Text>{getString('maxYield', language)}:</Text>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                prescriptionForm.setFieldValue('maxYield', Number(e.target.value || 0))
              }
              type="number"
              value={maxYield}
            />
          </Flex>
          <Flex gap="xs" justify="space-between">
            <Text>{getString('minYield', language)}:</Text>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                prescriptionForm.setFieldValue('minYield', Number(e.target.value || 0))
              }
              type="number"
              value={minYield}
            />
          </Flex>
        </>
      )}
      <Flex gap="xs" justify="space-between">
        {rxType === RX_TYPE_SEED && (
          <SeedSearch defaultSeed={null} language={language} rxForm={prescriptionForm} />
        )}
        {rxType === RX_TYPE_CUSTOM && (
          <ProductSearch defaultProduct={null} language={language} rxForm={prescriptionForm} />
        )}
      </Flex>
      {rxType !== RX_TYPE_BUILDUP && showDensity && densitySelector}
      {rxZoneType === RX_ZONE_TYPE_FORMULA && (
        <>
          <Flex justify="space-between">
            <Text mr="xs">{getString('formula', language)}</Text>
            <Select
              data={allFormulaOptions}
              onChange={(value) => value && handleFormulaChange(value)}
              value={formula}
            />
          </Flex>
          <Flex justify="space-between">
            <Text>{getString('formulaDetails', language)}:</Text>
            <Stack align="flex-end">
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {PRESCRIPTION_FORMULA_DETAILS[crop]?.[formula] || THREE_EM_DASH}
              </Text>
              {SOIL_TEST_FORMULAS.includes(formula) && (
                <Text>ST = {getString('soilTestValue', language)}</Text>
              )}
              {YIELD_FORMULAS.includes(formula) && <Text>Y: {getString('yield', language)}</Text>}
              {TARGET_VALUE_FORMULAS.includes(formula) && (
                <Text>TV: {getString('target', language)}</Text>
              )}
            </Stack>
          </Flex>
          <Divider />
        </>
      )}
      {rxType === RX_TYPE_BUILDUP && (
        <Flex justify="space-between">
          <Text mr="xs">{getString('soilTest', language)}</Text>
          <Select
            data={samplingPlanOptions}
            onChange={(val) => val && prescriptionForm.setFieldValue('samplePlan', val)}
            value={samplePlan}
          />
        </Flex>
      )}
      {isBrazilK && (
        <Flex justify="space-between">
          <Text>{getString('maximumRate', language)}</Text>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              prescriptionForm.setFieldValue('maxRate', Number(e.target.value || 0))
            }
            type="number"
            value={maxRate}
          />
        </Flex>
      )}
    </Stack>
  );
};

export default ScriptMetadata;
