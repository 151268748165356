import { byKey } from 'util/collectionUtils';

import {
  BATCH_REQUEST_ERROR,
  BatchActionsType,
  BatchStateType,
  RECEIVE_BATCH,
  RECEIVE_BATCH_FILES,
  RECEIVE_BATCHES,
  REQUEST_BATCHES,
} from './types';

const initialState: BatchStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  errorMessage: '',
  page: 1,
  fetchRequestNumber: 0,
  totalPages: 0,
  byId: {},
  batches: [],
};

const BatchReducer = (state = initialState, action: BatchActionsType) => {
  switch (action.type) {
    case RECEIVE_BATCHES:
      if (action.payload.fetchRequestNumber !== state.fetchRequestNumber) {
        return state;
      }
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        byId: byKey(action.payload.items, 'id'),
        batches: action.payload.items,
        page: action.payload.page || 1,
        totalPages: action.payload.per_page
          ? Math.ceil(action.payload.total / action.payload.per_page)
          : 1,
      };
    case REQUEST_BATCHES:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        hasFailed: false,
        errorMessage: '',
        fetchRequestNumber: state.fetchRequestNumber + 1,
        byId: {},
      };
    case RECEIVE_BATCH:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
        errorMessage: '',
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    case RECEIVE_BATCH_FILES:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            uploaded_files: action.payload.files,
          },
        },
      };
    case BATCH_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};

export default BatchReducer;
