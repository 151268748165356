import React from 'react';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getTrackingStatusDisplayName } from 'util/shipping';
import { getString } from 'strings/translation';
import { ShippingLabel } from 'store/shipping/types';
import { SortableTable, Spinner } from 'common';

import styles from './UPSLabels.module.css';

type UPSLabelsListPropsType = {
  labels?: ShippingLabel[];
};

export const UPSLabelsList = ({ labels }: UPSLabelsListPropsType) => {
  const language = useBroswerLanguage();

  if (!labels?.length) {
    return (
      <div className={styles.NoLabels}>
        {getString('noLabelsListMsg', language)}
        {labels === undefined && <Spinner className={styles.Spinner} />}
      </div>
    );
  }

  const tableColumns = [
    {
      name: getString('label', language),
      attribute: 'tracking_number',
      sortable: true,
      cellFormatter: (label: string) => <div className={styles.Link}>{label}</div>,
    },
    {
      name: getString('status', language),
      attribute: 'status',
      sortable: true,
      cellFormatter: (status: string) => getTrackingStatusDisplayName(status, language),
    },
  ];

  return (
    <SortableTable
      title=""
      data={labels}
      columns={tableColumns}
      initialSortBy={'tracking_number'}
    />
  );
};
