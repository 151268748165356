import React from 'react';
import { Stack } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import {
  AMMONIUM_ACETATE,
  BRAY,
  BULK_DENSITY,
  KCL,
  M3,
  OM,
  PH,
  SHOTGUN,
  SHOTGUN_QPCR,
  SOIL_TEXTURE,
  TOTAL_CARBON,
  TOTAL_INORGANIC_CARBON,
} from 'constants/analysis';

import { getRequiredAnalysisForSample } from 'util/analysis';
import { LabSampleType } from 'store/labSamples/types';

import { DueDate } from './DueDate';

type DueDatePropsType = {
  sample: LabSampleType;
  sampleQuery: UseQueryResult<LabSampleType, Error>;
};

export const DueDates = ({ sample, sampleQuery }: DueDatePropsType) => {
  const requiredAnalyses = getRequiredAnalysisForSample(sample, true);
  return (
    <Stack>
      {requiredAnalyses.includes(SHOTGUN) && (
        <DueDate analysisMethod={SHOTGUN} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(SHOTGUN_QPCR) && (
        <DueDate analysisMethod={SHOTGUN_QPCR} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(M3) && (
        <DueDate analysisMethod={M3} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(KCL) && (
        <DueDate analysisMethod={KCL} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(PH) && (
        <DueDate analysisMethod={PH} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(AMMONIUM_ACETATE) && (
        <DueDate analysisMethod={AMMONIUM_ACETATE} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(OM) && (
        <DueDate analysisMethod={OM} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(BRAY) && (
        <DueDate analysisMethod={BRAY} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(BULK_DENSITY) && (
        <DueDate analysisMethod={BULK_DENSITY} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(TOTAL_CARBON) && (
        <DueDate analysisMethod={TOTAL_CARBON} sample={sample} sampleQuery={sampleQuery} />
      )}
      {requiredAnalyses.includes(TOTAL_INORGANIC_CARBON) && (
        <DueDate
          analysisMethod={TOTAL_INORGANIC_CARBON}
          sample={sample}
          sampleQuery={sampleQuery}
        />
      )}
      {requiredAnalyses.includes(SOIL_TEXTURE) && (
        <DueDate analysisMethod={SOIL_TEXTURE} sample={sample} sampleQuery={sampleQuery} />
      )}
    </Stack>
  );
};
