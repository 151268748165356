import React from 'react';
import classNames from 'classnames';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { capitalize, numsToInvoiceDollars } from 'util/stringUtils';
import { AdjustmentsSimpleType } from 'store/payments/types';
import { TableCol, TableRow } from 'common';

import styles from './PaymentTables.module.css';

type AdjustmentsRowPropTypes = {
  description: string | null;
  approvedBy: string;
  transactionType: AdjustmentsSimpleType['transaction_type'];
  amount: number;
};

const AdjustmentsRow = ({
  description,
  approvedBy,
  transactionType,
  amount,
}: AdjustmentsRowPropTypes) => {
  const displayAmount = transactionType === 'debit' ? amount * -1 : amount;

  return (
    <TableRow>
      <TableCol className={classNames(styles.BigColumn, styles.TransactionTitleColumn)} shrink>
        {description ? capitalize(description) : NOT_APPLICABLE}
      </TableCol>
      <TableCol>{approvedBy ? capitalize(approvedBy) : NOT_APPLICABLE}</TableCol>
      <TableCol>{transactionType ? capitalize(transactionType) : NOT_APPLICABLE}</TableCol>
      <TableCol>{amount ? numsToInvoiceDollars(displayAmount) : numsToInvoiceDollars(0)}</TableCol>
    </TableRow>
  );
};

export default AdjustmentsRow;
