import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { MdCircle } from 'react-icons/md';
import classNames from 'classnames';

import Styles from './CarouselSelector.module.css';

interface CarouseSelectorProps {
  setSelectedIndex: (index: number) => void;
  indexes: number[];
  selectedIndex: number;
  wrapperClassName?: string;
  chevronSize?: string;
  circleSize?: string;
}
const CarouselSelector = ({
  wrapperClassName,
  chevronSize,
  circleSize,
  indexes,
  selectedIndex,
  setSelectedIndex,
}: CarouseSelectorProps) => {
  const setSelectorScreenLeft = () => {
    setSelectedIndex(selectedIndex === 0 ? 0 : selectedIndex - 1);
  };

  const setSelectorScreenRight = () => {
    setSelectedIndex(selectedIndex === Math.max(...indexes) ? selectedIndex : selectedIndex + 1);
  };

  return (
    <div className={classNames(Styles.Wrapper, wrapperClassName)}>
      <FiChevronLeft
        size={chevronSize || '4em'}
        onClick={setSelectorScreenLeft}
        className={Styles.Arrow}
      />
      {indexes.map((index) => (
        <MdCircle
          key={index}
          size={circleSize || '1.5em'}
          className={Styles.CircleSelector}
          onClick={() => setSelectedIndex(index)}
          color={selectedIndex === index ? '#003166' : '#b8c1d0'}
        />
      ))}
      <FiChevronRight
        size={chevronSize || '4em'}
        onClick={setSelectorScreenRight}
        className={Styles.Arrow}
      />
    </div>
  );
};

export default CarouselSelector;
