import { Button, FileButton } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useFieldQuery } from 'apps/ZoneAnalysisV3/queries';
import { FeatureCollection, Point, Polygon } from 'geojson';

import { POLYGON } from 'constants/mapbox';

import {
  clipZonesToLayer,
  filterZonesWithinBoundaries,
  processZoneFeatureCollection,
} from 'util/geospatial';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { postZonesForUpload } from 'store/samplePlans/requests';

type Props = {
  language: string;
  onSuccess: (newZones: FeatureCollection<Point | Polygon>) => void;
};

const UploadZoneFileButton = ({ language, onSuccess }: Props) => {
  const form = useOrderFormContext();
  const { data: fieldFeatureCollection } = useFieldQuery();

  const mutation = useMutation({
    mutationFn: (file: File) => postZonesForUpload(file),
    onSuccess: (uploadedFeatureCollection) => {
      if (fieldFeatureCollection && uploadedFeatureCollection.features.length) {
        const filteredZones = filterZonesWithinBoundaries(
          uploadedFeatureCollection,
          fieldFeatureCollection,
        );

        const clippedZones = clipZonesToLayer(filteredZones, fieldFeatureCollection);

        if (clippedZones.features?.length) {
          const newZones = processZoneFeatureCollection(clippedZones, POLYGON);

          onSuccess(newZones as FeatureCollection<Point | Polygon>);
          form.setValues({ zones: newZones as FeatureCollection<Polygon> });
          showToast(getString('planZonesSuccessMsg', language));
        } else {
          showToast(getString('planZonesErrorDiffMsg', language), 'error');
        }
      } else {
        showToast(getString('planZonesErrorNoZonesMsg', language), 'error');
      }
    },
    onError: (error) => {
      showToast(error.message || getString('planZonesErrorNoZipMsg', language), 'error');
    },
  });

  if (form.getValues().creationOption !== 'upload_zones') {
    return null;
  }

  return (
    <FileButton
      key={form.key('zonesFile')}
      accept=".gz,.zip"
      {...form.getInputProps('zonesFile')}
      onChange={(file) => {
        form.getInputProps('zonesFile').onChange(file);
        file && mutation.mutate(file);
      }}
    >
      {(props) => (
        <Button {...props} variant="outline" loading={mutation.isPending}>
          {getString(mutation.isSuccess ? 'resetMap' : 'upload', language)}
        </Button>
      )}
    </FileButton>
  );
};

export default UploadZoneFileButton;
