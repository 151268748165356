import React from 'react';
import { Group, Stack } from '@mantine/core';

import { numPlatesPerBatchType } from 'constants/batches';

import { BatchFormType } from 'store/batches/types';

import { LaneSelector } from './FormInputs/LaneSelector';
import { SourceBarcodeInput } from './FormInputs/SourceBarcodeInput';

type FormRowsPropsType = {
  batch: BatchFormType;
  onValueChange: (
    attributeKey: keyof BatchFormType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
  showLanes?: boolean;
};

export const FormRows = ({ batch, onValueChange, showLanes }: FormRowsPropsType) => {
  const batchType = batch.type;
  const numPlatesList = new Array(batchType ? numPlatesPerBatchType[batchType] : 0).fill(0);
  return (
    <Stack>
      {numPlatesList.map((_, idx) => (
        <Group key={idx} justify="flex-start" align="top">
          <SourceBarcodeInput
            index={idx}
            batch={batch}
            onValueChange={onValueChange}
            showLabel
            w="25rem"
          />
          {showLanes && (
            <LaneSelector
              index={idx}
              batch={batch}
              onValueChange={onValueChange}
              showLabel
              w="12.5rem"
            />
          )}
        </Group>
      ))}
    </Stack>
  );
};
