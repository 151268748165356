import { NOT_STARTED, progressDisplayNames } from 'constants/orders';

import { getString } from 'strings/translation';
import { OrderHistorySamplePlanType } from 'store/orderHistory/types';
import { ProgressStatusType } from 'store/samplePlans/types';

import { sum } from './numUtils';

export const retrieveProgressStatuses = (
  progressStatuses: ProgressStatusType | undefined,
  language: string,
) => {
  const defaultString = getString('none', language);
  if (progressStatuses === undefined) {
    return {
      samplingStatus: NOT_STARTED,
      analysisStatus: NOT_STARTED,
      orderedStatus: 0,
      sampledStatus: 0,
      arrivedStatus: 0,
      nutrientStatus: defaultString,
      pressureStatus: defaultString,
      performanceStatus: defaultString,
    };
  }

  const samplingStatus = getString(
    progressDisplayNames[progressStatuses?.sampling_progress_status || NOT_STARTED],
    language,
  );
  const analysisStatus = getString(
    progressDisplayNames[progressStatuses?.analysis_progress_status || NOT_STARTED],
    language,
  );

  return {
    samplingStatus,
    analysisStatus,
    orderedStatus: progressStatuses.total_active_count || '0',
    sampledStatus: progressStatuses.sampled_count || '0',
    arrivedStatus: progressStatuses.arrived_count || '0',
    nutrientStatus: progressStatuses.nutrient_status || defaultString,
    pressureStatus: progressStatuses.pressure_status || defaultString,
    performanceStatus: progressStatuses.performance_status || defaultString,
  };
};

export const getOrderHistoryTotals = (...plans: OrderHistorySamplePlanType[]) => {
  const totals = {
    total: 0,
    collection: 0,
    commission: 0,
  };

  plans.forEach((plan) => {
    totals.total += sum(Object.values(plan.price_summary).map((price) => price.total));
    totals.collection += sum(Object.values(plan.price_summary).map((price) => price.collection));
    totals.commission += sum(Object.values(plan.price_summary).map((price) => price.commission));
  });

  return totals;
};

export default retrieveProgressStatuses;
