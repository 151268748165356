import { useEffect, useRef, useState } from 'react';
import { Map, Style as MapStyle } from 'mapbox-gl';

import useMapboxGl from 'common/MapHooks';
import { DrawMode, ViewPortProps } from 'common/Maps/types';

type Settings = {
  initialViewport?: ViewPortProps;
  drawRef?: React.MutableRefObject<any>;
  mapStyle?: MapStyle | string | undefined;
  showGeocoderSearch?: boolean;
  showNavigationControls?: boolean;
  setDrawMode?: (mode: DrawMode) => void;
  onLoad?: (map: Map) => void;
};

const DEFAULT_VIEWPORT: ViewPortProps = {
  latitude: 0,
  longitude: 0,
  zoom: 2,
};

/**
 * A hook for the hook, this at least saves you the trouble of repetitive tasks like setting the two
 * main refs, wiring up state for the viewport/loading, and waiting.
 */
export const useReactMapbox = (settings?: Settings) => {
  const {
    drawRef,
    initialViewport = DEFAULT_VIEWPORT,
    setDrawMode,
    showGeocoderSearch,
    showNavigationControls,
    mapStyle,
    onLoad,
  } = settings || {};

  const mapRef = useRef<Map | null>(null);
  const mapContainerRef = useRef(null);
  const [viewport, setViewport] = useState<ViewPortProps>(initialViewport);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);

  useMapboxGl(
    mapContainerRef,
    mapRef,
    drawRef,
    viewport,
    setViewport,
    setDrawMode,
    showNavigationControls,
    showGeocoderSearch,
    mapStyle,
  );

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      map.on('load', () => setMapHasLoaded(true));
    }
  }, [mapRef]);

  useEffect(() => {
    const map = mapRef.current;

    if (map && mapHasLoaded && onLoad) {
      onLoad(map);
    }
  }, [mapRef, mapHasLoaded]);

  return { mapRef, mapContainerRef, viewport, setViewport, mapHasLoaded, setMapHasLoaded };
};

export default useReactMapbox;
