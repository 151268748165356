import { FaTrash } from 'react-icons/fa';
import { IoMdMove } from 'react-icons/io';
import { Button, Group, List, Stack, Title } from '@mantine/core';

import { UI_COLORS } from 'constants/colors';

import { formatToDateHourFormat } from 'util/date';
import { CarbonSampleProperties } from 'store/projects/types';

import { useProjectsTranslations } from '../hooks';

type Props = {
  properties: CarbonSampleProperties;
  onDeleteClick: VoidFunction;
  onMoveSampleClick: VoidFunction;
};

const SamplePointPopupContent = ({ properties, onDeleteClick, onMoveSampleClick }: Props) => {
  const translations = useProjectsTranslations();

  return (
    <Stack gap="lg">
      <Title order={3}>
        {translations.sample} {properties.id}
      </Title>
      <List withPadding>
        <List.Item>
          <b>{translations.created}:</b> {formatToDateHourFormat(properties.created_at)}
        </List.Item>
        <List.Item>
          <b>{translations.featureType}:</b> {properties.feature_type}
        </List.Item>
        <List.Item>
          <b>{translations.measurementTypes}:</b> {properties.measurement_types || 'N/A'}
        </List.Item>
      </List>
      <Group justify="center" gap="sm">
        <Button color={UI_COLORS.error} onClick={onDeleteClick} leftSection={<FaTrash />}>
          {translations.deleteSample}
        </Button>
        <Button color={UI_COLORS.error} onClick={onMoveSampleClick} leftSection={<IoMdMove />}>
          {translations.moveSample}
        </Button>
      </Group>
    </Stack>
  );
};

export default SamplePointPopupContent;
