import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { CreateFarmAccountRequestType, RegisterUserForOperationType } from 'store/user/types';

import {
  GenerateTotalFarmPriceEstimateType,
  OperationsSummaryResponse,
  OperationType,
  ResultPreferencesType,
  TotalFarmPriceEstimateResponse,
} from './types';

export const requestSingleOperation = (id: number): Promise<OperationType> =>
  requestGet(`${SERVICE_URL}/operation_summary/${id}`).then((response) => response.json());

const postEntity = (urlSuffix: string) => (body: any) =>
  requestPost(`${SERVICE_URL}/${urlSuffix}/`, {
    body,
  }).then(handleJsonResponse);

const putEntity = (urlSuffix: string) => (body: any) =>
  requestPut(`${SERVICE_URL}/${urlSuffix}/${body.id}`, {
    body,
  }).then(handleJsonResponse);

export const postOperation = postEntity('operation');

export const putOperation = putEntity('operation');

export const postField = postEntity('field');

export const putField = (body: any, id: number) =>
  requestPut(`${SERVICE_URL}/field/${id}`, {
    body,
  }).then(handleJsonResponse);

export const putUserOperation = (body: any) =>
  requestPut(`${SERVICE_URL}/users_operations/${body.id}`, {
    body,
  }).then(handleJsonResponse);

export const deleteUserOperation = (body: any) =>
  requestDelete(`${SERVICE_URL}/users_operations/${body.operationId}`, {
    body: { users_emails: [body.email] },
  }).then(handleJsonResponse);

export const requestOperationsPage = async (settings: {
  all: boolean;
  page: number;
  name?: string;
  users?: number[];
  agency?: { agency_id: number; agency_primary_id: number } | null;
  sortby?: string;
  desc?: boolean;
  include_agencies?: boolean;
}): Promise<OperationsSummaryResponse> => {
  const { name, users, agency, sortby, desc } = settings;

  return handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/operations_summary_paginated/?${queryStringify({
        ...settings,
        name: name?.length ? name : undefined,
        users: users?.length ? users.toString() : undefined,
        agency_id: agency ? agency.agency_id : undefined,
        agency_primary_id: agency ? agency.agency_primary_id : undefined,
        sortby: sortby || undefined,
        desc: desc || undefined,
      })}`,
    ),
  );
};

export const requestOperationsTotalStats = async (
  all: boolean,
  name?: string,
  users?: number[],
  agency?: { agency_id: number; agency_primary_id: number } | null,
) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/operations_summary_total_stats/?${queryStringify({
        all,
        name: name?.length ? name : undefined,
        users: users?.length ? users.toString() : undefined,
        agency_id: agency ? agency.agency_id : undefined,
        agency_primary_id: agency ? agency.agency_primary_id : undefined,
      })}`,
    ),
  );

export const startGenerateResultsByFormat = async (
  id: number,
  crop_year: number,
  format: string,
  body: {
    pages?: null | any[];
    language?: string;
    overviewOnly?: boolean;
    host?: string;
    fieldIds?: number[];
    field_ids?: number[];
    density?: string;
  },
) =>
  handleJsonResponse(
    await requestPost(
      `${SERVICE_URL}/generate_results_download/${id}/${crop_year}?format=${format}`,
      {
        body,
      },
    ),
  );

export const createMapbook = async (
  operation_id: number,
  body: {
    crop_year: number;
    pages: null | any[];
    overview_only: boolean;
    field_ids: number[];
    crops: string[];
  },
) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/mapbook_results/${operation_id}`, {
      body,
    }),
  );

export const requestResultsDownloadLink = async (operationId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/results_download_link/${operationId}`));

export const requestDeleteResultsDownloadLink = async (operationId: number, linkId: number) =>
  handleJsonResponse(
    await requestDelete(`${SERVICE_URL}/results_download_link/${operationId}`, {
      body: { results_download_id: linkId },
    }),
  );

export const requestMarkAsDelivered = (id: number) =>
  requestPost(`${SERVICE_URL}/mark_operation_delivered/${id}`).then(handleJsonResponse);

export const requestOperationDiscount = async (id: number, max_acres: number, name: string) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/operation_discount/${id}`, {
      body: { max_acres, name },
    }),
  );

export const requestCreateFarmAccount = async (body: CreateFarmAccountRequestType) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/operation/`, { body }));

export const requestAddBillingResponsibility = (operationId: number, userId: number) => {
  return requestPut(`${SERVICE_URL}/operation_billing_responsibility/${operationId}`, {
    body: {
      user_id: userId,
    },
  }).then(handleJsonResponse);
};

export const requestDeleteBillingResponsibility = (operationId: number) => {
  return requestDelete(`${SERVICE_URL}/operation_billing_responsibility/${operationId}`).then(
    handleJsonResponse,
  );
};

export const requestPutOperation = async (
  id: number,
  body: {
    commissions_user_id?: number | null;
    locked_in_acres?: number;
    default_bill_to_agency_id?: number | null;
  },
) => handleJsonResponse(await requestPut(`${SERVICE_URL}/operation/${id}`, { body }));

export const requestRegisterForOperation = (
  operationId: number,
  body: RegisterUserForOperationType,
) => {
  return requestPost(`${SERVICE_URL}/register_for_operation/${operationId}`, {
    body,
  }).then(handleJsonResponse);
};

export const requestJDConnect = async (body: { operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/jd_connect/`, {
      body,
    }),
  );

export const postJDCallback = async (body: { code: string; operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/jd_callback/`, {
      body,
    }),
  );

export const requestFVConnect = async (body: { operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/fv_connect/`, {
      body,
    }),
  );

export const postFVCallback = async (body: { code: string; operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/fv_callback/`, {
      body,
    }),
  );

export const requestCNHIConnect = async (body: { operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/cnhi_connect/`, {
      body,
    }),
  );

export const postCNHICallback = async (body: { response_url: string; operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/cnhi_callback/`, {
      body,
    }),
  );

export const requestAgLeaderConnect = async (body: { operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/agleader_connect/`, {
      body,
    }),
  );

export const postAgLeaderCallback = async (body: { code: string; operation_id?: number }) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/agleader_callback/`, {
      body,
    }),
  );

export const requestConnectedAccountsInfo = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/connected_accounts/${id}`));

export const requestPutResultPreferences = async (
  operationId: number,
  body: Partial<ResultPreferencesType>,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/operation_preferences/${operationId}`, {
      body,
    }),
  );

export const requestDeleteOperationAgencyConnection = async (
  agencyId: number,
  operationId: number,
) =>
  handleJsonResponse(
    await requestDelete(`${SERVICE_URL}/agency_operation/${agencyId}/${operationId}`),
  );

export const requestPostOperationAgencyConnection = async (agencyId: number, operationId: number) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/agency_operation/${agencyId}/${operationId}`),
  );

export const requestPostPuppeteer = async (operationId: number) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/puppet_results/`, {
      body: {
        operation_ids: [operationId],
      },
    }),
  );

export const generateTotalFarmPriceEstimate = async (
  payload: GenerateTotalFarmPriceEstimateType,
): Promise<TotalFarmPriceEstimateResponse> =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/total_farm_price_estimate/${payload.operation_id}`, {
      body: payload,
    }),
  );

export const requestPostTotalFarm = async (payload: TotalFarmPriceEstimateResponse) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/total_farm/`, {
      body: payload,
    }),
  );

export const requestDeleteTotalFarm = async (totalFarmId: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/total_farm/${totalFarmId}`));
