import {
  RECEIVE_DEFAULT_RECOMMENDATIONS,
  RECEIVE_RECOMMENDATIONS,
  RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS,
  RECOMMENDATIONS_REQUEST_ERROR,
  RecommendationsActionsType,
  RecommendationsStateType,
  REQUEST_RECOMMENDATIONS,
} from './types';

const initialState: RecommendationsStateType = {
  recommendations: [],
  byPlanId: {},
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const RecommendationsReducer = (
  state = initialState,
  action: RecommendationsActionsType,
): RecommendationsStateType => {
  switch (action.type) {
    case REQUEST_RECOMMENDATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: action.payload.recommendations,
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
      };
    case RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS:
      return {
        ...state,
        byPlanId: {
          ...state.byPlanId,
          [action.payload.planId]: action.payload.recommendations,
        },
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
      };
    case RECEIVE_DEFAULT_RECOMMENDATIONS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
      };
    case RECOMMENDATIONS_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
