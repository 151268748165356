import {
  LabAddressType,
  RECEIVE_SHIPPING_ADDRESSES,
  RECEIVE_SHIPPING_BATCHES,
  REQUEST_SHIPPING_ADDRESSES,
  REQUEST_SHIPPING_BATCHES,
  SHIPPING_REQUEST_ERROR,
  ShippingActionType,
  ShippingAddress,
  ShippingLabelBatches,
} from './types';

export const shippingRequestError = (groupId?: number): ShippingActionType => {
  return {
    type: SHIPPING_REQUEST_ERROR,
    payload: {
      groupId,
    },
  };
};

export const requestShippingAddresses = (): ShippingActionType => {
  return {
    type: REQUEST_SHIPPING_ADDRESSES,
  };
};

export const receiveAllShippingAddresses = (shippingInfo: {
  lab_addresses: LabAddressType[];
  shipping_addresses: ShippingAddress[];
}): ShippingActionType => ({
  type: RECEIVE_SHIPPING_ADDRESSES,
  payload: shippingInfo,
});

export const requestShippingBatches = (): ShippingActionType => {
  return {
    type: REQUEST_SHIPPING_BATCHES,
  };
};

export const receiveShippingBatches = (labelBatches: ShippingLabelBatches): ShippingActionType => ({
  type: RECEIVE_SHIPPING_BATCHES,
  payload: labelBatches,
});
