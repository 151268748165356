import { LabSampleType } from 'store/labSamples/types';

import { getAnalysisShortName, getRequiredAnalysisForSample } from './analysis';

export const printSampleBarcode = (sample: LabSampleType, qrCodeUrl: string, language: string) => {
  const analysisList = getRequiredAnalysisForSample(sample, true, false, true).map((analysis) =>
    getAnalysisShortName(analysis, language),
  );
  const projectId = sample.rnd_meta?.project_id?.substring(0, 4);
  const collectionId = sample.rnd_meta?.collection_id?.substring(0, 4);
  const sampleId = sample.rnd_meta?.sample_id?.substring(0, 4);
  const bagLabel = sample.rnd_meta?.bag_label;

  const analysisBubbleGap = '2';

  return `
        <html>
            <head>
                <title>Print QR Code</title>
                <style>
                    @media print {
                        @page {
                            size: 2in 1in;
                            margin: 0;
                        }
                    }
                    body {
                        margin: 0;
                        padding: 1mm;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 2in;
                        height: 1in;
                        box-sizing: border-box;
                    }
                    .Wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1.5mm;
                        width: 100%;
                        height: 100%;
                    }
                    .Qr {
                        max-width: 15mm;
                        max-height: 15mm;
                        padding: 2mm 0 2mm 2mm;
                    }
                    .InfoContainer {
                        padding: 0 0 1mm 0;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        gap: 0;
                        box-sizing: border-box;
                    }
                    .Info {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 0.5mm;
                    }
                    .AnalysisRow {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: ${analysisBubbleGap}px;
                        row-gap: 0.5mm;
                        min-height: 7.4mm;
                        align-content: flex-end;
                    }
                    .AnalysisBubble {
                        -webkit-print-color-adjust: exact;
                        font-size: 2mm;
                        font-weight: 700;
                        font-family: sans-serif;
                        padding: 0.5mm 1mm;
                        white-space: nowrap;
                        color: white;
                        border-radius: 0.8mm;
                        background-color: black;
                    }
                    .Ellipsis {
                        padding: 0;
                        height: 1mm;
                        font-size: 3mm;
                        color: black;
                        background-color: white;
                    }
                    .CarbonContainer {
                        display: flex;
                        flex-direction: column;
                        gap: 0.25mm;
                        font-size: 2.3mm;
                        font-family: sans-serif;
                    }
                    .CarbonRow {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 1mm;
                        row-gap: 0.25mm;
                    }
                    .Carbon {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        gap: 0.2mm;
                    }
                    .CarbonValue {
                        font-weight: 700;
                    }
                    .BarcodeContainer {
                        min-height: 6mm;
                        font-size: 0;
                        font-family: sans-serif;
                    }
                    .Barcode {
                        font-size: 2mm;
                        font-weight: 500;
                    }
                    .BarcodeBold {
                        font-size: 3mm;
                        font-weight: 900;
                    }
                </style>
            </head>
            <body>
                <div class="Wrapper">
                    <div>
                        <img src="${qrCodeUrl}" alt="QR Code" class="Qr" />
                    </div>
                    <div class="InfoContainer">
                        <div class="Info">
                            <div class="AnalysisRow">
                                ${analysisList
                                  .map(
                                    (analysis) => `<span class="AnalysisBubble">${analysis}</span>`,
                                  )
                                  .join('')}
                            </div>
                            <div class="CarbonContainer">
                                <div class="CarbonRow">
                                    ${
                                      projectId
                                        ? `<div class="Carbon">
                                            <span>P:</span>
                                            <span class="CarbonValue">${projectId}</span>
                                        </div>`
                                        : ''
                                    }
                                    ${
                                      collectionId
                                        ? `<div class="Carbon">
                                            <span>C:</span>
                                            <span class="CarbonValue">${collectionId}</span>
                                        </div>`
                                        : ''
                                    }
                                    ${
                                      sampleId
                                        ? `<div class="Carbon">
                                            <span>S:</span>
                                            <span class="CarbonValue">${sampleId}</span>
                                        </div>`
                                        : ''
                                    }
                                </div>
                                <div>
                                    ${bagLabel ? `<span class="CarbonValue">${bagLabel}</span>` : ''}
                                </div>
                            </div>
                        </div>
                        <div class="BarcodeContainer">
                            <span class="BarcodeBold">${sample.barcode?.substring(0, 8)}</span>
                            <span class="Barcode">${sample.barcode?.substring(8)}</span>
                        </div>
                    </div>
                </div>
                <script>
                    function adjustAnalysisRows() {
                        const container = document.querySelector(".AnalysisRow");
                        const analysisList = Array.from(container.children);
                        const maxWidth = container.clientWidth;
                        const maxRows = 2;

                        const { rowCount, rowWidth, lastAnalysisIndex } = analysisList.reduce(
                            ({ rowCount, rowWidth, lastAnalysisIndex }, analysis, index) => {
                                if (rowCount > maxRows) {
                                    return { rowCount, rowWidth, lastAnalysisIndex }
                                }
                                const width = analysis.getBoundingClientRect().width;
                                if (rowWidth + width > maxWidth) {
                                    const newRowCount = rowCount + 1
                                    return {
                                        rowCount: newRowCount,
                                        rowWidth: newRowCount > maxRows ? rowWidth : width,
                                        lastAnalysisIndex: newRowCount === maxRows ? index : lastAnalysisIndex,
                                    }
                                }
                                return {
                                    rowCount,
                                    rowWidth: rowWidth + width + ${analysisBubbleGap},
                                    lastAnalysisIndex: rowCount === maxRows ? index : lastAnalysisIndex,
                                }
                            },
                            { rowCount: 1, rowWidth: 0, lastAnalysisIndex: -1 }
                        );

                        if (rowCount > maxRows) {
                            const ellipsis = document.createElement("span");
                            ellipsis.textContent = "...";
                            ellipsis.classList.add("AnalysisBubble", "Ellipsis");
                            container.appendChild(ellipsis);
                            const ellipsisWidth = ellipsis.getBoundingClientRect().width;

                            const sliceIndex = rowWidth + ellipsisWidth + ${analysisBubbleGap} > maxWidth ? lastAnalysisIndex : lastAnalysisIndex + 1
                            if (sliceIndex !== -1) {
                                analysisList.slice(sliceIndex).forEach(b => b.remove());
                            }
                        }
                    }
                    window.onload = function() {
                        adjustAnalysisRows();
                        window.print();
                        window.onafterprint = function() {
                            window.close();
                        }
                    }
                </script>
            </body>
        </html>
    `;
};
