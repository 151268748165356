import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { formatFloatString } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { updatePlate } from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { Button, Container, Header, Input, Spinner } from 'common';

import styles from './Inputs.module.css';

type QPCRCyclesPropsType = {
  plate: PlateType;
};

export const QPCRCycles = ({ plate }: QPCRCyclesPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [qpcrCycles, setQpcrCycles] = useState(String(plate.meta_data?.qpcr_cycles || ''));

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const resetValues = () => {
    setQpcrCycles(String(plate.meta_data?.qpcr_cycles || ''));
    setIsEditing(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    try {
      setIsEditing(false);
      setIsSubmitting(true);
      await dispatch(updatePlate(plate.barcode, { qpcr_cycles: parseFloat(qpcrCycles) }));
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const cyclesDisplay = plate.meta_data?.qpcr_cycles || getString('noData', language);

  const cyclesInput = (
    <Input
      ref={inputRef}
      className={styles.Input}
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setQpcrCycles(formatFloatString(event.target.value))
      }
      value={String(qpcrCycles)}
    />
  );

  const startEditButton = (
    <div className={styles.SubmitButton}>
      {isSubmitting && <Spinner className={styles.SmallSpinner} />}
      <Button className={styles.Button} onClick={() => setIsEditing(true)} disabled={isSubmitting}>
        {getString(isSubmitting ? 'submitting' : 'editQpcrCycles', language)}
      </Button>
    </div>
  );

  const finishEditButtons = (
    <div className={styles.SubmitButton}>
      <Button className={styles.Button} onClick={handleSubmit} disabled={!qpcrCycles.length}>
        {getString('submit', language)}
      </Button>
      <Button className={styles.Button} danger onClick={resetValues}>
        {getString('cancel', language)}
      </Button>
    </div>
  );

  return (
    <Container vertical>
      <Header
        title={
          <div className={styles.Header}>
            <span>{`${getString('qpcrCycles', language)}: `}</span>
            <span className={styles.Cycles}>{isEditing ? cyclesInput : cyclesDisplay}</span>
          </div>
        }
        underline="none"
        size="H2"
      >
        {isEditing ? finishEditButtons : startEditButton}
      </Header>
    </Container>
  );
};
