import { useState } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/auth';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { setLocalStorage } from 'util/localStorage';
import { getString } from 'strings/translation';
import { requestLogin, requestPasswordChange } from 'requesters/authenticationRequester';
import { Button, Header, Input, Label, Spinner } from 'common';

import styles from './PasswordChange.module.css';

const PasswordChange = ({ email }) => {
  const language = useBroswerLanguage();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPasswordFormVisible, setIsPasswordFormVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [requestInProgress, setRequestInProgress] = useState(false);

  const resetForm = () => {
    setIsPasswordFormVisible(false);
    setOldPassword('');
    setNewPassword('');
    setNewPasswordConfirmation('');
    setRequestInProgress(false);
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setRequestInProgress(false);
  };

  const savePassword = () =>
    requestPasswordChange({ email, password: newPassword })
      .then(resetForm)
      .catch(() => handleError(getString('errorPasswordChange', language)));

  const submitNewPassword = () =>
    requestLogin({ email, password: oldPassword })
      .then((response) => {
        const { access_token: accessToken, refresh_token: refreshToken } = response;
        setLocalStorage(ACCESS_TOKEN, accessToken);
        setLocalStorage(REFRESH_TOKEN, refreshToken);
        return savePassword();
      })
      .catch(() => handleError(getString('incorrectPassword', language)));

  const validateForm = () => {
    if (!oldPassword.length) {
      return getString('oldPassMissing', language);
    }

    if (!newPassword.length) {
      return getString('newPassMissing', language);
    }

    if (!newPasswordConfirmation.length) {
      return getString('newPassConfMissing', language);
    }
  };

  const submit = () => {
    setRequestInProgress(true);

    if (validateForm()) {
      return handleError(validateForm());
    }

    submitNewPassword();
  };

  const getFormErrors = () => {
    if (oldPassword.length && oldPassword === newPassword) {
      return getString('existingPassNoReuse', language);
    }

    if (
      newPassword.length &&
      !(newPassword.match(/[a-z]/) && newPassword.match(/[A-Z]/) && newPassword.match(/[0-9]/))
    ) {
      return getString('errorNewPassRules', language);
    }

    if (newPassword !== newPasswordConfirmation) {
      return getString('errorNewPassConf', language);
    }

    return null;
  };

  const getPrimaryButtonText = () => {
    if (requestInProgress) {
      return <Spinner className={styles.Spinner} />;
    }

    return isPasswordFormVisible
      ? getString('update', language)
      : getString('changePassword', language);
  };

  const onInputChange = (setter) => (event) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setter(event.target.value);
  };

  const formError = getFormErrors();

  const newPasswordClassname = classNames({
    [styles.InputError]: formError,
  });

  return (
    <div className={styles.Wrapper}>
      <Header title={getString('password', language)} size="H2" underline="thin">
        {isPasswordFormVisible ? (
          <Button onClick={() => setIsPasswordFormVisible()}>
            {getString('cancel', language)}
          </Button>
        ) : null}
        <Button
          primary
          disabled={requestInProgress || formError || errorMessage}
          onClick={() => (isPasswordFormVisible ? submit() : setIsPasswordFormVisible(true))}
        >
          {getPrimaryButtonText()}
        </Button>
      </Header>
      {isPasswordFormVisible && (
        <form className={styles.Form}>
          <span className={styles.ErrorText}>{formError || errorMessage}</span>
          <Input
            autoComplete="username email"
            className={styles.Hidden}
            name="email"
            readOnly
            type="text"
            value={email}
          />
          <Label className={styles.Label} label={getString('oldPassword', language)}>
            <Input
              autoComplete="current-password"
              onChange={onInputChange(setOldPassword)}
              type="password"
              value={oldPassword}
            />
          </Label>
          <Label className={styles.Label} label={getString('newPassword', language)}>
            <Input
              autoComplete="new-password"
              onChange={onInputChange(setNewPassword)}
              type="password"
              value={newPassword}
            />
          </Label>
          <span className={styles.Note}>{getString('passwordRules', language)}</span>
          <Label className={styles.Label} label={getString('newPassConf', language)}>
            <Input
              autoComplete="new-password"
              className={newPasswordClassname}
              onChange={onInputChange(setNewPasswordConfirmation)}
              type="password"
              value={newPasswordConfirmation}
            />
          </Label>
        </form>
      )}
    </div>
  );
};

PasswordChange.propTypes = {
  email: string.isRequired,
};

export default PasswordChange;
