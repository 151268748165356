import React, { ChangeEventHandler, FunctionComponent } from 'react';
import classNames from 'classnames';

import styles from './Toggle.module.css';

type ToggleProps = {
  className?: string;
  dataTestId?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler;
};

export const Toggle: FunctionComponent<ToggleProps> = React.forwardRef<
  HTMLInputElement,
  ToggleProps
>(({ className, dataTestId, ...props }, ref) => {
  className = classNames(styles.Toggle, className);

  return (
    <label className={className} data-test-id={dataTestId}>
      <input ref={ref} type="checkbox" {...props} />
      <span className={styles.Slider} />
    </label>
  );
});

Toggle.displayName = 'Toggle';
