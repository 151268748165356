import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Image, Paper, Stack, Text } from '@mantine/core';
import turfBbox from '@turf/bbox';
import booleanIntersects from '@turf/boolean-intersects';
import {
  Feature,
  feature,
  FeatureCollection,
  featureCollection,
  Point,
  Polygon,
} from '@turf/helpers';
import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import MAPBOX_CONSTANTS, { MapMarkers, MARKERS, MARKERS_SELECTED, MODES } from 'constants/mapbox';

import useBroswerLanguage from 'util/hooks/useLanguage';
import removeMapLayer from 'util/mapbox';
import { getMapPinColor, getPlanTrackingLegendIcons } from 'util/mapImageryColors';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { getOperation, getOperationUsers } from 'store/operation/thunks';
import { SamplePlanTrackingType } from 'store/samplePlans/types';
import useMapboxGl from 'common/MapHooks';
import { ViewPortProps } from 'common/Maps/types';

import AssignSampler from '../Orders/FieldList/FullProductReport/OrderButtons/AssignSampler';

import { BulkAssignSampler } from './BulkAssign';
import createTrackingMapPopup from './TrackingMapPopup';

import styles from './TrackingMap.module.css';

const DEFAULT = 'default';
const BULK_ASSIGN = 'bulk_assign';

interface TrackingMapProps {
  mapContainerRef: { current: HTMLDivElement | null };
  mapRef: { current: mapboxgl.Map | null };
  drawRef: { current: any | null };
  setMapSearchBounds: (bounds: number[][] | null) => void;
  currentMapBounds: number[][] | null;
  toggleInitSearch: (val: boolean) => void;
}

const TrackingMap = ({
  mapContainerRef,
  mapRef,
  drawRef,
  setMapSearchBounds,
  currentMapBounds,
  toggleInitSearch,
}: TrackingMapProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const map = mapRef.current;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [centerLongitude, centerLatitude] = [-97, 37];
  const [tempBounds, setTempBounds] = useState<number[][] | null>(null);
  const [clickMode, setClickMode] = useState<typeof DEFAULT | typeof BULK_ASSIGN>(DEFAULT);
  const [isDrawing, toggleIsDrawing] = useState<boolean>(false);
  const { samplePlans, fieldGeometries, operations } = useSelector((state: RootState) => ({
    samplePlans: state.samplePlans.summary.items,
    fieldGeometries: state.fieldGeometry.geometries,
    operations: state.operations.operationsById,
  }));
  const samplePlanArr = useMemo(
    () => Object.values(samplePlans).flatMap((samplePlan) => samplePlan),
    [samplePlans],
  );

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [bulkAssignSamplerModal, toggleBulkAssignSamplerModal] = useState(false);
  const [assignSamplerModal, toggleAssignSamplerModal] = useState(false);
  const [assignSamplerPlan, setAssignSamplerPlan] = useState<SamplePlanTrackingType | null>(null);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 4,
    width: 0,
    height: 0,
  });
  const [planIds, setPlanIds] = useState(new Set());

  useMapboxGl(
    mapContainerRef,
    mapRef,
    drawRef,
    viewport,
    setViewport,
    () => {},
    true,
    false,
    MAPBOX_CONSTANTS.styleOutdoors,
  );

  const openAssignSampler = async (plan: SamplePlanTrackingType) => {
    toggleAssignSamplerModal(true);
    setAssignSamplerPlan(plan);
    if (!fieldGeometries[plan.field_id]) {
      dispatch(getFieldGeometry(plan.field_id));
    }
    if (!operations?.[plan.operation_id]) {
      dispatch(getOperationUsers(plan.operation_id));
      dispatch(getOperation(plan.operation_id));
    }
  };

  useEffect(() => {
    if (map) {
      map.on('load', () => {
        Object.values(MapMarkers).forEach((marker, index) => {
          map?.loadImage(marker.image, (error, image) => {
            if (error) {
              throw error;
            }
            // @ts-ignore
            map?.addImage(marker.name, image);
            if (index === Object.values(MapMarkers).length - 1) {
              setIsMapLoaded(true);
            }
          });
        });
      });
    }
  }, [map]);

  useEffect(() => {
    const planArrSet = new Set(samplePlanArr.map((samplePlan) => samplePlan.id));
    if (planIds !== planArrSet && isMapLoaded && map?.getSource(`markers`)) {
      map?.removeLayer(`markers`);
      map?.removeSource(`markers`);
    }
    setPlanIds(planArrSet);
  }, [samplePlanArr, isMapLoaded, mapRef]);

  useEffect(() => {
    if (isMapLoaded && map) {
      map.on('moveend', () => {
        const canvas = map?.getCanvas();
        const w = canvas?.width;
        const h = canvas?.height;
        if (w && h) {
          const upperLeft = map?.unproject([0, 0]).toArray();
          const upperRight = map?.unproject([w, 0]).toArray();
          const bottomRight = map?.unproject([w, h]).toArray();
          const bottomLeft = map?.unproject([0, h]).toArray();
          if (upperLeft && upperRight && bottomRight && bottomLeft) {
            setTempBounds([upperLeft, upperRight, bottomRight, bottomLeft, upperLeft]);
          }
        }
      });
    }
  }, [isMapLoaded, map]);

  const getSelectedPlans = () => {
    try {
      // dangling _ otherwise, just catch it and return []
      const { _data } = map?.getSource(MARKERS_SELECTED) as any;
      return (_data as FeatureCollection).features.reduce(
        (list, feat) =>
          feat.properties?.samplePlan ? [...list, feat.properties?.samplePlan] : list,
        [] as SamplePlanTrackingType[],
      );
    } catch (err) {
      return [];
    }
  };

  const setupSelectedMarkersLayer = (markers: FeatureCollection) => {
    const source = {
      type: 'geojson',
      data: markers,
    } as GeoJSONSourceRaw;
    map?.addLayer({
      id: MARKERS_SELECTED,
      type: 'symbol',
      source,
      layout: {
        'icon-image': ['get', 'pinColor'],
        'icon-allow-overlap': true,
      },
    });
  };

  const onBulkAssignClick = useCallback(
    (e: any) => {
      const plan = JSON.parse(e.features[0].properties.samplePlan);

      if (plan.id) {
        const newPlanFeat = feature(plan.field_centroid, {
          samplePlan: plan,
          pinColor: MapMarkers.RED_MARKER.name,
        });

        if (map?.getLayer(MARKERS_SELECTED)) {
          const { _data } = map?.getSource(MARKERS_SELECTED) as any;
          const alreadyHighlighted = (_data as FeatureCollection).features.some(
            (feat) => feat?.properties?.samplePlan?.id === plan.id,
          );
          const zoneAddedOrRemoved = alreadyHighlighted
            ? (_data as FeatureCollection).features.filter(
                (feat) => feat.properties?.samplePlan?.id !== plan.id,
              )
            : [_data.features, newPlanFeat].flat();
          const highlightLayer = featureCollection(zoneAddedOrRemoved as Feature<Point>[]);
          (map?.getSource(MARKERS_SELECTED) as GeoJSONSource).setData(highlightLayer);
        } else {
          setupSelectedMarkersLayer(featureCollection([newPlanFeat]));
        }
      }
    },
    [map],
  );

  const onCreatePolygon = useCallback(
    (e: FeatureCollection<Polygon>) => {
      try {
        const feat = e.features[0];
        const { _data } = map?.getSource(MARKERS) as any;
        const insideRectangle = (_data as FeatureCollection).features.reduce(
          (featList, planFeat) => {
            if (booleanIntersects(feat, planFeat)) {
              const updatedFeat = {
                ...planFeat,
                properties: {
                  ...planFeat.properties,
                  pinColor: MapMarkers.RED_MARKER.name,
                },
              } as Feature<Point>;
              return featList.concat([updatedFeat]);
            }
            return featList;
          },
          [] as Feature<Point>[],
        );
        const highlightLayer = featureCollection(insideRectangle as Feature<Point>[]);
        if (map?.getLayer(MARKERS_SELECTED)) {
          (map?.getSource(MARKERS_SELECTED) as GeoJSONSource).setData(highlightLayer);
        } else {
          setupSelectedMarkersLayer(highlightLayer);
        }
        drawRef.current?.deleteAll();
        handleToggleDraw(false);
      } catch (err) {
        showToast(err.message);
      }
    },
    [map, drawRef],
  );

  const handleToggleDraw = (enable: boolean) => {
    if (map) {
      if (enable) {
        drawRef.current?.changeMode(MODES.DRAW_RECTANGLE);
        map.on('draw.create', onCreatePolygon);
        map.getCanvas().style.cursor = 'crosshair';
      } else {
        map.off('draw.create', onCreatePolygon);
        drawRef.current?.changeMode(MODES.STATIC);
        drawRef.current?.deleteAll();
        map.getCanvas().style.cursor = '';
      }
    }
    toggleIsDrawing(enable);
  };

  const handleSetClickBehavior = (mode: typeof DEFAULT | typeof BULK_ASSIGN) => {
    if (map) {
      if (mode === BULK_ASSIGN) {
        map.on('click', MARKERS, onBulkAssignClick);
      } else {
        map.off('click', MARKERS, onBulkAssignClick);
        removeMapLayer(mapRef, MARKERS_SELECTED);
        drawRef.current?.deleteAll();
      }
      setClickMode(mode);
    }
  };

  useEffect(() => {
    if (isMapLoaded && samplePlanArr.length && map && !map?.getSource(`markers`)) {
      const popups = document.querySelectorAll('.mapboxgl-popup');
      popups.forEach((popup) => popup.remove());
      const source = {
        type: 'geojson',
        data: featureCollection(
          samplePlanArr.map((samplePlan) =>
            feature(samplePlan.field_centroid, {
              samplePlan,
              pinColor: getMapPinColor(samplePlan),
            }),
          ),
        ),
      } as GeoJSONSourceRaw;
      map?.addLayer({
        id: MARKERS,
        type: 'symbol',
        source: source,
        layout: {
          'icon-image': ['get', 'pinColor'],
          'icon-allow-overlap': true,
        },
      });
      const bbox = turfBbox(source.data) as LngLatBoundsLike;
      map.fitBounds(bbox, {
        padding: 50,
        maxZoom: 10,
      });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom: zoom > 13 ? zoom : 10,
      }));
      map.on('click', MARKERS, (e: any) => {
        const plan = JSON.parse(e.features[0].properties.samplePlan);
        const pop = createTrackingMapPopup({ plan, openAssignSampler, language });

        const placeholder = document.createElement('div');
        const root = createRoot(placeholder);
        root.render(pop);
        if (map instanceof mapboxgl.Map) {
          new mapboxgl.Popup({
            closeOnClick: true,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(placeholder)
            .addTo(map as mapboxgl.Map);
        }
      });
      handleSetClickBehavior(DEFAULT);

      map.on('mouseenter', MARKERS, () => {
        if (map && drawRef.current.getMode() === MODES.SELECT) {
          map.getCanvas().style.cursor = 'pointer';
        }
      });

      map.on('mouseleave', MARKERS, () => {
        if (map && drawRef.current.getMode() === MODES.SELECT) {
          map.getCanvas().style.cursor = '';
        }
      });
    }
  }, [samplePlanArr, map, isMapLoaded]);

  return (
    <div className={styles.Wrapper}>
      {assignSamplerModal && assignSamplerPlan && operations?.[assignSamplerPlan.operation_id] && (
        <AssignSampler
          samplePlan={assignSamplerPlan}
          fieldId={assignSamplerPlan.field_id}
          onClose={() => toggleAssignSamplerModal(false)}
          onSubmit={() => toggleInitSearch(true)}
          getFieldsWithUpdates={() => {}}
          billing_user_id={assignSamplerPlan.billing_user_id}
          opened={assignSamplerModal}
          operationId={assignSamplerPlan.operation_id}
        />
      )}
      {bulkAssignSamplerModal && (
        <BulkAssignSampler
          samplePlans={getSelectedPlans()}
          onClose={() => toggleBulkAssignSamplerModal(false)}
          open
          onSubmit={() => {
            handleSetClickBehavior(DEFAULT);
            toggleInitSearch(true);
          }}
        />
      )}
      <div className={styles.MapWrapper} ref={wrapperRef}>
        <div ref={mapContainerRef} className={styles.MapWrapper} />
        <Paper className={styles.MapLegendTopLeft} p="xs">
          <Stack gap={0}>
            <Text size="sm" w={FONT_WEIGHT_BOLD} td="underline">
              {getString('legend', language)}
            </Text>
            {getPlanTrackingLegendIcons(language).map((legendVal) => (
              <Group justify="space-between" key={legendVal.name}>
                <Text size="xs">{legendVal.name}</Text>
                <Image w="1.5rem" src={legendVal.icon} />
              </Group>
            ))}
          </Stack>
        </Paper>
        <div className={styles.MapButtons}>
          <Button variant="white" onClick={() => tempBounds && setMapSearchBounds(tempBounds)}>
            {getString('searchCurrentBounds', language)}
          </Button>
          {!!currentMapBounds && (
            <Button color="darkRed" onClick={() => tempBounds && setMapSearchBounds(null)}>
              {getString('removeSearchBounds', language)}
            </Button>
          )}
        </div>
        <div className={styles.MapButtonsBottomRow}>
          {clickMode === DEFAULT ? (
            <Button variant="white" onClick={() => handleSetClickBehavior(BULK_ASSIGN)}>
              {getString('bulkAssign', language)}
            </Button>
          ) : (
            <>
              <Button variant="white" onClick={() => toggleBulkAssignSamplerModal(true)}>
                {getString('clickToAssignPlans', language)}
              </Button>
              <Button
                onClick={() => handleToggleDraw(!isDrawing)}
                variant={isDrawing ? 'filled' : 'white'}
              >
                {getString('drawRectangle', language)}
              </Button>
              <Button onClick={() => handleSetClickBehavior(DEFAULT)} color="darkRed">
                {getString('cancel', language)}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackingMap;
