import React, { useState } from 'react';
import { FiDelete } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Table, Text, Title } from '@mantine/core';

import { SEEDS } from 'constants/cropPlan';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { ToastType } from 'actions/toastActions';
import { AnalyticType } from 'store/analytics/types';
import { receiveSingleCatalog } from 'store/catalogs/actions';
import { CatalogType } from 'store/catalogs/types';
import { requestCatalogRemoveSeed } from 'store/cropPlans/requests';
import { PestGroupPayloadType, SeedType } from 'store/cropPlans/types';
import { CollapsibleRow } from 'common';

import InputCoverageTable from './InputCoverageTable';
import InputModal from './InputModal';

type SeedRowsPropsType = {
  seeds: SeedType[];
  analytics: AnalyticType[];
  pestGroups: PestGroupPayloadType;
  catalog: Partial<CatalogType>;
  setToastMessage: (message: string, type?: ToastType, time?: number) => void;
  label?: string;
};

const SeedRows = ({
  seeds,
  analytics,
  catalog,
  pestGroups,
  setToastMessage,
  label,
}: SeedRowsPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalInput, setModalInput] = useState<SeedType | null>();

  const rowButtonWrapper = (e: React.MouseEvent, buttonFunction: () => void) => {
    e.stopPropagation();
    buttonFunction();
  };

  const handleRemoveSeed = async (seed: SeedType) => {
    try {
      const response = await requestCatalogRemoveSeed([seed.id], catalog?.id);
      dispatch(receiveSingleCatalog(response));
      setToastMessage(`${seed.hybrid} successfully removed.`);
      navigate(`/catalog/${response.id}`);
    } catch (error) {
      setToastMessage('Failed to remove seed. Try again later.', 'error');
    }
  };

  const seedRows = () => (
    <>
      <Table.Thead>
        {label && (
          <Table.Tr>
            <Table.Th colSpan={6}>
              <Title order={4}>{getString(label, language)}</Title>
            </Table.Th>
          </Table.Tr>
        )}
        <Table.Tr>
          <Table.Th w={'10%'} />
          <Table.Th w={'20%'}>{getString('crop', language)}</Table.Th>
          <Table.Th w={'20%'}>{getString('hybrid', language)}</Table.Th>
          <Table.Th w={'20%'}>{getString('traits', language)}</Table.Th>
          <Table.Th w={'20%'}>{getString('protection', language)}</Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {seeds?.length ? (
          seeds.map((seed) => (
            <CollapsibleRow
              key={seed.id}
              collapseComponent={
                <InputCoverageTable
                  analytics={analytics}
                  pestGroups={pestGroups}
                  seed={seed}
                  catalog={catalog}
                  pl="md"
                  pt="md"
                />
              }
            >
              <Table.Td w={'10%'}>
                <FiDelete
                  color="red"
                  cursor="pointer"
                  onClick={(e) => rowButtonWrapper(e, () => handleRemoveSeed(seed))}
                  size={28}
                />
              </Table.Td>
              <Table.Td w={'20%'}>{seed.crop}</Table.Td>
              <Table.Td w={'20%'}>{seed.hybrid}</Table.Td>
              <Table.Td w={'20%'}>{seed.traits?.join(',')}</Table.Td>
              <Table.Td w={'20%'} fw="bold">
                <Button variant="subtle" ml="-1.1rem">
                  {getString('clickToExpand', language)}
                </Button>
              </Table.Td>
              <Table.Td>
                <Button onClick={(e) => rowButtonWrapper(e, () => setModalInput(seed))}>
                  {getString('viewEdit', language)}
                </Button>
              </Table.Td>
            </CollapsibleRow>
          ))
        ) : (
          <Table.Tr>
            <Table.Td colSpan={6}>
              <Flex w="100%" justify="center">
                <Text>{getString('noSeedToDisplay', language)}</Text>
              </Flex>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </>
  );

  return (
    <>
      {seedRows()}
      {modalInput && (
        <InputModal
          closeModal={() => setModalInput(null)}
          isTemporary={false}
          modalOpened={Boolean(modalInput)}
          catalog={catalog}
          data={modalInput}
          type={SEEDS}
        />
      )}
    </>
  );
};

export default SeedRows;
