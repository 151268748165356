import { useState } from 'react';
import { Stepper } from '@mantine/core';
import { useZoneSetup } from 'apps/ZoneAnalysisV3/hooks';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

import AnalysisMapV3 from '../Maps/AnalysisMap';
import SamplingPointsMap from '../Maps/SamplingPointsMap';
import SplitDensityMap from '../Maps/SplitDensityMap';

import SamplingPointsStep from './MapSteps/SamplingPointsStep';
import SamplingZonesStep from './MapSteps/SamplingZonesStep';
import SplitDensityStep from './MapSteps/SplitDensityStep';
import AnalysisSamplingStep from './AnalysisSamplingStep';
import ReviewOrderStep from './ReviewOrderStep';
import StepperFooter from './StepperFooter';

const OrderFormStepper = () => {
  const language = useBroswerLanguage();
  const [activeStepNumber, setActiveStepNumber] = useState(0);
  const zoneSetup = useZoneSetup();
  const handleStepChange = (nextStep: number) => setActiveStepNumber(nextStep);
  const form = useOrderFormContext();
  const formValues = form.getValues();

  const { isProOrTillRx, hasBioProducts } = form.getTransformedValues();
  const { isSplitDensity } = formValues;

  if (!zoneSetup?.fieldFeatureCollection) {
    return null;
  }

  return (
    <>
      <Stepper
        active={activeStepNumber}
        mx="auto"
        flex={1}
        display="flex"
        w="100%"
        styles={{
          content: {
            flex: 1,
            overflowY: 'auto',
            margin: '0 auto',
            width: '100%',
          },
          root: {
            flexDirection: 'column',
            overflowY: 'hidden',
            gap: 'var(--mantine-spacing-md)',
          },
        }}
      >
        <Stepper.Step label={getString('analysisAndSampling', language)}>
          <AnalysisSamplingStep />
        </Stepper.Step>
        {hasBioProducts && (
          <Stepper.Step label={getString('samplingZones', language)}>
            {!!zoneSetup?.fieldFeatureCollection && (
              <SamplingZonesStep
                {...zoneSetup}
                map={
                  <AnalysisMapV3
                    drawRef={zoneSetup.drawRef}
                    field={zoneSetup.fieldFeatureCollection}
                  />
                }
              />
            )}
          </Stepper.Step>
        )}
        {isProOrTillRx && (
          <Stepper.Step label={getString('samplingPoints', language)}>
            {!!zoneSetup?.fieldFeatureCollection && (
              <SamplingPointsStep
                {...zoneSetup}
                map={
                  <SamplingPointsMap
                    drawRef={zoneSetup.drawRef}
                    field={zoneSetup.fieldFeatureCollection}
                  />
                }
              />
            )}
          </Stepper.Step>
        )}
        {isSplitDensity && (
          <Stepper.Step label={getString('splitDensity', language)}>
            <SplitDensityStep
              map={
                <SplitDensityMap
                  drawRef={zoneSetup.drawRef}
                  field={zoneSetup.fieldFeatureCollection}
                />
              }
            />
          </Stepper.Step>
        )}
        <Stepper.Step label={getString('confirmOrder', language)}>
          <ReviewOrderStep />
        </Stepper.Step>
      </Stepper>
      <StepperFooter
        activeStepNumber={activeStepNumber}
        onStepClick={handleStepChange}
        fieldFeatureCollection={zoneSetup.fieldFeatureCollection}
      />
    </>
  );
};

export default OrderFormStepper;
