import React from 'react';
import { Table } from '@mantine/core';

import {
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
  productShortNames,
} from 'constants/products';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { displayDivide } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { SamplePlanTrackingType } from 'store/samplePlans/types';

import styles from './Container.module.css';

type TrackingInnerRowProps = {
  samplePlan: SamplePlanTrackingType;
};

const TrackingInnerRow = ({ samplePlan }: TrackingInnerRowProps) => {
  const language = useBroswerLanguage();
  return (
    <>
      <Table.Tr
        style={(theme) => ({
          backgroundColor: theme.colors.grey[9],
        })}
      >
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td />
        <Table.Td className={styles.TopBottomBorder}>{getString('notes', language)}</Table.Td>
        <Table.Td className={styles.TopBottomBorder}>{getString('panel', language)}</Table.Td>
        <Table.Td className={styles.TopBottomBorder}>{getString('complete', language)}</Table.Td>
        <Table.Td className={styles.TopBottomBorder}>{getString('notAnalyzed', language)}</Table.Td>
        <Table.Td />
      </Table.Tr>
      {[
        productShortNames[NUTRIENT_PANEL],
        productShortNames[PRESSURE_PANEL],
        productShortNames[PERFORMANCE_PANEL],
        productShortNames[NITRATE_PANEL],
      ].map((product, index) => (
        <Table.Tr
          key={product}
          style={(theme) => ({
            backgroundColor: theme.colors.grey[9],
          })}
        >
          <Table.Td />
          <Table.Td />
          <Table.Td />
          <Table.Td />
          <Table.Td />
          <Table.Td>
            {index === 0 && (samplePlan.not_ready_reason || getString('None', language))}
          </Table.Td>
          <Table.Td>{getString(product, language)}</Table.Td>
          <Table.Td>
            {displayDivide(
              samplePlan[`${product}_completed_count`],
              samplePlan[`${product}_ordered_count`],
            )}
          </Table.Td>
          <Table.Td>
            {samplePlan[`${product}_not_analyzed_count`] !== undefined &&
              displayDivide(
                samplePlan[`${product}_not_analyzed_count`],
                samplePlan[`${product}_ordered_count`],
              )}
          </Table.Td>
          <Table.Td />
        </Table.Tr>
      ))}
    </>
  );
};

export default TrackingInnerRow;
