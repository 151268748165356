import { Flex, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useOperationUsersQuery } from 'apps/ZoneAnalysisV3/queries';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleTimingsQueryOptions } from 'util/queryOptions';
import { getString } from 'strings/translation';
import CenteredLoader from 'common/CenteredLoader';

import ReadyToSampleCheckbox from './ReadyToSampleCheckbox';

interface Props {
  sampledById: number | null;
  scannedById: number | null;
  sampleTiming: string | null;
  assigned_user_name?: string | null;
  assigned_scan_user_name?: string | null;
  isViewOnly?: boolean;
}

enum SamplerScanner {
  SAMPLED_BY = 'sampledBy',
  SCANNED_BY = 'scannedBy',
}

const SamplingExecution = ({
  sampleTiming,
  sampledById,
  scannedById,
  assigned_user_name,
  assigned_scan_user_name,
  isViewOnly = false,
}: Props) => {
  const language = useBroswerLanguage();
  const operationUsersQuery = useOperationUsersQuery();
  const sampleTimingsQuery = useQuery(sampleTimingsQueryOptions);

  if (operationUsersQuery.isPending || sampleTimingsQuery.isPending) {
    return <CenteredLoader />;
  }

  const getSamplerScanner = (samplerScanner: string) => {
    if (samplerScanner === SamplerScanner.SAMPLED_BY) {
      return assigned_user_name
        ? { first_name: assigned_user_name, last_name: '' }
        : operationUsersQuery.data?.users.find(({ id }) => id === sampledById);
    }
    return assigned_scan_user_name
      ? { first_name: assigned_scan_user_name, last_name: '' }
      : operationUsersQuery.data?.users.find(({ id }) => id === scannedById);
  };

  const sampleTimingText = sampleTimingsQuery.data?.sampling_timings.find(
    ({ value }) => value === sampleTiming,
  )?.display_name;
  const sampledBy = getSamplerScanner(SamplerScanner.SAMPLED_BY);
  const scannedBy = getSamplerScanner(SamplerScanner.SCANNED_BY);

  return (
    <Stack px="lg" gap="sm" align="flex-start" w="100%">
      <Title order={4} fw="bold">
        {getString('sampleExecution', language)}
      </Title>
      <Stack px="lg" gap="sm">
        <Flex
          rowGap="sm"
          columnGap="lg"
          display="grid"
          style={{ gridTemplateColumns: 'auto auto' }}
        >
          {!!sampledBy && (
            <>
              <Text fw="bold">{getString('sampledBy', language)}</Text>
              <Text>
                {sampledBy.first_name} {sampledBy.last_name}
              </Text>
            </>
          )}
          {!!scannedBy && (
            <>
              <Text fw="bold">{getString('scannedBy', language)}</Text>
              <Text>
                {scannedBy.first_name} {scannedBy.last_name}
              </Text>
            </>
          )}
          <Text fw="bold" tt="capitalize">
            {getString('sampleTiming', language)}
          </Text>
          <Text>{sampleTimingText}</Text>
        </Flex>
        {!isViewOnly && (
          <>
            <Text size="sm">{getString('planConfMsg', language)}</Text>
            <ReadyToSampleCheckbox />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default SamplingExecution;
