import React, { useEffect, useState } from 'react';
import { Flex, Group, Slider, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getRxDcpLayerByDepthAndPsi, getRxLayerByDepthAndPsi } from 'util/proMaps';
import { getString } from 'strings/translation';
import { EOInferenceLayerType, EOTargetMeasurement } from 'store/eoCollections/types';

type Props = {
  setGeojsonUri: (uri: string) => void;
  availableLayers: EOInferenceLayerType[];
  availableDcpLayers: EOTargetMeasurement[];
  onChange: () => void;
  setInferenceLayer: (inferenceLayer: EOInferenceLayerType) => void;
  setDcpUri: (uri: string) => void;
  inline?: boolean;
};

const DEFAULT_DEPTH = 12;
const DEFAULT_PSI = 300;

export const RxResultsSlider = ({
  availableLayers,
  setGeojsonUri,
  onChange,
  setInferenceLayer,
  availableDcpLayers,
  setDcpUri,
  inline,
}: Props) => {
  const [depth, setDepth] = useState(DEFAULT_DEPTH);
  const [psi, setPsi] = useState(DEFAULT_PSI);
  const language = useBroswerLanguage();

  useEffect(() => {
    const matchedLayer = getRxLayerByDepthAndPsi(depth, psi, availableLayers);
    const matchedDcpLayer = getRxDcpLayerByDepthAndPsi(depth, psi, availableDcpLayers);

    if (matchedLayer) {
      setInferenceLayer(matchedLayer);
      setGeojsonUri(matchedLayer.geojson_uri);
    }

    if (matchedDcpLayer) {
      setDcpUri(matchedDcpLayer.geojson_uri);
    }
  }, [depth, psi, availableLayers, setInferenceLayer]);

  return (
    <Flex direction={inline ? 'row' : 'column'} pr="lg" flex={1} gap="xl">
      <Group pr="lg" gap={inline ? 'md' : 'lg'} flex={1} maw={inline ? 350 : 450}>
        <Text id="depth-slider" style={{ justifySelf: 'flex-end' }} fw="bold">
          {getString('depth', language)}
        </Text>
        <Slider
          aria-labelledby="depth-slider"
          min={0}
          max={18}
          value={depth}
          step={1}
          thumbSize={24}
          label={(inch) => `${inch}"`}
          style={{ flex: 1 }}
          marks={[
            { value: 0, label: getString('surface', language) },
            { value: 3, label: '3"' },
            { value: 6, label: '6"' },
            { value: 9, label: '9"' },
            { value: 12, label: '12"' },
            { value: 16, label: '16"' },
            { value: 18, label: '18"' },
          ]}
          onChange={(newDepth) => {
            onChange();
            setDepth(newDepth);
          }}
        />
      </Group>
      <Group pr="lg" gap="lg" flex={1} maw={inline ? 350 : 450}>
        <Text id="psi-slider" style={{ justifySelf: 'flex-end' }} fw="bold">
          {getString('maxPsi', language)}
        </Text>
        <Slider
          aria-labelledby="psi-slider"
          min={0}
          max={1000}
          value={psi}
          step={50}
          thumbSize={24}
          style={{ flex: 1 }}
          marks={[
            { value: 0, label: 0 },
            { value: 250, label: 250 },
            { value: 500, label: 500 },
            { value: 750, label: 750 },
            { value: 1000, label: 1000 },
          ]}
          onChange={(newPsi) => {
            onChange();
            setPsi(newPsi);
          }}
        />
      </Group>
    </Flex>
  );
};
