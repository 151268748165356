import React from 'react';
import { rem, Stack, Text } from '@mantine/core';

import styles from './AnalyticButton.module.css';

type AnalyticButtonPropsType = {
  name: string;
  percent: number;
  setAnalytic: () => void;
  isSelected: boolean;
  pxWidth: number;
};

export const AnalyticButton = ({
  name,
  percent,
  setAnalytic,
  isSelected,
  pxWidth,
}: AnalyticButtonPropsType) => {
  const minTextSize = 0.5;
  const maxTextSize = 0.75;
  const lowerWidth = 2;
  const upperWidth = 5;

  const getTextSize = () => {
    // Scales text size proportionally based on chart width
    // Minimum 0.5rem at 2rem width, maximum 0.75rem at 5rem width
    const remWidth = pxWidth / 16;
    const scaled =
      minTextSize +
      ((remWidth - lowerWidth) * (maxTextSize - minTextSize)) / (upperWidth - lowerWidth);
    const minValue = Math.min(scaled, maxTextSize);
    return Math.max(minValue, minTextSize) * 16;
  };

  return (
    <Stack
      align="center"
      gap="0"
      w={rem(pxWidth)}
      style={
        isSelected
          ? {
              outline: '4px solid var(--mantine-color-orange-3)',
              borderRadius: 'var(--mantine-radius-xs)',
            }
          : {}
      }
    >
      <div className={styles.SingleBox} onClick={setAnalytic}>
        {[3, 2, 1].map((level) => (
          <div className={styles.SlimBox} key={level} />
        ))}
        <div className={styles.Bar} style={{ height: `${percent}%` }} />
      </div>
      <Text
        size={rem(getTextSize())}
        w={rem(pxWidth)}
        pt="xs"
        pb="xs"
        fw="bold"
        style={{ textAlign: 'center' }}
      >
        {name}
      </Text>
    </Stack>
  );
};
