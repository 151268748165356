import React from 'react';
import { Link } from 'react-router-dom';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import { getAnalysisDisplayName } from 'util/analysis';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getPlateStatusDisplayName, getWellCountByPlateType } from 'util/plates';
import { getString } from 'strings/translation';
import { LabShipmentFormType } from 'store/labShipments/types';
import { PlateType } from 'store/plates/types';
import { Checkbox, Spinner, Table, TableCol, TableRow } from 'common';

import styles from './PlateList.module.css';

type ShippingPlatesListPropsType = {
  plates?: PlateType[];
  shipment: LabShipmentFormType;
  updateShipment: (attributeName: string, newValue: string[]) => void;
};

export const ShippingPlatesList = ({
  plates,
  shipment,
  updateShipment,
}: ShippingPlatesListPropsType) => {
  const language = useBroswerLanguage();
  const shippingPlateBarcodes = shipment.plate_barcodes || [];

  const handleAddRemovePlate = (barcode: string, remove: boolean) => {
    if (remove) {
      const index = shippingPlateBarcodes.indexOf(barcode);
      if (index >= 0) {
        shippingPlateBarcodes.splice(index, 1);
      }
    } else {
      shippingPlateBarcodes.push(barcode);
    }

    updateShipment('plate_barcodes', shippingPlateBarcodes);
  };

  if (!plates?.length || !shipment?.group_id) {
    return (
      <div className={styles.NoFiles}>
        {getString('noPlatesListMsg', language)}
        {plates === undefined && <Spinner className={styles.Spinner} />}
      </div>
    );
  }

  return (
    <Table>
      <TableRow header>
        <TableCol size="x2">{getString('barcode', language)}</TableCol>
        <TableCol size="x2">{getString('analysis', language)}</TableCol>
        <TableCol>{getString('status', language)}</TableCol>
        <TableCol>{getString('wells', language)}</TableCol>
        <TableCol>{`${getString('include', language)}?`}</TableCol>
      </TableRow>
      {plates.map((plate) => {
        const isChecked = shippingPlateBarcodes.includes(plate.barcode);
        return (
          <TableRow key={plate.id}>
            <TableCol size="x2">
              <Link className={styles.Link} to={`/lab/plates/details/${plate.barcode}`}>
                {plate.barcode}
              </Link>
            </TableCol>
            <TableCol size="x2">
              {plate.analysis_type
                ? getAnalysisDisplayName(plate.analysis_type, language)
                : NOT_APPLICABLE}
            </TableCol>
            <TableCol>{getPlateStatusDisplayName(plate.status, language)}</TableCol>
            <TableCol>{`${plate.well_count} / ${getWellCountByPlateType(plate.plate_type)}`}</TableCol>
            <TableCol>
              <Checkbox
                className={styles.Checkbox}
                checked={isChecked}
                onChange={() => handleAddRemovePlate(plate.barcode, isChecked)}
                dark
              />
            </TableCol>
          </TableRow>
        );
      })}
    </Table>
  );
};
