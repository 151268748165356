import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Space, Table } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { joinStrings } from 'util/stringUtils';
import { userIsAgencyAdmin, userIsAgent } from 'util/userRoles';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { addUsersToAgency, removeUsersFromAgency } from 'store/agencies/requests';
import { User } from 'store/user/types';
import { Header, UserSearch } from 'common';

import styles from './ManageUsers.module.css';

type PropsType = {
  agencyId: number;
  fetchAgencyUsers: () => void;
};

const ManageCommercialUsers = ({ agencyId, fetchAgencyUsers }: PropsType) => {
  const language = useBroswerLanguage();
  const [email, setEmail] = useState('');

  const { agencyUsers, isFetching } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
    agencyUsers: state.agencies.byId[agencyId]?.allUsers || [],
    isFetching: state.agencies.isFetching,
    storeRoles: state.roles.roles,
  }));
  const nonAgentUsers = agencyUsers.filter(
    (user) => !userIsAgencyAdmin(user.roles) && !userIsAgent(user.roles),
  );

  const addUserToAgency = async () => {
    await addUsersToAgency(agencyId, [email]);
    fetchAgencyUsers();
    setEmail('');
  };

  const removeUserFromAgency = async (user: User) => {
    await removeUsersFromAgency(agencyId, [user.email]);
    fetchAgencyUsers();
  };

  const commercialUserRow = (user: User) => (
    <Table.Tr key={user.id}>
      <Table.Td>{joinStrings([user.first_name, user.last_name], ' ')}</Table.Td>
      <Table.Td>{user.email}</Table.Td>
      <Table.Td>{user.roles[0].name}</Table.Td>
      <Table.Td>
        <Button variant="danger" onClick={() => removeUserFromAgency(user)}>
          X
        </Button>
      </Table.Td>
    </Table.Tr>
  );

  return (
    <div>
      <Header title={getString('manageCommercialUsers', language)}>
        <UserSearch
          placeholder={email || getString('searchByUser', language)}
          onSelect={(user) => setEmail(user.email)}
          className={styles.SearchBar}
        />
        <Button disabled={isFetching || !email} onClick={addUserToAgency}>
          {getString('add', language)}
        </Button>
      </Header>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{getString('name', language)}</Table.Th>
            <Table.Th>{getString('email', language)}</Table.Th>
            <Table.Th>{getString('role', language)}</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{nonAgentUsers.map(commercialUserRow)}</Table.Tbody>
      </Table>
      <Space h="xl" />
    </div>
  );
};

export default ManageCommercialUsers;
