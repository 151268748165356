import { Button, Group, Modal, Radio, Stack, TextInput } from '@mantine/core';

import { SPLIT_MODAL_FORM_KEYS as formKeys, SplitFieldCreateFate } from 'constants/fields';

import { getString } from 'strings/translation';

type Props = {
  open: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onSubmit: (name: string, fateAfterSplit: SplitFieldCreateFate) => void;
  language: string;
};

const viewNewFieldValue: SplitFieldCreateFate = 'view-new-field';
const splitParentValue: SplitFieldCreateFate = 'split-parent-again';

const SplitFieldModal = ({ open, onClose, onSubmit, language, isLoading }: Props) => {
  return (
    <Modal opened={open} onClose={onClose} centered title={getString('splitField', language)}>
      <Stack
        gap="lg"
        component="form"
        onSubmit={(evt) => {
          evt.preventDefault();

          const formData = new FormData(evt.target as HTMLFormElement);
          const name = formData.get(formKeys.name) as string;
          const fateAfterSplit = formData.get(formKeys.fateAfterSplit) as SplitFieldCreateFate;

          onSubmit(name, fateAfterSplit);
        }}
      >
        <TextInput
          name={formKeys.name}
          required
          placeholder={getString('name', language)}
          label={getString('name', language)}
        />
        <Radio.Group
          name={formKeys.fateAfterSplit}
          label={getString('whatAfterSplit', language)}
          required
          withAsterisk={false}
          defaultValue={viewNewFieldValue}
          mb="xs"
          ta="center"
        >
          <Group mt="xs" justify="center">
            <Radio value={viewNewFieldValue} label={getString('viewNewField', language)} />
            <Radio value={splitParentValue} label={getString('splitParentFieldAgain', language)} />
          </Group>
        </Radio.Group>
        <Group justify="center">
          <Button onClick={onClose} variant="outline">
            {getString('cancel', language)}
          </Button>
          <Button disabled={isLoading} type="submit">
            {getString('splitField', language)}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default SplitFieldModal;
