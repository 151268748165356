import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { USER_TRANSACTION_ID } from 'constants/auth';
import { NOT_APPLICABLE } from 'constants/defaultValues';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { setLocalStorage } from 'util/localStorage';
import { capitalizeEveryWord } from 'util/stringUtils';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { postLoginToQuickbooks, postPaymentToApprove } from 'store/payments/requests';
import { createAdjustment, getOpenTransactionById } from 'store/payments/thunks';
import { AdjustmentsType, SamplePlanTransactionsType } from 'store/payments/types';
import { userIsAdmin, userIsSampler, userIsSuperAdmin } from 'store/user/selectors';
import { AdminAccess, Button, Header, Spinner } from 'common';

import ActivityDetailTable from '../common/Tables/ActivityDetailTable';
import AdjustmentsTable from '../common/Tables/AdjustmentsTable';
import OpenTable from '../common/Tables/OpenTable';

import AdjustmentDialog from './AdjustmentDialog';
import ApprovalController from './ApprovalController';

import styles from './Container.module.css';

interface TransactionsType {
  [sample_plan_id: number]: SamplePlanTransactionsType;
}

const PaymentOpenDetailsContainer = () => {
  const language = useBroswerLanguage();
  const [signingIn, toggleSigningIn] = useState(false);
  const [modTransactions, setModTransactions] = useState<TransactionsType | undefined>();
  const [showAddAdjustments, setShowAddAdjustments] = useState(false);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);

  const params = useParams<{ userId: string }>();
  const userId = Number(params.userId);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const fetchOpenTransactions = useCallback(
    () => dispatch(getOpenTransactionById(userId)),
    [dispatch, userId],
  );

  const { user, isFetching, hasFailed, isSampler, isAdmin } = useSelector((state: RootState) => ({
    user: state.payments.open.byId[userId],
    isFetching: state.payments.isFetching,
    hasFailed: state.payments.hasFailed,
    isSampler: userIsSampler(state),
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  useEffect(() => {
    if (!user && !hasFailed) {
      fetchOpenTransactions();
    }
  }, [fetchOpenTransactions, hasFailed, user]);

  const getPlanIds = () => {
    return modTransactions
      ? Object.values(modTransactions).reduce((all, tran) => {
          if (tran.inPayment) {
            return [...all, tran.sample_plan_id];
          }
          return all;
        }, [])
      : [];
  };

  useEffect(() => {
    if (!modTransactions && user?.open_transactions) {
      const allTransactions = user.open_transactions.transactions_by_sample_plan;
      setModTransactions(
        Object.keys(allTransactions).reduce((all, key) => {
          return {
            ...all,
            [key]: { ...allTransactions[key], inPayment: true },
          };
        }, {}),
      );
    }
  }, [modTransactions, user]);

  const handleAddAdjustments = useCallback(
    (data: AdjustmentsType) => {
      dispatch(createAdjustment(data, getPlanIds()));
    },
    [dispatch],
  );

  const handleApproveTransaction = async (
    term?: {
      displayName: string;
      id: number;
    },
    isQB?: boolean,
  ) => {
    try {
      if (Number(userId)) {
        setLocalStorage(USER_TRANSACTION_ID, userId);

        const auth_url_or_transaction = await postPaymentToApprove(
          userId,
          !!isQB,
          getPlanIds(),
          term?.displayName,
        );
        dispatch(getOpenTransactionById(userId));
        if (typeof auth_url_or_transaction === 'string') {
          window.open(auth_url_or_transaction, '_self');
        } else {
          showToast(
            isQB
              ? getString('successfullyCreatedInvoice', language)
              : getString('successfullyCreatedNoQB', language),
            'success',
          );
          navigate(`/payments/open`);
        }
      }
    } catch (err) {
      showToast(getString('errorApprovingTransaction', language), 'error');
    }
  };

  const name = user ? `${capitalizeEveryWord(user.billing_party_name)}` : NOT_APPLICABLE;
  const title = (
    <div>
      <Link to="/payments/open" className={styles.OpenPaymentsLink}>
        {getString('payment', language)} - {getString('open', language)}
      </Link>
      <span>{`${getString('openTransactions', language)} - ${name}`}</span>
    </div>
  );

  const loginQuickbooks = async () => {
    try {
      toggleSigningIn(true);
      setLocalStorage(USER_TRANSACTION_ID, userId);
      const url = await postLoginToQuickbooks();
      window.open(url, '_self');
    } catch (err) {
      showToast(getString('errorLoggingIn', language), 'error');
    }
  };

  const toggleModTransactions = (planId: number) => {
    const allTransactions = { ...modTransactions };
    allTransactions[planId] = {
      ...allTransactions[planId],
      inPayment: !allTransactions[planId].inPayment,
    };
    setModTransactions(allTransactions);
  };

  const openApprovalModal = async () => {
    await dispatch(getOpenTransactionById(user.id, getPlanIds()));
    setShowApprovalDialog(true);
  };

  const uncheckAll = () => {
    const allTransactions = user.open_transactions.transactions_by_sample_plan;
    setModTransactions(
      Object.keys(allTransactions).reduce((all, key) => {
        return {
          ...all,
          [key]: { ...allTransactions[key], inPayment: false },
        };
      }, {}),
    );
  };

  return isFetching ? (
    <div className={styles.Wrapper}>
      <Spinner fill />
    </div>
  ) : (
    <div className={styles.Wrapper}>
      <Header title={title} />
      {user && (
        <div className={styles.InvoicesWrapper}>
          <div className={styles.HeaderWithButton}>
            <h2>{getString('summary', language)}</h2>
            <AdminAccess>
              <div>
                <Button
                  className={styles.MarginRight}
                  onClick={openApprovalModal}
                  primary
                  disabled={!user.billing_addresses.length}
                >
                  {getString('approveOpenTransactions', language)}
                </Button>
                <Button onClick={loginQuickbooks} disabled={signingIn} primary>
                  {getString('loginToQuickbooks', language)}
                </Button>
                {!user.billing_addresses.length && (
                  <p className={styles.ErrorText}>
                    {getString('billingAddressRequired', language)}
                  </p>
                )}
              </div>
            </AdminAccess>
          </div>
          <OpenTable openTransactions={[user]} isSampler={isSampler} />
          {modTransactions && Object.keys(modTransactions).length > 0 && (
            <>
              <div className={styles.HeaderWithButton}>
                <h2>{getString('activityDetails', language)}</h2>
                <div>
                  {isAdmin && (
                    <Button onClick={uncheckAll}>{getString('uncheckAll', language)}</Button>
                  )}
                </div>
              </div>
              <ActivityDetailTable
                transactions={modTransactions}
                toggleModTransactions={toggleModTransactions}
                isSelectPayment
              />
            </>
          )}
          <div className={styles.HeaderWithButton}>
            <div>
              {user.open_transactions.adjustments.length > 0 && (
                <h2>{getString('adjustments', language)}</h2>
              )}
            </div>
            <AdminAccess>
              <Button className={styles.AddAdjustments} onClick={() => setShowAddAdjustments(true)}>
                {getString('addAdjustments', language)}
              </Button>
            </AdminAccess>
          </div>
          {user.open_transactions.adjustments.length > 0 && (
            <AdjustmentsTable adjustments={user.open_transactions.adjustments} />
          )}
          {showAddAdjustments && (
            <div>
              <AdjustmentDialog
                userId={userId}
                firstName={user.first_name}
                lastName={user.last_name}
                onSubmit={handleAddAdjustments}
                onClose={() => setShowAddAdjustments(false)}
              />
            </div>
          )}
          {showApprovalDialog && (
            <ApprovalController
              firstName={user.first_name}
              lastName={user.last_name}
              amount={user.open_transactions.summary.balance}
              onSubmit={handleApproveTransaction}
              onClose={() => setShowApprovalDialog(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentOpenDetailsContainer;
