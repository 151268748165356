import React from 'react';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { capitalizeEveryWord } from 'util/stringUtils';
import { getString } from 'strings/translation';
import { ApprovedPaymentsType } from 'store/payments/types';
import { Table, TableCol, TableRow } from 'common';

import styles from './UserInfo.module.css';

type UserInfoPropsType = {
  payment: ApprovedPaymentsType;
};

const UserInfo = ({ payment }: UserInfoPropsType) => {
  const language = useBroswerLanguage();
  const address = payment.user.billing_addresses[0];
  return (
    <Table>
      <TableRow className={styles.NoRowBorder}>
        <TableCol>
          <div>{getString('name', language)}</div>
          <div>{capitalizeEveryWord(payment.billing_party_name)}</div>
        </TableCol>
        <TableCol>
          <div>{getString('cellPhone', language)}</div>
          <div>{payment.user.cell ? payment.user.cell : NOT_APPLICABLE}</div>
        </TableCol>
      </TableRow>
      {address && (
        <TableRow className={styles.NoRowBorder}>
          <TableCol>
            <div>{getString('billingAddress', language)}</div>
            <div>{capitalizeEveryWord(address.street)}</div>
          </TableCol>
          <TableCol>
            <div>
              {getString('city', language)} &amp; {getString('state', language)}
            </div>
            <div>{`${capitalizeEveryWord(address.city)}, ${address.state.toUpperCase()}`}</div>
          </TableCol>
          <TableCol>
            <div>{getString('zipcode', language)}</div>
            <div>{address.zip}</div>
          </TableCol>
        </TableRow>
      )}
    </Table>
  );
};

export default UserInfo;
