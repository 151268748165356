import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space, Stack, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { receiveSingleOperation } from 'store/operation/actions';
import { postOperation } from 'store/operation/requests';
import { setActiveOperation } from 'store/operation/thunks';

import styles from './Container.module.css';

const CreateOperationContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [operationName, setOperationName] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const isFormValid = () => Boolean(operationName.length);

  const redirect = ({ id }) => {
    dispatch(setActiveOperation(id));
    navigate(`/operations/${id}/ingest_history`);
  };

  const submit = async () => {
    try {
      setIsFetching(true);
      const payload = await postOperation({ name: operationName });
      dispatch(receiveSingleOperation(payload));
      redirect(payload);
    } catch (error) {
      showToast(getString('createOperationErrorMsg', language), 'error');
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Stack justify="center" align="center">
      <Space h="xl" />
      <Text size="lg">{getString('getStartedCreateAccount', language)}:</Text>
      <Input.Wrapper label={getString('accountName', language)} w="20rem">
        <Input onChange={(e) => setOperationName(e.target.value)} value={operationName} />
      </Input.Wrapper>
      <Space h="lg" />
      <Button
        className={styles.SubmitButton}
        disabled={!isFormValid() || isFetching}
        onClick={submit}
        loading={isFetching}
      >
        {getString('createAccount', language)}
      </Button>
    </Stack>
  );
};

export default CreateOperationContainer;
