import React from 'react';
import { Link } from 'react-router-dom';

import { NOT_APPLICABLE } from 'constants/defaultValues';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';
import { Container, Header } from 'common';

import styles from './PlateBatches.module.css';

type PlateBatchesPropsType = {
  plate?: PlateType;
};

export const PlateBatches = ({ plate }: PlateBatchesPropsType) => {
  const language = useBroswerLanguage();

  return (
    <Container vertical>
      <Header
        title={
          <div className={styles.Batch}>
            <div>{`${getString('batch', language)}: `}</div>
            {!plate?.batch_id ? (
              NOT_APPLICABLE
            ) : (
              <Link className={styles.Link} to={`/lab/batches/details/${plate.batch_id}`}>
                {plate.batch_id}
              </Link>
            )}
          </div>
        }
        underline="none"
        size="H2"
      />
    </Container>
  );
};
