import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { OperationType } from 'store/operation/types';
import getSamplesForField from 'store/samples/thunks';

const useOperationSamples = (operation: OperationType, cropYear?: number | null) => {
  const dispatch = useDispatch();
  const operationFieldsIds = operation.fields.map((field) => field.id);

  const {
    hasFetchedSamples,
    hasFailedSamples,
    isFetchingSamples,
    willFetchFieldsList,
    hasFetchedFields,
    isFetchingFieldsList,
    analytics,
  } = useSelector((state: RootState) => ({
    hasFetchedSamples: state.samples.hasFetchedList,
    hasFailedSamples: state.samples.hasFailedList,
    isFetchingSamples: state.samples.isFetchingList,
    hasFetchedFields: [...state.fieldGeometry.hasFetchedList, ...state.fieldGeometry.hasFailedList],
    willFetchFieldsList: state.fieldGeometry.willFetchList,
    isFetchingFieldsList: state.fieldGeometry.isFetchingList,
    analytics: state.analytics.analytics,
  }));

  useEffect(() => {
    // Wait until fields are fetched
    if (
      operation.fields &&
      hasFetchedFields.length &&
      !isFetchingFieldsList.length &&
      !willFetchFieldsList.length &&
      !isFetchingSamples.length
    ) {
      const fetchedFailedOrFetching = [...hasFetchedSamples, ...hasFailedSamples];
      const neededFieldIds = hasFetchedFields.filter(
        (nfid) => operationFieldsIds.includes(nfid) && !fetchedFailedOrFetching.includes(nfid),
      );
      if (neededFieldIds.length) {
        neededFieldIds
          .slice(0, 3)
          .forEach((field_id) => dispatch(getSamplesForField(field_id, analytics)));
      }
    }
  }, [
    operation.fields,
    hasFetchedSamples,
    hasFailedSamples,
    willFetchFieldsList,
    cropYear,
    isFetchingSamples,
    hasFetchedFields,
    operationFieldsIds,
    analytics,
  ]);
};

export default useOperationSamples;
